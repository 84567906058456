import { Component } from '@angular/core';
import { RsrService } from '../rsr.services';
import { RsrRequest, RsrClientErrorResponse, RsrReportListResponse } from '../../models/rsr.models';
import { AppContextService } from '../../app-context/app-context.service';
import { SpinnerService } from '../../common/spinner/spinner.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-rsr-client-error',
  templateUrl: './rsr-client-error.component.html',
  styleUrl: './rsr-client-error.component.scss'
})

export class RsrClientErrorComponent {
  rsrClientErrors: RsrClientErrorResponse[];
  getRsrClientErrors = () => this.rsrClientErrors;
  showDialog = false;

  constructor(private rsrService: RsrService,
              public app: AppContextService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,              
              private spinnerService: SpinnerService) {
  }

  ngOnInit(): void {
    //this.initialize(1, 14);
  }

  onShow(rsrReportListItem: RsrReportListResponse) {
    this.initialize(rsrReportListItem);
    this.showDialog = true;    
  }

  onHide(event) {
    this.showDialog = false;
  }  

  initialize(rsrReportListItem: RsrReportListResponse) {
    var model : RsrRequest = { providerId: this.app.userServ.CurrentProviderID, fundYear: "CY2024", clinicId: rsrReportListItem.clinicId, funderId: rsrReportListItem.funderId, isAdmin: false };

    this.spinnerService.showSpinnerUntilCompleted(this.rsrService.GetRSRClientErrors(model)).subscribe({
      next: (result: RsrClientErrorResponse[]) => {
        this.rsrClientErrors = result;
      },
      error: (e) => { //HttpErrorResponse
        this.app.userServ.LogError(e);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: e.error.message, sticky: true });
      },
      complete: () => {
        //this.messageService.add({severity: 'info', summary: 'Completed', detail: "Executed sucessfully."});
      }
    });
  }  
}
