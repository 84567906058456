import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
//import { ClientListResponse, clientListRequest, PrimaryMedicalInfo } from './client-list-models' 
import { environment } from 'src/environments/environment';
import { BulkGroupListRequestModel, BulkGroupListResponseModel, BulkGroupRequestModel, BulkGroupReponseModel, BulkGroupPostModel } from '../models/bulk-group';
import { PostResponse } from '../models/post-response.model';

@Injectable({
  providedIn: 'root'
})
export class BulkgroupService {

  private readonly URL = environment.apiURL + '/clientbulkgroup';

  constructor(private http: HttpClient) { }

  public getBulkGroupList(model: BulkGroupListRequestModel)
  {
    return this.http.post<BulkGroupListResponseModel[]>(this.URL + '/GetBulkGroupList/', model);
  }

  public getBulkGroupServices(model: BulkGroupRequestModel)
  {
    return this.http.post<BulkGroupReponseModel[]>(this.URL + '/GetBulkGroupServices/', model);
  }

  public saveBulkGroupServices(model: Partial<BulkGroupPostModel>[])
  {
    return this.http.post<PostResponse>(this.URL + '/SaveBulkGroupServices/', model);
  }
}
