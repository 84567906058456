<div>
    <b>The client may not be eligible for Ryan White Part A, Part F (MAI) services.</b> <br><br>
    Possible reasons are:<br>
        <ul>
            <li>a) Client has a federal poverty level (FPL) of over 500% 
                <fa-icon [icon]="['fas', 'check']" [styles]="{'stroke': 'red', 'color': 'red'}" *ngIf="this.showFPLCheck" ></fa-icon>
            </li>
            <li>b) Client has FPL < 139%  and has no insurance - Client may be eligible for \n \t Medicaid Expansion (New Jersey Family Care). Please verify insurance status
                <fa-icon [icon]="['fas', 'check']" [styles]="{'stroke': 'red', 'color': 'red'}" *ngIf="this.showInsuranceCheck" ></fa-icon>
            </li>
            <li>c) Client has not been certified                     
                <fa-icon [icon]="['fas', 'check']" [styles]="{'stroke': 'red', 'color': 'red'}" *ngIf="this.showRecertCheck" ></fa-icon>
            </li>
        </ul>

        <br>(Check-mark indicates that the condition applies to this client. )
        <br><br>(Direct service and referrals are disabled due to ineligibility. )
</div>
