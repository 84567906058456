import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RsrReportListResponse, RsrClientErrorResponse, RsrRequest } from '../models/rsr.models';

@Injectable({
  providedIn: 'root'
})
export class RsrService {
    // URL which returns list of JSON items (API end-point URL)
    private readonly URL = environment.apiURL + '/rsr';

    constructor(private http: HttpClient) { }
  
    public GetRSRReportList(model: Partial<RsrRequest>) {
      let url = this.URL + '/GetRSRReportList';

      return this.http.post<RsrReportListResponse[]>(url, model);
    }

    public GetRSRClientErrors(model: Partial<RsrRequest>) {
      let url = this.URL + '/GetRSRClientErrors';

      return this.http.post<RsrClientErrorResponse[]>(url, model);
    }
}