//import { DecimalPipe } from '@angular/common'; 

export interface ClientDirectServiceRequestModel {
	providerID: number;  
	clientGuid: string;
	dsGuid: string;
	month: number;
	year: number;
	filterSelectionChoice: number; // enum DirectServiceFilterSelectionChoices
}
  
export interface ClientDirectServiceModel {
    guid:string;
	barrier?:number;
	billable:boolean;
	billableUnits?:number;
	bulkGroupGuid?:string;
	clientAltId:string;
	clientGuid:string;
	cohortIntervention?:number;
	contractId?:number;
	contractGuid?:string;
	contractLineItemGuid?:string;
	deleted:boolean;
	deliveredDate:string;
	deliveredTime:string;
	month?:any;
	year?:any;
	deliveredUnits:number;
	eis?:boolean;
	employeeId?:number;
	enterDate?:any;
	funder:number;
	indirectCost:number;
	lastUpdate:any;
	nonbillable:boolean;
	nonbillableUnits?:number;
	notes:string;
	paySourceException?:number;
	paySourceId?:number;
	paySource?:string;
	paySourceReasonId?:number;
	paySourceReason?:string;
	plannedUnits:number;
	recSource:string;
	serviceId:number;
	serviceDescription:string;
	subId:number;
	subTypeDescription:string;
	supplBillable?:boolean;
	supplEligible?:boolean;
	supplReason?:number;
	suppleDate?:any;
	totalCost?:number;
	unbillableReason?:number;
	unbillableReasonDescription?:string;
	unit:number;
	unitCost?:number;
	userId:number;
	subTypeInclusionIds?:string;
	subTypeInclusionDescriptions?:string;
	contractFunder?:any;
	containsAnyTypeOfRiskReduction?:boolean;
	subTypeAssessmentId?:number;
	linkage?:boolean;
	clientMedicalRiskReduction?:Partial<ClientMedicalRiskReductionModel>;
	//clientAssessment?:ClientAssessmentModel;
	clientAssessment?:Partial<ClientAssessmentModel>;
	//clientReferral?:ClientReferralModel;
	clientReferral?:Partial<ClientReferralModel>;
}

// used for saving assessment with a referral if a referral exists
export interface ClientAssessmentReferralModel {
	clientAssessment?:Partial<ClientAssessmentModel>;
	clientReferral?:Partial<ClientReferralModel>;
}

export class ClientAssessmentReferral {
	public constructor(init?: any) {
		if (!!init) {
			Object.assign(this, init);
		}
	}	
}

export interface ClientMedicalRiskReductionModel {
	guid: string;
	clientGuid: string;
	directServiceGuid: string | null;
	riskReduction: number | null;
	riskReductionDate: any | null;
	treatmentAdherence: number | null;
	treatmentAdherenceDate: any | null;
	datetime: any | null;
	deleted: boolean | null;
	userId: number;
	lastUpdate: any | null;
	expired: any | null;
	recSource: string;
	mentalHealthScreening: number | null;
	mentalHealthScreeningDate: any | null;
	substanceAbuseScreening: number | null;
	substanceAbuseScreeningDate: any | null;
	tobaccoUse: number | null;
	tobaccoCounseling: number | null;
	tobaccoCounselingDate: any | null;
	alcoholUse: number | null;
	alcoholCounseling: number | null;
	alcoholCounselingDate: any | null;
	tobaccoCounselingLastUpdate: any | null;
	alcoholCounselingLastUpdate: any | null;
	riskReductionCounselingLastUpdate: any | null;
	treatementAdherenceCounselingLastUpdate: any | null;
	substanceAbuseScreeningLastUpdate: any | null;
	mentalHealthScreeningLastUpdate: any | null;
	tobaccoCounselingRequired: boolean | null;
	alcoholCounselingRequired: boolean | null;
	riskReductionCounselingRequired: boolean | null;
	treatementAdherenceCounselingRequired: boolean | null;
	substanceAbuseScreeningRequired: boolean | null;
	mentalHealthScreeningRequired: boolean | null;
}

export interface ClientAssessmentModel {
	guid: string;
	clientGuid: string;
	directServiceGuid: string;
	assessmentType: number;
	assessmentDate: any;
	pastHistory: boolean;
	currentSubstanceAbuse: number;
	mentalHealthIssuesIdentified: number;
	assessmentOutcome: number;
	referred: boolean;
	referralGuid: string;
	notes: string;
	datetime: any;
	deleted: boolean;
	userId: number;
	lastUpdate: any;
	expired: any;
	recSource: string;
	followUp: number;
}

export interface ClientReferralModel {
	guid: string;
	id: number;
	clientGuid: string;
	serviceId: number;
	subId: number;
	period: number;
	expiring: number;
	providerId: number;
	confirmStatus: number;
	confirmLocation: string;
	memo: string;
	deleted: boolean;
	userId: number;
	lastUpdate: any;
	datetime: any;
	expired: any;
	employeeId: number;
	referralStatus: number;
	canProvideService: number | null;
	contactedReferrer: boolean | null;
	contactedReferrerDate: any | null;
	willProvideService: number | null;
	contractGuid: string | null;
	funderId: number | null;
	canProvideRefusal: number | null;
	willProvideRefusal: number | null;
	directServiceGuid: string | null;
	outsideProviderId: number;
	recSource: string | null;
	clinicId: number | null;
	completedDate: any | null;
	completedNotes: string | null;
	referredClientId: number | null;
	lostToFollowupDate: any | null;
	lostToFollowupNote: string | null;
	statusChangeNote: string | null;
	statusChangeDate: any | null;
	sourceClinicId: number | null;
}

// export class ClientReferralOutsideProvider {
// 	public constructor(init?: any) {
// 		if (!!init) {
// 			Object.assign(this, init);
// 		}
// 	}	
// }

// export interface ClientReferralOutsideProviderModel {
// 	id: number;
// 	sourceProviderId: number;
// 	name: string;
// 	phone: string;
// 	address: string;
// 	datetime: any | null;
// }

export interface PostResponse {
	success: boolean;
	message: string;
	data: string;
}

export interface FirstPMorMCMDirectServiceModel {
	guid: string | null;
	deliveredDate: string | null;
	serviceId: number | null;
	subId: number | null;
}

export interface EISSubCategoryDatesModel {
	guid: string | null;	
	firstEISDatetime: any | null;
	firstBillableEISDatetime: any | null;
	isEISSubCategoryBillable?: boolean;
}

export interface SimilarClientDirectServiceModel {
	guid: string;
	deliveredDate: string;
	serviceId: number;
	subId: number;
	funder: number | null;
	billable: boolean | null;
}

export interface MoreThan4UnitsIn12MonthsResult {
	moreThan4UnitsIn12MonthsResult: boolean;
	billableRegardlessMissingFieldsResult: boolean;
	remainingUnits: number;
	totalDeliveredUnits: number;
}

export interface ClientDirectServicesSubsetModel {
	guid: string;
	deliveredDate: string;
	deliveredUnits: number;
	serviceId: number;
	subId: number;
	funder: number | null;
	billable: boolean | null;
}

export class ClientDirectService {
	//public constructor(init?: Partial<ClientDirectServiceModel>) {
	public constructor(init?: any) {
		if (!!init) {
			//temporarily remove dates to get past API call failing
			// delete init.dsTobaccoCounselingDate;
			// delete init.dsRiskReductionDate;
			// delete init.dsTreatementAdherenceDate;
			// delete init.dsAlcoholCounselingDate;
			// delete init.dsAssessmentDeliveredDate;

			Object.assign(this, init);
		}
	}	
    // static toPartial(init?: Partial<ClientDirectServiceModel>): Partial<ClientDirectServiceModel>
	// {
	// 	const newPartial:Partial<ClientDirectServiceModel> = {};
	// 	Object.assign(newPartial, init)
	// 	return newPartial;
    // };
}

export class ClientRiskReduction {
	public constructor(init?: any) {
		if (!!init) {
			//remove un-needed fields to get past API call failing
			delete init.lastUpdate;
			//these fields moved to another screen
			delete init.mentalHealthScreeningDate;
			delete init.mentalHealthScreeningDate;
			delete init.mentalHealthScreeningLastUpdate;
			delete init.mentalHealthScreeningRequired;
			delete init.substanceAbuseScreening;
			delete init.substanceAbuseScreeningDate;
			delete init.substanceAbuseScreeningLastUpdate;
			delete init.substanceAbuseScreeningRequired;

			Object.assign(this, init);
		}
	}	
}

export class ClientAssessment {
	public constructor(init?: any) {
		if (!!init) {
			//remove un-needed fields to get past API call failing
			delete init.deliveredDate;
			delete init.assessmentCurrentYesNo;
			delete init.lastUpdate;

			Object.assign(this, init);
		}
	}	
}

export class ClientReferral {
	public constructor(init?: any) {
		if (!!init) {
			//remove un-needed fields to get past API call failing
			delete init.lastUpdate;
			// temporary to get past API error
			// delete init.expired;
			// delete init.contactedReferrerDate;
			// delete init.completedDate;
			// delete init.lostToFollowupDate;
			// delete init.statusChangeDate;
			// delete init.datetime;

			Object.assign(this, init);
		}
	}	
}