<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-7">Other STI</div>
                <div class="field col-4"></div>
                <div class="field col-1">
                    <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('STI', 'Other STI')"></button>      
                </div>                
            </div>
        </div>

        <!-- Other STI Results section -->
        <div class="champ-card-body-sm">
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12" style="margin-right: 10px;">
                    <label style="font-weight:normal;">Result of last STI test on medical record ordered by this clinic.</label>
                    <!-- <p-toolbar styleClass="mb-4">
                        <ng-template pTemplate="left">
                            <button pButton pRipple label="New" icon="pi pi-plus" class="btn btn-primary mr-2" (click)="onRowAdd(dt)" style="width: 5rem !important; height: 2rem !important; padding: .5rem"></button>
                        </ng-template>
                    </p-toolbar>                     -->
                    <p-table #dtOtherSTI [value]="getClientMedicalOtherStiResults()" dataKey="guid" editMode="row" selectionMode="single">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:8rem"><button pButton pRipple label="New" icon="pi pi-plus" class="btn btn-primary mr-2" (click)="onRowAdd(dtOtherSTI)"  *ngIf="!clientService.readonly" [disabled]="isRowInEditMode" style="width: 5rem !important; height: 2rem !important; padding: .5rem"></button></th>                                
                                <th style="width:10rem">Date</th>
                                <th>Description</th>
                                <th>Positive Result</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-otherSti let-editing="editing" let-rowIndex="rowIndex">
                            <tr [pEditableRow]="otherSti">
                                <td style="text-align:center">
                                    <button *ngIf="!isRowInEditMode && !clientService.readonly" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit($event,otherSti, rowIndex)" class="p-button-rounded p-button-text" [disabled]="clientService.readonly" ></button>
                                    <button *ngIf="isRowInEditMode && rowIndexInEditMode == rowIndex && validSTIResults() && !clientService.readonly" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(otherSti, rowIndex)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                    <button *ngIf="isRowInEditMode && rowIndexInEditMode == rowIndex && !clientService.readonly" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(otherSti, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    <button *ngIf="!isRowInEditMode && !clientService.readonly" pButton pRipple type="button" icon="pi pi-trash" (click)="onRowDelete($event, otherSti, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>                                
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isStiRowInEditMode(rowIndex) ">
                                                <input type="text" autocomplete="off" bsDatepicker formControlName="idOtherSTIDate"
                                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" [maxDate]="maxScreenedDate"
                                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idOtherSTIDate'].invalid}" 
                                                    class="champ-date-picker" pInputText />

                                                <div *ngIf="medical.controls['idOtherSTIDate']?.invalid">
                                                    <div *ngIf="medical.controls['idOtherSTIDate']?.errors?.required"> 
                                                        <small id="idOtherSTIDate-help" class="p-invalid">Date is required.</small>
                                                    </div>
                                                    <div *ngIf="!medical.controls['idOtherSTIDate']?.errors?.required && !!medical.controls['idOtherSTIDate']?.errors"> 
                                                        <small id="idOtherSTIDate-help" class="p-invalid">{{medical.controls['idOtherSTIDate']?.errors['invalidDate']}}</small>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="!isStiRowInEditMode(rowIndex)">
                                                {{ getOtherSTIDateValue(otherSti.datetime) }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ getOtherSTIDateValue(otherSti.datetime) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isStiRowInEditMode(rowIndex)">
                                                    <p-dropdown [options]="otherSTIDescriptions" optionLabel="label" optionValue="value" formControlName="idOtherSTIDescription" 
                                                                [style]="{'width':'100%'}" appendTo="body"></p-dropdown>

                                                <div *ngIf="medical.controls['idOtherSTIDescription']?.errors"> 
                                                    <small id="idOtherSTIDescription-help" class="p-invalid">Description is required.</small>
                                                </div>
                                            </div>
                                            <div *ngIf="!isStiRowInEditMode(rowIndex) ">
                                                {{ getOtherSTIDescription(otherSti.id) }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ getOtherSTIDescription(otherSti.id) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>                                
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isStiRowInEditMode(rowIndex)">
                                                <div class="field-checkbox" >
                                                    <p-checkbox formControlName="idOtherSTIPositive" [style]="{'width': '100%', 'margin-top': '1rem'}" binary="true" ></p-checkbox>
                                                </div>                   
                                            </div>       
                                            <div *ngIf="!isStiRowInEditMode(rowIndex) ">
                                                <div *ngIf="otherSti.positive">
                                                    <fa-icon class="champ-field-checked" [icon]="['fas', 'check']"></fa-icon> 
                                                </div>
                                            </div>                  
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <!-- {{ (clientMedicalOtherStiResult.positive) }} -->
                                            <div *ngIf="otherSti.positive">
                                                <fa-icon class="champ-field-checked" [icon]="['fas', 'check']"></fa-icon> 
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>                    
                </div>
            </div>
        </div>

    </div>
</form>

<app-history ></app-history>