<form [formGroup]='demographics' autocomplete="off">
    <div class="p-fluid p-formgrid grid">
        <div class="field col-12 xl:col-6">
            <div class="card champ-card-sm">
                <div class="champ-card-header-sm" >Demographics </div>
                <div class="champ-card-body-sm" >
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-6">
                            <span style="vertical-align:text-top">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idEthnicity">Ethnicity</label> 
                                <div class="formgroup-inline">
                                    <p-dropdown formControlName="idEthnicity" [options]="ethnicities" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}" (onChange)="onChangeEthnicity($event)" > </p-dropdown>      
                                </div>
                                <div  *ngIf="demographics.controls['idEthnicity'].invalid && (demographics.controls['idEthnicity'].dirty || demographics.controls['idEthnicity'].touched)"> 
                                    <div *ngIf="demographics.controls['idEthnicity'].errors.required || demographics.controls['idEthnicity'].errors['invalidSelection']"> 
                                        <small id="idEthnicity-help" class="p-invalid">Ethnicity is required.</small>
                                    </div>
                                </div>
                            </span>
                        </div> 

                        <div class="field col-6">
                            <span style="vertical-align:text-top">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idRace">Race</label> 
                                <div class="formgroup-inline">
                                    <p-dropdown formControlName="idRace" [options]="races" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem','min-width':'15rem'}" (onChange)="onChangeRace($event)" > </p-dropdown>      
                                </div>
                                <div  *ngIf="demographics.controls['idRace'].invalid && (demographics.controls['idRace'].dirty || demographics.controls['idRace'].touched)"> 
                                    <div *ngIf="demographics.controls['idRace'].errors.required || demographics.controls['idRace'].errors['invalidSelection']"> 
                                        <small id="idRace-help" class="p-invalid">Race is required.</small>
                                    </div>
                                </div>
                            </span>
                        </div> 

                        <div class="field col-6">
                            <span style="vertical-align:text-top">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idRegionOrigin">Hispanic Region of Origin</label> 
                                <div class="formgroup-inline">
                                    <p-dropdown formControlName="idRegionOrigin" [options]="regions" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}" (onChange)="onChangeRegion($event)" > </p-dropdown>      
                                </div>
                                <div  *ngIf="demographics.controls['idRegionOrigin'].invalid && (demographics.controls['idRegionOrigin'].dirty || demographics.controls['idRegionOrigin'].touched)"> 
                                    <div *ngIf="demographics.controls['idRegionOrigin'].errors.required || demographics.controls['idRegionOrigin'].errors['invalidSelection'] "> 
                                        <small id="idRegionOrigin-help" class="p-invalid">Region of Origin is required.</small>
                                    </div>
                                </div>
                            </span>
                        </div> 

                        <div class="field col-6">
                            <span style="vertical-align:text-top">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idSexualOrientation">Sexual Orientation</label> 
                                <div class="formgroup-inline">
                                    <p-dropdown formControlName="idSexualOrientation" [options]="sexualOrientations" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}" (onChange)="onChangeSexualOrientation($event)" > </p-dropdown>      
                                </div>
                                <div  *ngIf="demographics.controls['idSexualOrientation'].invalid && (demographics.controls['idSexualOrientation'].dirty || demographics.controls['idSexualOrientation'].touched)"> 
                                    <div *ngIf="demographics.controls['idSexualOrientation'].errors.required || demographics.controls['idSexualOrientation'].errors['invalidSelection'] "> 
                                        <small id="idSexualOrientation-help" class="p-invalid">Sexual Orientation is required.</small>
                                    </div>
                                </div>
                            </span>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
        

        <div class="field col-12 xl:col-6">
            <div class="card champ-card-sm">
                <div class="champ-card-header-sm" >HIV & AIDS / Client Information </div>
                <div class="champ-card-body-sm" >
                    <div class="p-fluid p-formgrid grid">

                        <div class="field col-6">
                            <span style="vertical-align:text-top">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idReferalSource">Referral Source</label>
                                <label id="idReferalSourceExpire">
                                    <app-client-expired-field *ngIf="!!this.clientData" [fieldId]="MasterFields.ReferralSource" 
                                    [lastUpdated]="this.clientData.clientReferralSource?.datetime"   [control]="demographics.controls['idReferalSource']" ></app-client-expired-field>
                                </label>
                                <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;" (click)="openHistoryWindow('REFSO', 'Referal Source')"></button>      
                                <p-dropdown formControlName="idReferalSource" [options]="referralSources" optionLabel="description" optionValue="id" appendTo="body" 
                                        [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}"  > </p-dropdown> 
                                <div  *ngIf="demographics.controls['idReferalSource'].invalid && (demographics.controls['idReferalSource'].dirty || demographics.controls['idReferalSource'].touched)"> 
                                    <div *ngIf="demographics.controls['idReferalSource'].errors.required"> 
                                        <small id="idReferalSource-help" class="p-invalid">Referral Source is required.</small>
                                    </div>
                                    <div *ngIf="!demographics.controls['idReferalSource'].invalid && demographics.controls['idReferalSource'].errors['invalidSelection']"> 
                                        <small id="idReferalSource-help" class="p-invalid">Referral Source is invalid.</small>
                                    </div>
                                </div>
                            </span>
                        </div> 

                        <div class="field col-6">
                            <span style="vertical-align:text-top">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idClientStatus">Client Status</label>
                                <label id="idClientStatusExpire">
                                    <app-client-expired-field *ngIf="!!this.clientData" [fieldId]="MasterFields.ClientStatus"
                                     [lastUpdated]="this.clientData.clientStatus?.datetime" [control]="demographics.controls['idClientStatus']"></app-client-expired-field>
                                </label>                                
                                <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;" (click)="openHistoryWindow('CS', 'Client Status')"></button>      
                                <p-dropdown formControlName="idClientStatus" [options]="clientStatuses" optionLabel="description" optionValue="id" appendTo="body" 
                                        [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}" > </p-dropdown> 
                                <div *ngIf="demographics.controls['idClientStatus'].invalid && (demographics.controls['idClientStatus'].dirty || demographics.controls['idClientStatus'].touched)"> 
                                      <div *ngIf="demographics.controls['idClientStatus'].errors.required || demographics.controls['idClientStatus'].errors['invalidSelection'] "> 
                                        <small id="idClientStatus-help" class="p-invalid">Client Status is required.</small>
                                    </div>

                                </div>
                            </span>
                        </div> 

                        <div class="field col-12">
                            <div class="formgroup-inline" style="margin-top: 15px; margin-bottom: 15px ;">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(demographics.controls['idDiagnosedLast12Months'])"></fa-icon>
                                <div class="field col-4">
                                        <label for="idDiagnosedLast12Months" style="font-weight:normal">Diagnosed in the last 12 months?</label>
                                        <!-- <p-checkbox [style]="{'width': '30px', 'padding': '0px 0px 0px 7px'}" formControlName="idDiagnosedLast12Months" binary="true" inputId="idDiagnosedLast12Months"></p-checkbox>
                                        <label for="dsTobaccoYesNo">Is this patient using Tobacco products?</label> style="margin-right: 1rem; margin-left: 1rem; margin-bottom: 1rem;" -->
                                </div> 

                                <div class="field col-1"><p-radioButton formControlName="idDiagnosedLast12Months" value=1 label="Yes"></p-radioButton></div>
                                <div class="field col-1"><p-radioButton formControlName="idDiagnosedLast12Months" value=2 label="No"></p-radioButton></div>
                                <div class="field col-1"><p-radioButton formControlName="idDiagnosedLast12Months" value=3 label="Affected"></p-radioButton></div>

                            </div>
                            <div  *ngIf="demographics.controls['idDiagnosedLast12Months'].invalid && (demographics.controls['idDiagnosedLast12Months'].dirty || demographics.controls['idHIVDiagnosis'].touched)"> 
                                <div *ngIf="demographics.controls['idDiagnosedLast12Months'].errors.required"> 
                                    <small id="idDiagnosedLast12Months-help" class="p-invalid">Diagnosis Last 12 months is required.</small>
                                </div>
                            </div> 
                        </div>    
    
                        <div class="field col-6"  *ngIf="demographics.controls['idDiagnosedLast12Months'] === null ? false :demographics.controls['idDiagnosedLast12Months'].value == 1"> 
                           <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                           <span style="vertical-align:text-top"><label htmlFor="idHIVDiagnosis">HIV Diagnosis (Date)</label></span>
                           <div class="formgroup-inline">
                               <input type="text"  #hivDiagDatePicker name="idHIVDiagnosis" autocomplete="off" bsDatepicker formControlName="idHIVDiagnosis"
                                       [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idHIVDiagnosis"    
                                       placeholder="MM-DD-YYYY" style="width:90px"  [maxDate]="maxHIVDiagDate" [minDate]="minHIVDiagDate"
                                       [ngClass]="{'p-invalid' : demographics.controls['idHIVDiagnosis'].invalid}" 
                                       class="champ-date-picker" pInputText
                                       (change)="onChangeHIVDiagnosisDate($event)"
                                       />
                                       <!-- [maxDate]="maxHIVDiagDate" [minDate]="minHIVDiagDate" -->
                           </div>
                           <div  *ngIf="demographics.controls['idHIVDiagnosis'].invalid && (demographics.controls['idHIVDiagnosis'].dirty || demographics.controls['idHIVDiagnosis'].touched)"> 
                               <div *ngIf="demographics.controls['idHIVDiagnosis'].errors.required"> 
                                   <small id="idHIVDiagnosis-help" class="p-invalid">HIV Diagnosis Date is required.</small>
                               </div>
                               <div  *ngIf="demographics.controls['idHIVDiagnosis'].errors"> 
                                    <small id="idHIVDiagnosis-help" class="p-invalid">{{demographics.controls['idHIVDiagnosis'].errors['invalidDate']}}</small>
                                </div> 
                           </div> 
                       </div>

                       <div class="field col-6"   *ngIf="demographics.controls['idDiagnosedLast12Months'] === null ? false : demographics.controls['idDiagnosedLast12Months'].value == 1"> 
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"  *ngIf="validationHelper.hasRequiredField(demographics.controls['idAIDSDiagnosis'])" ></fa-icon>  <!--  *ngIf="demographics.controls['idAIDSDiagnosis'].enabled" -->
                            <span style="vertical-align:text-top"><label htmlFor="idAIDSDiagnosis">AIDS Diagnosis (Date)</label></span>
                            <div class="formgroup-inline">
                                <input type="text"  #aidsDiagDatePicker  name="idAIDSDiagnosis" autocomplete="off" bsDatepicker formControlName="idAIDSDiagnosis"
                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idAIDSDiagnosis"    
                                        placeholder="MM-DD-YYYY" style="width:90px" [maxDate]="maxAidsDiagDate" [minDate]="minAidsDiagDate"
                                        [ngClass]="{'p-invalid' : demographics.controls['idAIDSDiagnosis'].invalid}" 
                                        class="champ-date-picker" pInputText/>
                                 <!-- <p-inputMask mask="99/99/9999" formControlName="idAIDSDiagnosis" placeholder="99/99/9999" slotChar="mm/dd/yyyy"></p-inputMask> -->
                            </div>
                            <div  *ngIf="demographics.controls['idAIDSDiagnosis'].invalid   && (demographics.controls['idAIDSDiagnosis'].dirty || demographics.controls['idAIDSDiagnosis'].touched)" >
                                <div *ngIf="demographics.controls['idAIDSDiagnosis'].errors.required"> 
                                    <small id="idAIDSDiagnosis-help" class="p-invalid">AIDS Diagnosis Date is required.</small>
                                </div>
                                <div  *ngIf="demographics.controls['idAIDSDiagnosis'].errors"> 
                                    <small id="idAIDSDiagnosis-help" class="p-invalid">{{demographics.controls['idAIDSDiagnosis'].errors['invalidDate']}}</small>
                                </div> 
                            </div> 
                        </div>


                        <div class="field col-6"  *ngIf="demographics.controls['idDiagnosedLast12Months'] === null ? false :demographics.controls['idDiagnosedLast12Months'].value == 2"> 
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                            <span style="vertical-align:text-top"><label htmlFor="idHIVDiagnosisYear">HIV Diagnosis (Year)</label></span>
                            <div style="width: 5rem;">
                                <p-inputMask formControlName="idHIVDiagnosisYear" inputId="idHIVDiagnosisYear" mask="9999" maxlength="4"></p-inputMask>
                            </div>
                            <div *ngIf="demographics.controls['idHIVDiagnosisYear'].invalid && (demographics.controls['idHIVDiagnosisYear'].dirty || demographics.controls['idHIVDiagnosisYear'].touched)">
                                <div *ngIf="demographics.controls['idHIVDiagnosisYear'].errors.required">
                                    <small id="idHIVDiagnosisYear-help" class="p-invalid">HIV Diagnosis year is required</small>
                                </div>
                                <div  *ngIf="demographics.controls['idHIVDiagnosisYear'].errors"> 
                                    <small id="idHIVDiagnosisYear-help" class="p-invalid">{{demographics.controls['idHIVDiagnosisYear'].errors['invalidDate']}}</small>
                                </div>                                 
                            </div>
                        </div>     


                        <div class="field col-6" *ngIf="demographics.controls['idDiagnosedLast12Months'] === null ? false :demographics.controls['idDiagnosedLast12Months'].value == 2"  >   <!-- disabled ="demographics.controls['idHIVStatus'].value != 8 " -->
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(demographics.controls['idAIDSDiagnosisYear'])"></fa-icon>   <!-- demographics.controls['idAIDSDiagnosisYear'].enabled" -->
                            <span style="vertical-align:text-top"><label htmlFor="idAIDSDiagnosisYear">AIDS Diagnosis (Year)</label></span>
                            <div style="width: 5rem;">
                                <p-inputMask formControlName="idAIDSDiagnosisYear" inputId="idAIDSDiagnosisYear" mask="9999" maxlength="4"></p-inputMask>
                            </div>
                            <div *ngIf="demographics.controls['idAIDSDiagnosisYear'].invalid && (demographics.controls['idAIDSDiagnosisYear'].dirty || demographics.controls['idAIDSDiagnosisYear'].touched)">
                                <div *ngIf="demographics.controls['idAIDSDiagnosisYear'].errors.required">
                                    <small id="idAIDSDiagnosisYear-help" class="p-invalid">AIDS Diagnosis year is required</small>
                                </div>
                            </div>
                            <div  *ngIf="demographics.controls['idAIDSDiagnosisYear'].errors"> 
                                <small id="idAIDSDiagnosisYear-help" class="p-invalid">{{demographics.controls['idAIDSDiagnosisYear'].errors['invalidDate']}}</small>
                            </div>                              
                        </div>     


                        <div class="field col-12" *ngIf="demographics.controls['idDiagnosedLast12Months'] === null ? false :demographics.controls['idDiagnosedLast12Months'].value != 3">
                            <div class="formgroup-inline" style="margin-top: 15px; margin-bottom: 15px ;">
                                <div class="field col-6">
                                        <label for="EISElligble" style="font-weight:normal">Eligible for EIS: {{  demographics.controls['idEISEligible'].value }} </label>  
                                </div>
                            </div>
                        </div>  


                        <div class="field col-6"  *ngIf="demographics.controls['idDiagnosedLast12Months'] === null ? false :demographics.controls['idDiagnosedLast12Months'].value != 3">
                            <span style="vertical-align:text-top">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" ></fa-icon>  <!--  ( PM, MCM )  = clinic (PM, MCM) -->
                                <label htmlFor="idHIVStatus">HIV Status</label>
                                <label id="idHIVStatusExpire">
                                    <app-client-expired-field *ngIf="!!this.clientData" [fieldId]="MasterFields.HIVStatus" 
                                    [lastUpdated]="this.clientData.clientHivStatus?.datetime" [control]="demographics.controls['idHIVStatus']"></app-client-expired-field>
                                </label>
                                <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;" (click)="openHistoryWindow('HIVSTAT', 'HIV Status')" ></button>  
                                <p-dropdown formControlName="idHIVStatus" [options]="hivStatuses" optionLabel="description" optionValue="id" appendTo="body" 
                                        [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}" > </p-dropdown>     
                                <div  *ngIf="demographics.controls['idHIVStatus'].invalid && (demographics.controls['idHIVStatus'].dirty || demographics.controls['idHIVStatus'].touched)"> 
                                    <div *ngIf="demographics.controls['idHIVStatus'].errors.required || demographics.controls['idHIVStatus'].errors['invalidSelection'] "> 
                                        <small id="idHIVStatus-help" class="p-invalid">HIV Status is required.</small>
                                    </div>                                    
                                </div>
                            </span>
                        </div> 

                        <div class="field col-12"  *ngIf=" demographics.controls['idDiagnosedLast12Months'] === null ? false :demographics.controls['idDiagnosedLast12Months'].value != 3">  <!--  this.clientData && this.clientData.clientTranModes.length &&     -->
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" ></fa-icon>
                            <label for="idTransmissionModes">Transmission Modes</label>
                            <label id="idTransmissionModesExpire">
                                <app-client-expired-field *ngIf="!!this.clientData && !!this.clientData.clientTranModes" [fieldId]="MasterFields.TransmissionModes" 
                                [lastUpdated]="this.clientData.clientTranModes[0]?.datetime" 
                                [control]="demographics.controls['idTransmissionModes']"></app-client-expired-field>  <!--   -->
                            </label>                            
                            <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;"  (click)="openHistoryWindow('TRANMODE', 'Transmission Mode')"></button>  

                            <ng-multiselect-dropdown name="idTransmissionModes" formControlName="idTransmissionModes"
                                [settings]="multiCheckDropDownSettings" 
                                [data]=tranmissionModes 
                                [(ngModel)]="tranModesSelectedItems" 
                                [disabled]="clientService.readonly"
                            >
                            <!--   -->
                            </ng-multiselect-dropdown>

                            <div  *ngIf="demographics.controls['idTransmissionModes'].invalid && (demographics.controls['idTransmissionModes'].dirty || demographics.controls['idTransmissionModes'].touched)"> 
                                <div *ngIf="demographics.controls['idTransmissionModes'].errors.required || demographics.controls['idTransmissionModes'].errors['idTransmissionModes'] "> 
                                    <small id="idTransmissionModes-help" class="p-invalid">Transmission mode is required.</small>
                                </div>                                    
                            </div>
                            
                        </div>   

                        <!--
                        (onSelect)="onSubTypeInclusionItemSelect($event)"
                        (onSelectAll)="onSubTypeInclusionSelectAll($event)"
                        (onDeSelect)="onSubTypeInclusionItemDeSelect($event)"
                        (onDeSelectAll)="onSubTypeInclusionDeSelectAll($event)"  -->


                    </div> 
                
                </div>
            </div>
        </div>



        <!-- <div class="field col-12 xl:col-6">
            <div class="card ">
                Form value : {{demographics.value | json}}
            </div>
        </div>   -->

    </div>

</form>



<!-- <app-history [clientGuid] = "clientData.guid"   [clientAltID] = "clientData.clientAltId"   [dataElement] = "historyElement" ></app-history> -->

<app-history ></app-history> 
