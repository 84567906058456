import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RecertificationErrors, EligibilityAlertErrors, PaySource, HIVIndeterminateStatusResult, HIVIndeterminateStatus, 
         RequiredFieldResult, RequiredFieldGroup, LiveIn } from "src/app/models/enums";
import { ClientFormService } from '../services/client-form.service';


@Component({
  selector: 'app-eligibilty-popup',
  templateUrl: './eligibilty-popup.component.html',
  styleUrls: ['./eligibilty-popup.component.scss']
})
export class EligibiltyPopupComponent implements OnInit {
  params: any = {};
  showFPLCheck: boolean= false;
  showInsuranceCheck: boolean= false;
  showRecertCheck: boolean= false;
 
  constructor(public  clientService: ClientFormService, 
          private diagConfig: DynamicDialogConfig
          ) { }

  ngOnInit(): void {
    this.params = this.diagConfig.data;
    //console.log((EligibilityAlertErrors.FPLOver500 & this.params.eligibilityAlert));
    this.showFPLCheck = (EligibilityAlertErrors.FPLOver500 & this.params.eligibilityAlert) == EligibilityAlertErrors.FPLOver500;
    this.showInsuranceCheck = (EligibilityAlertErrors.FPLUnder139orNoInsuranceandNoMedicaid & this.params.eligibilityAlert) == EligibilityAlertErrors.FPLUnder139orNoInsuranceandNoMedicaid;
    this.showRecertCheck = (EligibilityAlertErrors.NotCertified & this.params.eligibilityAlert) == EligibilityAlertErrors.NotCertified;

    //if (((this.clientService.isClientEligible() & EligibilityAlertErrors.FPLUnder139orNoInsuranceandNoMedicaid) == EligibilityAlertErrors.FPLUnder139orNoInsuranceandNoMedicaid) &&
      

  }

}
