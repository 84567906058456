import { Component, ViewChild } from '@angular/core';
import { RsrService } from '../rsr.services';
import { RsrRequest, RsrReportListResponse} from '../../models/rsr.models';
import { AppContextService } from '../../app-context/app-context.service';
import { SpinnerService } from '../../common/spinner/spinner.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { RsrClientErrorComponent } from '../rsr-client-error/rsr-client-error.component';

@Component({
  selector: 'app-rsr-list',
  standalone: false,
  //imports: [],
  templateUrl: './rsr-list.component.html',
  styleUrl: './rsr-list.component.scss',
  providers: [MessageService, ConfirmationService, DialogService]
})

export class RsrListComponent {
  @ViewChild(RsrClientErrorComponent) rsrClientErrorComponent: RsrClientErrorComponent;
  rsrReportList: RsrReportListResponse[];
  getRsrReportList = () => this.rsrReportList;

  constructor(private rsrService: RsrService,
              public app: AppContextService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,              
              private spinnerService: SpinnerService) {
  }

  ngOnInit(): void {
    this.initialize();
  }

  initialize() {
    var model : RsrRequest = { providerId: this.app.userServ.CurrentProviderID, fundYear: "CY2024", clinicId: null, funderId: null, isAdmin: false };

    this.spinnerService.showSpinnerUntilCompleted(this.rsrService.GetRSRReportList(model)).subscribe({
      next: (result: RsrReportListResponse[]) => {
        this.rsrReportList = result;
      },
      error: (e) => { //HttpErrorResponse
        this.app.userServ.LogError(e);
        this.messageService.add({ severity: 'error', summary: 'Error', detail: e.error.message, sticky: true });
      },
      complete: () => {
        //this.messageService.add({severity: 'info', summary: 'Completed', detail: "Executed sucessfully."});
      }
    });
  }

  openClientErrors(rsrRow: RsrReportListResponse) {
    this.rsrClientErrorComponent.onShow(rsrRow)
  }
}
