import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ReportConfigParamSQLValueModel, ReportConfigReportsModel, ReportsModel, ReportsRequestModel, ReportConfigContractDates, ReportConfigGroupListModel} from 'src/app/models/reports.model'

@Injectable({
  providedIn: 'root'
})
export class ReportsService {
   private readonly URL = environment.apiURL + '/reports';

   constructor(private http: HttpClient, private router:Router) { }

   public runReport(request: ReportsModel) 
   { 
      let url  = this.URL + "/RunReport"; 
      //let options = { headers: { "Content-Type": "application/json", Accept: "application/pdf" } };
      //return this.http.post(url, request, options);

      return this.http.post(url, request, {responseType: 'blob'});
   }

   public getReport(request: ReportsRequestModel) 
   { 
      let url  = this.URL +"/GetReportData"; 
      return this.http.post<ReportConfigReportsModel>(url, request);
   }

   public getReportList(menuId: Number) 
   { 
      let url  = this.URL +"/GetReportList"; 
      let params = new HttpParams();
      params = params.append('menuId', menuId.toString());     
      return this.http.get<ReportConfigGroupListModel[]>(url, { params:params });
   }

   public getContractsForProvider(providerId: string )
   { 
      let url  = this.URL +"/getContractsForProvider"; 
      let params = new HttpParams();
      params = params.append('providerId', providerId);

      return this.http.get<ReportConfigParamSQLValueModel[]>(url, { params:params });
   }

   public getContractsDateByGuid(guid: string )
   { 

      let url  = this.URL +"/getContractDates"; 
      let params = new HttpParams();
      params = params.append('guid', guid);

      return this.http.get<ReportConfigContractDates>(url, { params:params });
   }

   public getClientList(guid: string )
   { 
      let url  = this.URL +"/getClientList"; 
      let params = new HttpParams();
      params = params.append('guid', guid);

      return this.http.get<ReportConfigParamSQLValueModel[]>(url, { params:params });
   }
}
