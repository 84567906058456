<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<!-- <p-dialog [(visible)]="showUserDetailDialog"> -->
<form [formGroup]='fg' autocomplete="off">
    <div class="p-container-fluid champ-card-body-sm"> <!--  style="width: 98%;margin-top: 2rem;"-->
        <!-- <div class="card-header champ-card-header-sm" style="width: 99%;">{{ getProductGroupHeader() }}</div> -->
        <!-- <div>{{ fg.value | json }}</div>
        <div> VALID: {{ fg.valid }} </div>
        <div> DIRTY: {{ fg.dirty}}</div>
        <div> PWD Dirty: {{ fg.controls['password'].dirty }} </div> -->
        <!-- <div> Cfm pwd Valid: {{ fg.controls['passwordConfirm'].valid }} </div> -->
        
        <!-- <div class="p-card champ-card" style="width: 100%;"> -->
            <!-- <div class="p-card-body champ-card-body"> -->
                <div class="p-fluid p-formgrid p-1">
                    <!-- row #1 -->
                    <div class="col-12 p-1 " >
                        <div class="grid">
                            <div class="field col-3">
                                <label for="username" style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%"  > <!-- -->
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> User Name
                                </label>
                                <input id="username" type="name" formControlName="name"  pInputText    /> <!-- autocomplete="doNotAutoComplete" -->
                            </div>

                            <div class="field col-4">  
                                <div class="grid">
                                    <div class="field col-6">  
                                        <label for="userpassword " style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%"  >  <!--   style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%" -->
                                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Password
                                        </label>
                                        <p-password id="userpassword"  formControlName="password" autocomplete="doNotAutoComplete"  [toggleMask]="true"  />
                                    </div>
                                    <div class="field col-6">  
                                        <div *ngIf="fg.controls['password'].dirty" >
                                            <label for="userPasswordConfirm " style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%"  >  <!--   style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%" -->
                                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Confirm Password
                                            </label>
                                            <p-password id="userPasswordConfirm" formControlName="passwordConfirm" autocomplete="doNotAutoComplete"  [toggleMask]="true" />
                                        </div>
                                    </div>
                                </div>

                                <p id="trash-desc"  style="font-weight: bold; font-size: 10px;color: blue;">Only applies to CHAMP3.5, Firebase Password used for CHAMP4</p>
                            </div>
                           
                            <!-- <div class="field col-2">  
                                <div *ngIf="fg.controls['password'].dirty" >
                                    <label for="userPasswordConfirm " style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%"  > 
                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Confirm Password
                                    </label>
                                    <p-password id="userPasswordConfirm" formControlName="passwordConfirm" autocomplete="off"  [toggleMask]="true" />
                                </div>
                            </div>  -->

                            <div class="field col-5">
                                <label for="emailAddress" style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%">
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Email Address
                                </label>
                                <input id="emailAddress" type="email" formControlName="emailAddress"  pInputText aria-describedby="email-help"/>
                                <p id="trash-desc"  style="font-weight: bold; font-size: 10px;color: blue;">CHAMP4 Note: Email must be added to FireBase for authentication</p>
                            </div>   

                        </div>
                    </div>
                    <!-- row #2 -->
                    <div class="col-12 p-1" >
                        <div class="grid">

                            <div class="field col-4">
                                <label for="firstname" style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%">
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> First Name
                                </label>
                                <input id="firstname" type="text" formControlName="firstName"   pInputText />
                            </div>           

                            <div class="field col-4">
                                <label for="middleName" style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%">
                                     Middle Name
                                </label>
                                <input id="middleName" type="text" formControlName="middleName"   pInputText />  
                            </div>           

                            <div class="field col-4"  >
                                <label for="lastName" style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%">
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Last Name
                                </label>
                                <input id="lastName" type="text" formControlName="lastName" pInputText />
                            </div>         

                            <div class="field col-1" style="width: 10.5%; flex: 0 0 10.5%;max-width: 10.5%;">
                                <!-- <label for="termDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem; width:100%">Term Date</label>
                                <input type="text" autocomplete="off" bsDatepicker formControlName="termDate"
                                [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers: false }"
                                placeholder="MM/DD/YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : fg.controls['termDate'].invalid}" 
                                class="rmt-date-picker" pInputText appendTo="body"/>

                                <div *ngIf="fg.controls['termDate'].invalid"> 
                                    <div *ngIf="fg.controls['termDate'].errors?.required"> 
                                        <small id="termDate-help" class="rmt-invalid p-invalid-grid">Effective Date is required.</small>
                                    </div>
                                    <div *ngIf="fg.controls['termDate'].errors['invalidDate']">
                                        <small id="termDate2-help" class="rmt-invalid p-invalid-grid">{{fg.controls['termDate'].errors['invalidDate']}}</small>
                                    </div>
                                </div>                                 -->
                            </div>
                            <div class="field col-1">
                                <!-- <label for="active" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Active</label>
                                <div class="field-checkbox">
                                    <p-checkbox formControlName="active" binary="true" inputId="active"></p-checkbox>
                                </div> -->
                            </div>
                            <!-- <div class="field col-3"></div> -->
                            <!-- <div class="field col-2" *ngIf="!isProductUnderwriterOverrides"></div>  -->
                            <!-- need for placeholder when not an underwriter override product -->
                        </div>
                    </div>
                    <!-- row #3 -->
                    <div class="col-12 p-1" >
                        <div class="grid">
                            <div class="field col-2">
                                <label for="adminPrivilege" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Administrative Privilege</label>
                                <div class="field-checkbox">
                                    <p-checkbox formControlName="adminPrivilege" binary="true" inputId="adminPrivilege"></p-checkbox>
                                </div>
                            </div>

                            <div class="field col-2">
                                <label for="granteePrivilege" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Grantee Privilege</label>
                                <div class="field-checkbox">
                                    <p-checkbox formControlName="granteePrivilege" binary="true" inputId="granteePrivilege"></p-checkbox>
                                </div>
                            </div>

                            <div class="field col-2">
                                <label for="editPrivilege" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Edit Privilege</label>
                                <div class="field-checkbox">
                                    <p-checkbox formControlName="editPrivilege" binary="true" inputId="editPrivilege"></p-checkbox>
                                </div>
                            </div>
                            
                            <div class="field col-2">
                                <label for="readOnlyPrivilege" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Read Only Privilege</label>
                                <div class="field-checkbox">
                                    <p-checkbox formControlName="readOnlyPrivilege" binary="true" inputId="readOnlyPrivilege"></p-checkbox>
                                </div>
                            </div>

                            <div class="field col-2">
                                <label for="accountEnabled" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Acount Enabled</label>
                                <div class="field-checkbox">
                                    <p-checkbox formControlName="accountEnabled" binary="true" inputId="accountEnabled"></p-checkbox>
                                </div>
                            </div>

                            <div class="field col-2">
                                <label for="deleted" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Deleted</label>
                                <div class="field-checkbox">
                                    <p-checkbox formControlName="deleted" binary="true" inputId="deleted"></p-checkbox>
                                </div>
                            </div>

                            <!-- <div class="field col-3"></div> -->
                            <!-- <div class="field col-2" *ngIf="!isProductUnderwriterOverrides"></div>  -->
                            <!-- need for placeholder when not an underwriter override product -->
                        </div>
                    </div>
                    <!-- row #4 [disabled]="!fg.valid" -->
                    <div class="col-12 p-1" >
                        <div class="grid flex  align-items-start ">
                            <div class="field col-6"   >

                                @if(isNewUser){
                                    <small id="termDate2-help" class="p-warning">Please save user prior to adding employee</small>
                                }
                                @else {
                                    <app-employee mode="user"></app-employee>
                                }
                            </div>

                            <div class="col-4">
                                <app-user-provider-clinic-perm (onRefresh)="onProvidersClincPermRefresh($event)"  style="display: block; position: relative;top: 1rem;"></app-user-provider-clinic-perm> 
                            </div>
            
                            <div class="field col-2 flex  align-items-end ">
                                    <!-- <button pButton pRipple class="mt-4 mr-2"  icon="pi pi-check"  (click)="onSaveUser($event)" label="Save"  
                                       
                                        [disabled]="!(fg.valid && this.isProviderUserValid()  )" >  
                                    </button> -->
                                    <!-- {{ fg.valid  }}
                                    {{ this.isProviderUserValid() }} -->
                                    <p-button  pButton pRipple type="button" [ngStyle]="{'width':'12rem'}"   styleClass="p-button-text " class=" p-button-text mr-1 "
                                        (click)="onSaveUser($event)" [disabled]="!(fg.valid && this.isProviderUserValid()  )" > 
                                        <i class="fa-sharp fa-regular fa-cloud-arrow-up fa-xl" ></i><span class="text-lg">Save</span>
                                     </p-button>    

                                    <!-- A{{ this.user?.providerUsers?.length  }}
                                    B{{ this.isProviderUserValid() }}
                                    C{{ this.getAdminPrivilegeControl().value  }}
                                    D{{ this.getGranteePrivilegeControl().value }}

                                    E{{  ( this.getAdminPrivilegeControl().value || this.getGranteePrivilegeControl().value)  
                                        ||  this.user?.providerUsers?.length >  0}} -->

                                    <!-- styleClass="p-button-text note-button font-bold" class="p-button-help p-button-text "  -->
                                    <!--      && this.isProviderUserValid()      !(fg.valid ||    (!fg.valid && fg.controls['password'].dirty))  -->

                                    <!-- <p-button  pButton pRipple type="button" [ngStyle]="{'width':'15rem'}" styleClass="p-button-text note-button" class="p-button-help p-button-text mr-1 "
                                    (click)="onSaveUser($event)"  [disabled]="!(fg.valid && this.isProviderUserValid()  )"> 
                                    <i class="fa-sharp  fa-check  fa-lg" style="color:blue"  ></i>
                                    <span class="ml-2 font-bold">Save</span>
                                    </p-button> -->


                                    <!-- [ngClass]="'champ-button'" -->
                                    <!-- <p-button   label="New" [text]="true" icon="pi pi-plus" (click)="onEditUser($event,null)" [disabled]="isRowInEditMode" styleClass="p-button-text note-button font-bold" class="p-button-help p-button-text mr-1 " ></p-button> -->
                            
                            </div>
                        </div>
                    </div>
                        <!-- row #5 [disabled]="!fg.valid" -->
                    <!-- <div class="col-5 p-1" >
                        
                    </div>
                    <div class="col-7 p-1" >
                    </div> -->


                </div>
            </div>
        <!-- </div> -->

    <!-- </div> -->
  </form>
<!-- </p-dialog> -->

