import { Component, OnInit, Inject, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { ProviderContractItemsRequestModel, ProviderContractMaintenanceModel, ProviderContractItemsModel, ProviderContractItemsModelPostResponse, DropDownItem } from 'src/app/models/admin.models';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginService } from 'src/app/login/services/login.service';
import { ContractMaintenanceService } from '../contract-maintenance/contract-maintenance.service';
import { sortBy, orderBy, cloneDeep } from 'lodash';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { ServiceCategory } from '../../models/common-data.model';
import { CommonDataService } from '../../services/common-data.service';
import { ProviderService } from '../../services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { faTruckMedical } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-contract-items',
  templateUrl: './contract-items.component.html',
  styleUrls: ['./contract-items.component.scss','../admin-styles.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class ContractItemsComponent {

  @Output("onChange") refreshContractList: EventEmitter<number> = new EventEmitter();
  //contractAmount: FormControl<any>;
  fg: UntypedFormGroup;
  params: Partial<ProviderContractMaintenanceModel> = {};
  providerContractsItems: any; //ProviderContractItemsResponseModel[];
  //contractItem: Partial<ProviderContractItemsResponseModel> = {};

  funders: any[];
  serviceCategories: ServiceCategory[];
  subTypes: any[] = [];
  units: any[];

  isRowNew: boolean = false;
  isRowInEditMode: boolean = false;
  rowIndexInEditMode = -1;

  contractAmountNotEqual: boolean = false;

  getGuidControl = () => this.fg?.get('guid');
  getContractItemNoControl = () => this.fg?.get('contractItemNo');
  getContractIdControl = () => this.fg?.get('contractId');
  getStartDateControl = () => this.fg?.get('startDate');
  getEndDateControl = () => this.fg?.get('endDate');
  getServiceIdControl = () => this.fg?.get('serviceId');
  getSubIdControl = () => this.fg?.get('subId');
  getUnitControl = () => this.fg?.get('unit');
  getUnitCostControl = () => this.fg?.get('unitCost');
  getIndirectCostControl = () => this.fg?.get('indirectCost');
  getFunderControl = () => this.fg?.get('funder');
  getContractAmountControl = () => this.fg?.get('contractAmt');
  getLastUpdateControl = () => this.fg?.get('lastUpdate');
  getDeletedControl = () => this.fg?.get('deleted');
  getUserIdControl = () => this.fg?.get('userId');
  getMemoControl = () => this.fg?.get('memo');
  getDivideGroupUnitsControl = () => this.fg?.get('divideGroupUnits');
  getAllowClientLookupControl = () => this.fg?.get('allowClientLookup');
  getRecSourceControl = () => this.fg?.get('recSource');

  getContractsItems = () => this.providerContractsItems;
  isGroupRowInEditMode = (rowIndex: number) => this.isRowInEditMode && (this.rowIndexInEditMode == rowIndex);

  constructor(private fb: UntypedFormBuilder,
              private app: AppContextService,
              public helper: HelperClassService,
              private authService: AuthService,
              private loginService: LoginService,
              private userService: UserService,
              private contractMaintenanceService: ContractMaintenanceService,
              public commonDataService: CommonDataService,
              private providerService: ProviderService,
              private messageService: MessageService,
              private datePipe: DatePipe,
              public validationHelper: FormValidations,
              private confirmationService: ConfirmationService,
              public dialogService: DialogService,
              public diagRef: DynamicDialogRef, 
              public diagConfig: DynamicDialogConfig) { 
    // used if calling component used this.router.navigate(['client-products'], { state: { params: someData } })
    // this.params = this.router.getCurrentNavigation().extras.state.params;
    // console.log(this.params);
    this.params = this.diagConfig.data;

    this.fg = fb.group({
      guid: [],
      contractItemNo: [],
      contractId: [],
      startDate: [, [Validators.required, this.validationHelper.dateBeginValidator('endDate')]], //[this.validationHelper.dateBeginAsyncValidator('endDate')]
      endDate: [, [Validators.required, this.validationHelper.dateEndValidator('startDate')]], //[this.validationHelper.dateEndAsyncValidator('startDate')]
      serviceId: [, [Validators.required]],
      subId: [, [Validators.required]],
      unit: [, [Validators.required]],
      unitCost: [, [Validators.required]],
      indirectCost: [, [Validators.required]],
      funder: [, [Validators.required]],
      contractAmt: [0, [Validators.required]],
      lastUpdate: [],
      deleted: [false],
      userId: [0],
      memo: [],
      divideGroupUnits: [],
      allowClientLookup: [],
      recSource: []
    },
    {
      //validators: [this.contractItemAmountWarning()],
      //asyncValidators: [this.timePeriodValidator()]
      //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
    });
    //this.contractAmount = this.fg?.get('contractAmt');
  }

  ngOnInit(): void {
    // if(!this.authService.isUserAuthenticated){
    //   this.loginService.standAloneLogin().then(x => this.initializeData());
    // }
    // else this.initializeData();

    this.initializeData();
  }

  initializeData() {
    //this.contractFunders = this.providerService.getProviderContractFunderItems(new Date('03-01-2023'), true);
    this.funders = this.commonDataService.getCommonSetByName("Funder", true);
    this.units = this.commonDataService.getCommonSetByName("UnitType", false);
    this.serviceCategories = this.commonDataService.getServiceCategories(0, true);
    this.loadProviderContractItems();
  }

  loadProviderContractItems() {
    var request: Partial<ProviderContractItemsRequestModel> = {};
    Object.assign(request, {});
    request.contractId = this.params.id;

    this.contractMaintenanceService.getProviderContractItems(request).subscribe({
      next: (data: ProviderContractItemsModel[]) => {
            //console.log(data);
            this.providerContractsItems = data;
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => { }
      });
  }

  onBlurContractItemAmount($event: any, contractItem: Partial<ProviderContractItemsModel>) {
    // don't include the current row, since it's in edit mode we have to get that new value from the reactive control (the event currentTarget)
    var itemsSum = this.providerContractsItems?.filter(f => f.guid != contractItem.guid && !f.deleted).reduce((sum, current) => sum + current.contractAmt, 0);
    if (this.getDeletedControl()?.value == false) itemsSum = itemsSum + this.getContractAmountControl().value; //Number($event.currentTarget.value);

    //Change this to a warning somehow - MARK
    if (itemsSum > this.params?.contractAmt)
      this.contractAmountNotEqual = true; //this.getContractAmountControl().setErrors({'invalidContractItemAmount': true});
    else
      this.contractAmountNotEqual = false; //this.getContractAmountControl().setErrors(null);
  }

  onChangeFunder(funderId) {
    // This is not like Direct Services
    //var funderId = Number(funderKeys.funderId);
    //this.serviceCategories = this.commonDataService.getServiceCategories(funderId, true);
  }

  onChangeServiceType(serviceId: number) {
    //var funderId = this.getFunderControl().value;
    this.subTypes = this.commonDataService.getSubTypesByService(serviceId, 0, false, true);
    // 12/19/23 Jay wanted to default to All sub types - 999
    this.getSubIdControl().setValue(999); //this.subTypes[0]?.id
    this.onChangeSubType(999);
  }

  onChangeSubType(subTypeId: number) {
    var subType = this.commonDataService.getSubType(subTypeId);
    if (!!subType) {
      this.getUnitControl().setValue(subType.defaultUnit ?? this.units[0]?.id);
      this.getDivideGroupUnitsControl().setValue(subType.groupEntry ?? false);
    }
    else {
      this.getUnitControl().setValue(this.units[0]?.id ?? 0);
      this.getDivideGroupUnitsControl().setValue(false);
    }
  }

  setFieldValues(contractItem: Partial<ProviderContractItemsModel>) {
    this.getGuidControl().setValue(contractItem?.guid);
    this.getContractItemNoControl().setValue(0);
    this.getContractIdControl().setValue(this.params.id);
    this.getStartDateControl().setValue(this.helper.getDateOrEmpty(contractItem?.startDate));
    this.getEndDateControl().setValue(this.helper.getDateOrEmpty(contractItem?.endDate));
    this.getServiceIdControl().setValue(contractItem?.serviceId);
    this.onChangeServiceType(contractItem?.serviceId);
    this.getSubIdControl().setValue(contractItem?.subId);
    this.onChangeSubType(contractItem?.subId);
    this.getUnitControl().setValue(contractItem?.unit);
    this.getUnitCostControl().setValue(contractItem?.unitCost);
    this.getIndirectCostControl().setValue(contractItem?.indirectCost);
    this.getFunderControl().setValue(contractItem?.funder);
    this.getContractAmountControl().setValue(contractItem?.contractAmt);
    //this.getLastUpdateControl().setValue(); // handled on the backend
    this.getDeletedControl().setValue(contractItem?.deleted);
    this.getUserIdControl().setValue(contractItem?.userId);
    this.getMemoControl().setValue(contractItem?.memo);
    this.getDivideGroupUnitsControl().setValue(contractItem?.divideGroupUnits);
    this.getAllowClientLookupControl().setValue(contractItem?.allowClientLookup);
  }

  setGroupTableRowValues(contractItem: Partial<ProviderContractItemsModel>) {
    contractItem.guid = this.getGuidControl()?.value;
    contractItem.contractItemNo = this.getContractItemNoControl()?.value;
    contractItem.contractId = this.getContractIdControl()?.value;
    contractItem.startDate = this.getStartDateControl()?.value;
    contractItem.endDate = this.getEndDateControl()?.value;
    contractItem.serviceId = this.getServiceIdControl()?.value;
    contractItem.subId = this.getSubIdControl()?.value;
    contractItem.unit = this.getUnitControl()?.value;
    contractItem.unitCost = this.getUnitCostControl()?.value;
    contractItem.indirectCost = this.getIndirectCostControl()?.value;
    contractItem.funder = this.getFunderControl()?.value;
    contractItem.contractAmt = this.getContractAmountControl()?.value;
    contractItem.deleted = this.getDeletedControl()?.value;
    contractItem.userId = this.getUserIdControl()?.value;
    contractItem.memo = this.getMemoControl()?.value;
    contractItem.divideGroupUnits = this.getDivideGroupUnitsControl()?.value;
    contractItem.allowClientLookup = this.getAllowClientLookupControl()?.value;
  }  

  onRowAdd(event: Event, contractItemsTable) {
    var model: Partial<ProviderContractItemsModel> = {};
   
    model.guid = this.helper.getEmptyGuid();
    model.startDate = this.params.startDate;
    model.endDate = this.params.endDate;
    model.unit = 0;
    model.unitCost = 1;
    model.indirectCost = 0;
    model.contractAmt = 0;
    model.deleted = false;
    model.allowClientLookup = true;
    model.divideGroupUnits = false;
    model.lastUpdate = new Date().toISOString();
    
    // insert at the top of the grid not the bottom
    var len = this.getContractsItems().unshift(model); //.push(model) - 1
    var index = 0;
    this.isRowNew = true;
    contractItemsTable.clear();
    this.onRowEditInit(null, contractItemsTable, model, index);
    contractItemsTable.initRowEdit(this.getContractsItems()[index]);
    // commented out popup editing
    //var contractItem = this.getContractsItems()[index];
    //this.onShowContractItemDetails(event, contractItemsTable, contractItem, index);
  }

  onRowEditInit(event: Event, contractItemsTable, contractItem: Partial<ProviderContractItemsModel>, rowIndex: number) {
    this.rowIndexInEditMode = rowIndex;
    this.isRowInEditMode = true;    
    this.setFieldValues(contractItem);
    this.onBlurContractItemAmount(event, contractItem);
  }

  onRowEditSave(event: Event, contractItemsTable, contractItem: ProviderContractItemsModel, rowIndex: number) {
    var originalContractItem: ProviderContractItemsModel = cloneDeep(contractItem);
    this.setGroupTableRowValues(contractItemsTable);
    //this.onSaveContractItem(event, contractItemsTable, contractItem, rowIndex, originalContractItem);
    this.onSaveContractItem(event, contractItem);
    //this.isRowInEditMode = this.isRowNew = false;
  }

  onRowEditCancel(event: Event, contractItemsTable, contractItem: ProviderContractItemsModel, rowIndex: number) {
    if (this.isRowNew) {
      var removedProduct = this.getContractsItems().shift();
    }
    else {
      this.setFieldValues(contractItem);
    }
    this.isRowInEditMode = this.isRowNew = false;
  }

  onRowDelete(event: Event, contractItemsTable, contractItem: ProviderContractItemsModel, rowIndex: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete the selected row?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        try {
          contractItem.deleted = true;
          this.getDeletedControl().setValue(true);
          contractItem.deleted = true;
          this.saveContractItem(contractItem);
          //this.isRowInEditMode = this.isRowNew = false;
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Changes saved successfully.', life: this.app.messageDisplayTime});
        }
        catch (error) {
          console.log(error.error.message); console.log(error.error.data);
          this.app.userServ.LogError(error.error);
          //this.helper.displayError(this.messageService, "Changes failed to save successfully.", error);
        }	         
      },
      reject: () => {
        this.messageService.add({severity: "error", summary: "Aborted", detail: "Changes canceled", life: this.app.messageDisplayTime});
      }      
    });    
  }

  onSaveContractItem(event: Event, contractItem: ProviderContractItemsModel) {
    //this.findInvalidControls();
    this.confirmationService.confirm({
        target: event.target,
        message: "Are you sure that you want save?",
        icon: "pi pi-info-circle",
        accept: () => {
          try {
             var data: ProviderContractItemsModel =  contractItem; //{ }; // Partial<ProviderMaintenanceModel>
             Object.assign(data, this.fg.value)
             data.startDate = this.datePipe.transform(data.startDate, 'yyyy-MM-dd');
             data.endDate = this.datePipe.transform(data.endDate, 'yyyy-MM-dd');
             data.lastUpdate = new Date().toISOString();
             data.memo = data.memo ?? '';
             this.saveContractItem(contractItem);
          }
          catch (error) {
            console.log(error.error.message); console.log(error.error.data);
            this.app.userServ.LogError(error.error);
            //  this.helper.displayError(this.messageService, "Changes failed to save successfully.", error);
            this.messageService.add({severity: "error", summary: "Failure", detail: "Changes failed to save successfully.", life: this.app.messageDisplayTime});
          }	  
        },
        reject: () => {
          this.messageService.add({severity: "error", summary: "Aborted", detail: "Changes canceled", life: this.app.messageDisplayTime});
        }
      });
  }

  saveContractItem(data: ProviderContractItemsModel) {
    this.getUserIdControl().setValue(this.userService.userID);
    this.contractMaintenanceService.saveProviderContractItem(data).subscribe({
      next: (data: ProviderContractItemsModelPostResponse) => {    
            this.patchData(data.data);
            this.isRowInEditMode = this.isRowNew = false;
            this.loadProviderContractItems();            
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Contract Saved.', life: this.app.messageDisplayTime});
            this.refreshContractList.emit();
            var test = 'Contract Item Saved:  ${data?.data?.contractId}';
            this.app.userServ.LogInformation('Contract Item Saved: ' +  data?.data?.contractId);
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error.message, detail: 'Request failed' });
          this.app.userServ.LogError(error?.message  + ' ' + error?.error?.message);
         },
      complete: () => { }
      });
  }

  patchData(data: ProviderContractItemsModel) {
    //this.contractitem = data;
    data.startDate = this.datePipe.transform(data.startDate, 'MM-dd-YYYY');
    data.endDate = this.datePipe.transform(data.endDate, 'MM-dd-YYYY');
    this.fg.patchValue(data);    
  } 
}

  // public contractItemAmountWarning(): ValidatorFnWarn {
  //   if (!c.value || !this.getGuidControl()?.value) { return null; }
  
  //   var itemsSum = this.providerContractsItems?.filter(f => f.guid != this.getGuidControl()?.value && !f.deleted).reduce((sum, current) => sum + current.contractAmt, 0);
  //   if (this.getDeletedControl()?.value == false) itemsSum = itemsSum + c.value;
  
  //   c.warnings = itemsSum > this.params?.contractAmt ? { warningContractItemAmount: true } : null;
  //   return null;
  // }
//   public contractItemAmountWarning(): ValidatorFnWarn {
//     if (!this.getContractAmountControl()?.value || !this.getGuidControl()?.value) { return null; }
  
//     var itemsSum = this.providerContractsItems?.filter(f => f.guid != this.getGuidControl()?.value && !f.deleted).reduce((sum, current) => sum + current.contractAmt, 0);
//     if (this.getDeletedControl()?.value == false) itemsSum = itemsSum + c.value;
  
//     warnings = itemsSum > this.params?.contractAmt ? { warningContractItemAmount: true } : null;
//     return null;
//   }  
// }

//export interface ValidatorFnWarn extends ValidatorFn { warnings: any; }
//export interface AbstractControlWarn extends AbstractControl { warnings: any; }
// export function contractItemAmountValidator(c: AbstractControl) {
//   if (contractItemAmountWarning(c) !== null) { return null; }
//   let val = +c.value;
//   return val > 120 ? { invalidContractItemAmount: {val} } : null;
// }