import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders
  
} from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { Observable } from 'rxjs';
import { tap, map, finalize } from 'rxjs/operators';
import { LoginService } from '../login/services/login.service';
import { UserService }   from '../services/user.service';
import { environment as ENV } from 'src/environments/environment';


@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(public auth: AuthService, private loginService:LoginService, private userService:UserService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const token:string = 'Bearer ' + this.auth.getToken();
    request = request.clone({
        setHeaders: { 'Authorization': token,'user-name': this.userService?.userName ?? 'none', 'app-version': ENV.appVersion , 
                      'clinic-id': this.userService?.CurrentClinicID?.toString() ?? '0', 
                      'provider-id': this.userService?.CurrentProviderID?.toString() ?? '0'
                    }
      } );
                                  
        //headers: req.headers.set('Authorization',token),

        //'user-name': this.userService.userName
        //'user-name': this.userService?.userName ?? 'none'
        //console.log(request.headers.get('Authorization'));      
    return next.handle(request)
        .pipe(
              tap({
                // Succeeds when there is a response; ignore other events
                next: () => {},

                error: (err) =>  {
                  if (err instanceof HttpErrorResponse) {
                    if (err.status === 401 &&  request.url.search('login' ) == 0 ) ///err.error.ErrorMessage != "Invalid Password!") 
                    { // redirect to the login route
                      // or show a modal
                      //this.loginService.setLoginStatus(false,true, null, false);
                      
                      //For now we will re-auth then rety, else go to login
                      this.auth.reAuth();
                      request = request.clone({
                        setHeaders: { 'Authorization': token,'user-name': this.userService?.userName ?? 'none', 'app-version': ENV.appVersion , 
                                      'clinic-id': this.userService?.CurrentClinicID?.toString() ?? '0',
                                      'provider-id': this.userService?.CurrentProviderID?.toString() ?? '0'}
  
                        } );
                        return next.handle(request)
                        .pipe(
                              tap({
                                //next: () => {},
                                error: (err) =>  {
                                    if (err instanceof HttpErrorResponse) {
                                      if (err.status === 401) {
                                        this.loginService.setLoginStatus(false,true, null, false);
                                      }
                                    }
                                 }//,
                                //complete: () => { }
                              }));
                    }
                  }
                },
              complete: () => { }
              }) );
  }
}
