import { Injectable } from '@angular/core';
import { AbstractControl,  AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class DirectServiceValidatorsService {

  constructor() { }

  requiredFieldValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      
      if (!control) return null;

      if (!control.value || control.value == '0')
        return { invalidRequiredField: '*Missing required value.' };
      else if (Array.isArray(control.value) && !control.value.length)
        return { invalidRequiredField: '*Missing required value.' };
      else if (control.value.constructor === ({}).constructor) {
        // this case will handle JSON objects and only checks the first value it finds by the first key it encounters
        var keys = Object.keys(control.value);
        if (!!keys && ((control.value[keys[0]] ?? '0') == '0')) {
          return { invalidRequiredField: '*Missing required value.' };
        }
        else {
          return null;
        }
        // console.log(key, control.value[key]);
      }
      else {
        return null;
      }
    }
  }

  futureDateValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const today = new Date().getTime();
      if(!(control && control.value)) {  // if there's no control or no value, that's ok
        return null;
      }
      var value = new Date(control.value);
      // remove hours minutes seconds and milliseconds
      value.setHours(0,0,0,0);
      // return null if there's no errors
      return value.getTime() > today ? {invalidDate: '*Invalid future date.' }  : null;
    }
  }

  timeValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const today = new Date().getTime();
      if(!(control && control.value)) {  // if there's no control or no value, that's ok
        return null;
      }
      // return null if there's no errors
      return !control.value ? {invalidDate: '*Invalid time.' }  : null;
    }
  }  

}
