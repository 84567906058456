  export interface ProviderDataModel {
     provider: ProviderModel;
     clinics: ProviderClinicsModel[];
     contracts: ProviderContractsModel[];
     contractItems: ProviderContractItemsModel[];
     employees: ProviderEmployeesModel[];
     requiredFields: ProviderRequiredFieldsModel[];
     options: ProviderOptionsModel[];
     serviceExceptions: ProviderServiceExceptionsModel[];
     users: ProviderUsersModel[];
     referralProviders: ReferralProvidersModel[];
     externalProviders: ExternalProvidersModel[]
 }

 export interface ProviderInfoDataModel {
    provider: ProviderModel;
    clinic: ProviderClinicsModel;
}
 
 export interface ProviderClinicsModel {
     id: number;
     providerId: number | null;
     clinicName: string;
     recDate: string | null;
     rsrRegistrationCode: string;
     rsrProviderId: string;
     einNumber: string;
     showIndBilledGrpSubtype: boolean | null;
     active: boolean | null;
 }
 
 export interface ProviderModel {
     guid: string;
     id: number;
     providerName: string;
     addressLine1: string;
     addressLine2: string;
     city: string;
     state: string;
     zip: string;
     mainPhone: string;
     mainFax: string;
     site: string;
     url: string;
     email: string;
     active: boolean;
     funded: boolean;
     memo: string;
     test: boolean | null;
     rsrProviderId: string;
 }
 
 export interface ProviderContractsModel {
     guid: string;
     id: number;
     providerId: number;
     programName: string;
     startDate: string;
     endDate: string;
     contractAmt: number;
     fundYear: string;
     site: string;
     addressLine1: string;
     addressLine2: string;
     city: string;
     state: string;
     zip: string;
     phone: string;
     fax: string;
     url: string;
     email: string;
     clinicId: number | null;
     recSource: string;
     includesEisUnits: boolean | null;
 }
 
 export interface ProviderContractItemsModel {
     contractItemGuid: string;
     contractItemNo: number;
     contractId: number;
     startDate: string;
     endDate: string;
     serviceId: number;
     subId: number;
     unit: number | null;
     unitCost: number;
     indirectCost: number | null;
     funder: number | null;
     contractAmt: number;
     memo: string;
     divideGroupUnits: boolean | null;
     allowClientLookup: boolean | null;
     recSource: string;
 }
 
 export interface ProviderEmployeesModel {
     guid: string;
     id: number;
     firstName: string;
     middleName: string;
     lastName: string;
     title: string;
     addressLine1: string;
     addressLine2: string;
     city: string;
     state: string;
     zip: string;
     phone: string;
     fax: string;
     emailAddress: string;
     position: number;
     providerId: number;
     champUser: boolean;
     empUserId: number;
     userId: number;
     notes: string;
 }
 
 export interface ProviderRequiredFieldsModel {
     guid: string;
     serviceId: number;
     fieldId: number;
     providerId: number;
     effDate: string;
     deleted: boolean;
     required: boolean;
     allowSave: boolean;
     allowBillable: boolean;
     expiresDays: number;
     recSource: string;
 }
 
 export interface ProviderOptionsModel {
     guid: string;
     providerId: number;
     clientStatusExpirationDays: number;
     clientStatusResetValue: number;
     medicalChartReevaluationDays: number;
     adminBillableDays: number;
     messagingEnabled: boolean;
     emailEnabled: boolean;
     admin: boolean;
     lastUpdate: string;
     useFullNames: boolean;
     useAddressInformation: boolean;
     useFullSsn: boolean;
     useEmailAddress: boolean;
     referralExpirationDays: number;
     showContractedServicesOnly: boolean | null;
     cd4AidsLevel: number;
     viralLoadAidsLevel: number;
     useAssessment: boolean | null;
     funderBillingRestrictionEncounter: boolean | null;
     funderBillingRestrictionMonthly: boolean | null;
 }
 
 export interface ProviderServiceExceptionsModel {
     id: number;
     providerId: number;
     serviceId: number;
     subId: number;
     hivStatus: number;
 }
 
 export interface ProviderUsersModel {
     guid: string;
     providerId: number;
     userId: number;
     defaultClinicId: number | null;
     defaultEmployeeId: number | null;
     lastUpdate: any;
 }

export interface ReferralProvidersModel {
    guid: string;
    providerId: number;
    providerName: string;
    clinicName: string;
    clinicId: number | null;
    serviceId: number | null;
    subId: number | null;
    contractStartDate: string | null;
    contractEndDate: string | null;
}

export interface ExternalProvidersModel {
    id: number;
    sourceProviderId: number;
    name: string;
    phone: string;
    address: string;
    dateTime: any;
}

export class ExternalProvider {
	public constructor(init?: any) {
		if (!!init) {
			Object.assign(this, init);
		}
	}	
}

export interface PostResponse {
	success: boolean;
	message: string;
	data: string;
}