import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { UserClinicsModel, UserProvidersModel } from 'src/app/models/user.config.model';
import { UserService } from '../../services/user.service';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { LoginService } from 'src/app/login/services/login.service';
import { ProviderService } from 'src/app/services/provider.service';
import { forkJoin, noop, observable, ReplaySubject, from, of } from 'rxjs';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-switch-provider',
  templateUrl: './switch-provider.component.html',
  styleUrls: ['./switch-provider.component.scss'],
  providers:[MessageService]
})
export class SwitchProviderComponent implements OnInit {
  
  alwaysVisible: boolean = true;
  enableSwitchProvider: boolean = false;
  providerList: UserProvidersModel[];
  clinicList: UserClinicsModel[];
  providerId: number = 0;
  clinicId: number = 0;

  constructor(
              private app:AppContextService,
              //private userService: UserService, 
              private route: ActivatedRoute, 
              private router: Router,
              private loginService: LoginService,
              private providerService: ProviderService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) {
    this.providerList = this.app.userServ.getAccessibleProviders();
   }

  ngOnInit(): void {
  }

  onChangeProvider() {
    if (this.providerId > 0) {
      // load clinics for select the provider
      this.clinicId = 0;
      this.clinicList = this.app.userServ.getAccessibleClinics(this.providerId);
      if (this.clinicList.length == 1) this.clinicId = this.clinicList[0].clinicId;
      this.enableSwitchProvider = true;
    }
    else
      this.enableSwitchProvider = false;
  }

  onSwitchProviderConfirm(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure that you want to proceed?",
      icon: "pi pi-info-circle",
      accept: () => {
        this.messageService.add({
          severity: "info",
          summary: "Confirmed",
          detail: "Switching provider",
          life: 1000
        });
        this.onSwitchProvider();
      },
      reject: () => {
        this.messageService.add({
          severity: "error",
          summary: "Aborted",
          detail: "Switching provider canceled",
          life: 1000
        });
      }
    });
  }  

  onSwitchProvider() {
    this.app.userServ.CurrentProviderID = this.providerId;
    this.app.userServ.CurrentClinicID = this.clinicId;
    // also need to save current provider and current clinic to user registry
    this.providerService.load(this.app.userServ.CurrentProviderID).subscribe({
                    next:(data) =>{
                        noop; 
                    },
                    error:(error)=>{
                        console.log (error);
                    },
                    complete:() => {
                        console.log ("Provider switch complete!");
                        this.app.userServ.SaveUserConfigValue('CHAMP.Registry.ProviderID', this.app.userServ.CurrentProviderID.toString());
                        //this.userService.SaveUserConfigValue('CHAMP.Registry.ClinicID', this.userService.CurrentClinicID.toString());
                        this.app.userServ.SaveUserConfigValue('CHAMP4.Registry.ClinicID', this.app.userServ.CurrentClinicID.toString());
                        this.app.getClientStatusLookupCount();
                        this.app.getReferralsWaitingCount();
                        this.router.navigateByUrl("client-list");
                    }
                  });

    // I don't believe I need a full reload since user data & common data are not dependant upon the provider id that's changing
    // this.loginService.loadServices(this.userService.userID)
    //     .subscribe( 
    //                 resp => { noop },
    //                 error=>  { console.log ( error ); },
    //                 ()=> { console.log ("Switch Provider Complete!") }
    //               );
  }

  onCloseDialog() {
    this.alwaysVisible = false;
    this.router.navigateByUrl("client-list");
  }  
}
