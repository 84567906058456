//import { invalid } from '@angular/compiler/src/render3/view/util';
import { Injectable } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Form, Validators, AbstractControl, ValidatorFn, AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { CommonDataModel, CommonData, ServiceCategory, SubType, ServiceSubType } from 'src/app/models/common-data.model'
import { lastValueFrom, Observable, of, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormValidations {

    constructor() { }

    public AddRequiredValidator(control: AbstractControl) {
      this.AddValidators(control, Validators.required);
    }

    public AddRequiredMinMaxValidator(control:AbstractControl, min:number, max:number) {
      // control.enable();
      // control.setValidators([Validators.required, Validators.min(min), Validators.max(max)]);
      // control.markAsTouched();
      // control.updateValueAndValidity();
      this.AddValidators(control, [Validators.required, Validators.min(min), Validators.max(max)]);
    }    
    
    public AddValidators(control: AbstractControl, validators: ValidatorFn | ValidatorFn[]) { //validators: ValidatorFn[] = [Validators.required] //...validators: ValidatorFn[]
      
      control.enable();
      control.clearValidators();
      //validators.forEach(validator => control.addValidators(validator));
      //control.setValidators(this.dropdownValidator());      
      control.setValidators(validators);
      control.markAsTouched();
      control.updateValueAndValidity();
    }

    public RemoveValidators(control: AbstractControl, enable: boolean = false) {
      if (enable) control.enable();
      else control.disable();
      control.clearValidators();
      control.updateValueAndValidity();
    }

    public hasRequiredField = (abstractControl: AbstractControl): boolean => {
      if (abstractControl.validator) {
        const validator = abstractControl.validator({}as AbstractControl);
        if (validator && validator.required) {
          return true;
        }
      }
      return false;
    }

    requiredFieldValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
  
        if (!control) return null;
  
        if (!control.value || control.value == '0')
          return { invalidRequiredField: '*Missing required value.' };
        else if (control.value.constructor === ({}).constructor) {
          // this case will handle JSON objects and only checks the first value it finds by the first key it encounters
          var keys = Object.keys(control.value);
          if (!!keys && ((control.value[keys[0]] ?? '0') == '0')) {
            return { invalidRequiredField: '*Missing required value.' };
          }
          else {
            return null;
          }
          // console.log(key, control.value[key]);
        }
        else {
          return null;
        }
      }
    }  

    public dateFutureYearValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
        const today = new Date();
        if(!(control && control.value)) { // if there's no control or no value, that's ok
          return null;
        }
        // return null if there's no errors
      return Number(control.value )> today.getFullYear()  ? {invalidDate: 'You cannot use future year' } : null;
      }
    }

    public dateFutureDateValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
        const today = new Date().getTime();
        if(!(control && control.value)) {  // if there's no control or no value, that's ok
          return null;
        }
        const value = new Date(control.value);
        // return null if there's no errors
        return value.getTime() > today ? {invalidDate: 'You cannot use future dates.' }  : null;
      }
    }

    public dateBeginAsyncValidator(endDateName: string): AsyncValidatorFn {
      return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        if (!(control && control.value)) return null; // if there's no control or no value, that's ok

        const today = new Date().getTime();
        const form = control.parent;

        if (!!form) {
          const startDate = new Date(control.value);
          const endDate = form.get(endDateName).value == null ? null : new Date(form.get(endDateName).value);
          
          if (!!endDate && startDate?.getTime() > endDate?.getTime()) {
            return of({ invalidDate: 'Must be less than end date' });
          }
        }

        return of(null);        
      }
    }

    public dateEndAsyncValidator(beginDateName: string): AsyncValidatorFn {
      return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => { 
        if (!(control && control.value)) return null; // if there's no control or no value, that's ok

        const today = new Date().getTime();
        const form = control.parent;

        if (!!form) {
          const endDate = new Date(control.value);
          const startDate = form.get(beginDateName).value == null ? null : new Date(form.get(beginDateName).value);
          
          if (!!startDate && endDate?.getTime() < startDate?.getTime()) {
            return of({ invalidDate: 'Must be greater than start date' });
          }
        }

        return of(null);
      }
    }

    public dateBeginValidator(endDateName: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        if (!(control && control.value)) return null; // if there's no control or no value, that's ok

        const today = new Date().getTime();
        const form = control.parent;

        if (!!form) {
          const startDate = new Date(control.value);
          const endDate = form.get(endDateName).value == null ? null : new Date(form.get(endDateName).value);
          
          if (!!endDate && startDate?.getTime() > endDate?.getTime()) {
            return { invalidDate: 'Must be less than end date' };
          }
        }

        return null;
      }
    }

    public dateEndValidator(beginDateName: string): ValidatorFn {
      return (control: AbstractControl): { [key: string]: any } | null => {
        if (!(control && control.value)) return null; // if there's no control or no value, that's ok

        const today = new Date().getTime();
        const form = control.parent;

        if (!!form) {
          const endDate = new Date(control.value);
          const startDate = form.get(beginDateName).value == null ? null : new Date(form.get(beginDateName).value);
          
          if (!!startDate && endDate?.getTime() < startDate?.getTime()) {
            return { invalidDate: 'Must be greater than start date' };
          }
        }

        return null;
      }
    }

    public disableValidator(disabled:boolean): ValidatorFn {

      // experiment
      return (control: AbstractControl): {[key: string]: any} | null => {
        
        if ( disabled) control.disable(); else control.enable();

        if(!(control && control.value)) {  // if there's no control or no value, that's ok
          return null;
        }
        // const value = new Date(control.value);
        // // return null if there's no errors
        // return value.getTime() > today ? {invalidDate: 'You cannot use future dates.' }  : null;
      }
    }

    public dateBirthDateValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
        const today = new Date().getTime();
        if(!(control && control.value)) {  // if there's no control or no value, that's ok
          return null;
        }
        const form =  control.parent.parent;
        if (form != null)
        {
          const birthDate = new Date(form.get('identification').get('idBirthDate').value);
          //console.log(birthDate);
          return control.value.getTime() < birthDate.getTime() ? {invalidDate: 'Must be greater than birth date' } : null;
        }
        else{
          return null;
        }
      }
    }

    public incomeValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
        if(!(control && control.value)) { // if there's no control or no value, that's ok
          return null;
        }
      // return null if there's no errors
      return control.value > 500000 ? {invalidIncome: 'Income cannot exceed 500,000' } : null;
      }
    }

    public dropdownValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
        if(!control) { // if there's no control or no value, that's ok   //&& control.value
          return null;
        }
        // return null if there's no errors
        return (control.value ?? 0) == 0  ? {invalidSelection: 'Must select a value' } : null;
      }
    }  

    public dateAIDSDiagnosisYearValidator(): ValidatorFn {
      return (control: AbstractControl): {[key: string]: any} | null => {
        const today = new Date().getTime();
        if(!(control && control.value)) { // if there's no control or no value, that's ok
          return null;
        }

        const form =  control.parent.parent;
        if (form != null)
        {
          const diagLast12Months = form.get('demographics').get('idDiagnosedLast12Months').value;
          const hivStatus = form.get('demographics').get('idHIVStatus').value;
          const hivDiagYear = form.get('demographics').get('idHIVDiagnosisYear').value;
          
          if (diagLast12Months != 2 ||  hivStatus != 8    ) // If Diag Last 12 Months = NO  or NOT HIV STATUS  AIDS --> exit
          {
            return null;
          }
          else{
              if ( Number(control.value) < hivDiagYear )
              return  {invalidDate: 'Must be greater than HIV Diagnosis' } 
              else return null;
          }
        }
        else{
          return null;
        }
      }
    }
    
  public dateAIDSDiagnosisValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const today = new Date().getTime();
      if(!(control && control.value)) { // if there's no control or no value, that's ok
        return null;
      }

      const form =  control.parent.parent;
      if (form != null)
      {
        const diagLast12Months = form.get('demographics').get('idDiagnosedLast12Months').value;
        const hivStatus = form.get('demographics').get('idHIVStatus').value;
        const hivDiag = form.get('demographics').get('idHIVDiagnosis').value;
      
        if (diagLast12Months != 1 ||  hivStatus != 8    ) // If Diag Last 12 Months = NO  or NOT HIV STATUS  AIDS --> exit
        {
          return null;
        }
        else{
          // Check that greater than birthdate
          const birthDate = new Date(form.get('identification').get('idBirthDate').value);
          if ( control.value.getTime() < birthDate.getTime())
            return  {invalidDate: 'Must be greater than birth date' };

          return control.value.getTime() < hivDiag ? {invalidDate: 'Must be greater than HIV Diagnosis' } : null;
        }
      }
      else{
        return null;
      }
    }
  }

  public dateBirthYearValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const today = new Date().getTime();
      if(!(control && control.value)) {  // if there's no control or no value, that's ok
        return null;
      }
      const form =  control.parent.parent;
      if (form != null)
      {
        const birthDate = new Date(form.get('identification').get('idBirthDate').value);
        // return null if there's no errors
        return Number(control.value ) < Number(birthDate.getFullYear()) ? {invalidDate: 'Must be greater than birth date' } : null;
      }
      else{
        return null;
      }
    }
  }
  

  public passwordValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if(!(control && control.value ) ) {  // if there's no control or no value, that's ok
        return null;
      }
      const form =  control.parent;
      if (form != null)
      {
        if (!form.get('password').dirty){
          return null;
        }
        const pwd = form.get('password').value;
        const confirmpwd = form.get('passwordConfirm').value;
        // return null if there's no errors

        if (confirmpwd !=  pwd)
          return {invalid: 'Confirm password must match password field' } 
        else {
          form.get('password').setErrors(null);
          return null;

        }
        //return confirmpwd !=  pwd ? {invalid: 'Confirm password must match password field' } : null;
      }
      else{
        return null;
      }
    }
  }


}