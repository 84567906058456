<div class="provider-area" >  <!--  style="font-family:cursive"  URBAN-->
  <div class="grid" *ngIf="(providerService.provider$ | async) as prov">

      <div class="col">
              <span id="provider-name" style="overflow: hidden;max-width: 30rem;">{{ prov.provider.providerName }}  </span><br>
              <span style="overflow: hidden;max-width: 50rem;" > {{ prov.provider.addressLine1 }} </span>,  <span > {{ prov.provider.city }}</span>
      </div>
     

      <div class="col">
            <span id="clinic-name"  style="overflow: hidden;max-width: 30rem;"> {{ prov?.clinic?.clinicName }}  </span>
          
      </div>

  </div>

</div>
