import { Time, DatePipe } from '@angular/common';
//import { Component, OnInit, ViewEncapsulation, isDevMode, ChangeDetectorRef, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { Component, OnInit, isDevMode, Input, ViewChild, EventEmitter, Output } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl,
         AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable, Subject, from, noop, of, lastValueFrom } from 'rxjs';
import { YesNo, PosNegResults } from "src/app/models/enums";
import { ClientDirectServiceModel, ClientDirectServiceRequestModel, ClientDirectService, EISSubCategoryDatesModel, 
         ClientMedicalRiskReductionModel, ClientRiskReduction } from '../services/client-direct-service-models';
import { CommonDataService } from '../../services/common-data.service';
import { UserService } from '../../services/user.service';
import { HelperClassService } from '../../services/helper-class.service';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { ClientDirectServicesService } from './../services/client-direct-services.service';
import { ClientFormService } from 'src/app/client-form/services/client-form.service'
import { addDays, addMonths, parseISO, format, formatISO, formatISO9075, toDate, getDate, startOfMonth, endOfDay, subDays, sub, getTime, compareAsc } from 'date-fns';
import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, round, sumBy,
         sortedUniq, sortBy, orderBy, map, merge, uniqBy, intersection, split, toNumber, isBoolean } from 'lodash';
import { AppContextService } from 'src/app/app-context/app-context.service';         

@Component({
  selector: 'app-risk-reduction',
  templateUrl: './risk-reduction.component.html',
  styleUrls: ['./risk-reduction.component.scss']
})

export class RiskReductionComponent implements OnInit {
  @Output() dialogCompleted: EventEmitter<any> = new EventEmitter();
  //@Input() displayRiskReductionDialog$: Observable<boolean>;
  //@Input() displayRiskReductionDialog: boolean;
  mainForm: UntypedFormGroup;
  //YesNoEnum = YesNo;  
  displayRiskReductionDialog: boolean = false;
  validRiskReduction: boolean = false;
  selectedDirectService: Partial<ClientDirectServiceModel>;
  allowEdit: boolean = false;
  forceSave: boolean = false;
  readOnly: boolean = false;
  riskReductionHistory: ClientMedicalRiskReductionModel[];
  originDate: Date = new Date(0, 0); //Mon Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time) // new Date(1900, 0, 1)
  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

  tobaccoPanelVisible: boolean = false;
  tobaccoPanelEnabled: boolean = false;
  tobaccoRequired: boolean = false;
  tobaccoLoadValues: boolean = false;

  riskReductionPanelVisible: boolean = false;
  riskReductionPanelEnabled: boolean = false;
  riskReductionRequired: boolean = false;
  riskReductionLoadValues: boolean = false;

  treatmentAdherencePanelVisible: boolean = false;
  treatmentAdherencePanelEnabled: boolean = false;
  treatmentAdherenceRequired: boolean = false;
  treatmentAdherenceLoadValues: boolean = false;

  alcoholPanelVisible: boolean = false;
  alcoholPanelEnabled: boolean = false;
  alcoholRequired: boolean = false;
  alcoholLoadValues: boolean = false;

  // mentalHealthScreeningPanel: boolean = true;
  // mentalHealthScreeningRequired: boolean = true;
  // subAbuseScreeningPanel: boolean = true;
  // subAbuseScreeningRequired: boolean = true;

  getRiskReductionNotificationJSONMessage = (dialogCompleted:boolean) => { return '{ "dialogCompleted":' + dialogCompleted.toString() + ', "validRiskReduction":' + this.validRiskReduction.toString() + ' }'; }

  getRiskReductionYesNoControl = () => this.mainForm?.get('riskReduction');
  getRiskReductionYesNoValue = () => this.getRiskReductionYesNoControl()?.value;
  setRiskReductionYesNoValue = (value) => this.getRiskReductionYesNoControl()?.setValue(value);

  getRiskReductionDateControl = () => this.mainForm?.get('riskReductionDate');
  getRiskReductionDateValue = () => this.getRiskReductionDateControl()?.value ?? this.originDate;
  setRiskReductionDateValue = (value) => this.getRiskReductionDateControl()?.setValue(this.helperService.castToDate(value));

  getTreatementAdherenceYesNoControl = () => this.mainForm?.get('treatmentAdherence');
  getTreatementAdherenceYesNoValue = () => this.getTreatementAdherenceYesNoControl()?.value;
  setTreatementAdherenceYesNoValue = (value) => this.getTreatementAdherenceYesNoControl()?.setValue(value);

  getTreatementAdherenceDateControl = () => this.mainForm?.get('treatmentAdherenceDate');
  getTreatementAdherenceDateValue = () => this.getTreatementAdherenceDateControl()?.value ?? this.originDate;
  setTreatementAdherenceDateValue = (value) => this.getTreatementAdherenceDateControl()?.setValue(this.helperService.castToDate(value));

  getTobaccoYesNoControl = () => this.mainForm?.get('tobaccoUse');
  getTobaccoYesNoValue = () => this.getTobaccoYesNoControl()?.value;
  setTobaccoYesNoValue = (value) => this.getTobaccoYesNoControl()?.setValue(value);

  getTobaccoCounselingYesNoControl = () => this.mainForm?.get('tobaccoCounseling');
  getTobaccoCounselingYesNoValue = () => this.getTobaccoCounselingYesNoControl()?.value;
  setTobaccoCounselingYesNoValue = (value) => this.getTobaccoCounselingYesNoControl()?.setValue(value);

  getTobaccoCounselingDateControl = () => this.mainForm?.get('tobaccoCounselingDate');
  getTobaccoCounselingDateValue = () => this.getTobaccoCounselingDateControl()?.value ?? this.originDate;
  setTobaccoCounselingDateValue = (value) => this.getTobaccoCounselingDateControl()?.setValue(this.helperService.castToDate(value));

  getAlcoholYesNoControl = () => this.mainForm?.get('alcoholUse');
  getAlcoholYesNoValue = () => this.getAlcoholYesNoControl()?.value;
  setAlcoholYesNoValue = (value) => this.getAlcoholYesNoControl()?.setValue(value);

  getAlcoholCounselingYesNoControl = () => this.mainForm?.get('alcoholCounseling');
  getAlcoholCounselingYesNoValue = () => this.getAlcoholCounselingYesNoControl()?.value;
  setAlcoholCounselingYesNoValue = (value) => this.getAlcoholCounselingYesNoControl()?.setValue(value);

  getAlcoholCounselingDateControl = () => this.mainForm?.get('alcoholCounselingDate');
  getAlcoholCounselingDateValue = () => this.getAlcoholCounselingDateControl()?.value ?? this.originDate;
  setAlcoholCounselingDateValue = (value) => this.getAlcoholCounselingDateControl()?.setValue(this.helperService.castToDate(value));  

  getGuidControl = () => this.mainForm?.get('guid');
  getGuidValue = () => this.getGuidControl()?.value ?? '00000000-0000-0000-0000-000000000000';
  setGuidValue = (value) => this.getGuidControl()?.setValue(value ?? '00000000-0000-0000-0000-000000000000');
  
  getExpiredDateControl = () => this.mainForm?.get('expired');
  getExpiredDateValue = () => this.getExpiredDateControl()?.value ?? this.originDate;
  setExpiredDateValue = (value) => this.getExpiredDateControl()?.setValue(this.helperService.castToDate(value));

  getDeliveredDateValue = () => { 
    if (typeof this.selectedDirectService.deliveredDate == 'string')
      return parseISO(this.selectedDirectService.deliveredDate);
    else
      return toDate(this.selectedDirectService.deliveredDate);
  };  

  isAtLeastOneCounselingRequired = () => { return (this.riskReductionRequired || this.treatmentAdherenceRequired || this.tobaccoRequired || this.alcoholRequired); }
  isAtLeastOneCounselingVisible = () => { return (this.riskReductionPanelVisible || this.treatmentAdherencePanelVisible || this.tobaccoPanelVisible || this.alcoholPanelVisible); }

  constructor(private route: ActivatedRoute, 
              private router: Router, 
              private fb: UntypedFormBuilder, 
              private dsDataService: ClientDirectServicesService, 
              private commonDataService: CommonDataService, 
              public clientService:ClientFormService, 
              private helperService: HelperClassService,
              private app: AppContextService,
              private userService: UserService, 
              private datePipe: DatePipe) {
    this.mainForm = fb.group({
      guid:[],
      tobaccoUse: [YesNo.None],
      tobaccoCounseling: [YesNo.None],
      tobaccoCounselingDate: ['1900-01-01', this.futureDateValidator()],
      riskReduction: [YesNo.None],
      riskReductionDate: ['1900-01-01', this.futureDateValidator()],
      treatmentAdherence: [YesNo.None],
      treatmentAdherenceDate: ['1900-01-01', this.futureDateValidator()],
      alcoholUse: [YesNo.None],
      alcoholCounseling: [YesNo.None],
      alcoholCounselingDate: ['1900-01-01', this.futureDateValidator()],
      expired: ['1900-01-01'],
      userId: [0]
    },
    {
      validators: [this.riskReductionValidator(), this.treatmentAdherenceValidator(), this.tobaccoValidator(), this.alcoholValidator()]
    });

    this.displayRiskReductionDialog = false;
  }

  ngOnInit(): void {
    // this.displayRiskReductionDialog$.subscribe(val => {
    //   console.log(val);
    //   //this.form = this.qcs.toFormGroup(this.answers);
    // });
    //this.loadRiskReduction(selectedDirectService);
  }

  onHideDialog() {
    this.displayRiskReductionDialog = false;
    if (this.allowEdit) this.dialogCompleted.emit(this.getRiskReductionNotificationJSONMessage(true));    
  }

  async onShowDialog(selectedDirectService: Partial<ClientDirectServiceModel>, allowEdit: boolean = false, forceSave: boolean = false) {
    this.mainForm.reset();
    this.allowEdit = allowEdit;
    this.readOnly = !allowEdit;
    this.forceSave = forceSave;
    this.validRiskReduction = false;
    this.selectedDirectService = selectedDirectService;
    await this.loadRiskReduction();
    // don't show the window unless at least one question is required
    if (this.isAtLeastOneCounselingVisible()) {
      this.displayRiskReductionDialog = true;
      this.dialogCompleted.emit(this.getRiskReductionNotificationJSONMessage(false));
    }
    else {
      this.validRiskReduction = true;
      this.dialogCompleted.emit(this.getRiskReductionNotificationJSONMessage(true));
    }
    if (this.clientService.readonly)  this.mainForm.disable();
  }

  onSaveChangesNoConfirm(event: Event) {
    this.onSaveChanges().then(value => { this.onHideDialog(); })
                        .catch((error) => { alert(error); });    
  }

  async onSaveChanges() {
    // if (!this.mainForm.dirty) {
    //   return;
    // } else
    if (this.mainForm.valid) {
      var currentDateTime = await this.commonDataService.getCurrentDateTime();
      //var currentDateTimeFormatted = format(currentDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
      var currentDateTimeFormatted = format(currentDateTime, "yyyy-MM-dd");

      var model = this.getRiskReductionModel();

      model.riskReductionCounselingRequired = this.riskReductionRequired;
      model.treatementAdherenceCounselingRequired = this.treatmentAdherenceRequired;
      model.tobaccoCounselingRequired = this.tobaccoRequired;
      model.alcoholCounselingRequired = this.alcoholRequired;      

      if (this.getRiskReductionYesNoControl().dirty || this.getRiskReductionDateControl().dirty) model.riskReductionCounselingLastUpdate = currentDateTimeFormatted;
      if (this.getTreatementAdherenceYesNoControl().dirty || this.getTreatementAdherenceDateControl().dirty) model.treatementAdherenceCounselingLastUpdate = currentDateTimeFormatted;
      if (this.getTobaccoYesNoControl().dirty || this.getTobaccoCounselingYesNoControl().dirty || this.getTobaccoCounselingDateControl().dirty) model.tobaccoCounselingLastUpdate = currentDateTimeFormatted;
      if (this.getAlcoholYesNoControl()?.dirty || this.getAlcoholCounselingYesNoControl()?.dirty || this.getAlcoholCounselingDateControl()?.dirty) model.alcoholCounselingLastUpdate = currentDateTimeFormatted;

      try {
        var response = await lastValueFrom(this.dsDataService.saveRiskReduction(model));
        const keys = JSON.parse(response.data);
        this.setGuidValue(keys.riskReductionGuid);
        // on successful save flag that we have a valid risk reduction
        this.validRiskReduction = true;
        this.mainForm.reset(this.mainForm.value);        
      }
      catch (error) {
        console.log(error);
        this.app.loginSvc.LogError(error);
        //console.log(error.error.message); console.log(error.error.data);
      }      
    }
  }

  getRiskReductionModel(): Partial<ClientMedicalRiskReductionModel> {
    var model:Partial<ClientMedicalRiskReductionModel> = new ClientRiskReduction(this.mainForm.value);

    // removed time component, when time was being included and formatISO was not used to convert the date then every save added 4 hours to the datetime (not UTC compliant)
    // also need a string formatted date for API call to work
    model.guid = model.guid ?? '00000000-0000-0000-0000-000000000000';
    model.clientGuid = this.selectedDirectService.clientGuid;
    model.directServiceGuid = this.selectedDirectService.guid ?? '00000000-0000-0000-0000-000000000000';
    model.riskReduction = model.riskReduction ?? YesNo.None;
    model.riskReductionDate = formatISO(model.riskReductionDate ?? this.originDate, { representation: 'date' });
    //model.riskReductionCounselingLastUpdate
    //model.riskReductionCounselingRequired
    model.treatmentAdherence = model.treatmentAdherence ?? YesNo.None;
    model.treatmentAdherenceDate = formatISO(model.treatmentAdherenceDate ?? this.originDate, { representation: 'date' });
    //model.treatementAdherenceCounselingLastUpdate
    //model.treatementAdherenceCounselingRequired
    model.tobaccoUse = model.tobaccoUse ?? YesNo.None;
    model.tobaccoCounseling = model.tobaccoCounseling ?? YesNo.None;
    model.tobaccoCounselingDate = formatISO(model.tobaccoCounselingDate ?? this.originDate, { representation: 'date' });
    //model.tobaccoCounselingLastUpdate
    //model.tobaccoCounselingRequired
    model.alcoholUse = model.alcoholUse ?? YesNo.None;
    model.alcoholCounseling = model.alcoholCounseling ?? YesNo.None;
    model.alcoholCounselingDate = formatISO(model.alcoholCounselingDate ?? this.originDate, { representation: 'date' });
    //model.alcoholCounselingLastUpdate
    //model.alcoholCounselingRequired

    model.expired = formatISO(model.expired ?? this.originDate);
    model.datetime = formatISO(model.datetime ?? this.currentDate);
    model.deleted = false;
    model.userId = this.userService.userID;
    model.recSource = model.recSource ?? '4';

    //moved MH & SA to another screen
    //model.mentalHealthScreeningDate
    //model.mentalHealthScreeningDate
    //model.mentalHealthScreeningLastUpdate
    //model.mentalHealthScreeningRequired
    //model.substanceAbuseScreening
    //model.substanceAbuseScreeningDate
    //model.substanceAbuseScreeningLastUpdate
    //model.substanceAbuseScreeningRequired

    return model;
  }  

  async getRiskReductionHistory() {
    this.riskReductionHistory = await this.clientService.getRiskReductionHistory(this.selectedDirectService.clientGuid).toPromise();
  }

  async loadRiskReduction() {
    var serverDateTime = await this.commonDataService.getCurrentDateTime();
    
    //selectedDirectService.clientMedicalRiskReduction = null; this.forceSave = true; // for testing history

    // load risk reduction history for the client to check for required sections against client history
    if (this.forceSave) await this.getRiskReductionHistory();

    // NOTE for all settings ONLY a SINGLE EXPIRED FIELD controls all the different treatments and counselings

    this.setGuidValue(this.selectedDirectService?.clientMedicalRiskReduction?.guid);
    this.setExpiredDateValue(this.selectedDirectService?.clientMedicalRiskReduction?.expired ?? this.originDate);

    // risk reduction settings
    this.riskReductionPanelVisible = this.riskReductionPanelEnabled = this.riskReductionRequired = this.riskReductionLoadValues = false;
    this.setRiskReductionYesNoValue(YesNo.None);
    this.setRiskReductionDateValue(this.originDate);
    // if risk reduction exists for the current direct service
    if (this.commonDataService.isSubTypeRiskReduction(this.selectedDirectService?.serviceId, this.selectedDirectService?.subId)) { 
      if (!!this.selectedDirectService.clientMedicalRiskReduction) {
        // check each option
        if (this.forceSave && this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel enabled and show empty values to be force entered by user, creates new row in table
          this.riskReductionPanelVisible = this.riskReductionPanelEnabled = this.riskReductionRequired = true; this.riskReductionLoadValues = false;
        }
        else if (this.forceSave && !this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // hide panel, user not required to do anything since there are valid entries, no edit capability
          // Den said to always show Risk Reduction popup whether data was expired or not 10/24/2022, so set to true instead of false
          // this.riskReductionPanelVisible = this.riskReductionPanelEnabled = this.riskReductionRequired = false;
          this.riskReductionPanelVisible = this.riskReductionPanelEnabled = this.riskReductionRequired = this.riskReductionLoadValues = true;
        }
        else if (!this.forceSave && this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel disabled, user cannot change expired values, no edit capability
          this.riskReductionPanelVisible = this.riskReductionLoadValues = true; this.riskReductionPanelEnabled = this.riskReductionRequired = false;
        }
        else if (!this.forceSave && !this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel enabled, allow user to edit values, updates row in table
          this.riskReductionPanelVisible = this.riskReductionPanelEnabled = this.riskReductionRequired = this.riskReductionLoadValues = true;
        }
        if (this.riskReductionLoadValues) { // !this.forceSave
          this.setRiskReductionYesNoValue(this.selectedDirectService.clientMedicalRiskReduction.riskReduction);
          this.setRiskReductionDateValue(this.selectedDirectService.clientMedicalRiskReduction.riskReductionDate);
        }
      }
      // if no current risk reduction exists for the direct service then check client history
      else if (this.forceSave && !!this.riskReductionHistory) {
        // YES within 6 months of answer OR NO within 1 month of answer both test cases below must be tested in order to be certain if riskReductionRequired is true
        // a positive case below means a valid answer within the time period so MUST negate it to determine if required
        this.riskReductionRequired = (!this.riskReductionHistory.some(f => parseISO(f.riskReductionCounselingLastUpdate) > addMonths(serverDateTime, -6) && f.riskReduction == YesNo.Yes));
        if (this.riskReductionRequired) this.riskReductionRequired = (!this.riskReductionHistory.some(f => parseISO(f.riskReductionCounselingLastUpdate) > addMonths(serverDateTime, -1) && f.riskReduction == YesNo.No));
        // spoke with Den on 10/28/2021 and he said do not display existing values from history, force user to enter brand new values      
        if (this.riskReductionRequired) this.riskReductionPanelVisible = this.riskReductionPanelEnabled = true;
        //var riskReductionMostRecent = orderBy(this.riskReductionHistory, o => parseISO(o.riskReductionCounselingLastUpdate), ['desc'])[0];
        //this.setRiskReductionYesNoValue(riskReductionMostRecent?.riskReduction);
        //this.setRiskReductionDateValue(riskReductionMostRecent?.riskReductionDate);
        //this.getRiskReductionDateControl().updateValueAndValidity();
      }
    }

    // treatment adherence settings
    this.treatmentAdherencePanelVisible = this.treatmentAdherencePanelEnabled = this.treatmentAdherenceRequired = this.treatmentAdherenceLoadValues = false;
    this.setTreatementAdherenceYesNoValue(YesNo.None);
    this.setTreatementAdherenceDateValue(this.originDate);    
    // if treatment adherence exists for the current direct service
    if (this.commonDataService.isSubTypeTreatmentAdherence(this.selectedDirectService?.serviceId, this.selectedDirectService?.subId)) {
      if (!!this.selectedDirectService.clientMedicalRiskReduction) 
      {
        // check each option
        if (this.forceSave && this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel enabled and show empty values to be force entered by user, creates new row in table
          this.treatmentAdherencePanelVisible = this.treatmentAdherencePanelEnabled = this.treatmentAdherenceRequired = true; this.treatmentAdherenceLoadValues = false;
        }
        else if (this.forceSave && !this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // hide panel, user not required to do anything since there are valid entries, no edit capability
          // Den said to always show Risk Reduction popup whether data was expired or not 10/24/2022, so set to true instead of false
          // this.treatmentAdherencePanelVisible = this.treatmentAdherencePanelEnabled = this.treatmentAdherenceRequired = false;
          this.treatmentAdherencePanelVisible = this.treatmentAdherencePanelEnabled = this.treatmentAdherenceRequired = this.treatmentAdherenceLoadValues = true;
        }
        else if (!this.forceSave && this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel disabled, user cannot change expired values, no edit capability
          this.treatmentAdherencePanelVisible = this.treatmentAdherenceLoadValues = true; this.treatmentAdherencePanelEnabled = this.treatmentAdherenceRequired = false;
        }
        else if (!this.forceSave && !this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel enabled, allow user to edit values, updates row in table
          this.treatmentAdherencePanelVisible = this.treatmentAdherencePanelEnabled = this.treatmentAdherenceRequired = this.treatmentAdherenceLoadValues = true;
        }
        if (this.treatmentAdherenceLoadValues) { // !this.forceSave
          this.setTreatementAdherenceYesNoValue(this.selectedDirectService.clientMedicalRiskReduction.treatmentAdherence);
          this.setTreatementAdherenceDateValue(this.selectedDirectService.clientMedicalRiskReduction.treatmentAdherenceDate);
        }
      }
      // if no current treatment adherence exists for the direct service then check client history
      else if (this.forceSave && !!this.riskReductionHistory) {
        // YES within 3 months of answer OR NO within 1 month of answer both test cases below must be tested in order to be certain if treatmentAdherenceRequired is true
        // a positive case below means a valid answer within the time period so MUST negate it to determine if required
        this.treatmentAdherenceRequired = (!this.riskReductionHistory.some(f => parseISO(f.treatementAdherenceCounselingLastUpdate) > addMonths(serverDateTime, -3) && f.treatmentAdherence == YesNo.Yes));
        if (this.treatmentAdherenceRequired) this.treatmentAdherenceRequired = (!this.riskReductionHistory.some(f => parseISO(f.treatementAdherenceCounselingLastUpdate) > addMonths(serverDateTime, -1) && f.treatmentAdherence == YesNo.No));
        // spoke with Den on 10/28/2021 and he said do not display existing values from history, force user to enter brand new values      
        if (this.treatmentAdherenceRequired) this.treatmentAdherencePanelVisible = this.treatmentAdherencePanelEnabled = true;
      }
    }

    // tobacco settings
    this.tobaccoPanelVisible = this.tobaccoPanelEnabled = this.tobaccoRequired = this.tobaccoLoadValues = false;
    this.setTobaccoYesNoValue(YesNo.None);
    this.setTobaccoCounselingYesNoValue(YesNo.None);
    this.setTobaccoCounselingDateValue(this.originDate);
    // if tobacco exists for the current direct service    
    if (this.commonDataService.isSubTypeTobacco(this.selectedDirectService?.serviceId, this.selectedDirectService?.subId)) {
      if (!!this.selectedDirectService.clientMedicalRiskReduction) 
      {
        // check each option
        if (this.forceSave && this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel enabled and show empty values to be force entered by user, creates new row in table
          this.tobaccoPanelVisible = this.tobaccoPanelEnabled = this.tobaccoRequired = true; this.tobaccoLoadValues = false;
        }
        else if (this.forceSave && !this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // hide panel, user not required to do anything since there are valid entries, no edit capability
          // Den said to always show Risk Reduction popup whether data was expired or not 10/24/2022, so set to true instead of false
          // this.tobaccoPanelVisible = this.tobaccoPanelEnabled = this.tobaccoRequired = false;
          this.tobaccoPanelVisible = this.tobaccoPanelEnabled = this.tobaccoRequired = this.tobaccoLoadValues = true;
        }
        else if (!this.forceSave && this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel disabled, user cannot change expired values, no edit capability
          this.tobaccoPanelVisible = this.tobaccoLoadValues = true; this.tobaccoPanelEnabled = this.tobaccoRequired = false;
        }
        else if (!this.forceSave && !this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel enabled, allow user to edit values, updates row in table
          this.tobaccoPanelVisible = this.tobaccoPanelEnabled = this.tobaccoRequired = this.tobaccoLoadValues = true;
        }
        if (this.tobaccoLoadValues) { // !this.forceSave
          this.setTobaccoYesNoValue(this.selectedDirectService.clientMedicalRiskReduction.tobaccoUse);
          this.setTobaccoCounselingYesNoValue(this.selectedDirectService.clientMedicalRiskReduction.tobaccoCounseling);
          this.setTobaccoCounselingDateValue(this.selectedDirectService.clientMedicalRiskReduction.tobaccoCounselingDate);
        }
      }
      // if no current tobacco exists for the direct service then check client history
      else if (this.forceSave && !!this.riskReductionHistory) {
        // YES within 6 months of answer OR NO within 1 month of answer both test cases below must be tested in order to be certain if tobaccoRequired is true
        // a positive case below means a valid answer within the time period so MUST negate it to determine if required
        this.tobaccoRequired = (!this.riskReductionHistory.some(f => parseISO(f.tobaccoCounselingLastUpdate) > addMonths(serverDateTime, -6) && f.tobaccoCounseling == YesNo.Yes));
        if (this.tobaccoRequired) this.tobaccoRequired = (!this.riskReductionHistory.some(f => parseISO(f.tobaccoCounselingLastUpdate) > addMonths(serverDateTime, -1) && f.tobaccoCounseling == YesNo.No));
        // spoke with Den on 10/28/2021 and he said do not display existing values from history, force user to enter brand new values      
        if (this.tobaccoRequired) this.tobaccoPanelVisible = this.tobaccoPanelEnabled = true;
      }
    }

    // alcohol settings
    this.alcoholPanelVisible = this.alcoholPanelEnabled = this.alcoholRequired = this.alcoholLoadValues = false;
    this.setAlcoholYesNoValue(YesNo.None);
    this.setAlcoholCounselingYesNoValue(YesNo.None);
    this.setAlcoholCounselingDateValue(this.originDate);
    // if alcohol exists for the current direct service
    if (this.commonDataService.isSubTypeAlcohol(this.selectedDirectService?.serviceId, this.selectedDirectService?.subId)) {
      //Fogbugz 2101 - Only ask alcohol question if patient is positive for HEP B or HEP C - 11/04
      var hasHepatitus = this.clientService.clientData?.clientMedicalHepatitisB?.positive == PosNegResults.Positive || 
                         this.clientService.clientData?.clientMedicalHepatitisC?.positive == PosNegResults.Positive;
      if (!!this.selectedDirectService.clientMedicalRiskReduction && hasHepatitus)
      {
        // check each option
        if (this.forceSave && this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel enabled and show empty values to be force entered by user, creates new row in table
          this.alcoholPanelVisible = this.alcoholPanelEnabled = this.alcoholRequired = true; this.alcoholLoadValues = false;
        }
        else if (this.forceSave && !this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // hide panel, user not required to do anything since there are valid entries, no edit capability
          // Den said to always show Risk Reduction popup whether data was expired or not 10/24/2022, so set to true instead of false
          // this.alcoholPanelVisible = this.alcoholPanelEnabled = this.alcoholRequired = false;
          this.alcoholPanelVisible = this.alcoholPanelEnabled = this.alcoholRequired = this.alcoholLoadValues = true;
        }
        else if (!this.forceSave && this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel disabled, user cannot change expired values, no edit capability
          this.alcoholPanelVisible = this.alcoholLoadValues = true; this.alcoholPanelEnabled = this.alcoholRequired = false;
        }
        else if (!this.forceSave && !this.helperService.isExpired(this.selectedDirectService.clientMedicalRiskReduction?.expired)) {
          // show panel enabled, allow user to edit values, updates row in table
          this.alcoholPanelVisible = this.alcoholPanelEnabled = this.alcoholRequired = this.alcoholLoadValues = true;
        }
        if (this.alcoholLoadValues) { // !this.forceSave
          this.setAlcoholYesNoValue(this.selectedDirectService.clientMedicalRiskReduction.alcoholUse);
          this.setAlcoholCounselingYesNoValue(this.selectedDirectService.clientMedicalRiskReduction.alcoholCounseling);
          this.setAlcoholCounselingDateValue(this.selectedDirectService.clientMedicalRiskReduction.alcoholCounselingDate);
        }
      }
      // if no current alcohol exists for the direct service then check client history
      else if (this.forceSave && !!this.riskReductionHistory && hasHepatitus) {
        // YES within 6 months of answer OR NO within 1 month of answer both test cases below must be tested in order to be certain if alcoholRequired is true
        // a positive case below means a valid answer within the time period so MUST negate it to determine if required
        this.alcoholRequired = (!this.riskReductionHistory.some(f => parseISO(f.alcoholCounselingLastUpdate) > addMonths(serverDateTime, -6) && f.alcoholCounseling == YesNo.Yes));
        if (this.alcoholRequired) this.alcoholRequired = (!this.riskReductionHistory.some(f => parseISO(f.alcoholCounselingLastUpdate) > addMonths(serverDateTime, -1) && f.alcoholCounseling == YesNo.No));
        // spoke with Den on 10/28/2021 and he said do not display existing values from history, force user to enter brand new values      
        if (this.alcoholRequired) this.alcoholPanelVisible = this.alcoholPanelEnabled = true;
      }
    }     

    // if no counseling panels are set to be visible then auto hide the form
    // if (!this.isAtLeastOneCounselingVisible()) this.onHideDialog();

    // if no counselings are required then allow user to just exit out without saving
    if (!this.isAtLeastOneCounselingRequired()) this.forceSave = false;
  }

  onChangeAlcoholYesNo(value: number) {
    if (value == YesNo.No) {
      this.setAlcoholCounselingYesNoValue(YesNo.No);
      this.getAlcoholCounselingYesNoControl().markAsDirty();
      this.onChangeAlcoholCounselingYesNo(value);
    }
  }

  onChangeAlcoholCounselingYesNo(value: number) {
    if (value == YesNo.No) {
      this.setAlcoholCounselingDateValue(this.originDate);
    }
    else {
      this.setAlcoholCounselingDateValue(this.getDeliveredDateValue());
    }
    this.getAlcoholCounselingDateControl().markAsDirty();
  }  

  onChangeTobaccoYesNo(value: number) {
    if (value == YesNo.No) { 
      this.setTobaccoCounselingYesNoValue(YesNo.No);
      this.getTobaccoCounselingYesNoControl().markAsDirty();
      this.onChangeTobaccoCounselingYesNo(value);
    }
  }

  onChangeTobaccoCounselingYesNo(value: number) {
    if (value == YesNo.No) {
      this.setTobaccoCounselingDateValue(this.originDate);
    }
    else {
      this.setTobaccoCounselingDateValue(this.getDeliveredDateValue());
    }
    this.getTobaccoCounselingDateControl().markAsDirty();    
  }

  onChangeRiskReductionYesNo(value: number) {
    if (value == YesNo.No) {
      this.setRiskReductionDateValue(this.originDate);
    }
    else {
      this.setRiskReductionDateValue(this.getDeliveredDateValue());
    }
    this.getRiskReductionDateControl().markAsDirty();
  }

  onChangeTreatementAdherenceYesNo(value: number) {
    if (value == YesNo.No) {
      this.setTreatementAdherenceDateValue(this.originDate);
    }
    else {
      this.setTreatementAdherenceDateValue(this.getDeliveredDateValue());
    }
    this.getTreatementAdherenceDateControl().markAsDirty();
  }

  futureDateValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const today = new Date().getTime();
      if(!(control && control.value)) {  // if there's no control or no value, that's ok
        return null;
      }
      var value = new Date(control.value);
      // remove hours minutes seconds and milliseconds
      value.setHours(0,0,0,0);
      // return null if there's no errors
      return value.getTime() > today ? {invalidDate: '*You cannot enter a future date.' }  : null;
    }
  }

  public alcoholValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!this.getAlcoholYesNoControl() || !this.getAlcoholCounselingYesNoControl() || !this.getAlcoholCounselingDateControl()) return null;

      var alcoholYesNoValue = this.getAlcoholYesNoValue();
      var alcoholCounselingYesNoValue = this.getAlcoholCounselingYesNoValue();
      
      if (this.alcoholRequired) {
        if (!(alcoholYesNoValue == YesNo.No || alcoholYesNoValue == YesNo.Yes))
        {
          return { invalidAlcoholUse: '*Alcohol Use value is required.' };
        }

        if (!(alcoholCounselingYesNoValue == YesNo.No || alcoholCounselingYesNoValue == YesNo.Yes))
        {
          return { invalidAlcoholCounseling: '*Alcohol Counseling value is required.' };
        }        

        var alcoholCounselingDateValue = this.helperService.removeTimeFromDate(this.getAlcoholCounselingDateValue());
        if (alcoholCounselingYesNoValue == YesNo.Yes && alcoholCounselingDateValue.getTime() == this.originDate.getTime()) {
          return { invalidAlcoholCounseling: '*Alcohol Counseling date is required.' };
        }
      }
      return null;
    }
  }    

  public tobaccoValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!this.getTobaccoYesNoControl() || !this.getTobaccoCounselingYesNoControl() || !this.getTobaccoCounselingDateControl()) return null;

      var tobaccoYesNoValue = this.getTobaccoYesNoValue();
      var tobaccoCounselingYesNoValue = this.getTobaccoCounselingYesNoValue();
      
      if (this.tobaccoRequired) {
        if (!(tobaccoYesNoValue == YesNo.No || tobaccoYesNoValue == YesNo.Yes))
        {
          return { invalidTobaccoUse: '*Tobacco Use value is required.' };
        }

        if (!(tobaccoCounselingYesNoValue == YesNo.No || tobaccoCounselingYesNoValue == YesNo.Yes))
        {
          return { invalidTobaccoCounseling: '*Tobacco Counseling value is required.' };
        }        

        var tobaccoCounselingDateValue = this.helperService.removeTimeFromDate(this.getTobaccoCounselingDateValue());
        if (tobaccoCounselingYesNoValue == YesNo.Yes && tobaccoCounselingDateValue.getTime() == this.originDate.getTime()) {
          return { invalidTobaccoCounseling: '*Tobacco Counseling date is required.' };
        }
      }
      return null;
    }
  }  

  public riskReductionValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!this.getRiskReductionDateControl() || !this.getRiskReductionYesNoControl()) return null;

      var riskReductionYesNoValue = this.getRiskReductionYesNoValue();
      
      if (this.riskReductionRequired) {
        if (!(riskReductionYesNoValue == YesNo.No || riskReductionYesNoValue == YesNo.Yes))
        {
          return { invalidRiskReduction: '*Risk Reduction Counseling value is required.' };
        }

        var riskReductionDate = this.helperService.removeTimeFromDate(this.getRiskReductionDateValue());
        if (riskReductionYesNoValue == YesNo.Yes && riskReductionDate.getTime() == this.originDate.getTime()) {
          return { invalidRiskReduction: '*Risk Reduction Counseling date is required.' };
        }

        if (compareAsc(riskReductionDate, this.helperService.castToDate(this.selectedDirectService.deliveredDate)) > 0) {
          return { invalidTreatmentAdherence: '*Risk Reduction Counseling date should be less than or equal to the Service delivery date.' };
        }

        if (compareAsc(riskReductionDate, addDays(this.helperService.castToDate(this.selectedDirectService.deliveredDate), -30)) < 0) {
          return { invalidTreatmentAdherence: '*Risk Reduction Counseling should be provided within 30 days prior to the medical visit.' };
        }
      }
      return null;
    }
  }

  public treatmentAdherenceValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!this.getTreatementAdherenceDateControl() || !this.getTreatementAdherenceYesNoControl()) return null;

      var treatementAdherenceYesNoValue = this.getTreatementAdherenceYesNoValue();
      
      if (this.treatmentAdherenceRequired) {
        if (!(treatementAdherenceYesNoValue == YesNo.No || treatementAdherenceYesNoValue == YesNo.Yes))
        {
          return { invalidTreatmentAdherence: '*Treatment Adherence Counseling value is required.' };
        }

        var treatementAdherenceDate = this.helperService.removeTimeFromDate(this.getTreatementAdherenceDateValue());
        if (treatementAdherenceYesNoValue == YesNo.Yes && treatementAdherenceDate.getTime() == this.originDate.getTime()) {
          return { invalidTreatmentAdherence: '*Treatment Adherence Counseling date is required.' };
        }

        if (compareAsc(treatementAdherenceDate, this.helperService.castToDate(this.selectedDirectService.deliveredDate)) > 0) {
          return { invalidTreatmentAdherence: '*Treatement Adherence Counseling date should be less than or equal to the Service delivery date.' };
        }

        if (compareAsc(treatementAdherenceDate, addDays(this.helperService.castToDate(this.selectedDirectService.deliveredDate), -30)) < 0) {
          return { invalidTreatmentAdherence: '*Treatement Adherence Counseling should be provided within 30 days prior to the medical visit.' };
        }
      }
      return null;
    }
  }  

}
