import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ProviderService } from './services/provider.service';
import {  ProviderInfoDataModel } from './models/provide.model';


@Component({
  selector: 'app-provider-info',
  templateUrl: './app.provider.component.html',
  styleUrls: ['./app.provider.component.scss']
})
export class AppProviderInfoComponent implements OnInit {

  constructor(public providerService:ProviderService) { }

  provider$: Observable<ProviderInfoDataModel>;

  ngOnInit(): void {
      this.getProviderData();
    
  }

  getProviderData(){

    this.provider$ =  this.providerService.providerInfoData();
  }

}
