<form [formGroup]='behavior_health' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                    <div class="field col-7">Mental Health Screening</div>
                    <div class="field col-4"></div>
                    <div class="field col-1">
                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('MHS', 'Mental Health Screening')"></button>      
                    </div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12">
                    <div class="formgroup-inline champ-grid">
                        <label>Result of latest Mental Health Screening for depression?</label>
                        <label id="idMentalHealthScreeningResultDate">
                            <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientScreeningMentalHealth" 
                                [fieldId]="MasterFields.MentalHealthScreeningResultDate" 
                                [lastUpdated]="this.clientData.clientScreeningMentalHealth?.screeningDate"
                                [formGroup]="behavior_health"
                                ></app-client-expired-field>
                        </label>                            
                    </div>
                </div>   

                <div class="field col-12">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-4">
                            <div class="formgroup-inline champ-grid" >
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idMHResultDate" style="margin-right: 1rem;">Date of Result</label> 
                                <input type="text" #idMHResultDatePicker name="idMHResultDate" autocomplete="off" bsDatepicker formControlName="idMHResultDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idMHResultDate" [maxDate]="maxResultDate" [minDate]="minResultDate"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : behavior_health.controls['idMHResultDate']?.invalid}" 
                                    class="champ-date-picker" pInputText/>
                            </div>
                        </div>

                        <div class="field col-8">
                            <div class="formgroup-inline champ-grid">
                                <div style="margin-left: 1.5rem;">
                                    <fa-icon class="champ-field-required" style="vertical-align: top !important;" [icon]="['fas', 'asterisk']"></fa-icon>
                                    <label>Result:</label>
                                    <p-radioButton formControlName="idMHResult" value=2 label="Negative" [style]="{'margin-left':'15px'}"></p-radioButton>
                                    <p-radioButton formControlName="idMHResult" value=1 label="Positive" [style]="{'margin-left':'15px'}"></p-radioButton>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="field col-2"></div> -->

                        <!-- next row for errors above -->
                        
                        <div class="field col-4">
                            <div *ngIf="behavior_health.controls['idMHResultDate']?.invalid">
                                <div *ngIf="behavior_health.controls['idMHResultDate']?.errors?.required"> 
                                    <small id="idMHResultDate-help" class="p-invalid" style="margin-left: 1rem;">Result Date is required.</small>
                                </div>
                                <div *ngIf="!behavior_health.controls['idMHResultDate']?.errors?.required && !!behavior_health.controls['idMHResultDate']?.errors"> 
                                    <small id="idMHResultDate2-help" class="p-invalid" style="margin-left: 1rem;">{{behavior_health.controls['idMHResultDate']?.errors['invalidDate']}}</small>
                                </div>                                                               
                            </div>
                         </div>

                        <div class="field col-6">
                            <div *ngIf="!!behavior_health.controls['idMHResult']?.errors"> 
                                <small id="idMHResult-help" class="p-invalid" style="margin-left: 2.5rem;">Result is required.</small>
                            </div>
                        </div>

                        <div class="field col-2"></div>
                    </div>

                    <!-- next row -->
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-5">
                            <div class="formgroup-inline champ-grid">
                                <label htmlFor="idMHDiagnosis" style="margin-right: 1rem;">Mental Health Diagnosis</label>
                            </div>
                        </div>
                        <div class="field col-7">
                            <div class="formgroup-inline champ-grid" [ngClass]="{'disableDiv': disableMentalHealthDiagnosisFields()}">
                                <p-radioButton formControlName="idMHDiagnosis" value=2 label="No" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idMHDiagnosis" value=1 label="Yes" [style]="{'margin-left':'15px'}"></p-radioButton>
                            </div>
                        </div>
                    </div>

                    <!-- next row -->
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-5">
                            <div class="formgroup-inline champ-grid">
                                <label htmlFor="idMHFollowup" style="margin-right: 1rem;">Mental Health Follow-up / Treatment Plan Documented?</label>
                            </div>
                        </div>
                        <div class="field col-7">
                            <div class="formgroup-inline champ-grid" [ngClass]="{'disableDiv': disableMentalHealthDiagnosisFields()}">
                                <p-radioButton formControlName="idMHFollowup" value=2 label="No" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idMHFollowup" value=1 label="Yes" [style]="{'margin-left':'15px'}"></p-radioButton>
                            </div>
                        </div>                        
                    </div>                    
                </div>
            </div>

        </div>
    </div>
</form>

<app-history ></app-history>