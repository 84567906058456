import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';
import { LoginService } from "../login/services/login.service";
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { initializeApp } from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {

  currentEnv = environment.environmentName;

  constructor(public auth: AuthService, public router: Router, public loginService:LoginService) { }

  // canActivate(): boolean {

  //   if (this.currentEnv == "LOCAL"){
  //      console.log(environment);
  //      console.log( this.auth.IsAuthenticated());
      
  //      //this.loginService.standAloneLogin();
  //   }

  //   if ( this.currentEnv != "LOCAL"  && !this.auth.IsAuthenticated()) {
  //         //  this.router.navigate(['']);
  //          console.log('Login required: ' + environment);
  //          console.log( this.auth.IsAuthenticated());
  //          //this.login.setLoginStatus(false,false,null,false);
           
  //     return false;
  //   }
  //   return true;
  // }


  canActivate(): Observable<boolean> {
    
    return new Observable<boolean>((observer) => {
      //const firebaseApp = initializeApp(environment.firebase );
      const auth = getAuth(); // Get the Firebase auth instance
      console.log('auth.currentUser: ', auth.currentUser);
      onAuthStateChanged(auth, (user) => {
        // Perform user authentication check
        if (auth.currentUser && auth.currentUser.emailVerified) {
          this.auth.isUserAuthenticated = true;
          
          // User is authenticated and email is verified, allow activation
 
          console.log('User is authenticated and email is verified, allow activation');
          this.loginService.validate(auth, auth.currentUser.email).subscribe({
            next: (resp) => {
                //observer.next() 
                console.log('Login is validated for authenticated user.');
                observer.next(true);
             }, 
            error: (error) => {
                console.log(error); observer.error(error);
                observer.next(false);
              }, 
            complete: ()=> { 
              //observer.complete(); // Do not fir the complete , will cancel the observer.next
            }
          });  


  
        } else {
          // User is not authenticated or email is not verified, redirect to login page
          console.log('User is not authenticated or email is not verified, redirect to login page');
          this.auth.isUserAuthenticated = false;
          this.router.navigate(["/"]); // Replace "/login" with the appropriate route
          observer.next(false);
          
        }
        //observer.complete();
      });
    });
 
  }

}
