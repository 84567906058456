import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
//import { ClientGroupProductParams, ClientProductsModel, ClientProductsCommonModel, ClientGroupsModel } from 'src/app/models/client.model'
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, FilterMetadata, MessageService } from 'primeng/api';
//import { HelperService } from '../services/helper.service';
//import { CommonDataService } from '../services/common-data.service';
//import { ProductEnum } from '../models/common-data.model';
import { subMonths } from 'date-fns';
import { FilterService } from 'primeng/api';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { LoginService } from 'src/app/login/services/login.service';
import { ErrorReportRequestModel, ErrorReportResponseModel } from 'src/app/models/admin.models';
import { LogType } from 'src/app/models/enums';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { ErrorLogService } from './error-log.service';

@Component({
  selector: 'app-error-log',
  templateUrl: './error-log.component.html',
  styleUrls: ['./error-log.component.scss']
})
export class ErrorLogComponent implements OnInit {
  fg: UntypedFormGroup;
  errors: Partial<ErrorReportResponseModel>[];

  @ViewChild('dtErrors', { static: true }) dt: any;

  getErrors = () => this.errors;
  getStartDate = () => this.fg?.get('startDate');
  getEndDate = () => this.fg?.get('endDate');
  getLogTypeSeriLog = () => this.fg?.get('logTypeSeriLog');
  getLogLevel = () => this.fg?.get('logLevel');

  // public readonly filters: { [key in keyof Partial<ErrorReportResponseModel>]: FilterMetadata[] } = {
  //   logLevel: [{ value: 'Error', matchMode: "startsWith", operator: "and" }]
  // };


  constructor(private fb: UntypedFormBuilder,
              private app: AppContextService,
              private authService: AuthService,
              private loginService: LoginService,
              //private clientService: ClientService,
              //private commonDataService: CommonDataService,
              private helper: HelperClassService,
              private router: Router,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private validations: FormValidations,
              private spinnerService: SpinnerService,
              private errorLogService: ErrorLogService,
              private filterService:FilterService
              ) {

      this.fg = fb.group({
        startDate: [, [Validators.required, validations.dateFutureDateValidator()]],
        endDate: [, [Validators.required]],
        logTypeSeriLog: [true],
        logLevel: ['']
      },
      {
        //validators: [this.customValidator()],
        //asyncValidators: [this.timePeriodValidator()]
        //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
      });      
  }

  ngOnInit(): void {
    // if(!this.authService.isUserAuthenticated){
    //   this.loginService.standAloneLogin().then(x=> this.loadData());
    // }
    // else this.loadData();  
    this.app.updateTabTitle('CHAMP - Error Log');
    this.loadData();  
  }

  loadData() {
    this.getStartDate().setValue(subMonths(new Date(), 3));
    this.getEndDate().setValue(new Date());    
    this.loadErrors();
    //this.filterService.filters.equals('logLevel', 'Error');   

    

  }

  loadErrors() {
    //var request: Partial<ErrorReportRequestModel>;
    var logTypeId = this.getLogTypeSeriLog()?.value === true ? LogType.SeriLog : LogType.ChampLog;
    var request: ErrorReportRequestModel = { logTypeId: logTypeId,
                                             logLevel: this.getLogLevel()?.value,
                                             startDate: this.helper.getISODateString(this.getStartDate().value),
                                             endDate: this.helper.getISODateString(this.getEndDate().value) };
    // Object.assign(request, {});
    // request.startDate = this.helper.getISODateString(this.getStartDate().value);
    // request.endDate = this.helper.getISODateString(this.getEndDate().value);

    this.spinnerService.showSpinnerUntilCompleted(this.errorLogService.getErrors(request)).subscribe({
      next: (data: ErrorReportResponseModel[]) => {
            //console.log(data);
            this.errors = data;
           // this.dt.filters['logLevel'] = [{value: "Error", matchMode: "startsWith", operator: "and"}];
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => { }
      });
  }

  onChangeFilter(e, dtErrors) {
    // dtErrors.clear(); Den said to not clear the filter
    this.loadErrors();
  }

}
