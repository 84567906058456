import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCommon, ClientMedicalOtherStiModel } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields, HIVStatus } from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { faGalacticSenate } from '@fortawesome/free-brands-svg-icons';
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { isEqual, cloneDeep } from 'lodash';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-other-sti',
  templateUrl: './other-sti.component.html',
  styleUrls: ['./other-sti.component.scss']
})
export class OtherStiComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  YesnoMedical: CommonData[];
  otherSTIDescriptions: SelectItem[];
  MedicalYesNo = MedicalYesNo;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();
  originalClientMedicalOtherSti: Partial<ClientMedicalOtherStiModel>[];
  isRowNew: boolean = false;
  isRowInEditMode: boolean = false;
  rowIndexInEditMode = -1;  


  getOtherSti = () => this.clientData?.clientMedicalOtherSti; 
  isStiRowInEditMode = (rowIndex: number) => (this.isRowInEditMode && (this.rowIndexInEditMode == rowIndex)) || this.isRowNew;

  getOtherSTIDateControl = () => this.medical?.get('idOtherSTIDate');
  getOtherSTIPositiveControl = () => this.medical?.get('idOtherSTIPositive');
  getOtherSTIDescriptionControl = () => this.medical?.get('idOtherSTIDescription');

  getOtherSTIDescription = (id: number) => this.commonDataService.getDescription("OtherSti", id);
  getOtherSTIDateValue = (otherSTIDate) => format(parseISO(otherSTIDate), 'MM/dd/yyyy');
  getClientMedicalOtherStiResults = () => this.clientData?.clientMedicalOtherSti?.filter(f => !f.deleted);
  validSTIResults = () => (this.getOtherSTIDateControl().valid ?? false) && (this.getOtherSTIPositiveControl().valid ?? false) && (this.getOtherSTIDescriptionControl().valid ?? false);

  constructor(private fb: UntypedFormBuilder,
              private confirmationService: ConfirmationService,
              private commonDataService:CommonDataService,
              public providerService: ProviderService,
              private userService:UserService,
              public clientService:ClientFormService,
              private datePipe: DatePipe,
              private dateHelper:DateHelper,
              public  validationHelper:FormValidations,
              private helperService: HelperClassService,
              private medcommon:MedicalCommon) { 

  }

  ngOnInit(): void {
    this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
    this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate, 0);
    this.YesnoMedical = this.commonDataService.getCommonSetByName("YesnoMedicalNa", true);
    this.otherSTIDescriptions = this.commonDataService.getCommonSetByName("OtherSti", false).map(data => { const result: any = {value:parseInt(`${data.id}`), label:`${data.description}`};
                                                                                                           return result; });

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       // notification for client loading/refreshing
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { this.userService.LogError(error); },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             // need this for comparing if anything changed in order to send to backend Web API save routine
                             this.originalClientMedicalOtherSti = cloneDeep(this.clientData.clientMedicalOtherSti);
                             this.setFieldValues();
                           }
                         });
     }


  }

  refreshAfterSave() {
    //this.clientData.clientMedicalFemalePapSmear.lastUpdate = new Date().toISOString();
    this.setFieldValues();
    // reset as original, need this for comparing if anything changed in order to send to backend Web API save routine
    this.originalClientMedicalOtherSti = (!!this.clientData?.clientMedicalOtherSti) ? cloneDeep(this.clientData.clientMedicalOtherSti) : null;
    this.medical.markAsUntouched();
    this.isRowInEditMode = false;
  }  

  setFieldValues() {
    if (!this.clientData) return;
    // Birth Results section
    if (this.clientData?.clientMedicalOtherSti?.length > 0 && !this.helperService.isExpired(this.clientData?.clientMedicalOtherSti[0].expired)) {
      console.log(this.clientData?.clientMedicalOtherSti[0]);
    }
  }

  getOtherSTIModelArray() {
    if (!this.clientData?.clientMedicalOtherSti) return null;

    if (!isEqual(this.clientData.clientMedicalOtherSti, this.originalClientMedicalOtherSti))
    {
      return this.clientData.clientMedicalOtherSti;
    }
    return null;
  }

  onRowEditInit(event: Event, clientMedicalOtherStiResult: Partial<ClientMedicalOtherStiModel>, rowIndex: number) {
    this.rowIndexInEditMode = rowIndex; 
    this.isRowInEditMode = true;
    this.clientService.disableSave=true;
    //this.clonedClientMedicalOtherSti[clientMedicalOtherSti.guid] = {...clientMedicalOtherSti};
    this.getOtherSTIDateControl().setValue(parseISO(clientMedicalOtherStiResult.datetime));
    this.getOtherSTIPositiveControl().setValue(clientMedicalOtherStiResult.positive);
    this.getOtherSTIDescriptionControl().setValue(clientMedicalOtherStiResult.id);
  }

  onRowEditSave(clientMedicalOtherStiResult: ClientMedicalOtherStiModel, index: number) {
    // shuldn't need to do this, 2 way binding should handle
    // this.clientData.ClientMedicalOtherSti[index] = {...clientMedicalOtherSti};
    // this.clientData.ClientMedicalOtherSti[index].birthDate = new Date(this.getOtherSTIDateControl().value).toISOString();
    clientMedicalOtherStiResult.datetime = formatISO(this.getOtherSTIDateControl().value, { representation: 'date' }); //new Date(this.getOtherSTIDateControl().value).toISOString();
    clientMedicalOtherStiResult.positive = this.getOtherSTIPositiveControl().value;
    clientMedicalOtherStiResult.id = this.getOtherSTIDescriptionControl().value;
    this.isRowInEditMode = this.isRowNew = false;
    this.clientService.disableSave=false;
  }

  onRowEditCancel(clientMedicalOtherStiResult: ClientMedicalOtherStiModel, index: number) {

    if (this.isRowNew) {
      var removedGroup = this.getOtherSti().shift();
    }
    else {
      this.getOtherSTIDateControl().setValue(format(parseISO(clientMedicalOtherStiResult.datetime), 'MM/dd/yyyy'));
      this.getOtherSTIPositiveControl().setValue(clientMedicalOtherStiResult.positive);
      this.getOtherSTIDescriptionControl().setValue(clientMedicalOtherStiResult.id);
    }
    this.isRowInEditMode = this.isRowNew = false;
    this.clientService.disableSave=false;
  }

  onRowDelete(event: Event, clientMedicalOtherStiResult: ClientMedicalOtherStiModel, index: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete the selected row?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        clientMedicalOtherStiResult.deleted = true;
      }
    });    
  }

  onRowAdd(dt) {
    var model: Partial<ClientMedicalOtherStiModel> = new ClientMedicalCommon(this.userService.userID);
    model.guid = uuidv4();
    model.clientGuid = this.clientService.clientData.guid;
    var index = this.otherSTIDescriptions.findIndex(f => f.value == 0);
    model.id = this.otherSTIDescriptions[index == -1 ? 0 : index].value;
    model.datetime = new Date().toISOString();
    model.positive = false;
    model.unavailable = false;
    model.deleted = false;
    //var len = this.clientData.clientMedicalOtherSti.push(model) - 1;
    //this.clientData.ClientMedicalOtherSti.splice(0, 0, model);
    var len = this.clientData.clientMedicalOtherSti.unshift(model) ;
    var index = 0;
    this.isRowNew = true;
    dt.clear();
    this.onRowEditInit(null, model, index);
    //dt.initRowEdit(this.clientData.clientMedicalOtherSti[len]()[index]);
    dt.initRowEdit(this.clientData.clientMedicalOtherSti[index]);
  }

  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }
}
