import { Component, OnInit } from '@angular/core';
import { LoginService } from './login/services/login.service';
//import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { ErrorMessagesService } from './common/error-messages/error-messages.service';
import { SpinnerService } from './common/spinner/spinner.service';
import { UserModel } from './login/services/login-models';
//import { VersionCheckService } from './services/version-check.service'
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { IdleService } from './services/idle-service.service';
import { AuthService } from './auth/auth.service';
import { AppContextService } from './app-context/app-context.service';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [
            SpinnerService,
            ErrorMessagesService
    ]
})
export class AppComponent implements OnInit {
    title = 'CHAMP';
    users: any; 
    url:string;

  

    constructor(
                private app: AppContextService,
                private accountService: LoginService,
                private idleService: IdleService,
                private router: Router,
                private authService:AuthService,
                private messageService: MessageService    ) 
    {

        authService.initializeApp();

        this.idleService.startIdleMonitoring();

        idleService.idle.onIdleEnd.subscribe(() => idleService.idle.idleState = 'No longer idle.');

        idleService.idle.onTimeout.subscribe(() => {
            idleService.idleState = 'Timed out!';
            idleService.timedOut = true;
            this.app.overLayMangr.closeAll();
            //console.log(idleService.idleState);
            this.router.navigate(['/logout']);
        });

        idleService.idle.onIdleStart.subscribe(() => {
            idleService.idleState = 'You\'ve gone idle!';
            //console.log(idleService.idleState);
        }  );

        idleService.idle.onTimeoutWarning.subscribe((countdown) => { 
            idleService.idleState = 'You will time out in ' + countdown + ' seconds!';
            //console.log(idleService.idleState);
        });


        // sets the ping interval to 15 seconds
        idleService.keepalive.interval(15);
        idleService.keepalive.onPing.subscribe(() => idleService.idle.lastPing = new Date());
        idleService.reset();
    }


    ngOnInit() {
        //this.setCurrentUser(); // not needed because the login service should set the user object
        
    }

    setCurrentUser() {
        const user: UserModel = JSON.parse(localStorage.getItem('champ-user-data'));
        if (user) {
            this.accountService.setCurrentUser(user);
        }
    }

}
