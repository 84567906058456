<form [formGroup]='identification' autocomplete="off" autocapitalize="on">
    <div class="p-fluid p-formgrid grid">
        <div class="field col-12 xl:col-6">
            <div class="card champ-card-sm">
                <div class="champ-card-header-sm" >Name - Address</div>
                <div class="champ-card-body-sm" >
                    <!-- row 1 -->
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-4">
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                            <span style="vertical-align:text-top"><label htmlFor="idFirstName">First Initials ( 3 req. )</label></span>
                            <div class="formgroup-inline">
                                <p-inputMask formControlName="idFirstName" mask="aaa" maxlength="3" [style]="{'width': '45px', 'padding': '0px 0px 0px 7px', 'text-transform': 'uppercase'}" ></p-inputMask>
                            </div>
                            <div *ngIf="identification.controls['idFirstName'].invalid && (identification.controls['idFirstName'].dirty || identification.controls['idFirstName'].touched)"> 
                                <div *ngIf="identification.controls['idFirstName'].errors.required">
                                    <small id="idFirstName-help" class="p-invalid">First 3 characters are required.</small>
                                </div>
                            </div>
                        </div>

                        <div class="field col-4">
                            <!-- <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> -->
                            <span style="vertical-align:text-top"><label htmlFor="idMiddleName">Middle Initial ( 1 req. )</label></span>
                            <div class="formgroup-inline">
                                <p-inputMask formControlName="idMiddleName"  mask="a" maxlength="1" [style]="{'width': '30px', 'padding': '0px 0px 0px 7px', 'text-transform': 'uppercase'}"></p-inputMask>
                            </div>
                        </div> 
                        
                        <div class="field col-4">
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                            <span style="vertical-align:text-top">
                                <label htmlFor="idLastName">Last Initials ( 3 req. )</label>
                            </span>
                            <div class="formgroup-inline">
                                <p-inputMask formControlName="idLastName" mask="aaa" maxlength="3" [style]="{'width': '45px', 'padding': '0px 0px 0px 7px', 'text-transform': 'uppercase'}"></p-inputMask>
                                <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" (click)="openHistoryWindow('NAME', 'Client Name')"  ></button> 
                            </div>
                            <div *ngIf="identification.controls['idLastName'].invalid && (identification.controls['idLastName'].dirty || identification.controls['idLastName'].touched)"> 
                                <div *ngIf="identification.controls['idLastName'].errors.required"> 
                                    <small id="idFirstName-help" class="p-invalid">First 3 characters are required.</small>
                                </div>                    
                            </div>                    
                        </div> 
                        
                        <!-- <div class="field col-6">
                                &nbsp;
                        </div>   -->

                        <!-- row 2  -->
                        <div class="field col-4">
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                            <span style="vertical-align:text-top"> 
                                <label htmlFor="idZipCode">Zip Code</label>  
                                <label #idZipCodeExpire id="idZipCodeExpire">
                                    <app-client-expired-field *ngIf="!!this.clientData" [fieldId]="MasterFields.ZIPCode" 
                                        [lastUpdated]="this.clientData.clientZipCodes?.datetime" [control]="identification.controls['idZipCode']"
                                        (renewValue)="onRenewValue($event)" (isFieldExpired)="onCheckedExpiredField($event)">
                                    </app-client-expired-field>
                                </label>
                            </span>
                            
                            <div class="formgroup-inline">
                                <p-inputMask formControlName="idZipCode"  mask="99999" maxlength="5" [style]="{'width': '80px', 'margin-right': '10px'}" slotChar="" (onBlur)="onBlurZipCode()" ></p-inputMask>
                                <button pButton pRipple  type="button" label="History" class=" p-button-text p-button-sm champ_history_button "  (click)="openHistoryWindow('ZIP', 'Zip Code')"></button>  
                            </div>
                            <div *ngIf="identification.controls['idZipCode'].invalid && (identification.controls['idZipCode'].dirty || identification.controls['idZipCode'].touched)"> 
                                <div *ngIf="identification.controls['idZipCode'].errors.required">
                                    <small id="idZipCode-help" class="p-invalid">ZIP Code is required.</small>
                                </div>
                            </div>
                        </div>

                        <div class="field col-4">
                        <span style="vertical-align:text-top">
                                <label htmlFor="idCity">City</label> 
                            </span>
                        <div class="formgroup-inline">
                                <!-- <p-inputMask formControlName="idCity"   [style]="{'width': '200px', 'margin-right': '10px'}"  ></p-inputMask> -->
                                <!-- <p-inputMask formControlName="idCity"  maxlength="30" [style]="{'width': '40px', 'padding': '0px 0px 0px 7px'}"></p-inputMask> -->
                                <span style="font-weight: bold; font-size: 10px;"> {{identification.controls['idCity'].value }}</span>
                            </div>                   
                        </div> 
                        
                        <div class="field col-2">
                            <span style="vertical-align:text-top">
                                <label htmlFor="idState">State</label> 
                                <div class="formgroup-inline">
                                    <!-- <p-inputMask type="text" formControlName="idState" [style]="{'width': '50px', 'margin-right': '10px'}"   ></p-inputMask> -->
                                    <span style="font-weight: bold; font-size: 10px;"> {{identification.controls['idState'].value }}</span>
                                </div>
                        </span>
                        </div> 

                        <div class="field col-2">
                            <span style="vertical-align:text-top">
                                <label htmlFor="idCounty">County</label> 
                                <div class="formgroup-inline">
                                    <!-- <p-inputMask type="text" formControlName="idCounty" [style]="{'width': '200px', 'margin-right': '10px'}"   ></p-inputMask> -->
                                    <span style="font-weight: bold; font-size: 10px;"> {{identification.controls['idCounty'].value }}</span>
                                </div>
                            </span>
                        </div> 
                        
                        <!-- row 3  -->
                        <div class="field col-4">
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                            <span style="vertical-align:text-top"> 
                                <label htmlFor="idSSN">SSN </label> 
                                <!-- <label id="idSSNExpire" class="champ-field-expires" >(Expires on 3/1/22)</label> -->
                            </span>
                            <div class="formgroup-inline">
                                <p-inputMask formControlName="idSSN"  mask="9999" minlength="4" slotChar="" [style]="{'width': '80px', 'margin-right': '10px'}" (onblur)="onBlurSSN()" ></p-inputMask>
                                <!-- <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button"  ></button>  -->
                                
                            </div>
                            <div *ngIf="identification.controls['idSSN'].invalid && (identification.controls['idSSN'].dirty || identification.controls['idSSN'].touched)"> 
                                <div *ngIf="identification.controls['idSSN'].errors.required">
                                    <small id="idSSN-help" class="p-invalid">SSN is required.</small>
                                </div>
                            </div>
                        </div>
                        <div class="field col-8" >
                            <!-- <span style="vertical-align:text-top"> 
                                <label for="idEmployee">Employee</label>
                            </span> -->
                            <div class="formgroup-inline">
                                <!-- <p-dropdown formControlName="idEmployee" [options]="employees" optionLabel="fullName" optionValue="id" appendTo="body" 
                                        [style]="{'width': '250px', 'margin-right': '10px'}" > </p-dropdown> -->
                                <app-employee mode="client" (onChange)="onEmployeeChange($event)"></app-employee>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
            
            <div class="card champ-card-sm">
                <div class="champ-card-header-sm" >Provider Data</div>
                <div class="champ-card-body-sm" >
                    <div class="p-fluid p-formgrid grid">

                        <div class="field col-6">
                            <span style="vertical-align:text-top">
                                <!-- <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> -->
                                <label htmlFor="idPCP">Primary Care Physician</label> 
                                <div class="formgroup-inline">
                                    <p-dropdown formControlName="idPCP" [options]="pcps" optionLabel="fullName" optionValue="id" appendTo="body" 
                                             [style]="{'width': '100%', 'margin-right': '10px'}"  > </p-dropdown>      
                                 </div>
                                 <!-- <div  *ngIf="identification.controls['idPCP'].invalid && (identification.controls['idPCP'].dirty || identification.controls['idPCP'].touched)"> 
                                    <div *ngIf="identification.controls['idPCP'].errors.required"> 
                                        <small id="idPCP-help" class="p-invalid">Gender is required.</small>
                                    </div>
                                </div> -->
                            </span>
                        </div> 

                        <div class="field col-6">
                           <!-- <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> -->
                           <span style="vertical-align:text-top"><label htmlFor="idFirstAmbulatoryCareVisitDate">First Ambulatory Care Visit Date</label></span>
                           <div class="formgroup-inline">
                               <input type="text" name="idFirstAmbulatoryCareVisitDateControl" autocomplete="off" bsDatepicker formControlName="idFirstAmbulatoryCareVisitDate"
                                       [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idFirstAmbulatoryCareVisitDate"    
                                       placeholder="MM-DD-YYYY" style="width:90px"
                                       [ngClass]="{'p-invalid' : identification.controls['idFirstAmbulatoryCareVisitDate'].invalid}" 
                                       class="champ-date-picker" pInputText/>
                           </div>
                           <!-- <div  *ngIf="identification.controls['idFirstAmbulatoryCareVisitDate'].invalid && (identification.controls['idFirstAmbulatoryCareVisitDate'].dirty || identification.controls['idFirstAmbulatoryCareVisitDate'].touched)"> 
                               <div *ngIf="identification.controls['idFirstAmbulatoryCareVisitDate'].errors.required"> 
                                   <small id="idFirstAmbulatoryCareVisitDate-help" class="p-invalid">Birth Date is required.</small>
                               </div>
                           </div>  -->
                       </div>
               

                    </div>
                </div>
            </div>
        </div>

        <div class="field col-12 xl:col-6">
            <div class="card champ-card-sm">
                <div class="champ-card-header-sm" >Age - Gender</div>
                    <div class="champ-card-body-sm" >
                        <div class="p-fluid p-formgrid grid">

                            <div class="field col-4">
                                 <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <span style="vertical-align:text-top"><label htmlFor="idBirthDateControl">Birth Date</label></span>
                                <div class="formgroup-inline">
                                    <input type="text" name="idBirthDateControl" autocomplete="off" bsDatepicker formControlName="idBirthDate"
                                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idBirthDate"    
                                            placeholder="MM-DD-YYYY" style="width:90px" (change)="onChangeBirthDate($event)" [maxDate]="maxBirthDate" [minDate]="minBirthDate"
                                            [ngClass]="{'p-invalid' : identification.controls['idBirthDate'].invalid}" 
                                            class="champ-date-picker" pInputText/>

                                            <!--   [maxDate]="maxBirthDate" [minDate]="minBirthDate" -->
                                </div>
                                <div  *ngIf="identification.controls['idBirthDate'].invalid && (identification.controls['idBirthDate'].dirty || identification.controls['idBirthDate'].touched)"> 
                                    <div *ngIf="identification.controls['idBirthDate'].errors.required"> 
                                        <small id="idBirthDate-help" class="p-invalid">Birth Date is required.</small>
                                    </div>
                                    <div  *ngIf="!identification.controls['idBirthDate'].errors.required && identification.controls['idBirthDate'].errors"> 
                                        <small id="idBirthDate-help" class="p-invalid">{{identification.controls['idBirthDate'].errors['invalidDate']}}</small>
                                    </div> 
                                </div> 
     
                                
                            </div>
                    
                            <div class="field col-2">
                                <span style="vertical-align:text-top">
                                    <label htmlFor="idCounty">Age</label> 
                                    <div class="formgroup-inline">
                                        <!-- <p-inputMask type="text" formControlName="idCounty" [style]="{'width': '200px', 'margin-right': '10px'}"   ></p-inputMask> -->
                                         <label id="idAge" style="font-weight: bold;">{{ identification.get('idAge').value}}</label> 
                                         
                                    </div>
                                </span>
                            </div> 

                            <div class="field col-6">
                                <span style="vertical-align:text-top">
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                    <label htmlFor="idGender">Gender</label> 
                                    <div class="formgroup-inline">
                                        <p-dropdown formControlName="idGender" [options]="genders" optionLabel="description" optionValue="id" appendTo="body" 
                                                 [style]="{'width': '100%', 'margin-right': '10px'}" (onChange)="onChangeGender($event)"  > </p-dropdown>      
                                     </div>
                                     <div  *ngIf="identification.controls['idGender'].invalid && (identification.controls['idGender'].dirty || identification.controls['idGender'].touched)"> 
                                        <div *ngIf="identification.controls['idGender'].errors.required"> 
                                            <small id="idGender-help" class="p-invalid">Gender is required.</small>
                                        </div>
                                        <div  *ngIf="!identification.controls['idGender'].errors.required && identification.controls['idGender'].errors"> 
                                            <small id="idGender-help" class="p-invalid">{{identification.controls['idGender'].errors['invalidSelection']}}</small>
                                        </div>                                         
                                    </div>
                                </span>
                            </div> 

                        </div>
                    </div>
            </div>

            <div class="card champ-card-sm">
                <div class="champ-card-header-sm" >NOTES</div>  
                <div class="champ-card-body-sm" >
                    <label for="idNotes">Notes</label>
                    <textarea formControlName="idNotes" id="dsNotes" rows="15" cols="25" pInputTextarea style="height:147px;"></textarea>
                </div>   
            </div>    
        </div>
        <!-- <div class="field col-12 xl:col-6">
            <div class="card champ-card-sm">
                <div class="champ-card-header-sm" >NOTES</div>  
                <div class="champ-card-body-sm" >
                    <label for="dsNotes">Notes</label>
                    <textarea formControlName="dsNotes" id="dsNotes" rows="5" cols="25" pInputTextarea style="height:80px;"></textarea>
                </div>   
            </div>    
        </div>            -->

        <!-- <div class="field col-12 xl:col-6">
            <div class="card ">
                Form value : {{identification.value | json}}
            </div>
        </div>   -->
    </div>
</form>

<app-history ></app-history> 