<div>
    <p-toast escape="false"></p-toast> <!-- position="center"  -->
    <p-confirmPopup></p-confirmPopup>   
</div>

<div class="fluid formgrid grid">
        <div class="col-1 champ-page-title">
            Client List
        </div>

        <div class="col-3">
            <div class="form-group">
                    <label class="control-label mr-2" for="drpClientStatus" > Status: </label>
                    <select id="drpClientStatus"  class="form-control" [(ngModel)]="_clientStatus" (change)="onChangeStatus($event)" >
                        <option *ngFor="let item of _clientStatusList" [ngValue]="item.id" [ngStyle]="{'font-weight':item.id === '0' ? 'bold' : 'normal' }">{{item.description}}</option>
                    </select>   
            </div>      
        </div>
    
        <div class="col-5">
            <label class="control-label mr-2" for="drpContracts">Contract:</label>
            <select id="drpContracts" class="form-control" [(ngModel)]="_contractGuid"  (change)="onChangeContract($event)">
                <option *ngFor="let item of _contracts" [ngValue]="item.value" [ngStyle]="{'font-weight':item.value === '*' ? 'bold' : 'normal' }">{{item.text}}</option>
            </select>

        </div>

        <div class="col-2">
                <label class="mr-2" for="clientID">Client ID:</label>
                <input type="text" class="form-control" id="clientID"  (change)="onChangeClientID($event)"   [(ngModel)]="_clientID" > 
        </div>        

        
        <div class="col-5 p-buttonset  m-0" #AlphaButtons>
            <button pButton pRipple type="button" label="A" class="p-button-help p-button-text champ_alpha"   (click)="applyAlpha('A')"></button>
            <button pButton pRipple type="button" label="B" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('B')"></button>
            <button pButton pRipple type="button" label="C" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('C')"></button>
            <button pButton pRipple type="button" label="D" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('D')"></button>
            <button pButton pRipple type="button" label="E" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('E')"></button>
            <button pButton pRipple type="button" label="F" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('F')"></button>
            <button pButton pRipple type="button" label="G" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('G')"></button>
            <button pButton pRipple type="button" label="H" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('H')"></button>
            <button pButton pRipple type="button" label="I" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('I')"></button>
            <button pButton pRipple type="button" label="J" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('J')"></button>
            <button pButton pRipple type="button" label="K" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('K')"></button>
            <button pButton pRipple type="button" label="L" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('L')"></button>
            <button pButton pRipple type="button" label="M" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('M')"></button>
            <button pButton pRipple type="button" label="N" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('N')"></button>
            <button pButton pRipple type="button" label="O" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('O')"></button>
            <button pButton pRipple type="button" label="P" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('P')"></button>
            <button pButton pRipple type="button" label="Q" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('Q')"></button>
            <button pButton pRipple type="button" label="R" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('R')"></button>
            <button pButton pRipple type="button" label="S" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('S')"></button>
            <button pButton pRipple type="button" label="T" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('T')"></button>
            <button pButton pRipple type="button" label="U" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('U')"></button>
            <button pButton pRipple type="button" label="V" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('V')"></button>
            <button pButton pRipple type="button" label="W" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('W')"></button>
            <button pButton pRipple type="button" label="X" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('X')"></button>
            <button pButton pRipple type="button" label="Y" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('Y')"></button>
            <button pButton pRipple type="button" label="Z" class="p-button-help p-button-text  p-button champ_alpha"   (click)="applyAlpha('Z')"></button>
            <button pButton pRipple type="button" label="ALL" class="p-button-help p-button-text  p-button champ_alpha"  (click)="applyAlpha('*')"></button>
        

        </div>

        <div class="flex col-2  m-0" style="justify-content: left;">
            <!-- <a class="alpha">Reset Filters</a> -->
            <!-- <button #MostRecentClients pButton type="button" label="Recent Clients" class="p-button-link" style="width: 10rem;" (click)="applyFiltersClick(true)"></button> -->
            <!-- <button pButton type="button" label="My Clients" class="p-button-link" style="width: 10rem;"></button>        -->
            
            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'14rem'}"  styleClass="p-button-text " class="p-button-text mr-1 "
                             (click)="getMostRecentClients()"  > 
                <i class="fa-sharp  fa-solid  fa-files fa-lg" ></i>    <span >Recent Clients</span>
            </p-button>
            <!-- <i class="fa-sharp fa-solid fa-rectangle-history"></i> -->
            <!-- <i class="fa-solid fa-rectangle-history"></i> -->
            <!-- </div> -->
        
        <!-- <div class="col-1"> -->
            <!-- <a class="alpha">Reset Filters</a> -->
            <!-- <button pButton pRipple type="button" label="Reset Filters" class="p-button-help p-button-text  p-button" style="font-weight: bold;"  (Click)="resetFiltersClick()"></button> -->
            
            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'15rem'}" styleClass="p-button-text " class=" p-button-text  mr-1 "
                                (Click)="resetFiltersClick()"  > 
                <i class="fa-sharp fa-refresh fa-lg" ></i> <span>Reset Filters</span>
            </p-button>
        </div>

        
        <div class="flex col-4 m-0" style="justify-content: right;">
            
            <!-- <button pButton type="button" label="New Client" icon="pi pi-plus" style="width: 10rem; margin-right: 1rem;" (click)="onNewClient()" *ngIf="(userService.hasAdminPermission || userService.hasEditPermissions);"></button> -->

            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'15rem'}"  styleClass="p-button-text " class="p-button-text mr-1 "
                                (click)="onNewClient()" *ngIf="(userService.hasAdminPermission || userService.hasEditPermissions);"  > 
                <i class="fa-sharp fa-plus fa-lg" ></i> <span>New Client</span>
            </p-button>


            <!-- <button pButton type="button" label="Bulk/Group" icon="pi pi-plus" style="width: 10rem;" (click)="onNewClient()" *ngIf="(userService.hasAdminPermission || userService.hasEditPermissions);"></button> -->
            <!-- <td><a routerLink="../client-form/{{ClientListResponse.clientGuid}}"    routerLinkActive="active" > {{ClientListResponse.clientAltID}}</a></td>   -->

            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'15rem'}"  styleClass="p-button-text " class="p-button-text mr-1 " (click)="onLoadBulkGroup('bulk')"> 
                <i class="fa-sharp fa-solid fa-list-check fa-lg" pTooltip="Refresh data. Any unsaved changes will be lost."  ></i>
                <span>Bulk Services</span>
            </p-button>

            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'15rem'}"  styleClass="p-button-text " class="p-button-text mr-1 " (click)="onLoadBulkGroup('group')"> 
                <i class="fa-sharp fa-solid fa-people-group fa-lg" pTooltip="Refresh data. Any unsaved changes will be lost."  ></i>
                <span>Group Services</span>
            </p-button>  

     
        </div>

        <div class="flex col-1  m-0   justify-content-end" >
            <!-- 
            @if( this.app.userServ?.hasAdminPermission)
            {
               <button pButton pRipple type="button" label={{deletedLabel}} id="deletedButton"  class="p-button-text   p-button" style="font-weight: bold; border-style: none;"  (click)="showDeletedClicked()"></button>    
            }
               -->
            @if( this.app.userServ?.hasAdminPermission)
            { 
                <button pButton pRipple type="button"  label={{adminFeatureLabel}} id="deletedButton"  class="p-button-text   p-button" style="font-weight: bold; border-style: none;"  (click)="showAdminFeatures()"></button>    
            }
       </div>     
</div>

<div class="container-fluid" id="grid" > 

    <p-table  id="clientList"  [value]="_clientList" styleClass="p-datatable-sm p-datatable-striped " name="table1"    [first]="currentPage" scrollHeight="56vh"
            [paginator]="true" [rows]="15" [showCurrentPageReport]="true"  currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" 
            [rowsPerPageOptions]="[20,40,60,80,100,200,300,500]" 
            (onPage)="pageChange($event)"    >
        <ng-template pTemplate="header">
            <tr>
                <!-- <th  pSortableColumn="clientGuid" style="width:250px; margin: 5px;" >Client Guid <p-sortIcon field="clientGuid"></p-sortIcon></th> --> <!--hidden-->
                @if( this.app.userServ?.hasAdminPermission && this.adminFeatures){
                    <th style="max-width:30px;" class="m-0 p-0"></th>
                }
                <th  pSortableColumn="clientAltID" style="width:100px; margin: 5px;" >Client Alt ID<p-sortIcon field="clientGuid" ></p-sortIcon></th>
                <th  pSortableColumn="name" style="width:80px; margin: 5px; ">Name<p-sortIcon field="name"></p-sortIcon></th>
                <th  pSortableColumn="ssn" style="width:80px; margin: 5px; ">SSN<p-sortIcon field="ssn"></p-sortIcon></th>
                <th  style="width:75px; margin: 5px; ">Birth Date</th>
                <th  pSortableColumn="eis" style="width:50px; margin: 5px; ">EIS<p-sortIcon field="eis"></p-sortIcon></th>
                <th  pSortableColumn="linkage" style="width:50px; margin: 5px; ">Linkage<p-sortIcon field="linkage"></p-sortIcon></th>
                <th  pSortableColumn="linkageDate" style="width:100px; margin: 5px; ">Linkage Date<p-sortIcon field="linkageDate"></p-sortIcon></th>
                <th  pSortableColumn="linkage" style="width:50px; margin: 5px; " >Medical History</th>
                <th  style="width:50px; margin: 5px; ">QM</th>
                <th  pSortableColumn="cohortStudyLatest" style="width:50px; margin: 5px; ">COHORT<p-sortIcon field="cohortStudyLatest"></p-sortIcon></th>
                <th  pSortableColumn="clientStatus" style="width:200px; margin: 5px;" >Client Status<p-sortIcon field="clientStatusDesc"></p-sortIcon></th>
                <th style="width:100px; margin: 5px; ">Employee</th>
                <th pSortableColumn="hivStatusDesc" style="width:200px; margin: 5px;" >HIV Status<p-sortIcon field="clientStatusDesc"></p-sortIcon></th>
                
                <ng-container *ngIf="_showDetailedView">
                    <th pSortableColumn="certificationMsgs" style="width:300px; margin: 5px;    text-overflow: ellipsis;" >Certification Status<p-sortIcon field="certificationMsgs"></p-sortIcon></th>
                </ng-container>
            </tr>
        </ng-template>
        <ng-template  pTemplate="body" let-ClientListResponse>
            <tr class="cl-row" [ngStyle]="{'color': (ClientListResponse.requiredFieldsErrorCount > 0) ? 'red' : 'black'}">  
                
                <!-- "{'background-color':'red'}"    [ngClass] ="'cl-row'" -->  
                <!-- <td class="p-text-uppercase" >{{ClientListResponse.clientGuid}}</td> target="_blank" -->  
                <!-- <td><a (click)="openDirectServicesInNewWindow(ClientListResponse.clientGuid)"> {{ClientListResponse.clientAltID}}</a></td> --> 

                <!-- <td><a routerLink="../client-direct-services/{{ClientListResponse.clientGuid}}"    routerLinkActive="active" > {{ClientListResponse.clientAltID}}</a></td>   -->

                
                <!-- <td><a routerLink="../client-form/{{ClientListResponse.clientGuid}}"    routerLinkActive="active" > {{ClientListResponse.clientAltID}}</a></td>   -->
                <!-- <td><a routerLink="openClient(ClientListResponse.clientGuid)"    routerLinkActive="active" > {{ClientListResponse.clientAltID}}</a></td>   -->
<!-- style="min-width:2rem;max-width:2rem;text-align:left"  -->

                @if( this.app.userServ?.hasAdminPermission && this.adminFeatures){
                    <td class="m-0 p-0" style="width: 30px;max-width:30px;">   
                            @if( this.app.userServ?.hasAdminPermission && !ClientListResponse.deleted)
                            {  
                                <button title="Delete"  pButton pRipple  class="p-button p-button-text m-0 mr-2 p-0"   (click)="onDeleteClient($event,ClientListResponse)"   >
                                    <i class="fa-sharp fa-regular fa-trash " style="color: red;" ></i>
                                </button>  
                            }
                            @if( this.app.userServ?.hasAdminPermission && ClientListResponse.deleted)
                            {
                                <button title="Undelete"  pButton pRipple  class="p-button p-button-text m-0 mr-2 p-0"  (click)="onUndeleteClient(ClientListResponse)"   >
                                    <i class="fa-sharp fa-regular fa-trash-undo" style="color: darkgoldenrod;" ></i>
                                </button>  
                            }
                    </td>
                }

                <td style="width:100px; margin: 5px;" ><a [ngClass]="'clientID'" (click)="openClient(ClientListResponse.clientGuid)" [ngStyle]="{'color': (ClientListResponse.requiredFieldsErrorCount > 0) ? 'red' : 'blue'}"> {{ClientListResponse.clientAltID}}</a></td> 
                <td style="width:80px; margin: 5px; ">{{ClientListResponse.name}}</td>
                <td style="width:80px; margin: 5px; ">{{ClientListResponse.ssn}}</td>
                <td style="width:75px; margin: 5px; " [ngClass] ="'cl-client-date text-right'" >{{ClientListResponse.birthDate| date:'shortDate'}}</td> <!--| date:'short'-->
                <td [ngClass]="'text-center'"  style="width:50px; margin: 5px; ">  
                        <div *ngIf="ClientListResponse.eis" >
                        <fa-icon [icon]="facheckIcon" [styles]="{'stroke': 'green', 'color': 'green'}"></fa-icon> 
                    </div>
                </td>
                <td [ngClass]="'text-center'" style="width:50px; margin: 5px; ">    
                    <div *ngIf="ClientListResponse.linkage" >
                        <fa-icon [icon]="facheckIcon" [styles]="{'stroke': 'green', 'color': 'green'}"></fa-icon> 
                    </div>
                </td>
                <td [ngClass] ="'cl-client-date text-right'" style="width:100px; margin: 5px; ">{{ClientListResponse.linkageDate | date:'shortDate'}}</td>
                <td [ngClass]="'text-center'" style="width:50px; margin: 5px; ">    
                    <div *ngIf="ClientListResponse.isPrimaryMedical" >  
                        <fa-icon [icon]="['fas', 'notes-medical']"  [styles]="{'stroke': 'red', 'color': 'red'}" size="lg"  style="align-content:center;" (click)="onClickPrimaryMedial ( ClientListResponse.clientGuid, ClientListResponse.clientAltID)"></fa-icon>
                    </div>
                </td>

                <td style="width:50px; margin: 5px; ">{{ClientListResponse.qmCode}}</td>
                <td style="width:50px; margin: 5px; "> 
                    <div *ngIf="ClientListResponse.cohortStudyLatest" >
                        <fa-icon [icon]="facheckIcon" [styles]="{'stroke': 'green', 'color': 'green'}"></fa-icon> 
                    </div>
                </td>
                <td style="width:200px; margin: 5px;" >{{ClientListResponse.clientStatusDesc}}</td>
                <td style="width:100px; margin: 5px; ">{{ClientListResponse.employee_name}}</td>
                <td style="width:200px; margin: 5px;">{{ClientListResponse.hivStatusDesc}}</td>

                <ng-container *ngIf="_showDetailedView" style="width:300px; margin: 5px;    text-overflow: ellipsis;">
                    <td>{{ClientListResponse.certificationMsgs}}</td>                    
                </ng-container>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td colspan="14">Count: {{_clientList?.length}}</td>
            </tr>
        </ng-template>    
    </p-table>

</div>

<p-dialog [(visible)]="_medicalVisitsDialog"
          header="Primary Medical History for {{_currentClientAltID}}" [modal]="true" 
          [draggable]="false" [resizable]="false"
          [style]="{width: '50vw', minWidth: '60vw'}" [baseZIndex]="10000"
          >

    <div  style="height: 50%"> 
        <p-table  [value]="_primaryMedicalVisits"  styleClass="p-datatable-sm p-datatable-striped " name="PrimaryMedicalVisits"  [scrollable]="true"   scrollHeight="200px" >
            <ng-template pTemplate="header">
                <tr class="cl-row-header">
                    <th  pSortableColumn="providerName"  >Provider<p-sortIcon field="providerName"></p-sortIcon></th>
                    <th  pSortableColumn="subType" >Sub Type<p-sortIcon field="subType"></p-sortIcon></th>
                    <th  pSortableColumn="visitDate" style="width:75px" >Date<p-sortIcon field="visitDate"></p-sortIcon></th>
                    <th  pSortableColumn="units" style="width:75px"> Units<p-sortIcon field="units"></p-sortIcon></th>
                </tr>
            </ng-template>

            <ng-template  pTemplate="body" let-PrimaryMedicalVists>
                <tr class="cl-row">   
                    <td>{{PrimaryMedicalVists.providerName}}</td>
                    <td>{{PrimaryMedicalVists.subType}}</td>
                    <td [ngClass] ="'cl-client-date text-right'" style="width:75px" >{{PrimaryMedicalVists.visitDate | date:'shortDate' }}</td>
                    <td [ngClass] ="'text-right'" style="width:75px" >{{PrimaryMedicalVists.units}}</td>

                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="4">Count: {{_primaryMedicalVisits?.length}}</td>
                </tr>
            </ng-template>  
        </p-table>
    </div>


    <div class="mt-3 mb-3" style="height: 50%">  <!--     -->
        <p-table  [value]="_primaryMedicalTests"  styleClass="p-datatable-sm p-datatable-striped " name="PrimaryMedicalTests"  [scrollable]="true" scrollHeight="200px"  >
            <ng-template pTemplate="header">
                <tr class="cl-row-header">
                    <th  pSortableColumn="providerName" >Provider<p-sortIcon field="providerName"></p-sortIcon></th>
                    <th  pSortableColumn="testType" >Test Type<p-sortIcon field="testType"></p-sortIcon></th>
                    <th  pSortableColumn="testDate" style="width:75px" >Date<p-sortIcon field="testDate"></p-sortIcon></th> 
                    <th  pSortableColumn="count" style="width:75px" >Result<p-sortIcon field="count"></p-sortIcon></th>
                </tr>
            </ng-template>

            <ng-template  pTemplate="body" let-PrimaryMedicalTests>
                <tr class="cl-row">   
                    <td>{{PrimaryMedicalTests.providerName}}</td>
                    <td>{{PrimaryMedicalTests.testType}}</td>
                    <td [ngClass] ="'cl-client-date text-right'" style="width:75px">{{PrimaryMedicalTests.testDate | date:'shortDate'}}</td>
                    <td [ngClass] ="'text-right'" style="width:75px">{{PrimaryMedicalTests.count}}</td>

                </tr>
            </ng-template>

            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="4">Count: {{_primaryMedicalTests?.length}}</td>
                </tr>
            </ng-template>   
        </p-table>


        <!-- <p-paginator
        [rows]="10"
        [totalRecords]="120"
        [rowsPerPageOptions]="[10, 20, 30]"

      ></p-paginator> -->
    </div>

</p-dialog>

