<div [formGroup]="fg"  > 
    <div *ngIf="this.mode == 'user'  ||  this.mode == 'client'  ||  this.mode == 'provider' "    [ngStyle]="{'width': '32rem'}"  >
            <div class="flex align-content-start" >
                <span *ngIf="this.mode != 'provider'  ">
                    <fa-icon class="flex align-self-end champ-field-required mb-1" [icon]="['fas', 'asterisk']"></fa-icon>
                </span>
                <label class="flex align-self-end justify-content-start control-label mr-2 mb-0"  style="width:50%" for="modifiedBy">{{this.label}}</label>

                <span class="flex  justify-content-end" style="width: 50%;"> 
                    <span style="align-content:end; display: grid;">
                        <button pButton pRipple type="button" label="Add New" class="p-button-text p-button-sm " style="width: 100px;" (click)="onAddNewEmployee($event)" ></button>  
                    </span>     
                    <span style="align-content:end; display: grid;">
                        <button pButton pRipple type="button" label="Edit" class="p-button-text p-button-sm " style="width: 100px;"  (click)="onEditEmployee($event, this.fg.value?.employeeId)" [disabled]="!(this.fg.value?.employeeId > 0)" ></button>  
                    </span>      
                </span>
            </div>
                <!--  overlayOptions="{'baseZIndex','20000'}"    -->
            <p-dropdown formControlName="employeeId"  [options]="employees" optionLabel="name" optionValue="id"    
                        [filter]="true" filterBy="name"  
                        (onChange)="onEmployeeChanged($event)"  [showClear]="true" autoDisplayFirst="false" >

                <ng-template let-emp pTemplate="selectedItem">
                    <!-- <div *ngIf="!emp || emp.length === 0"> -->
                    <div *ngIf="emp ">  <!-- && (this.mode == 'user'  ||  this.mode == 'client' ) -->
                        <div class="grid"> 
                                <div class="col-7 overflow-hidden text-overflow-ellipsis" > {{ emp.name }} </div> 
                                <div class="col-5 overflow-hidden text-overflow-ellipsis"> {{ this.getEmployeePosition(emp.position) }} </div> 
                        </div>  
                    </div>
                    <!-- <div *ngIf="this.mode == 'provider' ">
                        Add/Edit Providers Employees
                    </div> -->
                </ng-template>

                <ng-template let-emp pTemplate="item">
                    <div class="grid"> 
                        <div class="col-7 overflow-hidden text-overflow-ellipsis"> {{ emp.name }} </div>
                        <div class="col-5 overflow-hidden text-overflow-ellipsis"> {{ this.getEmployeePosition(emp.position) }} </div> 
                    </div>
                </ng-template>
            </p-dropdown> 
    </div>

    <div *ngIf="this.mode == 'provider2'"  >  <!--   Need a multi-select here  -->
       
        <div class="field col-12" *ngIf="this.employees?.length">
            <label for="dsSubTypeInclusion" style="margin-top: 0.125rem;">Sub Type Inclusions</label>
            <ng-multiselect-dropdown name="multiSelectEmployees" formControlName="dsSubTypeInclusion" 
                [settings]="multiSelectEmployeeDropDownSettings" 
                [data]="employees"
                [(ngModel)]="selectedEmployees"

            >
            </ng-multiselect-dropdown>
        </div>    
    
        <!-- (onSelect)="onSubTypeInclusionItemSelect($event)"
        (onSelectAll)="onSubTypeInclusionSelectAll($event)"
        (onDeSelect)="onSubTypeInclusionItemDeSelect($event)"
        (onDeSelectAll)="onSubTypeInclusionDeSelectAll($event)" -->
    </div> 


</div>

<p-overlayPanel #employeeOverlay [dismissable]="false" [showCloseIcon]="true" [ngStyle]="{'padding': '.5rem'}"  (onHide)="onClose($event)" > 

<!-- <p-dialog #employeeOverlay [ngStyle]="{'padding': '.5rem'}"  (onHide)="onClose($event)" >      -->
    <!-- <ng-template pTemplate="closeicon">
        <p-button  pButton pRipple type="button"    styleClass="p-button-text" class="p-button-help p-button-text p-button m-0 p-0 mr-2 " (click)="onClose($event)" >
            <i class=" fa-sharp fa-light fa-xmark" style="color:gray" ></i>
        </p-button>
    </ng-template>     -->

    <ng-template pTemplate="content">
        <div class="p-fluid p-formgrid grid p-1" style="max-width: 70rem;" [formGroup]="fgEmployee">
            <!-- row #1 -->
            <div class="field col-12"  *ngIf="this.mode == 'user'">
                <span style="align-content:end; display: grid;">
                    <button pButton pRipple type="button" label="Copy fields from User" class="p-button-text p-button-sm " style="width: 20rem;" 
                        (click)="onCopyFieldsFromUser($event)"  ></button>    <!-- [disabled]="!(this.fg.value?.employeeId > 0)" -->
                </span>    
            </div>     


            <!-- <div class="field col-6">
                <label for="linkToUser" class="mr-2" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Link to user </label>
                <span class="mr-2"  style="font-size: small;"> {{ this.user.emailAddress}} </span>
                 <p-checkbox formControlName="linkToUser" binary="true" inputId="linkToUser"></p-checkbox>
            </div> -->

            <!-- row #2 -->
            <div class="field col-4">
                <label for="firstname" class="m-1">   
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> First Name
                </label>
                <input id="firstname" type="text" formControlName="firstName"   pInputText />
            </div>           

            <div class="field col-4">
                <label for="middleName" >
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Middle Name
                </label>
                <input id="middleName" type="text" formControlName="middleName"   pInputText />  
            </div>           

            <div class="field col-4"  >
                <label for="lastName">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Last Name
                </label>
                <input id="lastName" type="text" formControlName="lastName" pInputText />
            </div>         

            <!-- row #3 -->
            <div class="field col-5">
                <label for="emailAddress" >
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Email Address
                </label>
                <input id="emailAddress" type="email" formControlName="emailAddress"  pInputText aria-describedby="email-help"/>
                
            </div>   

            <div class="field col-2">
                <label for="mainPhone"><fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> Phone</label>
                <div style="width: 8rem;">
                    <p-inputMask  id="mainPhone" mask="(999) 999-9999" formControlName="phone" unmask="true" ></p-inputMask>
                </div>
            </div>   

            <div class="field col-5">
                <label for="position" > <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>  Position</label>
                <p-dropdown  inputId="position" formControlName="position"  [options]="this.positions" optionLabel="description" optionValue="id" 
                             overlayOptions="{'baseZIndex','20000'}" [filter]="true" filterBy="name" [showClear]="true" autoDisplayFirst="false"> 
                </p-dropdown>
            </div>   

            <!-- row #4 -->
            <div class="field col-6 justify-content-start mt-2">
                <label class="control-label m-0  mr-2" for="empProviderId" ><fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>  Provider </label>
                <p-dropdown  inputId="empProviderId" formControlName="providerId" [options]="this.providers" optionLabel="providerName" optionValue="id" 
                        [showClear]="true" autoDisplayFirst="false" ></p-dropdown> 
            </div>

            <div class="field col-2">
                <label for="deleted" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Deleted</label>
                <div class="field-checkbox">
                    <p-checkbox formControlName="deleted" binary="true" inputId="deleted"></p-checkbox>
                </div>
            </div>

             <div class="field col-4 flex justify-content-end mt-4">
                <button pButton pRipple [ngClass]="'champ-button'" icon="pi pi-check" style="width: 10rem; max-height: 3rem;" 
                        (click)="onSaveEmployee($event)" label="Save"    >   <!--  [disabled]="!(fg.valid && this.isProviderUserValid()  )"   -->          <!--         && this.isProviderUserValid()      !(fg.valid ||    (!fg.valid && fg.controls['password'].dirty))  -->
                </button>
            </div>

        </div>
    </ng-template>
</p-overlayPanel>
<!-- </p-dialog> -->

