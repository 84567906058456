import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { ProviderMaintenanceModel, ProviderMaintenancePostResponse } from 'src/app/models/admin.models';
import { ZipCodeResult } from 'src/app/models/common-data.model';
import { CommonDataService } from 'src/app/services/common-data.service';
import { UserService } from 'src/app/services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { ProviderMaintenanceService } from '../provider-maintenance/provider-maintenance.service';
import { EmployeeComponent } from '../employee/employee.component';

@Component({
  selector: 'app-provider-maintenance-detail',
  templateUrl: './provider-maintenance-detail.component.html',
  styleUrls: ['./provider-maintenance-detail.component.scss','../admin-styles.scss'],
  providers: [MessageService]
})
export class ProviderMaintenanceDetailComponent implements OnInit {
  @ViewChild(EmployeeComponent) employeeComponent:  EmployeeComponent;
  
  fg: UntypedFormGroup;
  params: Partial<ProviderMaintenanceModel> = {};
  providerId: number = 0;
  providerIdToClone: number = 0;
  providerGuid: string;
  provider: Partial<ProviderMaintenanceModel> = {};
  clientStatusResetValues: any[];

  getProviderGuidControl = () => this.fg?.get('guid');
  getProviderIdControl = () => this.fg?.get('id');
  getProviderNameControl = () => this.fg?.get('providerName');
  getProviderAddressLine1Control = () => this.fg?.get('addressLine1');
  getProviderAddressLine2Control = () => this.fg?.get('addressLine2');
  getProviderCityControl = () => this.fg?.get('city');
  getProviderStateControl = () => this.fg?.get('state');
  getProviderZipControl = () => this.fg?.get('zip');
  getProviderMainPhoneControl = () => this.fg?.get('mainPhone');
  getProviderMainFaxControl = () => this.fg?.get('mainFax');
  getProviderSiteControl = () => this.fg?.get('site');
  getProviderUrlControl = () => this.fg?.get('url');
  getProviderEmailControl = () => this.fg?.get('email');
  getProviderActiveControl = () => this.fg?.get('active');
  getProviderFundedControl = () => this.fg?.get('funded');
  getProviderUserIdControl = () => this.fg?.get('userId');
  getProviderDeletedControl = () => this.fg?.get('deleted');
  getProviderLastUpdateControl = () => this.fg?.get('lastUpdate');
  getProviderMemoControl = () => this.fg?.get('memo');
  getProviderDatasetTimestampControl = () => this.fg?.get('datasetTimestamp');
  getProviderTestControl = () => this.fg?.get('test');
  getProviderRsrProviderIdControl = () => this.fg?.get('rsrProviderId');
  getProviderRecSourceControl = () => this.fg?.get('recSource');
  getProviderIdToCloneControl = () => this.fg?.get('providerIdToClone');

  isZipCodeMissing = () => (this.getProviderZipControl()?.value?.length ?? 0) == 0;
  isZipCodeInvalid = () => (this.getProviderZipControl()?.value?.length ?? 0) > 0 && (this.getProviderZipControl()?.value?.length < 5);
  public isRequiredInformationSupplied = () =>  this.getProviderNameControl()?.valid &&
                                                this.getProviderAddressLine1Control()?.valid &&
                                                this.getProviderCityControl()?.valid &&
                                                this.getProviderStateControl()?.valid &&
                                                this.getProviderZipControl()?.valid &&
                                                this.getProviderMainPhoneControl()?.valid;

  getProviderOptionsGuidControl = () => this.fg?.controls.providerOptions.get('guid');
  getProviderOptionsProviderIdControl = () => this.fg?.controls.providerOptions.get('providerId');
  getProviderOptionsClientStatusExpirationDaysControl = () => this.fg?.controls.providerOptions.get('clientStatusExpirationDays');
  getProviderOptionsClientStatusResetValueControl = () => this.fg?.controls.providerOptions.get('clientStatusResetValue');
  getProviderOptionsMedicalChartReevaluationDaysControl = () => this.fg?.controls.providerOptions.get('medicalChartReevaluationDays');
  getProviderOptionsBillableCutOffControl = () => this.fg?.controls.providerOptions.get('adminBillableDays');
  getProviderOptionsMessagingEnabledControl = () => this.fg?.controls.providerOptions.get('messagingEnabled');
  getProviderOptionsEmailEnabledControl = () => this.fg?.controls.providerOptions.get('emailEnabled');
  getProviderOptionsAdminControl = () => this.fg?.controls.providerOptions.get('admin');
  getProviderOptionsLastUpdateControl = () => this.fg?.controls.providerOptions.get('lastUpdate');
  getProviderOptionsDeletedControl = () => this.fg?.controls.providerOptions.get('deleted');
  getProviderOptionsUserIdControl = () => this.fg?.controls.providerOptions.get('userId');
  getProviderOptionsUseFullNamesControl = () => this.fg?.controls.providerOptions.get('useFullNames');
  getProviderOptionsUseAddressInformationControl = () => this.fg?.controls.providerOptions.get('useAddressInformation');
  getProviderOptionsUseFullSsnControl = () => this.fg?.controls.providerOptions.get('useFullSsn');
  getProviderOptionsUseEmailAddressControl = () => this.fg?.controls.providerOptions.get('useEmailAddress');
  getProviderOptionsReferralExpirationDaysControl = () => this.fg?.controls.providerOptions.get('referralExpirationDays');
  getProviderOptionsShowContractedServicesOnlyControl = () => this.fg?.controls.providerOptions.get('showContractedServicesOnly');
  getProviderOptionsCd4AidsLevelControl = () => this.fg?.controls.providerOptions.get('cd4AidsLevel');
  getProviderOptionsViralLoadAidsLevelControl = () => this.fg?.controls.providerOptions.get('viralLoadAidsLevel');
  getProviderOptionsUseAssessmentControl = () => this.fg?.controls.providerOptions.get('useAssessment');
  getProviderOptionsRecSourceControl = () => this.fg?.controls.providerOptions.get('recSource');
  getProviderOptionsFunderBillingRestrictionEncounterControl = () => this.fg?.controls.providerOptions.get('funderBillingRestrictionEncounter');
  getProviderOptionsFunderBillingRestrictionMonthlyControl = () => this.fg?.controls.providerOptions.get('funderBillingRestrictionMonthly');

  constructor(private fb: UntypedFormBuilder,
              private app: AppContextService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public diagRef: DynamicDialogRef, 
              public diagConfig: DynamicDialogConfig,
              private commonDataService: CommonDataService,
              private userService: UserService,
              public providerMaintenanceService: ProviderMaintenanceService
             ) { 

              this.params = this.diagConfig.data;

              this.fg = fb.group({
                guid:[],
                id: [0],
                providerName: [, [Validators.required]],
                addressLine1: [, [Validators.required]],
                addressLine2: [],
                city: [, [Validators.required]],
                state: [, [Validators.required]],
                zip: [, [Validators.required]],
                mainPhone: [, [Validators.required]],
                mainFax: [],
                site: [],
                url: [],
                email: [],
                active: [],
                funded: [],
                userId: [],                
                deleted: [false],
                lastUpdate: [],
                memo: [],
                datasetTimestamp: [],
                test: [],
                rsrProviderId: [],                
                recSource: [],
                providerIdToClone: [],
                providerOptions: this.fb.group({
                  guid:[],
                  providerId: [0],
                  clientStatusExpirationDays: [365],
                  clientStatusResetValue: [6],
                  medicalChartReevaluationDays: [90],
                  adminBillableDays: [15],
                  messagingEnabled: [0],
                  emailEnabled: [0],
                  admin: [0],
                  lastUpdate: [],
                  deleted: [false],
                  userId: [],
                  useFullNames: [0],
                  useAddressInformation: [0],
                  useFullSsn: [0],
                  useEmailAddress: [0],
                  referralExpirationDays: [45],
                  showContractedServicesOnly: [false],
                  cd4AidsLevel: [-1],
                  viralLoadAidsLevel: [-1],
                  useAssessment: [false],
                  recSource: [],
                  funderBillingRestrictionEncounter: [true],
                  funderBillingRestrictionMonthly: [false]                  
                 })                
              },
              {
                //validators: [this.customValidator()],
                //asyncValidators: [this.timePeriodValidator()]
                //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
              });              
  }

  ngOnInit(): void {
    this.clientStatusResetValues = this.commonDataService.getCommonSetByName("ClientStatus", true);
    this.providerId = this.params?.id ?? 0;
    this.providerGuid = this.params?.guid;
    this.providerIdToClone = this.params?.providerIdToClone ?? 0;
    if (this.providerId > 0) {
          this.getProvider(this.providerId);
    }
  }

  getProvider(providerId: number) {
    this.providerMaintenanceService.getProvider(providerId).subscribe({
      next: (data: ProviderMaintenanceModel) => {    
            //console.log(data);
            this.provider = data;
            //var data: any = { }; // Partial<Clients>
            //Object.assign(this.fg.value, data)
            this.fg.patchValue(data);
            //this.getProviderGuidControl().setValue(data.guid);
            //this.getProviderOptionsGuidControl().setValue(data.providerOptions?.guid);
            //this.onChangeZipCode(data.zip);
            // if no guid was passed to the component that means we need to clone to a new Provider set a default bogus guid so the HTTP call does not fail, the backend will assign the real guids
            if (!this.providerGuid) {
              this.getProviderNameControl().setValue(this.provider.providerName + ' CLONE');
              this.providerGuid = uuidv4();
              this.getProviderGuidControl().setValue(this.providerGuid);
              this.getProviderOptionsGuidControl().setValue(uuidv4());
              this.getProviderIdToCloneControl().setValue(this.providerIdToClone);
            }

            this.employeeComponent.getEmployees(this.provider.id);
            //this.getProviderZipControl().updateValueAndValidity();
            //this.fg.markAsPristine();
            //this.fg.updateValueAndValidity({ onlySelf: false, emitEvent: true });
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
          this.app.userServ.LogError(error);
         },
      complete: () => { }
    });
  }

  onChangeZipCode(event: any) {
    var zip = event.target.value?.replaceAll('_', '');
    if (zip.length != 5) {
      this.getProviderCityControl().setValue("");
      this.getProviderStateControl().setValue("");      
      //this.setZipCodeInvalidClass();
      return;
    }
    this.commonDataService.getZipCodeData(zip).subscribe(    
      {
        next: (result: ZipCodeResult) => {
          if (result) {
            this.getProviderCityControl().setValue(result?.city);
            this.getProviderStateControl().setValue(result?.state);
          }
          else {
            this.getProviderCityControl().setValue("");
            this.getProviderStateControl().setValue("");
          }
        },
        error: (e) => console.error(e),
        complete:() => { } //this.setZipCodeInvalidClass();
      } );
  }

  onSaveProvider(event: Event) {
    //this.findInvalidControls();
    this.confirmationService.confirm({
        target: event.target,
        message: "Are you sure that you want save?",
        icon: "pi pi-info-circle",
        accept: () => {
          try {
            // set the latest model into the clientProductsDetailRow
           // this.params.clientProductsDetailRow = this.getClientProductDetailModel();
           this.saveProvider();
          }
          catch (error) {
            console.log(error.error.message); console.log(error.error.data);
            this.app.userServ.LogError(error.error);
            //  this.helper.displayError(this.messageService, "Changes failed to save successfully.", error);
            this.messageService.add({severity: "error", summary: "Failure", detail: "Changes failed to save successfully.", life: this.app.messageDisplayTime});
          }	  
        },
        reject: () => {
          this.messageService.add({severity: "error", summary: "Aborted", detail: "Changes canceled", life: this.app.messageDisplayTime});
        }
      });
  }

  saveProvider(){
    //const data:Partial<ProviderMaintenanceModel> = {};
    var data: any = { }; // Partial<ProviderMaintenanceModel>
    this.getProviderUserIdControl().setValue(this.userService.userID);
    this.getProviderOptionsUserIdControl().setValue(this.userService.userID);
    Object.assign(data, this.fg.value)
    //this.getProviderMainPhoneControl().setValue(data. )
    this.providerMaintenanceService.saveProvider(data).subscribe({
      next: (data: ProviderMaintenancePostResponse) => {    
            console.log(data);
            // return entire provider object
            this.provider = data.data;
            this.fg.patchValue(data.data);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Provider Saved.', life: this.app.messageDisplayTime});
            // this.refreshPayer.emit();
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
          this.app.userServ.LogError(error);
         },
      complete: () => { }
      });
  }  

    onEmployeeChange(e){
      console.log(e);
    }




  // setZipCodeInvalidClass() { 
  //   if (this.isZipCodeMissing() || this.isZipCodeInvalid()) {
  //     this.renderer.addClass(this.zipCodeElement.inputViewChild.nativeElement, 'p-invalid'); 
  //   }
  //   else {
  //     this.renderer.removeClass(this.zipCodeElement.inputViewChild.nativeElement, 'p-invalid'); 
  //   }
  // }  
}
