<div class="footer clearfix">
    <div class="row">
        <div class="col-3">
            <!-- <img src="assets/layout/images/logo-dark.png" alt="logo" /> -->
            <span class="app-name">CHAMP</span>
        </div>
         
        <div class="col-3">
            <p-toast key="update_msg" position="bottom-center" [baseZIndex]="5000">
                <ng-template let-message pTemplate="message">
                    <div class="p-flex p-flex-column" style="flex: 1">
                        <div class="p-text-center">
                            <i class="pi pi-exclamation-triangle" style="font-size: 2rem"></i>
                            <h4>{{message.summary}}</h4>
                            <p>{{message.detail}}</p>
                            <br>
                            <div >
                                <button type="button"  pButton (click)="onReload()" label="Reload" class="p-button-success"></button>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </p-toast>            

            <!-- <p-messages key="update_msg"> -->
                <!-- <ng-template let-message pTemplate="message">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h4>{{message.summary}}</h4>
                    <p>{{message.detail}}</p>
                </ng-template> -->
            <!-- </p-messages> -->

            <!-- <p-button> </p-button> -->

            <!-- <a></a> -->

            <!-- <button pButton pRipple type="button" label="Show Login" class="p-button-help p-button-text  p-button champ_alpha"   (click)="showLoginPopup()"></button> -->
        </div>

        <div class="col-2">
            <div style="font-size: smaller;" *ngIf="showServerDetails">
                <div class="col" style="text-overflow: ellipsis;overflow: hidden; ">{{env?.server}} </div>
                <div class="col">{{env?.webServer}} </div>
            </div>
        </div> 

        <div class="col-2">
            <div style="font-size: smaller;" *ngIf="showServerDetails">
                <div class="col">{{env?.serverEnvironment}} </div>
            </div>

        </div> 

        <div class="col-2">
            <div class="footer-right">
                <div class="col">Version {{currentApplicationVersion}} </div>
                <div class="col">All Rights Reserved - 1996 </div>
            </div>
        </div>
    </div>

</div>
