import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminEmployeesModel, AdminProviderEmployeeModel, AdminUserModel, AdminUserResponseModel, AuditUserEventsResponseModel, ProviderClinicsPermissionModel, ProvidersResponseModel, UserActivityRequestModel, UserRequestModel } from 'src/app/models/admin.models';
import { environment } from 'src/environments/environment';
//import { stringify } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class UserMaintenanceService {

  // URL which returns list of JSON items (API end-point URL)
  private readonly URL = environment.apiURL + '/admin';


  constructor(private http: HttpClient) { }

//   public getDefaultData () 
//   {
//     let url  = this.URL + '/getDefaults';
//     return this.http.get(url);
//   }

  public getUsers (request: UserRequestModel) 
  {
    let url  = this.URL + '/getUserList';
    return this.http.post<AdminUserModel[]>(url, request);
  }

  public getProviders () 
  {
    let url  = this.URL + '/getProviders';
    return this.http.get<ProvidersResponseModel[]>(url);
  }

  public getEmployees (providerId:number ) 
  {
    let url  = this.URL + '/getEmployees';
    return this.http.get<AdminEmployeesModel[]>(url);
  }


  public getEmployeesFromProvider (providerId) {
    let params = new HttpParams();
    params = params.append('providerId', providerId);
    return this.http.get<AdminEmployeesModel[]>(this.URL + '/getEmployeesFromProvider/', { params:params });

  }

  public getEmployeesFromMultipleProviders (providers) {
    let params = new HttpParams();
    params = params.append('providers', providers);
    return this.http.get<AdminEmployeesModel[]>(this.URL + '/getEmployeesFromMultipleProviders/', { params:params });

  }

  //



  public getEmployee (employeeId:number) 
  {
    let url  = this.URL + '/getEmployee';
    let params = new HttpParams();
    params = params.append('employeeId', employeeId);
    return this.http.get<AdminProviderEmployeeModel>(url, { params:params });
  }

  public saveEmployee (model: Partial<AdminProviderEmployeeModel>) 
  {
      let url  = this.URL + '/saveEmployee';
      return this.http.post<AdminProviderEmployeeModel>(url, model);
  }


  public getUser (guid: string)
  {
    let url  = this.URL + '/getUser';
    let params = new HttpParams();
    params = params.append('guid', guid);

    return this.http.get<AdminUserModel>(url, { params:params });
  }

  public saveUser (model: AdminUserModel) 
  {
    let url  = this.URL + '/SaveUser';
    return this.http.post<AdminUserResponseModel>(url, model);
  }

  public deleteUser (model: AdminUserModel ) 
  {
    let url  = this.URL + '/DeleteUser';
    return this.http.post<boolean>(url, model);
  }


  public getProviderClinicsPermissionList (userId:number) 
  {
    let url  = this.URL + '/getProviderClinicsPermissionList';
    let params = new HttpParams();
    params = params.append('userId', userId);
    return this.http.get<ProviderClinicsPermissionModel[]>(url, { params:params });
  }
 
}
