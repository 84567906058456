import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AppMainComponent } from './app.main.component';
import { LoginService } from './login/services/login.service';
import { UserModel} from './login/services/login-models' ;
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ReportsComponent } from './reports/reports.component';
import { environment } from 'src/environments/environment';
import { IdleService } from './services/idle-service.service';
import { UserService } from './services/user.service';
import { AppContextService } from './app-context/app-context.service';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    providers: [DialogService]
})
export class AppMenuComponent implements OnInit {
    model: any[];
    
    currentUser$:Observable<UserModel>;
    loggedin:boolean = false;
    diagRef: DynamicDialogRef;

    constructor(
                public app: AppContextService, 
                public appMain: AppMainComponent, 
                private loginService: LoginService,  
                private route: ActivatedRoute ,
                private router: Router,
                public dialogService: DialogService,
                private idleService: IdleService, 
                private userService:UserService) {}

    ngOnInit() {

        // START UP
       this.getCurrentLogin();
       //this.showReportDialog(null,18) // REPORT DEBUG
       //this.router.navigate(['/user-activity']);

    //    this.route.parent.url.subscribe(url => {
    //         console.log(url, url[0].path )
    //         if (url[0].path == 'client-direct_services' )
    //         {
                
    //         }
    //    });


       // If app times out, close report dialog 
        this.idleService.idle.onTimeout.subscribe(() => {
            this.loginService.logout();
            this.setMenu();
        });

     if (!(this.loggedin ?? false)) {
        this.model = [
            {label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['']},   
            {label: 'About', icon: 'pi pi-fw pi-info-circle', routerLink: ['/about']}
        ];

        this.addTestMenu();
    }


        // this.versionCheckService.updateReady$.subscribe({
        //     next: (updateReady) => { 
        //         if ( updateReady) 
        //         {
        //             console.log('next');
        //             // this.messageService.add({
        //             //     severity:'warn',
        //             //     summary: 'Search Warning', 
        //             //     detail: 'At least two search paramaters must be specified. Please select at least 2 of the 3 search criteria. ( Client Status, Contract and/or alpha) or at least 3 letters in the client ID.  ',  life: 5000});
      

        //             this.messageService.clear();
        //             this.messageService.add({key: 'c', sticky: true, severity:'warn', summary:'Are you sure?', detail:'Confirm to proceed'});

        //                 // this.confirmationService.confirm({
        //                 //     //target: ,
        //                 //     message: 'An updated version of CHAMP is avaiable. If your work is saved you con proceed with the update.  Are you ready to update ?',
        //                 //     header: 'Confirm',
        //                 //     icon: 'pi pi-cloud-download',
        //                 //     accept: () => {
                                
        //                 //         // ****** make the call to delete the item
        //                 //         //this.directService = null;
        //                 //         //results.header.note1;
                      
        //                 //         this.messageService.add({severity:'success', summary: 'Successful', detail: 'Service Deleted', life: 1000});
        //                 //     },
        //                 //     reject: () => {
        //                 //         this.messageService.add({severity: "error", summary: "Aborted", detail: "Delete canceled", life: 1000});
        //                 //         }
        //                 //     });



        //         }
                  
        //     },
        //     error: (error) => { console.log(error) },

        // });
    }

    addTestMenu(){

        if (environment.environmentName == "LOCAL" || environment.environmentName == "DEV" ){
            this.model.push( {label: 'Test Menu', icon: 'pi pi-fw pi-id-card', 
            items: [
                {label: 'Reports', icon: 'pi pi-fw pi-align-left', command: (event) =>{ this.showReportDialog(event,0)}},
                {label: 'User Activity', icon: 'pi pi-fw pi-id-card', routerLink: ['/test-ua']},
                {label: 'User Maintenance', icon: 'pi pi-fw pi-check-square', routerLink: ['/test-um']},
                {label: 'Error Logs', icon: 'pi pi-fw pi-bookmark', routerLink: ['/test-elogs']},
                {label: 'Provider Maintenance', icon: 'pi pi-fw pi-check-square', routerLink: ['/test-prov-main-list']},
                {label: 'Contract Maintenance', icon: 'pi pi-fw pi-check-square', routerLink: ['/test-prov-contract-list']},
                {label: 'Unit Maintenance', icon: 'pi pi-fw pi-check-square', routerLink: ['/test-unit']},
                {label: 'Client Merge', icon: 'pi pi-fw pi-id-card', routerLink: ['/test-client-merge']},
                //{label: 'Users', icon: 'pi pi-fw pi-bookmark', routerLink: ['/uikit/floatlabel']},
                ]
             })
         }
    }
    getCurrentLogin(){
        this.loginService.currentUser$.subscribe (  {
            next: (user) => {
                this.loggedin = !!user;
                this.setMenu();
        },
        error: (error)=>{
            console.log(error);
        }});
    }

    setMenu() {
        if (this.loggedin)   
        {
            this.model = [];
            let items: any[];

           this.model.push({label: 'Client List', icon: 'pi pi-fw pi-list', routerLink: ['/client-list']});
           
           items = [];
            if (this.userService?.hasAdminPermission  || this.userService?.hasDHHSPermission 
                || this.userService?.data?.userProviders.length > 1 || this.userService?.data?.userClinics.length > 1) {

                //items.push({label: 'Switch Provider', icon: 'pi pi-fw pi-id-card', routerLink: ['/switch-provider']});
                //this.model.push({label: 'Provider', icon: 'pi pi-fw pi-star', routerLink: ['/uikit'], badgeStyleClass: 'amber-badge',items:items});

                this.model.push({label: 'Switch Provider-Clinc', icon: 'pi pi-fw pi-id-card', routerLink: ['/switch-provider']});
            }
            this.model.push({label: 'Reports', icon: 'pi pi-fw pi-briefcase', command: (event) =>{ this.showReportDialog(event,0)}});

            if (this.userService?.hasAdminPermission){
                this.model.push({label: 'RSR - Coming Soon', icon: 'pi pi-fw pi-sitemap',  routerLink: ['/rsr-list']});
                // this.model.push({label: 'Adhoc - Coming Soon', icon: 'pi pi-fw pi-sitemap', command: (event) =>{ }});
            }

            if (this.userService?.hasAdminPermission) {
                this.model.push( {
                    label: 'Admin', icon: 'pi pi-fw pi-star', // badge: 7, badgeStyleClass: 'amber-badge',
                        items: [
                            {label: 'User Activity', icon: 'pi pi-fw pi-id-card', routerLink: ['/user-activity'], visible : false  },
                            {label: 'Error Logs', icon: 'pi pi-fw pi-id-card', routerLink: ['/error-log']},
                            {label: 'User Maintenance ', icon: 'pi pi-fw pi-id-card', routerLink: ['user-maintenance']},
                            {label: 'Provider Maintenance', icon: 'pi pi-fw pi-check-square', routerLink: ['provider-maintenance']},
                            {label: 'Contract Maintenance', icon: 'pi pi-fw pi-check-square', routerLink: ['contract-maintenance']},
                            {label: 'Unit Maintenance', icon: 'pi pi-fw pi-check-square', routerLink: ['unit-maintenance']} ,
                            {label: 'Client Merge', icon: 'pi pi-fw pi-id-card', routerLink: ['/test-client-merge']}
                    ]
                });
            }

            this.model.push({label: 'About', icon: 'pi pi-fw pi-info-circle', routerLink: ['/about']});
            this.model.push({label: 'Logout', icon: 'pi pi-fw pi-sign-in',  routerLink: ['/logout']});
        }
        else{
            this.model = [
                {label: 'Login', icon: 'pi pi-fw pi-sign-in', routerLink: ['']},
                {label: 'About', icon: 'pi pi-fw pi-info-circle', routerLink: ['/about']},
                
            ];
        }
      //  this.addTestMenu();
    }

    showReportDialog(event: Event, reportId: Number){
  
        this.diagRef = this.dialogService.open(ReportsComponent, {
             header: 'Report',
             width: '60%',
             height:'50%',
             contentStyle: {"max-height": "600px", "overflow": "visible"},
             baseZIndex: 10,
             data: {
                 reportId:reportId
             }
         });

         this.app.overLayMangr.add(this.diagRef, 'dialogRef');

         this.diagRef.onClose.subscribe(async (data) => {
            this.app.overLayMangr.remove(this.diagRef);
        });
       
    }

}
