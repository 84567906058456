<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]='fg' autocomplete="off">
    <div class="p-container-fluid champ-card-body-sm">
        <div class="p-fluid p-formgrid">
            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-4">
                        <label for="providerName" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Provider Name</label>
                        <input type="text" formControlName="providerName" pInputText readonly="readonly"/>
                    </div>

                    <div class="field col-4">
                        <label for="programName" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Program Name</label>
                        <input type="text" formControlName="programName" pInputText/>
                        <div *ngIf="fg.controls['programName'].invalid">
                            <small id="programName-help" class="p-invalid">Program Name is required.</small>
                        </div>
                    </div>

                    <div class="field col-2">
                        <label for="fundYear" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Fund Year</label>
                        <div style="width: 5rem;">
                            <p-inputMask formControlName="fundYear" mask="9?999" slotChar= "" maxlength="4"></p-inputMask>
                        </div>
                        <div *ngIf="fg.controls['fundYear'].invalid">
                            <small id="fundYear-help" class="p-invalid">Fund Year is required.</small>
                        </div>                        
                    </div>                    

                    <div class="field col-2">
                        <label for="deleted" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Deleted</label>
                        <div class="field-checkbox">
                            <p-checkbox formControlName="deleted" binary="true"></p-checkbox>
                        </div>
                    </div>
                </div>
            </div>

            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-2">
                        <label for="contractAmt" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Contract Amount</label>
                        <div style="width: 7rem;">
                            <p-inputNumber formControlName="contractAmt"
                                        mode="currency" minFractionDigits="0" maxFractionDigits="0" max="999999" min="0" [maxlength]="9"
                                        currency="USD" locale="en-US"></p-inputNumber>
                        </div>
                        <div *ngIf="fg.controls['contractAmt'].invalid">
                            <small id="contractAmt-help" class="p-invalid">Contract Amount is required.</small>
                        </div>                        
                    </div>

                    <div class="field col-2">
                        <label for="includesEisUnits" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Includes EIS Units</label>
                        <div class="field-checkbox">
                            <p-checkbox formControlName="includesEisUnits" binary="true"></p-checkbox>
                        </div>
                    </div>

                    <div class="field col-2">
                        <label for="startDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Start Date</label>
                        <div class="formgroup-inline">
                            <input type="text" #startDate name="startDate" autocomplete="off" bsDatepicker formControlName="startDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"
                                    placeholder="MM-DD-YYYY" style="width:90px"
                                    class="champ-date-picker" pInputText/>
                        </div>
                        <div *ngIf="fg.controls['startDate'].errors?.required">
                            <small id="startDate-help" class="p-invalid">Start Date is required.</small>
                        </div>
                        <div *ngIf="!fg.controls['startDate'].errors?.required && fg.controls['startDate'].errors">
                            <small id="startDate2-help" class="p-invalid">{{ fg.controls['startDate'].errors['invalidDate'] }}</small>
                        </div>
                    </div>

                    <div class="field col-2">
                        <label for="endDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">End Date</label>
                        <div class="formgroup-inline">
                            <input type="text" #endDate name="endDate" autocomplete="off" bsDatepicker formControlName="endDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"
                                    placeholder="MM-DD-YYYY" style="width:90px"
                                    class="champ-date-picker" pInputText/>
                        </div>
                        <div *ngIf="fg.controls['endDate'].errors?.required">
                            <small id="endDate-help" class="p-invalid">End Date is required.</small>
                        </div>
                        <div *ngIf="!fg.controls['endDate'].errors?.required && fg.controls['endDate'].errors"> 
                            <small id="endDate2-help" class="p-invalid">{{ fg.controls['endDate'].errors['invalidDate'] }}</small>
                        </div>                        
                    </div>                    
                </div>
            </div>

            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-6">
                        <label for="addressLine1" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Address Line 1</label>
                        <input type="text" formControlName="addressLine1" pInputText />
                    </div>

                    <div class="field col-6">
                        <label for="addressLine2" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Address Line 2</label>
                        <input type="text" formControlName="addressLine2" pInputText />
                    </div>
                </div>
            </div>

            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-4">
                        <label for="city" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">City</label>
                        <input type="text" formControlName="city" pInputText readonly="readonly"/>
                    </div>

                    <div class="field col-1">
                        <label for="state" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">State</label>
                        <input type="text" formControlName="state" pInputText readonly="readonly"/>
                    </div>                    

                    <div class="field col-2">
                        <label for="zip" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Zip</label>
                        <div style="width: 5rem;">
                            <p-inputMask formControlName="zip" mask="9?9999" slotChar= "" maxlength="5" (onInput)="onChangeZipCode($event)"></p-inputMask>
                        </div>
                    </div>
                </div>                                
            </div>

            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-2">
                        <label for="phone" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Phone</label>
                        <div style="width: 8rem;">
                            <input type="text" formControlName="phone" pInputText />
                        </div>
                    </div>
                    
                    <div class="field col-2">
                        <label for="fax" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Fax</label>
                        <div style="width: 8rem;">
                            <input type="text" formControlName="fax" pInputText />
                        </div>                    
                    </div>
                 </div>
            </div>
            
            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-6">
                        <label for="email" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Email</label>
                        <input type="text" formControlName="email" pInputText />
                    </div>

                    <div class="field col-6">
                        <label for="url" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">URL</label>
                        <input type="text" formControlName="url" pInputText />
                    </div>
                </div>                                
            </div>

            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-4">
                        <label for="clinicId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Clinic</label>
                        <!-- <div style="width: 5rem;">
                            <p-inputMask formControlName="clinicId" mask="9?99" slotChar= "" maxlength="3"></p-inputMask>
                        </div> -->
                        <p-dropdown formControlName="clinicId" [options]="clinics" optionLabel="clinicName" optionValue="id" appendTo="body"> </p-dropdown>
                    </div>
                </div>
            </div>            

            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-12">
                        <label for="memo" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Memo</label>
                        <textarea formControlName="memo" rows="3" cols="1" pInputTextarea style="height:40px;"></textarea>
                    </div>
                </div>
            </div>

            <div class="p-col-12" style="padding: .5em;height: 4rem;">
                <div class="flex justify-content-end" >
                        <!-- <button pButton pRipple [ngClass]="'champ-button'" icon="pi pi-check" label="Save" (click)="onSaveContract($event)"
                                [disabled]="!fg.valid" style="width: 75px;float: right;margin-right: 0.5rem;margin-top: 0.5rem;"></button>                 -->


                        <p-button  pButton pRipple type="button" [ngStyle]="{'width':'12rem'}"   styleClass="p-button-text " class=" p-button-text mr-1 "
                                (click)="onSaveContract($event)" [disabled]="!fg.valid"  > 
                                    <i class="fa-sharp fa-regular fa-cloud-arrow-up fa-xl" ></i><span class="text-lg">Save</span>
                        </p-button>                         
                        <!-- <div class="p-fluid p-formgrid grid">
                            <div class="field col-11"></div>
                            <div class="field col-1">
                            
                            </div>
                        </div>             -->
                </div>
            </div>
        </div>
    </div>
</form>