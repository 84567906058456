import { Component } from '@angular/core';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { MenuService } from './app.menu.service';
import { SpinnerService } from './common/spinner/spinner.service';
import { UserService } from './services/user.service';
import { LoginService } from './login/services/login.service';
import { ErrorMessagesService } from './common/error-messages/error-messages.service';
import { VersionCheckService } from './services/version-check.service';
import { environment } from 'src/environments/environment';
import { NavigationEnd, Router } from '@angular/router';
import { IdleService } from './services/idle-service.service';


//import { ErrorMessagesService } from './common/error-messages/error-messages.service';

@Component({
    selector: 'app-main',
    templateUrl: './app.main.component.html',
    providers: [
        SpinnerService,
        VersionCheckService
]
})
export class AppMainComponent {
    public menuMode = 'horizontal';
    public menuActive = true;
    public topbarMenuActive = false;
    activeTopbarItem: Element;
    menuClick: boolean;
    menuButtonClick: boolean;
    topbarMenuButtonClick: boolean;
    menuHoverActive: boolean;
    inputStyle = 'outlined';
    ripple: boolean;
    configActive: boolean;
    configClick: boolean;
    showLogin: boolean = false; 
    versionCheckURL:string;
    versioncheckEnabled:boolean;
  
    notificationsSidebar:boolean= true;

    constructor(
         private menuService: MenuService, private primengConfig: PrimeNGConfig, private spinnerService: SpinnerService,
         private userService: UserService, public loginService: LoginService, private versionCheckService: VersionCheckService,
         private router: Router, private messageService: MessageService, private idleService: IdleService,
    ) {
        this.versionCheckURL = environment.versionCheckURL;
        this.versioncheckEnabled = environment.versionCheckEnabled;
     }

    ngOnInit() {
        this.primengConfig.ripple = true;
        
        if (this.versioncheckEnabled){
                this.versionCheckService.initVersionCheck(this.versionCheckURL);

                this.versionCheckService.updateReady$.subscribe({
                    next: (updateReady) => {
                        if (updateReady) {
                            this.messageService.clear('update_msg');
                            this.messageService.add({key:'update_msg', sticky: true, severity:'warn', summary:'CHAMP update available.' , detail:'Please save current work and press reload.'});
                            this.userService.LogInformation("CHAMP updated available, download, and reloaded.");
                        }
                    },
                    error: ( error) => {
                        this.userService.LogError(error);
                    }
                });
        }

        this.router.events.subscribe((event) => {
            //console.log(event);
            if (event instanceof NavigationEnd  && event.url == '/'){
               if ( this.idleService.timedOut )  {
                    this.messageService.add({severity:'info', summary: 'Inactivity Timeout', detail: 'Logged out due to user inactivity.', life: 1209600000});  // 2 Weeks display msg  
                    this.userService.LogInformation("Logged out due to user inactivity.");

               }  
            }
        });
    }

    onMenuButtonClick(event: Event) {
        this.menuButtonClick = true;
        this.menuActive = !this.menuActive;
        event.preventDefault();
    }

    onTopbarMenuButtonClick(event: Event) {
        this.topbarMenuButtonClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;
        event.preventDefault();
    }

    onTopbarItemClick(event: Event, item: Element) {
        this.topbarMenuButtonClick = true;

        if (this.activeTopbarItem === item) {
            this.activeTopbarItem = null;
        } else {
            this.activeTopbarItem = item;
        }
        event.preventDefault();
    }

    onTopbarSubItemClick(event) {
        event.preventDefault();
    }

    onLayoutClick() {
        if (!this.menuButtonClick && !this.menuClick) {
            if (this.menuMode === 'horizontal') {
                this.menuService.reset();
            }

            if (this.isMobile() || this.menuMode === 'overlay' || this.menuMode === 'popup') {
                this.menuActive = false;
            }

            this.menuHoverActive = false;
        }

        if (!this.topbarMenuButtonClick) {
            this.activeTopbarItem = null;
            this.topbarMenuActive = false;
        }

        if (this.configActive && !this.configClick) {
            this.configActive = false;
        }

        this.configClick = false;
        this.menuButtonClick = false;
        this.menuClick = false;
        this.topbarMenuButtonClick = false;
        //this.userService.SaveUserConfigValue('CHAMP.Registry.User.Settings.MenuMode', this.menuMode);
    }

    onRippleChange(event) {
        this.ripple = event.checked;
        this.userService.SaveUserConfigValue('CHAMP.Registry.User.Settings.Ripple', this.ripple.toString());
    }

    onConfigClick(event) {
        this.configClick = true;
        this.userService.SaveUserConfigValue('CHAMP.Registry.User.Settings.MenuMode', this.menuMode);
    }

    onMenuClick() {
        this.menuClick = true;
    }

    isMobile() {
        return window.innerWidth < 1025;
    }

    isHorizontal() {
        return this.menuMode === 'horizontal';
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }
}
