
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCommon, ClientMedicalPharmaAssistanceModel } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields } from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';

@Component({
  selector: 'app-pharma-assistance',
  templateUrl: './pharma-assistance.component.html',
  styleUrls: ['./pharma-assistance.component.scss']
})
export class PharmaAssistanceComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  MedicalYesNo = MedicalYesNo;

  getPharmaAssistanceScreenedControl = () => this.medical?.get('idPharmaAssistanceScreened');

  constructor(private fb: UntypedFormBuilder, 
              private commonDataService:CommonDataService,
              public providerService: ProviderService,
              private userService:UserService,
              private clientService:ClientFormService,
              private datePipe: DatePipe,
              private dateHelper:DateHelper,
              public  validationHelper:FormValidations,
              private helperService: HelperClassService,
              private medcommon:MedicalCommon) { 

  }

  ngOnInit(): void {
    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
                         });
     }
     if (!this.clientService.readonly) {
      this.validationHelper.AddRequiredMinMaxValidator(this.getPharmaAssistanceScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.Unknown); // if (this.providerService.isFieldRequired(MasterFields.PharmaScreened))
     }
  }

  refreshAfterSave() {
    this.setFieldValues();
  }  

  setFieldValues() {
    if (!this.clientData) return;
    if (!this.helperService.isExpired(this.clientData?.clientMedicalPharmaAssistance?.expired)) {
      if (this.clientData.clientMedicalPharmaAssistance?.id != null)
        this.getPharmaAssistanceScreenedControl().setValue(this.clientData.clientMedicalPharmaAssistance?.id);
    }
  }

	getModel(): Partial<ClientMedicalPharmaAssistanceModel> {
	  var model: Partial<ClientMedicalPharmaAssistanceModel> = new ClientMedicalCommon(this.userService.userID); //this.medical.value

    if (this.getPharmaAssistanceScreenedControl().dirty)
    {
      model.id = this.getPharmaAssistanceScreenedControl()?.value;
      model.datetime = new Date().toISOString();
      // moved the below into base class ClientMedicalCommon
      // model.guid = ClientFormHelper.EmptyGuid();
      // model.deleted = false;
      // model.userId = this.userService.userID;
      // model.lastUpdate = ClientFormHelper.nullDate();
      // model.expired = null;

      return model;
    } 

    return null;
	}  

  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }  

}
