<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='fg' autocomplete="off">
    <div class="p-fluid p-formgrid p-grid " style="margin-bottom: 1rem;">
        <div class="p-col-2">
            <h3><b>Contract Maintenance</b></h3>
        </div>
        <div class="field col-2" style="padding-right:2rem">
            <label htmlFor="provider" style="margin-right:1rem; font-weight: bold;">Provider</label>
            <p-dropdown formControlName="providerId" inputId="provider"  [options]="providers" optionLabel="label" optionValue="value"
                        (onChange)="onChangeFilter($event, dtProviderContracts)" ></p-dropdown>
        </div>    
        <!-- <div class="col-md-1" style="width: 12.499999995%"></div>
        Since p-col-md-1 is of width 8.33333333%; simply multiply 8.33333333 * 1.5 and set it as your width. -->
        <!-- <div class="p-field p-col-2">
            <button #refresh pButton type="button" label="Refresh" class="p-button-link" style="width: 10rem;" (click)="onChangeFilter($event, dtProviderContracts)"></button>
        </div>  -->
    </div>

    <div class="container-fluid" id="grid" > 

        <p-table #dtProviderContracts [value]="getProviderContracts()" [scrollable]="true" scrollDirection="both" scrollHeight="65vh" styleClass="p-datatable-sm p-datatable-striped"> 
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10rem"></th>
                    <!-- TODO: Need to Add New Provider Feature  -->
                    <!-- <th style="width:10rem"><p-button   label="New" [text]="true" icon="pi pi-plus" (click)="onEditUser($event,null)" [disabled]="isRowInEditMode" styleClass="p-button-text note-button font-bold" class="p-button-help p-button-text mr-1 " ></p-button></th>                                 -->

                    <th pSortableColumn="id" style="width:6rem;">ID<p-sortIcon field="id" ></p-sortIcon></th>
                    <th pSortableColumn="programName" style="width:25rem;">Name<p-sortIcon field="programName"></p-sortIcon></th>
                    <th pSortableColumn="clinicName" style="width:15rem;">Clinic<p-sortIcon field="clinicName"></p-sortIcon></th>
                    <th pSortableColumn="startDate" style="width:8rem;">Start<p-sortIcon field="startDate"></p-sortIcon></th>
                    <th pSortableColumn="endDate" style="width:8rem;">End<p-sortIcon field="endDate"></p-sortIcon></th>
                    <th pSortableColumn="contractAmt" style="width:13rem;">Amount<p-sortIcon field="contractAmt"></p-sortIcon></th>
                    <th pSortableColumn="fundYear" style="width:10rem;">Fund Year<p-sortIcon field="fundYear"></p-sortIcon></th>
                    <th style="width:10rem">Contract Items</th>
                    <th pSortableColumn="address" style="width:15rem;">Address<p-sortIcon field="address"></p-sortIcon></th>
                    <th pSortableColumn="city" style="width:10rem;">City<p-sortIcon field="city"></p-sortIcon></th>
                    <th pSortableColumn="state" style="width:5rem;">State<p-sortIcon field="state"></p-sortIcon></th>
                    <th pSortableColumn="zip" style="width:5rem;">Zip<p-sortIcon field="zip"></p-sortIcon></th>
                    <th pSortableColumn="phone" style="width:6rem;">Phone<p-sortIcon field="phone"></p-sortIcon></th>
                    <!-- <th pSortableColumn="fax" style="width:12rem;">Fax<p-sortIcon field="fax"></p-sortIcon></th> -->
                    <!-- <th pSortableColumn="url" style="width:10rem;">Url<p-sortIcon field="url"></p-sortIcon></th> -->
                    <th pSortableColumn="email" style="width:5rem;">Email<p-sortIcon field="email"></p-sortIcon></th>
                    <th pSortableColumn="memo" style="width:18rem;">Memo<p-sortIcon field="memo"></p-sortIcon></th>
                    <th pSortableColumn="includesEisUnits" style="width:9rem;">Inc Eis Units<p-sortIcon field="includesEisUnits"></p-sortIcon></th>
                    <th pSortableColumn="deleted" style="width:5rem;">Deleted<p-sortIcon field="deleted"></p-sortIcon></th>
                </tr>
                <tr>
                    <th style="width:8rem;"></th>
                    <th style="width:6rem;"></th>
                    <th style="width:25rem;">
                        <p-columnFilter type="text" field="programName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:15rem;">
                        <p-columnFilter type="text" field="clinicName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:8rem;"></th>
                    <th style="width:8rem;"></th>
                    <th style="width:13rem;">
                        <p-columnFilter type="numeric" field="contractAmt" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:10rem;">
                        <p-columnFilter type="text" field="fundYear" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:10rem;"></th>
                    <th style="width:15rem;">
                        <!-- <p-columnFilter type="text" field="address" matchMode="contains"></p-columnFilter> -->
                    </th>
                    <th style="width:10rem;">
                        <!-- <p-columnFilter type="text" field="city" matchMode="contains"></p-columnFilter> -->
                    </th>         
                    <th style="width:5rem;">
                        <!-- <p-columnFilter type="text" field="state" matchMode="contains"></p-columnFilter> -->
                    </th>
                    <th style="width:5rem;">
                        <!-- <p-columnFilter type="text" field="zip" matchMode="contains"></p-columnFilter> -->
                    </th>
                    <th style="width:6rem;">
                        <!-- <p-columnFilter type="text" field="phone" matchMode="contains"></p-columnFilter> -->
                    </th>
                    <!-- <th style="width:12rem;">
                        <p-columnFilter type="text" field="fax" matchMode="contains"></p-columnFilter>
                    </th> -->
                    <!-- <th style="width:10rem;">
                        <p-columnFilter type="text" field="url" matchMode="contains"></p-columnFilter>
                    </th>                                   -->
                    <th style="width:5rem;">
                        <!-- <p-columnFilter type="text" field="email" matchMode="contains"></p-columnFilter> -->
                    </th>
                    <th style="width:18rem;">
                        <p-columnFilter type="text" field="memo" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:9rem;">
                        <p-columnFilter type="boolean" field="includesEisUnits" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:5rem;">
                        <p-columnFilter type="boolean" field="deleted" matchMode="contains"></p-columnFilter>
                    </th>                    
                </tr> 
            </ng-template>
            <ng-template pTemplate="body" let-contract let-editing="editing" let-rowIndex="rowIndex">
                <tr >  <!-- [pEditableRow]="event" -->
                    <td style="width:10rem;text-align:center" #buttonContainer>
                        <button #editRowButton  pButton pRipple type="button"  icon="pi pi-pencil"  class="p-button-rounded p-button-text" pTooltip="Edit Contract" (click)="onEditContract($event, contract)"></button>  
                        <button #deleteRowButton  pButton pRipple type="button" icon="pi pi-trash"  class="p-button-rounded p-button-text p-button-danger" pTooltip="Delete Contract" (click)="onDeleteContract($event, contract)"></button> 
                        <button #cloneRowButton  pButton pRipple type="button" icon="pi pi-clone"  class="p-button-rounded p-button-text" pTooltip="Clone Contract" (click)="onCloneContract($event, contract)"></button>
                    </td>                    
                    <td style="width:6rem;"> {{ contract?.id }} </td>
                    <td style="width:25rem;"><span title="{{contract.programName}}"> {{ contract?.programName }} </span></td>
                    <td style="width:15rem;"><span title="{{contract.clinicName}}"> {{ contract?.clinicName }} </span></td>
                    <td style="width:8rem;"><span title="{{contract.startDate | date:'MM/dd/yyyy'}}"> {{ contract?.startDate | date:'MM/dd/yyyy' }} </span></td>
                    <td style="width:8rem;"><span title="{{contract.endDate | date:'MM/dd/yyyy'}}"> {{ contract?.endDate | date:'MM/dd/yyyy' }} </span></td>                    
                    <td style="width:13rem;text-align:right !important;display:table-cell;padding-right:5rem;"><span title="{{contract.contractAmt | currency:'CAD':'symbol-narrow'}}"> {{ contract?.contractAmt | currency:'CAD':'symbol-narrow' }} </span></td>
                    <td style="width:10rem;"><span title="{{contract.fundYear}}"> {{ contract?.fundYear }} </span></td>
                    <td style="width:10rem;"><button pButton pRipple type="button" icon="pi pi-list" (click)="openContractItems(contract)" class="p-button-rounded p-button-text"></button></td>
                    <td style="width:15rem;"><span title="{{contract.address}}"> {{ contract?.address }} </span></td>
                    <td style="width:10rem;"><span title="{{contract.city}}"> {{ contract?.city }} </span></td>
                    <td style="width:5rem;"><span title="{{contract.state}}"> {{ contract?.state }} </span></td>
                    <td style="width:5rem;"><span title="{{contract.zip}}"> {{ contract?.zip }} </span></td>                    
                    <td style="width:6rem;"><span title="{{contract.phone}}"> {{ contract?.phone }} </span></td>
                    <!-- <td style="width:12rem;"><span title="{{contract.fax}}"> {{ contract?.fax }} </span></td> -->
                    <!-- <td style="width:10rem;"><span title="{{contract.url}}"> {{ contract?.url }} </span></td> -->
                    <td style="width:5rem;"><span title="{{contract.email}}"> {{ contract?.email }} </span></td>
                    <td style="width:18rem;"><span title="{{contract.memo}}"> {{ contract?.memo }} </span></td>
                 
                    <!-- <td style="width:9rem;"><i class="pi" [ngClass]="{'true-icon pi-check-circle': contract?.includesEisUnits, 'false-icon pi-times-circle p-button-danger': !contract?.includesEisUnits}"></i></td>                    
                    <td style="width:5rem;"><i class="pi" [ngClass]="{'true-icon pi-check-circle': contract?.deleted, 'false-icon pi-times-circle p-button-danger': !contract?.deleted}"></i></td>                     -->

                    <td style="width:9rem;"><div *ngIf="contract?.includesEisUnits">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   </td>
                    <td style="width:9rem;"><div *ngIf="contract?.deleted">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   </td>
                </tr>
            </ng-template>
        </p-table>                    
    </div> 
</form>