import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { UserMaintenanceService } from '../user-maintenance/user-maintenance.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserProviderClinicPermService } from '../user-provider-clinic-perm/user-provider-clinic-perm.service';
import { AdminEmployeesModel, AdminProviderEmployeeModel, AdminUserModel } from 'src/app/models/admin.models';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FormControl, FormGroup } from '@angular/forms';
import { CommonData } from 'src/app/models/common-data.model';
import { ProviderModel } from 'src/app/models/provide.model';
import { DropdownChangeEvent } from 'primeng/dropdown';

@Component({
  selector: 'app-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.scss']
})
export class EmployeeComponent {

  @Input() mode: string = "user";   // Modes  user, client, provider
  @Output("onChange") refreshEmployee: EventEmitter<number> = new EventEmitter();
  @ViewChild('employeeOverlay', { static: false }) employeeOverlay: OverlayPanel;

  employee:AdminUserModel;
  employees:AdminEmployeesModel[];
  public  employeeId: number;
  providers:any[];
  user:Partial<AdminUserModel>;
  multiSelectEmployeeDropDownSettings: any = {};
  selectedEmployees: AdminEmployeesModel[];
  label:string = "Employee";

  public fg = new FormGroup({
    employeeId: new FormControl(0)
  });

  public fgEmployee = new FormGroup({
    guid: new FormControl(''),  
    employeeId: new FormControl(0), 
    firstName: new FormControl(''), 
    middleName: new FormControl(''), 
    lastName: new FormControl(''), 
    emailAddress: new FormControl(''), 
    phone: new FormControl(''), 
    position: new FormControl(0),
    providerId: new FormControl(0),
    test: new FormControl(0),
    deleted: new FormControl(false),
    linkToUser: new FormControl(false),
  });

  getEmployeeId = () => this.fg?.get('employeeId');
  getEmployeePosition = (id) => { return  this.app.commonServ.getDescription('EmployeePosition',  id); }
  positions:CommonData[];

  constructor(
    public app: AppContextService,
    public userMaintenanceService: UserMaintenanceService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public userProviderClinicPermService: UserProviderClinicPermService
  ){

  
  }

  ngOnInit(): void {
    this.positions = this.app.commonServ.getCommonSetByName('EmployeePosition');
    this.fg.get('employeeId').valueChanges.subscribe(value  => { 
        this.employeeId = value;  
    });

  }

  public load(employeeId:number, user:Partial<AdminUserModel> ){
    this.employeeId = employeeId;
    this.user = user;
    this.fg.patchValue({employeeId:this.employeeId})
    this.providers = this.app.userServ.getAccessibleProviders().map(provider => { const result: any = {id: provider.providerId, 
            providerName: `${provider.providerName}`};    return result; });

    if (this.mode == 'provider') this.label = "Employees"

  }

  public getEmployees (providerId:number) {
     this.userMaintenanceService.getEmployeesFromProvider(providerId).subscribe({
        next: (data:AdminEmployeesModel[]) => {    
            this.employees =  data;
            this.fg.patchValue({employeeId:this.employeeId})
        },
        error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Failed to load employees' });
            console.log(error);
          },
        complete: () => { }
        });
  }

  public getEmployeesFromMultipleProviders (providers:string) {
    this.userMaintenanceService.getEmployeesFromMultipleProviders(providers).subscribe({
       next: (data:AdminEmployeesModel[]) => {    
           this.employees =  data;
           this.fg.patchValue({employeeId:this.employeeId})
       },
       error: (error) => { 
           this.messageService.add({ severity: 'error', summary: error, detail: 'Failed to load employees' });
           console.log(error);
         },
       complete: () => { }
       });
 }

  

  public getEmployee (employeeId) {
    this.userMaintenanceService.getEmployee(employeeId).subscribe({
      next: (data:AdminProviderEmployeeModel )=> {    
          this.fgEmployee.patchValue({employeeId: data.id, firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, 
            emailAddress: data.emailAddress, phone: data.phone, position: data.position, guid: data.guid, providerId: data.providerId });
      },
      error: (error) => { 
          this.app.userServ.LogErrorObject(error);
          this.messageService.add({ severity: 'error', summary: error, detail: 'Failed to load employees' });
          console.log(error);
        },
      complete: () => { }
      });
  }
  
 public onSaveEmployee(e) {
    this.saveEmployee();
 }

 public saveEmployee () {
    let model: Partial<AdminProviderEmployeeModel> = {};
    Object.assign(model, {});
    Object.assign(model, this.fgEmployee.value)

    this.userMaintenanceService.saveEmployee(model).subscribe({
      next: (data:AdminProviderEmployeeModel )=> {    
          this.fgEmployee.patchValue({employeeId: data.id, firstName: data.firstName, middleName: data.middleName, lastName: data.lastName, 
            emailAddress: data.emailAddress, phone: data.phone, position: data.position, guid: data.guid, providerId: data.providerId });
            //this.fgEmployee.updateValueAndValidity();
           // this.refreshEmployee.emit()
      },
      error: (error) => { 
         this.app.userServ.LogErrorObject(error);
         this.messageService.add({ severity: 'error', summary: 'Failed to save employee', detail:error?.error?.message  });
         //console.log(error);
        },
      complete: () => { }
      });
  }

  public onAddNewEmployee(e){
    //this.mode = mode;
    this.employeeOverlay.toggle(e);
    let model: Partial<AdminProviderEmployeeModel> = {};
    Object.assign(model, {});
    //Object.assign( this.fgEmployee.value, model),
    this.fgEmployee.patchValue({guid:'00000000-0000-0000-0000-000000000000'});  
    // if (this.user.providerUsers[0]) 
    //   {  this.fgEmployee.patchValue({providerId:  parseInt(this.user.providers[0])   });  }
    //   this.fgEmployee.updateValueAndValidity();
    this.app.overLayMangr.add(this.employeeOverlay, 'overlay');
  }

  public onEditEmployee(e, employeeId){
    this.employeeOverlay.toggle(e);
    this.getEmployee(this.fg?.value?.employeeId);
    this.app.overLayMangr.add(this.employeeOverlay, 'overlay');
  }

  public onClose(e){
    this.app.overLayMangr.remove( this.employeeOverlay);
      //this.employeeOverlay.toggle(e);
  }

  onEmployeeChanged(e:DropdownChangeEvent){
    this.refreshEmployee.emit(this.employeeId); // let the pages know that the employee changed
  }

  public onCopyFieldsFromUser(e){
    let providerId:number = this.fgEmployee.value?.providerId; 
    if (this.user?.providerUsers[0]) 
    {
        providerId = this.user.providerUsers[0]?.providerId;
    }

    this.fgEmployee.patchValue({ firstName: this.user.firstName, lastName: this.user.lastName, middleName: this.user?.middleName, 
                                 emailAddress :this.user.emailAddress, providerId: providerId });
  }


}
