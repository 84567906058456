import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
//import { stringify } from '@angular/compiler/src/util';
import { parseISO } from 'date-fns';
import { Observable } from 'rxjs';
import { ClientDirectService } from 'src/app/client-direct-services/services/client-direct-service-models';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { ClientModel } from 'src/app/models/client.model';
import { MCMListResponseModel, MCMRequestModel, NonRWVisit } from 'src/app/models/mcm.models';
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';

@Injectable({
  providedIn: 'root'
})
export class MCMService {
private readonly URL = environment.apiURL + '/mcm';
clientData: ClientModel;

getDefaultDate = () => parseISO('1900-01-01');

constructor(private http: HttpClient, public clientService:ClientFormService, 
            private providerService: ProviderService, 
            private commonDataService: CommonDataService,
            private userService: UserService) { }

//
public getMCMServices(request:MCMRequestModel) : Observable<MCMListResponseModel>
{ 
  return this.http.post<MCMListResponseModel>(this.URL + "/services", request);
}

public getStandardFilterSelections() {
  return [{name:"Current Month", code:"0"},
          {name:"Last Month", code:"1"},
          {name:"Last 3 Months", code:"3"},
          {name:"Last 6 Months", code:"6"},
          {name:"Last 12 Months", code:"12"},
          {name:"Current Fiscal Year", code:"13"}];
}


public getNonRWVisit(recGuid:string) : Observable<NonRWVisit>
{ 
  let params = new HttpParams();
  params = params.append('guid', recGuid);
  
  return this.http.get<NonRWVisit>(this.URL + "/getnonrwvisit",  { params:params });
}

  
public saveNonRWVisit(model:Partial<NonRWVisit>) : Observable<NonRWVisit>
{ 
  let url  = this.URL + '/addNonRWService';
  return this.http.post<NonRWVisit>(url, model);
}


public deleteNonRWVisit(model:Partial<NonRWVisit>, undelete:boolean=false ) : Observable<boolean>
{ 
  let url  = this.URL + '/deleteNonRWVisit';
  let params = new HttpParams();
  params = params.append('guid', model.guid.toString());
  params = params.append('undelete', undelete);

  return this.http.get<boolean>(url, { params:params });
}



public getLatestDirectServiceWithRiskReduction(clientGuid:string) : Observable<ClientDirectService>
{ 
  let params = new HttpParams();
  params = params.append('clientGuid', clientGuid);
  
  return this.http.get<NonRWVisit>(this.URL + "/getDirectServiceWLatestRiskReduction",  { params:params });
}



}