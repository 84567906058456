<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='fg' autocomplete="off">
    <div class="p-fluid p-formgrid p-grid " style="margin-bottom: 1rem;">
        <div class="p-col-2">
            <h3><b>Client Merge</b></h3>
        </div>
        <div class="field col-2" style="padding-right:2rem">
            <label htmlFor="provider" style="margin-right:1rem; font-weight: bold;">Provider</label>
            <p-dropdown formControlName="providerId" inputId="provider"  [options]="providers" optionLabel="label" optionValue="value"
                        (onChange)="onChangeProviderFilter($event)" ></p-dropdown>
        </div>        
        <!-- <div class="col-md-1" style="width: 12.499999995%"></div>
        Since p-col-md-1 is of width 8.33333333%; simply multiply 8.33333333 * 1.5 and set it as your width. -->
        <!-- <div class="p-field p-col-2">
            <button #refresh pButton type="button" label="Refresh" class="p-button-link" style="width: 10rem;" (click)="onChangeFilter($event, dtSourceClients)"></button>
        </div>  -->
    </div>

    <div class="container-fluid" id="grid" >
        <div class="p-col-12" style="padding:.5em">
            <div class="p-fluid p-formgrid grid">
                <div class="field col-1">
                    <label htmlFor="sourceClientAltId" style="margin-right:1rem;font-weight: 500;">Source Client Filter</label>                    
                    <input type="text" style="text-transform: uppercase;" id="sourceClientAltId" formControlName ="sourceClientAltId" pInputText/>
                </div>
                <div class="field col-1" style="align-self: flex-end;">
                    <button #refreshSource pButton type="button" label="Refresh" class="p-button-link" style="width: 5rem;" (click)="onChangeSourceFilter($event, dtSourceClients)"></button>                                
                </div>
            </div>
        </div>
        <p-table #dtSourceClients [value]="getSourceClients()" selectionMode="single" [scrollable]="true" scrollDirection="both" scrollHeight="25vh" styleClass="p-datatable-sm p-datatable-striped"> 
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th pFrozenColumn style="width:4rem"><p-tableHeaderCheckbox class="ml-1"></p-tableHeaderCheckbox></th> -->
                    <th style="width:4rem"></th>
                    <th pSortableColumn="clientAltId" style="width:10rem;">Client Alt Id<p-sortIcon field="clientAltId" ></p-sortIcon></th>
                    <th pSortableColumn="clientGuid" style="width:18rem;">Client Guid<p-sortIcon field="clientGuid"></p-sortIcon></th>
                    <th pSortableColumn="providerClientId" style="width:10rem;">Provider Client Id<p-sortIcon field="providerClientId"></p-sortIcon></th>
                    <th pSortableColumn="clientStatusDesc" style="width:15rem;">Client Status<p-sortIcon field="clientStatusDesc"></p-sortIcon></th>
                    <th pSortableColumn="clientHivStatusDesc" style="width:15rem;">Client Hiv Status<p-sortIcon field="clientHivStatusDesc"></p-sortIcon></th>                    
                </tr>
                <tr>
                    <th style="width:4rem;"></th>
                    <th style="width:10rem;">
                        <p-columnFilter type="text" field="clientAltId" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:18rem;">
                        <p-columnFilter type="text" field="clientGuid" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:10rem;">
                        <p-columnFilter type="numeric" field="providerClientId" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:15rem;">
                        <p-columnFilter type="text" field="clientStatusDesc" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:15rem;">
                        <p-columnFilter type="text" field="clientHivStatusDesc" matchMode="contains"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-source let-editing="editing" let-rowIndex="rowIndex">
                <tr>  <!-- [pEditableRow]="event" -->
                    <td style="width:4rem;"><p-tableCheckbox [value]="source"></p-tableCheckbox></td>
                    <td style="width:10rem;"><span title="{{ source.clientAltId }}"> {{ source?.clientAltId }} </span></td>
                    <td style="width:18rem;"><span title="{{ source.clientGuid }}"> {{ source?.clientGuid }} </span></td>
                    <td style="width:10rem;"><span title="{{ source.providerClientId }}"> {{ source?.providerClientId }} </span></td>
                    <td style="width:15rem;"><span title="{{ source.clientStatusDesc }}"> {{ source?.clientStatusDesc }} </span></td>
                    <td style="width:15rem;"><span title="{{ source.clientHivStatusDesc }}"> {{ source?.clientHivStatusDesc }} </span></td>                                        
                </tr>
            </ng-template>
            <!-- <ng-template pTemplate="footer">
                <tr>
                    <td colspan="1"></td>
                    <td style="width:4rem;">RowCount: {{this.getSourceClients().length}}</td>
                </tr>
            </ng-template>             -->
        </p-table>
        <br>
        <div class="field col-12" style="padding-right:2rem">
            Row Count: {{ this.getSourceClients()?.length }}
        </div>
    </div>
    <br><br>
    <div class="container-fluid" id="grid" >
        <div class="p-col-12" style="padding:.5em">
            <div class="p-fluid p-formgrid grid">
                <div class="field col-1">
                    <label htmlFor="destinationClientAltId" style="margin-right:1rem;font-weight: 500;">Dest. Client Filter</label>
                    <input type="text" style="text-transform: uppercase;" id="destinationClientAltId" formControlName ="destinationClientAltId" pInputText/>
                </div>
                <div class="field col-1" style="align-self: flex-end;">
                    <button #refreshDestination pButton type="button" label="Refresh" class="p-button-link" style="width: 5rem;" (click)="onChangeDesintationFilter($event, dtDestinationClients)"></button>                             
                </div>
            </div>
        </div>
        <p-table #dtDestinationClients [value]="getDestinationClients()" selectionMode="single" [scrollable]="true" scrollDirection="both" scrollHeight="25vh" styleClass="p-datatable-sm p-datatable-striped"> 
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th pFrozenColumn style="width:4rem"><p-tableHeaderCheckbox class="ml-1"></p-tableHeaderCheckbox></th> -->
                    <th pFrozenColumn style="width:4rem"></th>
                    <th pSortableColumn="clientAltId" style="width:10rem;">Client Alt Id<p-sortIcon field="clientAltId" ></p-sortIcon></th>
                    <th pSortableColumn="clientGuid" style="width:18rem;">Client Guid<p-sortIcon field="clientGuid"></p-sortIcon></th>
                    <th pSortableColumn="providerClientId" style="width:10rem;">Provider Client Id<p-sortIcon field="providerClientId"></p-sortIcon></th>
                    <th pSortableColumn="clientStatusDesc" style="width:15rem;">Client Status<p-sortIcon field="clientStatusDesc"></p-sortIcon></th>
                    <th pSortableColumn="clientHivStatusDesc" style="width:15rem;">Client Hiv Status<p-sortIcon field="clientHivStatusDesc"></p-sortIcon></th>                    
                </tr>
                <tr>
                    <th style="width:4rem;"></th>
                    <th style="width:10rem;">
                        <p-columnFilter type="text" field="clientAltId" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:18rem;">
                        <p-columnFilter type="text" field="clientGuid" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:10rem;">
                        <p-columnFilter type="numeric" field="providerClientId" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:15rem;">
                        <p-columnFilter type="text" field="clientStatusDesc" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:15rem;">
                        <p-columnFilter type="text" field="clientHivStatusDesc" matchMode="contains"></p-columnFilter>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-dest let-editing="editing" let-rowIndex="rowIndex">
                <tr>  <!-- [pEditableRow]="event" -->
                    <td pFrozenColumn style="width:4rem;"><p-tableCheckbox [value]="dest"></p-tableCheckbox></td>
                    <td style="width:10rem;"><span title="{{ dest.clientAltId }}"> {{ dest?.clientAltId }} </span></td>
                    <td style="width:18rem;"><span title="{{ dest.clientGuid }}"> {{ dest?.clientGuid }} </span></td>
                    <td style="width:10rem;"><span title="{{ dest.providerClientId }}"> {{ dest?.providerClientId }} </span></td>
                    <td style="width:15rem;"><span title="{{ dest.clientStatusDesc }}"> {{ dest?.clientStatusDesc }} </span></td>
                    <td style="width:15rem;"><span title="{{ dest.clientHivStatusDesc }}"> {{ dest?.clientHivStatusDesc }} </span></td>                                        
                </tr>
            </ng-template>
        </p-table>
        <br>
        <div class="field col-12" style="padding-right:2rem">
            Row Count: {{ this.getDestinationClients()?.length }}
        </div>        
    </div>
    <div class="field col-12" style="padding-right:2rem">
        <button pButton pRipple class="btn btn-primary" (click)="onMergeClientConfirm($event)" icon="pi pi-check" [disabled]="!this.isFormValid()"
                label="Merge Client" style="width: 130px;float: right;margin-right: 5px;margin-top: 0.5rem;"></button>
    </div>
</form>