import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { clone, orderBy, sum } from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { LoginService } from 'src/app/login/services/login.service';
import { AdminEmployeesModel, MonthModel, ProviderContractMaintenanceModel, ProviderContractMaintenanceRequestModel, RecalcUnitsModelRequest, RecalcUnitsModelResponse, UnitMaintenanceModelOptions, UnitMaintenanceModelRequest, UnitMaintenanceModelResponse, UnitMaintenanceModelUpdateRequest, UnitMaintenanceServicesModel } from 'src/app/models/admin.models';
import { CommonData, ServiceCategory, SubType } from 'src/app/models/common-data.model';
import { ProviderModel } from 'src/app/models/provider';
import { ContractMaintenanceService } from '../contract-maintenance/contract-maintenance.service';
import { UnitMaintenanceService } from './unit-maintenance.service';

@Component({
  selector: 'app-unit-maintenance',
  templateUrl: './unit-maintenance.component.html',
  styleUrls: ['./unit-maintenance.component.scss', '../../../styles.scss',  '../admin-styles.scss' ]
})
export class UnitMaintenanceComponent implements OnInit {
  providers:ProviderModel[];
  providerContracts:any[]=[];
  //providerContracts:ProviderContractMaintenanceModel[]=[];
  providerContractsToUpdate:any[]=[];
  unitData:UnitMaintenanceServicesModel[];
  selectedUnitData:UnitMaintenanceServicesModel[];
  currentRequest: Partial<UnitMaintenanceModelRequest>;
  fundingYears: string[];
  months: MonthModel[];
  serviceCategories: ServiceCategory[];
  serviceCategoriesToUpdate: ServiceCategory[];
  fundersToUpdate:CommonData[];
  subTypes: SubType[];
  subTypesToUpdate: Partial<SubType>[] = [];
  employees:any[];
  updateDialog:boolean= false;

  getProviderId = () => this.fg?.get('providerId');
  getProviderContract = () => this.fg?.get('providerContract');
  getFunder = () => this.fg?.get('funder');
  getFundingYear = () => this.fg?.get('fundingYear');
  getMonth = () => this.fg?.get('month');
  getClientId = () => this.fg?.get('clientId');
  getEthnicity = () => this.fg?.get('ethnicity');
  getRace = () => this.fg?.get('race');
  getReasonCode = () => this.fg?.get('reasonCode');
  getServiceId = () => this.fg?.get('serviceId');
  getSubType = () => this.fg?.get('subType');
  getStaff = () => this.fg?.get('staff');
  getBillable = () => this.fg?.get('billable');
  getNonBillable = () => this.fg?.get('nonBillable');
  getDeleted = () => this.fg?.get('deleted');
  getMemo = () => this.fg?.get('memo');


  getReplaceUnitValue = () => this.unitUpdateOptionsFG?.get('replaceUnitValue');
  getUnitValue = () => this.unitUpdateOptionsFG?.get('unitValue');
  getBillableUpdateOption = () => this.unitUpdateOptionsFG?.get('billableUpdateOption');
  getNonBillableUpdateOption= () => this.unitUpdateOptionsFG?.get('nonBillableUpdateOption');
  getDeleteUpdateOption = () => this.unitUpdateOptionsFG?.get('deleteUpdateOption');
  getContractUpdateOption = () => this.unitUpdateOptionsFG?.get('contractUpdateOption');
  getUpdateOnlyUnlinkedContracts = () => this.unitUpdateOptionsFG?.get('updateOnlyUnlinkedContracts');
  getNewServiceOption = () => this.unitUpdateOptionsFG?.get('newServiceOption');
  getNewSubTypeOption = () => this.unitUpdateOptionsFG?.get('newSubTypeOption');
  getNewFunderOption = () => this.unitUpdateOptionsFG?.get('newFunderOption');

  funders = () => this.app.commonServ.getCommonSetByName("Funder", true, "ALL");
  ethnicities = () => this.app.commonServ.getCommonSetByName("Ethnicity", true, "ALL");
  race = () => this.app.commonServ.getCommonSetByName("Race", true, "ALL");
  service = () => this.app.commonServ.getCommonSetByName("Race", true, "ALL");
  reasons = () => this.app.commonServ.getCommonSetByName("UnbillableReason", true, "ALL");

  binaryOptions:any[] = [ {"id":-1,  "description": "Leave Original Value"},
                          {"id":0,  "description": "Set Option to No"},
                          {"id":1,  "description": "Set Option to Yes"}];

  
  public fg = new FormGroup({
      providerId: new FormControl(0),
      providerContract: new FormControl(''),
      funder: new FormControl(0),
      fundingYear : new FormControl(''),
      month : new FormControl(0),
      clientId : new FormControl(''),
      ethnicity : new FormControl(0),
      race : new FormControl(0),
      reasonCode : new FormControl(0),
      serviceId : new FormControl(0),
      subType : new FormControl(0),
      staff : new FormControl(0),
      billable : new FormControl('0'),
      nonBillable : new FormControl('0'),
      deleted : new FormControl('0'),
      memo : new FormControl(''),
  });


  public unitUpdateOptionsFG = new FormGroup({
    replaceUnitValue: new FormControl(false),
    unitValue: new FormControl(0),
    billableUpdateOption : new FormControl(-1),
    nonBillableUpdateOption : new FormControl(-1),
    deleteUpdateOption : new FormControl(-1),
    contractUpdateOption : new FormControl('00000000-0000-0000-0000-000000000000'),
    updateOnlyUnlinkedContracts: new FormControl(true),
    newServiceOption : new FormControl(0),
    newSubTypeOption : new FormControl(0),
    newFunderOption : new FormControl(0),
  });

  constructor(
        private authService: AuthService,
        private loginService: LoginService,
        private contractMaintenanceService: ContractMaintenanceService,
        private unitMaintenanceService: UnitMaintenanceService,
        private app: AppContextService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        public validationHelper:FormValidations,
        private spinnerService:SpinnerService
     ){
  }

  ngOnInit(): void {
        this.app.updateTabTitle('CHAMP - Unit Maintenance');

        // Feature disabled for now
        // this.app.userServ.GetUserConfigValue('CHAMP.Registry.Admin.CurrentProviderId').subscribe({
        //   next: data => {
        //      this.getProviderId().setValue(Number(data?.keyValue));  
        //      this.loadData(); 
        //  }});

        this.loadData(); 

        this.getProviderContract().valueChanges.subscribe(value => {
            if (value != 'ALL'){
              let contract = this.providerContracts.find(x=> x.guid==value);
              this.getFundingYear().setValue('FY' + contract.fundYear);
            }
        })
  }

  loadData(){
    this.app.provSvc.getProviders().subscribe({
      next: (data: ProviderModel[]) => {
            this.providers = orderBy(data, ['providerName'], ['asc']);
            //if (this.getProviderId().value == null ) this.getProviderId().setValue( this.providers[0].id);

            this.getProviderId().setValue( this.providers[0].id);
            this.loadProviderContracts();
            //this.getProviderId().setValue(1);
            this.loadProviderContracts();
            this.loadProviderEmployees();
            //this.getProviderContract().setValue('af8b613f-eb34-4070-8397-03c0763e80e2');
            this.getDeleted().setValue('0');
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => { }
      });

     this.unitMaintenanceService.getFundingYears().subscribe({next: (data) => { 
                this.fundingYears =  orderBy(data, [], ['desc']); 
                //this.fundingYears.unshift('ALL');
              }});

     this.unitMaintenanceService.getMonths().subscribe({next: (data) => { this.months = data;}  });
     this.serviceCategories = this.app.commonServ.getServiceCategories(0, true);
     this.serviceCategories[0].description = "All Service Types";
     this.serviceCategoriesToUpdate = this.app.commonServ.getServiceCategories(0, true); //clone(this.serviceCategories);
     this.serviceCategoriesToUpdate[0].description = "Leave Orginal Value";
     this.fundersToUpdate = clone(this.funders());
     this.fundersToUpdate[0].description = "Leave Original Value";
     this.subTypesToUpdate = this.app.commonServ.getSubTypesByService(0, 0, false, true);
     this.subTypesToUpdate[0].description = "Leave Original Value";
     this.subTypesToUpdate[0].id = 0 ;
     let allVal: Partial<ProviderContractMaintenanceModel> = {programName: "ALL", providerId:0, guid:'00000000-0000-0000-0000-000000000000'};
     this.providerContracts.unshift(allVal);
  }

  onProvidersClick(e){
    this.loadProviderContracts();
    this.loadProviderEmployees();

    this.app.userServ.SaveUserConfigValue('CHAMP.Registry.Admin.CurrentProviderId',  this.getProviderId()?.value?.toString());
  }

  loadProviderContracts() {
    var request: Partial<ProviderContractMaintenanceRequestModel> = {};
    Object.assign(request, {});
    request.providerId = this.getProviderId().value;

    this.contractMaintenanceService.getProviderContracts(request).subscribe({
      next: (data: ProviderContractMaintenanceModel[]) => {
            //console.log(data);
            this.providerContracts = clone(data);
            let allVal: Partial<ProviderContractMaintenanceModel> = {programName: "ALL", providerId:0, guid:'00000000-0000-0000-0000-000000000000'};
            this.providerContracts.unshift(allVal);

            this.providerContractsToUpdate = clone(data);;
            let defaultlVal: Partial<ProviderContractMaintenanceModel> = {programName: "Leave Original Value", providerId:0, guid:'00000000-0000-0000-0000-000000000000'};
            this.providerContractsToUpdate.unshift(defaultlVal);

          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => { }
      });
  }

  loadProviderEmployees(){
    this.unitMaintenanceService.getEmployeesFromProvider(this.getProviderId().value).subscribe({
      next: (data:AdminEmployeesModel[]) => {    
           this.employees =  data.filter(x=> x.lastName != null );
           let positions = this.app.commonServ.getCommonSetByName("EmployeePosition");
           this.employees.forEach(e=> { 
              e.positionName = positions.find( x=> x.id == e.position)?.description ?? "";
              e.FullName = e.name  + '  ' + e.title + ' - [' + e.positionName + ']'; 
            });
           this.employees.unshift({ id:0, FullName:"ALL"});
       },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Failed to load employees' });
          this.app.userServ.LogError(error);
         },
      complete: () => { }
      });
  }



  onChangeServiceType(serviceId: number) {
    this.subTypes = this.app.commonServ.getSubTypesByService(serviceId, 0, false, true);
  }

  onChangeServiceTypeForUpdate(serviceId: number) {
    this.subTypesToUpdate = this.app.commonServ.getSubTypesByService(serviceId, 0, false, true);
    this.subTypesToUpdate[0].description = "Leave Original Value";
    this.subTypesToUpdate[0].id = 0 ; 
  }

  getUnits(){
        return this.unitData;
  }

  getTotalServicesCount = () => {  return this.unitData?.length  }
  getSumOfUnits = () => {  return   sum(this.unitData?.map(element => element.unit));  }

  checkParams(){
    if (this.getProviderId().value == 0 || this.getProviderContract().value == '00000000-0000-0000-0000-000000000000' )
    {
      this.messageService.add({ severity: 'error', summary: 'Must select Provider and Provider Contract', detail: 'Request failed' });
      return false;
    }

    return true;
  }

  refreshUnits(e){
    if (!this.checkParams()) return false;

    var request: Partial<UnitMaintenanceModelRequest> = {};
    Object.assign(request, {});
    request.providerId = this.getProviderId().value;
    request.contractGuid = this.getProviderContract().value;
    request.funder = this.getFunder().value;
    request.fundingYear = this.getFundingYear().value;
    request.month = this.getMonth().value;
    request.ethnicity = this.getEthnicity().value;
    request.race = this.getRace().value;
    request.clientAltId = this.getClientId().value;
    request.serviceId = this.getServiceId().value;
    request.subType = this.getSubType().value;
    request.staff = this.getStaff().value;
    request.reasonCode = this.getReasonCode().value;
    request.deleted = this.getDeleted().value == '1' ? true: false ;
    request.billable = this.getBillable().value == '1' ? true: false ;
    request.nonBillable = this.getNonBillable().value == '1' ? true: false ;
    this.currentRequest = request;

    this.spinnerService.On();
    this.unitMaintenanceService.getUnitMaintenanceUnits(request).subscribe({
      next: (response: UnitMaintenanceModelResponse) => {
            //console.log(data);
            if ( response.success) {
                this.unitData = response.services;
            }
            else {
              this.unitData = [];
              this.messageService.add({ severity: 'warn', summary: 'Request' , detail:  response.message });
              this.app.userServ.LogError(response.message );
            }
          },
      error: (error) => { 
            let msg = this.getErroMessage(error);
            this.messageService.add({ severity: 'error', summary: 'Request failed' , detail:  msg });
            this.app.userServ.LogError(error);
            this.spinnerService.Off();
          },
      complete: () => {
        this.spinnerService.Off(); }
      });
  }

  getErroMessage(error) {
    if (error) {
     if (error.error){
            if (error.message) {
              return error.error.message;
           } else  if (error.error.errors){
                return JSON.stringify(error.error.errors);
            }
       }
    }
    return 'Unexpected error occurred';
  }

  showUpdateDialog(e){
      this.updateDialog = true;
      
  }

  checkParamsForOptions(){
    if (this.getProviderId().value == 0 || this.getProviderContract().value?.length == 0 )
    {
      this.messageService.add({ severity: 'error', summary: 'Must select Provider and Provider Contract', detail: 'Request failed' });
      return false;
    }

    return true;
  }

  saveUnitChanges(e){
    if (!this.checkParamsForOptions()) return false;
      var options: UnitMaintenanceModelOptions = {
          replaceUnitValue : this.getReplaceUnitValue().value,
          unitValue : this.getUnitValue().value,
          billableUpdateOption : this.getBillableUpdateOption().value,
          nonBillableUpdateOption : this.getNonBillableUpdateOption().value,
          deleltedUpdateOption : this.getDeleteUpdateOption().value,
          contractOption : this.getContractUpdateOption().value,
          newServiceTypeOption : this.getNewServiceOption().value,
          newSubTypeOption : this.getNewSubTypeOption().value,
          newFunderOption:  this.getNewFunderOption().value,
          updateOnlyLinkedContracts: false
       };

      var request: UnitMaintenanceModelUpdateRequest= {request: this.currentRequest, options:options,unitsToUpdate :this.selectedUnitData };

      this.spinnerService.On();
      this.unitMaintenanceService.saveUnitMaintenanceUnits(request).subscribe({
        next: (response: UnitMaintenanceModelResponse) => {
              //console.log(data);
              if ( response.success) {
                this.refreshUnits(e);
                  this.updateDialog = false;
                  this.messageService.add({severity:'success', summary: 'Successful', detail: response.message, life: this.app.messageDisplayTime});
                  this.selectedUnitData = [];
              }
              else {
                this.unitData = [];
                this.messageService.add({ severity: 'warn', summary: 'Request' , detail:  response.message });
                this.app.userServ.LogError( response.message );
              }
            },
        error: (error) => { 
              let msg = this.getErroMessage(error);
              this.messageService.add({ severity: 'error', summary: 'Request failed' , detail:  msg });
              this.app.userServ.LogError(msg);
              this.spinnerService.Off();
            },
        complete: () => {this.spinnerService.Off(); }
        });
  }

  onRecalcUnits (e) {
    if (!this.checkParams()) return false;
    let contractGuid = this.getProviderContract().value;
    let request:RecalcUnitsModelRequest = { contractGuid: contractGuid};
    this.spinnerService.On();
    this.unitMaintenanceService.recalcUnits(request).subscribe({
      next: (response: RecalcUnitsModelResponse) => {
            //console.log(data);
            if ( response.success) {
                this.messageService.add({severity:'success', summary: 'Successful', detail: 'Unit reclaculation complete', life: this.app.messageDisplayTime});
            }
            else {
              this.unitData = [];
              this.messageService.add({ severity: 'warn', summary: 'Request' , detail:  'Unit recalaculation Failed' });
              this.app.userServ.LogError( response.message );
            }
          },
      error: (error) => { 
            let msg = this.getErroMessage(error);
            this.messageService.add({ severity: 'error', summary: 'Unit recalaculation Failed' , detail:  msg });
            this.app.userServ.LogError(msg);
            this.spinnerService.Off();
          },
      complete: () => {this.spinnerService.Off(); }
      });
  }
}
