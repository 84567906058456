<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                    <div class="field col-7">Aids Pharmaceutical Assistance</div>

                    <div class="field col-4">
                    </div>

                    <div class="field col-1">
                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('PHARMA', 'Aids Pharmaceutical Assistance')"></button>      
                    </div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12">
                    <div class="formgroup-inline chamformgroup-inline" style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px; line-height: 20px;">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <label style="font-weight:normal; margin-right: 10px;">Did the client receive local AIDS pharmaceutical Assistance (Not ADAP)?</label>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idPharmaAssistanceScreened" value=2 label="No"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idPharmaAssistanceScreened" value=1 label="Yes"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idPharmaAssistanceScreened" value=3 label="Unknown"></p-radioButton></div>
                        <label id="idPharmaAssistanceResultDate">
                            <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalPharmaAssistance" [formGroup]="medical"
                               [fieldId]="MasterFields.AIDSPharmaceuticalAssistance" [lastUpdated]="this.clientData.clientMedicalPharmaAssistance?.datetime"></app-client-expired-field>
                        </label>                            
                    </div>

                    <div *ngIf="medical.controls['idPharmaAssistanceScreened']?.errors?.required"> 
                        <small id="idPharmaAssistanceScreened-help" class="p-invalid" style="margin-left: 1rem;">Aids Pharmaceutical Assistance is required.</small>
                    </div>
                </div>   
            </div>
        </div>
    </div>
</form>

<app-history ></app-history> 