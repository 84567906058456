<div>
    <b>This client needs to be re-certified due to one or more of  the following reasons:</b> <br><br>
        <ul>
            <li>a) Expired Zipcode
                <fa-icon [icon]="['fas', 'check']" [styles]="{'stroke': 'red', 'color': 'red'}" *ngIf="this.showExpiredZipCodeCheck" ></fa-icon>
            </li>
            <li>b) Expired FPL Data (Family Size/Income) 
                <fa-icon [icon]="['fas', 'check']" [styles]="{'stroke': 'red', 'color': 'red'}" *ngIf="this.showExpiredFPLCheck" ></fa-icon>
            </li>
            <li>c) Expired Medical Insurance                    
                <fa-icon [icon]="['fas', 'check']" [styles]="{'stroke': 'red', 'color': 'red'}" *ngIf="this.showExpiredInsuranceCheck" ></fa-icon>
            </li>
        </ul>

        <br>(Check-mark indicates that the condition applies to this client. )
        <br><br>(Referrals are disable pending certification.)
</div>


