import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCommon, ClientMedicalGonorrheaModel } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields, PosNegResults } from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-gonorrhea',
  templateUrl: './gonorrhea.component.html',
  styleUrls: ['./gonorrhea.component.scss']
})
export class GonorrheaComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  YesnoMedical: CommonData[];
  noReasons: CommonData[];
  MedicalYesNo = MedicalYesNo;
  PosNegResults = PosNegResults;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();

  getGonorrheaScreenedControl = () => this.medical?.get('idGonoScreened');
  getGonorrheaScreenedNoReasonControl = () => this.medical?.get('idGonoScreenedNoReason');
  getGonorrheaResultDateControl = () => this.medical?.get('idGonoScreenedDate');
  getGonorrheaLastResultControl = () => this.medical?.get('idGonoPositive');
  getGonorrheaLastestResultUnavailableControl = () => this.medical?.get('idGonoUnavailable');

  showGonorrheaNoReason = () => (this.getGonorrheaScreenedControl()?.value ?? 0) == this.MedicalYesNo.No; // this.providerService.isFieldRequired(MasterFields.GonorrheaScreenedNoReason) --Den wanted this type of lookup removed 1/6/22
  showGonorrheaResultFields = () => (this.getGonorrheaScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  showGonorrheaUnavailable = () => { 
                                      // the order of the logic must be preserved for accuracy
                                      if (this.clientData?.clientMedicalGonorrhea == null) return true; // if no rows in table for this client then allow Unavailable to show
                                      if (this.clientData?.clientMedicalGonorrhea?.positive) return false;
                                      if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalGonorrhea?.lastUpdate, true), (this.app.requiredFields30DayGracePeriodReset * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again    
                                      if (!this.clientData?.clientMedicalGonorrhea?.unavailable) return false;
                                      if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalGonorrhea?.lastUpdate, true), this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
                                      return false;                                      
                                   }
  isGonorrheaUnavailable = () => (this.getGonorrheaLastestResultUnavailableControl()?.value ?? false);

  constructor(private fb: UntypedFormBuilder, 
              private commonDataService:CommonDataService,
              public providerService: ProviderService,
              private userService:UserService,
              private clientService:ClientFormService,
              private datePipe: DatePipe,
              private dateHelper:DateHelper,
              public  validationHelper:FormValidations,
              private helperService: HelperClassService,
              private medcommon:MedicalCommon,
              private app: AppContextService) { 

  }

  ngOnInit(): void {
    this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
    this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate);
    this.YesnoMedical = this.commonDataService.getCommonSetByName("YesnoMedical", true);
    this.noReasons =  this.YesnoMedical.filter(x => x.id == 0 || x.id > 2);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       // notification for client loading/refreshing
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { this.userService.LogError(error); },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
               
                         });
     }
     if (!this.clientService.readonly) {
        this.validationHelper.AddRequiredMinMaxValidator(this.getGonorrheaScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.GonorrheaScreened))
        this.getGonorrheaScreenedControl().valueChanges.subscribe(value => { this.setScreened();  });
        this.getGonorrheaLastestResultUnavailableControl().valueChanges.subscribe(value => { this.setUnavailable(value); });
     }
  }

  refreshAfterSave() {
    //this.clientData.clientMedicalGonorrhea.lastUpdate = new Date().toISOString();
    this.setFieldValues();
  }  

  setFieldValues() {
    if (!this.clientData) return;

    if (this.clientData.clientMedicalGonorrhea?.positive == PosNegResults.Positive) this.setUnavailable(true);
    
    if (!this.helperService.isExpired(this.clientData?.clientMedicalGonorrhea?.expired)) {
      if (this.clientData?.clientMedicalGonorrhea?.unavailable != null)
        this.getGonorrheaLastestResultUnavailableControl().setValue(this.clientData.clientMedicalGonorrhea?.unavailable);

      if (this.clientData?.clientMedicalGonorrhea?.screened != null)
        this.getGonorrheaScreenedControl().setValue(this.clientData.clientMedicalGonorrhea?.screened);

      if (this.clientData?.clientMedicalGonorrhea?.noReason != null)
        this.getGonorrheaScreenedNoReasonControl().setValue(this.clientData.clientMedicalGonorrhea?.noReason);

      if (this.clientData?.clientMedicalGonorrhea?.datetime != null)
        this.getGonorrheaResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalGonorrhea?.datetime));      
      
      if (this.clientData?.clientMedicalGonorrhea?.positive != null)
        this.getGonorrheaLastResultControl().setValue(this.clientData.clientMedicalGonorrhea?.positive);
    }
  }

  setUnavailable(unavailable) {
    if (unavailable) {
      this.validationHelper.RemoveValidators(this.getGonorrheaScreenedControl(), true);
      this.validationHelper.RemoveValidators(this.getGonorrheaScreenedNoReasonControl(), true);
      this.validationHelper.RemoveValidators(this.getGonorrheaResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getGonorrheaLastResultControl(), true);
    }
    else 
    {
      this.validationHelper.AddRequiredMinMaxValidator(this.getGonorrheaScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.GonorrheaScreened))
      this.setScreened();
    }
  }

  setScreened() {
    if (this.getGonorrheaScreenedControl()?.value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getGonorrheaScreenedNoReasonControl(), true);
      this.validationHelper.AddValidators(this.getGonorrheaResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]); // if (this.providerService.isFieldRequired(MasterFields.GonorrheaResultDate))
      this.validationHelper.AddRequiredMinMaxValidator(this.getGonorrheaLastResultControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.GonorrheaLastResult))
    }
    else if (this.getGonorrheaScreenedControl()?.value == this.MedicalYesNo.No) {
      //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
      this.validationHelper.AddValidators(this.getGonorrheaScreenedNoReasonControl(), this.validationHelper.dropdownValidator()); // if (this.providerService.isFieldRequired(MasterFields.GonorrheaScreenedNoReason))
      this.validationHelper.RemoveValidators(this.getGonorrheaResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getGonorrheaLastResultControl(), true);
      this.getGonorrheaResultDateControl().setValue(null);
    }
  }

	getModel(): Partial<ClientMedicalGonorrheaModel> {
	  var model: Partial<ClientMedicalGonorrheaModel> = new ClientMedicalCommon(this.userService.userID); //this.medical.value

    if (this.getGonorrheaScreenedControl().dirty || this.getGonorrheaLastestResultUnavailableControl().dirty || this.getGonorrheaScreenedNoReasonControl().dirty ||
        this.getGonorrheaResultDateControl().dirty || this.getGonorrheaLastResultControl().dirty)
    {
      let date_screened = (this.isGonorrheaUnavailable() || !this.getGonorrheaResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getGonorrheaResultDateControl().value, { representation: 'date' });

      model.unavailable = this.getGonorrheaLastestResultUnavailableControl()?.value ?? false;      
      model.screened = this.getGonorrheaScreenedControl()?.value;
      model.datetime = date_screened;
      model.positive = this.getGonorrheaLastResultControl()?.value ?? 0;
      model.noReason = this.getGonorrheaScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      // model.guid = ClientFormHelper.EmptyGuid();
      // model.deleted = false;
      // model.userId = this.userService.userID;
      // model.lastUpdate = ClientFormHelper.nullDate();
      // model.expired = null;

      return model;
    } 

    return null;
	}

  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }

}
