<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                    <div class="field col-7">Hepatitis C</div>

                    <div class="field col-4">
                        <div [ngClass]="{'disableDiv': !showHepatitisCUnavailable()}">
                            <p-checkbox value="false" inputId="unavailable" formControlName="idHepCUnavailable"  [binary]="true" ></p-checkbox>   
                            <label style="font-size: 0.875rem; font-weight: normal;" for="unavailable">Unavailable</label> 
                        </div>
                    </div>

                    <div class="field col-1">
                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('HEPC', 'Hepatitis C')"></button>      
                    </div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid" *ngIf="!isHepatitisCUnavailable()">
                <div class="field col-12">
                    <div class="formgroup-inline chamformgroup-inline" style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px; line-height: 20px;">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <label  style="font-weight:normal; margin-right: 10px;">Was the client screened for Hepatitis C?</label>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idHepCScreened" value=2 label="No"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idHepCScreened" value=1 label="Yes"></p-radioButton></div>
                        <label id="idHepatitisCResultDate">
                            <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalHepatitisC && this.clientData.clientMedicalHepatitisC?.positive != PosNegResults.Positive" [formGroup]="medical"
                               [fieldId]="MasterFields.HepatitisCResultDate" [lastUpdated]="this.clientData.clientMedicalHepatitisC?.datetime"></app-client-expired-field>
                        </label>                            
                    </div>
                    <div *ngIf="medical.controls['idHepCScreened']?.errors"> 
                        <small id="idHepCScreened-help" class="p-invalid" style="margin-left: 1rem;">Hepatitis C screening is required.</small>
                    </div>
                </div>   

                <div class="field col-12">
                    <div id="hepCNoDiv" class="fluid formgrid grid" *ngIf="showHepatitisCNoReason()">
                        <div class="field col-12">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idHepCScreenedNoReason" style="margin-right: 1rem;">Reason</label> 
                                <div class="field grid">
                                    <p-dropdown formControlName="idHepCScreenedNoReason" [options]="noReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}"  > </p-dropdown>      
                                </div>
                            </div>
                            <div *ngIf="medical.controls['idHepCScreenedNoReason']?.errors">
                                <small id="idHepCScreenedNoReason-help" class="p-invalid" style="margin-left: 1rem;">Reason is required.</small>
                            </div>
                        </div>
                    </div>
                    
                    <div id="hepCYesDiv" class="fluid formgrid grid" *ngIf="showHepatitisCResultFields()">
                        <div class="field col-6">
                            <div class="formgroup-inline chamformgroup-inline" >
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idHepCScreenedDate" style="margin-right: 1rem;">Date of Result</label> 
                                <input type="text"  #aidsDiagDatePicker  name="idHepCScreenedDate" autocomplete="off" bsDatepicker formControlName="idHepCScreenedDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idHepCScreenedDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idHepCScreenedDate'].invalid}" 
                                    class="champ-date-picker" pInputText/>
                            </div>
                        </div>

                        <div class="field col-6">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label  style="font-weight:normal; margin-right: 10px;">Result:</label>
                                <p-radioButton formControlName="idHepCPositive" value=2 label="Negative" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idHepCPositive" value=1 label="Positive" [style]="{'margin-left':'15px'}"></p-radioButton>
                            </div>
                        </div>
                        
                        <div class="field col-6">
                            <div *ngIf="medical.controls['idHepCScreenedDate']?.invalid">
                                <div *ngIf="medical.controls['idHepCScreenedDate']?.errors?.required"> 
                                    <small id="idHepCScreenedDate-help" class="p-invalid" style="margin-left: 1rem;">Result Date is required.</small>
                                </div>
                                <div *ngIf="!medical.controls['idHepCScreenedDate']?.errors?.required && medical.controls['idHepCScreenedDate']?.errors"> 
                                    <small id="idHepCScreenedDate2-help" class="p-invalid" style="margin-left: 1rem;">{{medical.controls['idHepCScreenedDate']?.errors['invalidDate']}}</small>
                                </div>
                            </div>
                        </div>

                        <div class="field col-6">
                            <div *ngIf="!!medical.controls['idHepCPositive']?.errors"> 
                                <small id="idHepCPositive-help" class="p-invalid" style="margin-left: 1rem;">Result is required.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="fluid formgrid grid" *ngIf="isHepatitisCUnavailable()">
                <small id="idHepatitisCUnavailable-help">Hepatitis C screening was marked unavailable. Screening will be required in 30 days.</small>
            </div>
        </div>
    </div>
</form>

<app-history ></app-history> 
