import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { SpinnerService } from '../common/spinner/spinner.service';
import { ClientReferralsModel, ClientReferralsRequestModel, ReferralStatusTypes, RequestType } from '../models/client_referrals.models';
import { ClientReferralsService } from './client-referrals.service';
import { LockDetails, RecordLockService, RecordTypes } from '../services/record-lock.service';
import { Router } from '@angular/router';
import { ReferralTicketComponent } from './referral-ticket/referral-ticket.component';
import { ClientReferralModel } from '../client-direct-services/services/client-direct-service-models';
import { DropdownChangeEvent } from 'primeng/dropdown';
import { mod } from 'mathjs';

@Component({
  selector: 'app-client-referrals',
  //standalone: false,
  templateUrl: './client-referrals.component.html',
  styleUrls: ['./client-referrals.component.scss']
})

export class ClientReferralsComponent implements OnInit {
  @ViewChild(ReferralTicketComponent) referralTicketComponent: ReferralTicketComponent;

  public fg = new FormGroup({
    referralTypes: new FormControl(0),
    referralStatus: new FormControl(1),
    onlyShowInNetworkReferrals: new FormControl(false),
    referralCreatedReceived: new FormControl(3),
    showDeleted: new FormControl(false)
  })


  referrals: any;
  referralStatus:any 
  referralStatusMaster = this.app.commonServ.getCommonSetByName('ReferralStatus');  
  referralStatusMasterEdit = this.referralStatusMaster.filter(x=>x.id > 0 );
  contractGuid: string = '00000000-0000-0000-0000-000000000000';
  public  get referralStatusTypes() { return ReferralStatusTypes };
  public  get requestType() { return RequestType };
  getClientReferrals = () => this.referrals;
  referralCreatedReceivedList: any[] = [ {id:1,description:'All'}, {id:2,description:'Created'}, {id:3,description:'Received'},  ];


  constructor(private fb: UntypedFormBuilder,
              public app: AppContextService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private spinnerService:SpinnerService,
              private referralsService:ClientReferralsService,
              private reclockService: RecordLockService,
              private router: Router
             )   {

   
  }

  ngOnInit(): void {
    this.app.updateTabTitle('CHAMP - Client Referrals');
    this.referralStatusMaster.unshift({id: 0, description: "All Referral Types", name:""});
    this.loadData();  
  }

  loadData() {
    this.spinnerService.On();
      let model:Partial<ClientReferralsRequestModel> = {};
      Object.assign(model, {});
      model.providerId=this.app.provSvc.providerID;
      model.clinicId = this.app.userServ.CurrentClinicID;
      model.referralStatus = this.fg.value.referralStatus;
      model.onlyShowInNetworkReferrals = this.fg.value.onlyShowInNetworkReferrals;
      model.referralCreatedReceived = this.fg.value.referralCreatedReceived;
      model.requestType = this.requestType.Get;

      this.referralsService.PostReferralRequest(model)
      .subscribe({
        next: (data: ClientReferralsRequestModel) => {
              this.referrals = data.referrals;
            },
        error: (error) => { 
              this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
              this.app.userServ.LogError(error);
            },
        complete: () => { this.spinnerService.Off();}
        });
  }
    
  saveData() {
  }

  openClient(guid:string) {
    this.app.openClient(guid);
  }

  onReferralStatusChange() {
    this.loadData();
  }


  onReferralStatusUpdate(event:DropdownChangeEvent, referral) {

    // this.confirmationService.confirm({
    //   target: event.originalEvent.currentTarget,
    //   message: 'Are you sure you want to change this referral status?',
    //   header: 'Confirm',
    //   icon: 'pi pi-exclamation-triangle',
    //   accept: () => {
    //     try {

    //       //this.isRowInEditMode = this.isRowNew = false;
    //       this.messageService.add({severity:'success', summary: 'Successful', detail: 'Changes saved successfully.', life: this.app.messageDisplayTime});
    //     }
    //     catch (error) {
    //       console.log(error.error.message); console.log(error.error.data);
    //       this.app.userServ.LogError(error.error);
    //       //this.helper.displayError(this.messageService, "Changes failed to save successfully.", error);
    //     }	         
    //   },
    //   reject: () => {
    //     this.messageService.add({severity: "error", summary: "Aborted", detail: "Changes canceled", life: this.app.messageDisplayTime});
    //   }      
    // });

    this.updateReferralStatus(referral, event.value)


  }


  updateReferralStatus(referral:ClientReferralsModel, newStatus){

    this.spinnerService.On();
    let model:Partial<ClientReferralsRequestModel> = {};
    Object.assign(model, {});
    model.guid = referral.guid;
    model.requestType = this.requestType.ChangeStatus;
    model.referralStatus = newStatus;

    this.referralsService.PostReferralRequest(model)
    .subscribe({
      next: (data: ClientReferralsRequestModel) => {
          //console.log(this.app.commonServ.getDescription("ReferralStatus", newStatus));
           referral.referralStatusDesc =  this.app.commonServ.getDescription("ReferralStatus", newStatus)[0];
           this.messageService.add({severity:'success', summary: 'Successful', detail: 'Status change saved successfully.', life: this.app.messageDisplayTime});
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' , life: this.app.messageDisplayTime});
            this.app.userServ.LogError(error);
          },
      complete: () => { this.spinnerService.Off();}
      });
  }

  onCreatedReceivedChange() {
    this.loadData();
  }

  onRenew(status) {
  }

  onOnlyShowInNetworkReferrals() {
    this.loadData();
  }

  onOpenReferralTicket(referral: ClientReferralModel) {
    this.referralTicketComponent.onShow(referral);
  }  
}
