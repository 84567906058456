
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { partition } from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginService } from 'src/app/login/services/login.service';
import { AdminUserModel } from 'src/app/models/admin.models';
import { UserActivityService } from '../user-activity/user-activity.service';
import { UserMaintenanceDetailComponent } from '../user-maintenance-detail/user-maintenance-detail.component';
import { UserMaintenanceService } from './user-maintenance.service';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-maintenance',
  templateUrl: './user-maintenance.component.html',
  styleUrls: ['./user-maintenance.component.scss','../admin-styles.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class UserMaintenanceComponent implements OnInit {
  diagRef: DynamicDialogRef;
  users: Partial<AdminUserModel>[];
  providers: any; //DropDownItem[];  
  //providers: DropDownItem[];
  //users:DropDownItem[];
  //@ViewChild('dtProducts', {read: ElementRef}) dtProducts: ElementRef;
  //@ViewChild('dtProducts') dtProducts: Table;
  //public get ProductEnum(): typeof ProductEnum { return ProductEnum; } // need to do this to expose the enum to the HTML file  
  //params: Partial<ClientGroupProductParams> = {};
  fg: UntypedFormGroup;
  isRowNew: boolean = false;
  isRowInEditMode: boolean = false;
  rowIndexInEditMode = -1;


  getUsers = () => this.users;
  getXMinutes = () => this.fg?.get('lastXMinutes');
  getLastEventOnly = () => this.fg?.get('lastEventOnly');
  getProviderId = () => this.fg?.get('providerId');
  getUserId = () => this.fg?.get('userId');
  

  constructor(private fb: UntypedFormBuilder,
              private authService: AuthService,
              private app:AppContextService,
              private loginService: LoginService,
              private router: Router,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public dialogService: DialogService,
              private spinnerService:SpinnerService,
              //public diagRef: DynamicDialogRef, 
              //public diagConfig: DynamicDialogConfig,
              private userService: UserService,
              public userMaintenanceService: UserMaintenanceService) { 
    // used if calling component used this.router.navigate(['client-products'], { state: { params: someData } })
    // this.params = this.router.getCurrentNavigation().extras.state.params;
    // console.log(this.params);
    //this.params = this.diagConfig.data;

    this.fg = fb.group({
      providerId: [0],
      userId: [],
      lastXMinutes: [480],
      lastEventOnly: [true]
    },
    {
      //validators: [this.customValidator()],
      //asyncValidators: [this.timePeriodValidator()]
      //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
    });    
  }

  ngOnInit(): void {
    // if(!this.authService.isUserAuthenticated){
    //       this.loginService.standAloneLogin().then(x=> this.loadData());
    // }
    // else this.loadData();
    this.app.updateTabTitle('CHAMP - User Maintenance');


    // Feature disabled for now
    // this.userService.GetUserConfigValue('CHAMP.Registry.Admin.CurrentProviderId').subscribe({
    //   next: data => {
    //      this.getProviderId().setValue(data?.keyValue);  
    //      this.loadData(); 
    //  }});

    this.loadData(); 
    
  }

  loadData(){
    this.userService.userInfo$.subscribe({
      next: (data: any) => {
        if (!!data) {
          this.providers = this.userService.getAccessibleProviders().map(provider => { const result: any = {value: `${provider.providerId}`, 
                                                                                                            label: `${provider.providerName}`};
                                                                                      return result; });
          this.providers.unshift({ value: 0, label: 'ALL'});                                                                                      
         if (this.getProviderId().value == null)  this.getProviderId().setValue(this.providers[0].value);
        }
      },
      error: (error) => {
        this.app.userServ.LogError(error);
      },
      complete:() => {
      }         
    });
    
    this.getUsersList();
  }

  onChangeFilter(e, dtUsers) {
    this.getUsersList();

    this.app.userServ.SaveUserConfigValue('CHAMP.Registry.Admin.CurrentProviderId',  this.getProviderId()?.value?.toString());
  }  

  getUsersList( ) {
    var request: any={};
    Object.assign(request, {});
    request.providerId = this.getProviderId()?.value ?? 0;  // all providers, will return all users 
    this.spinnerService.On();
    this.userMaintenanceService.getUsers(request).subscribe({
      next: (data:AdminUserModel[]) => {    //Partial<AuditUserEventsResponseModel>[]
          //console.log(data);
          this.users =  data;
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
          console.log(error);
         },
      complete: () => { 
        this.spinnerService.Off();
      }
      });
  }

  onParamChange(e){
    this.getUsersList();
  }

  onEditUser(e, user: AdminUserModel ){
      this.diagRef = this.dialogService.open(UserMaintenanceDetailComponent, {
        header:  user== null ? 'Add CHAMP User': ' Edit CHAMP User',
        width: '50%',
        //height: '700px',
        contentStyle: {"max-height": "700px", "overflow": "visible"},
        baseZIndex: 10000,
        data: user
      });

      this.app.overLayMangr.add(this.diagRef, 'dialogRef');

      
      this.diagRef.onClose.subscribe(async (data) => {
          this.getUsersList();
          this.app.overLayMangr.remove(this.diagRef);
      });
  }

   onDeleteUser(e, user: AdminUserModel){
      this.confirmationService.confirm({
        target: e.target,
        message: "Are you sure that you want delete this user?",
        icon: "pi pi-info-circle",
        accept: () => {
          try {
            this.userMaintenanceService.deleteUser(user).subscribe({
              next: (response) => {    
                    console.log(response);
                    this.messageService.add({severity:'success', summary: 'Successful', detail: 'User Deleted.', life: this.app.messageDisplayTime});
                },
              error: (error) => { 
                  this.messageService.add({ severity: 'error', summary: error, detail: 'User Delete failed' });
                  console.log(error);
                  },
              complete: () => { 
                this.getUsersList();
              }
              });
          }
          catch (error) {
            console.log(error.error.message); console.log(error.error.data);
            this.app.userServ.LogError(error.error);
            //  this.helper.displayError(this.messageService, "Changes failed to save successfully.", error);
            this.messageService.add({severity: "error", summary: "Failure", detail: "Changes failed to save successfully.", life: this.app.messageDisplayTime});
          }	  
        },
        reject: () => {
          this.messageService.add({severity: "error", summary: "Aborted", detail: "Changes canceled", life: this.app.messageDisplayTime});
        }
      });
   }

   onCloneUser (e, userToClone: AdminUserModel){
      let user:Partial<AdminUserModel> = {};
      Object.assign(user,userToClone );
      user.userId = null;
      user.guid = null;
      user.name = null;
      user.emailAddress = null;
      user.password = null;

      this.diagRef = this.dialogService.open(UserMaintenanceDetailComponent, {
        header:  'Clone CHAMP User',
        width: '50%',
        contentStyle: {"max-height": "600px", "overflow": "auto"},
        baseZIndex: 10000,
        data: user
      });

      this.diagRef.onClose.subscribe(async (data) => {
        this.getUsersList();
    });
   }

}