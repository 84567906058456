import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';


@Component({
  selector: 'app-error',
  templateUrl: './app.error.component.html',
})
export class AppErrorComponent  implements OnInit {

    private errorStr: string ; 


    constructor(private route: ActivatedRoute){


    }



    ngOnInit(): void {
      this.route.params.subscribe(params => { 
            this.errorStr = params['errorStr'];  
            //console.log(params);
            //console.log(this._clientGUID);
          });
    }
}