<div>
        <p-toast key="referral"></p-toast>
        <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]="referralForm"  autocomplete="off"> 
<p-dialog [(visible)]="showReferralEditDialog" header="Referral" (onHide)="onHide()"     
        [style]="{width: '60rem', 'height':'55rem'}" styleClass="p-fluid" [modal]="true" [closable]="true" [keepInViewport]="true" draggable="false">

        <ng-template pTemplate="header"  >
                <span class="p-dialog-title w-30rem"   >Client Referral Add/Edit </span>

                <div class=" w-30rem"  >
                        <span class="mr-2" style="color:white"  >Referral Status:</span>
                        <span style="background-color:yellow; color: red; ">{{referralStatusDescription(this.referralForm.value.status)}}</span>
                </div>
        </ng-template>    

        <ng-template pTemplate="content">
                <div class="card champ-card">
                        <div class="card-body champ-card-body">
                                <div class="p-formgrid pb-1" >
                                        <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                                                <!-- <div class="field col-1">
                                                        <label for="billable" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Billable</label>
                                                        <div class="formgroup-inline">
                                                        <div class="field-checkbox">
                                                                <p-checkbox [style]="{'width': '30px', 'padding': '0px 0px 0px 7px'}" formControlName="billable" binary="true" inputId="billable" ></p-checkbox> 
                                                        </div>
                                                        </div>
                                                </div> -->
                                
                                                <div class="field col-2">
                                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                                        <span style="vertical-align:text-top"><label htmlFor="dateTime" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Referral Date</label></span>
                                                        <div class="formgroup-inline">
                                                                <input type="text" name="referralDateTime" autocomplete="off" bsDatepicker formControlName="referralDateTime"
                                                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="referralDateTime"    
                                                                        placeholder="Referral Date"
                                                                        [ngClass]="{'p-invalid' : referralForm.controls['referralDateTime'].invalid}"
                                                                        class="champ-date-picker" pInputText style="z-index: 1000 !important;"/>
                                                        </div> 
                                                        <!-- <div *ngIf="referralForm.controls['dateTime'].invalid && (referralForm.controls['dateTime'].dirty || referralForm.controls['dateTime'].touched)">
                                                                <div *ngIf="referralForm.controls['dateTime'].errors.required">
                                                                        <small id="dateTime-help" class="p-invalid">*Delivered Date is required.</small>
                                                                </div>
                                                                <div *ngIf="referralForm.controls['dateTime'].errors"> 
                                                                        <small id="dateTime-help" class="p-invalid">{{referralForm.controls['dateTime'].errors['invalidDate']}}</small>
                                                                </div>                             
                                                        </div> -->
                                                </div> 
                        
                                                <div class="field col-10">
                                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                                        <span style="vertical-align:text-top"><label for="funderId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Funder</label></span>
                                                        <p-dropdown formControlName="funderId" [options]="funders" optionLabel="description" optionValue="id" appendTo="body" 
                                                                (onChange)="onChangeFunder($event.value)"></p-dropdown>
                                                                
                                                        <!-- <div *ngIf="!!referralForm.controls['funderId'].errors && !!referralForm.controls['funderId'].errors['invalidFunder']">  -->
                                                        <div *ngIf="!!referralForm.errors?.invalidFunder">                                                                
                                                                <small id="dsFunder-help" class="p-invalid">*Funder is required.</small>
                                                        </div>                         
                                                
                                                </div>

                                                <div class="field col-6">
                                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                                        <span style="vertical-align:text-top"><label for="serviceId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Service Category refererring to</label></span>
                                                        <p-dropdown formControlName="serviceId" [options]="serviceCategories" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeServiceType($event.value)"> </p-dropdown>
                                                       
                                                        <!-- <div *ngIf="!!referralForm.controls['serviceId'].errors && !!referralForm.controls['serviceId'].errors['invalidRequiredField']">  -->

                                                        <div *ngIf="!!referralForm.errors?.invalidServiceId">                                                                     
                                                                <small id="serviceId-help" class="p-invalid">*Service Category is required</small>
                                                        </div>                            
                                                </div>

                                                <div class="field col-6">
                                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                                        <span style="vertical-align:text-top"><label for="subType" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Sub Type</label></span>
                                                        
                                                        <p-dropdown formControlName="subType" [options]="subTypes" optionLabel="description" optionValue="id" appendTo="body" 
                                                                                (onChange)="onChangeReferralSubType($event.value)" > </p-dropdown>
                                                        <!-- <div *ngIf="!!referralForm.controls['subType'].errors && !!referralForm.controls['subType'].errors['invalidRequiredField']">  -->
                                                        <div *ngIf="!!referralForm.errors?.invalidSubId">                                                                    
                                                                <small id="subType-help" class="p-invalid">*Sub Type refererring to is required</small>
                                                        </div>                        
                                                                
                                                </div>

                                                <div class="field col-12">
                                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                                        <span style="vertical-align:text-top"><label for="subType" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Provider</label></span>

                                                        <p-dropdown formControlName="clinicId" [options]="referralProviders" optionLabel="clinicName" optionValue="clinicId" appendTo="body" (onChange)="onChangeReferralProvider($event.value)"> </p-dropdown>
                                                        <div *ngIf="!!referralForm.errors?.invalidProviderId">
                                                                <small id="providerId-help" class="p-invalid">{{referralForm.errors?.invalidProviderId}}</small>
                                                        </div>  
                                                </div>

                                                <div class="field col-11" [ngClass]="{'disableDiv': !outsideProviderEnabled}">
                                                        <span style="vertical-align:text-top"><label for="subType" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Outside Provider :</label></span>

                                                        <div class="grid "> 
                                                                <div class="col-11 "> 
                                                                        <p-dropdown formControlName="outsideProviderId" [options]="outsideProviders" optionLabel="fullName"  
                                                                                optionValue="id" appendTo="body" class="w-30rem mr-0"> </p-dropdown>
                                                                </div>

                                                                <div class="col-1 "> 
                                                                        <p-button  pButton pRipple type="button"   styleClass="p-button-text " class="p-button-text mr-2"
                                                                                 pTooltip="Modify Outside Providers" (click)="onShowOutsideProviderDialog()"  > 
                                                                                <i class="fa-sharp fa-solid fa-ellipsis fa-lg"></i>
                                                                        </p-button>                                                                                               
                                                                </div>
                                                        </div>                                                                                

                                                        <div *ngIf="!!referralForm.errors?.invalidOutsideProviderId">
                                                                <small id="outsideProviderId-help" class="p-invalid">{{referralForm.errors?.invalidOutsideProviderId}}</small>
                                                        </div>  

    
                                                </div>
<!-- 
                                                <div class="field col-4" [ngClass]="{'disableDiv': !outsideProviderEnabled}">  
         
                                                </div> -->

                                                <div class="field col-12" >
                                                        <span style="vertical-align:text-top"><label for="employeeId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Employee</label></span>
                                                        <p-dropdown formControlName="employeeId" [options]="employees" optionLabel="fullName" optionValue="id" appendTo="body"> </p-dropdown>
                                                        <div *ngIf="!!referralForm.errors?.invalidEmployeeId">
                                                                <small id="employeeId-help" class="p-invalid">{{referralForm.errors?.invalidEmployeeId}}</small>
                                                        </div>                            
                                                </div>
                
                                                <div class="field col-12">
                                                        <span style="vertical-align:text-top"><label for="confirmLocation" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Primary Documentation is located at</label></span>
                                                        <textarea formControlName="confirmLocation" rows="2" cols="50" pInputTextarea style="height:42px;"></textarea>
                                                        <div *ngIf="!!referralForm.errors?.invalidconfirmLocation">
                                                                <small id="confirmLocation-help" class="p-invalid">{{referralForm.errors?.invalidconfirmLocation}}</small>
                                                        </div>                            
                                                </div>

                                                <div class="field col-12" >
                                                        <span style="vertical-align:text-top"><label for="memo" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Notes :</label></span>
                                                        <textarea formControlName="memo" rows="2" cols="50" pInputTextarea style="height:60px;"></textarea>
                                                </div>                                                
                                        </div>
                                </div>

                                <div class="flex justify-content-end" >
                                        <div class="flex align-content-end" >
                                            <!-- <p-button  pButton pRipple type="button" [ngStyle]="{'width':'13rem'}"  styleClass="p-button-text " class="p-button-text mr-2"
                                                    (click)="onSaveAndNewReferralChangesConfirm($event)" *ngIf="!clientService.readonly" [disabled]="!referralForm.valid" > 
                                                    <i class="fa-sharp fa-plus fa-lg" ></i> <span>Save & New</span>
                                            </p-button>     -->
                    
                                            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'10rem'}"  styleClass="p-button-text " class="p-button-text mr-2"
                                                    (click)="onSaveReferraChangesConfirm($event)" *ngIf="!clientService.readonly" [disabled]="!referralForm.valid" > 
                                                    <i class="fa-sharp fa-plus fa-lg mr-2"></i> <span>Save</span>
                                            </p-button>       
                    
                    
                                            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'10rem'}"  styleClass="p-button-text " class="p-button-text mr-2"
                                                    (click)="onHideReferralDialog($event)" > 
                                                    <i class="fa-sharp fa-xmark fa-lg mr-2"  style="color: red;" ></i> <span>Cancel </span>
                                            </p-button>    
                                        </div>
                                </div>
                        </div>
                  
                </div>
        </ng-template>
</p-dialog>        


<p-dialog [(visible)]="displayOutsideProviderDialog" id="OutsideProviderModal" header="Outside Provider Information" (onHide)="onHideOutsideProviderDialog()"
        [modal]="true" position="center" [resizable]="false" [draggable]="false" showEffect="explode"> 
        <div  class="fluid formgrid grid">
                <div class="card champ-card">
                        <div class="card-body champ-card-body">
                                <!-- {{this.referralForm.value.outsideProviderName}} -->
                                <div class="field col-12"> <!-- class="formgroup-inline" -->
                                        <div class="field col-5">
                                                <label for="outsideProviderName">Provider Name :</label>
                                                </div>
                                                <div class="field col-12">
                                                        <textarea formControlName="outsideProviderName" rows="1" cols="50" pInputTextarea style="height:30px;"></textarea>
                                                        <div *ngIf="!!referralForm.errors?.invalidOutsideProviderName">
                                                                <small id="outsideProviderName-help" class="p-invalid">{{referralForm.errors?.invalidOutsideProviderName}}</small>
                                                        </div>                            
                                                </div>
                                        </div>
                                <div class="field col-12"> <!-- class="formgroup-inline" -->
                                        <div class="field col-5">
                                                <label for="outsideProviderPhone">Provider Phone :</label>
                                                </div>
                                                <div class="field col-7">
                                                <p-inputMask formControlName="outsideProviderPhone" mask="(999) 999-9999" placeholder="(999) 999-9999"></p-inputMask>
                                        </div>
                                </div>             
                                <div class="field col-12"> <!-- class="formgroup-inline" -->
                                        <div class="field col-12">
                                                <label for="outsideProviderAddress">Provider Address :</label>
                                                </div>
                                                <div class="field col-12">
                                                        <textarea formControlName="outsideProviderAddress" rows="1" cols="50" pInputTextarea style="height:30px;" placeholder="100 MAIN STREET NEWARK NJ 07102"></textarea>     
                                                </div>
                                </div>
                                <div class="fluid formgrid grid">  <!--  *ngIf="this.allowEdit" -->
                                        <div class="field col-12"> 
                                                <button pButton pRipple class="btn btn-primary" (click)="onOutsideProviderSaveChangesConfirm($event)" icon="pi pi-check"   *ngIf="!clientService.readonly"
                                                        label="Save" [disabled]="!!referralForm.errors?.invalidOutsideProviderName" style="width:130px;float: right;"></button>
                                                <!-- &nbsp;&nbsp;&nbsp; -->
                                                <!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="btn" 
                                                        (click)="onHideOutsideProviderDialog()" style="width:130px;float: right;margin-right:10px;"></button>      -->
                                        </div>
                                </div>                    

                        </div>
                </div>  
        </div>
</p-dialog>

</form>



