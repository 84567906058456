<div>
    <!-- <p-toast></p-toast> -->
    <p-confirmPopup></p-confirmPopup>   
</div>

<div class="p-fluid p-formgrid grid " style="margin-bottom: 1rem;" [formGroup]="fg">
        <div class="flex mr-6 champ-page-title">
           Client Referral List
        </div>

        <div class="flex mr-4 " style="min-width: 20rem;"> 
            <div class="col field">
                <label class="control-label mr-2" for="drpContracts">Referral Status</label>
                <p-dropdown  id="referralStatus"  formControlName="referralStatus" [options]="this.referralStatusMaster" optionLabel="description" optionValue="id" 
                    [ngStyle]="{'min-width': '15rem'}"    (onChange)="onReferralStatusChange()" ></p-dropdown>
            </div>                    
        </div>


        <div class="flex mr-8  " style="min-width: 20rem;"> 
            <div class="col field">
                <label class="control-label mr-2" for="drpContracts">Created/Received</label>
                <p-dropdown  id="referralStatus"  formControlName="referralCreatedReceived" [options]="this.referralCreatedReceivedList" optionLabel="description" optionValue="id" 
                    [ngStyle]="{'min-width': '15rem'}"    (onChange)="onCreatedReceivedChange()" ></p-dropdown> 
            </div>                    
        </div>


        <div class="flex mr-4 mt-4" style="min-width: 20rem;"> 
            <div class="col field">
                <span class="flex justify-content-end" style="width: 100%;">
                    <span style="align-content: center; display: grid;">All</span>
                    <div class="pl-3 pr-3">
                        <p-inputSwitch formControlName="onlyShowInNetworkReferrals" (onChange)="onOnlyShowInNetworkReferrals()" ></p-inputSwitch>
                    </div>
                    <span style="align-content: center; display: grid;">In Ryan White Network Only</span>
                </span>    
            </div>                    
        </div>
   
        <!--
         <div class="flex  align-content-end field col-3 ">
            <p-button  pButton pRipple type="button"  [ngStyle]="{'width':'10rem'}"  styleClass="p-button-text " class="p-button-text mr-1 " (click)="loadData()" > 
                <i class="fa-sharp fa-solid fa-refresh fa-lg" ></i>  <span>Refresh</span>
            </p-button>

            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'14rem'}"  styleClass="p-button-text" class="p-button-text mr-1 " (click)="saveData()"  > 
                <i class="fa-sharp  fa-regular  fa-cloud-arrow-up fa-xl "></i> <span>Save Updates</span>
            </p-button>
        </div>  -->
</div>

<div class="container-fluid" id="grid"  > 

    <p-table #dtProviders [value]="getClientReferrals()" [scrollable]="true" scrollDirection="both" scrollHeight="65vh"    [tableStyle]="{ 'max-width': '100%' }">
        styleClass="p-datatable-sm p-datatable-striped" >  <!-- [filters]="filters" -->
        <ng-template pTemplate="header">
            <tr>
                <!-- <th style="width:10rem"></th> -->
                 <th style="width:3rem;"></th>
                <th pSortableColumn="datetime" style="width:3rem;">Referral Date<p-sortIcon field="datetime" ></p-sortIcon></th> 
                <th pSortableColumn="referralStatusDesc" style="width:3rem;">Referral Status<p-sortIcon field="referralStatusDesc" ></p-sortIcon></th>
                <th pSortableColumn="clientAltId" style="width:3rem;">Client Alt ID<p-sortIcon field="clientAltId" ></p-sortIcon></th>
                <th pSortableColumn="sourceProvider" style="width:4rem;">Source Provider<p-sortIcon field="sourceProvider" ></p-sortIcon></th>
                <th pSortableColumn="destinationProvider" style="width:4rem;">Destination Provider<p-sortIcon field="destinationProvider" ></p-sortIcon></th>
                <th pSortableColumn="serviceDesc" style="width:4rem;">Service<p-sortIcon field="serviceDesc" ></p-sortIcon></th>
                <th pSortableColumn="subTypeDesc" style="width:4rem;">Sub Type<p-sortIcon field="subTypeDesc" ></p-sortIcon></th>
                <th style="width:4rem;">Confirmation Location</th>
                <th pSortableColumn="referrerName" style="width:4rem;">Referrer Name<p-sortIcon field="referrerName" ></p-sortIcon></th>
                <th style="width:4rem;">Referrer Phone</th>
                <th style="width:4rem;" >Referrer Email</th>
                <th pSortableColumn="completedDate" style="width:4rem;">Completed Date<p-sortIcon field="completedDate" ></p-sortIcon></th>
                <th style="width:4rem;" >Completed Notes</th>
                <th pSortableColumn="lostToFollowupDate" style="width:4rem;">Lost To Followup Date<p-sortIcon field="lostToFollowupDate" ></p-sortIcon></th>
                <th style="width:4rem;" >Lost To Followup Notes</th>

                <!-- <th pSortableColumn="clientStatusDate" style="width:8rem;">Client Status Date<p-sortIcon field="clientStatusDate" ></p-sortIcon></th>
                <th pSortableColumn="delDate" style="width:8rem;">Delivered Date<p-sortIcon field="delDate" ></p-sortIcon></th>
                <th pSortableColumn="clientStatusName" style="width:15rem;">Client Status<p-sortIcon field="clientStatusName" ></p-sortIcon></th>
                <th  style="width:10rem;">  </th> -->
            </tr>
            
        </ng-template>
        <ng-template pTemplate="body" let-referral let-editing="editing" let-rowIndex="rowIndex">
            <tr  class="cl-row" [ngStyle]="{'color': (referral.referralStatus == referralStatusTypes.Declined) ? 'red' : 
                            referral.referralStatus == referralStatusTypes.Completed ? 'blue':
                            referral.referralStatus == referralStatusTypes.Accepted ? 'green':
                            'black'}"> 
                <td >
                    <button title="ReferralTicket" pButton pRipple class="p-button p-button-text m-0" (click)="onOpenReferralTicket(referral)"  style="width:1rem">
                        <i class="fa-sharp fa-light fa-pencil " style="color: green;"></i>
                    </button>
                </td>                                                      
                <td [ngClass] ="'cl-client-date text-right'" >{{referral?.datetime| date:'shortDate'}}</td>


                @if( this.app.userServ?.hasAdminPermission){
                    <td [pEditableColumn]="referral.referralStatus" pEditableColumnField="referralStatus" >
                        <p-cellEditor>
                                <ng-template pTemplate="input">
                                        <p-dropdown  id="referralStatus"  [(ngModel)]="referral.referralStatus"  [options]="this.referralStatusMasterEdit" 
                                            optionLabel="description" optionValue="id" 
                                            (onChange)="onReferralStatusUpdate($event,referral )" appendTo="body"></p-dropdown> 
                                        <!--[ngStyle]="{'min-width': '15rem'}" [formControlName]="referral.referralStatus" -->
                                </ng-template>
                                <ng-template pTemplate="output">
                                    {{ referral?.referralStatusDesc }}
                                </ng-template>
                        </p-cellEditor>
                    </td>   
                }
                @else {
                    <td>{{ referral?.referralStatusDesc }}</td>                   
                }

                <!-- <td > {{ referral?.clientAltId }} </td> -->
                <td ><a [ngClass]="'clientID'" (click)="openClient(referral.clientGuid)"> {{referral?.clientAltId}}</a></td> 
                <td class="text-overflow-ellipsis"> {{ referral?.sourceProvider }} </td>
                <td class="text-overflow-ellipsis"> {{ referral?.destinationProvider }} </td>
                <td class="text-overflow-ellipsis"> {{ referral?.serviceDesc }} </td>
                <td class="text-overflow-ellipsis"> {{ referral?.subTypeDesc }} </td>
                <td class="text-overflow-ellipsis"> {{ referral?.confirmLocation }} </td>
                <td class="text-overflow-ellipsis"> {{ referral?.referrerName }} </td>
                <td > {{ referral?.referrerPhone }} </td>
                <td class="text-overflow-ellipsis"> {{ referral?.referrerEmail }} </td> 
                <td > {{ referral?.completedDate }} </td>
                <td class="text-overflow-ellipsis"> {{ referral?.completedNotes }} </td>
                <td > {{ referral?.lostToFollowupDate }} </td>
                <td class="text-overflow-ellipsis"> {{ referral?.lostToFollowupNote }} </td>
                <!-- <td [ngClass] ="'cl-client-date text-right'" >{{status?.clientStatusDate| date:'shortDate'}}</td> 
                <td [ngClass] ="'cl-client-date text-right'" >{{status?.delDate| date:'shortDate'}}</td> 
                <td [ngClass] ="" >
                        <div  class=" mr-2"   [ngStyle]="{'width': '15rem'}" >  
                            <p-dropdown id="clientStatus"  optionLabel="description" optionValue="id" appendTo="body"  [(ngModel)]="status.clientStatusId"
                                    [options]="this.clientStatusMaster()"  autowidth="false"  (onChange)="onClientStatusChange($event, status)"   
                                                [style]="{'width': '100%', 'min-width':'8rem'}" >
                            </p-dropdown>           
                            <span (click)="onRenew(status)" >
                                <fa-icon class='fab fa-angular fa-lg' [icon]="['fas', 'redo']"  [styles]="{'stroke': 'black', 'color': 'black', 'padding-left':'.5rem'}"
                                    pTooltip="Renew as Active" ></fa-icon>
                            </span>                                    
                    
                        </div>     
                
                </td> 
                <td >
                    <div class="text-green-500 text-left" *ngIf="status.clientStatusId != status.newClientStatusId || status.renew"  [ngStyle]="{'width': '5rem'}">
                        Status Updated!  
                    </div>
                </td> -->

            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr >
                <td colspan="15">Count: {{referrals?.length}}</td>
            </tr>
        </ng-template>            
    </p-table>  
    
    
    
</div> 

<div class="flex mt-2" [formGroup]="fg">
    <div class="flex mr-6 " style="min-width: 20rem;"> 
        <div class="flex  align-self-end"><div style="color: green;">Green</div><div class="mr-4"> - Accepted Referrals</div></div>
        <div class="flex  align-self-end"><div style="color: red;">Red</div><div class="mr-4"> - Declined Referrals</div></div>
        <div class="flex  align-self-end"><div style="color: blue;">Blue</div><div class="mr-4"> - Completed Referrals</div></div>
    </div>

    <!--
     <div class="flex  align-content-end field col-3 ">
        <p-button  pButton pRipple type="button"  [ngStyle]="{'width':'10rem'}"  styleClass="p-button-text " class="p-button-text mr-1 " (click)="loadData()" > 
            <i class="fa-sharp fa-solid fa-refresh fa-lg" ></i>  <span>Refresh</span>
        </p-button>

        <p-button  pButton pRipple type="button" [ngStyle]="{'width':'14rem'}"  styleClass="p-button-text" class="p-button-text mr-1 " (click)="saveData()"  > 
            <i class="fa-sharp  fa-regular  fa-cloud-arrow-up fa-xl "></i> <span>Save Updates</span>
        </p-button>
    </div>  -->
</div>
<!-- </form> -->
 
<app-referral-ticket (onRefresh)="loadData()"></app-referral-ticket>