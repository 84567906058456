import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCommon, ClientMedicalTuberculosisModel } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields, PosNegResults} from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-tuberculosis',
  templateUrl: './tuberculosis.component.html',
  styleUrls: ['./tuberculosis.component.scss']
})
export class TuberculosisComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  YesnoMedical: CommonData[];
  noReasons: CommonData[];
  testTypes: CommonData[];
  MedicalYesNo = MedicalYesNo;
  PosNegResults = PosNegResults;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();

  getTuberculosisScreenedControl = () => this.medical?.get('idTBScreened');
  getTuberculosisScreenedNoReasonControl = () => this.medical?.get('idTBScreenedNoReason');
  getTuberculosisResultDateControl = () => this.medical?.get('idTBScreenedDate');
  getTuberculosisLastResultControl = () => this.medical?.get('idTBPositive');
  getTuberculosisLastestResultUnavailableControl = () => this.medical?.get('idTBUnavailable');
  getTuberculosisTestTypeControl = () => this.medical?.get('idTBTestType');

  showTuberculosisNoReason = () => (this.getTuberculosisScreenedControl()?.value ?? 0) == this.MedicalYesNo.No; // this.providerService.isFieldRequired(MasterFields.TuberculosisScreenedNoReason) --Den wanted this type of lookup removed 1/6/22
  showTuberculosisResultFields = () => (this.getTuberculosisScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  showTuberculosisUnavailable = () => { 
                                        // //if (this.clientService.newclient || this.clientData?.clientMedicalTuberculosis == null) return true; // if no rows in table for this client then allow Unavailable to show    
                                        // //if (addDays(this.clientService.clientData.newClientDate, this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
                                        // if (this.clientData?.clientMedicalTuberculosis == null) return true; // if no rows in table for this client then allow Unavailable to show
                                        // if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalTuberculosis?.lastUpdate, true), this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
                                        // if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalTuberculosis?.lastUpdate, true), (this.app.requiredFields30DayGracePeriodReset * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again
                                        // return false;
                                        // the order of the logic must be preserved for accuracy
                                        if (this.clientData?.clientMedicalTuberculosis == null) return true; // if no rows in table for this client then allow Unavailable to show
                                        if (this.clientData?.clientMedicalTuberculosis?.positive) return false;
                                        if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalTuberculosis?.lastUpdate, true), (this.app.requiredFields30DayGracePeriodReset * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again    
                                        if (!this.clientData?.clientMedicalTuberculosis?.unavailable) return false;
                                        if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalTuberculosis?.lastUpdate, true), this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
                                        return false;                                        
                                      }
  isTuberculosisUnavailable = () => (this.getTuberculosisLastestResultUnavailableControl()?.value ?? false);
  //isTuberculosisLastResultValid = () => ([ 1, 2, 3 ].includes(this.getTuberculosisLastResultControl()?.value ?? -1));

  constructor(private fb: UntypedFormBuilder, 
              private commonDataService: CommonDataService,
              public providerService: ProviderService,
              private userService: UserService,
              private clientService: ClientFormService,
              private datePipe: DatePipe,
              private dateHelper: DateHelper,
              public  validationHelper: FormValidations,
              private helperService: HelperClassService,
              private medcommon: MedicalCommon,
              private app: AppContextService) { 
  }

  ngOnInit(): void {
    this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
    this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate);
    this.YesnoMedical = this.commonDataService.getCommonSetByName("YesnoMedical", true);
    this.noReasons =  this.YesnoMedical.filter(x => x.id == 0 || x.id > 2);
    this.testTypes = this.commonDataService.getCommonSetByName("TbTestType", true);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
                         });
     }
     if (!this.clientService.readonly) {
      this.validationHelper.AddRequiredMinMaxValidator(this.getTuberculosisScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.TuberculosisScreened))
      this.getTuberculosisScreenedControl().valueChanges.subscribe(value => { this.setScreened();  });
      this.getTuberculosisLastestResultUnavailableControl().valueChanges.subscribe(value => { this.setUnavailable(value); });   
     }
  }

  refreshAfterSave() {
    this.setFieldValues();
  }  

  setFieldValues() {
    if (!this.clientData) return;
    
    if (this.clientData.clientMedicalTuberculosis?.positive == PosNegResults.Positive) this.setUnavailable(true);

    if (!this.helperService.isExpired(this.clientData?.clientMedicalTuberculosis?.expired)) {
      if (this.clientData.clientMedicalTuberculosis?.unavailable != null)
        this.getTuberculosisLastestResultUnavailableControl().setValue(this.clientData.clientMedicalTuberculosis?.unavailable);

      if (this.clientData.clientMedicalTuberculosis?.screened != null)
        this.getTuberculosisScreenedControl().setValue(this.clientData.clientMedicalTuberculosis?.screened);

      if (this.clientData.clientMedicalTuberculosis?.noReason != null)
        this.getTuberculosisScreenedNoReasonControl().setValue(this.clientData.clientMedicalTuberculosis?.noReason);

      if (this.clientData.clientMedicalTuberculosis?.datetime != null)
        this.getTuberculosisResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalTuberculosis?.datetime));      
      
      if (this.clientData.clientMedicalTuberculosis?.positive != null)
        this.getTuberculosisLastResultControl().setValue(this.clientData.clientMedicalTuberculosis?.positive);

      if (this.clientData.clientMedicalTuberculosis?.testType != null)
        this.getTuberculosisTestTypeControl().setValue(this.clientData.clientMedicalTuberculosis?.testType);
    }
  }

  setUnavailable(unavailable) {
    //let unavailable = this.medical.get('idHepBUnavailable').value;

    if (unavailable) {
      this.validationHelper.RemoveValidators(this.getTuberculosisScreenedControl(), true);
      this.validationHelper.RemoveValidators(this.getTuberculosisScreenedNoReasonControl(), true);
      this.validationHelper.RemoveValidators(this.getTuberculosisResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getTuberculosisLastResultControl(), true);
      this.validationHelper.RemoveValidators(this.getTuberculosisTestTypeControl(), true);
    }
    else 
    {
      this.validationHelper.AddRequiredMinMaxValidator(this.getTuberculosisScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.TuberculosisScreened))
      this.setScreened();
    }
  }

  setScreened() {
    if (this.getTuberculosisScreenedControl()?.value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getTuberculosisScreenedNoReasonControl(), true);
      this.validationHelper.AddValidators(this.getTuberculosisResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]); // if (this.providerService.isFieldRequired(MasterFields.TuberculosisResultDate))
      this.validationHelper.AddRequiredMinMaxValidator(this.getTuberculosisLastResultControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.TuberculosisLastResult))
      this.validationHelper.AddValidators(this.getTuberculosisTestTypeControl(), this.validationHelper.dropdownValidator());
    }
    else if (this.getTuberculosisScreenedControl()?.value == this.MedicalYesNo.No) {
      //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
      this.validationHelper.AddValidators(this.getTuberculosisScreenedNoReasonControl(), this.validationHelper.dropdownValidator()); // if (this.providerService.isFieldRequired(MasterFields.TuberculosisScreenedNoReason))
      this.validationHelper.RemoveValidators(this.getTuberculosisResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getTuberculosisLastResultControl(), true);
      this.validationHelper.RemoveValidators(this.getTuberculosisTestTypeControl(), true);
      this.getTuberculosisResultDateControl().setValue(null);
    }
  }

	getModel(): Partial<ClientMedicalTuberculosisModel> {
	  var model: Partial<ClientMedicalTuberculosisModel> = new ClientMedicalCommon(this.userService.userID); //this.medical.value

    if (this.getTuberculosisScreenedControl().dirty || this.getTuberculosisLastestResultUnavailableControl().dirty || this.getTuberculosisScreenedNoReasonControl().dirty ||
        this.getTuberculosisResultDateControl().dirty || this.getTuberculosisLastResultControl().dirty || this.getTuberculosisTestTypeControl().dirty)
    {
      let date_screened = (this.isTuberculosisUnavailable() || !this.getTuberculosisResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getTuberculosisResultDateControl().value, { representation: 'date' });

      model.screened = this.getTuberculosisScreenedControl()?.value;
      model.datetime = date_screened;
      model.positive = this.getTuberculosisLastResultControl()?.value ?? 0;
      model.unavailable = this.getTuberculosisLastestResultUnavailableControl()?.value ?? false;
      model.noReason = this.getTuberculosisScreenedNoReasonControl()?.value ?? 0;
      model.testType = this.getTuberculosisTestTypeControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      // model.guid = ClientFormHelper.EmptyGuid();
      // model.deleted = false;
      // model.userId = this.userService.userID;
      // model.lastUpdate = ClientFormHelper.nullDate();
      // model.expired = null;

      return model;
    } 

    return null;
	}  

  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }  

}
