import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCommon, ClientMedicalAntiretroviralTherapyModel } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields } from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';

@Component({
  selector: 'app-antiretroviral-therapies',
  templateUrl: './antiretroviral-therapies.component.html',
  styleUrls: ['./antiretroviral-therapies.component.scss']
})
export class AntiretroviralTherapiesComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  noReasons: CommonData[];
  MedicalYesNo = MedicalYesNo;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();

  getAntiretroviralTherapyScreenedControl = () => this.medical?.get('idARTScreened');

  getAntiretroviralTherapyScreenedNoReasonControl = () => this.medical?.get('idARTScreenedNoReason');
  getAntiretroviralTherapyResultDateControl = () => this.medical?.get('idARTScreenedDate');
  
  showAntiretroviralTherapyNoReason = () => (this.getAntiretroviralTherapyScreenedControl()?.value ?? 0) == this.MedicalYesNo.No; // this.providerService.isFieldRequired(MasterFields.AntiretroviralTherapyScreenedNoReason) --Den wanted this type of lookup removed 1/6/22
  showAntiretroviralTherapyResultFields = () => (this.getAntiretroviralTherapyScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  showAntiretroviralTherapyUnavailable = () => { 
                                    if (this.clientData?.clientMedicalAntiretroviralTherapy == null) return true; // if no rows in table for this client then allow Unavailable to show
                                    if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalAntiretroviralTherapy?.lastUpdate, true), (365 * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again
                                    return false;
                                  }

  constructor(private fb: UntypedFormBuilder, 
              private commonDataService:CommonDataService,
              public providerService: ProviderService,
              private userService:UserService,
              private clientService:ClientFormService,
              private datePipe: DatePipe,
              private dateHelper:DateHelper,
              public  validationHelper:FormValidations,
              private helperService: HelperClassService,
              private medcommon:MedicalCommon) { 

  }

  ngOnInit(): void {
    this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
    this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate);
    this.noReasons = this.commonDataService.getCommonSetByName("AntiretroviralNoReason", true);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       // notification for client loading/refreshing
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { this.userService.LogError(error); },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
                         });
     }
     if (!this.clientService.readonly) {
      this.validationHelper.AddRequiredMinMaxValidator(this.getAntiretroviralTherapyScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.AntiretroviralTherapyScreened))
      this.getAntiretroviralTherapyScreenedControl().valueChanges.subscribe(value => { this.setScreened();  });
     }
  }

  refreshAfterSave() {
    this.setFieldValues();
  }  

  setFieldValues() {
    if (!this.clientData) return;
    
    if (!this.helperService.isExpired(this.clientData?.clientMedicalAntiretroviralTherapy?.expired)) {
      if (this.clientData?.clientMedicalAntiretroviralTherapy?.prescribed != null)
        this.getAntiretroviralTherapyScreenedControl()?.setValue(this.clientData.clientMedicalAntiretroviralTherapy?.prescribed);

      if (this.clientData?.clientMedicalAntiretroviralTherapy?.reason != null)
        this.getAntiretroviralTherapyScreenedNoReasonControl()?.setValue(this.clientData.clientMedicalAntiretroviralTherapy?.reason);

      if (this.clientData?.clientMedicalAntiretroviralTherapy?.prescribedDate != null)
        this.getAntiretroviralTherapyResultDateControl()?.setValue(parseISO(this.clientData.clientMedicalAntiretroviralTherapy?.prescribedDate));      
    }
  }

  setScreened() {
    if (this.getAntiretroviralTherapyScreenedControl()?.value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getAntiretroviralTherapyScreenedNoReasonControl(), true);
      this.validationHelper.AddValidators(this.getAntiretroviralTherapyResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]); // if (this.providerService.isFieldRequired(MasterFields.AntiretroviralTherapyResultDate))
    }
    else if (this.getAntiretroviralTherapyScreenedControl()?.value == this.MedicalYesNo.No) {
      //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
      this.validationHelper.AddValidators(this.getAntiretroviralTherapyScreenedNoReasonControl(), this.validationHelper.dropdownValidator()); // if (this.providerService.isFieldRequired(MasterFields.AntiretroviralTherapyScreenedNoReason))
      this.validationHelper.RemoveValidators(this.getAntiretroviralTherapyResultDateControl(), true);
      this.getAntiretroviralTherapyResultDateControl().setValue(null);
    }
  }

	getModel(): Partial<ClientMedicalAntiretroviralTherapyModel> {
	  var model: Partial<ClientMedicalAntiretroviralTherapyModel> = new ClientMedicalCommon(this.userService.userID); //new ClientMedicalCommon(this.medical.value)

    if (this.getAntiretroviralTherapyScreenedControl().dirty || this.getAntiretroviralTherapyScreenedNoReasonControl().dirty ||
        this.getAntiretroviralTherapyResultDateControl().dirty)
    {
      let date_screened = (!this.getAntiretroviralTherapyResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getAntiretroviralTherapyResultDateControl().value, { representation: 'date' });

      model.prescribed = model.id = this.getAntiretroviralTherapyScreenedControl()?.value ?? 0;
      model.prescribedDate = model.datetime = date_screened;
      model.reason = this.getAntiretroviralTherapyScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      //model.guid = ClientFormHelper.EmptyGuid();
      //model.deleted = false;
      //model.userId = this.userService.userID;
      //model.lastUpdate = ClientFormHelper.nullDate();
      //model.expired = null;

      return model;
    } 

    return null;
	}

  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }

}
