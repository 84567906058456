import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { addMinutes, addSeconds } from 'date-fns';
import { Subscription, interval, BehaviorSubject, Observable, Subject, timer } from 'rxjs';
import { repeatWhen, switchMap, takeUntil } from 'rxjs/operators';
import { CounntdownTimerService } from './counntdown-timer.service'

@Component({
  selector: 'app-countdown-timer',
  templateUrl: './countdown-timer.component.html',
  styleUrls: ['./countdown-timer.component.scss']
})
export class CountdownTimerComponent implements OnInit {

  @Input() minutes: number = 0;
  @Input() seconds: number = 0;
  @Input() showMinutes: boolean = true;
  @Input() showSeconds: boolean = true;
  @Input() title: string = "";

  private subscription: Subscription;
  
  private readonly _stop = new Subject<void>();
  private readonly _start = new Subject<void>();

  public dateNow = new Date();
  public dDay = new Date('Jan 01 2021 00:00:00');
  milliSecondsInASecond = 1000;
  hoursInADay = 24;
  minutesInAnHour = 60;
  SecondsInAMinute  = 60;
  timerRunning = false;
  //test = true; 
  //countdownMinutes = 1;
  //private eta = addMinutes(new Date(), this.countdownMinutes); 
  private eta : Date;
  public timeDifference;
  public secondsToDday;
  public minutesToDday;
  public hoursToDday;
  public daysToDday;

  private timerSubject = new BehaviorSubject<boolean>(false);
  public timerCompleted$: Observable<boolean> = this.timerSubject.asObservable();

  private name = 'Angular 6';
  private timeLeft: number = 60;
  private interval;
  private subscribeTimer: any;

  constructor(private timerService:CounntdownTimerService) {
   // this.stopTimer();
  }

  private calculateETA(){
    this.eta = new Date();
    this.eta = addMinutes(new Date(), this.minutes ?? 0); 
    this.eta = addSeconds(this.eta, this.seconds ?? 0); 
    this.getTimeDifference();

  }

  private getTimeDifference () {
        this.timeDifference = this.eta.getTime() - new  Date().getTime();
        this.allocateTimeUnits(this.timeDifference);
    }

  private allocateTimeUnits (timeDifference) {
        this.secondsToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond) % this.SecondsInAMinute);
        this.minutesToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour) % this.SecondsInAMinute);
        //console.log(this.minutesToDday, this.secondsToDday);
        //this.hoursToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute) % this.hoursInADay);
        //this.daysToDday = Math.floor((timeDifference) / (this.milliSecondsInASecond * this.minutesInAnHour * this.SecondsInAMinute * this.hoursInADay));
  }

  ngOnInit() {
     //this._stop.next();
     this.calculateETA();
     this.subscription = interval(1000).pipe(takeUntil(this._stop), repeatWhen(()=> this._start))
         .subscribe(x => { 
          this.getTimeDifference();
          //console.log(this.secondsToDday, this.minutesToDday);
          if (this.secondsToDday <= 0 &&  this.minutesToDday <=0)
          {
            this.stopTimer();
            //this.timerService.setTimerAsExpired();
            //this.timerService.next();
            this.timerSubject.next(true);
          }

        });

    //this.resetTimer();

    this.calculateETA();
    // //this.startTimer();

  //   this.subscription = interval(1000).subscribe(x => { 
  //     this.getTimeDifference();
  //     //console.log(this.secondsToDday, this.minutesToDday);
  //     if (this.secondsToDday <= 0 &&  this.minutesToDday <=0)
  //     {
  //       //this.stopTimer();
  //       this.timerSubject.next(true);
  //       this.subscription.unsubscribe();
  //     }
  // });
   
  }
 ngOnDestroy() {
    this.subscription.unsubscribe();
 }

 resetTimer () {
    //this.stopTimer();

    // this.subscription.unsubscribe();
    //console.log(this.eta);
    this.calculateETA();
    this.startTimer();
    //console.log(this.eta);


    // this.subscription = interval(1000).subscribe(x => { 
    //   this.getTimeDifference();
    //   console.log(this.secondsToDday, this.minutesToDday);
    //   if (this.secondsToDday <= 0 &&  this.minutesToDday <=0)
    //   {
    //     //this.stopTimer();
    //     this.timerSubject.next(true);
    //     this.subscription.unsubscribe();
        
    //   }
    // });
 }


 startTimer (){
      this._start.next();
 }
 
 
 stopTimer(){
      //this.subscription.unsubscribe();

      this._stop.next();
     //This.subscription!!.unsubscribe();
 }

//  oberserableTimer() {
//   const source = timer(1000, 2000);
//   const abc = source.subscribe(val => {
//     console.log(val, '-');
//     this.subscribeTimer = this.timeLeft - val;
//   });
// }

//   startTimer() {
//     this.interval = setInterval(() => {
//       if(this.timeLeft > 0) {
//         this.timeLeft--;
//       } else {
//         this.timeLeft = 60;
//       }
//     },1000);
//   }

//   pauseTimer() {
//     clearInterval(this.interval);
//   }

}
