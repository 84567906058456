import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoginService } from '.././login/services/login.service';
import { CommonDataService } from '../services/common-data.service';
import { LockDetails, RecordLockService, RecordTypes } from '../services/record-lock.service';
import { ClientListResponse, PrimaryMedicalInfo, PrimaryMedicalTests, PrimaryMedicalVists, clientListRequest } from './services/client-list-models';
import { ClientListService } from './services/client-list.service';
//import { faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faNotesMedical } from '@fortawesome/free-solid-svg-icons';
import { SpinnerService } from '../common/spinner/spinner.service';
import { UserService } from '../services/user.service';

import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { ClientEligibilityComponent } from '../client-form/client-eligibility/client-eligibility.component';
import { AppContextService } from '../app-context/app-context.service';
import { Table } from 'primeng/table';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss'],
  providers: [MessageService, DialogService]
  //providers: [ SpinnerService ]  // Spinner, USer, Common Data, Provider
})

export class ClientListComponent implements OnInit, OnDestroy {
  @ViewChild('AlphaButtons') alphaButtons: ElementRef;
  @ViewChild('MostRecentClients') mostRecentClients: ElementRef;
  @ViewChild('clientList') clientListTable: Table;
  @ViewChild('paginator', { static: true }) paginator?: Paginator;

  env = environment;

  // pagination 
  currentPage = 0;
  rows = 18;


  _provider: number = 1;
  _clinic: number = 1;
  _clientStatus: number = 0;
  _contractGuid: string = '0';
  _user: string = null;
  _alpha: string = '*'; 
  _clientID: string = null;
  _showDetailedView: boolean = true;
  _mostRecentClients: boolean = false;
 
  _clientList: ClientListResponse[];
  _primaryMedicalVisits: PrimaryMedicalVists[];
  _primaryMedicalTests: PrimaryMedicalTests[];
  _currentClientAltID: string; 
  _clientStatusList: any[];
  _contracts: any[];
  _users: any[];
  _selectedStatus: string;
  div_visible: boolean = false; 
  facheckIcon = faCheck;
  facheckMedicalNotes = faNotesMedical;
  _medicalVisitsDialog: boolean = false;

  adminFeatures:boolean = false;
  adminFeatureLabel:string  = "Show Admin Features";
  showDeleted = false;
  deletedLabel:string  = "Show Deleted";
  returnMostRecent = false; 

  diagRef: DynamicDialogRef;

  constructor(
     private clientListService:ClientListService,
     public app: AppContextService,
     private route: ActivatedRoute,
     private router: Router,
     private commonDataService: CommonDataService,
     private spinnerService: SpinnerService,
     public userService: UserService,
     private loginService: LoginService,
     private confirmationService: ConfirmationService,
     private messageService: MessageService,
     private primengConfig: PrimeNGConfig,
     private reclockService: RecordLockService,
     public dialogService: DialogService,
     private authService: AuthService,
     ) { 

     this.app.updateTabTitle('CHAMP - Client List');
    this.initializeData();

  }

  ngOnInit(): void {   
    //console.log("here");


  }

  ngOnDestroy() {
    if (this.diagRef) {
        this.diagRef.close();
    }
  }  

  showClientEligibility() {
    this.diagRef = this.dialogService.open(ClientEligibilityComponent, {
        header: 'Client Eligibility',
        width: '20%',
        contentStyle: {"max-height": "600px", "overflow": "visible"}
        //baseZIndex: 10000
    });

    const self = this;
    this.diagRef.onClose.subscribe((data) => {
      console.log(data?.eligibility);
      let route = '../client-form';
      if (!!data?.eligibility) {
        try {
          self.router.navigate([route], { state: { eligibility: data?.eligibility } });
        }
        catch (e) {
          console.log(e)
        }
      }
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //this.router.onSameUrlNavigation = 'reload';      
    });
  }

  public initializeData(){
    this._provider = this.userService.CurrentProviderID;
    this.clientListService.getDefaults(this._provider).subscribe(
      {   next: (data: any) => {
           this._contracts = data.contracts;
           this._users = data.users;
           this._clientStatus = 0;
           this._contractGuid = "*";
           this._user = "0";
           this._showDetailedView = true;
           this._clientStatusList = this.commonDataService.getCommonSetByName("ClientStatus");
           this._clientStatusList.push( { name:"ClientStatus", id:0, description:"ALL STATUSES"  });

          },
          error: (e) => {console.log(e);},
          complete:() => {
            this.restorePreviousSearch();
          }
         
    });
  }

  public restorePreviousSearch () {
    ///return new Observable ( observer => {
        forkJoin({
                clientID: this.userService.GetUserConfigValue('CHAMP.Registry.Filters.LastClientID'),
                clientStatus: this.userService.GetUserConfigValue('CHAMP.Registry.Filters.LastClientStatusID'),
                contract: this.userService.GetUserConfigValue('CHAMP.Registry.Filters.LastContractID'),
                mostRecentClients: this.userService.GetUserConfigValue('CHAMP.Registry.Filters.LastMostRecentClientsValue')

                // Add Code here to make sure at least values are selected for search and two of the 3, except when 
                // Last Client ID is more the 2 characters
                // provider: this.userService.GetUserConfigValue(CHAMP.Registry.ProviderID')
              }).subscribe
              ( {
                  next: ({clientID, clientStatus, contract, mostRecentClients}) => {
                          if ( clientID.keyValue.length > 1 ) {
                            this._clientID = clientID.keyValue;
                          }
                          else {
                            this._alpha = clientID.keyValue;
                            this.alphaButtons.nativeElement.childNodes.forEach(element => {
                              if (element.textContent == (this._alpha == '*' ? 'ALL' : this._alpha)) element.focus();
                            });                            
                          }
                          this._clientStatus = parseInt (clientStatus.keyValue);
                          this._contractGuid = contract.keyValue;
                          this.returnMostRecent = (mostRecentClients.keyValue == 'true');
                          if (this._mostRecentClients) this.mostRecentClients?.nativeElement?.focus();
                          //this._provider = parseInt(provider.keyValue);
                    },
                  error: (e) => "Client List: Get Previous Search forkJoin err" + console.log(e) ,
                  complete: () => {
                      //console.log("Client List: Get Previous Search forkJoin completed " );
                      this.applyFiltersClick();
                    }
              } );
  }

  applyAlpha(value){  
    this.returnMostRecent = false; 
    this._alpha = value;
    this._clientID = '';
    this.applyFiltersClick();
    this.userService.SaveUserConfigValue('CHAMP.Registry.Filters.LastClientID', this._alpha);
  }

  toggleDetailView(){
    this._showDetailedView = !this._showDetailedView;
    this.applyFiltersClick();
  }

  applyFiltersClick() {
      var searchString: string  = "";

      this.userService.SaveUserConfigValue('CHAMP.Registry.Filters.LastMostRecentClientsValue', this.returnMostRecent.toString());
      this._mostRecentClients = this.returnMostRecent;

      if ( this._clientID?.length > 0 )
      {
        searchString = this._clientID;
      }
      else {
        searchString = this._alpha;
      }

      var missing_param_cnt = 0;
      if  ( this._clientStatus == null || this._clientStatus  == 0 ) missing_param_cnt += 1;
      if  ( this._contractGuid == null || this._contractGuid  == '*' ) missing_param_cnt += 1;
      if  ( this._alpha == null || this._alpha  == '*' ) missing_param_cnt += 1;

      if ( missing_param_cnt > 1 && this._clientID?.length < 3) {
            this.messageService.add({severity:'warn', summary: 'Search Warning', 
              detail: 'At least two search paramaters must be specified. Please select at least 2 of the 3 search criteria. ( Client Status, Contract and/or alpha) or at least 3 letters in the client ID.  ',  life: 5000});
      }
      else {
            var request: clientListRequest =
            {  providerID: this._provider, 
                clientStatus : + this._clientStatus, 
                searchChar: searchString, 
                user: this.userService.userID, //parseInt(this._user)
                contractGuid: this._contractGuid,
                showDetailedView: this._showDetailedView,
                clinicId: this.userService.CurrentClinicID,
                returnMostRecent: this.returnMostRecent,
                showDeleted:this.showDeleted
            };
          
            //this.clientListService.getClientListWithParams(this._provider.toString(), this._clientStatus, this._alpha, this._user, this._contractGuid).subscribe((data: any[]) => {
            //   const loadClients$ = this.clientListService.getClientListWithParams(request).subscribe(  (data: any[]) => { this._clientList = data;  },
            //     error => {console.log(error);},  
            //     () => {    }    //this.spinnerService.loadingOff();
            //  );
            
            const loadClients$ = this.clientListService.getClientListWithParams(request)
              .pipe(
                  map( (response :any[]) => {

                       //this.clientListReference.resetPageOnSort = true;
                       //this.clientListReference.resetScrollTop();
                       //this.paginator.changePageToFirst();
                       //this.clientListTable?.reset();
                       this.reset();
                       this._clientList = response;
                    
                      }),
                );
            
            this.spinnerService.showSpinnerUntilCompleted(loadClients$).subscribe();
      }
  }

  resetFiltersClick(){
    this.returnMostRecent = false; 

  }

  onChangeStatus(e){
      this.returnMostRecent = false; 
      this.applyFiltersClick();
      this.userService.SaveUserConfigValue('CHAMP.Registry.Filters.LastClientStatusID', this._clientStatus.toString());
  }

  onChangeContract(e){
    this.returnMostRecent = false; 
    this.applyFiltersClick();
    this.userService.SaveUserConfigValue('CHAMP.Registry.Filters.LastContractID', this._contractGuid);
  }

  onClickPrimaryMedial(clientGuid, clientAltId){
    this._currentClientAltID = clientAltId;

    const loadPrimaryMedical$ = this.clientListService.getPrimaryMedicalData(clientGuid)

    loadPrimaryMedical$.subscribe({
        next: (pmdata: PrimaryMedicalInfo) => {
            this._primaryMedicalVisits = pmdata.visits;
            this._primaryMedicalTests = pmdata.tests;
        },
        error: (error) => { console.log(error);},
        complete: () => {}
      });

      this._medicalVisitsDialog = true;
    
  }

  onChangeClientID(e){
    this.returnMostRecent = false; 
    this.applyFiltersClick();
    this.userService.SaveUserConfigValue('CHAMP.Registry.Filters.LastClientID', this._clientID);
  }

  openDirectServicesInNewWindow(guid:string) {
    // Converts the route into a string that can be used 
    // with the window.open() function
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`champ_ang/client-direct-services/${guid}`])
    );
  
    window.open(url, '_blank');
    //window.open(url, '_blank');
  }


  openClient(guid:string) {
    this.app.openClient(guid);
  }

  onNewClient() {
    this.showClientEligibility();
    //console.log("here3");
    //this.router.navigate(['../client-form/null']);
    //this.router.navigate(['../client-form/client-eligibility']);
  }

  onLoadBulkGroup(mode: string) {
    this.router.navigate( ['../bulk-group/' + mode] );
  }

  pageChange(event) {
    this.currentPage = event.first;
    this.rows = event.rows;
  } 

  reset() {
    this.currentPage = 0;
  }

  onDeleteClient(event, clientListReesponse:ClientListResponse){
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete theis client?',
      header: 'Confirm',
      icon: 'pi pi-info-circle',
      defaultFocus:'reject',
      acceptLabel:'No',
      rejectLabel:'Yes',
      accept: () => {
        this.messageService.add({severity: "error", summary: "Aborted", detail: "Delete canceled", life: this.app.messageDisplayTime});
      },
      reject: () => {
          this.deleteClient(clientListReesponse, false);
          }
      });

  }

  onUndeleteClient(clientListResponse:ClientListResponse){

    this.deleteClient(clientListResponse, true);
  }

  deleteClient(clientListResponse:ClientListResponse, undelete){
    this.clientListService.deleteClient(clientListResponse, undelete, this.app.userServ.userID).subscribe(
    {
          next: (result: any) => {
            if (result){
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'Client ' + clientListResponse.clientAltID + ' Deleted', life: this.app.messageDisplayTime});
              this.applyFiltersClick();
            }
            else 
              this.messageService.add({severity: "error", summary: "Error", detail: 'Delete ' + clientListResponse.clientAltID + 'failed  ', life: this.app.messageDisplayTime});
          },
          error: (error) => {
            this.app.userServ.LogError(error);
            this.messageService.add({severity: "error", summary: "Error", detail: 'Delete ' + clientListResponse.clientAltID + 'failed  ' + error.error , life: this.app.messageDisplayTime});
          },
          complete:() => {
          }   
        });
    }


  getMostRecentClients(){
    this.returnMostRecent = true; 
    this.applyFiltersClick();

  }

  showDeletedClicked(){
    this.showDeleted = !this.showDeleted;
    //this.fetchGridData();
    this.applyFiltersClick();
   
    if (this.showDeleted )
      this.deletedLabel = "Hide Deleted";
    else
      this.deletedLabel = "Show Deleted";  
  }

  showAdminFeatures(){
      this.adminFeatures = !this.adminFeatures;
      if (this.adminFeatures )
        this.adminFeatureLabel = "Hide Admin Features";
      else
        this.adminFeatureLabel = "Show Admin Features";  
   }
  

}
