<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-7">Aids Defining Condition</div>
                <div class="field col-4"></div>
                <div class="field col-1">
                    <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('AIDSDefiningCondition', 'AIDS Defining Condition')"></button>      
                </div>
            </div>
        </div>

		<div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12">
                    <div class="formgroup-inline chamformgroup-inline" style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px; line-height: 20px;">
                        <label style="font-weight:normal; margin-bottom: 10px; margin-right: 10px;">Select the Aids defining conditions that apply to this patient.
                            <label id="idAidsDefiningCondition">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalOppDiseases?.length > 0" 
                                    [fieldId]="MasterFields.AIDSDefiningCondition" [lastUpdated]="this.clientData.clientMedicalOppDiseases[0]?.datetime" [formGroup]="medical" ></app-client-expired-field>
                            </label>
                        </label>
                    </div>
                </div>
                <div class="field col-12">
                    <!-- <label for="dsSubTypeInclusion" style="margin-top: 0.125rem;">Sub Type Inclusions</label> -->
                    <ng-multiselect-dropdown name="idAidsDefineConditionName" 
                        formControlName="idAidsDefineCondition"
                        [placeholder]="'Select up to 4 Conditions'"
                        [settings]="aidsDefineConditionDropDownSettings" 
                        [data]="aidsDefineConditions"
                        [(ngModel)]="aidsDefineConditionsSelectedItems"
                        (onSelect)="onAidsDefineConditionItemSelect($event)"
                        (onSelectAll)="onAidsDefineConditionSelectAll($event)"
                        (onDeSelect)="onAidsDefineConditionItemDeSelect($event)"
                        (onDeSelectAll)="onAidsDefineConditionDeSelectAll($event)"      
                        [disabled]="clientService.readonly"                  
                        appendTo="body"
                    >
                    </ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
</form>

<app-history ></app-history>
