import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { UserActivityService } from '../user-activity/user-activity.service';
import { Table } from 'primeng/table';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginService } from 'src/app/login/services/login.service';
import { AuditUserEventsResponseModel, DropDownItem, UserActivityDefaults } from 'src/app/models/admin.models';
import { LogType } from 'src/app/models/enums';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';

@Component({
  selector: 'app-user-activity',
  templateUrl: './user-activity.component.html',
  styleUrls: ['./user-activity.component.scss','../admin-styles.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class UserActivityComponent implements OnInit {
  events: Partial<AuditUserEventsResponseModel>[];
  providers: DropDownItem[];
  users: DropDownItem[];
  @ViewChild('dtProducts') dtProducts: Table;
  fg: UntypedFormGroup;
  isRowNew: boolean = false;
  isRowInEditMode: boolean = false;
  rowIndexInEditMode = -1;

  getAuditEvents = () => this.events;
  getXMinutes = () => this.fg?.get('lastXMinutes');
  getLastEventOnly = () => this.fg?.get('lastEventOnly');
  getProviderId = () => this.fg?.get('providerId');
  getUserId = () => this.fg?.get('userId');
  getLogTypeSeriLog = () => this.fg?.get('logTypeSeriLog');
  
  constructor(private fb: UntypedFormBuilder,
              private app: AppContextService,
              private authService: AuthService,
              private loginService: LoginService,
              private router: Router,
              private route: ActivatedRoute,
              private spinnerService: SpinnerService,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public uaservice: UserActivityService) { 


    this.fg = fb.group({
      providerId: [],
      userId: [],
      lastXMinutes: [15],
      lastEventOnly: [true],
      logTypeSeriLog: [false]
    },
    {
      //validators: [this.customValidator()],
      //asyncValidators: [this.timePeriodValidator()]
      //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
    });    
  }

  ngOnInit(): void {
    this.app.updateTabTitle('CHAMP - User Activity');
    this.loadData();  
  }

  loadData() {
    this.uaservice.getDefaultData().subscribe({
      next: (data: UserActivityDefaults) => {
        //console.log(data) 
        this.providers = data.providers;
        this.users = data.users;
      },
      error: (error) => { console.log(error) },
      complete: () => { 
        this.getProviderId().setValue(this.providers[0]?.value);
        this.getUserId().setValue(this.users[0]?.value);
        this.getActivity();
      }
    });
  }

  getActivity() {
    var request: any = {};
    Object.assign(request, {});
    request.lastXMinutes = this.getXMinutes().value; 
    request.lastEventOnly = this.getLastEventOnly().value;
    request.providerId = this.getProviderId().value;
    request.userId = ((this.getUserId()?.value == "0") ? null : request.userId);
    request.logTypeId = this.getLogTypeSeriLog()?.value === true ? LogType.SeriLog : LogType.ChampLog;    

    this.spinnerService.showSpinnerUntilCompleted(this.uaservice.getEvents(request)).subscribe({
      next: (data: AuditUserEventsResponseModel[]) => {    //Partial<AuditUserEventsResponseModel>[]
          //console.log(data);
          this.events =  data;
        
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
          console.log(error);
         },
      complete: () => { }
      });
    

  }
  onParamChange(e) {
    this.getActivity();
  }
}
