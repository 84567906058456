import { Component, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { addDays, compareAsc, getHours, getMinutes, getMonth, getYear, lastDayOfMonth } from 'date-fns';
import { cloneDeep } from 'lodash';
import { add, divide, round } from 'mathjs';
import { ConfirmationService, MessageService } from "primeng/api";
import { lastValueFrom } from 'rxjs';
import { BulkGroupIndicator, RequiredFieldResult, UnbillableReason } from "src/app/models/enums";
import { ClientFormService } from '../client-form/services/client-form.service';
import { ClientRequiredFieldErrorsComponent } from '../client-required-field-errors/client-required-field-errors.component';
import { SpinnerService } from '../common/spinner/spinner.service';
import { BulkGroupListRequestModel, BulkGroupListResponseModel, BulkGroupPostModel, BulkGroupReponseModel, BulkGroupRequestModel, BulkGroupService } from '../models/bulk-group';
import { ClientListModel } from '../models/client.model';
import { ServiceCategory } from '../models/common-data.model';
import { CommonDataService } from '../services/common-data.service';
import { HelperClassService } from '../services/helper-class.service';
import { ProviderService } from '../services/provider.service';
import { UserService } from '../services/user.service';
import { DirectServiceValidatorsService } from '../validators/direct-service-validators.service';
import { BulkgroupService } from './bulkgroup.service';
import { AppContextService } from '../app-context/app-context.service';

@Component({
  selector: 'app-bulk-group',
  templateUrl: './bulk-group.component.html',
  styleUrls: ['./bulk-group.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class BulkGroupComponent {
  //@Input() displayRiskReductionDialog: boolean;
  @ViewChild(ClientRequiredFieldErrorsComponent) requiredFieldErrorsSub: ClientRequiredFieldErrorsComponent;
  mode: string;

  fg: UntypedFormGroup;
  bulkGroupIdentifiers: BulkGroupListResponseModel[];
  months: any[] = this.helperService.getMonths();
  years: string[] = this.helperService.getYears();
  contractFunders: any[];
  clientAltIds: ClientListModel[]; //SelectItem[] = [];
  serviceCategories: ServiceCategory[];
  subTypes: any[] = [];
  employees: any[];
  barriers: any[];
  bulkGroupServices: BulkGroupReponseModel[];
  newBulkGroupGuid: string = '00000000-0000-0000-0000-000000000000';
  loading: boolean = true;
  showDateControls: boolean = true;
  currentDate: Date = new Date();
  generated: boolean = false;
  allowGroupDivideUnits: boolean = false;

  getMonthControl = () => this.fg.get('month');
  getYearControl = () => this.fg.get('year');
  getClientAltIdsControl = () => this.fg?.get('clientAltIds');
  getDeliveredDateControl = () => this.fg?.get('deliveredDate');
  // when you select a date in the pick it returns a string but when you populate the control via code its a date so need to handle either case
  getDeliveredDate = () => this.getDeliveredDateControl()?.value instanceof Date ? this.getDeliveredDateControl()?.value : this.helperService.convertToDate(this.getDeliveredDateControl()?.value, "MM-DD-YYYY");
  getDeliveredTimeControl = () => this.fg?.get('deliveredTime');
  getDeliveredTime = () => getHours(this.getDeliveredTimeControl()?.value).toString().padStart(2, '0') + ":" + getMinutes(this.getDeliveredTimeControl()?.value).toString().padStart(2, '0');
  getFunderControl = () => this.fg?.get('funder');
  getContractGuid = () => this.getFunderControl()?.value?.contractGuid;
  getFunderId = () => this.getFunderControl()?.value?.funderId ?? 0;
  getServiceControl = () => this.fg?.get('serviceId');
  getSubTypeControl = () => this.fg?.get('subId');
  getPlannedUnitsControl = () => this.fg?.get('plannedUnits');
  getDeliveredUnitsControl = () => this.fg?.get('deliveredUnits');
  getBillableControl = () => this.fg?.get('billable');
  getNotesControl = () => this.fg?.get('notes');
  //getUnbillableReasonControl = () => this.fg?.get('unbillableReasonId');
  getEmployeeControl = () => this.fg?.get('employeeId');
  getBarrierControl = () => this.fg?.get('barrier');
  //getSubTypeValue = () => parseInt(isNaN(this.fg?.get('subId')?.value) ? 0 : (this.fg?.get('subId')?.value ?? 0));
  getUnitControl = () => this.fg?.get('unit');
  getBulkGroupIdentifierControl = () => this.fg?.get('bulkGroupIdentifier');

  isBulkMode = () => this.mode.toLowerCase() == 'bulk';
  isGroupMode = () => this.mode.toLowerCase() == 'group';
  isServiceEditable = () => this.isServiceBillable() || this.getBulkGroupIdentifierControl()?.value == this.newBulkGroupGuid;
  isServiceBillable = () => { var days = this.providerService.getProviderOptions()?.adminBillableDays ?? 15;
                              var lastDayAllowedToEdit = addDays(lastDayOfMonth(this.getDeliveredDate()), days);
                              return compareAsc(lastDayAllowedToEdit, this.currentDate) >= 0; }; // returns 1 if the first date is after the second, -1 if the first date is before the second or 0 if dates are equal.

  constructor(private route: ActivatedRoute, 
              private app: AppContextService,
              private fb: UntypedFormBuilder, 
              private bulkGroupService: BulkgroupService, 
              private commonDataService: CommonDataService,
              public  clientService: ClientFormService,
              private validatorService: DirectServiceValidatorsService,
              public userService: UserService,
              private providerService: ProviderService,
              //private datePipe: DatePipe,
              private spinnerService: SpinnerService,
              private helperService: HelperClassService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService) {
      
      this.mode = this.route.snapshot.params.mode;
      //var currentDate: string = format(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()), 'MM-dd-yyyy');
      var currentMonth = (getMonth(new Date()) + 1).toString();
      //var deliverTime = getHours(currentDate).toString + ":" + getMinutes(currentDate).toString;
      this.fg = fb.group({
                  month: [currentMonth, [Validators.required]],
                  year: [this.years[0], [Validators.required]],
                  bulkGroupIdentifier: ['00000000-0000-0000-0000-000000000000'],
                  // guid: ['00000000-0000-0000-0000-000000000000'],
                  // clientGuid: ['00000000-0000-0000-0000-000000000000'],
                  funder: [, [validatorService.requiredFieldValidator()]],
                  clientAltIds: [[], [validatorService.requiredFieldValidator()]], //new FormControl([]),
                  serviceId: [0, [validatorService.requiredFieldValidator()]],
                  subId: [0, [validatorService.requiredFieldValidator()]],
                  billable: [true],
                  plannedUnits: [1],
                  deliveredUnits: [1, [Validators.required]], //{ validators: [Validators.required], updateOn: 'blur' }
                  unit: [{value: '', disabled: true}],
                  deliveredDate: [this.currentDate, [Validators.required, validatorService.futureDateValidator()]],
                  deliveredTime: [new Date(), [Validators.required, validatorService.timeValidator()]], //'00:00'
                  barrier: [0],
                  employeeId: [0, [validatorService.requiredFieldValidator()]],
                  notes: [''],
                  //unbillableReason: [],
                  userId: [0]
                }//,
                // {
                // validators: [this.barrierValidator(), this.unitCostValidator(), this.interventionValidator(), this.medicaidExpansionValidator(),
                //               this.firstEISSubTypeDateValidator(), this.firstBillableEISSubTypeDateValidator(), this.paySourceBillableValidator(),
                //               this.emergencyFinancialOtherAllowedValidator(), this.directServiceFundedValidator(), this.assessmentValidator()],
                // asyncValidators: [this.billableTimePeriodValidator(), this.hopwaWorksheetExpiredValidator(), this.hopwaWorksheetQualifiedValidator(),
                //                   this.medicalCaseManagementDataValidator(), this.directServiceBillableByFunderBillingRestrictionEncounterValidator(),
                //                   this.validAgeForIndeterminateStatusValidator(), this.moreThan4UnitsIn12MonthsValidator()]
                // //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
                // }
                );

      this.initializeData();
    }

    ngOnInit(): void {
      this.getUnitControl()?.disable();

      this.getDeliveredDateControl().valueChanges.subscribe(value => {
        console.log(value);
        var currentMonth = getMonth(value) + 1;
        if (currentMonth != Number(this.getMonthControl()?.value)) {
          this.getMonthControl()?.setValue(currentMonth.toString());
          this.onMonthYearChange();
          this.resetFieldValues();          
          this.bulkGroupServices = [];
          this.getBulkGroupIdentifierControl().setValue(this.newBulkGroupGuid);           
        }

        var currentYear = getYear(value);
        if (currentYear != Number(this.getYearControl()?.value)) {
          this.getYearControl()?.setValue(currentYear.toString());
          this.onMonthYearChange();
          this.resetFieldValues();          
          this.bulkGroupServices = [];
          this.getBulkGroupIdentifierControl().setValue(this.newBulkGroupGuid);           
        }
      });
    }

    async initializeData() {
      this.spinnerService.showSpinnerUntilCompleted(this.clientService.getClientAltIdList(this.userService.CurrentProviderID)).subscribe( {
        next: (result) => {
          if (!!result) {
            //this.clientAltIds = result.map(val => <SelectItem>{ label: val, value: val }); //.map(val => new Map([['clientAltId', val]]));
            this.clientAltIds = result;
          }
        },
        error: (error) => {
            this.helperService.displayError(this.messageService, "Fatal error occurred when loading client alt ids. Please contact CHAMP support:", error, true);
        }});

        this.barriers = this.commonDataService.getCommonSetByName("Barrier", true);
        this.employees = this.providerService.getProviderEmployeeList(true);
        this.contractFunders = this.providerService.getProviderContractFunderItems(this.getDeliveredDate(), true);
        this.onChangeMonth(this.getMonthControl()?.value);
        this.currentDate = await this.commonDataService.getCurrentDateTime();
        this.loading = false;
    }

    resetFieldValues() { 
      this.getServiceControl().setValue(0);
      this.getSubTypeControl().setValue(0);
    }

    onMonthYearChange() {
      //if (!this.loading) this.getDeliveredDateControl().setValue(format(new Date(Number(this.getYearControl()?.value), Number(this.getMonthControl()?.value) - 1, 1), 'MM-dd-yyyy'));
      this.generated = false;
      this.contractFunders = this.providerService.getProviderContractFunderItems(this.getDeliveredDate(), true);
      this.getFunderControl().setValue({ contractGuid: '0', funderId: '0' });
      var bulkGroupIndicator: BulkGroupIndicator = this.isBulkMode() ? BulkGroupIndicator.Bulk : BulkGroupIndicator.Group;
      
      var model: BulkGroupListRequestModel = { bulkGroupIndicator: bulkGroupIndicator, providerID: this.userService.CurrentProviderID, month: Number(this.getMonthControl()?.value), year: Number(this.getYearControl()?.value) };
      this.spinnerService.showSpinnerUntilCompleted(this.bulkGroupService.getBulkGroupList(model)).subscribe({
        next: (result) => {
          if (!!result) {
            this.bulkGroupIdentifiers = result;  
          }
        },
        error: (error) => {
            this.helperService.displayError(this.messageService, "Fatal error occurred when loading bulk list ids. Please contact CHAMP support:", error, true);
        },
        complete: () => {
          this.bulkGroupIdentifiers.splice(0, 0, { bulkGroupGuid: this.newBulkGroupGuid, bulkGroupDescription: 'Create New' });
        }
      });      
    }

    onChangeMonth(value) {
      //this.getDeliveredDateControl().setValue(format(new Date(Number(this.getYearControl()?.value), Number(value) - 1, 1), 'MM-dd-yyyy'));
      //this.contractFunders = this.providerService.getProviderContractFunderItems(this.getDeliveredDate(), true);
      this.onMonthYearChange();
      this.resetFieldValues();
      this.bulkGroupServices = [];
      this.getBulkGroupIdentifierControl().setValue(this.newBulkGroupGuid); // not in resetFieldValues because changing the funder should not reset this value
    }
  
    onChangeYear(value) {
      //this.getDeliveredDateControl().setValue(format(new Date(Number(value), Number(this.getMonthValue()?.value) - 1, 1), 'MM-dd-yyyy'));
      //this.contractFunders = this.providerService.getProviderContractFunderItems(this.getDeliveredDate(), true);
      this.onMonthYearChange();
      this.resetFieldValues();
      this.bulkGroupServices = [];
      this.getBulkGroupIdentifierControl().setValue(this.newBulkGroupGuid); // not in resetFieldValues because changing the funder should not reset this value
    }

    onChangeBulkGroupIdentifier(value) {
      this.bulkGroupServices = [];
      this.resetFieldValues();
      if (value != this.newBulkGroupGuid) {
        var model: BulkGroupRequestModel = { providerID: this.userService.CurrentProviderID, bulkGroupGuid: this.getBulkGroupIdentifierControl().value, userId: this.userService.userID };
        this.spinnerService.showSpinnerUntilCompleted(this.bulkGroupService.getBulkGroupServices(model)).subscribe({
          next: (result) => {
            if (!!result) {
              this.bulkGroupServices = result;
              this.populateControls(result);
            }
          },
          error: (error) => {
              this.helperService.displayError(this.messageService, "Fatal error occurred when loading bulk group services. Please contact CHAMP support:", error, true);
          },
          complete: () => {
          }
        }); 
      }
    }

    populateControls(bulkservices: BulkGroupReponseModel[]) {
      // this.clientAltIds = bulkservices.map(data => { const result: ClientListModel = { clientAltId:`${data.clientAltId}`, clientGuid:`${data.clientGuid}` };
      //                                                return result; });
      if (bulkservices.length == 0) return;
      this.getClientAltIdsControl().setValue(bulkservices.map(m => m.clientGuid));
      var bulkService = bulkservices[0];
      // need to set contract first so it will load the services list
      //{ keys: { contractGuid: bulkService.contractGuid, funderId: bulkService.funder.toString() } }
      this.getFunderControl().setValue({ contractGuid: bulkService.contractGuid, funderId: bulkService.funder.toString() });
      this.onChangeFunder(this.getFunderControl().value);
      // need to set service so it will load the sub types list
      this.getServiceControl().setValue(bulkService.serviceId);
      this.onChangeServiceType(bulkService.serviceId);
      this.getSubTypeControl().setValue(bulkService.subId);
      this.onChangeSubType(bulkService.subId);
      this.getEmployeeControl().setValue(bulkService.employeeId);
      this.getBarrierControl().setValue(bulkService.barrierId);
      this.getBillableControl().setValue(bulkService.billable);
      this.getPlannedUnitsControl().setValue(bulkService.plannedUnits);
      var deliveredUnits = bulkService.deliveredUnits;
      if (this.isGroupMode() && this.providerService.allowGroupDivideUnits(this.getContractGuid(), this.getFunderId(), this.getServiceControl()?.value, this.getSubTypeControl()?.value)) {
        deliveredUnits = bulkservices.reduce((accumulator, currentValue) => add(round(accumulator, 2), round(currentValue.deliveredUnits, 2)), 0);
      } 
      this.getDeliveredUnitsControl().setValue(deliveredUnits);
      this.getNotesControl().setValue(bulkService.notes);
      this.getDeliveredDateControl().setValue(this.helperService.convertToDate(bulkService.deliveredDate, "YYYY-MM-DD"));
      this.getDeliveredTimeControl().setValue(this.helperService.convertToDate(bulkService.deliveredDate, "YYYY-MM-DD HH:mm"));
    }

    onChangeFunder(funderKeys) {
      var funderId = Number(funderKeys.funderId);

      if (this.isBulkMode)
        this.serviceCategories = this.commonDataService.getBulkServiceCategories(funderId, true);
      else
        this.serviceCategories = this.commonDataService.getGroupServiceCategories(funderId, true);

      this.resetFieldValues();
    }

    onChangeServiceType(serviceId: number) {
      var funderId = this.getFunderId();

      if (this.isBulkMode)
        this.subTypes = this.commonDataService.getBulkSubTypesByService(serviceId, funderId, true);
      else
        this.subTypes = this.commonDataService.getGroupSubTypesByService(serviceId, funderId, true);

      // always reset subId to the first in the list here, setFieldValues will take care of assigning the correct id when loading existing to edit
      this.getSubTypeControl().setValue(this.subTypes[0]?.id);
      this.getUnitControl().setValue("");
    }

    onChangeSubType(subTypeId: number) {
      var subType = this.subTypes.find(x => x.id == subTypeId);
      if (subType != null) {
        this.getUnitControl().setValue(this.commonDataService.getDescription("UnitType", subType.defaultUnit)[0]);
      }
    }

    onChangeBillable(billable: any) {
      // //console.log(billable);
      // if (billable) { 
      //   this.setUnbillableReason(UnbillableReason.Billable);
      //   this.resetAcknowledgements();
      //   this.dsForm.get('billable').updateValueAndValidity();
      //   //this.dsForm.get('billable').markAsTouched();      
      // }
      // else if (this.getUnbillableReason() == UnbillableReason.Billable) {
      //   this.setUnbillableReason(UnbillableReason.UserMarkUnbillable);
      // }
    }

    onClientAltIdChange(event) {
      const ITEM_LIMIT = 50;
      if (event.value.length > ITEM_LIMIT) {
        event.value.pop();
        this.messageService.add({
          severity: "info",
          summary: "Information",
          detail: "You have exceeded the maximum number of items allowed [" + ITEM_LIMIT.toString() + "] for selection. The last item will not be added.",
          life: this.app.messageDisplayTime});          
      }
    }

    onClearClientAltIds() {
      this.getClientAltIdsControl().setValue([]);
    }

    onGenerateConfirm(event: Event) {
      this.confirmationService.confirm({
        target: event.target,
        message: "Are you sure that you want to proceed?",
        icon: "pi pi-info-circle",
        accept: () => {
          this.generateBulkGroupServices()
        },
        reject: () => {
          this.messageService.add({
            severity: "error",
            summary: "Aborted",
            detail: "Changes Canceled",
            life: this.app.messageDisplayTime
          });
        }
      });      
    }

    async generateBulkGroupServices() {
      // this.bulkGroupServices // add all selected clients to the grid with options selected make sure clientGuid is added to the grid too
      this.bulkGroupServices = [];
      // var clientGuids = this.getClientAltIdsControl()?.value;
      // for (var i=0; i < clientGuids?.length; ++i) {
      //   var model: BulkGroupReponseModel = { 
      //                                         clientGuid: clientGuids[i],
      //                                         clientAltId: this.clientAltIds.find(f => f.clientGuid == clientGuids[i])?.clientAltId, 
      //                                         deliveredDate: this.getDeliveredDateControl()?.value,
      //                                         serviceId: this.getServiceControl()?.value,
      //                                         serviceCategory: this.commonDataService.getServiceCategoryDescription(this.getServiceControl()?.value),
      //                                         subId: this.getSubTypeControl()?.value,
      //                                         subType: this.commonDataService.getSubTypeDescription(this.getSubTypeControl()?.value),
      //                                         plannedUnits: this.getPlannedUnitsControl()?.value,
      //                                         deliveredUnits: this.getDeliveredUnitsControl()?.value,
      //                                         billable: this.getBillableControl()?.value,
      //                                         unbillableReasonId: null,
      //                                         unbillableReason: "N/A",
      //                                         notes: this.getNotesControl()?.value,
      //                                         barrierId: this.getBarrierControl()?.value,
      //                                         employeeId: this.getEmployeeControl()?.value
      //                                       };
      //   this.bulkGroupServices.push(model);
      // }
      this.spinnerService.On();
      try {
        // for group mode we need to check if we should divide the units
        this.allowGroupDivideUnits = false;
        if (this.isGroupMode()) {
          this.allowGroupDivideUnits = (this.providerService.allowGroupDivideUnits(this.getContractGuid(), this.getFunderId(), this.getServiceControl()?.value, this.getSubTypeControl()?.value));
        }
        //this.getClientAltIdsControl().value.foreach, changed to map to support the wait for all the promises to finish, so we can use the spinner
        await Promise.all(this.getClientAltIdsControl().value.map(async (clientGuid) => 
          {
            //bulkGroupGuid: this.getBulkGroupIdentifierControl().value
            var model: BulkGroupReponseModel = { 
                                                 contractGuid: this.getContractGuid(),
                                                 funder: this.getFunderId(),
                                                 clientGuid: clientGuid,
                                                 clientAltId: this.clientAltIds.find(f => f.clientGuid == clientGuid)?.clientAltId, 
                                                 deliveredDate: this.getDeliveredDateControl()?.value,
                                                 deliveredTime: this.getDeliveredTime(),
                                                 serviceId: this.getServiceControl()?.value,
                                                 serviceCategory: this.commonDataService.getServiceCategoryDescription(this.getServiceControl()?.value),
                                                 subId: this.getSubTypeControl()?.value,
                                                 subType: this.commonDataService.getSubTypeDescription(this.getSubTypeControl()?.value),
                                                 plannedUnits: this.getPlannedUnitsControl()?.value,
                                                 deliveredUnits: this.getDeliveredUnitsControl()?.value,
                                                 billable: this.getBillableControl()?.value,
                                                 notes: this.getNotesControl()?.value,
                                                 unbillableReasonId: null,
                                                 unbillableReason: "",
                                                 barrierId: this.getBarrierControl()?.value,
                                                 employeeId: this.getEmployeeControl()?.value,
                                                 clientRequiredFieldErrors: []
                                               };
            
            if (!this.isServiceBillable()) {
              model.billable = false;
              model.unbillableReasonId = UnbillableReason.PastAllowableBillableTimePeriod;
              model.unbillableReason = "Past Allowable Billable Time Period";
            }
            else {
              var result = await this.clientService.validateRequiredFields(clientGuid, this.userService.userID);
              if (result != RequiredFieldResult.Pass) {
                //console.debug(this.clientService.clientRequiredFieldErrors);
                model.billable = false;
                model.unbillableReasonId = UnbillableReason.MissingRequiredField; //this.clientService.clientRequiredFieldErrors[0].fldErrType
                model.unbillableReason = "Missing Required Field(s)"; //this.clientService.clientRequiredFieldErrors.map(m => m.errDesc).join("\n");
                model.clientRequiredFieldErrors = cloneDeep(this.clientService.clientRequiredFieldErrors);
              }
            }

            // for group mode we need to check if we should divide the units
            if (this.isGroupMode() && this.allowGroupDivideUnits) {
                var numberItems = this.getClientAltIdsControl()?.value?.length ?? 1;
                var units = round(divide(model.deliveredUnits, numberItems), 2);
                model.deliveredUnits = units;
            }
           
            this.bulkGroupServices.push(model);
          }));

          // handle uneven division cases must be done outside the async loop above
          if (this.isGroupMode() && this.allowGroupDivideUnits && !!this.bulkGroupServices) {
            var totalUnits = this.bulkGroupServices?.reduce((accumulator, currentValue) => add(round(accumulator, 2), round(currentValue.deliveredUnits, 2)), 0);
            var difference = round(this.getDeliveredUnitsControl()?.value - totalUnits, 2);
            if (difference != 0) {
              // put the difference on the last line per Den, 5/24/2024
              this.bulkGroupServices[this.bulkGroupServices.length - 1].deliveredUnits = round(add(this.bulkGroupServices[this.bulkGroupServices.length - 1].deliveredUnits, difference), 2);
            }
          }

          // if at least one service is not billable then update billable control
          if (!!this.bulkGroupServices?.find(f => !f.billable)) this.getBillableControl().setValue(false);
          this.generated = true;
      }
      catch(error) {
        this.helperService.displayError(this.messageService, "Fatal error generating bulk/group services. Please contact CHAMP support:", error, true);
      }
      finally {
        this.spinnerService.Off();
      }
    }

    async showClientRequiredFieldErrors(bulkGroup: BulkGroupReponseModel) {
      // this case handles selecting and existing group, I did not want to run validateRequiredFields on the backend for every client in the group 
      // since the user may never click to look at the required fields
      if (!bulkGroup?.clientRequiredFieldErrors) {
        this.spinnerService.On();
        try {
          await this.clientService.validateRequiredFields(bulkGroup.clientGuid, this.userService.userID);
          bulkGroup.clientRequiredFieldErrors = cloneDeep(this.clientService.clientRequiredFieldErrors);        
        }
        catch(error) {
          this.helperService.displayError(this.messageService, "Fatal error calculating required fields. Please contact CHAMP support:", error, true);
        }
        finally {
          this.spinnerService.Off();
        }        
      }
      // this case is after a Save occurs and the user wants to see the latest required fields to see why a service flipped to not billable
      if (!!bulkGroup?.clientRequiredFieldErrors) {
        this.clientService.clientRequiredFieldErrors = bulkGroup?.clientRequiredFieldErrors;
        this.requiredFieldErrorsSub.onShowRequiredFieldErrorsDialog();
      }
      else {
        this.messageService.add({
          severity: "info",
          summary: "Information",
          detail: "No required fields errors found for " + bulkGroup.clientAltId + " after re-validating all the data elements.",
          life: this.app.messageDisplayTime});        
      }
    }

    onSaveDirectServiceChangesConfirm(event: Event) {
      this.confirmationService.confirm({
        target: event.target,
        message: "Are you sure that you want to proceed?",
        icon: "pi pi-info-circle",
        accept: () => {
          this.onSaveDirectServiceChanges().then(value => {
            this.messageService.add({
              severity: "info",
              summary: "Confirmed",
              detail: "Changes Saved",
              life: this.app.messageDisplayTime});            
          });
        },
        reject: () => {
          this.messageService.add({
            severity: "error",
            summary: "Aborted",
            detail: "Changes Canceled",
            life: this.app.messageDisplayTime
          });
        }
      });
    }

    async onSaveDirectServiceChanges() {
      this.spinnerService.On();
      try {
        //Object.assign(model, {});
        var bulkGroupPostModel = await Promise.all(this.bulkGroupServices.map(async (bulkGroupService) => {
          var model: Partial<BulkGroupPostModel> = new BulkGroupService(cloneDeep(bulkGroupService));
          model.contractGuid = this.getContractGuid();
          model.funder = Number(this.getFunderId());
          model.bulkGroupGuid = this.getBulkGroupIdentifierControl()?.value;
          model.userId = this.userService.userID;
          model.providerId = this.providerService.providerID;
          model.deliveredDate = this.helperService.castToDateString(this.getDeliveredDateControl()?.value, "yyyy-MM-dd");
          return model;
        }));
        //console.log(bulkGroupPostModel);

        var response = await lastValueFrom(this.bulkGroupService.saveBulkGroupServices(bulkGroupPostModel));
        const keys = JSON.parse(response.data);
        // if (keys.isNewBulkGroupGuid.toLowerCase() == 'true') {
        //   var newBulkGroupDesc = this.commonDataService.getServiceCategoryDescription(bulkGroupPostModel[0]?.serviceId) + ' - ' + 
        //                          this.helperService.castToDateString(this.getDeliveredDateControl()?.value, "yyyy-MM-dd") + ' ' +
        //                          this.getDeliveredTime() + ' - Bulk Id = ' + keys.bulkGroupId;
        //   this.bulkGroupIdentifiers.splice(1, 0, { bulkGroupGuid: keys.bulkGroupGuid, bulkGroupDescription: newBulkGroupDesc });
        //   this.getBulkGroupIdentifierControl().setValue(keys.bulkGroupGuid);
        // }
        var newBulkGroupDesc = this.commonDataService.getServiceCategoryDescription(bulkGroupPostModel[0]?.serviceId) + ' - ' + 
                                this.helperService.castToDateString(this.getDeliveredDateControl()?.value, "yyyy-MM-dd") + ' ' +
                                this.getDeliveredTime() + ' - Bulk Id = ' + keys.bulkGroupId;

        var index = this.bulkGroupIdentifiers.findIndex(f => f.bulkGroupGuid == keys.bulkGroupGuid);                                
        if (index < 0)
          this.bulkGroupIdentifiers.splice(1, 0, { bulkGroupGuid: keys.bulkGroupGuid, bulkGroupDescription: newBulkGroupDesc });
        else
          this.bulkGroupIdentifiers.splice(index, 1, { bulkGroupGuid: keys.bulkGroupGuid, bulkGroupDescription: newBulkGroupDesc });

        this.getBulkGroupIdentifierControl().setValue(null);
        this.getBulkGroupIdentifierControl().setValue(keys.bulkGroupGuid);
        this.generated = false;
      }
      catch (error) {
        console.log(error.error.message); console.log(error.error.data);
        this.helperService.displayError(this.messageService, "Fatal error savings bulk/group services. Please contact CHAMP support:", error, true);
      }
      finally {
        this.spinnerService.Off();
      }      
    }

}
