<div class="layout-wrapper" [ngClass]="{'layout-menu-horizontal': menuMode === 'horizontal',
                                        'layout-menu-popup': menuMode === 'popup',
                                        'layout-menu-overlay': menuMode === 'overlay',
                                        'layout-menu-static': menuMode === 'static',
                                        'layout-menu-static-inactive': (menuMode == 'static' && menuActive === false),
                                        'p-input-filled': inputStyle === 'filled', 'p-ripple-disabled': !ripple}" (click)="onLayoutClick()">

    <app-topbar></app-topbar>
    <!-- <error-messages></error-messages> -->
    <div class="layout-main">
        <div class="layout-content">
            <app-spinner></app-spinner>
            <router-outlet>  </router-outlet>
            <p-toast position="center"></p-toast> 
<!-- 
            <p-sidebar [(visible)]="notificationsSidebar" position="right">
                <h3>Right Sidebar</h3>
            </p-sidebar>
             -->


            <div  *ngIf="(loginService.currentLoginStatus$ | async) as status">
                <p-dialog [visible]="!status.loggedIn && !status.userLogout"  [closable]="false"   [showHeader]="false"
                       header="Login" 
                       [modal]="true" position="center" [resizable]="false" [draggable]="true" showEffect="explode"
                       styleClass="pDialog" 
                       [blockScroll]="true" 
                       
                        >  <!-- REMOVED CAUSING ERRORS IN PRIME - DL  style="z-index: 1000 !important;pointer-events: auto;" -->
                   <ng-template pTemplate="content">
                       <app-login></app-login>
                   </ng-template>
               </p-dialog>  
             </div>
       

        </div>
        <app-footer></app-footer>
    </div>
    <!-- <app-config></app-config> -->
</div>
