import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-reportviewer',
  templateUrl: './reportviewer.component.html',
  styleUrls: ['./reportviewer.component.scss']
})
export class ReportviewerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
