import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { sortBy } from 'lodash';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { DateHelper } from "src/app/common/date-helper";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ZipCodeResult } from '../../../models/common-data.model';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { ClientModel, ClientPrimaryCarePhysicianModel } from '../../../models/client.model';
import { HistoryComponent } from '../../history/history.component';
import { ClientFormService } from '../../services/client-form.service';
import { ClientExpiredFieldComponent } from '../../client-expired-field/client-expired-field.component'
//import { ClientFormSharedModule } from 'src/app/client-form/client-form.shared.module'
import { MasterFields } from "src/app/models/enums";
import { HelperClassService } from 'src/app/services/helper-class.service';
import { noop } from 'rxjs';
import { EmployeeComponent } from 'src/app/admin/employee/employee.component';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-identification',
  templateUrl: './identification.component.html',
  styleUrls: ['./identification.component.scss']
})
export class IdentificationComponent implements OnInit, AfterViewInit {
  @ViewChild('idZipCodeExpire', {static: false}) private zipExpireLabel: ElementRef<HTMLDivElement>;
  @Input() identification: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  @ViewChild(EmployeeComponent) employeeComponent:  EmployeeComponent;
  @Output() componentFinishedLoading: EventEmitter<boolean> = new EventEmitter();

  getZipCodeControl = () => this.identification.get('idZipCode');

  //MasterFields: typeof MasterFields; 
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file
  clientData: ClientModel;
  clientGUID: string;
  genders:any[];
  pcps:any[];
  employees:any[];
  pcpt:ClientPrimaryCarePhysicianModel[];
  maxBirthDate = new Date();
  minBirthDate = new Date();

  constructor(
    private app:AppContextService,
    private fb: UntypedFormBuilder, 
    private commonDataService:CommonDataService,
    private providerService: ProviderService,
    private userService:UserService,
    private clientService:ClientFormService,
    private datePipe: DatePipe,
    private dateHelper:DateHelper,
    public  validationHelper:FormValidations,
    private cdRef:ChangeDetectorRef,
    private helperService: HelperClassService
    ) {
      this.componentFinishedLoading.emit(false);
  }

  ngAfterViewInit() {
  }

  // ngAfterViewChecked() {
  //   if (this.zipExpireLabel?.nativeElement?.innerText.includes("(Expired")) {
  //     this.identification.get('idZipCode').setValue(null);
  //     this.onBlurZipCode();
  //   }
  // }

  ngOnInit(): void {
    
    this.minBirthDate= this.dateHelper.add( "year", -100, this.minBirthDate );
    this.maxBirthDate =this.dateHelper.add( "day", -1, this.maxBirthDate );

    this.genders = this.commonDataService.getCommonSetByName("Gender", true);
    // this.employees = this.providerService.getProviderEmployeeList();
    // this.employees = sortBy(this.employees, obj => obj.fullName);
    // this.employees.splice(0, 0, {id: 0, fullName: '-- Select a value --'});

    this.pcps = this.providerService.getProviderPCPsList();
    this.pcps = sortBy(this.pcps, obj => obj.fullName);
    this.pcps.splice(0, 0, {id: 0, fullName: '-- Select a value --'});

    // this.identification.get('idFirstName').setValue("");
    // this.identification.get('idFirstName').setValue(this.clientData?.firstName ?? null);
    // this.identification.get('idFirstName').updateValueAndValidity();
    
    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.identification.markAllAsTouched();
    }
    else {
            this.clientService.$clientGet.subscribe(
            {
              next: (result) => { this.clientData = result; },
              error: (error) => { this.userService.LogError(error); },
              complete: () => { this.setFieldValues(); } //this.clientData = this.clientService.clientData;
            });        
    }
  }

  refreshAfterSave() {
    this.setFieldValues();
  }	 

  setFieldValues() {
      if (!this.clientData) return;
      this.componentFinishedLoading.emit(false);

      this.employeeComponent.getEmployees(this.app.provSvc.providerID);
      this.identification.get('idFirstName').setValue(this.clientData?.firstName ?? "");
      this.identification.get('idMiddleName').setValue(this.clientData?.middleName);
      this.identification.get('idLastName').setValue(this.clientData?.lastName);
      this.identification.get('idSSN').setValue(this.clientData?.ssn);
      this.identification.get('idEmployee').setValue(this.clientData?.employeeId);
      this.identification.get('idGender').setValue(this.clientData?.gender);
      this.identification.get('idNotes').setValue(this.clientData?.notes);
      this.identification.get('idBirthDate').setValue(this.helperService.getISODate(this.clientData?.birthdate));
      
      var birthdate = new Date(this.identification.get('idBirthDate').value);
      let age = ClientFormHelper.CalculateAge(birthdate);
      this.identification.get('idAge').setValue(age);

      this.identification.get('idFirstAmbulatoryCareVisitDate').setValue(this.helperService.getISODate(this.clientData?.clientPrimaryCarePhysicians?.firstVisitDate));
      this.identification.get('idPCP').setValue(this.clientData?.clientPrimaryCarePhysicians?.id ?? 0);

      this.identification.get('idZipCode').setValue(this.clientData?.clientZipCodes?.zipCode);
      this.onBlurZipCode();

      this.employeeComponent.load( this.identification.get('idEmployee').value, null)

      this.identification.markAsPristine();
      this.componentFinishedLoading.emit(true);
      // Clear and Errors created while setting form 
      //this.clientService.clearValidators(this.identification);
      //this.identification.markAllAsTouched();
  }

  onChangePCP(event) {
  }

  onChangeGender(event) {
      //console.log('HERE');
  }

  onChangeBirthDate(event) {
      let age  = ClientFormHelper.CalculateAge(this.identification.get('idBirthDate').value);
      this.identification.get('idAge').setValue(age);
    }

  onBlurSSN() {
      this.identification.get('idSSN').updateValueAndValidity();
  }

  onBlurZipCode() {

    
      this.commonDataService.getZipCodeData( this.identification.get('idZipCode').value).subscribe(    
          {
            next: (result: ZipCodeResult) => { 
                //console.log(result); 
                this.identification.get('idCity').setValue(result?.city);
                this.identification.get('idState').setValue(result?.state);
                this.identification.get('idCounty').setValue(result?.county);
            
            },
            error: (e) => console.error(e),
            complete:() => {
              this.cdRef.detectChanges();
            }
        } );
  }
    
  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData.guid;
      this.histComp.clientAltID = this.clientData.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }

  onRenewValue(value){
      let fieldId:number =  value; 

      if (fieldId == MasterFields.ZIPCode ){
          this.getZipCodeControl().setValue(this.getZipCodeControl().value);
          this.getZipCodeControl().markAsDirty(); 
      }

  }

  onEmployeeChange(employeeId){
      this.identification.get('idEmployee').setValue(employeeId);
      this.identification.get('idEmployee').markAsTouched();
      this.identification.get('idEmployee').markAsDirty();
  }

  onCheckedExpiredField(event: any) {
    if (!event?.expired) return;

    switch (event?.fieldId) {
      case MasterFields.ZIPCode:
        this.identification.get('idZipCode').setValue(null);
        this.onBlurZipCode();
        break;
    }
 }  

}
