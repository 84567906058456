import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
//import { ClientFormHelperService} from 'src/app/client-form/services/client-form-helper.service';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { DateHelper } from "src/app/common/date-helper";
import { CommonData } from 'src/app/models/common-data.model';
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { ClientModel } from '../../../models/client.model';
import { HistoryComponent } from '../../history/history.component';
import { ClientFormService } from '../../services/client-form.service';
import { MasterFields } from "src/app/models/enums";
import { noop } from 'rxjs';

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.scss']
  })
export class DemograhicsComponent implements OnInit, AfterViewChecked {
  @Input() demographics: UntypedFormGroup;

  public loaded:boolean=false;
  clientData: ClientModel;
  ethnicities:CommonData[];
  races:CommonData[];
  regions:CommonData[];
  sexualOrientations:CommonData[];
  referralSources:CommonData[];
  clientStatuses:CommonData[];
  hivStatuses:CommonData[];
  tranmissionModes:CommonData[];

  multiCheckDropDownSettings: any = {};
  tranModesSelectedItems:any[] = []; // this has to be multi select use a single select for now
  maxAidsDiagDate = new Date();
  minAidsDiagDate = new Date();
  maxHIVDiagDate = new Date();
  minHIVDiagDate = new Date();
  birthDate = new Date();


  @ViewChild('aidsDiagDatePicker') aidsDiagDatePicker: ElementRef;
  @ViewChild(HistoryComponent, {static: false}) histComp:HistoryComponent;

  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  //@ViewChild(BsDatepickerDirective, { static: false }) datepicker: BsDatepickerDirective;

  constructor(private fb: UntypedFormBuilder, 
    private commonDataService:CommonDataService,
    private providerService: ProviderService,
    private userService:UserService,
    public clientService:ClientFormService,
    private datePipe: DatePipe,
    private dateHelper:DateHelper,
    public  validationHelper:FormValidations,
    private cdRef:ChangeDetectorRef

    ) {



     }

  ngOnInit(): void {

    this.minHIVDiagDate= this.dateHelper.add( "year", -100, this.minHIVDiagDate );
    this.minAidsDiagDate= this.dateHelper.add( "year", -100, this.minAidsDiagDate );
    this.maxHIVDiagDate= this.dateHelper.add( "day", -1 , this.maxHIVDiagDate );
    this.maxAidsDiagDate =this.dateHelper.add( "day", -1, this.maxAidsDiagDate );

    this.ethnicities = this.commonDataService.getCommonSetByName("Ethnicity", true);
    this.races = this.commonDataService.getCommonSetByName("Race", true);
    this.regions = this.commonDataService.getCommonSetByName("RegionOrigin", true);
    this.sexualOrientations = this.commonDataService.getCommonSetByName("SexualOrientation", true);
    this.referralSources = this.commonDataService.getCommonSetByName("ReferralSource", true);
    this.clientStatuses = this.commonDataService.getCommonSetByName("ClientStatus", true);
    this.hivStatuses = this.commonDataService.getCommonSetByName("HivStatus",true);
    this.tranmissionModes = this.commonDataService.getCommonSetByName("TransmissionMode", false);
      
    // this.employees = this.providerService.getProviderEmployeeList();
    // this.employees = sortBy(this.employees, obj => obj.fullName)
    // this.pcps = this.providerService.getProviderPCPsList();
    // this.pcps = sortBy(this.pcps, obj => obj.fullName)

    this.multiCheckDropDownSettings = { 
          singleSelection: false,
          limitSelection: 4,
          idField: 'id',
          textField: 'description',
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableCheckAll:false,
          allowSearchFilter: false};

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.demographics.markAllAsTouched();
    }
    else {
            this.clientService.$clientGet.subscribe(
            {
              next: (result) => { this.clientData = result; },
              error: (error) => { this.userService.LogError(error); },
              complete: () => { this.setFieldValues(); } //this.clientData = this.clientService.clientData;
            });        
    }        

    this.setObservables();
    this.setAIDSRequiredFields(this.demographics.get('idHIVStatus').value);
    //this.demographics.get('idTransmissionModes').markAsPristine();
  }

  refreshAfterSave() {
    this.setFieldValues();
  }  

  // ngAfterViewInit() {
  //   this.demographics.get('idTransmissionModes').markAsPristine();
  // }

  ngAfterViewChecked() {
    //this.demographics.get('idTransmissionModes').markAsPristine();
  }


  setObservables(){

    this.demographics.get('idHIVStatus').valueChanges
    .subscribe(hivStatus => {
      this.setAIDSRequiredFields(this.demographics.get('idHIVStatus').value);
    });

    this.demographics.get('idDiagnosedLast12Months').valueChanges
    .subscribe(hivStatus => {
      this.setAIDSRequiredFields(this.demographics.get('idHIVStatus').value);
    });

  }


  setFieldValues() {
    if (!this.clientData) return;

    this.demographics.get('idEthnicity').setValue(this.clientData.ethnicity);
    this.demographics.get('idRace').setValue(this.clientData.race);
    this.demographics.get('idRegionOrigin').setValue(this.clientData.regionOrigin);
    this.demographics.get('idSexualOrientation').setValue(this.clientData.sexualOrient);
    this.demographics.get('idClientStatus').setValue(this.clientData.clientStatus?.id);
    this.demographics.get('idReferalSource').setValue( this.clientData.clientReferralSource?.id);
    this.demographics.get('idHIVStatus').setValue(this.clientData.clientHivStatus?.id);
    this.demographics.get('idDiagnosedLast12Months').setValue(this.clientData.newlyDiagnosed);
    
    let test = new Date();
    //this.demographics.get('idHIVDiagnosis').setValue(this.datePipe.transform(this.clientData.hivConfirmYear, 'MM-dd-YYYY'));

    if ( this.clientData.hivConfirmYear) this.demographics.get('idHIVDiagnosis').setValue(new Date(this.clientData.hivConfirmYear));
    if ( this.clientData.aidsDiagnosYear ) this.demographics.get('idAIDSDiagnosis').setValue(new Date(this.clientData.aidsDiagnosYear)); 

    if ( this.clientData.hivConfirmYear) this.demographics.get('idHIVDiagnosisYear').setValue(this.datePipe.transform(this.clientData.hivConfirmYear, 'YYYY'));
    if ( this.clientData.aidsDiagnosYear ) this.demographics.get('idAIDSDiagnosisYear').setValue(this.datePipe.transform(this.clientData.aidsDiagnosYear, 'YYYY')); 
    this.demographics.get('idEISEligible').setValue(this.clientData.eisIndicator);
    this.demographics.get('idTransmissionModes').setValue(this.clientData.clientTranModes);
    //this.idForm.get('idTransmissionModes').setValue(this.clientData.clientTranModes.map(x => {x.id, x. }));

    this.birthDate = this.demographics.parent.get('identification').get('idBirthDate').value;
    this.tranModesSelectedItems =  ClientFormHelper.GetMultiItems(this.demographics.get('idTransmissionModes'), this.clientData.clientTranModes, this.tranmissionModes);


    // Force change detection than mark the form as prsitine - needed for multi-select dropdowns mostly 
    this.cdRef.detectChanges();
    this.demographics.markAsPristine();
    
   // this.cdRef.detectChanges();
   // this.demographics.get('idTransmissionModes').markAsPristine();

    //this.tranModesSelectedItems = this.GetMultiItems(this.demographics.get('idTransmissionModes'), this.clientData.clientTranModes, this.tranmissionModes);
  //  this.tranModesSelectedItems = [];

    // if (this.tranmissionModes && this.clientData.clientTranModes && this.clientData.clientTranModes?.length > 0) {
    //   this.clientData.clientTranModes.forEach( (tm) => {
    //           var x = this.tranmissionModes.find(x => x.id == tm.id);
    //           if (x != null) this.tranModesSelectedItems.push(x);   
    //     });
    //    // this.demographics.get('idTransmissionModes').markAsPristine();
    // }
    
    // this.minHIVDiagDate=  this.birthDate;
    // this.minAidsDiagDate=  this.birthDate;

    // if (this.clientData.clientTranModes?.length > 0) {
    //   this.clientData.clientTranModes.forEach( (tm) => {
    //           var x = this.tranmissionModes.find(x => x.id == tm.id);
    //           if (x != null) this.tranModesSelectedItems.push(x);   
    //    });
    // }

    // Clear and Errors created while setting form 
   
    //this.clientService.clearValidators(this.demographics);
  //  this.demographics.markAllAsTouched();
   
    this.clientService.loadedDemos = true;

}


GetMultiItems( control: AbstractControl,  multiItems: any[],  common : CommonData[]) {
  var returnList: CommonData[]=[];

  if (common && multiItems && multiItems.length > 0) {
      multiItems.forEach( (tm) => {
              var x = common.find(x => x.id == tm.id);
              if (x != null) returnList.push(x);   
        });
        control.markAsPristine();
        return returnList;
  }
  else{
   control.markAsPristine();
    return null;
  }
}

 setAIDSRequiredFields(hivStatus){
    let hivDiag = this.demographics.get('idAIDSDiagnosis');
    let hivDiagYear = this.demographics.get('idAIDSDiagnosisYear');

    if ( this.demographics.get('idDiagnosedLast12Months').value == null ) { // If new client and no value set 
        hivDiagYear.disable();
        hivDiagYear.clearValidators();
        hivDiagYear.updateValueAndValidity();

        hivDiag.disable();
        hivDiag.clearValidators();
        hivDiag.updateValueAndValidity();
    } 
    else
    {
        let diagLast12Months = this.demographics.get('idDiagnosedLast12Months').value  == 1 ? true : false;
        let affected = this.demographics.get('idDiagnosedLast12Months').value  == 3 ? true : false;
        if (!affected)
        { 
          if (hivStatus ==  8) {
              //this.demographics.get('state').reset();
              if ( diagLast12Months  )
              {
                hivDiag.enable();
                hivDiag.setValidators(Validators.required);
                hivDiag.markAsTouched();
                hivDiag.updateValueAndValidity();
              }
              else{
                hivDiagYear.enable();
                hivDiagYear.setValidators(Validators.required)
                hivDiagYear.markAsTouched();
                hivDiagYear.updateValueAndValidity();
              }
          }
          else {
            hivDiagYear.disable();
            hivDiagYear.clearValidators();
            hivDiagYear.updateValueAndValidity();

            hivDiag.disable();
            hivDiag.clearValidators();
            hivDiag.updateValueAndValidity();
              
          }
        }
        else{
          hivDiagYear.disable();
          hivDiagYear.clearValidators();
          hivDiagYear.updateValueAndValidity();

          hivDiag.disable();
          hivDiag.clearValidators();
          hivDiag.updateValueAndValidity();

        }

    }

 }

  onChangeEthnicity(event){
    
  }

  onChangeRace(event){
    
  }

  onChangeRegion(event){
    
  }

  onChangeSexualOrientation(event){
    
  }

  onChangeHIVDiagnosisDate(event){
    
    //this.aidsDiagDate.nativeElement.minDate = this.dateHelper.add( "day", 1, this.demographics.get('idHIVDiagnosis').value );
    //this.aidsDiagDate.nativeElement.hide();
    //this.aidsDiagDatePicker.nativeElement.minDate =  this.minAidsDiagDate;
    //this.minAidsDiagDate.setDate(this.demographics.get('idHIVDiagnosis').value);
 
  }

  onChangeHIVDiagnosisDatePicker(){
    //this.aidsDiagDate.nativeElement.minDate = this.dateHelper.add( "day", 1, this.demographics.get('idHIVDiagnosis').value );

  }

   openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData.guid;
      this.histComp.clientAltID = this.clientData.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
   }

}
