import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { addDays, compareAsc, format } from 'date-fns';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { MasterFields } from 'src/app/models/enums';
import { HelperClassService } from '../../services/helper-class.service';
import { ProviderService } from '../../services/provider.service';

@Component({
  selector: 'app-client-expired-field',
  templateUrl: './client-expired-field.component.html',
  styleUrls: ['./client-expired-field.component.scss']
})
export class ClientExpiredFieldComponent implements OnInit {
  @Input() fieldId: number;
  @Input() lastUpdated: any;
  @Input() control: AbstractControl = null;
  @Input() formGroup: FormGroup = null;
  @Output() renewValue: EventEmitter<number> = new EventEmitter();
  @Output() isFieldExpired: EventEmitter<any> = new EventEmitter();

  showLabel: boolean = false;
  showRenew: boolean = false;
  expireLabel: string = "";
  expired: boolean = false;
  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  //changeObservable$: Observable<any>;

  constructor(private providerService: ProviderService, private helperClassService: HelperClassService, public appContext:AppContextService) { }

  ngOnInit(): void {
    if ( this.fieldId == MasterFields.MedicalCaseManagementAddNonRWMedicalVisit){
      console.log("here");
    }

    this.setup();

    // if ( this.formGroup == null && this.control == null){
    //   // console.log("here");
    // }

    // if (this.fieldId == 5 ){
    //   console.log("here")      ;
    // }
  
    this.control?.valueChanges.subscribe(val  => { 
     this.lastUpdated = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
     
     if (this.fieldId == 179 ){  // HardCoded for MasterFields.MedicalCaseManagementAddNonRWMedicalVisit  since the value and last update are both the same field
         this.lastUpdated = val;
     }

      if (this.control.valid)     this.setup();
    });

    this.formGroup?.valueChanges.subscribe(ctl  => { 
      this.lastUpdated = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
      //console.log(ctl);
      if (this.formGroup.valid)     this.setup();
    });

  }

  setup() {
    var requiredField = this.providerService.getExpiredRequiredField(this.fieldId);
    // if (this.fieldId == 179 ){
    //     console.log("here")      ;
    // }

    switch (this.fieldId) {
      case MasterFields.ZIPCode:
      case MasterFields.PaymentSource:
      case MasterFields.LivingIn:
      case MasterFields.FamilyIncome:
      {
        this.showRenew = true;
      }
    }

    this.expireLabel = "";
    this.expired = false;    
    this.showLabel = !!requiredField && !!this.lastUpdated;    //  && (this.lastUpdated instanceof Date && !isNaN(this.lastUpdated ));
    if (!!requiredField && !!this.lastUpdated) {
      var toExpire = addDays(this.helperClassService.castToDate(this.lastUpdated), requiredField.expiresDays);
      if (toExpire instanceof Date && !isNaN(toExpire.valueOf())){
          if (compareAsc(toExpire, this.currentDate) > 0) {
            this.expireLabel = "Expires on " + format(this.helperClassService.castToDateWithNoTime(toExpire), 'MM/dd/yyyy');
          }
          else {
            this.expireLabel = "Expired on " + format(this.helperClassService.castToDateWithNoTime(toExpire), 'MM/dd/yyyy');
            this.expired = true;
          }
      }
      else {
        this.showLabel = false;
      }
    }
    this.isFieldExpired.emit({ fieldId: this.fieldId, expired: this.expired });
  }

  onRenew() {
    this.renewValue.emit(this.fieldId)
  }

}
