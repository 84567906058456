import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { RecertificationErrors } from "src/app/models/enums";
import { ClientFormService } from '../services/client-form.service';


@Component({
  selector: 'app-certification-popup',
  templateUrl: './certification-popup.component.html',
  styleUrls: ['./certification-popup.component.scss']
})
export class CertificationPopupComponent implements OnInit {

  params: any = {};
  showExpiredZipCodeCheck: boolean= false;
  showExpiredFPLCheck: boolean= false;
  showExpiredInsuranceCheck: boolean= false;

  constructor(public  clientService: ClientFormService, 
              private diagConfig: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.params = this.diagConfig.data;
    this.showExpiredZipCodeCheck = (RecertificationErrors.ZipCode & this.params.certificationAlert) == RecertificationErrors.ZipCode;
    this.showExpiredFPLCheck = (RecertificationErrors.FPL & this.params.certificationAlert) == RecertificationErrors.FPL;
    this.showExpiredInsuranceCheck = (RecertificationErrors.Insurance & this.params.certificationAlert) == RecertificationErrors.Insurance;

  }

}
