import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { parseISO } from 'date-fns';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { noop } from 'rxjs';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { DateHelper } from "src/app/common/date-helper";
import { ServiceCategory } from 'src/app/models/common-data.model';
import { NonRWVisit, NonRWVisitParams } from 'src/app/models/mcm.models';
import { CommonDataService } from 'src/app/services/common-data.service';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { IdleService } from 'src/app/services/idle-service.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { MCMService } from '../mcm.service';

@Component({
  selector: 'app-non-rwvisit',
  templateUrl: './non-rwvisit.component.html',
  styleUrls: ['./non-rwvisit.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class NonRWVisitComponent implements OnInit {

  formNonRWVisit:UntypedFormGroup;
  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  serviceCategories: ServiceCategory[];
  //serviceId = 0;
  subTypes: any[] = [];
  params: Partial<NonRWVisitParams> = {};
  displayNonRWVisit= false;

  getGuid = () => this.formNonRWVisit?.get('guid');
  getClientGuid = () => this.formNonRWVisit?.get('clientGuid');
  getLocation = () => this.formNonRWVisit?.get('location');
  getVisitDate = () => this.formNonRWVisit?.get('visitDate');
  getServiceID = () => this.formNonRWVisit?.get('serviceId');
  getSubID = () => this.formNonRWVisit?.get('subId');
  getNotes = () => this.formNonRWVisit?.get('notes');
  getCohortIntervention = () => this.formNonRWVisit?.get('cohortIntervention');
  getUserId = () => this.formNonRWVisit?.get('userId');

  constructor(private fb: UntypedFormBuilder, 
              private mcmService:MCMService,
              private commonDataService:CommonDataService,
              private providerService: ProviderService,
              private userService:UserService,
              public  clientService:ClientFormService,
              private datePipe: DatePipe,
              private dateHelper:DateHelper,
              public  validationHelper:FormValidations,
              private cdRef:ChangeDetectorRef,
              private helperService: HelperClassService,
              private diagConfig: DynamicDialogConfig,
              private ref: DynamicDialogRef, 
              private idleService: IdleService,
              private messageService:MessageService
    ) {

    this.formNonRWVisit = fb.group({
      guid: ['00000000-0000-0000-0000-000000000000'],
      clientGuid: ['00000000-0000-0000-0000-000000000000'],
      location:[null,[validationHelper.requiredFieldValidator()]],
      visitDate: [this.currentDate, [Validators.required, validationHelper.dateFutureDateValidator()]],
      serviceId: [0, [validationHelper.requiredFieldValidator()]],
      subId: [0, [validationHelper.requiredFieldValidator()]],
      notes: [''],
      cohortIntervention: [0],
      userId: [0]
    },
    {
    });
    
    //Default Values 
    var includedServiceIds: number[] = [81,83,85,86]; // PM, Dental, MH, SA
    this.serviceCategories = this.commonDataService.getServiceCategories(0,true,includedServiceIds);
    this.getClientGuid().setValue(this.clientService.clientData.guid);

    this.params = this.diagConfig.data;

    if (this.params.guid)
        this.getVisit(this.params.guid);
    else{
      this.getCohortIntervention().setValue(2);
    }
 
       // If app times out, close report dialog 
    this.idleService.idle.onTimeout.subscribe(() => {
        this.ref.close();
    });
  }

  ngOnInit(): void {
  }

  getVisit(guid){
        this.mcmService.getNonRWVisit(this.params.guid).subscribe({
            next:(result:NonRWVisit)=> {
                this.getLocation().setValue(result.location);
                this.getClientGuid().setValue(result.clientGuid);
                this.getVisitDate().setValue(parseISO(result.visitDate));
                this.getServiceID().setValue(result.serviceId);
                this.subTypes = this.commonDataService.getSubTypesByService(result.serviceId, 0, true, false);
                this.getSubID().setValue(result.subId);
                this.getNotes().setValue(result.memo);
                this.getCohortIntervention().setValue(result.cohortIntervention);
                this.getClientGuid().setValue(result.clientGuid);
                this.getGuid().setValue(result.guid);
                this.formNonRWVisit.markAsPristine();
                if (this.clientService.readonly)  this.formNonRWVisit.disable();
          
            },
            error:(error) => {  
              console.log(error);

            },
            complete:()=> {
            }
        });
  }

  onChangeServiceType(serviceId){
    if (this.getServiceID().value == 81){
      // For PM only show presciring subtypes 
      var unfilteredSubTypes =  this.commonDataService.getSubTypesByService(serviceId, 0, true, false);
      this.subTypes = unfilteredSubTypes.filter(x=> x.prescribingPrivilege == true); 
    }
    else
      this.subTypes = this.commonDataService.getSubTypesByService(serviceId, 0, true, false);
  }

  onChangeSubType(event){

  }

  onChangeInterventionOption(result){

  }

  onHideDialog(event){
    
    event.preventDefault(); // Stop Form from posting , Needed for Dynamic Dialog Buttom 

    try { this.ref.close(true);}
    catch(err){
      console.log(err);
    }
    //this.ref.destroy();
  }

  onSaveChangesNoConfirm(event: Event) {

     this.onSaveChanges().then(value => { 
        //this.onHideDialog(); 
       // this.cancel();
      })
      .catch((error) => { alert(error); });
                        
                        //   // this.confirmationService.confirm({
  //   //   target: event.target,
  //   //   message: "Are you sure that you want to proceed?",
  //   //   icon: "pi pi-info-circle",
  //   //   accept: () => {
  //   //     this.onSaveChanges().then(value => {
  //   //       this.messageService.add({
  //   //         severity: "info",
  //   //         summary: "Confirmed",
  //   //         detail: "Changes Saved",
  //   //         life: 2000
  //   //       });         
  //   //     });
  //   //   },
  //   //   reject: () => {
  //   //     this.messageService.add({
  //   //       severity: "error",
  //   //       summary: "Aborted",
  //   //       detail: "Changes Canceled",
  //   //       life: 2000
  //   //     });
  //   //   }
  //   // });
  }


  async onSaveChanges() {
    if (!this.formNonRWVisit.dirty) {
      return;
    }
    else if (this.formNonRWVisit.valid) {
      let guid = this.getGuid().value;
      var model:Partial<NonRWVisit>= {};
      model.guid = guid;
      model.clientGuid  = this.getClientGuid().value;
      model.visitDate = this.getVisitDate().value;   //this.helperService.castToDate(value)
      model.currentProvider = false;
      model.serviceId = this.getServiceID().value;
      model.subId = this.getSubID().value;
      model.userId = this.userService.userID;
      model.location = this.getLocation().value;
      model.memo = this.getNotes().value;
      model.cohortIntervention = this.getCohortIntervention().value;
      this.mcmService.saveNonRWVisit(model).subscribe({
          next:(model:NonRWVisit) => {
               
            this.messageService.add({
                        severity: "info",
                        summary: "Confirmed",
                        detail: "Changes Saved",
                        life: 2000
                      });         
          },
          error:(error)=> {
             this.helperService.displayError(this.messageService, "Fatal error occurred when saving non RW Visit. Please contact CHAMP support:", error, true);
          },
          complete:()=>{


          }
      })
    }
 }


  async onShowDialog(selectedDirectService: Partial<NonRWVisit>, allowEdit: boolean = false, forceSave: boolean = false) {
    // this.mainForm.reset();
    // this.allowEdit = allowEdit;
    // this.readOnly = !allowEdit;
    // this.forceSave = forceSave;
    // this.validRiskReduction = false;
    // this.selectedDirectService = selectedDirectService;
    // await this.loadRiskReduction();
    // // don't show the window unless at least one question is required
    // if (this.isAtLeastOneCounselingVisible()) {
    //   this.displayRiskReductionDialog = true;
    //   this.dialogCompleted.emit(this.getRiskReductionNotificationJSONMessage(false));
    // }
    // else {
    //   this.validRiskReduction = true;
    //   this.dialogCompleted.emit(this.getRiskReductionNotificationJSONMessage(true));
    // }
    // if (this.clientService.readonly)  this.mainForm.disable();
    this.displayNonRWVisit = true;

  
  }

  

}
