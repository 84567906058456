<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm" [ngClass]="{'disableDiv': !isPatientFemale()}">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-7">Female Only Section</div>
                <div class="field col-5"></div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <!-- PAP Smear section -->
            <div class="p-fluid p-formgrid grid"  style="padding: 0;" >
                <div class="field col-12" style="padding: 0;">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label for="idPAPScreened">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Did this patient receive a PAP Smear?
                            </label>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idPAPScreened" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idPAPScreened" value=1 label="Yes"></p-radioButton>
                        </div>
                        <div class="field col-4">
                            <label id="idPapSmearResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalFemalePapSmear"   [control]="medical.controls['idPAPScreened']"
                                [fieldId]="MasterFields.FemalePapSmearDate" [lastUpdated]="this.clientData.clientMedicalFemalePapSmear?.datetime"></app-client-expired-field>
                            </label> 
                        </div>
                        <div class="field col-1">
                            <button pButton pRipple type="button" label="History" style="color:#2196F3 !important;" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('PAP', 'PAP Smear')"></button>
                        </div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idPAPScreened']?.errors?.required">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-6">
                            <small id="idPAPScreened-help" class="p-invalid">PAP Smear is required.</small>
                        </div>
                        <div class="field col-6"></div>
                    </div>
                </div>                

                <div class="field col-12" style="padding: 0;" *ngIf="showPapSmearNoReason()">
                    <div id="PAPNoDiv"  class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idPAPScreenedNoReason">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Reason
                            </label>
                        </div>
                        <div class="field col-3">
                            <!-- <div class="p-grid"> interesting effect where it resizes combo to largest value but doesn't shrink properly-->
                                <p-dropdown formControlName="idPAPScreenedNoReason" [options]="papNoReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                        [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}" tooltip="{{getPapSmearScreenedNoReasonDescription()}}"> </p-dropdown>      
                            <!-- </div> -->
                        </div>
                        <div class="field col-4"></div>
                    </div>

                    <div class="field col-12" style="padding: 0;" *ngIf="showPapSmearNoReason() && medical.controls['idPAPScreenedNoReason']?.errors">
                        <div class="fluid formgrid grid champ-grid" >
                            <div class="field col-5"></div>
                            <div class="field col-3">
                                <small id="idPAPScreenedNoReason-help" class="p-invalid">Reason is required.</small>
                            </div>
                            <div class="field col-4"></div>
                        </div>
                    </div>                    
                    
                    <div class="field col-12" style="padding: 0;"  *ngIf="showPapSmearResultFields()">
                        <div id="PAPYesDiv" class="fluid formgrid grid champ-grid" >
                            <div class="field col-5">
                                <label htmlFor="idPAPScreenedDate">
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                    Date of Result
                                </label> 
                            </div>
                            <div class="field col-2">
                                <input type="text"  #papDatePicker  name="idPAPScreenedDate" autocomplete="off" bsDatepicker formControlName="idPAPScreenedDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idPAPScreenedDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idPAPScreenedDate'].invalid}" 
                                    class="champ-date-picker" pInputText/>
                            </div>
                            <div class="field col-5"></div>
                        </div>
                    </div>
                    
                    <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idPAPScreenedDate']?.invalid">
                        <div class="fluid formgrid grid champ-grid" >
                            <div class="field col-5"></div>
                            <div class="field col-2">
                                <div *ngIf="showPapSmearResultFields() && medical.controls['idPAPScreenedDate']?.errors?.required"> 
                                    <small id="idPAPScreenedDate-help" class="p-invalid">Result Date is required.</small>
                                </div>
                                <div *ngIf="!medical.controls['idPAPScreenedDate']?.errors?.required && !!medical.controls['idPAPScreenedDate']?.errors"> 
                                    <small id="idPAPScreenedDate2-help" class="p-invalid">{{medical.controls['idPAPScreenedDate']?.errors['invalidDate']}}</small>
                                </div>
                            </div>
                            <div class="field col-5"></div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- End of PAP Smear section -->

            <!-- Pelvic Exam section -->
            <div class="fluid formgrid grid">
                <div class="field col-12" style="padding: 0;">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label for="idPelvicScreened">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Did this patient receive a Pelvic Exam?
                            </label>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idPelvicScreened" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idPelvicScreened" value=1 label="Yes"></p-radioButton>
                        </div>
                        <div class="field col-4">
                            <label id="idPelvicExamResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalFemalePelvicExam" [control]="medical.controls['idPelvicScreened']"
                                  [fieldId]="MasterFields.FemalePelvicExamDate" [lastUpdated]="this.clientData.clientMedicalFemalePelvicExam?.datetime"></app-client-expired-field>
                            </label> 
                        </div>
                        <div class="field col-1">
                            <button pButton pRipple type="button" label="History" style="color:#2196F3 !important;" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('PELVIC', 'Pelvic Exam')"></button>
                        </div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idPelvicScreened']?.errors?.required">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-6">
                            <small id="idPelvicScreened-help" class="p-invalid">Pelvic Exam is required.</small>
                        </div>
                        <div class="field col-6"></div>
                    </div>
                </div>                

                <div class="field col-12" style="padding: 0;" *ngIf="showPelvicExamNoReason()">
                    <div id="PelvicNoDiv"  class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idPelvicScreenedNoReason">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Reason
                            </label>
                        </div>
                        <div class="field col-3">
                            <!-- <div class="field grid"> -->
                            <p-dropdown formControlName="idPelvicScreenedNoReason" [options]="pelvicNoReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                    [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}" tooltip="{{getPelvicExamScreenedNoReasonDescription()}}"> </p-dropdown>
                            <!-- </div> -->
                        </div>
                        <div class="field col-4"></div>                        
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="showPelvicExamNoReason() && medical.controls['idPelvicScreenedNoReason']?.errors">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-3">
                            <small id="idPelvicScreenedNoReason-help" class="p-invalid">Reason is required.</small>
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>                
                
                <div class="field col-12" style="padding: 0;" *ngIf="showPelvicExamResultFields()">
                    <div id="PelvicYesDiv"  class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idPelvicScreenedDate">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Date of Result
                            </label> 
                        </div>
                        <div class="field col-2">
                            <input type="text"  #pelvicDatePicker  name="idPelvicScreenedDate" autocomplete="off" bsDatepicker formControlName="idPelvicScreenedDate"
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idPelvicScreenedDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate"
                                placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idPelvicScreenedDate'].invalid}" 
                                class="champ-date-picker" pInputText/>
                        </div>
                        <div class="field col-5"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="showPelvicExamResultFields()  && medical.controls['idPelvicScreenedDate']?.invalid">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-2">
                            <div *ngIf="showPelvicExamResultFields() && medical.controls['idPelvicScreenedDate']?.errors?.required"> 
                                <small id="idPelvicScreenedDate-help" class="p-invalid">Result Date is required.</small>
                            </div>
                            <div *ngIf="!medical.controls['idPelvicScreenedDate']?.errors?.required && !!medical.controls['idPelvicScreenedDate']?.errors"> 
                                <small id="idPelvicScreenedDate2-help" class="p-invalid">{{medical.controls['idPelvicScreenedDate']?.errors['invalidDate']}}</small>
                            </div>   
                        </div>
                        <div class="field col-5"></div>
                    </div>
                </div>                
            </div>
            <!-- End of Pelvic Exam section -->

            <!-- Pregnant section -->
            <div class="fluid formgrid grid">
                <div class="field col-12" style="padding: 0;">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label for="idPregnancyScreened">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Was the patient pregnant?
                            </label>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idPregnancyScreened" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idPregnancyScreened" value=1 label="Yes"></p-radioButton>
                        </div>
                        <div class="field col-4">
                            <label id="idPregnancyResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalFemalePregnancy" [control]="medical.controls['idPregnancyScreened']"
                                 [fieldId]="MasterFields.FemalePregnantDate" [lastUpdated]="this.clientData.clientMedicalFemalePregnancy?.datetime"></app-client-expired-field>
                            </label> 
                        </div>
                        <div class="field col-1">
                            <button pButton pRipple type="button" label="History" style="color:#2196F3 !important;" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('PREG', 'Pregnancy Exam')"></button>
                        </div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idPregnancyScreened']?.errors?.required">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-6">
                            <small id="idPregnancyScreened-help" class="p-invalid">Pregnancy Exam is required.</small>
                        </div>
                        <div class="field col-6"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;">
                    <div id="pregNoDiv" class="fluid formgrid grid" *ngIf="showPregnancyTrimester()">
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="showPregnancyResultFields()">
                    <div id="pregYesDiv"  class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idPregnancyScreenedDate">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Date of Result
                            </label>
                        </div>
                        <div class="field col-2">
                            <input type="text" #pregnancyDatePicker name="idPregnancyScreenedDate" autocomplete="off" bsDatepicker formControlName="idPregnancyScreenedDate"
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idPregnancyScreenedDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate"
                                placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idPregnancyScreenedDate'].invalid}" 
                                class="champ-date-picker" pInputText/>
                        </div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="showPregnancyResultFields() && medical.controls['idPregnancyScreenedDate']?.invalid">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-2">
                            <div *ngIf="showPregnancyResultFields() && medical.controls['idPregnancyScreenedDate']?.errors?.required"> 
                                <small id="idPregnancyScreenedDate-help" class="p-invalid">Result Date is required.</small>
                            </div>
                            <div *ngIf="!medical.controls['idPregnancyScreenedDate']?.errors?.required && !!medical.controls['idPregnancyScreenedDate']?.errors"> 
                                <small id="idPregnancyScreenedDate2-help" class="p-invalid">{{medical.controls['idPregnancyScreenedDate']?.errors['invalidDate']}}</small>
                            </div> 
                        </div>
                        <div class="field col-5"></div>
                     </div>                
                </div>
                
                <div class="field col-12" style="padding: 0;" *ngIf="showPregnancyResultFields()">
                    <div  class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idPregnancyTrimester">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Trimester
                            </label>
                        </div>
                        <div class="field col-3">
                            <p-dropdown formControlName="idPregnancyTrimester" [options]="trimesterReasons" optionLabel="description" optionValue="id" appendTo="body" 
                            [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}" tooltip="{{getPregnancyTrimesterDescription()}}"> </p-dropdown> 
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="showPregnancyResultFields() && medical.controls['idPregnancyTrimester']?.errors">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-3">
                            <small id="idPregnancyTrimester-help" class="p-invalid">Trimester is required.</small>
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>
            </div>
            <!-- End of Pregnant section -->

            <!-- Antiretroviral Prevention section -->
            <div class="fluid formgrid grid">
                <div class="field col-12" style="padding: 0;">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label for="idAntiPrevScreened">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Did the patient receive <label pTooltip="{{getAntiretroviralPreventionDescription()}}">ART</label> to prevent transmission of HIV to her children?
                            </label>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idAntiPrevScreened" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idAntiPrevScreened" value=1 label="Yes"></p-radioButton>
                        </div>
                        <div class="field col-4">
                            <label id="idAntiPrevResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalAntiretroviralPrevention" [control]="medical.controls['idAntiPrevScreened']"
                                 [fieldId]="MasterFields.FemaleAntiRetroPreventionTherapyDate" [lastUpdated]="this.clientData.clientMedicalAntiretroviralPrevention?.datetime"></app-client-expired-field>
                            </label> 
                        </div>
                        <div class="field col-1">
                            <button pButton pRipple type="button" label="History" style="color:#2196F3 !important;" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('ANTIPREV', 'Antiretroviral Prevention')"></button>
                        </div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idAntiPrevScreened']?.errors?.required">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-6">
                            <small id="idAntiPrevScreened-help" class="p-invalid" style="margin-left: 1rem">Antiretroviral Therapy is required.</small>
                        </div> 
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;">
                    <div id="AntiPrevNoDiv" *ngIf="showAntiretroviralPreventionNoReason()" class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idAntiPrevScreenedNoReason">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                Reason
                            </label>
                        </div>
                        <div class="field col-3">
                            <p-dropdown formControlName="idAntiPrevScreenedNoReason" [options]="anitRetroNoReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                    [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}" tooltip="{{getAntiretroviralPreventionScreenedNoReasonDescription()}}"> </p-dropdown>      
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="showAntiretroviralPreventionNoReason() && medical.controls['idAntiPrevScreenedNoReason']?.errors">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-3">
                            <small id="idAntiPrevScreenedNoReason-help" class="p-invalid">Reason is required.</small>
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>                

                <div class="field col-12" style="padding: 0;" *ngIf="showAntiretroviralPreventionResultFields()">
                    <div id="AntiPrevYesDiv"  class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idAntiPrevScreenedDate">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                Date of Result
                            </label> 
                        </div>
                        <div class="field col-2">
                            <input type="text" #AntiPrevDatePicker name="idAntiPrevScreenedDate" autocomplete="off" bsDatepicker formControlName="idAntiPrevScreenedDate"
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idAntiPrevScreenedDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate"
                            placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idAntiPrevScreenedDate'].invalid}" 
                            class="champ-date-picker" pInputText/>
                        </div>
                        <div class="field col-5"></div>
                    </div>                
                </div>                    
                    
                <div class="field col-12" style="padding: 0;" *ngIf="showAntiretroviralPreventionResultFields() && medical.controls['idAntiPrevScreenedDate']?.invalid">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-2">
                            <div *ngIf="showAntiretroviralPreventionResultFields() && medical.controls['idAntiPrevScreenedDate']?.errors?.required"> 
                                <small id="idAntiPrevScreenedDate-help" class="p-invalid" style="margin-left: 1rem;">Result Date is required.</small>
                            </div>
                            <div *ngIf="!medical.controls['idAntiPrevScreenedDate']?.errors?.required && !!medical.controls['idAntiPrevScreenedDate']?.errors"> 
                                <small id="idAntiPrevScreenedDate2-help" class="p-invalid" style="margin-left: 1rem;">{{medical.controls['idAntiPrevScreenedDate']?.errors['invalidDate']}}</small>
                            </div>
                        </div>
                        <div class="field col-5"></div>
                    </div>
                </div>
            </div>
            <!-- End of Antiretroviral Prevention section -->

            <!-- Birth Results section -->
            <div class="fluid formgrid grid">
                <div class="field col-12" style="margin-right: 10px;">
                    <label>Please list birthdates and results of pregnancies.</label>
                    <!-- <p-toolbar styleClass="mb-4"> style="font-weight:normal;"
                        <ng-template pTemplate="left">
                            <button pButton pRipple label="New" icon="pi pi-plus" class="btn btn-primary mr-2" (click)="onRowAdd(dt)" style="width: 5rem !important; height: 2rem !important; padding: .5rem"></button>
                        </ng-template>
                    </p-toolbar>                     -->
                    <p-table #dtBirthResults [value]="getClientMedicalFemaleBirthResults()" dataKey="guid" editMode="row" selectionMode="single">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:8rem"><button pButton pRipple label="New" icon="pi pi-plus" class="btn btn-primary mr-2" (click)="onRowAdd(dtBirthResults)" *ngIf="!clientService.readonly"  [disabled]="isRowInEditMode" style="width: 5rem !important; height: 2rem !important; padding: .5rem"></button></th>                                
                                <th>Gender</th>
                                <th>Birthdate</th>
                                <th>HIV Status</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-clientMedicalFemaleBirthResult let-editing="editing" let-rowIndex="rowIndex">
                            <tr [pEditableRow]="clientMedicalFemaleBirthResult">
                                <td style="text-align:center">
                                    <button *ngIf="!isRowInEditMode && !clientService.readonly" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit(clientMedicalFemaleBirthResult, rowIndex)" class="p-button-rounded p-button-text"></button>
                                    <button *ngIf="isRowInEditMode && rowIndexInEditMode == rowIndex && validBirthResults() && !clientService.readonly" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave(clientMedicalFemaleBirthResult, rowIndex)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                    <button *ngIf="isRowInEditMode && rowIndexInEditMode == rowIndex && !clientService.readonly" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel(clientMedicalFemaleBirthResult, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    <button *ngIf="!isRowInEditMode && !clientService.readonly" pButton pRipple type="button" icon="pi pi-trash" (click)="onRowDelete($event, clientMedicalFemaleBirthResult, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>                                
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isBirthResultsRowInEditMode(rowIndex)">
                                                <div [ngClass]="{'disableDiv': !isRowInEditMode}">
                                                    <p-dropdown [options]="genders" optionLabel="label" optionValue="value" formControlName="idBirthResultsGender" [style]="{'width':'100%'}" appendTo="body" ></p-dropdown>
                                                </div>
                                            </div>

                                            <div *ngIf="!isBirthResultsRowInEditMode(rowIndex)">
                                                {{ getGenderDescription(clientMedicalFemaleBirthResult.genderId) }}
                                            </div>

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ getGenderDescription(clientMedicalFemaleBirthResult.genderId) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isBirthResultsRowInEditMode(rowIndex)">
                                                <div [ngClass]="{'disableDiv': !isRowInEditMode}">
                                                    <input type="text" autocomplete="off" bsDatepicker formControlName="idBirthResultsBirthDate"
                                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" [maxDate]="maxScreenedDate"
                                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idBirthResultsBirthDate'].invalid}" 
                                                    class="champ-date-picker" pInputText/>
                                                </div>

                                                <div *ngIf="medical.controls['idBirthResultsBirthDate']?.invalid">
                                                    <div *ngIf="medical.controls['idBirthResultsBirthDate']?.errors?.required"> 
                                                        <small id="idBirthResultsBirthDate-help" class="p-invalid">Birthdate is required.</small>
                                                    </div>
                                                    <div *ngIf="!medical.controls['idBirthResultsBirthDate']?.errors?.required && !!medical.controls['idBirthResultsBirthDate']?.errors"> 
                                                        <small id="idBirthResultsBirthDate-help" class="p-invalid">{{medical.controls['idBirthResultsBirthDate']?.errors['invalidDate']}}</small>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="!isBirthResultsRowInEditMode(rowIndex)">
                                                {{ getBirthResultsBirthDateValue(clientMedicalFemaleBirthResult.birthDate) }}
                                            </div>

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ getBirthResultsBirthDateValue(clientMedicalFemaleBirthResult.birthDate) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isBirthResultsRowInEditMode(rowIndex)">
                                                <div [ngClass]="{'disableDiv': !isRowInEditMode}">
                                                    <p-dropdown [options]="hivStatuses" optionLabel="label" optionValue="value" formControlName="idBirthResultsHIVStatus" [style]="{'width':'100%'}" appendTo="body"></p-dropdown>
                                               </div>
                                            </div>

                                            <div *ngIf="!isBirthResultsRowInEditMode(rowIndex)">
                                                {{ getBirthResultsBirthDateValue(clientMedicalFemaleBirthResult.hivStatusId) }}
                                            </div>

                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ getHIVStatusDescription(clientMedicalFemaleBirthResult.hivStatusId) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>                    
                </div>
            </div>
            <!-- End of Birth Results section -->
        </div>
    </div>
</form>

<app-history ></app-history>