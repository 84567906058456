<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]='fg' autocomplete="off">
    <p-dialog [(visible)]="showDialog" header="Client Referral Ticket" (onHide)="onHide($event)" [modal]="true" [closable]="true" position="center"
            [draggable]="false" showEffect="explode" [style]="{'width': '60%', 'height': '75%'}" [contentStyle]="{'overflow-y': 'clip'}">
            <!-- [resizable]="false"  -->
        <div class="grid">
            <div class="field col-6">
                <div class="formgroup-inline chamformgroup-inline" style="width: 90%;">
                    <div class="field col-1" style="width: 150px;">
                        <b><p-radioButton formControlName="serviceOption" value=0 label="Referred Services" (click)="onChangeServiceOption(0)"></p-radioButton></b>
                    </div>
                    <div class="field col-1" style="width: 150px;">
                        <b><p-radioButton formControlName="serviceOption" value=1 label="All Services" (click)="onChangeServiceOption(1)"></p-radioButton></b>
                    </div>
                </div>
                <!-- style="width: 100vw; height: 100vh; z-index: 9999;" style="width: 60vw; height: 60vh;" src="assets/pdfs/ClientReferralTicket.pdf" -->
                <div class="container-fluid" style="height: 600px">
                    <ng2-pdfjs-viewer #pdfViewer
                    [zoom]="'page-width'"
                    [downloadFileName]="'ClientReferralTicket.pdf'">
                    </ng2-pdfjs-viewer>
                </div>
            </div>
            <div class="field col-6">
                <div class="grid">
                    <div class="grid">
                        <div class="col-10">
                            <label style="margin-top: 1.5rem; margin-bottom: 0.5rem; width: 15rem;"><b>Referral Workflow Process :</b></label>
                        </div>
                    </div>

                    <!-- Step 1 -->
                    <div class="grid" [ngClass]="{'disableDiv': canProvideServicePanelDisabled()}">
                        <div class="col-10" style="margin-left: 1rem;">
                            <label><b>Step 1 :</b></label>
                        </div>
                        <div class="col-10" style="margin-left: 4rem;">
                            <b><label for="canProvideService">Can you provide this service to the client?</label></b>
                            <!-- <div *ngIf="!!fg.errors?.invalidcanProvideService">
                                <small id="canProvideService-help" class="p-invalid">{{fg.errors?.invalidcanProvideService}}</small>
                            </div> -->
                        </div>
                        <div class="col-10" style="margin-left: 4rem;">
                            <div class="formgroup-inline chamformgroup-inline">
                                <div class="field col-1" [style]="{'width': '4rem'}">
                                    <b><p-radioButton formControlName="canProvideService" value=1 label="Yes" (click)="onChangeCanProvideServiceYesNo(1)"></p-radioButton></b>
                                </div>
                                <div class="field col-1">
                                    <b><p-radioButton formControlName="canProvideService" value=2 label="No" (click)="onChangeCanProvideServiceYesNo(2)"></p-radioButton></b>
                                </div>
                                <div class="field col-2" *ngIf="canProvideRefusalVisible()">
                                    <b><label for="canProvideRefusal" style="margin-left: 1.5rem; margin-top: .125rem; width: 6rem;">Why not ?</label></b>
                                </div>
                                <div class="field col-5" *ngIf="canProvideRefusalVisible()">
                                    <p-dropdown formControlName="canProvideRefusal" [options]="canProvideRefusalList" optionLabel="description" optionValue="id" appendTo="body"></p-dropdown>
                                    <div *ngIf="fg.controls['canProvideRefusal'].errors">
                                        <small id="canProvideRefusal-help" class="p-invalid">*Reason is required.</small>
                                    </div>                                     
                                </div>
                            </div>
                        </div>
                    </div>                    

                    <!-- Step 2 -->
                    <div class="grid" [ngClass]="{'disableDiv': contactedReferrerPanelDisabled()}">
                        <div class="col-10" style="margin-left: 1rem;">
                            <label><b>Step 2 :</b></label>
                        </div>
                        <div class="col-10" style="margin-left: 4rem;">
                            <b><label for="contactedReferrer">Please contact referrer to arrange the details of the services(s) needed.</label></b>
                            <!-- <div *ngIf="!!fg.errors?.invalidcontactedReferrer">
                                <small id="contactedReferrer-help" class="p-invalid">{{fg.errors?.invalidcontactedReferrer}}</small>
                            </div> -->
                        </div>
                        <div class="col-10" style="margin-left: 4rem;">
                            <div class="formgroup-inline chamformgroup-inline">
                                <b><span style="vertical-align:text-top"><label htmlFor="contactedReferrer" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Have you contacted the Referrer?</label></span></b>
                                <!-- <div class="field-checkbox"> -->
                                    <b><p-checkbox [style]="{'margin-left': '.5rem', 'margin-top': '.25rem'}" formControlName="contactedReferrer" binary="true" inputId="contactedReferrer" (onChange)="onChangeContactedReferrer($event.checked)"></p-checkbox></b>
                                <!-- </div> -->
                                <b><span style="vertical-align:text-top"><label htmlFor="contactedReferrer" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Yes</label></span></b>
                                
                                <b><span style="vertical-align:text-top"><label htmlFor="contactedReferrerDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem; margin-left: 2rem;margin-right: .25rem;">Date:</label></span></b>
                                <div>
                                    <input type="text" name="contactedReferrerDateName" autocomplete="off" bsDatepicker formControlName="contactedReferrerDate"
                                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="contactedReferrerDateID"    
                                            placeholder="01-01-1900"
                                            [ngClass]="{'p-invalid' : fg.controls['contactedReferrerDate'].invalid}"
                                            class="champ-date-picker" pInputText style="z-index: 1000 !important;"/>

                                    <div *ngIf="fg.controls['contactedReferrerDate'].invalid && (fg.controls['contactedReferrerDate'].dirty || fg.controls['contactedReferrerDate'].touched)">
                                        <div *ngIf="fg.controls['contactedReferrerDate'].errors.required">
                                            <small id="contactedReferrerDate-help" class="p-invalid">*Date is required.</small>
                                        </div>
                                        <div *ngIf="fg.controls['contactedReferrerDate'].errors"> 
                                            <small id="contactedReferrerDate-help" class="p-invalid">{{fg.controls['contactedReferrerDate'].errors['invalidDate']}}</small>
                                        </div>                             
                                    </div>                                            
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Step 3 -->
                    <div class="grid" [ngClass]="{'disableDiv': willProvideServicePanelDisabled()}">
                        <div class="col-10" style="margin-left: 1rem">
                            <label><b>Step 3 :</b></label>
                        </div>
                        <div class="col-10" style="margin-left: 4rem">
                            <b><label for="willProvideService">Will your agency provide this service?</label></b>
                            <!-- <div *ngIf="!!fg.errors?.invalidwillProvideService">
                                <small id="willProvideService-help" class="p-invalid">{{fg.errors?.invalidwillProvideService}}</small>
                            </div> -->
                        </div>
                        <div class="col-10" style="margin-left: 4rem">
                            <div class="formgroup-inline chamformgroup-inline">
                                <div class="field col-1" [style]="{'width': '4rem'}">
                                    <b><p-radioButton formControlName="willProvideService" value=1 label="Yes" (click)="onChangeWillProvideServiceYesNo(1)"></p-radioButton></b>
                                </div>
                                <div class="field col-1">
                                    <b><p-radioButton formControlName="willProvideService" value=2 label="No" (click)="onChangeWillProvideServiceYesNo(2)"></p-radioButton></b>
                                </div>
                                <div class="field col-2" *ngIf="willProvideRefusalVisible()">
                                    <b><label for="willProvideRefusal" style="margin-left: 1.5rem; margin-top: .125rem; width: 6rem;">Why not ?</label></b>
                                </div>
                                <div class="field col-5" *ngIf="willProvideRefusalVisible()">
                                    <p-dropdown formControlName="willProvideRefusal" [options]="willProvideRefusalList" optionLabel="description" optionValue="id" appendTo="body"></p-dropdown>
                                    <div *ngIf="fg.controls['willProvideRefusal'].errors">
                                        <small id="willProvideRefusal-help" class="p-invalid">*Reason is required.</small>
                                    </div>                                    
                                </div>                            
                            </div>
                        </div>
                    </div>

                    <!-- Step 4 -->
                    <div class="grid" [ngClass]="{'disableDiv': agreeProvideServicePanelDisabled()}">
                        <div class="col-10" style="margin-left: 1rem">
                            <label><b>Step 4 :</b></label>
                        </div>
                        <div class="col-10" style="margin-left: 4rem">
                            <div class="formgroup-inline chamformgroup-inline">
                                <div class="field col-1" style="width: 1rem;">
                                    <b><p-checkbox [style]="{'margin-top': '.25rem'}" formControlName="agreeProvideService" binary="true" inputId="agreeProvideService" (onChange)="onChangeAgreeProvideService($event.checked)"></p-checkbox></b>
                                </div>
                                <div class="field col-9">
                                    <label htmlFor="agreeProvideService" style="margin-top: 0.125rem; word-wrap: break-word;">
                                        <b>By clicking here you are agreeing to provide the service described on this screen to the client. Client details will be downloaded to your agency and appear in your client list after you save this referral.</b>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-10" >
                        <hr class="hrStyle">
                    </div>

                    <!-- Completetion -->
                    <div class="grid" [ngClass]="{'disableDiv': completionPanelDisabled()}">
                        <div class="col-10" style="margin-left: 1rem">
                            <div class="formgroup-inline chamformgroup-inline">
                                <div class="field col-1">
                                    <label><b>Completion</b></label>
                                </div>
                                <div class="field col-8" style="word-wrap: break-word;margin-left: 2rem">
                                    <label>To be completed by referrer or automatically for RW funded referred services when the services are delivered.</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-10" style="margin-left: 4rem">
                            <div class="formgroup-inline chamformgroup-inline">
                                <b><span style="vertical-align:text-top"><label htmlFor="completed" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Has this referred service been delivered?</label></span></b>
                                <b><p-checkbox [style]="{'margin-left': '.5rem', 'margin-top': '.25rem'}" formControlName="completed" binary="true" inputId="completed" (onChange)="onChangecompleted($event.checked)"></p-checkbox></b>
                                <b><span style="vertical-align:text-top"><label htmlFor="completed" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Yes</label></span></b>
                                
                                <b><span style="vertical-align:text-top"><label htmlFor="completedDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem; margin-left: 2rem;margin-right: .25rem;">Date:</label></span></b>
                                <div>
                                    <input type="text" name="completedDateName" autocomplete="off" bsDatepicker formControlName="completedDate"
                                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="completedDateID"    
                                            placeholder="01-01-1900"
                                            [ngClass]="{'p-invalid' : fg.controls['completedDate'].invalid}"
                                            class="champ-date-picker" pInputText style="z-index: 1000 !important;"/>

                                    <div *ngIf="fg.controls['completedDate'].invalid && (fg.controls['completedDate'].dirty || fg.controls['completedDate'].touched)">
                                        <div *ngIf="fg.controls['completedDate'].errors.required">
                                            <small id="completedDate-help" class="p-invalid">*Date is required.</small>
                                        </div>
                                        <div *ngIf="fg.controls['completedDate'].errors"> 
                                            <small id="completedDate-help" class="p-invalid">{{fg.controls['completedDate'].errors['invalidDate']}}</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-10" style="margin-left: 4rem">
                            <div class="formgroup-inline chamformgroup-inline">
                                <label><b>Note</b></label>
                                <textarea rows="5" cols="65" pInputTextarea formControlName="completedNotes" [style]="{'margin-left': '.5rem', 'height': '3rem'}"></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- After Completetion -->
                    <div class="col-10">
                        <hr class="hrStyle">
                    </div>

                    <!-- Save & Close -->
                    <div class="col-12">
                        <div class="flex justify-content-end">
                            <div class="flex align-content-end">
                                <p-button pButton pRipple type="button" [ngStyle]="{'width':'10rem'}" styleClass="p-button-text" class="p-button-text mr-2"
                                        (click)="onSaveConfirm($event)" [disabled]="!(fg.valid && fg.dirty)"> 
                                    <i class="fa-sharp fa-plus fa-lg"></i> <span>Save</span>
                                </p-button>       

                                <p-button pButton pRipple type="button" [ngStyle]="{'width':'10rem'}" styleClass="p-button-text" class="p-button-text mr-2"
                                        (click)="onHide($event)"> 
                                    <i class="fa-sharp fa-xmark fa-lg" style="color: red;"></i> <span>Cancel</span>
                                </p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </p-dialog>
</form>