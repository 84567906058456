import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy, DatePipe, PathLocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

// Firebase
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { provideAuth, getAuth } from '@angular/fire/auth';

// import { AngularFireModule } from "@angular/fire";
// import { AngularFireAuthModule } from "@angular/fire/auth";
// import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from '../environments/environment';
// Interceptors
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { BlobErrorHttpInterceptor } from './interceptors/error.interceptor';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipsModule} from 'primeng/chips';
//import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
//import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DropdownModule} from 'primeng/dropdown';
//import {FieldsetModule} from 'primeng/fieldset';
//import {FileUploadModule} from 'primeng/fileupload';
//import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
//import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {RadioButtonModule} from 'primeng/radiobutton';
//import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
//import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';
import {ConfirmPopupModule} from "primeng/confirmpopup";
import {ConfirmationService, MessageService} from "primeng/api";

// Application Components
import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {AboutComponent} from './pages/about.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './login/app.login.component';


// Font Awesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome'
import { faSquare, faCheckSquare, faCircle, faCheckCircle, faCertificate, faQuestionCircle, faRedoAlt, faRedo,
         faCog, faAsterisk, faExclamationCircle, faStar, faNotesMedical, faCheck, faTimes, faSync } from '@fortawesome/free-solid-svg-icons';
import { faSquare as farSquare, faCheckSquare as farCheckSquare, faSun as farSun,  
         faCircle as farCircle, faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { faStackOverflow, faGithub, faMedium } from '@fortawesome/free-brands-svg-icons';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive'; // this includes the core NgIdleModule but includes keepalive providers for easy wireup


// Application services
import {MenuService} from './app.menu.service';

// Champ 
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
//import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
//import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfJsViewerComponent, PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { SpinnerComponent } from './common/spinner/spinner.component';
import { AppLogoutComponent } from './login/app.logout.component';
import { SpinnerService } from './common/spinner/spinner.service';
import { AppProviderInfoComponent} from './app.provider.component';
import { DemograhicsComponent } from './client-form/tabs/demograhics/demographics.component';
import { IdentificationComponent } from './client-form/tabs/identification/identification.component';

// CHAMP Services
import { CommonDataService } from './services/common-data.service';
import { AuthService } from './auth/auth.service';
import { LoginService } from './login/services/login.service';

// CHAMP Components
import { ClientListComponent } from './client-list/client-list.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { ClientDirectServicesComponent } from './client-direct-services/client-direct-services.component';
import { DirectServiceComponent } from './client-direct-services/direct-service/direct-service.component';
import { SocioEconComponent } from './client-form/tabs/socio-econ/socio-econ.component';
import { MedicalComponent } from './client-form/tabs/medical/medical.component';
import { McmComponent } from './client-form/tabs/mcm/mcm.component';
import { BehaviorHealthComponent } from './client-form/tabs/behavior-health/behavior-health.component';
import { NotesComponent } from './client-form/tabs/notes/notes.component';
import { SwitchProviderComponent } from './menu/switch-provider/switch-provider.component';
import { Cd4Component } from './client-form/tabs/medical/cd4/cd4.component';
import { HepatitisBComponent } from './client-form/tabs/medical/hepatitis-b/hepatitis-b.component';
import { HepatitisCComponent } from './client-form/tabs/medical/hepatitis-c/hepatitis-c.component';
import { RiskReductionComponent } from './client-direct-services/risk-reduction/risk-reduction.component';
import { AssessmentComponent } from './client-direct-services/assessment/assessment.component';
import { ViralLoadComponent } from './client-form/tabs/medical/viral-load/viral-load.component';
import { HistoryComponent } from './client-form/history/history.component';
import { ClientRequiredFieldErrorsComponent } from './client-required-field-errors/client-required-field-errors.component';
import { ClientExpiredFieldComponent } from './client-form/client-expired-field/client-expired-field.component';
import { AidsDefineConditionComponent } from './client-form/tabs/medical/aids-define-condition/aids-define-condition.component';
import { CountdownTimerComponent } from './common/countdown-timer/countdown-timer.component';
import { TuberculosisComponent } from './client-form/tabs/medical/tuberculosis/tuberculosis.component';
import { SyphilisComponent } from './client-form/tabs/medical/syphilis/syphilis.component';
import { ChlamydiaComponent } from './client-form/tabs/medical/chlamydia/chlamydia.component';
import { GonorrheaComponent } from './client-form/tabs/medical/gonorrhea/gonorrhea.component';
import { ToxoplasmaComponent } from './client-form/tabs/medical/toxoplasma/toxoplasma.component';
import { LipidpanelComponent } from './client-form/tabs/medical/lipidpanel/lipidpanel.component';
import { CovidComponent } from './client-form/tabs/medical/covid/covid.component';
import { PharmaAssistanceComponent } from './client-form/tabs/medical/pharma-assistance/pharma-assistance.component';
import { AntiretroviralTherapiesComponent } from './client-form/tabs/medical/antiretroviral-therapies/antiretroviral-therapies.component';
import { VaccinationsComponent } from './client-form/tabs/medical/vaccinations/vaccinations.component';
import { FemaleOnlyComponent } from './client-form/tabs/medical/female-only/female-only.component';
import { OtherStiComponent } from './client-form/tabs/medical/other-sti/other-sti.component';
import { ReportsComponent } from './reports/reports.component';
import { ReportviewerComponent } from './reports/reportviewer/reportviewer.component';
import { MentalHealthComponent } from './client-form/tabs/behavior-health/mental-health/mental-health.component';
import { SubstanceAbuseComponent } from './client-form/tabs/behavior-health/substance-abuse/substance-abuse.component';
import { ClientEligibilityComponent } from './client-form/client-eligibility/client-eligibility.component';
import { EligibiltyPopupComponent } from './client-form/alerts/eligibilty-popup.component';

import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { UserMaintenanceComponent } from './admin/user-maintenance/user-maintenance.component';
import { ProviderMaintenanceComponent } from './admin/provider-maintenance/provider-maintenance.component';
import { ProviderMaintenanceDetailComponent } from './admin/provider-maintenance-detail/provider-maintenance-detail.component';
import { UserMaintenanceDetailComponent } from './admin/user-maintenance-detail/user-maintenance-detail.component';
import { ContractMaintenanceDetailComponent } from './admin/contract-maintenance-detail/contract-maintenance-detail.component';
import { ContractMaintenanceComponent } from './admin/contract-maintenance/contract-maintenance.component';
import { UserActivityComponent } from './admin/user-activity/user-activity.component';
import { ErrorLogComponent } from './admin/error-log/error-log.component';
import { UnitMaintenanceComponent } from './admin/unit-maintenance/unit-maintenance.component';
import { ClientLookupComponent } from './client-lookup/client-lookup.component';
import { NonRWVisitComponent} from './client-form/tabs/mcm/non-rwvisit/non-rwvisit.component';
import { CertificationPopupComponent } from './client-form/alerts/certification-popup.component';
import { ExistingClientListComponent } from './client-form/existing-client-list/existing-client-list.component';
import { ContractItemsComponent } from './admin/contract-items/contract-items.component';
import { ClientMergeComponent } from './admin/client-merge/client-merge.component';
import { UserProviderClinicPermissionComponent } from './admin/user-provider-clinic-perm/user-provider-clinic-perm.component';
import { EmployeeComponent } from './admin/employee/employee.component';
import { BulkGroupComponent } from './bulk-group/bulk-group.component';
import { ClientReferralsComponent } from './client-referrals/client-referrals.component';
import { ReferralEditComponent } from './client-referrals/referral-edit/referral-edit.component';
import { ReferralTicketComponent } from './client-referrals/referral-ticket/referral-ticket.component';
import { ReferralsComponent } from './client-referrals/referral-edit-list/referral-edit-list.component';
import { RsrListComponent } from './rsr/rsr-list/rsr-list.component';
import { RsrClientErrorComponent } from './rsr/rsr-client-error/rsr-client-error.component';

//import { ClientStatusFollowupComponent } from './client-status-followup/client-status-followup.component';
//import { ExamplePdfViewerComponent } from './example-pdf-viewer/example-pdf-viewer.component';
//import { ProviderContractMaintenanceComponent } from './admin/provider-contract-maintenance/provider-contract-maintenance.component';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        //CodeHighlighterModule,
        ConfirmDialogModule,
        //ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        //FieldsetModule,
        //FileUploadModule,
        //FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        //LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        //RatingModule,
        RippleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SidebarModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        //TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule,
        ProgressSpinnerModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        //NgxExtendedPdfViewerModule,
        //PdfViewerModule,
        PdfJsViewerModule,
        FontAwesomeModule,
        NgMultiSelectDropDownModule.forRoot(),
        ConfirmPopupModule,
        NgIdleKeepaliveModule.forRoot(),
        DynamicDialogModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AboutComponent,
        AppLoginComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        ClientListComponent,
        ClientFormComponent,
        ClientDirectServicesComponent,
        DirectServiceComponent,
        SpinnerComponent,
        AppLogoutComponent,
        AppProviderInfoComponent,
        DemograhicsComponent,
        IdentificationComponent,
        SocioEconComponent,
        MedicalComponent,
        McmComponent,
        BehaviorHealthComponent,
        //ReferralsComponent,
        NotesComponent,
        SwitchProviderComponent,
        Cd4Component,
        HepatitisBComponent,
        HepatitisCComponent,
        RiskReductionComponent,
        AssessmentComponent,
        ViralLoadComponent,
        HistoryComponent,
        ClientRequiredFieldErrorsComponent,
        ClientExpiredFieldComponent,
        AidsDefineConditionComponent,
        CountdownTimerComponent,
        TuberculosisComponent,
        SyphilisComponent,
        ChlamydiaComponent,
        GonorrheaComponent,
        ToxoplasmaComponent,
        LipidpanelComponent,
        CovidComponent,
        PharmaAssistanceComponent,
        AntiretroviralTherapiesComponent,
        VaccinationsComponent,
        FemaleOnlyComponent,
        ReportsComponent,
        ReportviewerComponent,
        OtherStiComponent,
        MentalHealthComponent,
        SubstanceAbuseComponent,
        ClientEligibilityComponent,
        UserMaintenanceComponent,
        ProviderMaintenanceComponent,
        ProviderMaintenanceDetailComponent,
        UserMaintenanceDetailComponent,
        ContractMaintenanceDetailComponent,
        ContractMaintenanceComponent,
        UserActivityComponent,
        ErrorLogComponent,
        UnitMaintenanceComponent,
        ClientLookupComponent,
        NonRWVisitComponent,
        EligibiltyPopupComponent,
        CertificationPopupComponent,
        ExistingClientListComponent,
        ContractItemsComponent,
        ClientMergeComponent,
        UserProviderClinicPermissionComponent,
        EmployeeComponent,
        BulkGroupComponent,
        ClientReferralsComponent,
        ReferralEditComponent,
        ReferralTicketComponent,
        ReferralsComponent,
        RsrListComponent,
        RsrClientErrorComponent
        
        //ExamplePdfViewerComponent,
       //  ProviderContractMaintenanceComponent
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: BlobErrorHttpInterceptor, multi: true },
        // CountryService, CustomerService, EventService, IconService, NodeService,    PhotoService, ProductService,
        MenuService, SpinnerService, DatePipe,
        ConfirmationService, MessageService, CommonDataService, AuthService, LoginService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    _username: string = '';

    constructor(library: FaIconLibrary) {
        library.addIcons(faSquare, faCheckSquare, faCircle, faCheckCircle, faCheck, faTimes, faSync,
                         faCertificate, faCog, faAsterisk, faExclamationCircle, faStar, faRedo, faRedoAlt,
                         farSquare, farCheckSquare, farCircle, farCheckCircle, farSun,
                         faStackOverflow, faGithub, faMedium, faNotesMedical, faQuestionCircle);
    }
 }
