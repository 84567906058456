<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-7">CD4</div>

                <div class="field col-4">
                    <div [ngClass]="{'disableDiv': !showCD4Unavailable()}">                        
                        <p-checkbox value="false" inputId="unavailable" formControlName="idCD4Unavailable" [binary]="true" ></p-checkbox>   
                        <label style="font-size: 0.875rem; font-weight: normal;" for="unavailable">Unavailable</label>  
                    </div>
                 </div>

                <div class="field col-1">
                    <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('CD4', 'CD4')"></button>      
                </div>
            </div>
        </div>

		<div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid" *ngIf="!isCD4Unavailable()">
                <div class="field col-12">
                    <div class="formgroup-inline champ-formgroup-inline" style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px; line-height: 20px;">
                        <label style="font-weight:normal; margin-bottom: 10px; margin-right: 10px;">Result of last CD4 count on medical record?
                            <label id="idCD4LatestResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalCd4" [fieldId]="MasterFields.CD4LatestResultDate" 
                                    [lastUpdated]="this.clientData.clientMedicalCd4?.datetime" [formGroup]="medical"></app-client-expired-field>
                            </label>                            
                        </label>
                    </div>
                </div>

                <div class="field col-12">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-6">
                            <div class="formgroup-inline champ-formgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idCD4Date" style="margin-right: 1rem;">Date of Result</label> 
                                <input type="text" name="idCD4Date" autocomplete="off" bsDatepicker formControlName="idCD4Date"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idCD4Date" [maxDate]="maxDate" [minDate]="minDate"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idCD4Date'].invalid}" 
                                    class="champ-date-picker" pInputText/>
                            </div>
                            <div *ngIf="medical.controls['idCD4Date'].invalid && (medical.controls['idCD4Date'].dirty || medical.controls['idCD4Date'].touched)"> 
                                <div *ngIf="medical.controls['idCD4Date'].errors.required"> 
                                    <small id="idCD4Date-help" class="p-invalid">Result Date is required.</small>
                                </div>
                                <div *ngIf="medical.controls['idCD4Date'].errors">
                                    <small id="idCD4Date-help" class="p-invalid">{{medical.controls['idCD4Date'].errors['invalidDate']}}</small>
                                </div>
                            </div>
                        </div>

                        <div class="field col-6" style="padding-left: 1rem;">
                            <div class="formgroup-inline champ-formgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idCD4Count" style="margin-right: 1rem;">CD4 Count</label>
                                <div style="width: 5rem;">
                                    <p-inputMask formControlName="idCD4Count" inputId="idCD4Count" mask="9?99999" maxlength="6"></p-inputMask>
                                </div>  
                            </div>
                            <div *ngIf="medical.controls['idCD4Count'].invalid && (medical.controls['idCD4Count'].dirty || medical.controls['idCD4Count'].touched)"> 
                                <div *ngIf="medical.controls['idCD4Count'].errors.required"> 
                                    <small id="idCD4Count-help" class="p-invalid">CD4 Count is required.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field col-12">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-6" style="padding-left: 1rem;">
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                            <label htmlFor="idCD4PcpProphylaxis" style="width:100px;margin-right: 1rem;">PCP Prophylaxis</label>
                            <p-radioButton [style]="{'width':'55px'}" class="col-2" formControlName="idCD4PcpProphylaxis" value=1 label="Yes"></p-radioButton>
                            <p-radioButton [style]="{'width':'55px'}" class="col-2" formControlName="idCD4PcpProphylaxis" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-4" style="padding-left: 1rem;" *ngIf="showCD4PcpProphylaxisNoReason()">
                            <p-dropdown formControlName="idCD4PcpProphylaxisNoReason" [options]="pcpNoReasons" optionLabel="description" optionValue="id" appendTo="body"> </p-dropdown>                                
                        </div>
                        <div class="field col-2"></div>
                        <div class="field col-6" style="padding-left: 1rem;">
                            <div *ngIf="medical.controls['idCD4PcpProphylaxis']?.errors"> 
                                <small id="idCD4PcpProphylaxis-help" class="p-invalid">PCP Prophylaxis is required.</small>
                            </div>
                        </div>
                        <div class="field col-4" style="padding-left: 1rem;">
                            <div *ngIf="showCD4PcpProphylaxisNoReason() && medical.controls['idCD4PcpProphylaxisNoReason']?.errors"> 
                                <small id="idCD4MacProphylaxis-help" class="p-invalid">Reason is required.</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="field col-12">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-6" style="padding-left: 1rem;">
                            <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                            <label htmlFor="idCD4MacProphylaxis" style="width:100px;margin-right: 1rem;">MAC Prophylaxis</label> 
                            <p-radioButton [style]="{'width':'55px'}" class="col-2" formControlName="idCD4MacProphylaxis" value=1 label="Yes"></p-radioButton>                           
                            <p-radioButton [style]="{'width':'55px'}" class="col-2" formControlName="idCD4MacProphylaxis" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-4" style="padding-left: 1rem;" *ngIf="showCD4MacProphylaxisNoReason()">
                            <p-dropdown formControlName="idCD4MacProphylaxisNoReason" [options]="macNoReasons" optionLabel="description" optionValue="id" appendTo="body"> </p-dropdown>                                
                        </div>
                        <div class="field col-2"></div>
                        <div class="field col-6" style="padding-left: 1rem;">
                            <div *ngIf="medical.controls['idCD4MacProphylaxis']?.errors"> 
                                <small id="idCD4MacProphylaxis-help" class="p-invalid">MAC Prophylaxis is required.</small>
                            </div>
                        </div>
                        <div class="field col-4" style="padding-left: 1rem;">
                            <div *ngIf="showCD4MacProphylaxisNoReason() && medical.controls['idCD4MacProphylaxisNoReason']?.errors"> 
                                <small id="idCD4MacProphylaxis-help" class="p-invalid">Reason is required.</small>
                            </div>
                        </div>                                       
                    </div>
                </div>
            </div>

            <div class="p-fluid p-formgrid grid" *ngIf="isCD4Unavailable()">
                <small id="idCD4Unavailable-help" >CD4 was marked unavailable. Screening will be required in 30 days.</small>
            </div>
        </div>        
    </div>
</form>

<app-history ></app-history>