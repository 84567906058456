import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HistoryComponent } from '../../../history/history.component';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCd4Model, ClientMedicalCd4, ClientMedicalCommon } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields, PosNegResults } from "src/app/models/enums";
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-cd4',
  templateUrl: './cd4.component.html',
  styleUrls: ['./cd4.component.scss']
})
export class Cd4Component implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  clientData: ClientModel;  
  clientGUID: string;
  yesNoList: Array<number> = [ 1, 2 ];
  pcpNoReasons: CommonData[];
  macNoReasons: CommonData[];
  MedicalYesNo = MedicalYesNo;  
  maxDate = new Date();
  minDate = new Date();

  getCD4LatestResultUnavailableControl = () => this.medical?.get('idCD4Unavailable');
  getCD4LatestResultDateControl = () => this.medical?.get('idCD4Date');
  getCD4LatestResultControl = () => this.medical?.get('idCD4Count');
  getCD4PCPProphylaxisControl = () => this.medical?.get('idCD4PcpProphylaxis');
  getCD4PCPProphylaxisNoReasonControl = () => this.medical?.get('idCD4PcpProphylaxisNoReason');
  getCD4MACProphylaxisControl = () => this.medical?.get('idCD4MacProphylaxis');
  getCD4MACProphylaxisNoReasonControl = () => this.medical?.get('idCD4MacProphylaxisNoReason');

  showCD4PcpProphylaxisNoReason = () => (this.getCD4PCPProphylaxisControl()?.value ?? 0) == MedicalYesNo.No;
  showCD4MacProphylaxisNoReason = () => (this.getCD4MACProphylaxisControl()?.value ?? 0) == MedicalYesNo.No;
  isCD4Unavailable = () => (this.getCD4LatestResultUnavailableControl()?.value ?? false);
  showCD4Unavailable = () => { 
                                // the order of the logic must be preserved for accuracy
                                if (this.clientData?.clientMedicalCd4 == null) return true; // if no rows in table for this client then allow Unavailable to show
                                //if (this.clientData?.clientMedicalCd4?.positive) return false;
                                if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalCd4?.lastUpdate, true), (this.app.requiredFields30DayGracePeriodReset * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again    
                                if (!this.clientData?.clientMedicalCd4?.unavailable) return false;
                                if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalCd4?.lastUpdate, true), this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
                                return false;                                
                             }
  
  constructor(    
    private fb: UntypedFormBuilder, 
    private commonDataService: CommonDataService,
    public providerService: ProviderService,
    private userService: UserService,
    private clientService: ClientFormService,
    private datePipe: DatePipe,
    private dateHelper: DateHelper,
    public  validationHelper: FormValidations,
    private helperService: HelperClassService,
    private medcommon: MedicalCommon,
    private app: AppContextService) { }

  ngOnInit(): void {
    this.minDate = this.medcommon.getMinScreenedDate(this.minDate);
    this.maxDate = this.medcommon.getMaxScreenedDate(this.maxDate);
    this.pcpNoReasons =  this.commonDataService.getCommonSetByName("YesnoMedicalRefused", true).filter(x => x.id > 2 || x.id == 0);
    this.macNoReasons =  this.commonDataService.getCommonSetByName("YesnoMedicalRefusedMac", true).filter(x => x.id > 2 || x.id == 0);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
                         });
     }

     this.getCD4LatestResultUnavailableControl().valueChanges.subscribe(value => { this.setUnavailable(value); });
     this.getCD4PCPProphylaxisControl().valueChanges.subscribe(value => { this.setPCPProphylaxisValidators(value); });
     this.getCD4MACProphylaxisControl().valueChanges.subscribe(value => { this.setMACProphylaxisValidators(value); });
  }

  refreshAfterSave() {
    this.setFieldValues();
  }   

  setUnavailable(unavailable) {
    //var unavailable = this.medical.get('idCD4Unavailable').value;
    var count = this.getCD4LatestResultControl();
    var date = this.getCD4LatestResultDateControl();
    var pcp = this.getCD4PCPProphylaxisControl();
    var mac = this.getCD4MACProphylaxisControl();

    if (unavailable) {
      this.validationHelper.RemoveValidators(count, true);
      this.validationHelper.RemoveValidators(date, true);
      this.validationHelper.RemoveValidators(pcp, true);
      this.validationHelper.RemoveValidators(mac, true);
    }
    else 
    {
      this.validationHelper.AddRequiredValidator(count); // if (this.providerService.isFieldRequired(MasterFields.CD4LatestResult))
      this.validationHelper.AddValidators(date, new Array(Validators.required, this.validationHelper.dateFutureDateValidator())); // if (this.providerService.isFieldRequired(MasterFields.CD4LatestResultDate))
      this.validationHelper.AddRequiredValidator(pcp); // if (this.providerService.isFieldRequired(MasterFields.PCPProphylaxis))
      this.validationHelper.AddRequiredValidator(mac); // if (this.providerService.isFieldRequired(MasterFields.MACProphylaxis))
    }
  }

  setPCPProphylaxisValidators(value) {
    if (value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getCD4PCPProphylaxisNoReasonControl());
    }
    else if (value == this.MedicalYesNo.No) {
      this.validationHelper.AddValidators(this.getCD4PCPProphylaxisNoReasonControl(), new Array(Validators.required, this.validationHelper.dropdownValidator()));
    }
  }

  setMACProphylaxisValidators(value) {
    if (value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getCD4MACProphylaxisNoReasonControl());
    }
    else if (value == this.MedicalYesNo.No) {
      this.validationHelper.AddValidators(this.getCD4MACProphylaxisNoReasonControl(), new Array(Validators.required, this.validationHelper.dropdownValidator()));
    }
  }

  setFieldValues() {
    if (!this.clientData) return;
    
    if (!this.helperService.isExpired(this.clientData.clientMedicalCd4?.expired)) {
      //this.getCD4LatestResultDateControl().setValue(format(parseISO(this.clientData.clientMedicalCd4?.datetime), 'MM/dd/yyyy'));
      //this.getCD4LatestResultDateControl().setValue(parseISO(this.clientData?.clientMedicalCd4?.datetime));
      this.getCD4LatestResultDateControl().setValue(this.helperService.getISODate(this.clientData?.clientMedicalCd4?.datetime));
      this.getCD4LatestResultControl().setValue(this.clientData?.clientMedicalCd4?.count ?? 0);
      if (this.yesNoList.includes(this.clientData?.clientMedicalCd4?.pcpProphylaxis)) this.getCD4PCPProphylaxisControl().setValue(this.clientData.clientMedicalCd4?.pcpProphylaxis);
      this.getCD4PCPProphylaxisNoReasonControl().setValue(this.clientData?.clientMedicalCd4?.pcpProphylaxisNoReason);
      if (this.yesNoList.includes(this.clientData?.clientMedicalCd4?.macProphylaxis)) this.getCD4MACProphylaxisControl().setValue(this.clientData.clientMedicalCd4?.macProphylaxis);
      this.getCD4MACProphylaxisNoReasonControl().setValue(this.clientData?.clientMedicalCd4?.macProphylaxisNoReason);
    }
  }

	getModel(): Partial<ClientMedicalCd4Model> {
	  var model: Partial<ClientMedicalCd4Model> = new ClientMedicalCommon(this.userService.userID); //ClientMedicalCd4(this.medical.value);

    if (this.getCD4LatestResultUnavailableControl().dirty || this.getCD4LatestResultControl().dirty || this.getCD4LatestResultDateControl().dirty ||
        this.getCD4PCPProphylaxisControl().dirty || this.getCD4MACProphylaxisControl().dirty || 
        this.getCD4PCPProphylaxisNoReasonControl().dirty || this.getCD4MACProphylaxisNoReasonControl().dirty)
    {
      //let date_screened = (this.isCD4Unavailable() || !this.getCD4LatestResultDateControl().value) ? new Date().toISOString() : (new Date (this.getCD4LatestResultDateControl().value)).toISOString();
      let date_screened = (this.isCD4Unavailable() || !this.getCD4LatestResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getCD4LatestResultDateControl().value, { representation: 'date' });

      model.count = this.getCD4LatestResultControl()?.value ?? 0;
      model.datetime = date_screened;
      model.unavailable = this.getCD4LatestResultUnavailableControl()?.value ?? false;
      model.pcpProphylaxis = this.getCD4PCPProphylaxisControl()?.value;
      model.pcpProphylaxisNoReason = this.getCD4PCPProphylaxisNoReasonControl()?.value;
      model.macProphylaxis = this.getCD4MACProphylaxisControl()?.value;
      model.macProphylaxisNoReason = this.getCD4MACProphylaxisNoReasonControl()?.value;
      // moved the below into base class ClientMedicalCommon
      // model.guid = ClientFormHelper.EmptyGuid();
      // model.deleted = false;
      // model.userId = this.userService.userID;
      // model.lastUpdate = ClientFormHelper.nullDate(); //set on server side
      // model.expired = ClientFormHelper.nullDate(); //set on server side

      return model;
    }

    return null;
	}  

  openHistoryWindow(code, title) {
    this.histComp.clientGuid = this.clientData?.guid;
    this.histComp.clientAltID = this.clientData?.clientAltId;
    this.histComp.dataElement = code;
    this.histComp.title = title;
    this.histComp.showHistory(); 
 }  

}
