import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MergeClientList, MergeClientRequestModel } from 'src/app/models/client.model';
import { PostResponse } from 'src/app/models/provide.model';

@Injectable({
  providedIn: 'root'
})
export class ClientMergeService {
  private readonly URL = environment.apiURL + '/client';

  constructor(private http: HttpClient) { 
  }

  public getMergeClientList(providerId: number, clientAltId: string) {
    let url  = this.URL + '/getMergeClientList';

    let params = new HttpParams();
    params = params.append('providerId', providerId);
    params = params.append('clientAltId', clientAltId);

    return this.http.get<MergeClientList[]>(url , { params:params })
  }

  public mergeClient(model: Partial<MergeClientRequestModel>) {
    let url = this.URL + '/mergeClient';
    return this.http.post<PostResponse>(url, model);
  }  
}
