import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProviderContractMaintenanceRequestModel, ProviderContractMaintenanceModel, ProviderClinicsModel, ProviderContractMaintenancePostResponse,
         ProviderContractItemsRequestModel, ProviderContractItemsModel, ProviderContractItemsModelPostResponse } from 'src/app/models/admin.models';

@Injectable({
  providedIn: 'root'
})
export class ContractMaintenanceService {
  private readonly URL = environment.apiURL + '/admin';

  constructor(private http: HttpClient) { 
  }
  
  public getProviderContractItems (model: Partial<ProviderContractItemsRequestModel>) {
    let url = this.URL + '/getProviderContractItems';
    return this.http.post<ProviderContractItemsModel[]>(url, model);
  }

  public saveProviderContractItem(model: Partial<ProviderContractItemsModel>) {
    let url = this.URL + '/saveProviderContractItem';
    return this.http.post<ProviderContractItemsModelPostResponse>(url, model);
  }

  public getProviderContracts (model: Partial<ProviderContractMaintenanceRequestModel>) {
    let url = this.URL + '/getProviderContractList';
    return this.http.post<ProviderContractMaintenanceModel[]>(url, model);
  }

  public getProviderContract (model: Partial<ProviderContractMaintenanceRequestModel>) {
    let url = this.URL + '/getProviderContract';
    return this.http.post<ProviderContractMaintenanceModel>(url, model);
  }

  public saveProviderContract(model: Partial<ProviderContractMaintenanceModel>) {
    let url = this.URL + '/saveProviderContract';
    return this.http.post<ProviderContractMaintenancePostResponse>(url, model);
  }

  public getProviderClinics(providerId: number) {
    let url  = this.URL + '/getProviderClinics';

    let params = new HttpParams();
    params = params.append('providerId', providerId);

    return this.http.get<ProviderClinicsModel[]>(url , { params:params })
  }  


}
