<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                    <div class="field col-7">Antiretroviral Therapy</div>

                    <div class="field col-4"></div>

                    <div class="field col-1">
                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('ART', 'Antiretroviral Therapy')"></button>      
                    </div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12">
                    <div class="formgroup-inline chamformgroup-inline" style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px; line-height: 20px;">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <label style="font-weight:normal; margin-right: 10px;" for="idARTScreened">Was the patient presribed HAART?</label>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idARTScreened" value=2 label="No"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idARTScreened" value=1 label="Yes"></p-radioButton></div>
                        <label id="idAntiretroviralTherapyResultDate">
                            <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalAntiretroviralTherapy"  [formGroup]="medical"
                            [fieldId]="MasterFields.AntiRetroViralTherapyPrescribedDate" [lastUpdated]="this.clientData.clientMedicalAntiretroviralTherapy?.datetime"></app-client-expired-field>
                        </label>                            
                    </div>

                    <div *ngIf="medical.controls['idARTScreened']?.errors?.required"> 
                        <small id="idARTScreened-help" class="p-invalid" style="margin-left: 1rem;">Antiretroviral Therapy is required.</small>
                    </div> 
                </div>   

                <div class="field col-12">
                    <div id="ARTNoDiv" class="fluid formgrid grid" *ngIf="showAntiretroviralTherapyNoReason()">
                        <div class="field col-12">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idARTScreenedNoReason" style="margin-right: 1rem;">Reason</label>
                                <div class="field grid">
                                    <p-dropdown formControlName="idARTScreenedNoReason" [options]="noReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}"  > </p-dropdown>      
                                </div>
                            </div>
                            <div *ngIf="medical.controls['idARTScreenedNoReason']?.errors"> 
                                <small id="idARTScreenedNoReason-help" class="p-invalid" style="margin-left: 1rem;">Reason is required.</small>
                            </div>
                        </div>
                    </div>
                    
                    <div id="ARTYesDiv" class="fluid formgrid grid" *ngIf="showAntiretroviralTherapyResultFields()">
                        <div class="field col-6">
                            <div class="formgroup-inline chamformgroup-inline" >
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idARTScreenedDate" style="margin-right: 1rem;">Date of Result</label> 
                                <input type="text"  #aidsDiagDatePicker  name="idARTScreenedDate" autocomplete="off" bsDatepicker formControlName="idARTScreenedDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idARTScreenedDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idARTScreenedDate'].invalid}" 
                                    class="champ-date-picker" pInputText/>
                            </div>
                        </div>

                        <div class="field col-6">
                            <!-- <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label style="font-weight:normal; margin-right: 10px;">Result:</label>
                                <p-radioButton formControlName="idARTResult" value=2 label="Negative" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idARTResult" value=1 label="Positive" [style]="{'margin-left':'15px'}"></p-radioButton>
                            </div> -->
                        </div>
                        
                        <div class="field col-6">
                            <div *ngIf="medical.controls['idARTScreenedDate']?.invalid">
                                <div *ngIf="medical.controls['idARTScreenedDate']?.errors?.required"> 
                                    <small id="idARTScreenedDate-help" class="p-invalid" style="margin-left: 1rem;">Result Date is required.</small>
                                </div>
                                <!-- <div *ngIf="!!medical.controls['idARTScreenedDate'].errors['invalidDate']">-->
                                <div *ngIf="!medical.controls['idARTScreenedDate']?.errors?.required && !!medical.controls['idARTScreenedDate']?.errors"> 
                                    <small id="idARTScreenedDate2-help" class="p-invalid" style="margin-left: 1rem;">{{medical.controls['idARTScreenedDate']?.errors['invalidDate']}}</small>
                                </div>                                                               
                            </div>
                         </div>

                        <div class="field col-6">
                            <!-- <div *ngIf="!!medical.controls['idARTResult']?.errors"> 
                                <small id="idARTResult-help" class="p-invalid" style="margin-left: 1rem;">Result is required.</small>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<app-history ></app-history>