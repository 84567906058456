<div class="p-grid">
    <div class="col-12">
        <div class="card docs">

            <h4>Welcome to CHAMP 4</h4>
            <p>Case Managers HIV-AIDS Management Program</p>

      
            <h4>Getting Started</h4>
            <p>CHAMP 4 will work in the Google Chrome, Microsoft Edge, and Apple Safari browsers.</p>
            <p>Prior to logging in to CHAMP 4 for the first time. You must call or email the CHAMP Helpdesk so they can register your
                 email address which will then grant you access to the system. Once granted access, you can sign up to use CHAMP 4. 
                 The sign in process will validate your identity using your email adrress or text message via your phone. Once verified, you will have 
                 access to CHAMP 4.</p> 

            <h4>Contact Info </h4>
                
            <ul>
                <li> <a href = "mailto:  champ@futurebridge.net"> helpdesk email:</a> </li>
                <li>helpdesk phone:  (732) 667-7910 </li>
            </ul>
        
               <!-- <app-code ngPreserveWhitespaces ngNonBindable lang="javascript">
                &lt;div class="layout-wrapper layout-menu-horizontal"&gt;
                </app-code> -->
      
    </div>
</div>
