import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { FilterMetadata } from "primeng/api";
import { AppContextService } from 'src/app/app-context/app-context.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { LoginService } from 'src/app/login/services/login.service';
import { ProviderMaintenanceModel } from 'src/app/models/admin.models';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { ProviderMaintenanceDetailComponent } from '../provider-maintenance-detail/provider-maintenance-detail.component';
import { ProviderMaintenanceService } from './provider-maintenance.service';


@Component({
  selector: 'app-provider-maintenance',
  templateUrl: './provider-maintenance.component.html',
  styleUrls: ['./provider-maintenance.component.scss','../admin-styles.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class ProviderMaintenanceComponent implements OnInit {
  diagRef: DynamicDialogRef;
  fg: UntypedFormGroup;
  //providers: Partial<ProviderMaintenanceModel>[];
  providers: ProviderMaintenanceModel[];
  getProviders = () => this.providers;

  readonly filters: { [key in keyof Partial<ProviderMaintenanceModel>]: FilterMetadata[] } = {
    active: [{ value: true, matchMode: "contains", operator: "and" }]
  };

  constructor(private fb: UntypedFormBuilder,
              private app: AppContextService,
              // private authService: AuthService,
              // private loginService: LoginService,
              // private helper: HelperClassService,
              // private router: Router,
              // private route: ActivatedRoute,
              private messageService: MessageService,
            //  private confirmationService: ConfirmationService,
              public dialogService: DialogService,              
             // private validations: FormValidations,
              private providerMaintenanceService: ProviderMaintenanceService) {
    this.fg = fb.group({
      providerId: [0]
    },
    {
      //validators: [this.customValidator()],
      //asyncValidators: [this.timePeriodValidator()]
      //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
    });                
  }

  ngOnInit(): void {
    // if(!this.authService.isUserAuthenticated){
    //   this.loginService.standAloneLogin().then(x=> this.loadData());
    // }
    // else this.loadData();      

    this.app.updateTabTitle('CHAMP - Provider Maintenance');
    this.loadData();  
  }

  loadData() {
    this.loadProviders();
  }

  loadProviders() {
    //var request: Partial<ErrorReportRequestModel>;
    // var request: any = {};
    // Object.assign(request, {});
    // request.startDate = this.helper.getISODate(this.getStartDate().value);
    // request.endDate = this.helper.getISODate(this.getEndDate().value);

    this.providerMaintenanceService.getProviders().subscribe({
      next: (data: ProviderMaintenanceModel[]) => {
            //console.log(data);
            this.providers = data;
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => { }
      });
  }

  onChangeFilter(e, dtProviders) {
    this.loadProviders();
    //this.app.userServ.SaveUserConfigValue('CHAMP.Registry.Admin.CurrentProviderId',  this.getProviderId()?.value?.toString());
  }

  onEditProvider(e, provider: ProviderMaintenanceModel) { //, user: AdminUserModel
    provider.providerIdToClone = 0;
    this.diagRef = this.dialogService.open(ProviderMaintenanceDetailComponent, {
      header:  provider == null ? 'Add CHAMP Provider': ' Edit CHAMP Provider',
      width: '50%',
      //height: '80%', //'700px'
      contentStyle: {"max-height": "950px", "overflow": "visible"},
      baseZIndex: 10000,
      data: provider
    });

    this.app.overLayMangr.add(this.diagRef, 'dialogRef');

    this.diagRef.onClose.subscribe(async (data) => {
        this.loadProviders();
        this.app.overLayMangr.remove(this.diagRef);
    });
  }

  onDeleteProvider(e, provider: ProviderMaintenanceModel) {
  }

  onCloneProvider(e, provider: ProviderMaintenanceModel) {
    provider.guid = null;
    provider.providerIdToClone = provider.id;
    this.diagRef = this.dialogService.open(ProviderMaintenanceDetailComponent, {
      header:  'Clone CHAMP Provider',
      width: '50%',
      contentStyle: {"max-height": "750px", "overflow": "auto"},
      baseZIndex: 10000,
      data: provider
    });

    this.app.overLayMangr.add(this.diagRef, 'dialogRef');

    this.diagRef.onClose.subscribe(async (data) => {
        this.loadProviders();
        this.app.overLayMangr.remove(this.diagRef);
    });
   }

}
