import { DatePipe } from '@angular/common';
import { AfterContentInit, AfterViewInit, Component, Input, OnInit, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { CommonData } from 'src/app/models/common-data.model';
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { ClientModel } from '../../../models/client.model';
import { HistoryComponent } from '../../history/history.component';
import { ClientFormService } from '../../services/client-form.service';
import { MasterFields } from "src/app/models/enums";
import { noop } from 'rxjs';

//import { ClientFormHelperService} from 'src/app/client-form/services/client-form-helper.service'

@Component({
  selector: 'app-socio-econ',
  templateUrl: './socio-econ.component.html',
  styleUrls: ['./socio-econ.component.scss']
})
export class SocioEconComponent implements  OnInit, AfterViewInit, AfterContentInit  {
  //@ViewChild('idIncomeDataExpire', {static: false}) private incomeDataExpire: ElementRef<HTMLDivElement>;
  @Input() socio_econ: UntypedFormGroup;
  

   clientData: ClientModel;
   empStatuses:CommonData[];
   careGivers:CommonData[];
   usualPrimaryCareSources:CommonData[];
   livingIns:CommonData[];
   livingWiths:CommonData[];
   incomeSources:CommonData[];
   paySources:CommonData[];
   //livingWithSelectedItems:any[]=[]; 
   livingWithSelectedItems: CommonData[]=[];
   incomeSourcesSelectedItems:CommonData[]=[]; 
   paySourcesSelectedItems:CommonData[]=[];
   multiCheckDropDownSettings: any = {};



   public getIncomeData = () => this.socio_econ?.get('incomeData') as FormGroup;
   public getFamilyIncome = () => this.getIncomeData().get('idFamilyIncome').value;
   public getFamilySize = () => this.getIncomeData().get('idFamilySize').value;
   public getPovertyLevelControl = () => this.getIncomeData().get('idFPLDescription');
  // tranModesSelectedItems:any[] = []; // this has to be multi select use a single select for no
  getPaysoureControl = () => this.socio_econ.get('idPaySource');
  getLivingInControl = () => this.socio_econ.get('idLivingIn');
  getFamilyIncomeControl = () => this.getIncomeData().get('idFamilyIncome');
  getFamilySizeControl = () => this.getIncomeData().get('idFamilySize');
  getMedUnableToWorkControl = () => this.getIncomeData().get('idMedicallyUnableToWork');  

  @ViewChild(HistoryComponent, {static: false}) histComp:HistoryComponent;

  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  constructor(private fb: UntypedFormBuilder, 
    private commonDataService:CommonDataService,
    private providerService: ProviderService,
    private userService:UserService,
    public clientService:ClientFormService,
    private datePipe: DatePipe,
    public  validationHelper:FormValidations,
    private cdRef:ChangeDetectorRef
    ) { 

  
    }

// ngAfterViewChecked() {
//    if (this.incomeDataExpire?.nativeElement?.innerText.includes("(Expired")) {
//       this.getIncomeData().get('idMedicallyUnableToWork').setValue(null);   
//       this.getIncomeData().get('idFamilyIncome').setValue(null); 
//       this.getIncomeData().get('idFamilySize').setValue(null); 
//       this.getIncomeData().get('idFPL').setValue(null); 
//       this.getIncomeData().get('idFPLDescription').setValue(null); 
//    }
// }

onCheckedExpiredField(event: any) {
   if (!event?.expired) return;

   switch (event?.fieldId) {
      case MasterFields.EmploymentStatus:
         this.socio_econ.get('idEmploymentStatus').setValue(null);
         break;
      case MasterFields.PaymentSource:
         this.socio_econ.get('idPaySource').setValue(null);
         break;
      case MasterFields.LivingIn:
         this.socio_econ.get('idLivingIn').setValue(null);
         break;
      case MasterFields.FamilyIncome:
         this.getIncomeData().get('idMedicallyUnableToWork').setValue(null);   
         this.getIncomeData().get('idFamilyIncome').setValue(null); 
         this.getIncomeData().get('idFamilySize').setValue(null); 
         this.getIncomeData().get('idFPL').setValue(null); 
         this.getIncomeData().get('idFPLDescription').setValue(null);
         break;
   }
}

ngOnInit(): void {
   
    this.paySources = this.commonDataService.getCommonSetByName("PaySource", false);
    this.empStatuses = this.commonDataService.getCommonSetByName("EmploymentStatus", true);
    this.careGivers = this.commonDataService.getCommonSetByName("CareGiver", true);
    this.usualPrimaryCareSources = this.commonDataService.getCommonSetByName("PrimaryCare", true);
    this.livingIns = this.commonDataService.getCommonSetByName("LiveIn", true);
    this.livingWiths = this.commonDataService.getCommonSetByName("LiveWith", false);
    this.incomeSources = this.commonDataService.getCommonSetByName("IncomeSource", false);
    

    this.multiCheckDropDownSettings = { 
      singleSelection: false,
      limitSelection: 4,
      idField: 'id',
      textField: 'description',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableCheckAll:false,
      allowSearchFilter: false};

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                        if (!!result) {
                           this.clientData = this.clientService?.clientData; 
                           this.refreshAfterSave();
                        }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                     });
  
      if (this.clientService.newclient)
      {
        this.socio_econ.markAllAsTouched();
      }
      else {
              this.clientService.$clientGet.subscribe(
              {
                next: (result) => { this.clientData = result; },
                error: (error) => { this.userService.LogError(error); },
                complete: () => { this.setFieldValues(); } //this.clientData = this.clientService.clientData;
              });        
      }
}

refreshAfterSave() {
   this.setFieldValues();
}

ngAfterContentInit(): void{
}

ngAfterViewInit(): void {
  //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
  //Add 'implements AfterViewInit' to the class.
}

 setFieldValues() { 
     if (!this.clientData) return;

     if (this.clientData?.clientEmploymentStatus != null){
        this.socio_econ.get('idEmploymentStatus').setValue(this.clientData.clientEmploymentStatus.id ?? 0);
     }
     
     if ( this.clientData?.clientCareGiverSupport != null){
        this.socio_econ.get('idCareGiver').setValue(this.clientData.clientCareGiverSupport.id ?? 0);
     }
          
     if ( this.clientData?.clientPrimaryCareSource ){
        this.socio_econ.get('idUsualSourcePrimaryCare').setValue(this.clientData.clientPrimaryCareSource.id);
     }

     if ( this.clientData?.clientLiveIn ){
        this.socio_econ.get('idLivingIn').setValue(this.clientData.clientLiveIn.id);
     }

     if ( this.clientData?.clientLiveWith )
     {
        this.socio_econ.get('idLivingWith').setValue(this.clientData.clientLiveWith);
        this.livingWithSelectedItems = ClientFormHelper.GetMultiItems(this.socio_econ.get('idLivingWith'),this.clientData.clientLiveWith, this.livingWiths);
     }

     if ( this.clientData?.clientIncomeSources )
      {
         this.socio_econ.get('idIncomeSource').setValue(this.clientData.clientIncomeSources);
         this.incomeSourcesSelectedItems = ClientFormHelper.GetMultiItems(this.socio_econ.get('idIncomeSource'),this.clientData.clientIncomeSources, this.incomeSources);
      }

      if ( this.clientData?.clientPaySources )
      {
         this.socio_econ.get('idPaySource').setValue(this.clientData.clientPaySources);
         this.paySourcesSelectedItems = ClientFormHelper.GetMultiItems(this.socio_econ.get('idPaySource'),this.clientData.clientPaySources, this.paySources);
      }
      this.getIncomeData().get('idMedicallyUnableToWork').setValue(this.clientData.clientIncomeData?.medUnable);   
      this.getIncomeData().get('idFamilyIncome').setValue(this.clientData.clientIncomeData?.householdIncome); 
      this.getIncomeData().get('idFamilySize').setValue(this.clientData.clientIncomeData?.householdSize); 
      this.getIncomeData().get('idFPL').setValue(this.clientData.clientIncomeData?.povertyCode); 
      this.getIncomeData().get('idFPLDescription').setValue(this.commonDataService.getPovertyLevelDescriptionWithCode(this.clientData.clientIncomeData?.povertyCode)); 

      //this.socio_econ.markAsPristine();
      // this.clientService.clearValidators(this.socio_econ);
      // this.socio_econ.markAllAsTouched();

      // Force change detection than mark the form as prsitine - needed for multi-select dropdowns mostly 
      this.cdRef.detectChanges();
      this.socio_econ.markAsPristine();
      

      this.clientService.loadedSocio = true;
 
}

openHistoryWindow(code, title) {
   this.histComp.clientGuid = this.clientData.guid;
   this.histComp.clientAltID = this.clientData.clientAltId;
   this.histComp.dataElement = code;
   this.histComp.title = title;
   this.histComp.showHistory(); 
}

onBlurIncome(){

   this.getPovertyLevelControl()?.setValue(this.commonDataService.getPovertyLevelDescription(parseInt(this.getFamilyIncome()), parseInt( this.getFamilySize())));
   
}
onRenewValue(value){
   let fieldId:number =  value; 
   switch(fieldId)
   {
      case MasterFields.PaymentSource:{       
         this.getPaysoureControl().setValue(this.getPaysoureControl().value);
         this.getPaysoureControl().markAsDirty(); 
         break;
      }
      case MasterFields.LivingIn:{       
         this.getLivingInControl().setValue(this.getLivingInControl().value);
         this.getLivingInControl().markAsDirty();
         break;
      }
      case MasterFields.FamilyIncome:{       
         this.getFamilyIncomeControl().setValue(this.getFamilyIncomeControl().value);
         this.getFamilyIncomeControl().markAsDirty();
         this.getFamilySizeControl().setValue(this.getFamilySizeControl().value);
         this.getFamilySizeControl().markAsDirty();
         this.getMedUnableToWorkControl().setValue(this.getMedUnableToWorkControl().value);
         this.getMedUnableToWorkControl().markAsDirty();   
         break;      
      }
   }

//    if (fieldId == MasterFields.PaymentSource ){
//        this.getPaysoureControl().setValue(this.getPaysoureControl().value);
//        this.getPaysoureControl().markAsDirty(); 
//    }

//    if (fieldId == MasterFields.PaymentSource ){
//       this.getPaysoureControl().setValue(this.getPaysoureControl().value);
//       this.getPaysoureControl().markAsDirty(); 
//   }

}


}
