<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]='mcm' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm">
            <div class="p-fluid p-formgrid grid">
                    <div class="field col-7">Medical Case Management Care Plan</div>
                    <div class=" col-4 mt-2">
                        <div class="flex align-items-end" [ngClass]="{'disableDiv': !showMCMCarePlanUnavailable()}">   <!--  -->
                            <p-checkbox class="champ-unavailable-check" value="false" inputId="unavailable" formControlName="idMCMCarePlanDateUnavailable" [binary]="true"></p-checkbox>   
                            <label class="champ-unavailable" for="unavailable">Unavailable</label>
                        </div>
                    </div>
                    <div class="col-1">
                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical mr-5" (click)="openHistoryWindow('MCM', 'Care Plan ')" style="min-width: 5rem ;" ></button>   
                    </div>
            </div>
        </div>
    
        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid" style="margin-left: .0125rem">
                <div class="col-12 field " style="margin-top: 10px ;">
                        <div class="formgroup-inline" style="height: 25px;">
                                <div class="formgroup-inline champ-formgroup-inline" >
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                    <label htmlFor="idMCMCarePlanDate" style="margin-right: 1rem;">Care Plan Last Update On</label> 
                                    <input type="text"  #aidsDiagDatePicker  name="idMCMCarePlanDate" autocomplete="off" bsDatepicker formControlName="idMCMCarePlanDate"
                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idMCMCarePlanDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate"
                                        placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : mcm.controls['idMCMCarePlanDate'].invalid}" 
                                        class="champ-date-picker" pInputText/>
                                        <label id="idMCMCarePlanDateLabel">
                                            <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalCaseManagement" [control]="mcm.controls['idMCMCarePlanDate']"
                                                [fieldId]="MasterFields.MedicalCaseManagementCarePlan" [lastUpdated]="this.clientData?.clientMedicalCaseManagementCarePlan?.carePlanDate"></app-client-expired-field>
                                        </label>   
                                </div>
                        </div>
                </div>
            </div>
        </div>

    </div>

    <div class="card champ-card-sm" > 
        <div class=" champ-card-header-sm">
            <div class="p-fluid p-formgrid grid">
                <div class="field col-7">Medical Case Management Services</div>
                <div class="field col-4">
                    <div [ngClass]="{'disableDiv': !showMCMCarePlanUnavailable()}">  
                        <p-checkbox value="false" inputId="unavailable" formControlName="idMCMCarePlanServicesUnavailable" [binary]="true"></p-checkbox>   
                        <label style="font-size: 0.875rem; font-weight: normal;" for="unavailable">Unavailable</label>
                    </div>
                </div>
                <div class="field col-1">
                    <!-- <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" ></button>  -->
                        <!-- (click)="openHistoryWindow('HEPB', 'Hepatitis B')" -->   
                </div>
            </div>
        </div>
    
        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col">
                    <ng-template #loading>
                        <div class="champ-notification-loading">Loading MCM services...</div>
                    </ng-template>                
                </div>
            </div>
            <form [formGroup]='mcmForm' autocomplete="off">
                <div class="p-fluid p-formgrid grid">
                        <div class="field col-5 pb-0">
                            <div class="formgroup-inline" style="margin-top: 15px; margin-bottom: 15px ;">
                                <label for="idFilterOptions" class="mr-2">Filter Options:</label>
                                <div ><p-radioButton formControlName="mcmVisitsOption" value=1 label="Ryan White Visits" (click)="onChangeFilterOption(1)" ></p-radioButton></div>
                                <div ><p-radioButton formControlName="mcmVisitsOption" value=2 label="Non-Ryan White Visits" (click)="onChangeFilterOption(2)" ></p-radioButton></div>
                                <div ><p-radioButton formControlName="mcmVisitsOption" value=3 label="All" (click)="onChangeFilterOption(3)" ></p-radioButton></div>
                            </div>
                            <!-- <div  *ngIf="demographics.controls['idDiagnosedLast12Months'].invalid && (demographics.controls['idDiagnosedLast12Months'].dirty || demographics.controls['idHIVDiagnosis'].touched)"> 
                                <div *ngIf="demographics.controls['idDiagnosedLast12Months'].errors.required"> 
                                    <small id="idDiagnosedLast12Months-help" class="p-invalid">Diagnosis Last 12 months is required.</small>
                                </div>
                            </div>  -->
                        </div>    

                        <div class="flex align-items-center col-4 pb-0" *ngIf="!clientService.readonly">
                            <fa-icon class="champ-field-required ml-2" [icon]="['fas', 'asterisk']"></fa-icon>
                            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'21rem'}"  styleClass="p-button-text " class="p-button-text mr-1 mt-1"
                                [disabled]="!(this.app.userServ?.hasAdminPermission || this.app.userServ?.hasEditPermissions)"
                                (click)="showAddNonRWVisitDialog()"   > 
                                <i class="fa-sharp fa-plus fa-lg" ></i> <span>Add non-Ryan-White Visit</span>
                            </p-button>        

                            <app-client-expired-field *ngIf="!!this.clientData && this.mcmVisits"
                                [fieldId]="MasterFields.MedicalCaseManagementAddNonRWMedicalVisit" 
                                    [control]="mcm.controls['idMCMVisitDate']"  [lastUpdated]="getMCMVisitDate()?.value">
                            </app-client-expired-field>
                            
                        </div>

                        <div class="flex align-items-center col-3 pb-0">
                            <!-- (click)="openHistoryWindow('REFSO', 'Referal Source')" -->
                            <button pButton pRipple type="button" label="Treatment Adherence/Risk Redcution/Counseling/Screenings"  (click)="onClickShowRiskReduction()"
                                        class="p-button-text p-button-sm" style="width: 100%; float:right" ></button>      
                        </div>

                        <div class="grid col-12 pt-0"> 
                            <div class="col-10  flex justify-content-start" >
                                <div class=" field ml-0" style="margin-top: 10px ;">
                                    <div class="formgroup-inline" style="height: 25px;">
                                        <label for="filterOption" class="mr-2">Date Options:</label>
                                        <div class="field-radiobutton"> 
                                            <p-radioButton [style]="{'margin-top': '0.25rem','align-items': 'unset'}" name="filterOption" value=1 label="Standard Filters" formControlName="filterOption" (click)="onChangeFilterOption(1)"></p-radioButton>
                                        </div>
                                        <div class="field-radiobutton">
                                            <p-radioButton [style]="{'margin-top': '0.25rem','align-items': 'unset'}" name="filterOption" value=2 label="Custom Filters" formControlName="filterOption" (click)="onChangeFilterOption(2)"></p-radioButton>
                                        </div>
                                    </div>
                                </div>

                                <div class="field ml-1" style="min-width: 10rem;"*ngIf="usingCustomDSListFiltering()">
                                    <label for="dsMonth">Month</label>
                                    <p-dropdown formControlName="month" [options]="months" optionLabel="name" optionValue="code" appendTo="body" (onChange)="onChangeMonth($event.value)"> </p-dropdown>
                                </div>
                                <div class="field ml-1 " style="min-width: 10rem;" *ngIf="usingCustomDSListFiltering()">
                                    <label for="dsYear">Year</label>
                                    <p-dropdown formControlName="year" [options]="years" appendTo="body" (onChange)="onChangeYear($event.value)"> </p-dropdown>
                                </div>
                                <div class="field ml-1" style="min-width: 15rem;" *ngIf="!usingCustomDSListFiltering()">
                                    <label for="dateFilterSelection">Please select a time period</label>
                                    <p-dropdown formControlName="dateFilterSelection" [options]="standardFilterSelections" optionLabel="name" optionValue="code" appendTo="body" (onChange)="onChangeFilterSelection($event.value)"> </p-dropdown>
                                </div>        

                            </div>

                            <div class="col-2  flex justify-content-end" >
                                 @if( this.app.userServ?.hasAdminPermission)
                                 {
                                    <button pButton pRipple type="button" label={{deletedLabel}} id="deletedButton"  class="p-button-text   p-button" style="font-weight: bold; border-style: none;"  (click)="showDeletedClicked()"></button>    
                                 }
                            </div>
                        </div>
                    
                </div>
            </form>
     
           <p-scrollPanel [style]="{width: '99.2%', padding: '0', margin: '.5rem'}">
                <ng-container *ngIf="(mcmServices$ | async) as dsData else loading"> 
                    <!-- let-product let-editing="editing" let-rowIndex="rowIndex" -->
                    <!-- [autoLayout]="true" [resizableColumns]="true" scrollHeight="100%" scrollWidth="100%" [rows]="10" [style]="{'width':'99.75%'}" -->
                    <p-table [value]="dsData.mcmServices"
                            [rowHover]="true"
                            [style]="{'width':'99.9%'}" 
                            styleClass="p-datatable-sm p-datatable-striped"
                            name="tblClientDirectServices"
                            [showCurrentPageReport]="false"
                            [paginator]="false"
                            currentPageReportTemplate="{totalRecords} entries">
                    <ng-template pTemplate="header">
                        <tr>
                            <th style="min-width:8rem;max-width:8rem;text-align:left"></th>
                             <!-- <th></th> -->
                            <th pSortableColumn="visitDate" style="min-width:50px;max-width:50px;text-align:left">Date</th>
                            <th pSortableColumn="location" style="min-width:200px;max-width:200px;text-align:left">Location<p-sortIcon field="location"></p-sortIcon></th>
                            <th pSortableColumn="serviceDescription" style="min-width:150px;max-width:150px;text-align:left">Service Category<p-sortIcon field="serviceDescription"></p-sortIcon></th>
                            <th pSortableColumn="subTypeDescription" style="min-width:160px;max-width:160px;text-align:left">Sub Category<p-sortIcon field="subTypeDescription"></p-sortIcon></th>
                            <th pSortableColumn="memo" style="min-width:200px;max-width:200px;text-align:left">Memo<p-sortIcon field="memo"></p-sortIcon></th>
                            <th pSortableColumn="rwVisit" style="min-width:60px;max-width:60px;text-align:left">RW Visit</th>
                            
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" ngFor let-mcmService let-editing="editing" let-rowIndex="rowIndex" >
                        <tr>
                            <td style="min-width:8rem;max-width:8rem;text-align:left">
                            <!-- <td> -->
                                <button title="Edit" *ngIf="!clientService.readonly && !mcmService.rwVisit && mcmService.currentProvider" pButton pRipple class="p-button p-button-text m-1 mr-2" style="width:2rem"  (click)="onOpenMCMVisit(mcmService)"   >
                                    <i class="fa-sharp fa-light fa-pencil " style="color: green;"></i>
                                </button> 
                                <!-- {{mcmService.deleted }} -->
                                <!-- {{this.app.userServ?.hasAdminPermission  }} -->
                                @if( this.app.userServ?.hasAdminPermission && !mcmService.deleted)
                                {  
                                    <button title="Delete" *ngIf="!mcmService.rwVisit && mcmService.currentProvider"  pButton pRipple  class="p-button p-button-text m-1 mr-2"  style="width:2rem" (click)="onDeleteMCMVisit(mcmService)"   >
                                        <i class="fa-sharp fa-regular fa-trash " style="color: red;" ></i>
                                    </button>  
                                }
                                @if( this.app.userServ?.hasAdminPermission && mcmService.deleted)
                                {
                                    <button title="Undelete" *ngIf="!mcmService.rwVisit && mcmService.currentProvider"  pButton pRipple  class="p-button p-button-text m-1 mr-2"  style="width:2rem" (click)="onUndeleteMCMVisit(mcmService)"   >
                                        <i class="fa-sharp fa-regular fa-trash-undo" style="color: darkgoldenrod;" ></i>
                                   </button>  
                                }
                                
                                <button title="Open" *ngIf="clientService.readonly && (!mcmService.rwVisit && mcmService.currentProvider)" pButton pRipple icon="pi pi-book" class="p-button p-button-text" style="width:2rem"   (click)="onOpenMCMVisit(mcmService)">
                                    <i class="fa-sharp fa-regular fa-trash" style="color: blue;" ></i>
                                </button> 
                                <button title="Open" *ngIf=" (mcmService.rwVisit && mcmService.currentProvider)" pButton pRipple class="p-button p-button-text m-1 mr-2" style="width:2rem"  (click)="onOpenDirectService(mcmService)">
                                    <i class="fa-sharp fa-regular fa-book" style="color: blue;" ></i>
                                </button> 
                            </td>
                            <td style="min-width:50px;max-width:50px;text-align:right" class="champ-datatable-date">{{mcmService.visitDate | date:'shortDate'}}</td>
                            <td style="min-width:70px;max-width:70px;text-align:left" ><span title="{{mcmService.location}}">{{mcmService.location}}</span></td>
                            <td style="min-width:70px;max-width:70px;text-align:left" ><span title="{{mcmService.serviceDescription}}">{{mcmService.serviceDescription}}</span></td>
                            <td style="min-width:70px;max-width:70px;text-align:left" ><span title="{{mcmService.subTypeDescription}}">{{mcmService.subTypeDescription}}</span></td>
                            <td style="min-width:70px;max-width:70px;text-align:left" ><span title="{{mcmService.memo}}">{{mcmService.memo}}</span></td>
                            <!-- <td style="min-width:70px;max-width:70px;text-align:left" ><span title="{{mcmService.locatirwVisiton}}">{{mcmService.rwVisit}}</span></td> -->
                            <td style="min-width:60px;max-width:60px;text-align:center">  
                                <div *ngIf="mcmService.rwVisit">
                                    <fa-icon class="champ-field-checked" [icon]="['fas', 'check']"></fa-icon> 
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="4">Count: {{dsData.mcmServices?.length}}</td>
                        </tr>
                    </ng-template>   
                    </p-table>

                </ng-container>
            </p-scrollPanel>    

            </div>
    </div>
</form>

<!-- Risk Reduction Dialog -->
<app-risk-reduction (dialogCompleted)="onRiskReductionDialogCompletedNotification($event)"></app-risk-reduction>

<app-history ></app-history> 
<!-- <app-non-rwvisit></app-non-rwvisit> -->

