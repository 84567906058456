import { Component, OnInit, ViewEncapsulation, isDevMode, Input, ViewChild, ElementRef  } from '@angular/core';
import { FormControl, FormBuilder, UntypedFormGroup, Validators, Form } from '@angular/forms';
import { MedicalYesNo, BehavioralHealthComponents } from "src/app/models/enums";
import { MentalHealthComponent } from './mental-health/mental-health.component';
import { SubstanceAbuseComponent } from './substance-abuse/substance-abuse.component';

@Component({
  selector: 'app-behavior-health',
  templateUrl: './behavior-health.component.html',
  styleUrls: ['./behavior-health.component.scss']
})
export class BehaviorHealthComponent implements OnInit {
  @Input() client: UntypedFormGroup;
  @ViewChild(MentalHealthComponent, { static: false }) mentalHealthSub: MentalHealthComponent;
  @ViewChild(SubstanceAbuseComponent, { static: false }) substanceAbuseSub: SubstanceAbuseComponent;

  constructor() { }

  ngOnInit(): void {
  }

  getModel(type: BehavioralHealthComponents) {
    if (type == BehavioralHealthComponents.MentalHeath) return this.mentalHealthSub.getModel();
    else if (type == BehavioralHealthComponents.SubstanceAbuse) return this.substanceAbuseSub.getModel();
  
    return null;
  }  
}
