import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { CommonDataService } from 'src/app/services/common-data.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ZipCodeResult } from '../../models/common-data.model';
import { HIVStatus } from "src/app/models/enums";
import { ConfirmationService, MessageService } from 'primeng/api';
import { NewClientModel } from 'src/app/models/client.model';

@Component({
  selector: 'app-client-eligibility',
  templateUrl: './client-eligibility.component.html',
  styleUrls: ['./client-eligibility.component.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ClientEligibilityComponent implements OnInit, AfterViewInit {
  @ViewChild('zipCodeId') zipCodeElement: any;
  @ViewChild('familySizeId') familySizeElement: any;
  @ViewChild('familyIncomeId') familyIncomeElement: any;
  @ViewChild('hivStatusId') hivStatusElement: any;

  hivStatuses: SelectItem[];
  //hivStatus: SelectItem;
  hivStatus: SelectItem;
  familySize: number = 0;
  familyIncome: number = 0;
  zipCode: any;
  povertyDescription: string;
  poveryCode: number = 0;
  cityState: string;
  city: string;
  state: string;
  county: string;

  isHivStatusInvalid = () => this.hivStatus?.value <= 0 || this.hivStatus?.value == HIVStatus.HIVAffected; //10 HIVStatus.HIVAffected
  isFamilySizeInvalid = () => this.familySize <= 0 || this.familySize > 49;
  isFamilyIncomeInvalid = () => this.familyIncome <= 0 || this.familyIncome > 999999;
  isPovertyCodeUnder = () => this.poveryCode < 5;
  isZipCodeMissing = () => (this.zipCode?.replaceAll('_', '')?.length ?? 0) == 0;
  //isZipCodeOutOfRange = () => (this.zipCode?.replaceAll('_', '')?.length ?? 0) != 5;
  isZipCodeInvalid = () => !this.cityState;
  isEligibilityValid = () => { return !this.isHivStatusInvalid() && !this.isFamilySizeInvalid() && !this.isFamilyIncomeInvalid() && !this.isZipCodeInvalid() };

  constructor(private route: ActivatedRoute,
              private router: Router,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private renderer: Renderer2,
              public ref: DynamicDialogRef, 
              public config: DynamicDialogConfig,
              private commonDataService:CommonDataService) { 
  }

  ngOnInit(): void {
    this.hivStatuses = this.commonDataService.getCommonSetByName("HivStatus", true)
                                             .filter(x => x.id != HIVStatus.HIVStatusUnknown)
                                             .map(data => { const result: any = { value:parseInt(`${data.id}`), label:`${data.description}`};
                                                                                  return result; });
    this.hivStatus = this.hivStatuses[0];                                                                                         
  }

  ngAfterViewInit() {
    // var elem = this.familyIncomeElement?.nativeElement;
    this.setFamilySizeElementInvalidClass();
    this.setFamilyIncomeElementInvalidClass();
    this.setZipCodeInvalidClass();
  }

  onContinue(event: Event) {
    //this.messageService.add({severity:'success', summary: 'Successful', detail: 'Service Deleted', life: 1000});
    var eligibility: Partial<NewClientModel> = { 
                                                  hivStatus: this.hivStatus?.value,
                                                  familySize: this.familySize,
                                                  familyIncome: this.familyIncome,
                                                  poveryCode: this.poveryCode,
                                                  zipCode: this.zipCode,
                                                  cityState: this.cityState,
                                                  povertyDescription: this.povertyDescription,
                                                  city: this.city,
                                                  state: this.state,
                                                  county: this.county
                                               };
        
    if (this.isPovertyCodeUnder()) {
      this.ref.close({ eligibility: eligibility });
    }
    else {
      this.messageService.add({severity:'info', summary: 'Information', detail: 'Clients with income greater than 500% of the federal poverty level are only eligible for EHE services. Other funding sources will be disabled.', sticky: true});
      this.confirmationService.confirm({
          target: event.target,
          message: 'Press Yes to continue to New Client or No to make additional eligibility changes.',
          header: 'Confirm',
          icon: 'pi pi-info-circle',
          accept: () => {
            this.ref.close({ eligibility: eligibility });
          },
          reject: () => {
              //this.messageService.add({severity: "error", summary: "Aborted", detail: "Delete canceled", life: 1000});
          }
      }); 
    }
  }

  onChangeFamilySize(event: any) {
    var familySize = parseInt(event.target.value?.replaceAll('_', ''));
    this.setPovertyLevel(this.familyIncome, familySize);
    this.familySize = familySize;
    this.setFamilySizeElementInvalidClass();
  }

  onChangeFamilyIncome(event: any) {
    // because maxlength doesn't work on inputNumber
    if (event.value > 999999) { 
      var numStr = event.value.toString().substring(0, 6);
      event.value = this.familyIncome = parseInt(numStr);
      return;
    }
    var familyIncome = event.value;
    this.setPovertyLevel(familyIncome, this.familySize);
    this.familyIncome = familyIncome;
    this.setFamilyIncomeElementInvalidClass();
  }

  onChangeZipCode(event: any) {
    var zip = event.target.value?.replaceAll('_', '');
    if (zip.length != 5) {
      this.cityState = null;
      this.setZipCodeInvalidClass();
      return;
    }
    this.commonDataService.getZipCodeData(zip).subscribe(    
      {
        next: (result: ZipCodeResult) => {
          if (result)
          {
            this.cityState = result?.city.concat(', ').concat(result?.state).concat(' ').concat(result?.county);
            this.city = result?.city;
            this.state = result?.state;
            this.county = result?.county;
          }
          else
            this.cityState = this.city = this.state = this.county = null;
        },
        error: (e) => console.error(e),
        complete:() => { this.setZipCodeInvalidClass(); }
      } );
  }

  setHIVStatusElementInvalidClass() {
    if (this.isHivStatusInvalid()) {
      this.renderer.addClass(this.hivStatusElement.inputViewChild.nativeElement, 'p-invalid'); 
    }
    else {
      this.renderer.removeClass(this.hivStatusElement.inputViewChild.nativeElement, 'p-invalid'); 
    }
  }

  setFamilySizeElementInvalidClass() { 
    if (this.isFamilySizeInvalid()) {
      this.renderer.addClass(this.familySizeElement.inputViewChild.nativeElement, 'p-invalid'); 
    }
    else {
      this.renderer.removeClass(this.familySizeElement.inputViewChild.nativeElement, 'p-invalid'); 
    }
  }

  setFamilyIncomeElementInvalidClass() { 
    if (this.isFamilyIncomeInvalid()) {
      this.renderer.addClass(this.familyIncomeElement.input.nativeElement, 'p-invalid'); 
    }
    else {
      this.renderer.removeClass(this.familyIncomeElement.input.nativeElement, 'p-invalid'); 
    }
  }

  setZipCodeInvalidClass() { 
    if (this.isZipCodeMissing() || this.isZipCodeInvalid()) {
      this.renderer.addClass(this.zipCodeElement.inputViewChild.nativeElement, 'p-invalid'); 
    }
    else {
      this.renderer.removeClass(this.zipCodeElement.inputViewChild.nativeElement, 'p-invalid'); 
    }
  }

  setPovertyLevel(familyIncome:number, familySize:number) {
    if (Number.isNaN(familyIncome)) familyIncome = 0;
    if (Number.isNaN(familySize)) familySize = 0;
    this.poveryCode = this.commonDataService.getPovertyLevelCode(familyIncome, familySize);
    this.povertyDescription = this.commonDataService.getPovertyLevelDescription(familyIncome, familySize);
    if (!this.povertyDescription) this.povertyDescription = "N/A";
  }
}
