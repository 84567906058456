import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ExistingClientModel } from '../../models/client.model'
import { ClientFormService } from '../../client-form/services/client-form.service'
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-existing-client-list',
  templateUrl: './existing-client-list.component.html',
  styleUrls: ['./existing-client-list.component.scss']
})

export class ExistingClientListComponent implements OnInit {
  //existingClients: ExistingClientModel[];
  existingClients$: Observable<ExistingClientModel[]>;
  params: any;

  constructor(private clientService: ClientFormService,
              private userService: UserService,
              private diagConfig: DynamicDialogConfig,
              public ref: DynamicDialogRef) {
    this.params = this.diagConfig?.data;
    if (!!this.params) {
      this.existingClients$ = of(this.params?.existingClients);
      // this.clientService.findClient(this.params?.providerId, this.params?.clientAltId, this.params?.clientId).subscribe(
      // {
      //   next: (result) => { if (!!result) { this.existingClients = result; this.existingClients$ = of(result); } },
      //   error: (error) => { this.userService.LogError(error); },
      //   complete: () => { if (this.existingClients?.length <= 0) this.ref.close({ existingClient: null }); }
      // });
    }
  }

  ngOnInit(): void {
  }

  selectClient(existingClient: ExistingClientModel) {
    this.ref.close({ existingClient: existingClient });
  }
}
