import { Component, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { ClientFormService } from '../client-form/services/client-form.service'
import { ClientRequiredFieldErrorModel } from '../models/client.model'
import { CommonDataService } from '../services/common-data.service';
import { replace } from 'lodash';

@Component({
  selector: 'app-client-required-field-errors',
  templateUrl: './client-required-field-errors.component.html',
  styleUrls: ['./client-required-field-errors.component.scss']
})
export class ClientRequiredFieldErrorsComponent implements OnInit {
  displayRequiredFieldErrorsDialog: boolean = false;
  alwaysVisible: boolean = true;
  clientRequiredFieldErrors$: Observable<ClientRequiredFieldErrorModel[]>;

  getServiceCategoryDescription = (serviceId: number) => { return (this.commonDataService.getServiceCategoryDescription(serviceId, true) ?? 'All Services'); }
  getMasterFieldDescription = (Id: number) => { return (this.commonDataService.getMasterFieldDescription(Id)); }
  getMasterFieldCategoryDescription = (Id: number) => { var fieldCategory = this.commonDataService.getMasterField(Id).fieldCategory;
                                                        var fieldCategoryDescription = this.commonDataService.getDescription("MasterFieldsCategory", fieldCategory)[0] ?? 'N/A';
                                                        return replace(fieldCategoryDescription, 'TAB Fields', ''); }

  constructor(public clientService: ClientFormService, private commonDataService: CommonDataService) { }

  ngOnInit(): void {
  }

  onShowRequiredFieldErrorsDialog() {
    //if (!this.displayRequiredFieldErrorsDialog) {
      this.displayRequiredFieldErrorsDialog = this.alwaysVisible = true;
      this.clientRequiredFieldErrors$ = of(this.clientService.clientRequiredFieldErrors);
    //}
  }

  onHideRequiredFieldErrorsDialog() {
    this.displayRequiredFieldErrorsDialog = false;
  }
}
