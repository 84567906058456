<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<!-- 
isServiceEditable = {{ isServiceEditable() }}
isServiceBillable = {{ isServiceBillable() }}
this.getBulkGroupIdentifierControl() = {{ this.getBulkGroupIdentifierControl()?.value }}    
DeliveredTime = {{ this.getDeliveredTime() }}
DeliveredTime Errors = {{ fg.controls['deliveredTime'].errors?.required }}
getDeliveredTimeControl()?.value
    fg.controls['deliveredTime']?.value
Alt Id Total Count {{ fg.controls['clientAltIds']?.value?.length }}
Alt Id Selected Count {{ getClientAltIdsControl()?.value.length }} -->

<form [formGroup]='fg' autocomplete="off">
    <div class="card champ-card">
        <div class="card-body champ-card-body"> <!-- card-body -->
            <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                <div class="field col-2 champ-page-title">
                    @if (isBulkMode()) {
                        <span>Bulk Services</span>
                    }
                    @else {
                        <span>Group Services</span>
                    }
                </div>
                <!-- <div class="field col-1">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label for="month" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Month</label></span>
                    <p-dropdown formControlName="month" [options]="months" optionLabel="name" optionValue="code" appendTo="body" (onChange)="onChangeMonth($event.value)"> </p-dropdown>
                </div> -->

                <!-- <div class="field col-1">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label for="year" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Year</label></span>
                    <p-dropdown formControlName="year" [options]="years" appendTo="body" (onChange)="onChangeYear($event.value)"> </p-dropdown>
                </div> -->

                <!-- <div class="field col-2">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label htmlFor="deliveredDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Delivery Date</label></span>
                    <div class="formgroup-inline">
                        <p-inputMask formControlName="deliveredDate" mask="99-99-9999" placeholder="99-99-9999" slotChar="mm-dd-yyyy" [readonly]="true"></p-inputMask>                        
                    </div>
                </div> -->

                <div class="field col-1" style="padding-left:3rem" [ngStyle]="!showDateControls ? {'display': 'block'} : {'display': 'none'}"></div>
                <div class="field col-1" style="padding-left:3rem" [ngStyle]="showDateControls ? {'display': 'block'} : {'display': 'none'}">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label htmlFor="deliveredDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Delivery Date</label></span>
                    <div class="formgroup-inline">
                        <input type="text" name="deliveredDateName" autocomplete="off" bsDatepicker formControlName="deliveredDate"
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="deliveredDateID"    
                                placeholder="Delivery Date"
                                [ngClass]="{'p-invalid' : fg.controls['deliveredDate'].invalid}"
                                class="champ-date-picker" pInputText style="z-index: 1000 !important;"/>
                    </div>
                    <div *ngIf="fg.controls['deliveredDate'].invalid && (fg.controls['deliveredDate'].dirty || fg.controls['deliveredDate'].touched)">
                        <div *ngIf="fg.controls['deliveredDate'].errors.required">
                            <small id="dsDeliveredDate-help" class="p-invalid">*Delivered Date is required.</small>
                        </div>
                        <div *ngIf="fg.controls['deliveredDate'].errors">
                            <small id="dsDeliveredDate-help" class="p-invalid">{{fg.controls['deliveredDate'].errors['invalidDate']}}</small>
                        </div>
                    </div>
                </div>

                <div class="field col-3" [ngStyle]="!showDateControls ? {'display': 'block'} : {'display': 'none'}"></div>
                <div class="field col-3" [ngStyle]="showDateControls ? {'display': 'block'} : {'display': 'none'}">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label htmlFor="deliveredTime" style="margin-top: 0.125rem; margin-bottom: 0.25rem;">Delivery Time</label></span>
                    <timepicker formControlName="deliveredTime" [showSpinners]="false"></timepicker>
                    <!-- <p-inputMask mask="23:59" formControlName="deliveredTime" placeholder="23:59"></p-inputMask> -->
                    <!-- <div *ngIf="fg.controls['deliveredTime'].invalid && (fg.controls['deliveredTime'].dirty || fg.controls['deliveredTime'].touched)"> -->
                        <div *ngIf="!!fg.controls['deliveredTime'].errors">
                            <small id="deliveredTime-help" class="p-invalid">*Invalid Delivered Time.</small>
                        </div>
                    <!-- </div> -->
                </div>

                <!-- <div class="field col-1"></div> -->
            </div>

            <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                <div class="field col-4">
                    <!-- <span style="vertical-align:text-top"><label for="bulkGroupIdentifier" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Group Identifier</label></span> -->
                    <span style="vertical-align: text-top">
                        <label class="control-label mr-2" for="bulkGroupIdentifier" style="margin-top: 0.125rem;">Group Identifier</label>
                        <label class="control-label mr-2" for="bulkGroupIdentifier" style="color: #0000ff; font-weight: bold; font-size: smaller; margin-bottom: 0.5rem;">[{{ bulkGroupIdentifiers?.length - 1 }} existing]</label>                                            
                    </span>
                    <p-dropdown formControlName="bulkGroupIdentifier" [options]="bulkGroupIdentifiers" optionLabel="bulkGroupDescription" optionValue="bulkGroupGuid" appendTo="body" 
                                (onChange)="onChangeBulkGroupIdentifier($event.value)"></p-dropdown>
                </div>

                <div class="field col-8">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align: text-top">
                        <label class="control-label mr-2" for="client" style="margin-top: 0.125rem;">Client Alt Ids</label>
                        <label class="control-label mr-2" for="client" style="color: #0000ff; font-weight: bold; font-size: smaller; margin-bottom: 0.5rem;">[{{ getClientAltIdsControl()?.value.length }} selected]</label>                                            
                        <button style="width: 5rem;" pButton pRipple type="button" label="Clear" class="p-button-text p-button-sm champ_history_button" (click)="onClearClientAltIds()"></button>
                    </span>
                    <!-- <span><button pButton pRipple type="button" label="Clear" class="p-button-text p-button-sm champ_history_button" (click)="onClearClientAltIds()"></button></span> -->
                    <p-multiSelect id="client" formControlName="clientAltIds" [options]="this.clientAltIds" optionLabel="clientAltId" optionValue="clientGuid" appendTo="body"
                                   [maxSelectedLabels]="10" [virtualScroll]="true" [virtualScrollItemSize]="50" [showToggleAll]="false" (onChange)="onClientAltIdChange($event)"
                                   display="chip" [style]="{'width': '100%', 'min-width':'2rem'}"> 
                                
                                   <ng-template let-option pTemplate="item" >
                                        <!-- <div class="p-0 m-0" > -->
                                            <!-- <span class="ml-1 mt-1" >{{ option.clientAltId }}</span> -->
                                        <!-- </div> -->
                                        <!-- <div class="flex gap-0">
                                            {{ option.clientAltId }}
                                        </div> -->
                                        <div class='clientAltIdItem' [class.errors]="option.hasErrors" >{{ option.clientAltId }}</div>
                                 </ng-template>
                                
                    </p-multiSelect>
                    <!-- (onChange)="onClientChange($event)" (onSelectAllChange)="onClientSelectAllChange($event)"                                    -->
                    <div *ngIf="!!fg.controls['clientAltIds'].errors && !!fg.controls['clientAltIds'].errors['invalidRequiredField']"> 
                        <small id="serviceId-help" class="p-invalid">*Client Alt Id is required.</small>
                    </div>                    
                </div>
            </div>

            <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                <div class="field col-4">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label for="funder" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Contract/Program</label></span>
                    <p-dropdown formControlName="funder" [options]="contractFunders" optionLabel="name" optionValue="keys" appendTo="body" 
                                (onChange)="onChangeFunder($event.value)"></p-dropdown>
                    <div *ngIf="!!fg.controls['funder'].errors && !!fg.controls['funder'].errors['invalidRequiredField']"> 
                        <small id="funder-help" class="p-invalid">*Contract/Program is required.</small>
                    </div>                         
                </div>

                <div class="field col-4">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label for="serviceId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Service Category</label></span>
                    <p-dropdown formControlName="serviceId" [options]="serviceCategories" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeServiceType($event.value)"> </p-dropdown>
                    <!-- <div *ngIf="!fg.get('serviceId').value || fg.get('serviceId').value == 0">
                        <small id="serviceId-help" class="p-invalid">*Service Category is required.</small>
                    </div> -->
                    <div *ngIf="!!fg.controls['serviceId'].errors && !!fg.controls['serviceId'].errors['invalidRequiredField']"> 
                        <small id="serviceId-help" class="p-invalid">*Service Category is required.</small>
                    </div>                            
                </div>

                <div class="field col-4">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label for="subId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Sub Category</label></span>
                    <!-- <button *ngIf="qualifyForAssessment() && !hasInitialAssessmentChanged()" pButton pRipple type="button" label="Assessment" class="p-button-text p-button-sm" style="height: 20px;width: 100px;text-decoration: underline;float:right" (click)="onClickShowAssessment(directService, true)"></button> -->
                    <!-- <button *ngIf="!!this.directService?.clientMedicalRiskReduction && qualifyForRiskReduction()" pButton pRipple type="button" label="Risk Reduction" class="p-button-text p-button-sm" style="height: 20px;width: 100px;text-decoration: underline;float:right" (click)="onClickShowRiskReduction(directService, true)"></button> -->
                    <p-dropdown formControlName="subId" [options]="subTypes" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeSubType($event.value)"> 
                        <ng-template let-subType pTemplate="item">
                            <div style="width: 125px;">
                                <div style="font-weight: bold;">{{subType.description}}</div>
                                <div style="font-size: smaller;font-weight: 300;"><span title="{{subType.longDescription}}">{{subType.longDescription}}</span></div>
                            </div>
                        </ng-template>
                    </p-dropdown>
                    <div *ngIf="!!fg.controls['subId'].errors && !!fg.controls['subId'].errors['invalidRequiredField']"> 
                        <small id="subId-help" class="p-invalid">*Sub Type is required.</small>
                    </div>
                </div>   
            </div>

            <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                <div class="field col-4">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> <!-- [styles]="{'color': 'red', 'padding': '0px 3px 0px 0px'}" -->
                    <span style="vertical-align:text-top"><label for="employeeId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Employee</label></span>
                    <p-dropdown formControlName="employeeId" [options]="employees" optionLabel="fullName" optionValue="id" appendTo="body"> </p-dropdown>
                    <!-- <div *ngIf="!fg.get('employeeId').value || fg.get('employeeId').value == 0">
                        <small id="employeeId-help" class="p-invalid">*Employee is required.</small>
                    </div> -->
                    <div *ngIf="!!fg.controls['employeeId'].errors && !!fg.controls['employeeId'].errors['invalidRequiredField']"> 
                        <small id="employeeId-help" class="p-invalid">*Employee is required.</small>
                    </div>                         
                </div>
            
                <div class="field col-4">
                    <label for="barrier" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Barrier</label>
                    <p-dropdown formControlName="barrier" [options]="barriers" optionLabel="description" optionValue="id" appendTo="body"> </p-dropdown>
                    <div *ngIf="!!fg.errors?.invalidBarrier">
                        <small id="barrier-help" class="p-invalid">{{fg.errors?.invalidBarrier}}</small> 
                    </div>
                </div>

                <div class="field col-4">
                    <label for="notes" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Notes</label>
                    <textarea formControlName="notes" id="notes" rows="5" cols="25" pInputTextarea style="height:40px;"></textarea>
                </div>                  
            </div>
            
            <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                <div class="field col-1">
                    <label for="billable" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Billable</label>
                    <div class="formgroup-inline">
                        <div class="field-checkbox">
                            <p-checkbox [style]="{'width': '30px', 'padding': '0px 0px 0px 7px'}" formControlName="billable" binary="true" inputId="billable" (onChange)="onChangeBillable($event.checked)"></p-checkbox>
                        </div>
                    </div>
                </div>

                <div class="field col-1">
                    <label htmlFor="plannedUnits" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Planned</label>
                    <div style="width: 5rem;">
                        <p-inputNumber formControlName="plannedUnits" inputId="plannedUnits" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2"
                                       [min]="1" [max]="9999.99"></p-inputNumber>
                    </div>
                </div>

                <div class="field col-1">
                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                    <span style="vertical-align:text-top"><label htmlFor="deliveredUnits" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Delivered Units</label></span>
                    <div style="width: 5rem;">
                        <p-inputNumber formControlName="deliveredUnits" inputId="deliveredUnits" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="2"
                                       [min]="1" [max]="9999.99"></p-inputNumber>
                    </div>
                    <div *ngIf="fg.controls['deliveredUnits'].invalid && (fg.controls['deliveredUnits'].dirty || fg.controls['deliveredUnits'].touched)">
                        <div *ngIf="fg.controls['deliveredUnits'].errors.required">
                            <small id="deliveredUnits-help" class="p-invalid">*Delivered Units are required.</small>
                        </div>
                    </div>
                </div>

                <div class="field col-2">
                    <div><label for="unit" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Unit</label></div>
                    <input type="text" formControlName="unit" inputId="unit" style="width:140px; height: 28px" >
                    <!-- disabled="true" -->
                </div>

                <div class="field col-5"></div>

                <div class="field col-1">
                    <div><label for="generate" style="margin-top: 0.125rem; margin-bottom: 0.5rem;"></label></div>
                    <p-button pButton pRipple type="button" [ngStyle]="{'width':'10rem'}"  styleClass="p-button-text " class="p-button-text mr-1 " (click)="onGenerateConfirm($event)"
                              [disabled]="!fg.valid || !isServiceEditable()  ">  
                        <i class="fa-sharp fa-solid fa-check fa-lg" pTooltip="Services must be generated before you can save."></i>
                        <span>Generate</span>
                    </p-button>
                    <!-- <button style="width: 130px;float: right;margin-right: 5px;margin-top: 0.5rem;"></button> -->
                </div>

                <div class="field col-1">
                    <div><label for="save" style="margin-top: 0.125rem; margin-bottom: 0.5rem;"></label></div>
                    <p-button pButton pRipple type="button" [ngStyle]="{'width':'10rem'}"  styleClass="p-button-text " class="p-button-text mr-1" (click)="onSaveDirectServiceChangesConfirm($event)"
                              [disabled]="((bulkGroupServices?.length ?? 0) == 0) || !isServiceEditable() || !this.generated  
                                                     && !(this.userService?.hasAdminPermission || this.userService?.hasEditPermissions)" > 
                        <i class="fa-sharp fa-solid fa-check fa-lg" pTooltip="Saves all generated services."></i>
                        <span>Save</span>
                    </p-button>
                    <!-- <button pButton pRipple class="btn btn-primary" (click)="onSaveDirectServiceChangesConfirm($event)" icon="pi pi-check"
                            label="Save" [disabled]="((bulkGroupServices?.length ?? 0) == 0) || !isServiceEditable()" style="width: 130px;float: right;margin-right: 5px;margin-top: 0.5rem;"></button> -->
                </div>
            </div>

            <div class="p-fluid p-formgrid grid" style="padding-top: 15px;">
                <p-table style="width: 100%;" #dtBulkGroupServices [value]="bulkGroupServices" selectionMode="single" [scrollable]="true" scrollDirection="both" scrollHeight="25vh" styleClass="p-datatable-sm p-datatable-striped"> 
                    <ng-template pTemplate="header">
                        <tr>
                            <!-- <th pFrozenColumn style="width:4rem"><p-tableHeaderCheckbox class="ml-1"></p-tableHeaderCheckbox></th> -->
                            <!-- <th style="min-width: 4rem"></th> -->
                            <th pSortableColumn="clientAltId" style="min-width: 15rem;">Client Alt Id<p-sortIcon field="clientAltId"></p-sortIcon></th>
                            <th pSortableColumn="deliveredDate" style="min-width: 10rem;">Delivered Date<p-sortIcon field="deliveredDate"></p-sortIcon></th>
                            <!-- <th pSortableColumn="deliveredTime" style="min-width: 10rem;">Delivered Time<p-sortIcon field="deliveredTime"></p-sortIcon></th> -->
                            <th pSortableColumn="serviceCategory" style="min-width: 18rem;">Service Category<p-sortIcon field="serviceCategory"></p-sortIcon></th>
                            <th pSortableColumn="subType" style="min-width: 15rem;">Sub Category<p-sortIcon field="subType"></p-sortIcon></th>
                            <th pSortableColumn="plannedUnits" style="min-width: 10rem;">Planned Units<p-sortIcon field="plannedUnits"></p-sortIcon></th>
                            <th pSortableColumn="deliveredUnits" style="min-width: 10rem;">Delivered Units<p-sortIcon field="deliveredUnits"></p-sortIcon></th>
                            <th pSortableColumn="billable" style="min-width: 5rem;">Billable<p-sortIcon field="billable"></p-sortIcon></th>
                            <th pSortableColumn="unBillableReason" style="min-width: 20rem;">Unbillable Reason<p-sortIcon field="unBillableReason"></p-sortIcon></th>
                            <th pSortableColumn="notes">Notes<p-sortIcon field="notes"></p-sortIcon></th>
                        </tr>
                        <tr>
                            <!-- <th style="min-width: 4rem;"></th> -->
                            <th style="min-width: 15rem;">
                                <p-columnFilter type="text" field="clientAltId" matchMode="contains"></p-columnFilter>
                            </th>
                            <th style="min-width: 10rem;">
                                <!-- <p-columnFilter type="text" field="deliveredDate" matchMode="contains"></p-columnFilter> -->
                            </th>
                            <!-- <th style="min-width: 10rem;"> -->
                                <!-- <p-columnFilter type="text" field="deliveredTime" matchMode="contains"></p-columnFilter> -->
                            <!-- </th> -->
                            <th style="min-width: 15rem;">
                                <!-- <p-columnFilter type="numeric" field="serviceCategory" matchMode="contains"></p-columnFilter> -->
                            </th>
                            <th style="min-width: 15rem;">
                                <!-- <p-columnFilter type="text" field="subType" matchMode="contains"></p-columnFilter> -->
                            </th>
                            <th style="min-width: 10rem;">
                                <!-- <p-columnFilter type="text" field="plannedUnits" matchMode="contains"></p-columnFilter> -->
                            </th>
                            <th style="min-width: 10rem;">
                                <!-- <p-columnFilter type="text" field="deliveredUnits" matchMode="contains"></p-columnFilter> -->
                            </th>
                            <th style="min-width: 5rem;">
                                <p-columnFilter type="boolean" field="billable"></p-columnFilter>
                            </th>
                            <th style="min-width: 20rem;">
                                <!-- <p-columnFilter type="text" field="unBillableReason" matchMode="contains"></p-columnFilter> -->
                            </th>
                            <th style="min-width: 40rem;">
                                <!-- <p-columnFilter type="text" field="notes" matchMode="contains"></p-columnFilter> -->
                            </th>                            
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-source let-editing="editing" let-rowIndex="rowIndex">
                        <tr>  <!-- [pEditableRow]="event" -->
                            <!-- <td style="min-width: 4rem;"><p-tableCheckbox [value]="source"></p-tableCheckbox></td> -->
                            <td style="min-width: 15rem;"><span title="{{ source?.clientAltId }}"> {{ source?.clientAltId }} </span></td>
                            <td style="min-width: 10rem;"><span title="{{ source?.deliveredDate | date: 'MM-dd-yyyy' }} {{ source?.deliveredTime }}"> {{ source?.deliveredDate | date: 'MM-dd-yyyy' }} {{ source?.deliveredTime }} </span></td>
                            <!-- <td style="min-width: 10rem;"><span title="{{ source?.deliveredTime }}"> {{ source?.deliveredTime }} </span></td> -->
                            <td style="min-width: 18rem;"><span title="{{ source?.serviceCategory }}"> {{ source?.serviceCategory }} </span></td>
                            <td style="min-width: 15rem;"><span title="{{ source?.subType }}"> {{ source?.subType }} </span></td>
                            <td style="min-width: 10rem;"><span title="{{ source?.plannedUnits }}"> {{ source?.plannedUnits }} </span></td>
                            <td style="min-width: 10rem;"><span title="{{ source?.deliveredUnits }}"> {{ source?.deliveredUnits }} </span></td>
                            <!-- <td style="min-width: 5rem;"><span title="{{ source.billable }}"> {{ source?.billable }} </span></td> -->
                            <td style="min-width: 5rem;">
                                <i class="pi" [ngClass]="{'true-icon pi-check-circle': source?.billable, 'false-icon pi-times-circle': !source?.billable}"></i>
                            </td>
                            <td style="min-width: 20rem;"><span title="{{ source?.unbillableReason }}"> <a [ngClass]="'pointer-hyperlink'" (click)="showClientRequiredFieldErrors(source)">{{ source?.unbillableReason }} </a></span></td>
                            <td><span title="{{ source?.notes }}"> {{ source?.notes }} </span></td>
                        </tr>
                    </ng-template>
                    <tr></tr>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="10">Count: {{bulkGroupServices?.length}}</td>
                        </tr>
                    </ng-template>
                </p-table>                
            </div>

            <!-- <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                <div class="field col-11"></div>

            </div> -->
        </div>
    </div>

    <!-- Client Required Field Error Dialog -->
    <app-client-required-field-errors></app-client-required-field-errors>    
</form>