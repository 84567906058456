<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]='fg' autocomplete="off">
    <div class="p-container-fluid champ-card-body-sm">
        <div class="p-fluid p-formgrid">
            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-4">
                        <label for="providerName" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Provider Name</label>
                        <input type="text" formControlName="providerName" pInputText />
                        <div *ngIf="fg.controls['providerName'].invalid">
                            <small id="providerName-help" class="p-invalid">Provider Name is required.</small>
                        </div>                       
                    </div>

                    <div class="field col-2">
                        <label for="active" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Active</label>
                        <div class="field-checkbox">
                            <p-checkbox formControlName="active" binary="true"></p-checkbox>
                        </div>
                    </div>

                    <div class="field col-2">
                        <label for="funded" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Funded</label>
                        <div class="field-checkbox">
                            <p-checkbox formControlName="funded" binary="true"></p-checkbox>
                        </div>
                    </div>

                    <div class="field col-2">
                        <label for="deleted" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Deleted</label>
                        <div class="field-checkbox">
                            <p-checkbox formControlName="deleted" binary="true"></p-checkbox>
                        </div>
                    </div>

                    <div class="field col-2">
                        <label for="test" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Test</label>
                        <div class="field-checkbox">
                            <p-checkbox formControlName="test" binary="true"></p-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-6">
                        <label for="addressLine1" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Address Line 1</label>
                        <input type="text" formControlName="addressLine1" pInputText />
                        <div *ngIf="fg.controls['addressLine1'].invalid">
                            <small id="addressLine1-help" class="p-invalid">Address is required.</small>
                        </div>
                    </div>

                    <div class="field col-6">
                        <label for="addressLine2" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Address Line 2</label>
                        <input type="text" formControlName="addressLine2" pInputText />
                    </div>
                </div>
            </div>
            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-4">
                        <label for="city" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">City</label>
                        <input type="text" formControlName="city" pInputText readonly="readonly"/>
                        <div *ngIf="fg.controls['city'].invalid">
                            <small id="city-help" class="p-invalid">City is required.</small>
                        </div>
                    </div>

                    <div class="field col-1">
                        <label for="state" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">State</label>
                        <input type="text" formControlName="state" pInputText readonly="readonly"/>
                        <div *ngIf="fg.controls['state'].invalid">
                            <small id="state-help" class="p-invalid">State is required.</small>
                        </div>
                    </div>                    

                    <div class="field col-2">
                        <label for="zip" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Zip</label>
                        <!-- <input type="text" formControlName="zip" pInputText /> -->
                        <div style="width: 5rem;">
                            <p-inputMask formControlName="zip" mask="9?9999" slotChar= "" maxlength="5" (onInput)="onChangeZipCode($event)" [ngClass]="{'p-invalid' : isZipCodeMissing() || isZipCodeInvalid()}"></p-inputMask>
                        </div>
                        <div *ngIf="isZipCodeMissing()"> 
                            <small id="zipCode-help" class="p-invalid">Zip Code is required.</small>
                        </div>
                        <div *ngIf="!isZipCodeMissing() && isZipCodeInvalid()"> 
                            <small id="zipCode-help" class="p-invalid">Invalid Zip Code.</small>
                        </div>                        
                    </div>
                </div>                                
            </div>
            <div class="p-col-12" style="padding:.5em">
                <div class="p-fluid p-formgrid grid">
                    <div class="field col-6">
                        <label for="email" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Email</label>
                        <input type="text" formControlName="email" pInputText />
                    </div>

                    <div class="field col-6">
                        <label for="url" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">URL</label>
                        <input type="text" formControlName="url" pInputText />
                    </div>
                </div>                                
            </div>
        </div>
        <div class="p-col-12" style="padding:.5em">
            <div class="p-fluid p-formgrid grid">
                <div class="field col-2">
                    <label for="mainPhone" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Phone</label>
                    <div style="width: 8rem;">
                        <!-- <input type="text" formControlName="mainPhone" pInputText /> -->
                        <p-inputMask mask="(999) 999-9999" formControlName="mainPhone" unmask="true"></p-inputMask>
                    </div>
                </div>
                
                <div class="field col-2">
                    <label for="mainFax" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Fax</label>
                    <div style="width: 8rem;">
                        <!-- <input type="text" formControlName="mainFax" pInputText /> -->
                        <p-inputMask mask="(999) 999-9999" formControlName="mainFax" unmask="true"></p-inputMask>
                    </div>                    
                </div>

                <div class="field col-2">
                    <label for="site" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Site</label>
                    <input type="text" formControlName="site" pInputText />
                </div>

                <div class="field col-6">
                    <app-employee mode="provider" (onChange)="onEmployeeChange($event)"></app-employee>
                </div>

<!--                 <div class="field col-2">
                    <label for="rsrProviderId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">RSR Provider ID</label>
                    <input type="text" formControlName="rsrProviderId" pInputText />
                </div> -->
            </div>
        </div>
        <div class="p-col-12" style="padding:.5em">
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12">
                    <label for="memo" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Memo</label>
                    <textarea formControlName="memo" rows="3" cols="1" pInputTextarea style="height:40px;"></textarea>
                </div>
            </div>
        </div>
        <!-- <div class="p-col-12" style="padding:.5em"><b>Options:</b></div> -->
        <div class="p-col-12" style="padding:.5em">
            <div class="p-fluid p-formgrid grid" formGroupName="providerOptions">
                <div class="field col-3">
                    <label for="clientStatusExpirationDays" style="margin-top: 0.125rem; margin-bottom: 0.5rem;" pTooltip="Days to Inactive">Client Status Expiration</label>
                    <div style="width: 5rem;">
                        <p-inputMask formControlName="clientStatusExpirationDays" mask="9?99" slotChar= "" maxlength="3"></p-inputMask>
                    </div>
                </div>

                <div class="field col-3">
                    <label for="adminBillableDays" style="margin-top: 0.125rem; margin-bottom: 0.5rem;" pTooltip="Days from end of the month">Billable Cut-Off</label>
                    <div style="width: 5rem;">
                        <p-inputMask formControlName="adminBillableDays" mask="9?99" slotChar= "" maxlength="3"></p-inputMask>
                    </div>
                </div>

                <div class="field col-3">
                    <label for="medicalChartReevaluationDays" style="margin-top: 0.125rem; margin-bottom: 0.5rem;" pTooltip="Medical Chart Re-Eval # of Days">Medical Chart Re-Eval</label>
                    <div style="width: 5rem;">
                        <p-inputMask formControlName="medicalChartReevaluationDays" mask="9?99" slotChar= "" maxlength="3"></p-inputMask>
                    </div>
                </div>

                <div class="field col-3">
                    <label for="referralExpirationDays" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Referral Expiration Days</label>
                    <div style="width: 5rem;">
                        <p-inputNumber formControlName="referralExpirationDays" mask="9?99" slotChar= "" maxlength="3"></p-inputNumber>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-col-12" style="padding:.5em">
            <div class="p-fluid p-formgrid grid" formGroupName="providerOptions">
                <div class="field col-3">
                    <label for="clientStatusResetValue" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Client Status Reset Value</label>
                    <!-- <p-inputMask formControlName="clientStatusResetValue" mask="9?99" maxlength="3"></p-inputMask> -->
                    <p-dropdown formControlName="clientStatusResetValue" [options]="clientStatusResetValues" optionLabel="description" optionValue="id" appendTo="body"> </p-dropdown>
                </div>

                <div class="field col-3">
                    <label for="cd4AidsLevel" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">CD4 Aids Level</label>
                    <div style="width: 5rem;">
                        <p-inputNumber formControlName="cd4AidsLevel" mask="9?99" slotChar= "" maxlength="3"></p-inputNumber>
                    </div>
                </div>

                <div class="field col-3">
                    <label for="viralLoadAidsLevel" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Viral Load Aids Level</label>
                    <div style="width: 5rem;">
                        <p-inputNumber formControlName="viralLoadAidsLevel" mask="9?99" slotChar= "" maxlength="3"></p-inputNumber>
                    </div>
                </div>

                <!-- <div class="field col-1"></div> -->

                <!-- <div class="field col-1">
                    <label for="admin" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Admin</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="admin" binary="true"></p-checkbox>
                    </div>
                </div> -->
                
                <div class="field col-2">
                    <label for="useAssessment" style="margin-top: 0.125rem; margin-bottom: 0.5rem;" pTooltip="Use Assessment Screen">Use Assessment</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="useAssessment" binary="true"></p-checkbox>
                    </div>
                </div>                
            </div>
        </div>
<!--         <div class="p-col-12" style="padding:.5em">
            <div class="p-fluid p-formgrid grid" formGroupName="providerOptions">
                <div class="field col-2">
                    <label for="messagingEnabled" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Messaging Enabled</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="messagingEnabled" binary="true"></p-checkbox>
                    </div>
                </div>

                <div class="field col-2">
                    <label for="emailEnabled" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Email Enabled</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="emailEnabled" binary="true"></p-checkbox>
                    </div>
                </div>

                <div class="field col-2">
                    <label for="useEmailAddress" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Use Email</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="useEmailAddress" binary="true"></p-checkbox>
                    </div>
                </div>

                <div class="field col-2">
                    <label for="useFullNames" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Use Full Names</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="useFullNames" binary="true"></p-checkbox>
                    </div>
                </div>

                <div class="field col-2">
                    <label for="useAddressInformation" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Use Address</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="useAddressInformation" binary="true"></p-checkbox>
                    </div>
                </div>

                <div class="field col-2">
                    <label for="useFullSsn" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Use Full SSN</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="useFullSsn" binary="true"></p-checkbox>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="p-col-12" style="padding:.5em">
            <div class="p-fluid p-formgrid grid" formGroupName="providerOptions">
                <div class="field col-3">
                    <label for="showContractedServicesOnly" style="margin-top: 0.125rem; margin-bottom: 0.5rem;" pTooltip="Show Contracted Services Only">Show Contracted Services</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="showContractedServicesOnly" binary="true"></p-checkbox>
                    </div>
                </div>

                <!-- <div class="field col-3">
                    <label for="useAssessment" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Use Assessment Screen</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="useAssessment" binary="true"></p-checkbox>
                    </div>
                </div> -->

                <div class="field col-3">
                    <label for="funderBillingRestrictionEncounter" style="margin-top: 0.125rem; margin-bottom: 0.5rem;" pTooltip="Funder Billing Restriction Encounter">Restriction Encounter</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="funderBillingRestrictionEncounter" binary="true"></p-checkbox>
                    </div>
                </div>

                <div class="field col-3">
                    <label for="funderBillingRestrictionMonthly" style="margin-top: 0.125rem; margin-bottom: 0.5rem;" pTooltip="Funder Billing Restriction Monthly">Restriction Monthly</label>
                    <div class="field-checkbox">
                        <p-checkbox formControlName="funderBillingRestrictionMonthly" binary="true"></p-checkbox>
                    </div>
                </div>

                <!-- <div class="col-1"></div> -->

                <!-- <div class="col-1" style="margin: .5rem; float: right;"> -->
                <div class="col-2">
                    <!-- <button pButton pRipple [ngClass]="'champ-button'" icon="pi pi-check" label="Save" (click)="onSaveProvider($event)"
                    [disabled]="!fg.valid"></button> -->

                    <p-button  pButton pRipple type="button" [ngStyle]="{'width':'12rem'}"   styleClass="p-button-text " class=" p-button-text mr-1 "
                        (click)="onSaveProvider($event)" [disabled]="!fg.valid"  > 
                            <i class="fa-sharp fa-regular fa-cloud-arrow-up fa-xl" ></i><span class="text-lg">Save</span>
                     </p-button> 
                </div>
            </div>
        </div>

        <!-- <div class="flex align-items-end col-12" style="padding:1.5em">
            <div class="col-10"></div>
            <div class="col-2" style="margin: .5rem; float: right;">
                <button pButton pRipple [ngClass]="'champ-button'" icon="pi pi-check"
                        (click)="onSaveProvider($event)" label="Save">
                </button>
            </div>
        </div> -->
    </div>
</form>        