import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCommon, ClientMedicalInfluenzaVaccinationModel, ClientMedicalPneumoccocalVaccinationModel, ClientMedicalHepatitisBVaccinationModel } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields } from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { faGalacticSenate } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-vaccinations',
  templateUrl: './vaccinations.component.html',
  styleUrls: ['./vaccinations.component.scss']
})
export class VaccinationsComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  influenzaNoReasons: CommonData[];
  pneumoccocalNoReasons: CommonData[];
  hepatitisBNoReasons: CommonData[];
  MedicalYesNo = MedicalYesNo;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();

  getInfluenzaVaccinationScreenedControl = () => this.medical?.get('idFluVaxScreened');
  getInfluenzaVaccinationScreenedControlValue = () => this.getInfluenzaVaccinationScreenedControl()?.value == this.MedicalYesNo.Yes;
  setInfluenzaVaccinationScreenedControlValue = (value: boolean) => this.getInfluenzaVaccinationScreenedControl().setValue(value ? this.MedicalYesNo.Yes : this.MedicalYesNo.No);
  getInfluenzaVaccinationScreenedNoReasonControl = () => this.medical?.get('idFluVaxScreenedNoReason');
  getInfluenzaVaccinationResultDateControl = () => this.medical?.get('idFluVaxScreenedDate');
  showInfluenzaVaccinationNoReason = () => (this.getInfluenzaVaccinationScreenedControl()?.value ?? 0) == this.MedicalYesNo.No;
  showInfluenzaVaccinationResultFields = () => (this.getInfluenzaVaccinationScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  getInfluenzaVaccinationScreenedNoReasonDescription = () => this.influenzaNoReasons.find(f => f.id == this.getInfluenzaVaccinationScreenedNoReasonControl().value)?.description;

  getPneumoccocalVaccinationScreenedControl = () => this.medical?.get('idPneumoVaxScreened');
  getPneumoccocalVaccinationScreenedControlValue = () => this.getPneumoccocalVaccinationScreenedControl()?.value == this.MedicalYesNo.Yes;
  setPneumoccocalVaccinationScreenedControlValue = (value: boolean) => this.getPneumoccocalVaccinationScreenedControl().setValue(value ? this.MedicalYesNo.Yes : this.MedicalYesNo.No);
  getPneumoccocalVaccinationScreenedNoReasonControl = () => this.medical?.get('idPneumoVaxScreenedNoReason');
  getPneumoccocalVaccinationResultDateControl = () => this.medical?.get('idPneumoVaxScreenedDate');
  showPneumoccocalVaccinationNoReason = () => (this.getPneumoccocalVaccinationScreenedControl()?.value ?? 0) == this.MedicalYesNo.No;
  showPneumoccocalVaccinationResultFields = () => (this.getPneumoccocalVaccinationScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  getPneumoccocalVaccinationNoReasonDescription = () => this.pneumoccocalNoReasons.find(f => f.id == this.getPneumoccocalVaccinationScreenedNoReasonControl().value)?.description;

  getHepatitisBVaccinationScreenedControl = () => this.medical?.get('idHepBVaxScreened');
  getHepatitisBVaccinationScreenedControlValue = () => this.getHepatitisBVaccinationScreenedControl()?.value == this.MedicalYesNo.Yes;
  setHepatitisBVaccinationScreenedControlValue = (value: boolean) => this.getHepatitisBVaccinationScreenedControl().setValue(value ? this.MedicalYesNo.Yes : this.MedicalYesNo.No);
  getHepatitisBVaccinationScreenedNoReasonControl = () => this.medical?.get('idHepBVaxScreenedNoReason');
  getHepatitisBVaccinationResultDateControl = () => this.medical?.get('idHepBVaxScreenedDate');
  showHepatitisBVaccinationNoReason = () => (this.getHepatitisBVaccinationScreenedControl()?.value ?? 0) == this.MedicalYesNo.No;
  showHepatitisBVaccinationResultFields = () => (this.getHepatitisBVaccinationScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  getHepatitisBVaccinationScreenedNoReasonDescription = () => this.hepatitisBNoReasons.find(f => f.id == this.getHepatitisBVaccinationScreenedNoReasonControl().value)?.description;

  getClientMedicalVaccinationFormattedDate = (date: string) => { if ((!date) || (date === '') || date == '1900-01-01T00:00:00') 
                                                                    return parseISO(formatISO(new Date(), { representation: 'date' })); 
                                                                 else 
                                                                    return parseISO(date); }

  constructor(private fb: UntypedFormBuilder, 
              private commonDataService:CommonDataService,
              public providerService: ProviderService,
              private userService:UserService,
              private clientService:ClientFormService,
              private datePipe: DatePipe,
              private dateHelper:DateHelper,
              public  validationHelper:FormValidations,
              private helperService: HelperClassService,
              private medcommon:MedicalCommon) { 

  }

  ngOnInit(): void {
    this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
    this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate);
    this.influenzaNoReasons = this.commonDataService.getCommonSetByName("VaccinationInfluenzaNoreason", true);
    this.pneumoccocalNoReasons = this.commonDataService.getCommonSetByName("VaccinationPneumoccocalNoreason", true);
    this.hepatitisBNoReasons = this.commonDataService.getCommonSetByName("YesnoMedicalVaccination", true);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       // notification for client loading/refreshing
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { this.userService.LogError(error); },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
                         });
     }

     if (!this.clientService.readonly) {
        this.validationHelper.AddRequiredMinMaxValidator(this.getInfluenzaVaccinationScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No);
        this.validationHelper.AddRequiredMinMaxValidator(this.getPneumoccocalVaccinationScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No);
        this.validationHelper.AddRequiredMinMaxValidator(this.getHepatitisBVaccinationScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No);
        this.getInfluenzaVaccinationScreenedControl().valueChanges.subscribe(value => { this.setScreenedInfluenza();  });
        this.getPneumoccocalVaccinationScreenedControl().valueChanges.subscribe(value => { this.setScreenedPneumoccocal();  });
        this.getHepatitisBVaccinationScreenedControl().valueChanges.subscribe(value => { this.setScreenedHepatitisB();  });
     }
  }

  refreshAfterSave() {
    //this.clientData.clientMedicalInfluenzaVaccination.lastUpdate = new Date().toISOString();
    this.setFieldValues();
  }  

  setFieldValues() {
    if (!this.clientData) return;
    
    // Influenza section
    if (!this.helperService.isExpired(this.clientData?.clientMedicalInfluenzaVaccination?.expired)) {
      if (this.clientData?.clientMedicalInfluenzaVaccination?.received != null)
        this.setInfluenzaVaccinationScreenedControlValue(this.clientData.clientMedicalInfluenzaVaccination?.received);

      if (this.clientData?.clientMedicalInfluenzaVaccination?.noReason != null)
        this.getInfluenzaVaccinationScreenedNoReasonControl().setValue(this.clientData.clientMedicalInfluenzaVaccination?.noReason);

      if (this.clientData?.clientMedicalInfluenzaVaccination?.datetime != null)
        this.getInfluenzaVaccinationResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalInfluenzaVaccination?.datetime));      
    }

    // Pneumoccocal section
    if (!this.helperService.isExpired(this.clientData?.clientMedicalPneumoccocalVaccination?.expired)) {
      if (this.clientData?.clientMedicalPneumoccocalVaccination?.received != null)
        this.setPneumoccocalVaccinationScreenedControlValue(this.clientData.clientMedicalPneumoccocalVaccination?.received);

      if (this.clientData?.clientMedicalPneumoccocalVaccination?.noReason != null)
        this.getPneumoccocalVaccinationScreenedNoReasonControl().setValue(this.clientData.clientMedicalPneumoccocalVaccination?.noReason);

      if (this.clientData?.clientMedicalPneumoccocalVaccination?.datetime != null)
        this.getPneumoccocalVaccinationResultDateControl().setValue(parseISO(this.clientData.clientMedicalPneumoccocalVaccination?.datetime));      
    }    
    
    // HepatitisB section
    if (!this.helperService.isExpired(this.clientData?.clientMedicalHepatitisBVaccination?.expired)) {
      if (this.clientData?.clientMedicalHepatitisBVaccination?.received != null)
        this.setHepatitisBVaccinationScreenedControlValue(this.clientData.clientMedicalHepatitisBVaccination?.received);

      if (this.clientData?.clientMedicalHepatitisBVaccination?.noReason != null)
        this.getHepatitisBVaccinationScreenedNoReasonControl().setValue(this.clientData.clientMedicalHepatitisBVaccination?.noReason);

      if (this.clientData?.clientMedicalHepatitisBVaccination?.datetime != null)
        this.getHepatitisBVaccinationResultDateControl().setValue(parseISO(this.clientData.clientMedicalHepatitisBVaccination?.datetime));
    }        
  }

  setScreenedInfluenza() {
    // Influenza section
    if (this.getInfluenzaVaccinationScreenedControl()?.value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getInfluenzaVaccinationScreenedNoReasonControl(), true);
      this.validationHelper.AddValidators(this.getInfluenzaVaccinationResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]); // if (this.providerService.isFieldRequired(MasterFields.InfluenzaVaccinationResultDate))
      this.getInfluenzaVaccinationResultDateControl().setValue(this.getClientMedicalVaccinationFormattedDate(this.clientData.clientMedicalInfluenzaVaccination?.datetime)); //parseISO(formatISO(new Date(), { representation: 'date' })) parseISO(this.clientData.clientMedicalInfluenzaVaccination?.datetime)
    }
    else if (this.getInfluenzaVaccinationScreenedControl()?.value == this.MedicalYesNo.No) {
      //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
      this.validationHelper.AddValidators(this.getInfluenzaVaccinationScreenedNoReasonControl(), this.validationHelper.dropdownValidator()); // if (this.providerService.isFieldRequired(MasterFields.InfluenzaVaccinationScreenedNoReason))
      this.validationHelper.RemoveValidators(this.getInfluenzaVaccinationResultDateControl(), true);
      this.getInfluenzaVaccinationResultDateControl().setValue(null);
    }
  }

  setScreenedPneumoccocal() { 
    // Pneumoccocal section
    if (this.getPneumoccocalVaccinationScreenedControl()?.value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getPneumoccocalVaccinationScreenedNoReasonControl(), true);
      this.validationHelper.AddValidators(this.getPneumoccocalVaccinationResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]); // if (this.providerService.isFieldRequired(MasterFields.InfluenzaVaccinationResultDate))
      this.getPneumoccocalVaccinationResultDateControl().setValue(this.getClientMedicalVaccinationFormattedDate(this.clientData.clientMedicalPneumoccocalVaccination?.datetime)); //parseISO(formatISO(new Date(), { representation: 'date' }))
    }
    else if (this.getPneumoccocalVaccinationScreenedControl()?.value == this.MedicalYesNo.No) {
      //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
      this.validationHelper.AddValidators(this.getPneumoccocalVaccinationScreenedNoReasonControl(), this.validationHelper.dropdownValidator()); // if (this.providerService.isFieldRequired(MasterFields.InfluenzaVaccinationScreenedNoReason))
      this.validationHelper.RemoveValidators(this.getPneumoccocalVaccinationResultDateControl(), true);
      this.getPneumoccocalVaccinationResultDateControl().setValue(null);
    }
  }

  setScreenedHepatitisB() {
    // HepatitisB section
    if (this.getHepatitisBVaccinationScreenedControl()?.value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getHepatitisBVaccinationScreenedNoReasonControl(), true);
      this.validationHelper.AddValidators(this.getHepatitisBVaccinationResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]); // if (this.providerService.isFieldRequired(MasterFields.InfluenzaVaccinationResultDate))
      this.getHepatitisBVaccinationResultDateControl().setValue(this.getClientMedicalVaccinationFormattedDate(this.clientData.clientMedicalHepatitisBVaccination?.datetime)); //parseISO(formatISO(new Date(), { representation: 'date' }))
    }
    else if (this.getHepatitisBVaccinationScreenedControl()?.value == this.MedicalYesNo.No) {
      //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
      this.validationHelper.AddValidators(this.getHepatitisBVaccinationScreenedNoReasonControl(), this.validationHelper.dropdownValidator()); // if (this.providerService.isFieldRequired(MasterFields.InfluenzaVaccinationScreenedNoReason))
      this.validationHelper.RemoveValidators(this.getHepatitisBVaccinationResultDateControl(), true);
      this.getHepatitisBVaccinationResultDateControl().setValue(null);
    }    
  }

	getInfluenzaModel(): Partial<ClientMedicalInfluenzaVaccinationModel> {
	  var model: Partial<ClientMedicalInfluenzaVaccinationModel> = new ClientMedicalCommon(this.userService.userID); //new ClientMedicalCommon(this.medical.value)

    if (this.getInfluenzaVaccinationScreenedControl().dirty || this.getInfluenzaVaccinationScreenedNoReasonControl().dirty || this.getInfluenzaVaccinationResultDateControl().dirty)
    {
      let date_screened = (!this.getInfluenzaVaccinationResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getInfluenzaVaccinationResultDateControl().value, { representation: 'date' });

      model.received = this.getInfluenzaVaccinationScreenedControlValue() ?? false;
      model.datetime = date_screened;
      model.noReason = this.getInfluenzaVaccinationScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      //model.guid = ClientFormHelper.EmptyGuid();
      //model.deleted = false;
      //model.userId = this.userService.userID;
      //model.lastUpdate = ClientFormHelper.nullDate();
      //model.expired = null;

      return model;
    } 

    return null;
	}

  getPneumoccocalModel(): Partial<ClientMedicalPneumoccocalVaccinationModel> {
	  var model: Partial<ClientMedicalPneumoccocalVaccinationModel> = new ClientMedicalCommon(this.userService.userID); //new ClientMedicalCommon(this.medical.value)

    if (this.getPneumoccocalVaccinationScreenedControl().dirty || this.getPneumoccocalVaccinationScreenedNoReasonControl().dirty || this.getPneumoccocalVaccinationResultDateControl().dirty)
    {
      let date_screened = (!this.getPneumoccocalVaccinationResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getPneumoccocalVaccinationResultDateControl().value, { representation: 'date' });

      model.received = this.getPneumoccocalVaccinationScreenedControlValue() ?? false;
      model.datetime = date_screened;
      model.noReason = this.getPneumoccocalVaccinationScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      //model.guid = ClientFormHelper.EmptyGuid();
      //model.deleted = false;
      //model.userId = this.userService.userID;
      //model.lastUpdate = ClientFormHelper.nullDate();
      //model.expired = null;

      return model;
    }

    return null;
  }

  getHepatitisBModel(): Partial<ClientMedicalHepatitisBVaccinationModel> {
	  var model: Partial<ClientMedicalHepatitisBVaccinationModel> = new ClientMedicalCommon(this.userService.userID); //new ClientMedicalCommon(this.medical.value)

    if (this.getHepatitisBVaccinationScreenedControl().dirty || this.getHepatitisBVaccinationScreenedNoReasonControl().dirty || this.getHepatitisBVaccinationResultDateControl().dirty)
    {
      let date_screened = (!this.getHepatitisBVaccinationResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getHepatitisBVaccinationResultDateControl().value, { representation: 'date' });

      model.received = this.getHepatitisBVaccinationScreenedControlValue() ?? false;
      model.datetime = date_screened;
      model.noReason = this.getHepatitisBVaccinationScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      //model.guid = ClientFormHelper.EmptyGuid();
      //model.deleted = false;
      //model.userId = this.userService.userID;
      //model.lastUpdate = ClientFormHelper.nullDate();
      //model.expired = null;

      return model;
    }

    return null;
  }

  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }
}
