<div>
    <p-toast></p-toast>
    <!-- <p-confirmPopup></p-confirmPopup>    -->
</div>

<div class="container-fluid" id="grid"> 
    <p-table #dtRsrReportList [value]="getRsrReportList()" [scrollable]="true" scrollDirection="both" scrollHeight="65vh" [tableStyle]="{ 'max-width': '100%' }">
             styleClass="p-datatable-sm p-datatable-striped" >  <!-- [filters]="filters" -->
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="fundYear">Fund Year<p-sortIcon field="fundYear"></p-sortIcon></th> 
                <th pSortableColumn="clinicName">Clinic Name<p-sortIcon field="clinicName"></p-sortIcon></th>
                <th pSortableColumn="regCode">Reg Code<p-sortIcon field="regCode"></p-sortIcon></th>
                <th pSortableColumn="funderName">Funder<p-sortIcon field="funderName"></p-sortIcon></th>
                <th pSortableColumn="lastRunDateString">Last Run Date<p-sortIcon field="lastRunDateString"></p-sortIcon></th>
                <th pSortableColumn="clientsTotal">Client Count<p-sortIcon field="clientsTotal"></p-sortIcon></th>
                <th pSortableColumn="errorsTotal">Error Count<p-sortIcon field="errorsTotal"></p-sortIcon></th>
                <th pSortableColumn="reportStatus">Status<p-sortIcon field="reportStatus"></p-sortIcon></th>
                <th pSortableColumn="dateSubmittedString">Date Submitted<p-sortIcon field="dateSubmittedString"></p-sortIcon></th>
                <th style="width:5rem;">Run Report</th>
                <th style="width:5rem;">Download XML</th>
                <th style="width:5rem;">Download Zipcode CSV</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rsr let-editing="editing" let-rowIndex="rowIndex">
            <tr class="cl-row"> 
                <!-- <td>
                    <button title="ReferralTicket" pButton pRipple class="p-button p-button-text m-0" (click)="onOpenReferralTicket(referral)"  style="width:1rem">
                        <i class="fa-sharp fa-light fa-pencil " style="color: green;"></i>
                    </button>
                </td> -->
                <!-- <td [ngClass] ="'cl-client-date text-right'" >{{referral?.datetime| date:'shortDate'}}</td> -->
                <td class="text-overflow-ellipsis"> {{ rsr?.fundYear }} </td>
                <td class="text-overflow-ellipsis"> {{ rsr?.clinicName }} </td>
                <td class="text-overflow-ellipsis"> {{ rsr?.regCode }} </td>
                <td class="text-overflow-ellipsis"> {{ rsr?.funderName }} </td>
                <td class="text-overflow-ellipsis"> {{ rsr?.lastRunDateString }} </td>
                <td [ngStyle]="{'color': (rsr?.errorsTotal > 0) ? 'blue': 'green'}"> {{ rsr?.clientsTotal }} </td>
                <td (click)="openClientErrors(rsr)" [ngStyle]="{'color': (rsr?.errorsTotal > 0) ? 'red': 'black'}"> {{ rsr?.errorsTotal }} </td>
                <td class="text-overflow-ellipsis"> {{ rsr?.reportStatus }} </td>
                <td class="text-overflow-ellipsis"> {{ rsr?.dateSubmittedString }} </td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
        </ng-template>
        <ng-template pTemplate="footer">
            <tr>
                <td colspan="15">Count: {{rsrReportList?.length}}</td>
            </tr>
        </ng-template>            
    </p-table>  
</div> 

<app-rsr-client-error></app-rsr-client-error>