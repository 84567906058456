<div>
    <p-button  pButton pRipple type="button" styleClass="p-button-text"   class="p-button-help p-button-text p-button mr-2" 
        [ngStyle]="{'width': '25rem'}" (click)="onProviderClinicsPermButtonClick($event)"  badge="{{this.userProviderClinicPermService.user?.providerUsers?.length}}" >
        <i class=" fa-sharp fa-light fa-list-dropdown fa-lg" style="color:green" pTooltip="Specify Repricer Configuration"  ></i>
        <span  class="ml-2 font-bold">Provider-Clinic-Permissions</span>
    </p-button>
</div>

<p-overlayPanel #providerClinicPermOverlay [dismissable]="false" [showCloseIcon]="true" [ngStyle]="{'padding': '.5rem'}"  (onHide)="onClose($event)" > 
    <ng-template pTemplate="content">
        <!-- <div class="flex justify-content-end p-0 m=0" >
                <p-button  pButton pRipple type="button"    styleClass="p-button-text" 
                    class="p-button-help p-button-text p-button m-0 p-0 mr-2 " 
                    (click)="onClose($event)" >
                    <i class=" fa-sharp fa-light fa-xmark" style="color:gray" ></i>
                </p-button>
        </div> -->
        
        <p-table [value]="this.providerClinicData"  dataKey="clinicId"  [tableStyle]="{ 'min-width': '40rem' }" [scrollable]="true"  scrollHeight="15rem"  
            styleClass="p-datatable-sm"  sortField="permission"  sortOrder="-1" >
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="providerName" >Provider<p-sortIcon field="providerName" ></p-sortIcon></th>
                    <th pSortableColumn="clinicName" >Clinic<p-sortIcon field="clinicName" ></p-sortIcon></th>
                    <th pSortableColumn="permission" >Grant Permission<p-sortIcon field="permission" ></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-perm  let-editing="editing"   let-expanded="expanded">
                <tr class="p-0 m-0">
                    <ng-template #permissionTemplate>
                        <div > 
                            <p-checkbox  [style]="{'width': '100%', 'margin-top': '1rem'}" binary="true"  [(ngModel)]="perm.permission"  (onChange)="onDataChanged($event, perm)" ></p-checkbox>
                        </div>
                    </ng-template>

                    <td class="p-0 m-0">{{perm.providerName }}   </td>
                    <td class="p-0 m-0">{{perm.clinicName }}   </td>

                    <td class="p-0 m-0">
                        <p-cellEditor >
                            <ng-template pTemplate="input">
                                <ng-template [ngTemplateOutlet]="permissionTemplate"></ng-template>
                            </ng-template>
                            <ng-template pTemplate="output">
                                <ng-template [ngTemplateOutlet]="permissionTemplate"></ng-template>
                            </ng-template>                            
                        </p-cellEditor>
                    </td>

                </tr>
            </ng-template>
        </p-table>
            
    </ng-template>
</p-overlayPanel>