import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CounntdownTimerService {

  private timerSubject = new BehaviorSubject<boolean>(false);
  public timerCompleted$: Observable<boolean> = this.timerSubject.asObservable();
  public timerName:string = ""

  constructor() { }

  public setTimerAsExpired()
  {
     //this.timerSubject.complete();
  }

  public next(){
    this.timerSubject.next(true);
  }

  public reset(name:string) {
    this.timerName = name;
    this.timerSubject.next(false);

  }

}
