import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Navigation, Router } from '@angular/router';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { BehaviorSubject, Observable, Subscription, lastValueFrom, of } from 'rxjs';
import { concatMap, finalize, tap } from 'rxjs/operators';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { ClientLookupComponent } from 'src/app/client-lookup/client-lookup.component';
import { LoginService } from 'src/app/login/services/login.service';
import { BehavioralHealthComponents, ClientStatus, MedicalComponents } from "src/app/models/enums";
import { IdleService } from 'src/app/services/idle-service.service';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { CountdownTimerComponent } from '../common/countdown-timer/countdown-timer.component';
import { SpinnerService } from '../common/spinner/spinner.service';
import {
  ClientCareGiverSupportModel,
  ClientEmploymentStatusModel,
  ClientHivStatusModel,
  ClientIncomeDataModel, ClientIncomeSourcesModel,
  ClientLiveInModel,
  ClientMedicalCaseManagementCarePlanModel,
  ClientModel, ClientPaySourcesModel, ClientPrimaryCarePhysicianModel,
  ClientPrimaryCareSourceModel,
  ClientReferralSourceModel,
  ClientStatusHistoryModel,
  ClientTranModesModel,
  ClientZipCodeModel,
  NewClientModel
} from '../models/client.model';
import { CommonDataService } from '../services/common-data.service';
import { HelperClassService } from '../services/helper-class.service';
import { ProviderService } from '../services/provider.service';
import { RecordLockService, RecordTypes } from '../services/record-lock.service';
import { UserService } from '../services/user.service';
import { CertificationPopupComponent } from './alerts/certification-popup.component';
import { EligibiltyPopupComponent } from './alerts/eligibilty-popup.component';
import { ExistingClientListComponent } from './existing-client-list/existing-client-list.component';
import { ClientFormService } from './services/client-form.service';
import { BehaviorHealthComponent } from './tabs/behavior-health/behavior-health.component';
import { MedicalComponent } from './tabs/medical/medical.component';
import { AppContextService } from '../app-context/app-context.service';
import { ReportsService } from '../reports/services/reports.service';
import { ReportsModel } from '../models/reports.model';
import { format, subYears } from 'date-fns';
import { HttpErrorResponse } from '@angular/common/http';

// This to do 
// 1) Update Client Alt ID when compnents of ID Change

@Component({
  selector: 'app-client-form',
  templateUrl: './client-form.component.html',
  styleUrls: ['./client-form.component.scss'],
  providers:[MessageService,DialogService]
})

export class ClientFormComponent implements OnInit, AfterViewInit, OnDestroy, AfterViewChecked {
  //@ViewChild(HepatitisBComponent) hepatitisBSub: HepatitisBComponent;
  //@ViewChild('hepatitisB', { static: false }) hepatitisBSub: HepatitisBComponent;
  // private hepatitisBSub: HepatitisBComponent;
  // @ViewChild(HepatitisBComponent, { static: false }) set content(content: HepatitisBComponent) {
  //    if (content) { // initially setter gets called with undefined
  //         this.hepatitisBSub = content;
  //    }
  // }
  //@ViewChild(HepatitisCComponent) hepatitisCSub: HepatitisCComponent;
  @ViewChild(MedicalComponent, { static: false }) medicalSub: MedicalComponent;
  @ViewChild(BehaviorHealthComponent, { static: false }) behaviorHealthSub: BehaviorHealthComponent;
  //@ViewChild(IdentificationComponent, { static: false }) identificationComponent: IdentificationComponent;
  
  @ViewChild('timerLockExpire') timerLockExpire: CountdownTimerComponent;
  @ViewChild('timerLockExit') timerLockExit: CountdownTimerComponent;
  
  CountdownTimerComponent

  clientGUID: string;
  clientData: Partial<ClientModel> = null;
  clientForm: UntypedFormGroup;
  _validationErrorDialog: boolean = false;
  _tabIDHasErrors:boolean = false;
  _historyDialog: boolean = false;
  lockID =  0; 
  timerClientLockTimeoutMinutes:number = 60;
  timerLockTimeoutExitSeconds:number = 20;
  readonly:boolean = false;
  public eligibilityAlert:number = 0; 
  public certificationAlert:number = 0; 
  subscriptions: Subscription;

  diagLookupRef: DynamicDialogRef;
  dsEligilbiltyModalRef: DynamicDialogRef;
  dsRecertificationModalRef: DynamicDialogRef;
  diagExistingClientRef: DynamicDialogRef;
  diagExistingClientRefOpen: boolean = false;
  identificationFinishedLoading: boolean = false;

  // timerClientLockTimeoutMinutes:number = 2;
  // timerLockTimeoutExitSeconds:number = 30;
  
  private spinnerSubject = new BehaviorSubject<boolean>(false);
  //public spinning$: Observable<boolean> = this.spinnerSubject.asObservable();
  public spinning: boolean = true; // spinning is the same as loading

  public getClientValidationHelper = () => this.validationHelper;
  public getZipCodeControl = () => this.clientForm?.controls.identification.get("idZipCode");
  public getPaySourceControl = () => this.clientForm?.controls.socio_econ.get("idPaySource");
  public getIncomeDataControl = () => this.clientForm?.controls.socio_econ.get("incomeData");
  
  public isNewClient = () => this.clientService?.newclient ?? false;
  public isDevelopment = () => environment.environmentName == "DEV";

  public isRequiredIdentificationSupplied = () =>    this.clientForm?.controls?.identification?.get('idFirstName').valid &&
                                                     this.clientForm?.controls?.identification?.get('idLastName').valid &&
                                                    // this.clientForm?.controls?.identification?.get('idZipCode').valid && // Removed DL - NOt part of Identification
                                                     this.clientForm?.controls?.identification?.get('idSSN').valid &&
                                                     this.clientForm?.controls?.identification?.get('idBirthDate').valid &&
                                                     this.clientForm?.controls?.identification?.get('idGender').valid;
 
                                                    

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: UntypedFormBuilder, 
              private app: AppContextService,
              private commonDataService: CommonDataService,
              private userService: UserService,
              public  clientService:ClientFormService,
              private loginService:LoginService,
              private providerService: ProviderService,
              private spinnerService: SpinnerService,
              private datePipe: DatePipe,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private primengConfig: PrimeNGConfig,
              public  validationHelper:FormValidations,
              public reclockService:RecordLockService ,
              private idleService:IdleService,
              private cdRef:ChangeDetectorRef,
              public dialogService: DialogService,
              public helper: HelperClassService,
              private helperService: HelperClassService,
              private reportService:ReportsService
              //private timerService:CounntdownTimerService
              //public  recTypes:RecordTypes
)
  {
    this.clientForm = this.fb.group({
        identification:this. fb.group ({
            idFirstName: [ null, [Validators.required, Validators.minLength(3)]],
            idMiddleName: [],
            idLastName: [null, [Validators.required, Validators.minLength(3)]],
            idZipCode:[ null, [Validators.required,  Validators.minLength(5)]],
            idCity:[],
            idState:[],
            idCounty:[],
            idSSN:[null,[Validators.required, Validators.minLength(4)]],
            idEmployee: [null,[Validators.required]],    //{value: null, disabled: this.readonly}
            idGender:[null, [Validators.required, validationHelper.dropdownValidator() ]],
            idPCP:[],
            idFirstAmbulatoryCareVisitDate:[],
            idBirthDate: [null, [Validators.required, validationHelper.dateFutureDateValidator()]], 
            idAge:[],
            idNotes:[],
           }),
           demographics: this.fb.group({
            idEthnicity:[null, [Validators.required, validationHelper.dropdownValidator() ]],
            idRace:[null, [Validators.required, validationHelper.dropdownValidator() ]],
            idRegionOrigin:[null, [Validators.required, validationHelper.dropdownValidator() ]], // only if hispanic
            idSexualOrientation:[null, [Validators.required, validationHelper.dropdownValidator() ]],
            idReferalSource:[ null, [ Validators.required, validationHelper.dropdownValidator() ]],
            idClientStatus:[null , [Validators.required, validationHelper.dropdownValidator() ]],
            idDiagnosedLast12Months:[null, [Validators.required]],
            idHIVDiagnosis:[null, [ validationHelper.dateFutureDateValidator(), validationHelper.dateBirthDateValidator() ]], 
            idAIDSDiagnosis:[null, [validationHelper.dateFutureDateValidator(), validationHelper.dateAIDSDiagnosisValidator()]], //  this.dateBirthDateValidator() ADD Require if HIV Status - AIDS     
            idHIVDiagnosisYear:[null, [Validators.required, validationHelper.dateFutureYearValidator(), validationHelper.dateBirthYearValidator()]],
            idAIDSDiagnosisYear:[null, [validationHelper.dateFutureYearValidator(), validationHelper.dateAIDSDiagnosisYearValidator()]], // ADD Require if HIV Status - AIDS and diag last 12 = no
            idEISEligible:[],
            idHIVStatus:[null, [Validators.required, validationHelper.dropdownValidator() ]],
            idTransmissionModes:[null, [Validators.required, validationHelper.dropdownValidator() ]]
          }),
          socio_econ: fb.group({
              idEmploymentStatus:[null, [Validators.required, validationHelper.dropdownValidator() ]],
              idCareGiver:[],
              idUsualSourcePrimaryCare:[],
              idLivingIn:[null, [Validators.required, validationHelper.dropdownValidator() ]],
              idLivingWith:[ ],
              idIncomeSource:[],
              idPaySource:[null, [Validators.required, validationHelper.dropdownValidator()]],

              incomeData: fb.group({
                idMedicallyUnableToWork:[ null],
                idFamilySize:[null, [Validators.required, Validators.minLength(1)]],
                idFamilyIncome:[null, [Validators.required, validationHelper.incomeValidator()]],
                //idFamilyIncome:[null, [Validators.required, Validators.minLength(1)], Validators.maxLength(6)],
                idFPL:[],
                idFPLDescription:[]})


          }),
          medical: fb.group({

            cd4:fb.group({
            idCD4Count:[null, [Validators.required]],
            idCD4Date:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idCD4PcpProphylaxis: [null, [Validators.required]],
            idCD4PcpProphylaxisNoReason: [null, [Validators.required, validationHelper.dropdownValidator()]],
            idCD4MacProphylaxis: [null, [Validators.required]],
            idCD4MacProphylaxisNoReason: [null, [Validators.required, validationHelper.dropdownValidator()]],
            idCD4Unavailable:[]}),
            
            vl:fb.group({
            idVLCount:[null, [Validators.required, Validators.min(1), Validators.max(9999999)]],
            idVLDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idVLUndetectable:[],
            idVLUndetectableRange:[],
            idVLUnavailable:[]}),

            hepB:fb.group({
            idHepBScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idHepBScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idHepBScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idHepBPositive:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idHepBUnavailable:[]}),
           
            hepC:fb.group({
            idHepCScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idHepCScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idHepCScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idHepCPositive:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idHepCUnavailable:[]}),

            adc:fb.group({
            idAidsDefineCondition:[]}),
            
            tb:fb.group({
            idTBScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idTBScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idTBScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idTBPositive:[null, [Validators.required, Validators.min(1), Validators.max(3)]],
            idTBTestType:[null, [Validators.required, validationHelper.dropdownValidator()]],            
            idTBUnavailable:[]}),

            syphilis:fb.group({
            idSyphScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idSyphScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idSyphScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idSyphPositive:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idSyphTreated:[],
            idSyphUnavailable:[]}),

            chlamydia:fb.group({
            idChlmScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idChlmScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idChlmScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idChlmPositive:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idChlmUnavailable:[]}),

            gono:fb.group({
            idGonoScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idGonoScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idGonoScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idGonoPositive:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idGonoUnavailable:[]}),

            toxo:fb.group({
            idToxoScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idToxoScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idToxoScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idToxoResult:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idToxoUnavailable:[]}),

            lipid:fb.group({
            idLipidScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idLipidScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idLipidScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            //idLipidResult:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idLipidUnavailable:[]}),

            covid:fb.group({
            idCovidScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idCovidScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idCovidScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idCovidPositive:[null, [Validators.required, Validators.min(1), Validators.max(3)]],
            idCovidTestType:[null, [Validators.required, validationHelper.dropdownValidator()]],            
            idCovidUnavailable:[]}),

            pharma:fb.group({
            idPharmaAssistanceScreened:[null, [Validators.required, Validators.min(1), Validators.max(3)]]}),

            art:fb.group({
            idARTScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idARTScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idARTScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]]}),

            vax:fb.group({
            idFluVaxScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idFluVaxScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idFluVaxScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],

            idPneumoVaxScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idPneumoVaxScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idPneumoVaxScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],

            idHepBVaxScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idHepBVaxScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idHepBVaxScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]]}),

            female:fb.group({
            idPAPScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idPAPScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idPAPScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],

            idPelvicScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idPelvicScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idPelvicScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],

            idPregnancyScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idPregnancyScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idPregnancyTrimester:[null, [Validators.required, validationHelper.dropdownValidator()]],

            idAntiPrevScreened:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idAntiPrevScreenedNoReason:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idAntiPrevScreenedDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],

            idBirthResultsBirthDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idBirthResultsGender:[null, [Validators.required, validationHelper.dropdownValidator()]],
            idBirthResultsHIVStatus:[null, [Validators.required, validationHelper.dropdownValidator()]]}),

            otherSTI:fb.group({
            idOtherSTIDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idOtherSTIPositive:[null],
            idOtherSTIDescription:[null, [Validators.required]]}) //validationHelper.dropdownValidator() zero is allowed for empty selection
          }),
          behavior_health: fb.group({

            MH:fb.group({
            idMHResultDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idMHResult:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idMHDiagnosis:[null], //, [Validators.required, Validators.min(1), Validators.max(2)]
            idMHFollowup:[null]}), //, [Validators.required, Validators.min(1), Validators.max(2)]

            SA:fb.group({
            idSAResultDate:[null, [Validators.required, validationHelper.dateFutureDateValidator()]],
            idSAResult:[null, [Validators.required, Validators.min(1), Validators.max(2)]],
            idSADiagnosis:[null], //, [Validators.required, Validators.min(1), Validators.max(2)]
            idSAFollowup:[null] })//, [Validators.required, Validators.min(1), Validators.max(2)]
          }),
          mcm: fb.group({
            idMCMCarePlanDateUnavailable:[],
            idMCMCarePlanDate:[],
            idMCMCarePlanServicesUnavailable:[],
            //idFilterOptions:[],
            idMCMVisitDate:[]

          }),

        });

    let nav: Navigation = this.router.getCurrentNavigation();
    if (nav?.extras && nav?.extras?.state && nav?.extras?.state?.eligibility) {
      var eligibility: NewClientModel = nav?.extras?.state?.eligibility;
      this.clientService.newclient = true;
      this.clientGUID = 'null';

      this.clientForm.controls.identification.get('idZipCode').setValue(eligibility.zipCode);
      this.clientForm.controls.identification.get('idZipCode').markAsDirty();
      this.clientForm.controls.identification.get('idCity').setValue(eligibility?.city);
      this.clientForm.controls.identification.get('idState').setValue(eligibility?.state);
      this.clientForm.controls.identification.get('idCounty').setValue(eligibility?.county);

      this.clientForm.controls.socio_econ.get('incomeData').get('idFamilySize').setValue(eligibility.familySize);
      this.clientForm.controls.socio_econ.get('incomeData').get('idFamilySize').markAsDirty();
      this.clientForm.controls.socio_econ.get('incomeData').get('idFamilyIncome').setValue(eligibility.familyIncome);
      this.clientForm.controls.socio_econ.get('incomeData').get('idFamilyIncome').markAsDirty();
      this.clientForm.controls.socio_econ.get('incomeData').get('idFPL').setValue(eligibility.poveryCode);
      this.clientForm.controls.socio_econ.get('incomeData').get('idFPLDescription').setValue(eligibility.povertyDescription);
      this.clientService.loadedSocio = true;

      this.clientForm.controls.demographics.get('idClientStatus').setValue(ClientStatus.Active);
      this.clientForm.controls.demographics.get('idClientStatus').markAsDirty();
      this.clientForm.controls.demographics.get('idHIVStatus').setValue(eligibility.hivStatus);
      this.clientForm.controls.demographics.get('idHIVStatus').markAsDirty();
      this.clientService.loadedDemos = true;
    }
    else {
      this.clientGUID = this.route.snapshot.params.guid;
    }       
  }

  ngAfterViewInit() {
    this.setTimer();
  }

  ngAfterViewChecked() {
  }

  ngAfterContentInit() {
    //if (this.clientService.newclient) this.identificationComponent.onBlurZipCode(); decided to pass all the values in nav extras above to avoid the extra lookup call
    //this.clientForm.get("demographics").get('idTransmissionModes').markAsPristine();
  }

  ngOnInit(): void {

    //  if (!this.loginService.loggedin) {
    //    this.router.navigate(['/']); 
    //  }

     this.readonly = !(this.userService.hasAdminPermission || this.userService.hasEditPermissions);

     if (this.readonly){
        this.clientService.readonly = true;
        this.clientForm.disable();

      // this.disableControls(this.clientForm);

      // this.clientForm.get("demographics").get('idTransmissionModes').disable();
      // this.clientForm.get("socio_econ").get('idLivingIn').disable();
      // this.clientForm.get("socio_econ").get('idIncomeSource').disable();
      // this.clientForm.get("socio_econ").get('idPaySource').disable();
     }
     else 
     {
        this.clientService.readonly = false;
     }

     //this.spinnerService.On();

     // if idle timeout save form to local storage
     this.idleService.idle.onTimeout.subscribe(() => {
        localStorage.setItem('champ-last-client-data', JSON.stringify(this.clientForm.value));
        console.log("Saving Client Form Data...");
      });

    var postSubscription = this.clientService.$clientPost.subscribe( {
      next: (result) =>  {
        if (!!result) {
          this.clientData = this.clientService.clientData;          
          this.clientForm.markAsPristine();
          this.setupClientEligibiltyAndCertification(); 
          this.spinning = false;
          this.clientService.newclient = false;
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Client Saved ', life: 3000});          
        }
      },
      error: (error) =>  {
          this.spinning = false;
          this.helper.displayError(this.messageService, "Fatal error occurred when saving client. Please contact CHAMP support:", error, true);
      }
      //complete: () => {
          // this.clientData = this.clientService.clientData;
          // // Resets the form control, marking it pristine and untouched , and resetting the value
          // //this.clientForm.reset(this.clientForm.value);
          // this.clientForm.markAsPristine();
          // // this.spinnerService.Off();
          // this.spinning = false;
          // this.clientService.newclient = false;
          // this.messageService.add({severity:'success', summary: 'Successful', detail: 'Client Saved ', life: 3000});
          // //  this.clientForm.valueChanges.subscribe( value=> { console.log(JSON.stringify(value));  } );  
      //} 
    });
    //this.subscriptions.add(postSubscription);
    if (this.clientGUID == 'null')  // New Client
    { 
        this.clientGUID = uuidv4(); //ClientFormHelper.EmptyGuid();
        this.clientData = { guid: this.clientGUID, id: 0, providerId: this.userService.CurrentProviderID };
        //this.clientService.clientData = Object.assign({ guid: this.clientGUID, id: 0, clientAltId: this.clientData.clientAltId }, this.clientData);
        //this.clientData = {...this.clientService.clientDefaults};
        //this.clientService.clientData = this.clientData;
        this.clientService.newclient = this.identificationFinishedLoading = true; 
        //this.clientForm.markAllAsTouched(); // We will mark each tab and dat item seperately because some things can be saved without providing data first
        this.spinning = false;
    }
    else if (this.clientGUID == 'reload') // Reload
    {
        this.clientForm = JSON.parse(localStorage.getItem('champ-last-client-data'))
        //this.clientData = this.clientService.clientData;
        this.reclockService.AddLock(this.userService.userID, RecordTypes.client,  this.clientData.guid, this.clientData.clientAltId).subscribe(lockID => { this.lockID = lockID});
        this.spinning = false;
        this.identificationFinishedLoading = true;
    }
    else
    {
        this.clientService.getClientData(this.clientGUID); //this.clientData?.clientAltId
        this.clientService.newclient = false; 
        //this.showSpinnerUntilCompleted(this.clientService.$client).subscribe();
        var getSubscription = this.clientService.$clientGet.subscribe({
            next: (result) => {
             },
            error: (error) => {
                console.log(error);
                this.spinning = false;
            }, 
            complete: () => {
                this.clientData = this.clientService.clientData;
                this.reclockService.AddLock(this.userService.userID, RecordTypes.client,  this.clientData.guid, this.clientData.clientAltId).subscribe(lockID => { this.lockID = lockID});
                // this.spinnerService.Off();
                this.clientForm.get("demographics").get('idTransmissionModes').markAsPristine();
                this.clientForm.markAsPristine();
                this.clientService.clientForm = this.clientForm;
                this.setupClientEligibiltyAndCertification(); 
                this.spinning = false;
            }
          });
        //this.subscriptions.add(getSubscription);
    }

    var firstNameControl = this.clientForm?.controls.identification.get('idFirstName');
    var lastNameControl = this.clientForm?.controls.identification.get('idLastName');
    var genderControl = this.clientForm?.controls.identification.get('idGender');
    var ssnControl = this.clientForm?.controls.identification.get('idSSN');
    var birthDateControl = this.clientForm?.controls.identification.get('idBirthDate');
    firstNameControl.valueChanges.subscribe(value => { var clientAltId = this.clientService.createClientAltId(value ?? '_', lastNameControl.value ?? '_', genderControl.value ?? 0, (ssnControl.value ?? '____').trim(), this.helper.castToDateString(birthDateControl.value ?? '01-01-1900'), this.clientData?.clientAltId);
                                                       this.checkForExistingClient(clientAltId); });
    lastNameControl.valueChanges.subscribe(value => { var clientAltId = this.clientService.createClientAltId(firstNameControl.value ?? '_', value ?? '_', genderControl.value ?? 0, (ssnControl.value ?? '____').trim(), this.helper.castToDateString(birthDateControl.value ?? '01-01-1900'), this.clientData?.clientAltId);
                                                      this.checkForExistingClient(clientAltId); });
    genderControl.valueChanges.subscribe(value => { var clientAltId = this.clientService.createClientAltId(firstNameControl.value ?? '_', lastNameControl.value ?? '_', value ?? 0, (ssnControl.value ?? '____').trim(), this.helper.castToDateString(birthDateControl.value ?? '01-01-1900'), this.clientData?.clientAltId);
                                                    this.checkForExistingClient(clientAltId); });
    ssnControl.valueChanges.subscribe(value => { var clientAltId = this.clientService.createClientAltId(firstNameControl.value ?? '_', lastNameControl.value ?? '_', genderControl.value ?? 0, (value ?? '____').trim(), this.helper.castToDateString(birthDateControl.value ?? '01-01-1900'), this.clientData?.clientAltId);
                                                 this.checkForExistingClient(clientAltId); });
    birthDateControl.valueChanges.subscribe(value => { var clientAltId = this.clientService.createClientAltId(firstNameControl.value ?? '_', lastNameControl.value ?? '_', genderControl.value ?? 0, (ssnControl.value ?? '____').trim(), this.helper.castToDateString(value ?? '01-01-1900'), this.clientData?.clientAltId);
                                                       this.checkForExistingClient(clientAltId); });
    
   // this.cdRef.detectChanges();
  }

  async checkForExistingClient(clientAltId: string) {
    var clientAltIdChanged = this.clientData.clientAltId == clientAltId ? false : true;
    this.clientData.clientAltId = clientAltId;

    // spinning ensures client form finished loading and identificationFinishedLoading ensures that component is also finished loading
    if (!this.spinning && this.identificationFinishedLoading && clientAltIdChanged && !this.diagExistingClientRefOpen && clientAltId.length >= 9) {
      this.diagExistingClientRefOpen = true;
      var existingClients = await lastValueFrom(this.clientService.findClient(this.providerService.providerID, this.clientData.clientAltId, this.clientData.id));
      if (!(!!existingClients && existingClients?.length > 0)) {
        this.diagExistingClientRefOpen = false;
      }
      else { // if (!!existingClients && existingClients.length > 0)
        this.diagExistingClientRef = this.dialogService.open(ExistingClientListComponent, {
            header: 'Existing Client List',
            width: '30%',
            contentStyle: {"max-height": "600px", "overflow": "visible"},
            data: {
              existingClients: existingClients
              // providerId: this.providerService.providerID,
              // clientAltId: this.clientData.clientAltId,
              // clientId: this.clientData.id
            }
        });
        const self = this;
        this.diagExistingClientRef.onClose.subscribe((data) => {
          this.diagExistingClientRefOpen = false;
          //console.log(data?.existingClientData);
          if (!!data?.existingClient) {
            try {
              self.clientData = null;
              self.clientGUID = data?.existingClient?.guid;
              //self.ngOnInit(); // doesn't work since child components won't refresh
              //let route  = ['../client-form/${guid}']; 
              //this.router.navigate(['../client-form/' + self.clientGUID]);            
              this.router.routeReuseStrategy.shouldReuseRoute = () => false;
              this.router.navigateByUrl('/client-form/' + self.clientGUID); //, { skipLocationChange: true }
            }
            catch (e) {
              console.log(e)
            }
          }
        });
      }
    }
  }

  isIdentificationFinishedLoading(value: boolean) {
    this.identificationFinishedLoading = value;
  }

  setupClientEligibiltyAndCertification(){
    this.eligibilityAlert = this. clientService.isClientEligible();
    this.certificationAlert = this.clientService.clientRecertificationRequired();
  }

  disableControls(fg:FormGroup){
    Object.keys(fg.controls).forEach(key => {
      const control = fg.controls[key] as FormControl | FormGroup | FormArray;
      if(control instanceof FormControl) {
        if (key == "idIncomeSource") {
          console.log(key);
         }
        if (key == "idHepBScreened") {
           console.log(key);
          }
          console.log(`disabling control ${key}`);
          control.disable();

      } else if(control instanceof FormGroup ) { // || control instanceof FormArray
          console.log("FORM GROUP ------------ " + key);
          this.disableControls(control);
      } else {
        console.log("ignoring control")
     }
    });


  }


  dirtyControls(fg:FormGroup){
    Object.keys(fg.controls).forEach(key => {
      const control = fg.controls[key] as FormControl | FormGroup | FormArray;
      if(control instanceof FormControl) {
        if ( control.dirty ) {
         console.log(`dirty control ${key}`);
        }
      } else if(control instanceof FormGroup ) { // || control instanceof FormArray
          console.log("FORM GROUP ------------ " + key);
          this.dirtyControls(control);
      } else {
        console.log("ignoring control")
     }
    });

  }

  ngOnDestroy() {
    //this.subscriptions.unsubscribe();
    this.clientService.postCleanUp();
    this.reclockService.ReleaseLock(this.userService.userID, RecordTypes.client, this.lockID).subscribe(data => { });
  }

  onValidate(){
    // this.spinning = false;
    // this.messageService.add({severity:'error', summary: 'Validation Errors Exist', 
    //       detail: 'Valdiation errors exist. Please fix fields in red before saving.', life: 3000});

    //console.log(this.clientForm?.controls.identification.get('idGender').dirty);
    //this.clientForm.get('demographics').get('idGender').markAsPristine();
    
    this.clientService.resetInvalidControlsList();
    this.clientService.getInvalidControlsList(this.clientForm, null);
    console.log(this.clientService.invalidControls)
    this._validationErrorDialog = false;
    this._tabIDHasErrors = true;
    console.log(this.clientForm);
  }

  setTimer(){

        if (!this.readonly){
            // Timer observables need to be here because they are set until ngAfterViewInit because they reference components in the view 
          // if ( this.timerLockExpire) {
              this.timerLockExpire?.resetTimer();
              this.timerLockExpire?.timerCompleted$.subscribe({
                next:(value:boolean) => {
                  if (value ) {
                        this.showLockMessage();
                  };
                },
                complete:()=>{      }
              });
          // }
          
          //if ( this.timerLockExit) {
            this.timerLockExit?.stopTimer();
            this.timerLockExit?.timerCompleted$.subscribe({
              next:(value:boolean) => {
                if (value ) {
                  let msg = 'Automatically closing client page ${ this.clientData.clientAltId }- lock timeout!';
                  this.userService.LogWarning(msg );
                  this.router.navigate(['/client-list']);
                };
              },
              complete:()=>{      }
            });   
        }
  }

  showLockMessage(){
    this.timerLockExit.resetTimer();
    this.confirmationService.confirm({
      //target: event.target,
      key:'lock',
      message: 'Client lock has expired. Client must be closed.   Do you want to save changes ?',
      header: 'Lock expired',
      icon: 'pi pi-info-circle',
      accept: () => {
           this.messageService.add({severity:'info', summary: 'Renew', detail: 'Lock Renewed', life: 1000});
           this.timerLockExit.stopTimer();
           this.timerLockExpire.resetTimer();
      },
      reject: () => {
            this.messageService.add({severity: "info", summary: "Released", detail: "Lock Released", life: 1000});
            this.router.navigate(['/client-list']);
          }
      });
  }

  setFormToClientObject(){

    // Identification Tab  -----------------------------------------------------------------------------------
    let identGroupControls: any = this.clientForm.controls.identification;
    let identGroup: any = this.clientForm.value.identification;

    this.clientData.firstName = identGroup.idFirstName;
    this.clientData.middleName = identGroup.idMiddleName;
    this.clientData.lastName = identGroup.idLastName;
    this.clientData.notes = identGroup.idNotes;
    this.clientData.ssn = identGroup.idSSN;
    this.clientData.employeeId = identGroup.idEmployee;
    this.clientData.primaryCarePhysician = identGroup.idPCP;
    this.clientData.gender = identGroup.idGender;
    this.clientData.birthdate = (new Date ( identGroup.idBirthDate )).toISOString();
    this.clientData.userId = this.userService.userID;

    if (this.clientForm.controls.identification.get('idZipCode').dirty) {
      let zipModel: ClientZipCodeModel = {
        guid: ClientFormHelper.EmptyGuid(),
        zipCode: identGroup.idZipCode,
        datetime: ClientFormHelper.nullDate(),
        deleted: false,
        expired: ClientFormHelper.nullDate(),
        userId: this.userService.userID,
        lastUpdate: ClientFormHelper.nullDate(),
        streetAddress: '',
        recSource: '4',
        sourceGuid: ClientFormHelper.EmptyGuid(),
        sourceProviderId: 0
      };
      this.clientData.clientZipCodes = zipModel;
      // this.clientData.clientZipCodes.guid =  ClientFormHelper.EmptyGuid();
      // this.clientData.clientZipCodes.zipCode = identGroup.idZipCode;
    }

    if (identGroupControls.get('idFirstAmbulatoryCareVisitDate').dirty  || identGroupControls.get('idPCP').dirty) {
          let pcpModel : ClientPrimaryCarePhysicianModel = {
              guid: ClientFormHelper.EmptyGuid(),
              id: identGroup.idPCP,
              datetime: ClientFormHelper.nullDate(),
              deleted: false,
              expired: ClientFormHelper.nullDate(),
              userId: this.userService.userID,
              lastUpdate: ClientFormHelper.nullDate(),
              clinicId: 0,
              firstVisitDate: new Date(identGroup.idFirstAmbulatoryCareVisitDate).toISOString()
          };
          this.clientData.clientPrimaryCarePhysicians = pcpModel;
    }

    // Demographics Tab  -----------------------------------------------------------------------------------
    if (this.clientService.loadedDemos) {
        let demoGroupControls: any = this.clientForm.controls.demographics;
        let demoGroup: any = this.clientForm.getRawValue().demographics;
        //let grpDemoValues: any = this.clientForm.value.demographics;
        this.clientData.ethnicity = demoGroup.idEthnicity ?? 0;
        this.clientData.race = demoGroup.idRace ?? 0;
        this.clientData.regionOrigin = demoGroup.idRegionOrigin ?? 0;
        this.clientData.sexualOrient = demoGroup.idSexualOrientation ?? 0;

        if (demoGroupControls.get('idReferalSource').dirty) {
          let clientReferralSourceModel: ClientReferralSourceModel = {
            guid: ClientFormHelper.EmptyGuid(),
            id: demoGroup.idReferalSource,
            datetime: ClientFormHelper.nullDate(),
            userId: this.userService.userID,
            lastUpdate: ClientFormHelper.nullDate(),
            expired: ClientFormHelper.nullDate(),
            clinicId: this.userService.CurrentClinicID          
          };
          this.clientData.clientReferralSource = clientReferralSourceModel;
          //this.clientData.clientReferralSource.guid =  ClientFormHelper.EmptyGuid();
          //this.clientData.clientReferralSource.id = demoGroup.idReferalSource;
        }

        if (demoGroupControls.get('idClientStatus').dirty) {
          let clientStatusHistoryModel: ClientStatusHistoryModel = {
            guid: ClientFormHelper.EmptyGuid(),
            id: demoGroup.idClientStatus,
            datetime: ClientFormHelper.nullDate(),
            userId: this.userService.userID,
            lastUpdate: ClientFormHelper.nullDate(),
            expired: ClientFormHelper.nullDate(),
            clinicId: this.userService.CurrentClinicID
          };
          this.clientData.clientStatusHistory = clientStatusHistoryModel;
          // this.clientData.clientStatusHistory.guid =  ClientFormHelper.EmptyGuid();
          // this.clientData.clientStatusHistory.id = demoGroup.idClientStatus;
          // this.clientData.clientStatusHistory.clinicId = this.userService.CurrentClinicID;
          // this.clientData.clientStatusHistory.userId = this.userService.userID;
        }

        if (demoGroupControls.get('idHIVStatus').dirty) {
          let clientHivStatusModel: ClientHivStatusModel = {
            guid: ClientFormHelper.EmptyGuid(),
            id: demoGroup.idHIVStatus,
            datetime: ClientFormHelper.nullDate(),
            expired: ClientFormHelper.nullDate(),
            userId: this.userService.userID,
            lastUpdate: ClientFormHelper.nullDate(),
            sourceGuid: ClientFormHelper.EmptyGuid(),
            sourceProviderId: 0
          };
          this.clientData.clientHivStatus = clientHivStatusModel;
          // this.clientData.clientHivStatus.guid =  ClientFormHelper.EmptyGuid();
          // this.clientData.clientHivStatus.id = demoGroup.idHIVStatus;
        }

        this.clientData.newlyDiagnosed =  Number(demoGroup.idDiagnosedLast12Months);
        if (this.clientData.newlyDiagnosed  ==  1){
            this.clientData.hivConfirmYear =  (new Date (demoGroup.idHIVDiagnosis)).toISOString();
            this.clientData.aidsDiagnosYear =  (new Date (demoGroup.idAIDSDiagnosis)).toISOString();  // this.userForm.getRawValue()
        }
        else{
           if (demoGroup.hivConfirmYear != null) this.clientData.hivConfirmYear =  (new Date ( "1/1/" + demoGroup.idHIVDiagnosisYear)).toISOString();
           if (demoGroup.idAIDSDiagnosisYear!= null) this.clientData.aidsDiagnosYear =   (new Date ( "1/1/" + demoGroup.idAIDSDiagnosisYear)).toISOString(); //this.userForm.getRawValue()
        }

        if (demoGroupControls.get('idTransmissionModes').dirty) {
          this.clientData.clientTranModes = [];
            demoGroupControls.get('idTransmissionModes').value?.forEach(element => {
                let tm : ClientTranModesModel = {
                    setguid: ClientFormHelper.EmptyGuid(),
                    guid: ClientFormHelper.EmptyGuid(),
                    datetime: ClientFormHelper.nullDate(),
                    expired : ClientFormHelper.nullDate(),
                    id: element.id,
                    userId: this.userService.userID,
                    lastUpdate: ClientFormHelper.nullDate()
                } ;
                this.clientData.clientTranModes.push(tm);
          });
        }
    }

    // Socio Econ Tab  -----------------------------------------------------------------------------------
    if (this.clientService.loadedSocio) {
        let socioEconControls: any = this.clientForm.controls.socio_econ;
        let socioEconGroup: any = this.clientForm.value.socio_econ;

        if ( socioEconControls.get('idEmploymentStatus').dirty) {
          let clientEmploymentStatusModel: ClientEmploymentStatusModel = {
            guid: ClientFormHelper.EmptyGuid(),
            id: socioEconGroup.idEmploymentStatus,
            datetime: ClientFormHelper.nullDate(),
            expired : ClientFormHelper.nullDate(),
            userId: this.userService.userID,
            lastUpdate: ClientFormHelper.nullDate()
          };
          this.clientData.clientEmploymentStatus = clientEmploymentStatusModel;
          // this.clientData.clientEmploymentStatus.guid =  ClientFormHelper.EmptyGuid();
          // this.clientData.clientEmploymentStatus.id = socioEconGroup.idEmploymentStatus;
          // this.clientData.clientEmploymentStatus.userId = this.userService.userID;
        }

        if ( socioEconControls.get('idCareGiver').dirty) {
          let clientCareGiverSupportModel: ClientCareGiverSupportModel = {
            guid: ClientFormHelper.EmptyGuid(),
            id: socioEconGroup.idCareGiver,
            datetime: ClientFormHelper.nullDate(),
            expired : ClientFormHelper.nullDate(),
            userId: this.userService.userID,
            lastUpdate: ClientFormHelper.nullDate()
          };
          this.clientData.clientCareGiverSupport = clientCareGiverSupportModel;
          // this.clientData.clientCareGiverSupport.guid =  ClientFormHelper.EmptyGuid();
          // this.clientData.clientCareGiverSupport.id = socioEconGroup.idCareGiver;
          // this.clientData.clientCareGiverSupport.userId = this.userService.userID;
        }

        if ( socioEconControls.get('idUsualSourcePrimaryCare').dirty) {
          let clientPrimaryCareSourceModel: ClientPrimaryCareSourceModel = {
            guid: ClientFormHelper.EmptyGuid(),
            id: socioEconGroup.idUsualSourcePrimaryCare,
            datetime: ClientFormHelper.nullDate(),
            expired : ClientFormHelper.nullDate(),
            userId: this.userService.userID,
            lastUpdate: ClientFormHelper.nullDate()
          };
          this.clientData.clientPrimaryCareSource = clientPrimaryCareSourceModel;
          // this.clientData.clientPrimaryCareSource.guid =  ClientFormHelper.EmptyGuid();
          // this.clientData.clientPrimaryCareSource.id = socioEconGroup.idUsualSourcePrimaryCare;
          // this.clientData.clientPrimaryCareSource.userId = this.userService.userID;
        }  

        if ( socioEconControls.get('idLivingIn').dirty) {
          let clientLiveInModel: ClientLiveInModel = {
            guid: ClientFormHelper.EmptyGuid(),
            id: socioEconGroup.idLivingIn,
            datetime: ClientFormHelper.nullDate(),
            expired : ClientFormHelper.nullDate(),
            userId: this.userService.userID,
            lastUpdate: ClientFormHelper.nullDate()
          };
          this.clientData.clientLiveIn = clientLiveInModel;
          // this.clientData.clientLiveIn.guid =  ClientFormHelper.EmptyGuid();
          // this.clientData.clientLiveIn.id = socioEconGroup.idLivingIn;
          // this.clientData.clientLiveIn.userId = this.userService.userID;
        }  

        if ( socioEconControls.get('idLivingWith').dirty) {
          this.clientData.clientLiveWith = [];
          socioEconControls.get('idLivingWith').value?.forEach(element => {
                let  lw : ClientTranModesModel = {
                    setguid: ClientFormHelper.EmptyGuid(),
                    guid: ClientFormHelper.EmptyGuid(),
                    datetime: ClientFormHelper.nullDate(),
                    expired : ClientFormHelper.nullDate(),
                    id: element.id,
                    userId: this.userService.userID,
                    lastUpdate: ClientFormHelper.nullDate()
                } ;
                this.clientData.clientLiveWith.push(lw);
          });
        }

        if ( socioEconControls.get('idIncomeSource').dirty) {
          this.clientData.clientIncomeSources = [];
          socioEconControls.get('idIncomeSource').value?.forEach(element => {
                let  inc : ClientIncomeSourcesModel = {
                    setguid: ClientFormHelper.EmptyGuid(),
                    guid: ClientFormHelper.EmptyGuid(),
                    datetime: ClientFormHelper.nullDate(),
                    expired : ClientFormHelper.nullDate(),
                    id: element.id,
                    userId: this.userService.userID,
                    deleted: false,
                    lastUpdate: ClientFormHelper.nullDate()
                } ;
                this.clientData.clientIncomeSources.push(inc);
          });
        }
        
        if ( socioEconControls.get('idPaySource').dirty) {
          this.clientData.clientPaySources = [];
          socioEconControls.get('idPaySource').value?.forEach(element => {
                let  ps : ClientPaySourcesModel = {
                    setguid: ClientFormHelper.EmptyGuid(),
                    guid: ClientFormHelper.EmptyGuid(),
                    datetime: ClientFormHelper.nullDate(),
                    expired : ClientFormHelper.nullDate(),
                    id: element.id,
                    userId: this.userService.userID,
                    directServiceGuid: ClientFormHelper.EmptyGuid(),
                    sourceGuid: ClientFormHelper.EmptyGuid(),
                    lastUpdate: ClientFormHelper.nullDate(),
                    recSource: '4',
                    sourceProviderId:this.userService.CurrentProviderID
                } ;
                this.clientData.clientPaySources.push(ps);
          });
        }

        if ( socioEconControls.get('incomeData').get('idMedicallyUnableToWork').dirty || 
              socioEconControls.get('incomeData').get('idFamilyIncome').dirty  ||  
              socioEconControls.get('incomeData').get('idFamilySize').dirty  )
          {
            let  incData : ClientIncomeDataModel = {
              guid: ClientFormHelper.EmptyGuid(),
              datetime: ClientFormHelper.nullDate(),
              expired : ClientFormHelper.nullDate(),
              medUnable: socioEconControls.get('incomeData').get('idMedicallyUnableToWork').value ?? 0,
              householdIncome: socioEconControls.get('incomeData').get('idFamilyIncome').value,
              householdSize: socioEconControls.get('incomeData').get('idFamilySize').value,
              individualIncome: 0,
              povertyCode: socioEconControls.get('incomeData').get('idFPL').value ?? 0,
              userId: this.userService.userID,
              sourceGuid: ClientFormHelper.EmptyGuid(),
              lastUpdate: ClientFormHelper.nullDate(),
              recSource: '4',
              sourceProviderId:this.userService.CurrentProviderID
          };
          this.clientData.clientIncomeData = incData;
        }
    }
    
    // Medical Tab  -----------------------------------------------------------------------------------
    if (!this.clientService.newclient) {
      //couldn't call grandchild component directly had to expose a method on medical form (parent component) to get to HepaB, HepaC, etc components
      this.clientData.clientMedicalCd4 = this.medicalSub.getModel(MedicalComponents.CD4);
      this.clientData.clientMedicalViral = this.medicalSub.getModel(MedicalComponents.ViralLoad);
      this.clientData.clientMedicalHepatitisB = this.medicalSub.getModel(MedicalComponents.HepatitisB);
      this.clientData.clientMedicalHepatitisC = this.medicalSub.getModel(MedicalComponents.HepatitisC);
      this.clientData.clientMedicalOppDiseases = this.medicalSub.getModelArray(MedicalComponents.AidsDefiningCondition);
      this.clientData.clientMedicalTuberculosis = this.medicalSub.getModel(MedicalComponents.Tuberculosis);
      this.clientData.clientMedicalSyphilis = this.medicalSub.getModel(MedicalComponents.Syphilis);
      this.clientData.clientMedicalChlamydia = this.medicalSub.getModel(MedicalComponents.Chlamydia);
      this.clientData.clientMedicalGonorrhea = this.medicalSub.getModel(MedicalComponents.Gonorrhea);
      this.clientData.clientMedicalToxoplasma = this.medicalSub.getModel2(MedicalComponents.Toxoplasma);  //getModel2
      this.clientData.clientMedicalLipidPanel = this.medicalSub.getModel2(MedicalComponents.LipidPanel);  //getModel2
      this.clientData.clientMedicalCovid = this.medicalSub.getModel(MedicalComponents.Covid);
      this.clientData.clientMedicalPharmaAssistance = this.medicalSub.getModel(MedicalComponents.PharmaAssistance);
      this.clientData.clientMedicalAntiretroviralTherapy = this.medicalSub.getModel(MedicalComponents.AntiretroviralTherapy);
      this.clientData.clientMedicalInfluenzaVaccination = this.medicalSub.getModel(MedicalComponents.InfluenzaVaccination);
      this.clientData.clientMedicalPneumoccocalVaccination = this.medicalSub.getModel(MedicalComponents.PneumoccocalVaccination);
      this.clientData.clientMedicalHepatitisBVaccination = this.medicalSub.getModel(MedicalComponents.HepatitisBVaccination);
      this.clientData.clientMedicalFemalePapSmear = this.medicalSub.getModel(MedicalComponents.FemalePapSmear);
      this.clientData.clientMedicalFemalePelvicExam = this.medicalSub.getModel(MedicalComponents.FemalePelvicExam);
      this.clientData.clientMedicalFemalePregnancy = this.medicalSub.getModel(MedicalComponents.FemalePregnancy);
      this.clientData.clientMedicalAntiretroviralPrevention = this.medicalSub.getModel(MedicalComponents.AntiretroviralPrevention);
      this.clientData.clientMedicalFemaleBirthResults = this.medicalSub.getModelArray(MedicalComponents.FemaleBirthResults);
      this.clientData.clientMedicalOtherSti = this.medicalSub.getModelArray(MedicalComponents.OtherSTIResults);
      this.clientData.clientScreeningMentalHealth = this.behaviorHealthSub.getModel(BehavioralHealthComponents.MentalHeath);
      this.clientData.clientScreeningSubstanceAbuse = this.behaviorHealthSub.getModel(BehavioralHealthComponents.SubstanceAbuse);
    }
    //this.clientData = ClientFormHelper.SaveHistoryField(this.clientForm.controls.identification, 'idZipCode', this.clientData, this.userService.userID);


     // MCM Tab  -----------------------------------------------------------------------------------
    if (this.clientService.loadedMCM) {

      let mcmControls: any = this.clientForm.controls.mcm;
      let mcmGroup: any = this.clientForm.value.mcm;
      if ( mcmControls.dirty) {
        let mcmModel: ClientMedicalCaseManagementCarePlanModel = {
          guid: ClientFormHelper.EmptyGuid(),
          carePlanDate :  mcmControls.get('idMCMCarePlanDate').value,
          unavailable: mcmControls.get('idMCMCarePlanServicesUnavailable').value ?? false,
          datetime: ClientFormHelper.nullDate(),
          userId: this.userService.userID,
          lastUpdate: ClientFormHelper.nullDate(),
          recSource: '4',
          deleted:false, 
        };
        this.clientData.clientMedicalCaseManagementCarePlan = mcmModel;
      }
    }
 
  }


  onDelete(){
   // this.clientForm.get("demographics").get('idTransmissionModes').markAsPristine();

  }

  onSave() {
      // if (! this.clientForm.valid){
      //   this.spinning = false;
      //   this.messageService.add({severity:'error', summary: 'Validation Errors Exist', 
      //                             detail: 'Valdiation errors exist. Please fix fields in red before saving.', life: 3000});
      //   this.clientService.getInvalidControlsList(this.clientForm,"");
          
      //     // const invalid = [];
      //     // const controls = this.clientForm.controls;
      //     // for (const formGroup in controls) {
      //     //     const formGroupControls = toArray(this.clientForm.get(formGroup).controls);
      //     //     //console.log(formGroup);
      //     //     for (const control in formGroupControls ){
      //     //       if (controls[control].invalid) {
      //     //         invalid.push(control);
      //     //         console.log(control);
      //     //       }
      //     //     }
      //     //   }
      //     //}
      //     //return invalid;
      //   return
      // }

      //this.clientForm.get('demographics').get('idTransmissionModes').markAsPristine();
      //this.dirtyControls(this.clientForm);

    //this.onValidate();
    console.log( this.clientForm?.controls.identification.get('idGender').dirty);
    this.spinning = true;
    this.setFormToClientObject();
    // the client alt id should already be set by this point, this is an extra safety net
    if (this.clientService.newclient && this.clientData?.clientAltId?.length < 9) {
      this.clientData.clientAltId = this.clientService.createClientAltId(this.clientData.firstName, this.clientData.lastName, this.clientData.gender, this.clientData.ssn, this.clientData.birthdate, this.clientData?.clientAltId);
    }
    this.clientService.postClientData(this.clientData);
  }

  // private dirtyControls() {
  //   let changedProperties = [];
  
  //   Object.keys(this.clientForm.controls).forEach((name) => {
  //     const currentControl = this.clientForm.controls[name];
  
  //     if (currentControl.dirty) {
  //       console.log(name);
  //     }
  //   });
  
  //   //return changedProperties;
  // }


  showSpinnerUntilCompleted<T>(obs$: Observable<T>): Observable<T> {
    return of(null)
        .pipe(
            tap(() => this.On()),
            concatMap(() => obs$),
            finalize(() => this.Off())
        );
  }

  On() {
    this.spinnerSubject.next(true);
  }

  Off() {
      this.spinnerSubject.next(false);
  }

  public checkValidity(): void {
    Object.keys(this.clientForm.controls).forEach((key) => {
        this.clientForm.controls[key].markAsDirty();
    });
  }



  public showClientLookupDialog(){
  
    // this.diagLookupRef = this.dialogService.open(ClientLookupComponent, {
    //     header: 'Client Lookup',
    //     width: '80%',
    //     height:'65%',
    //     contentStyle: {"max-height": "600px", "overflow": "visible"},
    //     baseZIndex: 10,
    //     data: {
    //         clientAltId:this.clientService.clientData.clientAltId
    //     }
    // });
    //this.clientAltId = this.clientService.clientData.clientAltId;

    let params = [];
    params.push({name:"ProviderID", value:this.userService.CurrentProviderID.toString()});
    params.push({name:"UserID", value: this.userService.userID.toString()});
    params.push({name:"ContractGuid", value: ClientFormHelper.EmptyGuid()});  //ClientFormHelper.EmptyGuid()}
    params.push({name:"ClientID", value: this.clientService.clientData.clientAltId});
    params.push({name:"StartDate", value:format(subYears(new Date(), 3), 'MM/dd/yyyy')});
    params.push({name:"EndDate", value:format(new Date(), 'MM/dd/yyyy')});

    
    params.push({name:"ServiceCat", value:"0"});
    params.push({name:"SubType", value:"0"});

    var reportModel : ReportsModel = { reportId: 1 ,reportName: "ClientLookup", 
      reportFormat:"PDF", parameters:params };

    this.spinnerService.On();
    this.reportService.runReport(reportModel).subscribe(
      {
        next: (result: any) => {
          let blob = new Blob([result], { type: 'application/pdf' });
          let pdfUrl = window.URL.createObjectURL(blob);

          var File_link = document.createElement('a');
          File_link.href = pdfUrl;
          //   TO OPEN PDF ON BROWSER IN NEW TAB
          //window.open(pdfUrl, '_blank');
          //   TO DOWNLOAD PDF TO YOUR COMPUTER
          
          File_link.download = ("ClientLookup" + format(new Date(), 'yyyyMMddHHmmss')+ ".pdf").toLowerCase();
          File_link.click();



        },
        error: (e: HttpErrorResponse) => {
          console.log(e) ;
          this.userService.LogError(e);
          this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.message, sticky: true});
          this.spinnerService.Off();
        }
        ,
        complete:() => {  
                        this.spinnerService.Off();
                        //this.messageService.add({severity: 'info', summary: 'Completed', detail: "Report executed sucessfully and can be found in downloads window."});
                      }
      }     
     );

}


onOpenEligibiilitPopup(event) {

    this.dsEligilbiltyModalRef = this.dialogService.open(EligibiltyPopupComponent, {
      header: 'Eligibilty Information',
      width: '60rem',
      //height:'40 rem',
      //contentStyle: {"max-height": "31rem", "overflow": "visible"},
      data: {
          eligibilityAlert: this.eligibilityAlert
      }
    });
  }

  onOpenRecertificationPopup(event) {

    this.dsRecertificationModalRef = this.dialogService.open(CertificationPopupComponent, {
      header: 'Certification Information',
      width: '60rem',
      data: {
        certificationAlert: this.certificationAlert
      }
    });
  }  

}