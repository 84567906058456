import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCommon, ClientMedicalFemalePapSmearModel, ClientMedicalFemalePelvicExamModel, ClientMedicalFemalePregnancyModel,
         ClientMedicalAntiretroviralPreventionModel, ClientMedicalFemaleBirthResultsModel } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields, HIVStatus, Gender } from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { faGalacticSenate } from '@fortawesome/free-brands-svg-icons';
import { SelectItem } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { v4 as uuidv4 } from 'uuid';
import { isEqual, cloneDeep } from 'lodash';

@Component({
  selector: 'app-female-only',
  templateUrl: './female-only.component.html',
  styleUrls: ['./female-only.component.scss']
})
export class FemaleOnlyComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  YesnoMedical: CommonData[];
  papNoReasons: CommonData[];
  pelvicNoReasons: CommonData[];
  anitRetroNoReasons: CommonData[];
  trimesterReasons: CommonData[];
  genders: SelectItem[];
  hivStatuses: SelectItem[];
  MedicalYesNo = MedicalYesNo;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();
  //clonedClientMedicalFemaleBirthResults: { [s: string]: ClientMedicalFemaleBirthResultsModel; } = {};
  originalClientMedicalFemaleBirthResults: Partial<ClientMedicalFemaleBirthResultsModel>[];
  isRowNew: boolean = false;
  isRowInEditMode: boolean = false;
  rowIndexInEditMode = -1;  

  getPapSmearScreenedControl = () => this.medical?.get('idPAPScreened');
  getPapSmearScreenedControlValue = () => this.getPapSmearScreenedControl()?.value == this.MedicalYesNo.Yes;
  setPapSmearScreenedControlValue = (value: boolean) => this.getPapSmearScreenedControl().setValue(value ? this.MedicalYesNo.Yes : this.MedicalYesNo.No);
  getPapSmearScreenedNoReasonControl = () => this.medical?.get('idPAPScreenedNoReason');
  getPapSmearResultDateControl = () => this.medical?.get('idPAPScreenedDate');
  showPapSmearNoReason = () => (this.getPapSmearScreenedControl()?.value ?? 0) == this.MedicalYesNo.No;
  showPapSmearResultFields = () => (this.getPapSmearScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  getPapSmearScreenedNoReasonDescription = () => this.papNoReasons.find(f => f.id == this.getPapSmearScreenedNoReasonControl().value)?.description;

  getPelvicExamScreenedControl = () => this.medical?.get('idPelvicScreened');
  getPelvicExamScreenedControlValue = () => this.getPelvicExamScreenedControl()?.value == this.MedicalYesNo.Yes;
  setPelvicExamScreenedControlValue = (value: boolean) => this.getPelvicExamScreenedControl().setValue(value ? this.MedicalYesNo.Yes : this.MedicalYesNo.No);
  getPelvicExamScreenedNoReasonControl = () => this.medical?.get('idPelvicScreenedNoReason');
  getPelvicExamResultDateControl = () => this.medical?.get('idPelvicScreenedDate');
  showPelvicExamNoReason = () => (this.getPelvicExamScreenedControl()?.value ?? 0) == this.MedicalYesNo.No;
  showPelvicExamResultFields = () => (this.getPelvicExamScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  getPelvicExamScreenedNoReasonDescription = () => this.pelvicNoReasons.find(f => f.id == this.getPelvicExamScreenedNoReasonControl().value)?.description;

  getPregnancyScreenedControl = () => this.medical?.get('idPregnancyScreened');
  getPregnancyScreenedControlValue = () => this.getPregnancyScreenedControl()?.value == this.MedicalYesNo.Yes;
  setPregnancyScreenedControlValue = (value: boolean) => this.getPregnancyScreenedControl().setValue(value ? this.MedicalYesNo.Yes : this.MedicalYesNo.No);
  getPregnancyTrimesterControl = () => this.medical?.get('idPregnancyTrimester');
  getPregnancyResultDateControl = () => this.medical?.get('idPregnancyScreenedDate');
  showPregnancyTrimester = () => (this.getPregnancyScreenedControl()?.value ?? 0) == this.MedicalYesNo.No;
  showPregnancyResultFields = () => (this.getPregnancyScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  getPregnancyTrimesterDescription = () => this.trimesterReasons.find(f => f.id == this.getPregnancyTrimesterControl().value)?.description;

  getAntiretroviralPreventionScreenedControl = () => this.medical?.get('idAntiPrevScreened');
  getAntiretroviralPreventionScreenedControlValue = () => this.getAntiretroviralPreventionScreenedControl()?.value == this.MedicalYesNo.Yes;
  setAntiretroviralPreventionScreenedControlValue = (value: boolean) => this.getAntiretroviralPreventionScreenedControl().setValue(value ? this.MedicalYesNo.Yes : this.MedicalYesNo.No);
  getAntiretroviralPreventionScreenedNoReasonControl = () => this.medical?.get('idAntiPrevScreenedNoReason');
  getAntiretroviralPreventionResultDateControl = () => this.medical?.get('idAntiPrevScreenedDate');
  showAntiretroviralPreventionNoReason = () => (this.getAntiretroviralPreventionScreenedControl()?.value ?? 0) == this.MedicalYesNo.No;
  showAntiretroviralPreventionResultFields = () => (this.getAntiretroviralPreventionScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  getAntiretroviralPreventionScreenedNoReasonDescription = () => this.anitRetroNoReasons.find(f => f.id == this.getAntiretroviralPreventionScreenedNoReasonControl().value)?.description;
  getAntiretroviralPreventionDescription = () => "Antiretroviral Therapy";


  getBirthResults = () => this.clientData?.clientMedicalFemaleBirthResults; 
  isBirthResultsRowInEditMode = (rowIndex: number) => (this.isRowInEditMode && (this.rowIndexInEditMode == rowIndex) )||  this.isRowNew;
  getBirthResultsBirthDateControl = () => this.medical?.get('idBirthResultsBirthDate');
  getBirthResultsGenderControl = () => this.medical?.get('idBirthResultsGender');
  getBirthResultsHIVStatusControl = () => this.medical?.get('idBirthResultsHIVStatus');

  //getGenderDescription = (clientMedicalFemaleBirthResult: ClientMedicalFemaleBirthResultsModel, genderId: number) => this.commonDataService.getDescription("Gender", genderId);
  getGenderDescription = (genderId: number) => this.commonDataService.getDescription("Gender", genderId);
  getHIVStatusDescription = (hivStatusId: number) => this.commonDataService.getDescription("HivStatus", hivStatusId);
  getBirthResultsBirthDateValue = (birthDate) => format(parseISO(birthDate), 'MM/dd/yyyy');
  getClientMedicalFemaleBirthResults = () => this.clientData?.clientMedicalFemaleBirthResults?.filter(f => !f.deleted);
  validBirthResults = () => (this.getBirthResultsBirthDateControl().valid ?? false) && (this.getBirthResultsGenderControl().valid ?? false) && (this.getBirthResultsHIVStatusControl().valid ?? false);

  isPatientFemale = () => this.clientData?.gender == Gender.Female;

  constructor(private fb: UntypedFormBuilder,
              private confirmationService: ConfirmationService,
              private commonDataService:CommonDataService,
              public providerService: ProviderService,
              private userService:UserService,
              public clientService:ClientFormService,
              private datePipe: DatePipe,
              private dateHelper:DateHelper,
              public  validationHelper:FormValidations,
              private helperService: HelperClassService,
              private medcommon:MedicalCommon) { 

  }

  ngOnInit(): void {
    this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
    this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate, 0);
    this.YesnoMedical = this.commonDataService.getCommonSetByName("YesnoMedicalNa", true);
    this.papNoReasons =  this.YesnoMedical.filter(x => x.id == 0 || x.id > 2);
    this.pelvicNoReasons =  this.YesnoMedical.filter(x => x.id == 0 || (x.id > 2 && x.id != 6));
    this.anitRetroNoReasons =  this.YesnoMedical.filter(x => x.id == 0 || (x.id > 2 && x.id != 6));
    this.trimesterReasons = this.commonDataService.getCommonSetByName("FemaleTrimester", true);
    this.genders = this.commonDataService.getCommonSetByName("Gender").map(data => { const result: any = {value:parseInt(`${data.id}`), label:`${data.description}`};
                                                                                     return result; });
    this.hivStatuses = this.commonDataService.getCommonSetByName("HivStatus").map(data => { const result: any = {value:parseInt(`${data.id}`), label:`${data.description}`};
                                                                                            return result; });

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       // notification for client loading/refreshing
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { this.userService.LogError(error); },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             // need this for comparing if anything changed in order to send to backend Web API save routine
                             this.originalClientMedicalFemaleBirthResults = cloneDeep(this.clientData.clientMedicalFemaleBirthResults);
                             this.setFieldValues();
                           }
                         });
     }

     if (!this.clientService.readonly) {
        this.validationHelper.AddRequiredMinMaxValidator(this.getPapSmearScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No);
        this.validationHelper.AddRequiredMinMaxValidator(this.getPelvicExamScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No);
        this.validationHelper.AddRequiredMinMaxValidator(this.getPregnancyScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No);
        this.validationHelper.AddRequiredMinMaxValidator(this.getAntiretroviralPreventionScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No);
        this.getPapSmearScreenedControl().valueChanges.subscribe(value => { this.setScreened(); });
        this.getPelvicExamScreenedControl().valueChanges.subscribe(value => { this.setScreened(); });
        this.getPregnancyScreenedControl().valueChanges.subscribe(value => { this.setScreened(); });
        this.getAntiretroviralPreventionScreenedControl().valueChanges.subscribe(value => { this.setScreened(); });
     }
  }

  refreshAfterSave() {
    //this.clientData.clientMedicalFemalePapSmear.lastUpdate = new Date().toISOString();
    this.setFieldValues();
    // reset as original, need this for comparing if anything changed in order to send to backend Web API save routine
    this.originalClientMedicalFemaleBirthResults = (!!this.clientData?.clientMedicalFemaleBirthResults) ? cloneDeep(this.clientData.clientMedicalFemaleBirthResults) : null;
    this.isRowInEditMode = false;
  }  

  setFieldValues() {
    if (!this.clientData) return;

    // Pap Smear section
    if (!this.helperService.isExpired(this.clientData?.clientMedicalFemalePapSmear?.expired)) {
      if (this.clientData?.clientMedicalFemalePapSmear?.performed != null)
        this.setPapSmearScreenedControlValue(this.clientData.clientMedicalFemalePapSmear?.performed);

      if (this.clientData?.clientMedicalFemalePapSmear?.noReason != null)
        this.getPapSmearScreenedNoReasonControl().setValue(this.clientData.clientMedicalFemalePapSmear?.noReason);

      if (this.clientData?.clientMedicalFemalePapSmear?.datetime != null)
        this.getPapSmearResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalFemalePapSmear?.datetime));      
    }

    // Pelvic Exam section
    if (!this.helperService.isExpired(this.clientData?.clientMedicalFemalePelvicExam?.expired)) {
      if (this.clientData?.clientMedicalFemalePelvicExam?.performed != null)
        this.setPelvicExamScreenedControlValue(this.clientData.clientMedicalFemalePelvicExam?.performed);

      if (this.clientData?.clientMedicalFemalePelvicExam?.noReason != null)
        this.getPelvicExamScreenedNoReasonControl().setValue(this.clientData.clientMedicalFemalePelvicExam?.noReason);

      if (this.clientData?.clientMedicalFemalePelvicExam?.datetime != null)
        this.getPelvicExamResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalFemalePelvicExam?.datetime));      
    }

    // Pregnancy section
    if (!this.helperService.isExpired(this.clientData?.clientMedicalFemalePregnancy?.expired)) {
      if (this.clientData?.clientMedicalFemalePregnancy?.pregnant != null)
        this.setPregnancyScreenedControlValue(this.clientData.clientMedicalFemalePregnancy?.pregnant);

      if (this.clientData?.clientMedicalFemalePregnancy?.id != null)
        this.getPregnancyTrimesterControl().setValue(this.clientData.clientMedicalFemalePregnancy?.id);

      if (this.clientData?.clientMedicalFemalePregnancy?.datetime != null)
        this.getPregnancyResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalFemalePregnancy?.datetime));      
    }

    // AntiretroviralPrevention section
    if (!this.helperService.isExpired(this.clientData?.clientMedicalAntiretroviralPrevention?.expired)) {
      if (this.clientData?.clientMedicalAntiretroviralPrevention?.performed != null)
        this.setAntiretroviralPreventionScreenedControlValue(this.clientData.clientMedicalAntiretroviralPrevention?.performed);

      if (this.clientData?.clientMedicalAntiretroviralPrevention?.noReason != null)
        this.getAntiretroviralPreventionScreenedNoReasonControl().setValue(this.clientData.clientMedicalAntiretroviralPrevention?.noReason);

      if (this.clientData?.clientMedicalAntiretroviralPrevention?.datetime != null)
        this.getAntiretroviralPreventionResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalAntiretroviralPrevention?.datetime));      
    }

    // Birth Results section
    if (this.clientData?.clientMedicalFemaleBirthResults?.length > 0 && !this.helperService.isExpired(this.clientData?.clientMedicalFemaleBirthResults[0].expired)) {
      console.log(this.clientData?.clientMedicalFemaleBirthResults[0]);
    }
  }

  setScreened() {
    if (!this.isPatientFemale()) {
      this.validationHelper.RemoveValidators(this.getPapSmearScreenedNoReasonControl(), true);
      this.validationHelper.RemoveValidators(this.getPapSmearResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getPelvicExamScreenedNoReasonControl(), true);
      this.validationHelper.RemoveValidators(this.getPelvicExamResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getPregnancyResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getPregnancyTrimesterControl(), true);
      this.validationHelper.RemoveValidators(this.getAntiretroviralPreventionScreenedNoReasonControl(), true);
      this.validationHelper.RemoveValidators(this.getAntiretroviralPreventionResultDateControl(), true);
    }
    else {
      // Pap Smear section
      if (this.getPapSmearScreenedControl()?.value == this.MedicalYesNo.Yes) {
        this.validationHelper.RemoveValidators(this.getPapSmearScreenedNoReasonControl(), true);
        this.validationHelper.AddValidators(this.getPapSmearResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]);
      }
      else if (this.getPapSmearScreenedControl()?.value == this.MedicalYesNo.No) {
        //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
        this.validationHelper.AddValidators(this.getPapSmearScreenedNoReasonControl(), this.validationHelper.dropdownValidator());
        this.validationHelper.RemoveValidators(this.getPapSmearResultDateControl(), true);
        this.getPapSmearResultDateControl().setValue(null);
      }

      // Pelvic Exam section
      if (this.getPelvicExamScreenedControl()?.value == this.MedicalYesNo.Yes) {
        this.validationHelper.RemoveValidators(this.getPelvicExamScreenedNoReasonControl(), true);
        this.validationHelper.AddValidators(this.getPelvicExamResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]);
      }
      else if (this.getPelvicExamScreenedControl()?.value == this.MedicalYesNo.No) {
        //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
        this.validationHelper.AddValidators(this.getPelvicExamScreenedNoReasonControl(), this.validationHelper.dropdownValidator());
        this.validationHelper.RemoveValidators(this.getPelvicExamResultDateControl(), true);
        this.getPelvicExamResultDateControl().setValue(null);
      }

      // Pregnancy section
      if (this.getPregnancyScreenedControl()?.value == this.MedicalYesNo.Yes) {
        this.validationHelper.AddValidators(this.getPregnancyResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]);
        this.validationHelper.AddValidators(this.getPregnancyTrimesterControl(), this.validationHelper.dropdownValidator());
      }
      else if (this.getPregnancyScreenedControl()?.value == this.MedicalYesNo.No) {
        //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
        this.validationHelper.RemoveValidators(this.getPregnancyResultDateControl(), true);
        this.validationHelper.RemoveValidators(this.getPregnancyTrimesterControl(), true);
        this.getPregnancyResultDateControl().setValue(null);
      }

      // AntiretroviralPrevention section
      if (this.getAntiretroviralPreventionScreenedControl()?.value == this.MedicalYesNo.Yes) {
        this.validationHelper.RemoveValidators(this.getAntiretroviralPreventionScreenedNoReasonControl(), true);
        this.validationHelper.AddValidators(this.getAntiretroviralPreventionResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]);
      }
      else if (this.getAntiretroviralPreventionScreenedControl()?.value == this.MedicalYesNo.No) {
        //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
        this.validationHelper.AddValidators(this.getAntiretroviralPreventionScreenedNoReasonControl(), this.validationHelper.dropdownValidator());
        this.validationHelper.RemoveValidators(this.getAntiretroviralPreventionResultDateControl(), true);
        this.getAntiretroviralPreventionResultDateControl().setValue(null);
      }
    }
  }

	getFemalePapSmearModel(): Partial<ClientMedicalFemalePapSmearModel> {
	  var model: Partial<ClientMedicalFemalePapSmearModel> = new ClientMedicalCommon(this.userService.userID); //new ClientMedicalCommon(this.medical.value)

    if (this.getPapSmearScreenedControl().dirty || this.getPapSmearScreenedNoReasonControl().dirty || this.getPapSmearResultDateControl().dirty)
    {
      let date_screened = (!this.getPapSmearResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getPapSmearResultDateControl().value, { representation: 'date' });

      model.performed = this.getPapSmearScreenedControlValue() ?? false;
      model.datetime = date_screened;
      model.noReason = this.getPapSmearScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      //model.guid = ClientFormHelper.EmptyGuid();
      //model.deleted = false;
      //model.userId = this.userService.userID;
      //model.lastUpdate = ClientFormHelper.nullDate();
      //model.expired = null;

      return model;
    } 

    return null;
	}

  getFemalePelvicExamModel(): Partial<ClientMedicalFemalePelvicExamModel> {
	  var model: Partial<ClientMedicalFemalePelvicExamModel> = new ClientMedicalCommon(this.userService.userID); //new ClientMedicalCommon(this.medical.value)

    if (this.getPelvicExamScreenedControl().dirty || this.getPelvicExamScreenedNoReasonControl().dirty || this.getPelvicExamResultDateControl().dirty)
    {
      let date_screened = (!this.getPelvicExamResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getPelvicExamResultDateControl().value, { representation: 'date' });

      model.performed = this.getPelvicExamScreenedControlValue() ?? false;
      model.datetime = date_screened;
      model.noReason = this.getPelvicExamScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      //model.guid = ClientFormHelper.EmptyGuid();
      //model.deleted = false;
      //model.userId = this.userService.userID;
      //model.lastUpdate = ClientFormHelper.nullDate();
      //model.expired = null;

      return model;
    }

    return null;
	}

  getFemalePregnancyModel(): Partial<ClientMedicalFemalePregnancyModel> {
	  var model: Partial<ClientMedicalFemalePregnancyModel> = new ClientMedicalCommon(this.userService.userID); //new ClientMedicalCommon(this.medical.value)

    if (this.getPregnancyScreenedControl().dirty || this.getPregnancyTrimesterControl().dirty || this.getPregnancyResultDateControl().dirty)
    {
      let date_screened = (!this.getPregnancyResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getPregnancyResultDateControl().value, { representation: 'date' });

      model.pregnant = this.getPregnancyScreenedControlValue() ?? false;
      model.datetime = date_screened;
      model.id = this.getPregnancyTrimesterControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      //model.guid = ClientFormHelper.EmptyGuid();
      //model.deleted = false;
      //model.userId = this.userService.userID;
      //model.lastUpdate = ClientFormHelper.nullDate();
      //model.expired = null;

      return model;
    } 

    return null;
	} 

  getFemaleAntiretroviralPreventionModel(): Partial<ClientMedicalAntiretroviralPreventionModel> {
	  var model: Partial<ClientMedicalAntiretroviralPreventionModel> = new ClientMedicalCommon(this.userService.userID); //new ClientMedicalCommon(this.medical.value)

    if (this.getAntiretroviralPreventionScreenedControl().dirty || this.getAntiretroviralPreventionScreenedNoReasonControl().dirty || this.getAntiretroviralPreventionResultDateControl().dirty)
    {
      let date_screened = (!this.getAntiretroviralPreventionResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getAntiretroviralPreventionResultDateControl().value, { representation: 'date' });

      model.performed = this.getAntiretroviralPreventionScreenedControlValue() ?? false;
      model.datetime = date_screened;
      model.noReason = this.getAntiretroviralPreventionScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      //model.guid = ClientFormHelper.EmptyGuid();
      //model.deleted = false;
      //model.userId = this.userService.userID;
      //model.lastUpdate = ClientFormHelper.nullDate();
      //model.expired = null;

      return model;
    }

    return null;
	}

  getFemaleBirthResultsModelArray() {
    if (!this.clientData?.clientMedicalFemaleBirthResults) return null;
    
    //var arr1 = this.clientData.clientMedicalFemaleBirthResults; //.map(element => { element.guid, element.birthDate, element.genderId, element.hivStatusId, element.deleted });
    //var arr2 = this.originalClientMedicalFemaleBirthResults; //.map(element => { element.guid, element.birthDate, element.genderId, element.hivStatusId, element.deleted });
    //if (this.getBirthResultsGenderControl().dirty || this.getBirthResultsBirthDateControl().dirty || this.getBirthResultsHIVStatusControl().dirty)
    if (!isEqual(this.clientData.clientMedicalFemaleBirthResults, this.originalClientMedicalFemaleBirthResults))
    {
      return this.clientData.clientMedicalFemaleBirthResults;
    }
    return null;
  }

  onRowEditInit(clientMedicalFemaleBirthResult: Partial<ClientMedicalFemaleBirthResultsModel>, rowIndex: number) {
    this.rowIndexInEditMode = rowIndex; 
    //this.clonedClientMedicalFemaleBirthResults[clientMedicalFemaleBirthResult.guid] = {...clientMedicalFemaleBirthResult};
    this.getBirthResultsBirthDateControl().setValue(parseISO(clientMedicalFemaleBirthResult.birthDate));
    this.getBirthResultsGenderControl().setValue(clientMedicalFemaleBirthResult.genderId);
    this.getBirthResultsHIVStatusControl().setValue(clientMedicalFemaleBirthResult.hivStatusId);
    this.isRowInEditMode = true;
    this.clientService.disableSave=true;
  }

  onRowEditSave(clientMedicalFemaleBirthResult: ClientMedicalFemaleBirthResultsModel, index: number) {
    // shuldn't need to do this, 2 way binding should handle
    // this.clientData.clientMedicalFemaleBirthResults[index] = {...clientMedicalFemaleBirthResult};
    // this.clientData.clientMedicalFemaleBirthResults[index].birthDate = new Date(this.getBirthResultsBirthDateControl().value).toISOString();
    // this.clientData.clientMedicalFemaleBirthResults[index].genderId = this.getBirthResultsGenderControl().value;
    // this.clientData.clientMedicalFemaleBirthResults[index].hivStatusId = this.getBirthResultsHIVStatusControl().value;
    clientMedicalFemaleBirthResult.birthDate = formatISO(this.getBirthResultsBirthDateControl().value, { representation: 'date' }); //new Date(this.getBirthResultsBirthDateControl().value).toISOString();
    clientMedicalFemaleBirthResult.genderId = this.getBirthResultsGenderControl().value;
    clientMedicalFemaleBirthResult.hivStatusId = this.getBirthResultsHIVStatusControl().value;
    this.isRowInEditMode = this.isRowNew = false;
    this.clientService.disableSave=false;
  }

  onRowEditCancel(clientMedicalFemaleBirthResult: ClientMedicalFemaleBirthResultsModel, index: number) {
    if (this.isRowNew) {
      var removedGroup = this.getBirthResults().shift();
    }
    else {
      this.getBirthResultsBirthDateControl().setValue(parseISO(clientMedicalFemaleBirthResult.birthDate));
      this.getBirthResultsGenderControl().setValue(clientMedicalFemaleBirthResult.genderId);
      this.getBirthResultsHIVStatusControl().setValue(clientMedicalFemaleBirthResult.hivStatusId);
    }
    this.isRowInEditMode = this.isRowNew = false;
    this.clientService.disableSave=false;
  }

  onRowDelete(event: Event, clientMedicalFemaleBirthResult: ClientMedicalFemaleBirthResultsModel, index: number) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete the selected row?',
      header: 'Confirm',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        clientMedicalFemaleBirthResult.deleted = true;
      }
    });    
  }

  onRowAdd(dt) {
    // var model: Partial<ClientMedicalFemaleBirthResultsModel> = { guid: this.helperService.getEmptyGuid(), genderId: this.genders[0].value, hivStatusId: this.hivStatuses[0].value,
    //                                                              birthDate: new Date().toISOString(), deleted: false };
    var model: Partial<ClientMedicalFemaleBirthResultsModel> = new ClientMedicalCommon(this.userService.userID);
    model.guid = uuidv4();
    model.genderId = this.genders[0].value;
    var hivStatusIndex = this.hivStatuses.findIndex(f => f.value == HIVStatus.HIVStatusUnknown);
    model.hivStatusId = this.hivStatuses[hivStatusIndex == -1 ? 0 : hivStatusIndex].value;
    model.birthDate = new Date().toISOString();
    // var fullModel = Object.assign({
    //   guid:  uuidv4(),
    //   genderId: this.genders[0].value,
    //   hivStatusId: this.hivStatuses[0].value,
    //   birthDate: new Date().toISOString()
    // }, partialModel);
    //var len = this.clientData?.clientMedicalFemaleBirthResults.push(model) - 1;
    //this.clientData.clientMedicalFemaleBirthResults.splice(0, 0, model);

    var len = this.getBirthResults().unshift(model) ;
    var index = 0;
    this.isRowNew = true;
    dt.clear();
    this.onRowEditInit(model,index);
    dt.initRowEdit(this.clientData.clientMedicalFemaleBirthResults[index]);
  }

  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }
}
