import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { HistoryRequest, HistoryResults, HistoryHeader, HistoryDetail,
         MedicalHistoryRequest, MedicalHistoryResults, HistoryDeletedItem } from './history-models' 
import { environment } from 'src/environments/environment';
//import { stringify } from '@angular/compiler/src/util';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientHistoryService {
  // URL which returns list of JSON items (API end-point URL)
  private readonly URL = environment.apiURL + '/clienthistory';
  constructor(private http: HttpClient) { }

  public getClientHistory(request: HistoryRequest): Observable<HistoryResults>
  { 
     let url  = this.URL;
     return this.http.post<HistoryResults>(url, request)
  }

  public deleteHistoryItem(request: HistoryDeletedItem): Observable<boolean>
  { 
     let url  = this.URL + '/DeleteHistoryItem';
     return this.http.post<boolean>(url, request)
  }


  // spoke with Den on 1/18/2022 and he wants this in the common history format above
  // public getClientMedicalHistory(request: MedicalHistoryRequest): Observable<MedicalHistoryResults>
  // { 
  //    let url  = this.URL + '/getMedicalHistory'; 
  //    return this.http.post<MedicalHistoryResults>(url, request)
  // }  
}
