import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HistoryRequest, HistoryResults, HistoryHeader, HistoryDetail, MedicalHistoryRequest, MedicalHistoryResults, HistoryDeletedItem } from './services/history-models' 
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { ClientHistoryService} from './services/history.service'
import { Observable, forkJoin, noop, of, map } from 'rxjs';
import { UserService } from 'src/app/services/user.service';



@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnDestroy {

  _historyResult: HistoryResults;
  _details: HistoryDetail[];

  public clientGuid: string = "";
  public clientAltID: string = "";
  public dataElement: string = "";
  public title: string = "";
  public height: number;
  public width: string = "150vw";
  public historyVisible: Boolean = false;
  //public medicalHistoryVisible: Boolean = false;
  loadHistory$: Observable<HistoryResults>;
  //loadMedicalHistory$: Observable<MedicalHistoryResults>;
 
  constructor(
    private clientHistoryService: ClientHistoryService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService,
    public userService:UserService
    ) { 
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.historyVisible = false;
    //this.medicalHistoryVisible = false;
  }

  public showHistory() {
    this.refreshData();
    this.historyVisible = true;   
  }

  public refreshData() {
    var request: HistoryRequest =
    {  
      clientGuid: this.clientGuid,
      dataElement: this.dataElement
    };
    //this.loadHistory$ = this.clientHistoryService.getClientHistory(request).pipe(map( elem => ({ clientGuid: elem.clientGuid, header: elem.header, details:elem.details.filter(f => !f.deleted) })));
    this.loadHistory$ = this.clientHistoryService.getClientHistory(request);
  }

  // spoke with Den on 1/18/2022 and he wants this in the common history format above
  // public showMedicalHistory() {
  //   var request: MedicalHistoryRequest =
  //   {  
  //     clientGuid: this.clientGuid,
  //     dataElement: this.dataElement
  //   };
  //   this.loadMedicalHistory$ = this.clientHistoryService.getClientMedicalHistory(request);
  //   this.medicalHistoryVisible = true;      
  // }

  onDeleteHistoryItem(event, detail: HistoryDetail, results: HistoryResults) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete this row dated ' + detail.datetime + '?',
      header: 'Confirm',
      icon: 'pi pi-info-circle',
      accept: () => {
          // ****** make the call to delete the item
          var histDeletedItem: HistoryDeletedItem = { guid: detail.guid, tableName: results.header.note1 };
          this.clientHistoryService.deleteHistoryItem(histDeletedItem).subscribe(
              {
                next: (data) => {   
                  console.log(data);
                  noop;
                },
                error: (error) => {
                  console.log("ERROR LOG CHAMP4"  + error);
                  this.messageService.add({severity: "error", summary: "Failed", detail: "Delete failed", life: 1000});
                },
                complete: () => {
                  // we could flag deleted from either the history detail item (detail), or from the HistoryResults (results) both will point to the same reference
                  // just picked the first one since it was easier
                  detail.deleted = true;
                  //var det = results.details.find(f => f.guid == detail.guid);
                  //if (!!det) det.deleted = true;
                  // this prevents a server side call, basically just filters the local observable dataset
                  this.loadHistory$ = this.loadHistory$.pipe(map( elem => ({ clientGuid: elem.clientGuid, header: elem.header, details:elem.details.filter(f => !f.deleted) })));
                  this.messageService.add({severity:'success', summary: 'Successful', detail: 'Service Deleted', life: 1000});
                  //this.refreshData();
                }
              }
          );
      },
      reject: () => {
          this.messageService.add({severity: "error", summary: "Aborted", detail: "Delete canceled", life: 1000});
          }
      });


  }

}
