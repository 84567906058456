<p-dialog header= "{{title}} history for {{clientAltID}}" [modal]="true" [draggable]="true" [resizable]="true" [baseZIndex]="10000"
                [(visible)]="historyVisible"   appendTo="body">

          <!-- [style]="{width: '20vw', minWidth: '30vw'}"      [ngStyle]="{'width': '300vw'}"   [style]="{width: '50vw', minWidth: '30vw'}"    [style]="{width: '50vw', minWidth: '30vw'}"  -->
    <div class="fluid formgrid grid">
        <div class="field p-col"> <!-- style="display: inline-block; vertical-align: top" -->
            <ng-template #loading>
                <div class="champ-notification-loading">Loading services...</div>
            </ng-template>                
        </div>
    </div>

    <div *ngIf="(loadHistory$ | async) as histResults; else loading">   <!--     -->
        <p-table #dtHistory [value]="histResults.details" styleClass="p-datatable-sm p-datatable-striped " name="HistoryResults">   <!--   [scrollable]="true" scrollHeight="200px"  -->
            <ng-template pTemplate="header">
                <tr>
                    <th  pSortableColumn="datetime" style="width:150px">Date<p-sortIcon field="datetime"></p-sortIcon></th>
                    <th  pSortableColumn="description" *ngIf="histResults?.header?.showDescription">Description<p-sortIcon field="description" ></p-sortIcon></th>      <!-- *ngIf="!!histResults?.header?.showDescription" -->
                    
                    <th  pSortableColumn="col1"  *ngIf="!!histResults?.header?.colName1" >{{histResults.header.colName1}}<p-sortIcon field="col1"></p-sortIcon></th>
                    <th  pSortableColumn="col2"  *ngIf="!!histResults?.header?.colName2" >{{histResults.header.colName2}}<p-sortIcon field="col2"></p-sortIcon></th>    
                    <th  pSortableColumn="col3"  *ngIf="!!histResults?.header?.colName3" >{{histResults.header.colName3}}<p-sortIcon field="col3"></p-sortIcon></th>   
                    <th  pSortableColumn="col4"  *ngIf="!!histResults?.header?.colName4" >{{histResults.header.colName4}}<p-sortIcon field="col4"></p-sortIcon></th>   
                    <th  pSortableColumn="col5"  *ngIf="!!histResults?.header?.colName5" >{{histResults.header.colName5}}<p-sortIcon field="col5"></p-sortIcon></th> 
                    <th  pSortableColumn="col6"  *ngIf="!!histResults?.header?.colName6" >{{histResults.header.colName6}}<p-sortIcon field="col6"></p-sortIcon></th>
                    <th  pSortableColumn="col7"  *ngIf="!!histResults?.header?.colName7" >{{histResults.header.colName7}}<p-sortIcon field="col7"></p-sortIcon></th>    
                    <th  pSortableColumn="col8"  *ngIf="!!histResults?.header?.colName8" >{{histResults.header.colName8}}<p-sortIcon field="col8"></p-sortIcon></th>   
                    <th  pSortableColumn="col9"  *ngIf="!!histResults?.header?.colName9" >{{histResults.header.colName9}}<p-sortIcon field="col9"></p-sortIcon></th>   
                    <th  pSortableColumn="col10"  *ngIf="!!histResults?.header?.colName10" >{{histResults.header.colName10}}<p-sortIcon field="col10s"></p-sortIcon></th> 
                    <th  pSortableColumn="username" style="width:75px">User<p-sortIcon field="username"></p-sortIcon></th>    
                    <th  pSortableColumn="lastUpdate" style="width:75px">Last Update<p-sortIcon field="lastUpdate"></p-sortIcon></th>
                    <th  pSortableColumn="expired" style="width:75px">Expired<p-sortIcon field="expired"></p-sortIcon></th>
                    <th  *ngIf="userService.hasAdminPermission"  style="width: 75px;" >Delete<p-sortIcon field="clientGuid" ></p-sortIcon></th>
                    <!-- <th  pSortableColumn="clientAltID" style="width: 75px;" >Delete<p-sortIcon field="clientGuid" ></p-sortIcon></th> -->
                </tr>
            </ng-template>

            <ng-template  pTemplate="body" ngFor let-HistoryDetail>
                <tr>   
                    <td [ngClass] ="'cl-client-date text-right'" style="width:150px" >{{ HistoryDetail.datetime | date:'MM/dd/yyyy  hh:mm:ss aa' }}</td>
                    <td *ngIf="!!histResults?.header?.showDescription">{{HistoryDetail.description}}</td>
                    <td *ngIf="!!histResults?.header?.colName1">{{HistoryDetail.col1}}</td>
                    <td *ngIf="!!histResults?.header?.colName2">{{HistoryDetail.col2}}</td>
                    <td *ngIf="!!histResults?.header?.colName3">{{HistoryDetail.col3}}</td>
                    <td *ngIf="!!histResults?.header?.colName4">{{HistoryDetail.col4}}</td>
                    <td *ngIf="!!histResults?.header?.colName5">{{HistoryDetail.col5}}</td>
                    <td *ngIf="!!histResults?.header?.colName6">{{HistoryDetail.col6}}</td>
                    <td *ngIf="!!histResults?.header?.colName7">{{HistoryDetail.col7}}</td>
                    <td *ngIf="!!histResults?.header?.colName8">{{HistoryDetail.col8}}</td>
                    <td *ngIf="!!histResults?.header?.colName9">{{HistoryDetail.col9}}</td>
                    <td *ngIf="!!histResults?.header?.colName10">{{HistoryDetail.col10}}</td>                     
                    <td >{{HistoryDetail.username}}</td>
                    <td [ngClass] ="'cl-client-date text-right'" style="width:75px" >{{ HistoryDetail.lastUpdate | date:'MM/dd/yyyy  hh:mm:ss aa' }}</td>
                    <td [ngClass] ="'cl-client-date text-right'" style="width:75px" >{{ HistoryDetail.expired | date:'MM/dd/yyyy  hh:mm:ss aa' }}</td>
                    <!-- <td> -->
                    <button *ngIf="userService.hasAdminPermission" title="Delete" pButton pRipple icon="pi pi-trash" class="p-button-rounded" style="color: red; background-color: white; border-color: white" (click)="onDeleteHistoryItem($event, HistoryDetail, histResults)"></button> 
                    <!-- </td> -->
                </tr>
            </ng-template>
        </p-table>
    </div>

</p-dialog>

