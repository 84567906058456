import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder, ValidatorFn } from '@angular/forms';
import { formatISO, getMonth, getYear, parseISO } from 'date-fns';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, lastValueFrom } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { ClientListModel } from 'src/app/models/client.model';
import { ClientReferralsModel, ClientReferralsRequestModel, ReferralStatusTypes, RequestType } from 'src/app/models/client_referrals.models';
import { ServiceCategory } from 'src/app/models/common-data.model';
import { YesNo } from 'src/app/models/enums';
import { ExternalProvider, ExternalProvidersModel } from 'src/app/models/provide.model';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ReferralService } from '../referral-edit-list/referrals.service';

@Component({
  selector: 'app-referral-edit',
  //standalone:true,
  //imports: [DialogModule],
  templateUrl: './referral-edit.component.html',
  styleUrl: './referral-edit.component.scss'
})
export class ReferralEditComponent implements OnInit{

  @Output("onRefresh") refreshReferralList: EventEmitter<any> = new EventEmitter();

  showReferralEditDialog:boolean = false;  
  //referralForm: UntypedFormGroup;
  //clientData: ClientModel;
  YesNoEnum = YesNo;  
  displayAssessmentDialog: boolean = false;
  displayOutsideProviderDialog: boolean = false;
  referrals:any;
  referral:ClientReferralsModel;
  referralServices$: Observable<ClientReferralsRequestModel>;
  directSereferralService: Partial<ClientReferralsModel> = null; //any = {};
  public  get requestType() {return RequestType };
  public  get referralStatusTypes() { return ReferralStatusTypes };
  months: any[] = this.helperService.getMonths();
  years: string[] = this.helperService.getYears();
  contractFunders: any[];
  funders: any[];
  referralProviders: any[];
  outsideProviders: any[];
  clientAltIds: ClientListModel[]; //SelectItem[] = [];
  serviceCategories: ServiceCategory[];
  subTypes: any[] = [];
  employees: any[];
  referralStatus:string = 'New'

  outsideProviderEnabled: boolean = false;  
  allowEdit: boolean = false;
  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  originDate: Date = new Date(0, 0); //Mon Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time) // new Date(1900, 0, 1)

  referralStatusDescription = (id:any) => { return this.app.commonServ.getDescription('ReferralStatus', id)[0];  }

  getReferralFunderControl = () => this.referralForm?.get('funderId');
  getReferralFunderValue = () => this.getReferralFunderControl()?.value;
  getReferralServiceTypeControl = () => this.referralForm?.get('serviceId');
  getReferralServiceTypeValue = () => this.getReferralServiceTypeControl()?.value;
  getReferralSubTypeControl = () => this.referralForm?.get('subType');
  getReferralSubTypeValue = () => this.getReferralSubTypeControl()?.value;
  getReferralProviderControl = () => this.referralForm?.get('clinicId');
  getReferralProviderValue = () => this.getReferralProviderControl()?.value;
  getReferralOutsideProviderIdControl = () => this.referralForm?.get('outsideProviderId');
  getReferralOutsideProviderIdValue = () => this.getReferralOutsideProviderIdControl()?.value ?? 0;
  getReferralOutsideProviderNameControl = () => this.referralForm?.get('outsideProviderName');
  getReferralOutsideProviderNameValue = () => this.getReferralOutsideProviderNameControl()?.value ?? '';
  getReferralOutsideProviderPhoneControl = () => this.referralForm?.get('outsideProviderPhone');
  getReferralOutsideProviderPhoneValue = () => this.getReferralOutsideProviderPhoneControl()?.value ?? '';
  getReferralOutsideProviderAddressControl = () => this.referralForm?.get('outsideProviderAddress');
  getReferralOutsideProviderAddressValue = () => this.getReferralOutsideProviderAddressControl()?.value ?? '';
  getReferralEmployeeControl = () => this.referralForm?.get('employeeId');
  getReferralEmployeeValue = () => this.getReferralEmployeeControl()?.value;
  getReferralConfirmLocationControl = () => this.referralForm?.get('confirmLocation');
  getReferralConfirmLocationValue = () => this.referralForm?.get('confirmLocation')?.value;
  getReferralMemoValue = () => this.referralForm?.get('memo')?.value;
  getReferralGuidValue = () => this.referralForm?.get('guid')?.value;
  getReferralClientGuidValue = () => this.referralForm?.get('clientGuid')?.value;
  getReferralDirectServiceGuidValue = () => this.referralForm?.get('directServiceGuid')?.value;
  getReferralContractGuidValue = () => this.referralForm?.get('contractGuid')?.value;
  getReferralDateTimeValue = () => this.referralForm?.get('datetime')?.value;



   public referralForm = new FormGroup({
      month: new FormControl(0),
      year: new FormControl(this.years[0]),
      guid:new FormControl('00000000-0000-0000-0000-000000000000'),
      referralDateTime: new FormControl(new Date),
      funderId:new FormControl(0),
      serviceId:new FormControl(0),
      subType:new FormControl(0),
      providerId:new FormControl(0),
      clinicId:new FormControl(0),
      employeeId:new FormControl(0),
      outsideProviderId: new FormControl(0),
      outsideProviderName: new FormControl(''),
      outsideProviderPhone: new FormControl(''),
      outsideProviderAddress: new FormControl(''),
      confirmLocation:new FormControl(''),
      memo:new FormControl(''),
      status:new FormControl(0),
    },
    
    {
      validators: [this.funderValidator(), this.serviceIdValidator(), this.subIdValidator(), this.providerIdValidator(), this.outsideProviderIdValidator(),
                   this.employeeIdValidator(), this.outsideProviderNameValidator(), this.confirmLocationValidator()]
    });
  


  getCurrentDate = () => { var dt = new Date(); dt.setHours(0,0,0,0); return dt; };
  getFunders = () => this.app.commonServ.getCommonSetByName("Funder", true, "ALL");
  getReferralDateMonthValue = () => getMonth(this.referralForm.value.referralDateTime) + 1;
  getReferralYearValue = () => getYear(this.referralForm.value.referralDateTime);

  //@ViewChild(PayerRepricersComponent ) payerRepricersComponent:  PayerRepricersComponent;

constructor(private fb: UntypedFormBuilder,
    public app: AppContextService,
    private providerService:ProviderService,
    private messageService: MessageService,
    public dialogService:DialogService,
    private referralService:ReferralService,
    public  clientService:ClientFormService,
    public  validationHelper:FormValidations,
    private helperService: HelperClassService,
    private confirmationService: ConfirmationService
    
   )   {

    var currentMonth = (getMonth(new Date()) + 1).toString(); //this.months[0].code
}

ngOnInit(): void {
  this.clearFormValues();
  this.setFieldValues();
}

setFieldValues() {
  // this.setMonthValue(this.directService.month);
  // this.setYearValue(this.directService.year);

  this.contractFunders = this.app.provSvc.getProviderContractFunderItems( this.referralForm.value.referralDateTime, true);
  this.funders = this.app.commonServ.getCommonSetByName("Funder", true);
  this.serviceCategories = this.app.commonServ.getServiceCategories(99999, true);
  //this.assessmentServiceTypes = this.commonDataService.getServiceCategories(99999, true);
  this.employees = this.providerService.getProviderEmployeeList(true);

  //this.funders = this.app.commonServ.getCommonSetByName
}

show(guid){
  this.showReferralEditDialog = true;

  if (guid != null ){
    this.getReferral(guid)
  } 
  else { 
     this.clearFormValues();
  }

}

onHide(){
  this.showReferralEditDialog = false;
 }

 onChangeFunder(funderKeys) {
  var funderId = Number(funderKeys.funderId);
  this.serviceCategories = this.app.commonServ.getServiceCategories(funderId, true);
  this
}

onChangeServiceType(serviceId: number) {
   var funderId = this.referralForm.value.funderId;
   this.subTypes = this.app.commonServ.getSubTypesByService(serviceId, funderId, true, true);
   // always reset subId to the first in the list here, setFieldValues will take care of assigning the correct id when loading existing to edit
   this.referralForm.value.serviceId =this.subTypes[0]?.id;
   //this.referralForm.value.unit = "";
}

getReferral(guid){
    let request:ClientReferralsRequestModel =   {
      requestType: RequestType.EditReferral,
      success: false,
      guid: guid,
      message: '',
      clientGuid: '',
      month: 0,
      year: 0,
      filterSelectionChoice: 0,
      dateSelectionChoice: 0,
      userId: 0,
      clinicId: 0,
      providerId: 0,
      referralStatus: 0,
      onlyShowInNetworkReferrals: false,
      referralCreatedReceived: 0,
      referrals: [],
      waitingCount: 0,
      showDeleted: false
    }
    this.referralService.PostReferralRequest(request).subscribe({                
      next: (result:ClientReferralsRequestModel) => { 
          if (result.success){
            this.referral = result.referrals[0];
            this.setFormValues()
          }

      },
      error: (error) => { },
      complete: () => {
      }
  });

}

saveReferral(){

  let referral: Partial<ClientReferralsModel> = {
    guid:this.referralForm.value.guid,
    //id: 0,
    clientGuid: this.clientService.clientData.guid,
    serviceId: this.referralForm.value.serviceId,
    subId: this.referralForm.value.subType,
    providerId: this.referralForm.value.providerId,
    //confirmStatus: 0,
    confirmLocation: this.referralForm.value.confirmLocation,
    memo: this.referralForm.value.memo,
    userId: this.app.userServ.userID,
    datetime: formatISO(this.referralForm.value.referralDateTime),
    employeeId: this.referralForm.value.employeeId,
    funderId: this.referralForm.value.funderId,
    clinicId: this.referralForm.value.clinicId,
    outsideProviderId: ( this.referralForm.value.clinicId == 0 ? this.referralForm.value.outsideProviderId : 0 ),
    sourceClinicId: this.app.userServ.CurrentClinicID

    //employeeName: '',
    //referralStatus: 0,
    //canProvideService: 0,
    //contactedReferrer: false,
    //contactedReferrerDate: '',
    //willProvideService: 0,
    //contractGuid: this.referralForm.value.,
    
    //funderName: '',
    // canProvideRefusal: 0,
    // willProvideRefusal: 0,
    // directServiceGuid: '',
     
    // destinationProviderId: 0,
    
    //completedDate: '',
    //completedNotes: '',
    //lostToFollowupDate: '',
    //lostToFollowupNote: '',
    //clientAltId: '',
    //providerName: '',
    //sourceProvider: '',
    //destinationProvider: '',
    //referralStatusDesc: '',
    //serviceDesc: '',
    //subTypeDesc: '',
    //referrerName: '',
    //referrerPhone: '',
    //referrerEmail: '',
    //referrerNote: ''
  }

  let referrals: ClientReferralsModel[] = [];
  referrals.push(referral as ClientReferralsModel);

  let request:ClientReferralsRequestModel =   {
    requestType: RequestType.Save,
    success: false,
    guid: this.referralForm.value.guid,
    message: '',
    clientGuid: '',
    month: 0,
    year: 0,
    filterSelectionChoice: 0,
    dateSelectionChoice: 0,
    userId: 0,
    clinicId: 0,
    providerId: 0,
    referralStatus: 0,
    onlyShowInNetworkReferrals: false,
    referralCreatedReceived: 0,
    referrals: referrals,
    waitingCount: 0,
    showDeleted: false
  }
 

  this.referralService.PostReferralRequest(request).subscribe({                
    next: (result:ClientReferralsRequestModel) => { 
        if (result.success){
          this.referral = result.referrals[0];
          this.setFormValues()
          this.onHide();
          this.refreshReferralList.emit();
        }

    },
    error: (error) => {
      this.app.loginSvc.LogError(error);

     },
    complete: () => {
    }
});


}


clearFormValues(){
  this.referralForm.patchValue({   
    guid: '00000000-0000-0000-0000-000000000000',
    //referralDateTime: this.referral?.datetime)new Date(parseISO(this.referral?.datetime)),
    funderId: 14,
    serviceId: 0,
    subType: 0,
    clinicId: -1,
    outsideProviderId: -1,
    employeeId: 0,

    //outsideProviderName: referral.outsideProviderId,
    //providerId:new FormControl(0),
    //outsideProviderPhone: referral.referrerPhone
    //outsideProviderAddress: new FormControl(''),

    confirmLocation: '',
    memo:'',
    status: this.referralStatusTypes.New
  });

}

setFormValues(){
  var funderId = this.referralForm.value.funderId;
  this.subTypes = this.app.commonServ.getSubTypesByService(this.referral.serviceId, this.referral.funderId, true, true);
  this.referralForm.patchValue({  referralDateTime:new Date(parseISO(this.referral.datetime)) });
  this.loadReferralProviders(this.referral.serviceId, this.referral.subId);

  this.referralForm.patchValue({   
    guid: this.referral.guid,
    //referralDateTime:new Date(parseISO(this.referral.datetime)),
    funderId: this.referral.funderId,
    serviceId: this.referral.serviceId,
    subType: this.referral.subId,
    clinicId: this.referral.clinicId,
    outsideProviderId: this.referral.outsideProviderId,
    employeeId: this.referral.employeeId,

    //outsideProviderName: referral.outsideProviderId,
    //providerId:new FormControl(0),
    //outsideProviderPhone: referral.referrerPhone
    //outsideProviderAddress: new FormControl(''),

    confirmLocation: this.referral.confirmLocation,
    memo:this.referral.memo,
    status: this.referral.referralStatus
  });

   this.onChangeReferralProvider(this.referral.clinicId);
}


loadReferralProviders(serviceId:number, subTypeId:number) {
  this.referralProviders = null;
  if (!!serviceId && !!subTypeId)
  {
    var year = this.getReferralYearValue();
    var month = this.getReferralDateMonthValue();
    var date = new Date(); var rangeDate = new Date(year, month, 1);
    this.referralProviders = this.providerService.getReferralProviders(serviceId, subTypeId, rangeDate, true);
  }

  this.referralForm.value.clinicId =(!!this.referralProviders ? this.referralProviders[0]?.clinicId : -1);
  this.onChangeReferralProvider(this.referralForm.value.clinicId);
}
s
onChangeReferralSubType(subTypeId:number) {
  this.loadReferralProviders(this.referralForm.value.serviceId, subTypeId);
}

onChangeReferralProvider(referralClinicId:number) {
  // load outside providers
  this.outsideProviders = null;
  if (referralClinicId == 0) {
    this.outsideProviders = this.providerService.getExternalProviders(true);
    this.outsideProviderEnabled = true;
  }
  else
    this.outsideProviderEnabled = false; 
}

onShowOutsideProviderDialog() {
  this.displayOutsideProviderDialog = true;
  var outsideProviderId = this.referralForm.value.outsideProviderId;
  var outsideProvider = this.providerService.getExternalProviderById(outsideProviderId);
  this.referralForm.value.outsideProviderName = (outsideProvider?.name ?? '');
  this.referralForm.value.outsideProviderPhone =(outsideProvider?.phone ?? '');
  this.referralForm.value.outsideProviderAddress = (outsideProvider?.address ?? '');    
}

onHideOutsideProviderDialog() {
 this.displayOutsideProviderDialog = false;
}

onOutsideProviderSaveChangesConfirm(event: Event) {
  this.confirmationService.confirm({
    target: event.target,
    message: "Are you sure that you want to proceed?",
    icon: "pi pi-info-circle",
    accept: () => {
      this.onOutsideProviderSaveChanges().then(value => {
        this.messageService.add({
          severity: "info",
          summary: "Confirmed",
          detail: "Changes Saved",
          life: 2000
        });         
      });
    },
    reject: () => {
      this.messageService.add({
        severity: "error",
        summary: "Aborted",
        detail: "Changes Canceled",
        life: 2000
      });
    }
  });
}

getReferralOutsideProviderModel(): Partial<ExternalProvidersModel> {
  var model:Partial<ExternalProvidersModel> = new ExternalProvider();
  model.dateTime = formatISO(model.dateTime ?? this.currentDate, { representation: 'date' });

  return model;
}

async onOutsideProviderSaveChanges() {
  var model = this.getReferralOutsideProviderModel();

  model.id = this.referralForm.value.outsideProviderId;
  model.sourceProviderId = this.providerService.providerID;
  model.name = this.referralForm.value.outsideProviderName;
  model.phone = this.referralForm.value.outsideProviderPhone;
  model.address = this.referralForm.value.outsideProviderAddress;

  try {
    var response = await lastValueFrom(this.providerService.saveExternalProvider(model));   
    const keys = JSON.parse(response.data);
    model.id = Number(keys.id);
    model.phone = keys.phone.toString(); // the web api cleanses special characters out of phone #
    this.referralForm.value.outsideProviderPhone = model.phone;
    // add or update the new external provider to the local external providers array
    this.providerService.updateExternalProvider(model);
    // next refresh the external provider list which adds the new external provider to assessmentOutsideProviders drop down
    this.outsideProviders = this.providerService.getExternalProviders(true);
    // now select the newly created external provider
    this.referralForm.value.outsideProviderId = model.id;
  }
  catch (error) {
    //console.log(error.error.message); console.log(error.error.data);
    this.app.loginSvc.LogError(error);

  }	    
}

onSaveAndNewReferralChangesConfirm(e){



}

onHideReferralDialog(event){

//  event.preventDefault();

  this.showReferralEditDialog = false;

  // this.newDSItem = false;
  // this.newItemWasInitiallySetBillable = false;
  // this.setUnbillableReason(null);
  // this.dsForm.reset(this.dsForm.value); //this.dsForm.reset(); // this was causing issues with dsMonth & dsYear after saving needed parameter
  // this.loadingDirectServiceDialog = false;
  // this.displayDirectServiceDialog = false;
  // this.contractDate = this.currentDate;
  // this.deliveredDateSubscription.unsubscribe();
  // this.ref.close();
}


onSaveReferraChangesConfirm(event: Event) {
  this.confirmationService.confirm({
    target: event.target,
    message: "Are you sure that you want to proceed?",
    icon: "pi pi-info-circle",
    accept: () => {
       this.saveReferral();

    },
    reject: () => {
      this.messageService.add({
        severity: "error",
        summary: "Aborted",
        detail: "Changes Canceled",
        life: 2000
      });
    }
  });
}

futureDateValidator(): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    const today = new Date().getTime();
    if(!(control && control.value)) {  // if there's no control or no value, that's ok
      return null;
    }
    var value = new Date(control.value);
    // remove hours minutes seconds and milliseconds
    value.setHours(0,0,0,0);
    // return null if there's no errors
    return value.getTime() > today ? {invalidDate: '*Invalid future date.' }  : null;
  }
}

public funderValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const funderControl = this.getReferralFunderControl();
    //const outcomeControl = this.getAssessmentOutcomeControl();

    if (!funderControl) return null;

    if ((this.getReferralFunderValue() ?? 0) <= 0)
    {
      return { invalidFunder: '*Funder is required.' };
    }

    return null;
  }
}

public serviceIdValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const serviceTypeControl = this.getReferralServiceTypeControl();

    if (!serviceTypeControl ) return null;

    if  ((this.getReferralServiceTypeValue() ?? 0) <= 0)
    {
      return { invalidServiceId: '*Service Category is required.' };
    }

    return null;
  }
}

public subIdValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const subTypeControl = this.getReferralSubTypeControl();
    
    if (!subTypeControl) return null;

    if ((this.getReferralSubTypeValue() ?? 0) <= 0)
    {
      return { invalidSubId: '*Sub Type is required.' };
    }

    return null;
  }
}

public providerIdValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const providerControl = this.getReferralProviderControl();

    if (!providerControl ) return null;

    //console.log(this.getReferralProviderValue());
    if  ((this.getReferralProviderValue() ?? -1) == -1)
    {
      return { invalidProviderId: '*Provider is required.' };
    }

    return null;
  }
}

public outsideProviderIdValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const providerControl = this.getReferralProviderControl();
    const outsideProviderControl = this.getReferralOutsideProviderIdControl();


    if (!providerControl || !outsideProviderControl) return null;

    if ( ((this.getReferralOutsideProviderIdValue() ?? 0) <= 0) && ((this.getReferralProviderValue() ?? 0) == 0))
    {
      return { invalidOutsideProviderId: '*Outside Provider is required.' };
    }

    return null;
  }
}

public employeeIdValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const employeeControl = this.getReferralEmployeeControl();
    //const outcomeControl = this.getAssessmentOutcomeControl();

    if (!employeeControl) return null;

    if (((this.getReferralEmployeeValue() ?? 0) <= 0))
    {
      return { invalidEmployeeId: '*Employee is required.' };
    }

    return null;
  }
}

public confirmLocationValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const confirmLocationControl = this.getReferralConfirmLocationControl();
    //const outcomeControl = this.getAssessmentOutcomeControl();

    if (!confirmLocationControl) return null;

    if (((this.getReferralConfirmLocationValue() ?? '') == ''))
    {
      return { invalidconfirmLocation: '*Confirmation Location is required.' };
    }

    return null;
  }
}

public outsideProviderNameValidator(): ValidatorFn {
  return (formGroup: FormGroup) => {
    const outsideProviderNameControl = this.getReferralOutsideProviderNameControl();
    //const outcomeControl = this.getAssessmentOutcomeControl();

    if (!outsideProviderNameControl || !this.displayOutsideProviderDialog) return null;

    var outsideProviderName:string = this.getReferralOutsideProviderNameValue() ?? '';
    if ( (outsideProviderName.length <= 0))
    {
      return { invalidOutsideProviderName: '*Outside Provider Name is required.' };
    }

    return null;
  }
}

// public assessmentOutcomeValidator(): ValidatorFn {
//   return (formGroup: FormGroup) => {
//     const assessmentOutcomeControl = this.getAssessmentOutcomeControl();
//     const outcomeControl = this.getAssessmentOutcomeControl();
//     const currentYesNoControl = this.getAssessmentCurrentYesNoControl();

//     if (!assessmentOutcomeControl || !outcomeControl || !currentYesNoControl) return null;

//     var assessmentOutcome = this.getAssessmentOutcomeValue();

//     //if ((!assessmentOutcome || assessmentOutcome < AssessmentOutcome.ProvidedOnSite) && this.getAssessmentCurrentYesNoValue() == YesNo.Yes)
//     if ((!assessmentOutcome || assessmentOutcome < AssessmentOutcome.ProvidedOnSite) && this.assessmentOutComeEnabled)
//     {
//       return { invalidAssessmentOutcome: '*Assessment Outcome is required.' };
//     }

//     return null;
//   }
// }  














}
