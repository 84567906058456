import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl,  AsyncValidatorFn, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from "primeng/api";
import { lastValueFrom, Observable, of, Subscription } from 'rxjs';
import { ServiceCategory } from '../../models/common-data.model';
import { CommonDataService } from '../../services/common-data.service';
import { ProviderService } from '../../services/provider.service';
import { UserService } from '../../services/user.service';
import { ClientDirectService, ClientDirectServiceModel, ClientDirectServiceRequestModel, EISSubCategoryDatesModel } from './../services/client-direct-service-models';
import { ClientDirectServicesService } from './../services/client-direct-services.service';
import { LoginService } from 'src/app/login/services/login.service';
import { compareAsc, format, formatISO, getMonth, getYear, isDate, parseISO, toDate, getHours, getMinutes, addHours, addMinutes } from 'date-fns';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { ClientStatus, DirectServiceFilterSelectionChoices, EISSubTypes, EligibilityAlertErrors, HIVIndeterminateStatusResult, LiveIn, PaySource, 
         UnbillableReason, YesNo, UnbillableReasonPriority } from "src/app/models/enums";
import { AuthService } from '../../auth/auth.service';
import { ClientRequiredFieldErrorsComponent } from '../../client-required-field-errors/client-required-field-errors.component';
import { HelperClassService } from '../../services/helper-class.service';
import { AssessmentComponent } from './../assessment/assessment.component';
import { RiskReductionComponent } from './../risk-reduction/risk-reduction.component';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { result } from 'lodash';
import { DirectServiceValidatorsService } from 'src/app/validators/direct-service-validators.service';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { InputNumberModule } from 'primeng/inputnumber';

@Component({
  selector: 'app-direct-service',
  templateUrl: './direct-service.component.html',
  styleUrls: ['./direct-service.component.css'],
  providers: [DialogService, MessageService, ConfirmationService]
})

export class DirectServiceComponent implements OnInit {
  @ViewChild(RiskReductionComponent) riskReductionSub: RiskReductionComponent;
  @ViewChild(AssessmentComponent) assessmentSub: AssessmentComponent;
  @ViewChild(ClientRequiredFieldErrorsComponent) requiredFieldErrorsSub: ClientRequiredFieldErrorsComponent;
  
  ackInvalidUnitCost: boolean = false;
  ackInvalidBillableTimePeriod: boolean = false;
  ackInvalidHopwaWorksheetExpired: boolean = false;
  ackInvalidHopwaWorksheetQualified: boolean = false;
  ackInvalidMedicalCaseManagementData: boolean = false;
  ackInvalidDirectServiceBillableByFunderBillingRestrictionEncounter: boolean = false;
  ackInvalidEmergencyFinancialOtherAllowed: boolean = false;
  ackInvalidAgeForIndeterminateStatus: boolean = false;
  ackInvalidMoreThan4UnitsIn12Months: boolean = false;
  ackInvalidDirectServiceFunded: boolean = false;
  ackInvalidPaySourceBillable: boolean = false;
  ackInvalidFirstBillableEISSubTypeDate: boolean = false;
  ackInvalidFirstEISSubTypeDate: boolean = false;
  ackInvalidMedicaidExpansion: boolean = false;
  ackInvalidIntervention: boolean = false;
  ackInvalidAssessment: boolean = false;

  params: any = {};
  YesNoEnum = YesNo;
  clientGUID: string;
  months: any[] = this.helperService.getMonths();
  years: string[] = this.helperService.getYears();
  standardFilterSelections: any[] = this.dsDataService.getStandardFilterSelections();
  employees: any[];
  employeeId = 0;
  contractFunders: any[];
  //contractId = 0;
  serviceCategories: ServiceCategory[];
  disableUnits: boolean = false;
  serviceId = 0;
  subTypes: any[] = [];
  paySources: any[];
  paySourceId = 0;
  paySourceReasons: any[] = [];
  barriers: any[];
  barrierId = 0;
  subTypeInclusions: any[] = [];
  subTypeInclusionsSelectedItems: any[] = []; // this has to be multi select use a single select for now
  dsForm: UntypedFormGroup;
  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  currentTime: Date = new Date();
  originDate: Date = new Date(0, 0); //Mon Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time) // new Date(1900, 0, 1)
  //lastContractDate: Date = this.originDate;
  contractDate: Date = this.currentDate;
  hasChanges: boolean = false; //receive an event from children if any changes have been made
  clientDirectServices$: Observable<ClientDirectServiceModel[]>;
  //formDetectChanges$: Observable<any>;
  subTypeInclusionDropDownSettings: any = {};
  //useProviderAssessment: boolean = false;
  assessmentTypeIdInitialValue: number = 0;
  //submitted: boolean;
  newDSItem: boolean = true;
  saveAndNew: boolean = false;
  newItemWasInitiallySetBillable = false;
  useCustomDSListFiltering = false;
  directService: Partial<ClientDirectServiceModel> = null; //any = {};
  directServiceDefaults: Partial<ClientDirectServiceModel> = { guid: '00000000-0000-0000-0000-000000000000',
                                                               clientGuid: this.clientService?.clientData?.guid,
                                                               billable: true, deliveredUnits: 1, plannedUnits: 1, unit: 1,
                                                               deliveredDate: formatISO(this.currentDate),
                                                               //deliveredTime: getHours(new Date()).toString() + ":" + getMinutes(new Date()).toString(), //"00:00"
                                                               deliveredTime: formatISO(this.currentTime),
                                                               notes: "", cohortIntervention: 0, subTypeInclusionIds: ""};
  loadingDirectServiceDialog: boolean = true;
  eisSubCategoriesBillableData: EISSubCategoryDatesModel;
  deliveredDateSubscription: Subscription;
  
  // dialog visible variables
  displayDirectServiceDialog: boolean = false;
  alwaysVisible: boolean = true;

  getClient = () => { return (!this.clientService) ? this.dsDataService.getClientService() : this.clientService; };
  getClientData = () => { return (!this.clientService) ? this.dsDataService?.clientData : this.clientService?.clientData; };
  //getClientCohortStudy = () => { return this.getClientData()?.cohortStudy ?? false; }; // this will be replaced once I get a reference to a client object from Den
  getClientCohortStudy = () => { return this.getClientData()?.cohortStudyLatest ?? false; };
  getCurrentDate = () => { var dt = new Date(); dt.setHours(0,0,0,0); return dt; };
  // getMonthValue = () => this.dsForm.get('dsMonth').value;
  // setMonthValue = (value) => this.dsForm.get('dsMonth').setValue(value);
  // getYearValue = () => this.dsForm.get('dsYear').value;
  // setYearValue = (value) => this.dsForm.get('dsYear').setValue(value);
  // getEmployeeValue = () => this.dsForm.get('employeeId').value;
  // getFunderValue = () => this.dsForm.get('contractGuid').value;
  getMonthValue = () => (getMonth(new Date(this.directService?.deliveredDate)) + 1).toString();
  getYearValue = () => getYear(new Date(this.directService?.deliveredDate)).toString();

  getGuidControl = () => this.dsForm.get('guid');
  getDeliveredDateControl = () => this.dsForm?.get('deliveredDate');
  // when you select a date in the pick it returns a string but when you populate the control via code its a date so need to handle either case
  getDeliveredDate = () => !this.getDeliveredDateControl()?.value ? null : 
                           this.getDeliveredDateControl()?.value instanceof Date ? this.getDeliveredDateControl()?.value : 
                           this.helperService.convertToDate(this.getDeliveredDateControl()?.value, "MM-DD-YYYY");
  // getDeliveredDateValue = () => toDate(this.dsForm?.get('deliveredDate')?.value ?? this.dsDataService.getDefaultDate());
  getDeliveredTimeControl = () => this.dsForm?.get('deliveredTime');
  getDeliveredTime = () => getHours(this.getDeliveredTimeControl()?.value).toString().padStart(2, '0') + ":" + getMinutes(this.getDeliveredTimeControl()?.value).toString().padStart(2, '0');

  getFunderControl = () => this.dsForm?.get('dsFunder');
  getContractValue = () => this.getFunderControl()?.value?.contractGuid;
  getFunderValue = () => this.getFunderControl()?.value?.funderId ?? 0;
  getServiceControl = () => this.dsForm?.get('serviceId');
  getServiceValue = () => parseInt(isNaN(this.getServiceControl()?.value) ? 0 : (this.getServiceControl()?.value ?? 0));
  getSubTypeControl = () => this.dsForm?.get('subId');
  getSubTypeValue = () => parseInt(isNaN(this.getSubTypeControl()?.value) ? 0 : (this.getSubTypeControl()?.value ?? 0));
  getPaySourceControl = () => this.dsForm?.get('paySourceId');
  getPaySourceValue = () => parseInt(isNaN(this.getPaySourceControl()?.value) ? 0 : (this.getPaySourceControl()?.value ?? 0));
  getPaySourceReasonControl = () => this.dsForm?.get('paySourceReasonId');
  getPaySourceReasonValue = () => this.getPaySourceReasonControl()?.value ?? 0;
  getBarrierControl = () => this.dsForm?.get('barrier');
  getBarrierValue = () => parseInt(isNaN(this.getBarrierControl()?.value ?? 0) ? 0 : (this.getBarrierControl()?.value ?? 0));
  getPlannedUnitsControl = () => this.dsForm?.get('plannedUnits');
  getPlannedUnitsValue = () => parseInt(isNaN(this.getPlannedUnitsControl()?.value) ? 0 : (this.getPlannedUnitsControl()?.value ?? 0));
  getDeliveredUnitsControl = () => this.dsForm?.get('deliveredUnits');
  getDeliveredUnitsValue = () => parseInt(isNaN(this.getDeliveredUnitsControl()?.value) ? 0 : (this.getDeliveredUnitsControl()?.value ?? 0));
  getBillableControl = () => this.dsForm?.get('billable');
  getBillableValue = () => this.getBillableControl()?.value;
  setBillableValue = (value) => this.getBillableControl().setValue(value);
  getCohortInterventionControl = () => this.dsForm?.get('cohortIntervention');
  getCohortIntervention = () => this.getCohortInterventionControl()?.value ?? 0;
  getUnbillableReasonControl = () => this.dsForm?.get('unbillableReason');
  getUnbillableReason = () => this.getUnbillableReasonControl()?.value ?? 0;
  setUnbillableReason = (value) => this.getUnbillableReasonControl().setValue(value);
  getEmployeeControl = () => this.dsForm?.get('employeeId');
  getNotesControl = () => this.dsForm?.get('notes');
  getUserControl = () => this.dsForm?.get('userId');

  // this is either standard or custom from radio button choice
  getFilterOption = () => this.dsForm?.get('filterOption')?.value ?? 1;
  setFilterOption = (value) => this.dsForm?.get('filterOption').setValue(value ?? 1);
  // this is one of the options in the drop down i.e. 3 month, 6 month, 12 month, etc
  getFilterSelection = () => this.dsForm?.get('filterSelection')?.value ?? DirectServiceFilterSelectionChoices.CurrentMonth;
  setFilterSelection = (value) => this.dsForm?.get('filterSelection').setValue(value ?? DirectServiceFilterSelectionChoices.CurrentMonth);
  // getSubTypeInclusions = () => this.dsForm.get('dsSubTypeInclusion').value;
  getSubTypeInclusionControl = () => this.dsForm?.get('dsSubTypeInclusion');
  qualifyForAssessmentById = (subTypeAssessmentId) => { return (this.providerService?.getProviderOptions().useAssessment && subTypeAssessmentId > 0); }
  qualifyForAssessment = () => { return (this.providerService?.getProviderOptions().useAssessment && this.commonDataService?.getSubType(this.getSubTypeValue())?.assessmentTypeId > 0); }
  qualifyForRiskReduction = () => { return (this.commonDataService.containsAnyRiskReductionCounseling(this.getServiceValue(), this.getSubTypeValue())); }
  getUnbillableReasonDescription = () => { let list: Array<number> = [ UnbillableReason.Billable, UnbillableReason.UserMarkUnbillable, UnbillableReason.UserEnteredNotBillable ];
                                           return (!!this.getBillableValue() && this.getUnbillableReason() > 0 && !list.includes(this.getUnbillableReason())) ? 
                                           ('*Service is not billable due to ' + this.commonDataService.getDescription("UnbillableReason", this.getUnbillableReason())) : 
                                           null; }
  hasInitialAssessmentChanged = () => { return (this.assessmentTypeIdInitialValue != this.commonDataService.getSubType(this.getSubTypeValue())?.assessmentTypeId) }
  hasMissingRequiredFieldErrors = () => { return !!this.clientService.clientRequiredFieldErrors; }
  usingCustomDSListFiltering = () => { return (this.getFilterOption() == 2);}

  constructor(private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, 
              private dsDataService: ClientDirectServicesService, 
              private commonDataService: CommonDataService,
              private validatorService: DirectServiceValidatorsService,
              private userService: UserService,
              private loginService: LoginService,
              private authService: AuthService,
              private providerService: ProviderService,
              public  clientService: ClientFormService,
              private datePipe: DatePipe,
              private spinnerService: SpinnerService,
              private helperService: HelperClassService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              private diagConfig: DynamicDialogConfig,
              private app: AppContextService,
              private ref: DynamicDialogRef )
              // private primengConfig: PrimeNGConfig
              // private changeDetectorRef: ChangeDetectorRef,
              // private elementRef: ElementRef, 
              // private renderer: Renderer2) 
  {
      var currentMonth = (getMonth(new Date()) + 1).toString(); //this.months[0].code
      this.dsForm = fb.group({
        // dsMonth: [currentMonth, [Validators.required]],
        // dsYear: [this.years[0], [Validators.required]],
        filterOption: [1],
        filterSelection: [DirectServiceFilterSelectionChoices.CurrentMonth],
        guid: ['00000000-0000-0000-0000-000000000000'],
        clientGuid: ['00000000-0000-0000-0000-000000000000'],
        dsFunder: [, [validatorService.requiredFieldValidator()]],   
        funder: [0],
        //contractGuid: ['00000000-0000-0000-0000-000000000000'], replaced by dsFunder since need to capture both contract guid and funder
        employeeId: [this.employeeId, [validatorService.requiredFieldValidator()]],      
        serviceId: [this.serviceId, [validatorService.requiredFieldValidator()]],
        subId: ['0', [validatorService.requiredFieldValidator()]],
        dsSubTypeInclusion: [],
        subTypeInclusionIds: [''],
        paySourceId: ['0', [validatorService.requiredFieldValidator()]],
        paySourceReasonId: [0],
        billable: [true],
        plannedUnits: [1],
        deliveredUnits: [1, [Validators.required]], //{ validators: [Validators.required], updateOn: 'blur' }
        dsUnit: [{value: '', disabled: true}],
        deliveredDate: [this.currentDate, [Validators.required, validatorService.futureDateValidator()]],
        deliveredTime: [this.currentTime, [Validators.required, validatorService.timeValidator()]], //new Date()
        barrier: [0],
        notes: [''],
        cohortIntervention: [0],
        unbillableReason: [],
        userId: [0]
      },
      {
        validators: [this.barrierValidator(), this.unitCostValidator(), this.interventionValidator(), this.medicaidExpansionValidator(),
                     this.firstEISSubTypeDateValidator(), this.firstBillableEISSubTypeDateValidator(), this.paySourceBillableValidator(),
                     this.emergencyFinancialOtherAllowedValidator(), this.directServiceFundedValidator(), this.assessmentValidator()],
        asyncValidators: [this.billableTimePeriodValidator(), this.hopwaWorksheetExpiredValidator(), this.hopwaWorksheetQualifiedValidator(),
                          this.medicalCaseManagementDataValidator(), this.directServiceBillableByFunderBillingRestrictionEncounterValidator(),
                          this.validAgeForIndeterminateStatusValidator(), this.moreThan4UnitsIn12MonthsValidator()]
        //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
      });
      
      this.subTypeInclusionDropDownSettings = { 
                                singleSelection: false,
                                idField: 'id',
                                textField: 'description',
                                selectAllText: 'Select All',
                                unSelectAllText: 'UnSelect All',
                                allowSearchFilter: true};

      
      this.params = this.diagConfig.data;
      this.clientGUID = this.params.clientGuid;
      if (this.params.newClient ?? false) {
        this.onOpenNewDirectServiceDialog();
        this.initializeData();
      }
      else {
        this.getDirectService(this.params.guid);
      }
  }

  ngOnInit(): void {
    //this.primengConfig.ripple = true;
  }


  getDirectService(guid:string){
    var request: ClientDirectServiceRequestModel =
    {  
        providerID: this.userService.CurrentProviderID,
        clientGuid: null, 
        dsGuid: guid,
        month: parseInt(this.getMonthValue()), 
        year: parseInt(this.getYearValue()),
        filterSelectionChoice: DirectServiceFilterSelectionChoices.MonthAndYear
    };
    this.dsDataService.getDirectService(request).subscribe({
        next:(result) => {
          this.onEditDirectService(result);
          this.initializeData();
        },
        error:(err) =>{

        },
        complete:()=> {
          
        }

    });
  }


  async standAloneLogin() {
    var user = "msavaryn@hotmail.com";
    var password = "Dallas99";
    try {
      await this.authService.SignIn(user, password);
      if(this.authService.isUserAuthenticated) {
          //this.loginService.validate("marks", "Dallas99").subscribe({
          this.loginService.validate(user, password).subscribe({
                            next: (resp) => { console.log(resp) },
                            error: (error) => { console.log(error) },
                            complete: () => { 
                                  // this happens when routing directly to ClientDirectServicesComponent for testing and not routing to AppMainComponent
                                  // Provider ID 1 testing guids "F2A2CCD6-C1A2-43B6-A42C-67138FAE77B5"; //"fd4c19a9-79de-40b7-976b-3738ef9571c5"; // for testing isDevMode()
                                  // Provider ID 94 testing guids "96489FB6-D0FA-4701-B034-1498607F25AA"
                                  if (!this.clientGUID) this.clientGUID = "96489FB6-D0FA-4701-B034-1498607F25AA";
                                  if (!this.clientService?.clientData) this.dsDataService.loadMockClient(this.clientGUID); else this.dsDataService.setClientService(this.clientService);

                                  this.initializeData();
                                  //if (!!this.providerService.data) console.log("provider data loaded");
                                  //console.log(this.userService.CurrentProviderID);
                                }});
      }
    }
    catch (error) {
      console.log(error);
      //this.app.loginSvc.LogError(error);
    }
  }

  initializeData() {
    // funder is same as contracts
    //let clinicId = this.providerService.getDefaultClinicID(userService.userID);
    this.directServiceDefaults.month = this.months[0]?.code;
    this.directServiceDefaults.year = this.years[0];
    this.directServiceDefaults.clientGuid = this.clientGUID;
    //this.contractDate = new Date(this.directServiceDefaults.month + '-01-' + this.directServiceDefaults.year)
    
    this.paySources = this.commonDataService.getCommonSetByName("PaySource", true);
    this.barriers = this.commonDataService.getCommonSetByName("Barrier", true);
    this.employees = this.providerService.getProviderEmployeeList(true);
    this.contractFunders = this.providerService.getProviderContractFunderItems(this.helperService.convertToDate(this.directService.deliveredDate, "YYYY-MM-DD"), true);

    //this.useProviderAssessment = this.providerService.getProviderOptions().useAssessment;

    if ((Array.isArray(this.contractFunders) && this.contractFunders?.length)) this.directServiceDefaults.contractFunder = this.contractFunders[0];
    if ((Array.isArray(this.serviceCategories) && this.serviceCategories?.length)) this.directServiceDefaults.serviceId = this.serviceCategories[0].id;
    if ((Array.isArray(this.paySources) && this.paySources?.length)) this.directServiceDefaults.paySourceId = this.paySources[0].id;
    if ((Array.isArray(this.employees) && this.employees?.length)) this.directServiceDefaults.employeeId = this.employees[0].id;
    if ((Array.isArray(this.barriers) && this.barriers?.length)) this.directServiceDefaults.barrier = this.barriers[0].id;

    // this.userService.GetUserConfigValue('CHAMP.Registry.Filters.DirectServiceStandardFilter').subscribe( (result) =>
    // { 
    //   if (result)
    //   { 
    //     this.setFilterSelection(result.keyValue);
    //   }
    //   this.fetchDirectServiceGridData();
    // });

    //this.fetchDirectServiceGridData(); //populate grid

    //Assessment/Screening fields
    // this.assessmentFunders = this.commonDataService.getCommonSetByName("Funder", true);
    // this.assessmentServiceTypes = this.commonDataService.getServiceCategories(true);
    // this.assessmentEmployees = this.providerService.getProviderEmployeeList(true);
  }

  // resetting fields is handled by the assignment to directServiceDefaults in open and edit methods
  setFieldValues() {
    //this.setMonthValue(this.directService.month);
    //this.setYearValue(this.directService.year);
    //var dsGuid = this.directService.guid;
    //if (this.newDSItem) dsGuid = uuidv4();
    this.getGuidControl().setValue(this.directService?.guid);
    this.dsForm.get('clientGuid').setValue(this.directService?.clientGuid);

    this.contractFunders = this.providerService.getProviderContractFunderItems(this.helperService.convertToDate(this.directService.deliveredDate, "YYYY-MM-DD"), true);
    this.directService.contractFunder = this.contractFunders.find(v => v.keys.funderId == this.directService?.funder && 
                                                                       v.keys.contractGuid == this.directService?.contractGuid)
    if (!this.directService.contractFunder?.keys) {
      if (this.contractFunders?.length == 2) // if only one contract then auto select it
        this.directService.contractFunder = this.contractFunders[1];
      else
        this.directService.contractFunder = this.contractFunders[0];
    }

    this.getFunderControl().setValue(this.directService.contractFunder.keys) ;
    this.onChangeFunder(this.directService.contractFunder.keys);
    this.getEmployeeControl().setValue(this.directService.employeeId ?? 0);
    this.getServiceControl().setValue(this.directService.serviceId ?? 0);
    this.onChangeServiceType(this.directService.serviceId);
    this.getSubTypeControl().setValue(this.directService.subId ?? 0);
    this.onChangeSubType(this.directService.subId);

    this.assessmentTypeIdInitialValue = this.commonDataService.getSubType(this.getSubTypeValue())?.assessmentTypeId;
    //loop through and set sub type inclusions
    this.subTypeInclusionsSelectedItems.length = 0;
    if (this.directService.subTypeInclusionIds?.length > 0) {
      this.directService.subTypeInclusionIds?.split(", ")
          .forEach( (subId) => {
              var subTypeInclusionItem = this.subTypeInclusions.find(x => x.id == subId);
              if (subTypeInclusionItem != null) this.subTypeInclusionsSelectedItems.push(subTypeInclusionItem);
          });
      //don't need this because of the 2 way binding in the html
      //this.dsForm.get('dsSubTypeInclusion') = this.subTypeInclusionsSelectedItems;
    }

    //this.dsForm.get('contractGuid').setValue(this.directService.contractGuid);
    this.getPaySourceControl().setValue(this.directService.paySourceId ?? 0);
    this.onChangePaySource(this.directService.paySourceId); // load the list of reasons
    if (!!this.directService.paySourceReasonId) this.getPaySourceReasonControl().setValue(this.directService.paySourceReasonId);
    this.getPlannedUnitsControl().setValue(this.directService.plannedUnits ?? 0);
    this.getDeliveredUnitsControl().setValue(this.directService.deliveredUnits ?? 0);

    this.getDeliveredDateControl().setValue(this.helperService.convertToDate(this.directService.deliveredDate, "YYYY-MM-DD"));
    this.getDeliveredTimeControl().setValue(this.helperService.convertToDate(this.directService.deliveredTime, "YYYY-MM-DD HH:mm"));
    // var delTime = this.helperService.convertToDate(this.directService.deliveredDate, "YYYY-MM-DD HH:mm");
    // if (this.newDSItem) {
    //   delTime = addMinutes(addHours(delTime, 5), 15);
    //   this.getDeliveredTimeControl().setValue(delTime);
    // }
    // else {
    //   this.getDeliveredTimeControl().setValue(delTime);
    // }

    this.getNotesControl().setValue(this.directService.notes);
    this.getCohortInterventionControl().setValue(this.directService.cohortIntervention);
    this.getBarrierControl().setValue(this.directService.barrier ?? 0);
    this.getUserControl().setValue(this.userService.userID ?? 0);
    //this.dsForm.get('billable').setValue(this.directService.billable);
    this.setBillableValue(this.directService.billable);
    this.onChangeBillable(this.directService.billable);
    
    // always refresh contract when delivered date changes
    this.deliveredDateSubscription = this.getDeliveredDateControl().valueChanges.subscribe(delDate => { this.onChangeDeliveredDate(delDate); });
  }

  // onChangeFilterOption(option: number) {
  //   //this.useCustomDSListFiltering = (option == 2);
  //   //this.setFilterOption(option);
  //   this.fetchDirectServiceGridData();
  // }

  resetAcknowledgements() {
    this.ackInvalidUnitCost = false;
    this.ackInvalidBillableTimePeriod = false;
    this.ackInvalidHopwaWorksheetExpired = false;
    this.ackInvalidHopwaWorksheetQualified = false;
    this.ackInvalidMedicalCaseManagementData = false;
    this.ackInvalidDirectServiceBillableByFunderBillingRestrictionEncounter = false;
    this.ackInvalidEmergencyFinancialOtherAllowed = false;
    this.ackInvalidAgeForIndeterminateStatus = false;
    this.ackInvalidMoreThan4UnitsIn12Months = false;
    this.ackInvalidDirectServiceFunded = false;
    this.ackInvalidPaySourceBillable = false;
    this.ackInvalidFirstBillableEISSubTypeDate = false;
    this.ackInvalidFirstEISSubTypeDate = false;
    this.ackInvalidMedicaidExpansion = false;
    this.ackInvalidIntervention = false;
    this.ackInvalidAssessment = false;
  }

  onShowDirectServiceDialog() {
    this.resetAcknowledgements();
  }

  onHideDirectServiceDialog(event) {
    event.preventDefault();
    this.newDSItem = false;
    this.newItemWasInitiallySetBillable = false;
    this.setUnbillableReason(null);
    this.dsForm.reset(this.dsForm.value); //this.dsForm.reset(); // this was causing issues with dsMonth & dsYear after saving needed parameter
    this.loadingDirectServiceDialog = false;
    this.displayDirectServiceDialog = false;
    this.contractDate = this.currentDate;
    this.deliveredDateSubscription.unsubscribe();
    this.ref.close();
  } 

  onOpenNewDirectServiceDialog() {
    this.loadingDirectServiceDialog = true;
    this.newDSItem = true;
    this.getClient().resetRequiredFields();
    this.directService = {...this.directServiceDefaults};
    this.setFieldValues();
    this.displayDirectServiceDialog = this.alwaysVisible = true;
    this.loadingDirectServiceDialog = false;
  }

  onEditDirectService(directService: ClientDirectServiceModel) {
    this.loadingDirectServiceDialog = true;
    this.newDSItem = false;
    this.getClient().resetRequiredFields();
    this.directService = {...this.directServiceDefaults};
    this.directService = {...directService};
    // when a direct service is saved, it combines the delivered date and time
    this.directService.deliveredTime = this.directService.deliveredDate;
    // convert the string date format to a real date
    //this.directService.deliveredDate = parseISO(this.directService.deliveredDate);
    this.setFieldValues();

    if (this.clientService.readonly) {
      this.dsForm.disable();
    } 
    this.displayDirectServiceDialog = this.alwaysVisible = true;
    this.loadingDirectServiceDialog = false;
  }  

  onDeleteSelectedDirectServices() {
    // this is if we have a select all checkbox column in the future
  }

  onDeleteDirectService(event: Event, directService: ClientDirectServiceModel) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete the service ' + directService.serviceDescription + '?',
      header: 'Confirm',
      icon: 'pi pi-info-circle',
      accept: () => {
      },
      reject: () => {
          this.messageService.add({severity: "error", summary: "Aborted", detail: "Delete canceled", life: 1000});
          }
      });
  }

  onSaveAndNewDirectServiceChangesConfirm(event: Event) {
    this.saveAndNew = true;
    this.onSaveDirectServiceChangesConfirm(event);
  }

  onSaveDirectServiceChangesConfirm(event: Event) {
    try {    
      this.confirmationService.confirm({
        target: event.target,
        message: "Are you sure that you want to proceed?",
        icon: "pi pi-info-circle",
        accept: () => {
          this.spinnerService.On();
          if (this.qualifyForAssessment()) {
            if (this.newDSItem) {
              // for new direct services we need to save it to the backend first to get an entry in the table which is needed for
              // the foreign key FK_client_assessments_client_direct_services, otherwise you can't save an assessment
              this.newItemWasInitiallySetBillable = this.getBillableValue();
              if (this.newItemWasInitiallySetBillable) {
                this.setBillableValue(false);
                this.setUnbillableReason(UnbillableReason.IncompleteOrInvalidAssessment);
              }
              // save new item as non billable with unbillable reason invalid assessment and update later to billable if a valid assessment was created
              this.onSaveDirectServiceChanges().then(value => {
                var dsModel = this.getDirectServiceModel();
                this.spinnerService.Off();
                this.assessmentSub.onShowDialog(dsModel, true, false);
              });
            }
            else {
              var dsModel = this.getDirectServiceModel();
              this.spinnerService.Off();
              this.assessmentSub.onShowDialog(dsModel, true, false); 
            }
          }
          // need to see if qualifies for risk reduction when it doesn't qualify for an assessment
          else if (this.qualifyForRiskReduction()) {
            this.spinnerService.Off();
            this.riskReductionSub.onShowDialog(this.directService, true, true);
          }
          else {
            this.spinnerService.On();
            this.onSaveDirectServiceChangesWithMessaging();
            this.spinnerService.Off();
          }
        },
        reject: () => {
          this.spinnerService.Off();
          this.messageService.add({
            severity: "error",
            summary: "Aborted",
            detail: "Changes Canceled",
            life: 2000
          });
        }
      });
    }
    finally {
    }    
  }

  onAssessmentDialogCompletedNotification(value) {
    var assessmentNotificationMessage = JSON.parse(value);
    if (assessmentNotificationMessage?.dialogCompleted) {
      if (!assessmentNotificationMessage?.validAssessment) {
        this.setBillableValue(false);
        this.setUnbillableReason(UnbillableReason.IncompleteOrInvalidAssessment);
        this.messageService.add({
          severity: "error",
          summary: "Warning",
          detail: "The direct service will be saved as non billable because no valid assessment(s) exist.",
          life: 2000
        });
      }
      else {
        var assessmentWithReferralModel = this.assessmentSub.getAssessmentWithReferralModel();
        this.directService.clientAssessment = assessmentWithReferralModel.clientAssessment;
        this.directService.clientReferral = assessmentWithReferralModel.clientReferral;
        // reset assessmentTypeId to the new initial value after the save
        this.assessmentTypeIdInitialValue = this.commonDataService.getSubType(this.getSubTypeValue())?.assessmentTypeId;
        // if a valid assessment was created and the service was initially marked as billable then reset it back to billable and update the service
        if (this.newItemWasInitiallySetBillable && !this.getBillableValue() && this.getUnbillableReason() == UnbillableReason.IncompleteOrInvalidAssessment) {
          this.setBillableValue(true);
          this.setUnbillableReason(UnbillableReason.Billable);
        }
      }
      // need to see if qualifies for risk reduction when it did qualify for an assessment
      if (this.qualifyForRiskReduction()) {
        this.riskReductionSub.onShowDialog(this.directService, true, true);
      }
      else {
        this.spinnerService.On();
        this.onSaveDirectServiceChangesWithMessaging();
      }  
    }
  }

  onRiskReductionDialogCompletedNotification(value) {
    var riskReductionNotificationMessage = JSON.parse(value);
    if (riskReductionNotificationMessage?.dialogCompleted && riskReductionNotificationMessage?.validRiskReduction) {
      var riskReductionModel = this.riskReductionSub.getRiskReductionModel();
      this.directService.clientMedicalRiskReduction = riskReductionModel;
      this.spinnerService.On();
      this.onSaveDirectServiceChangesWithMessaging();
      //this.riskReductionSub.mainForm.getRawValue()
      //Object.assign(this.directService.clientMedicalRiskReduction, riskReductionModel);
    }
  }

  onSaveDirectServiceChangesWithMessaging() {
    this.onSaveDirectServiceChanges().then(value => {
      var serverity = "info";
      var detail = "Changes Saved";
      var life = 2000;
      if (this.hasMissingRequiredFieldErrors()) {
        serverity = "warn";
        detail = "Changes Saved, but the service is not billable due to missing required fields for the service category specified. Once the required data has been provided, you can return to this service and check it billable.";
        life = 0;
      }
      this.messageService.add({ severity: serverity, summary: "Confirmed", detail: detail, life: life });
      // if user selected Save & New option
      if (this.saveAndNew) {
        // get existing contract, employee and service
        var currentFunder = this.dsForm.get('dsFunder').value;
        var currentEmployee = this.dsForm.get('employeeId').value;
        var currentService = this.dsForm.get('serviceId').value;
        var currentPaySource = this.dsForm.get('paySourceId').value;
        var currentPaySourceReason = this.dsForm.get('paySourceReasonId').value;

        // force the start of a new direct service
        this.newDSItem = true;
        this.directService = {...this.directServiceDefaults};
        this.setFieldValues();

        // reset to the prior contract, employee and service
        this.dsForm.get('dsFunder').setValue(currentFunder);
        this.onChangeFunder(currentFunder); //.keys
        this.dsForm.get('employeeId').setValue(currentEmployee);
        this.dsForm.get('serviceId').setValue(currentService);
        this.onChangeServiceType(currentService);
        this.dsForm.get('paySourceId').setValue(currentPaySource);
        this.onChangePaySource(currentPaySource);
        this.dsForm.get('paySourceReasonId').setValue(currentPaySourceReason);
      }
      this.saveAndNew = false;
      this.spinnerService.Off();
    });
  }

  // onSaveDirectServiceChangesWithNoMessaging() {
  //   this.onSaveDirectServiceChanges().then(value => {
  //     console.log("onSaveDirectServiceChangesWithNoMessaging completed");
  //   });
  // }

  getDirectServiceModel(): Partial<ClientDirectServiceModel> {
    var dsModel:Partial<ClientDirectServiceModel> = new ClientDirectService(this.dsForm.value);

    dsModel.funder = this.getFunderControl().value?.funderId;
    dsModel.contractGuid = this.getFunderControl().value?.contractGuid;
    this.getSubTypeInclusionControl().value?.forEach(element => {
      dsModel.subTypeInclusionIds = dsModel.subTypeInclusionIds + element.id + ',';
    });
    // removed time component, when time was being included and formatISO was not used to convert the date then every save added 4 hours to the datetime (not UTC compliant)
    // also need a string formatted date for API call to work
    //dsModel.deliveredDate = formatISO(dsModel.deliveredDate, { representation: 'date' });
    dsModel.deliveredDate = this.helperService.castToDateString(this.getDeliveredDate(), "yyyy-MM-dd");
    dsModel.deliveredTime = this.getDeliveredTime();
    dsModel.eis = false;
    dsModel.deleted = false;
    if (!dsModel.subTypeAssessmentId) dsModel.subTypeAssessmentId = this.commonDataService.getSubType(dsModel.subId)?.assessmentTypeId;

    if (dsModel.billable) {
      dsModel.nonbillableUnits = 0;
      dsModel.nonbillable = false;      
    }
    else {
      dsModel.nonbillableUnits = dsModel.deliveredUnits;
      dsModel.nonbillable = true;       
    }

    // for an existing service if the user changed the sub type to one that has a different assessmentTypeId then force a new assessment to be created
    if (!this.newDSItem && this.hasInitialAssessmentChanged()) {
      dsModel.clientAssessment = null;
      dsModel.clientReferral = null;
    }
    else {
      dsModel.clientAssessment = this.directService.clientAssessment;
      dsModel.clientReferral = this.directService.clientReferral;
    }

    // reset direct service object to new selected form values
    Object.assign(this.directService, dsModel);

    return dsModel;
  }

  async onSaveDirectServiceChanges() {
    //if (this.dsForm.valid) { // this should be handled by the validators disabling the save button
      var dsModel = this.getDirectServiceModel();
      if (this.commonDataService.isSubTypeEISEligible(dsModel.subId))
      {
        var eisStartDate = this.dsDataService.getEISStartDate();
        var eisEndDateObject = await this.dsDataService.getEISEndDate();
        var eisEndDate = eisEndDateObject?.eisDate;
        dsModel.eis = this.dsDataService.isEISTrueByDeliveredDateTime(this.getDeliveredDate(), eisStartDate, eisEndDate); // dsModel.deliveredDate can use because string
        if (dsModel.eis)
        {
          this.clientService.clientData.linkage = (!eisEndDateObject) ? false : eisEndDateObject?.recievedMedical;
          dsModel.linkage = this.clientService.clientData.linkage;
        }
      }
      // removed time component, when time was being included and formatISO was not used to convert the date then every save added 4 hours to the datetime (not UTC compliant)
      // also need a string formatted date for API call to work
      // dsModel.deliveredDate = formatISO(dsModel.deliveredDate, { representation: 'date' });
      dsModel.unbillableReason = await this.dsDataService.getFirstUnbillableReason(dsModel, this.paySourceReasons.length, dsModel.unbillableReason);
      this.setUnbillableReason(dsModel.unbillableReason);
      if (dsModel.billable && ((dsModel.unbillableReason ?? UnbillableReason.Billable) != UnbillableReason.Billable)) {
        dsModel.billable = false;
        this.setBillableValue(false);
        dsModel.nonbillableUnits = dsModel.deliveredUnits;
        dsModel.nonbillable = true;
      }
      else if (dsModel.billable) {
        //this.dsDataService.linkContractLineItem(dsModel);       
        dsModel.nonbillableUnits = 0;
        dsModel.nonbillable = false;
      }
      else {
        // if user selected non billable
        dsModel.nonbillableUnits = dsModel.deliveredUnits;
        dsModel.nonbillable = true;
      }
      // TODO AddPaySourceToSet happens in the backend save service so need to figure out a way to refresh the client object health insurance/payment source to get the latest changes
      try {
        var response = await lastValueFrom(this.dsDataService.saveDirectService(dsModel));
        const keys = JSON.parse(response.data);
        if (this.newDSItem) {
          this.getGuidControl().setValue(keys.guid);
          this.dsForm.get('clientGuid').setValue(keys.clientGuid);
          this.newDSItem = false;
          //this.newItemWasInitiallySetBillable = false; can't reset this here because of assessment callback and save
        }

        var delDate = parseISO(dsModel.deliveredDate);
        var delMonth = (getMonth(delDate) + 1).toString();
        var delYear = (getYear(delDate)).toString();
        //this.setMonthValue(delMonth);
        //this.setYearValue(delYear);

        // reset assessmentTypeId to the new initial value after the save
        this.assessmentTypeIdInitialValue = this.commonDataService.getSubType(this.getSubTypeValue())?.assessmentTypeId;
        this.dsForm.reset(this.dsForm.getRawValue()); //this.dsForm.value

        this.fetchDirectServiceGridData();        
      } 
      catch (error) {
        console.log(error);
        this.app.loginSvc.LogError(error);
        //console.log(error.error.message); console.log(error.error.data);
        //this.clientPost.error(error);
      }	      
    //}
    // else
    //  alert("The Direct Service has invalid data, please correct.");
  }  

  fetchDirectServiceGridData() {
    var request: ClientDirectServiceRequestModel =
    {  
        providerID: this.userService.CurrentProviderID,
        clientGuid: this.clientGUID, 
        dsGuid:null,
        month: parseInt(this.getMonthValue()), 
        year: parseInt(this.getYearValue()),
        filterSelectionChoice: DirectServiceFilterSelectionChoices.MonthAndYear
    };

    // if standard filter option was selected then set the correct option to pass to the API call
    if (!this.usingCustomDSListFiltering()) {
      request.filterSelectionChoice = this.getFilterSelection();
   
    }
    else {
      if (isNaN(request.month)) { alert("Cannot refresh direct service grid, Invalid month"); return; }
      if (isNaN(request.year)) { alert("Cannot refresh direct service grid, Invalid year"); return; }
    }

    // this may have to change after adding new client list date range filters
    //this.contractDate = new Date(request.month.toString() + '-01-' + request.year.toString())
    //this.contractFunders = this.providerService.getProviderContractFunderItems(this.contractDate, true);
    
    this.clientDirectServices$ = this.dsDataService.getDirectServices(request)
  }

  onChangeServiceType(serviceId: number) {
    var funderId = this.getFunderValue();
    this.subTypes = this.commonDataService.getSubTypesByService(serviceId, funderId, true);
    // always reset subId to the first in the list here, setFieldValues will take care of assigning the correct id when loading existing to edit
    this.dsForm.get('subId').setValue(this.subTypes[0]?.id);
    this.dsForm.get('dsUnit').setValue("");
  }

  async onChangeSubType(subTypeId: number) {
    this.subTypeInclusions = this.commonDataService.getIncludedSubTypesList(subTypeId);
    this.subTypeInclusionsSelectedItems.length = 0;
    var subType = this.subTypes.find(x => x.id == subTypeId);
    if (subType != null) {
      this.dsForm.get('dsUnit').setValue(this.commonDataService.getDescription("UnitType", subType.defaultUnit)[0]);
    }
    if (Object.values(EISSubTypes).includes(subTypeId)) {
      this.eisSubCategoriesBillableData = await this.dsDataService.getEISSubCategoriesBillableData(subTypeId);
    }
    // 304	Nurse - Phlebotomy/Lab Samples/Vital Signs, 306	Laboratory /Diagnostic Testing
    var labIds: Array<number> = [ 304, 306 ];
    this.disableUnits = (this.getServiceControl()?.value == 81 && !labIds.includes(subTypeId)); // disable 81 - Primary Medical except Lab sub types
    if (this.disableUnits) this.getDeliveredUnitsControl()?.setValue(1);
  }

  onChangeDeliveredDate(delDate: any) {
    var deliveredDate = this.getDeliveredDate();
    // only refresh if the delivered date was changed
    if (compareAsc(deliveredDate, this.contractDate) != 0) {
      console.log('onChangeDeliveredDate, refreshing contracts ' + deliveredDate);
      // refresh funder list only if at least one item is different
      var newContractFunders = this.providerService.getProviderContractFunderItems(deliveredDate, true);
      if (JSON.stringify(newContractFunders) != JSON.stringify(this.contractFunders)) {
        this.contractFunders = newContractFunders;
        this.dsForm.get('dsFunder').setValue(0);
      }
      this.contractDate = deliveredDate;
    }
    else
      console.log('onChangeDeliveredDate, no date changed ' + deliveredDate);
  }

  onChangeFunder(funderKeys) {
    var funderId = Number(funderKeys.funderId);
    this.serviceCategories = this.commonDataService.getServiceCategories(funderId, true);
  }

  onChangePaySource(paySourceId:number) {
    this.paySourceReasons = this.commonDataService.getPaySourceReasons(paySourceId, true);
    // removed undocumented option when ssn is not 9999
    if (this.getClient().clientData.ssn != '9999') {
      var index = this.paySourceReasons.findIndex(f => f.id == 5)
      if (index >= 0) this.paySourceReasons.splice(index, 1);
    }
    this.dsForm.get('paySourceReasonId').setValue(this.paySourceReasons[0]?.id);
    //if (this.newDSItem && !this.directService.paySourceReasonId) { this.directService.paySourceReasonId = this.paySourceReasons[0]?.id; }
    //this.dsForm.get('paySourceReasonId').setValue(this.directService.paySourceReasonId);
  }

  onSubTypeInclusionItemSelect(subTypeInclusionItem:any) {
    //this.subTypeInclusionsSelectedItems.push(subTypeInclusionItem);
  }

  onSubTypeInclusionSelectAll(subTypeInclusionItems:any) {
    //this.subTypeInclusionsSelectedItems.length = 0;
    //this.subTypeInclusionsSelectedItems = subTypeInclusionItems;
  }

  onSubTypeInclusionItemDeSelect(subTypeInclusionItem:any) {
    //const index = this.subTypeInclusionsSelectedItems.findIndex(x => x.id == subTypeInclusionItem.id);
    //this.subTypeInclusionsSelectedItems.splice(index, 1);
  }

  onSubTypeInclusionDeSelectAll(subTypeInclusionItems:any){
    //this.subTypeInclusionsSelectedItems.length = 0;
  }

  onChangeMonth(value) {
    this.directServiceDefaults.month = value; //this.getMonthValue();
    this.fetchDirectServiceGridData();
  }

  onChangeYear(value) {
    this.directServiceDefaults.year = value; //this.getYearValue();
    this.fetchDirectServiceGridData();
  }

  onChangeFilterSelection(value) {
    this.userService.SaveUserConfigValue('CHAMP.Registry.Filters.DirectServiceStandardFilter', value.toString());
    this.fetchDirectServiceGridData();
  }

  onChangeRadioIntervention() {
  }

  onChangeBillable(billable: any) {
    //console.log(billable);
    if (billable) { 
      this.setUnbillableReason(UnbillableReason.Billable);
      this.resetAcknowledgements();
      this.dsForm.get('billable').updateValueAndValidity();
      //this.dsForm.get('billable').markAsTouched();      
    }
    else {
      this.setUnbillableReasonByPriority(UnbillableReason.UserMarkUnbillable);
    }
    // else if (this.getUnbillableReason() == UnbillableReason.Billable) {
    //   // 3-14-2024 this will have to change to check if a high priority unbillable reason was set then don't override it as UserMarkUnbillable
    //   this.setUnbillableReason(UnbillableReason.UserMarkUnbillable);
    // }
  }

  onClickAssessmentCurrentOutcomeOption(value:any){
    console.log(value);
  }

  onClickShowRiskReduction(selectedDirectService: Partial<ClientDirectServiceModel>, allowEdit: boolean) {
    // met with Den on 10/27/21 and he wanted any hyperlinks to show risk reduction to require a risk reduction entry to exist for the given direct service in order to display
    // only when a forced save occurs from the save button do we check client risk reduction history
    if (!!selectedDirectService.clientMedicalRiskReduction) {
      this.riskReductionSub.onShowDialog(selectedDirectService, allowEdit, false);
    }
    else {
      this.messageService.add({severity: "error", summary: "Aborted", detail: "Currently, no Counselings and Included Screenings entries exist for selected direct service!", life: 2000});
    }
  }
  
  onClickShowAssessment(selectedDirectService: Partial<ClientDirectServiceModel>, allowEdit: boolean) {
    if (!!selectedDirectService.clientAssessment || !!selectedDirectService.clientReferral) {
      this.assessmentSub.onShowDialog(selectedDirectService, allowEdit);
      //this.loadAssessment(selectedDirectService);
      //this.displayAssessmentDialog = true;
    }
    else {
      this.messageService.add({severity: "error", summary: "Aborted", detail: "Currently, no Assessment entries exist for the selected direct service!", life: 2000});
    }
  }

  onChange(change: boolean) {
    this.hasChanges = change;
  }  

  // onSave(){
  //   // force child components to save any changes (if changes exist)
  //   this.emitSaveEventToChildren();
  //   this.hasChanges = false;
  // }

  // // this is an event for the children to subscribe to in order for each to save its own changes
  // eventsSaveSubject: Subject<void> = new Subject<void>();

  // emitSaveEventToChildren() {
  //   this.eventsSaveSubject.next();
  // }

  onAckInvalidInterventionClick() {
    this.ackInvalidIntervention = !this.ackInvalidIntervention;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.COHORTInterventionQuestionRequired);
    //this.dsForm.get('cohortIntervention').updateValueAndValidity();
  }      
  
  onAckInvalidMedicaidExpansionClick() {
    this.ackInvalidMedicaidExpansion = !this.ackInvalidMedicaidExpansion;
    // just need to do this to one of the required controls for the validator re-run
    this.dsForm.get('paySourceId').updateValueAndValidity();
  }    

  onAckInvalidFirstEISSubTypeDateClick() {
    this.ackInvalidFirstEISSubTypeDate = !this.ackInvalidFirstEISSubTypeDate;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.EISNotAllowed);
  }  

  onAckInvalidFirstBillableEISSubTypeDateClick() {
    this.ackInvalidFirstBillableEISSubTypeDate = !this.ackInvalidFirstBillableEISSubTypeDate;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.EISNotAllowed);
  }  

  onAckInvalidPaySourceBillableClick() {
    this.ackInvalidPaySourceBillable = !this.ackInvalidPaySourceBillable;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.PaymentSourceNotBillable);
  }

  onAckInvalidUnitCostClick() {
    this.ackInvalidUnitCost = !this.ackInvalidUnitCost;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.DeliveredUnitsCostExceedsMaximum);
  }

  onAckInvalidBillableTimePeriodClick() {
    this.ackInvalidBillableTimePeriod = !this.ackInvalidBillableTimePeriod;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.DeadlineMissed);
  }

  onAckInvalidHopwaWorksheetExpiredClick() {
    this.ackInvalidHopwaWorksheetExpired = !this.ackInvalidHopwaWorksheetExpired;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.HopwaWorksheetNotValid);
  }

  onAckInvalidHopwaWorksheetQualifiedClick() {
    this.ackInvalidHopwaWorksheetQualified = !this.ackInvalidHopwaWorksheetQualified;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.HopwaWorksheetNotQualified);
  }

  onAckInvalidMedicalCaseManagementDataClick() {
    this.ackInvalidMedicalCaseManagementData = !this.ackInvalidMedicalCaseManagementData;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.MissingMCMData);
  }

  onAckInvalidDirectServiceBillableByFunderBillingRestrictionEncounterClick() {
    this.ackInvalidDirectServiceBillableByFunderBillingRestrictionEncounter = !this.ackInvalidDirectServiceBillableByFunderBillingRestrictionEncounter;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason._2XSameEncounter);
  } 

  onAckInvalidEmergencyFinancialOtherAllowedClick() {
    this.ackInvalidEmergencyFinancialOtherAllowed = !this.ackInvalidEmergencyFinancialOtherAllowed;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.SubtypeNotBillable);
  }
  
  onAckInvalidAgeForIndeterminateStatusClick() {
    this.ackInvalidAgeForIndeterminateStatus = !this.ackInvalidAgeForIndeterminateStatus;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.HIVAffectedIndeterminateClient);
  }

  onAckInvalidMoreThan4UnitsIn12MonthsClick() {
    this.ackInvalidMoreThan4UnitsIn12Months = !this.ackInvalidMoreThan4UnitsIn12Months;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.MoreThan4UnitsIn12Months);
  }

  onAckInvalidDirectServiceFundedClick() {
    this.ackInvalidDirectServiceFunded = !this.ackInvalidDirectServiceFunded;
    // just need to do this to one of the required controls for the validator re-run
    // Den said even though not funded the service can still be billable
    // this.flagNonbillableAndResetValidationStatus();
    this.dsForm.get('billable').updateValueAndValidity();
  }

  onAckInvalidAssessmentClick() {
    this.ackInvalidAssessment = !this.ackInvalidAssessment;
    // just need to do this to one of the required controls for the validator re-run
    this.flagNonbillableAndResetValidationStatus(UnbillableReason.IncompleteOrInvalidAssessment);
  }

  flagNonbillableAndResetValidationStatus(newUnbillableReason: number) {
    if (this.getBillableValue()) {
      //this.dsForm.get('billable').setValue(false);
      this.setBillableValue(false);
      // 3-14-2023 Per Den, Ensure to set the unbillable reason based on priority 
      this.setUnbillableReasonByPriority(newUnbillableReason);
    }
    this.dsForm.get('billable').updateValueAndValidity();
  }

  setUnbillableReasonByPriority(newUnbillableReason: number) {
    if (!!this.getUnbillableReason()) {
      var existingPriority = UnbillableReasonPriority.find(f => f.enumIndex == this.getUnbillableReason())?.priority ?? Number.MAX_VALUE;
      var newPriority = UnbillableReasonPriority.find(f => f.enumIndex == newUnbillableReason)?.priority ?? Number.MAX_VALUE;
      // new reason has to have a high priority (a lower # is a higher priority)
      if (newPriority < existingPriority) this.setUnbillableReason(newUnbillableReason);
    }
    else
      this.setUnbillableReason(newUnbillableReason);
  }  

  barrierValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const barrierControl = formGroup.get('barrier');
      const plannedUnitsControl = formGroup.get('plannedUnits');
      const deliveredUnitsControl = formGroup.get('deliveredUnits');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!barrierControl || !plannedUnitsControl || !deliveredUnitsControl) return null;

      if (this.getBarrierValue() > 0) return null;

      if (this.getPlannedUnitsValue() > this.getDeliveredUnitsValue()) 
        return { invalidBarrier: '*You must select a barrier.' };

      return null;
    }
  }

  unitCostValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const serviceControl = formGroup.get('serviceId');
      const subTypeControl = formGroup.get('subId');
      const deliveredUnitsControl = formGroup.get('deliveredUnits');
      const funderControl = formGroup.get('dsFunder');
      const billableControl = formGroup.get('billable');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!serviceControl || !subTypeControl || !deliveredUnitsControl || !funderControl || !billableControl) return null;

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidUnitCost || !this.getBillableValue()) return null;      

      var serviceId = this.getServiceValue();
      var subId = this.getSubTypeValue();
      var deliveredUnits = this.getDeliveredUnitsValue();
      var contractGuid = this.getContractValue();
      var billable = this.getBillableValue();

      if (serviceId <= 0 || subId <= 0 || deliveredUnits <= 0 || !this.contractDate || !contractGuid || !billable) return null;

      var unitCost = this.providerService.getContractItemBillableCost(contractGuid, serviceId, subId, deliveredUnits, this.contractDate) ?? 0;
      if (billable && unitCost > 5000) 
        return { invalidUnitCost: '*Service cannot be billable, delivered units cost ($' + unitCost.toString() +  ') exceeds the maximum total cost ($5000) per patient visit, per service-sub type.' };

      return null;
    }
  }

  interventionValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const serviceControl = formGroup.get('serviceId');
      const subTypeControl = formGroup.get('subId');
      const cohortInterventionControl = formGroup.get('cohortIntervention');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!serviceControl || !subTypeControl || !cohortInterventionControl) return null;

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidIntervention) return null;         

      var serviceId = this.getServiceValue();
      var subId = this.getSubTypeValue();
      var cohortIntervention = this.getCohortIntervention();
      var billable = this.getBillableValue();

      if (!billable || serviceId <= 0 || subId <= 0) return null;

      var isRequired = this.commonDataService.isSubTypeCohortInterventionRequired(serviceId, subId);
      if (this.getClientCohortStudy() && isRequired && cohortIntervention <= 0) 
        return { invalidIntervention: '*Intervention question must be answered for patients who are part of the COHORT study before billable services can be added.' };

      return null;
    }
  }

  medicaidExpansionValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      if ((this.getClientData()?.clientStatusHistory?.id ?? 0) != ClientStatus.Active || this.getClientData()?.ssn == '9999') return;

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidMedicaidExpansion) return null;          

      if (((this.getClient().isClientEligible() & EligibilityAlertErrors.FPLUnder139orNoInsuranceandNoMedicaid) == EligibilityAlertErrors.FPLUnder139orNoInsuranceandNoMedicaid) &&
          this.getClientData()?.clientLiveIn?.id != LiveIn.JailOrPrison &&
          (this.getPaySourceValue() == PaySource.NoInsurance || this.getPaySourceValue() == PaySource.Charity))
        return { invalidMedicaidExpansion: '*Client may be eligible for Medicaid Expansion (New Jersey Family Care), Please verify insurance status.' };

      return null;
    }
  }

  firstEISSubTypeDateValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const subTypeControl = formGroup.get('subId');
      const deliveredDateControl = formGroup.get('deliveredDate');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!subTypeControl || !deliveredDateControl) return null;

      // if the user acknowledged the warning then bypass the validation
      //if (this.ackInvalidFirstEISSubTypeDate) return null;        

      var subId = this.getSubTypeValue();
      var deliveredDate = this.getDeliveredDate();
      if (!deliveredDate || subId <= 0 || !Object.values(EISSubTypes).includes(subId) || !this.eisSubCategoriesBillableData ||
          deliveredDate.getTime() <= this.dsDataService.getDefaultDate().getTime()) return null;

      // eisSubCategoriesBillableData data is loaded in onChangeSubType
      if (this.eisSubCategoriesBillableData.isEISSubCategoryBillable == false && 
          deliveredDate.getTime() < this.eisSubCategoriesBillableData.firstEISDatetime.getTime()) 
      {
        var subCatDesc = this.commonDataService.getSubTypeDescription(subId);
        return { invalidFirstEISSubTypeDate: '*' + subCatDesc + ' service Date must be greater than or equal to previous ' + subCatDesc + ' service Date.' };
      }

      return null;
    }
  }

  firstBillableEISSubTypeDateValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const subTypeControl = formGroup.get('subId');
      const billableControl = formGroup.get('billable');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!subTypeControl || !billableControl) return null;

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidFirstBillableEISSubTypeDate) return null;      

      var subId = this.getSubTypeValue();
      var billable = this.getBillableValue();

      if (this.loadingDirectServiceDialog || !billable || subId <= 0 || !Object.values(EISSubTypes).includes(subId) || 
          !this.eisSubCategoriesBillableData) return null;

      // eisSubCategoriesBillableData data is loaded in onChangeSubType
      if (this.getGuidControl()?.value.toUpperCase() != this.eisSubCategoriesBillableData?.guid.toUpperCase() && 
          this.eisSubCategoriesBillableData.firstBillableEISDatetime.getTime() != this.dsDataService.getDefaultDate().getTime()) 
      {
        var subCatDesc = this.commonDataService.getSubTypeDescription(subId);
        return { invalidFirstBillableEISSubTypeDate: '*This service is not billable because you can have only one billable ' + subCatDesc + ' service, which is provided on ' + format(this.eisSubCategoriesBillableData.firstBillableEISDatetime, 'MM/dd/yyyy') };
      }

      return null;
    }
  }

  paySourceBillableValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const billableControl = formGroup.get('billable');
      const deliveredDateControl = formGroup.get('deliveredDate');
      const serviceControl = formGroup.get('serviceId');
      const subTypeControl = formGroup.get('subId');
      const funderControl = formGroup.get('dsFunder');
      const paySourceControl = formGroup.get('paySourceId');
      const paySourceReasonControl = formGroup.get('paySourceReasonId');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!billableControl || !deliveredDateControl || !serviceControl || !subTypeControl || !funderControl ||
          !paySourceControl || !paySourceReasonControl) return null;

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidPaySourceBillable) return null;             

      var billable = this.getBillableValue();
      var serviceId = this.getServiceValue();
      var subId = this.getSubTypeValue();      
      var funderId = this.getFunderValue();
      var paySourceId = this.getPaySourceValue();
      var paySourceReasonId = this.getPaySourceReasonValue();
      var deliveredDate = this.getDeliveredDate();

      if (!billable || serviceId <= 0 || funderId <= 0 || paySourceId <= 0 || !deliveredDate ||
          paySourceReasonId <= 0 || subId <= 0 || deliveredDate.getTime() <= this.dsDataService.getDefaultDate().getTime()) return null;

      let dsModel: Partial<ClientDirectServiceModel> = new ClientDirectService();
      dsModel.deliveredDate = formatISO(deliveredDate, { representation: 'date' });
      dsModel.serviceId = serviceId;
      dsModel.funder = funderId;
      dsModel.paySourceId = paySourceId;
      dsModel.paySourceReasonId = paySourceReasonId;

      if (!this.dsDataService.isPaySourceBillable(dsModel, 0))
      {
        if (paySourceReasonId <= 0 && (this.paySourceReasons?.length ?? 0) > 0 &&
            (paySourceId != 18 || (paySourceId == 18 && subId == 303)))
        {
          return { invalidPaySourceBillable: '*This service is not billable without selecting the pay source Reason.' };
        }
        else if (paySourceId == 18 && subId != 303)
        {
          return { invalidPaySourceBillable: '*Patients with Insurance Coverage Source - Medicaid - Managed Care cannot receive Ryan White Primary Medical services. This service is not billable.' };
        }
      }

      return null;
    }
  }

  public billableTimePeriodValidator(): AsyncValidatorFn {
    return (formGroup: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const billableControl = formGroup.get('billable');
      const deliveredDateControl = formGroup.get('deliveredDate');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!billableControl || !deliveredDateControl) return of(null);
      // ||  (billableControl.pristine && deliveredDateControl.pristine)) return of(null);

      //if (this.getInvalidBillableTimePeriodAcknowledgement()) return of(null);
      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidBillableTimePeriod || !this.getBillableValue()) return of(null);

      var deliveredDate = formatISO(this.getDeliveredDate(), { representation: 'date' });
      return this.dsDataService.isAllowedBillable(deliveredDate).then(
        isAllowed => {
          if (this.getBillableValue() && !isAllowed)
            return { invalidBillableTimePeriod: '*The direct service will be set to not billable because it was entered past the allowable billable time period.' };
          else
            return null;
        });
    }
  }

  public hopwaWorksheetExpiredValidator(): AsyncValidatorFn {
    return (formGroup: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const billableControl = formGroup.get('billable');
      const deliveredDateControl = formGroup.get('deliveredDate');
      const serviceControl = formGroup.get('serviceId');
      const subTypeControl = formGroup.get('subId');
      const funderControl = formGroup.get('dsFunder');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!billableControl || !deliveredDateControl || !serviceControl || !subTypeControl || !funderControl) return of(null);
      // || (billableControl.pristine && deliveredDateControl.pristine && serviceControl.pristine && subTypeControl.pristine && funderControl.pristine)) return of(null);

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidHopwaWorksheetExpired || !this.getBillableValue()) return of(null);

      let dsModel: Partial<ClientDirectServiceModel> = new ClientDirectService();
      dsModel.deliveredDate = formatISO(this.getDeliveredDate(), { representation: 'date' });
      dsModel.serviceId = this.getServiceValue();
      dsModel.funder = this.getFunderValue();
      dsModel.subId = this.getSubTypeValue();

      return this.dsDataService.isValidHopwaWorksheetData(dsModel).then(
        isAllowed => {
          if (this.getBillableValue() && !isAllowed)
            return { invalidHopwaWorksheetExpired: '*A HOPWA worksheet must be completed prior to entering units for long term residential housing. The worksheet date should be prior to the date of this service and cannot be expired. This service will be non billable.' };
          else
            return null;
        });
    }
  }

  public hopwaWorksheetQualifiedValidator(): AsyncValidatorFn {
    return (formGroup: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const billableControl = formGroup.get('billable');
      const deliveredDateControl = formGroup.get('deliveredDate');
      const serviceControl = formGroup.get('serviceId');
      const subTypeControl = formGroup.get('subId');
      const funderControl = formGroup.get('dsFunder');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!billableControl || !deliveredDateControl || !serviceControl || !subTypeControl || !funderControl) return of(null);
      //  ||  (billableControl.pristine && deliveredDateControl.pristine && serviceControl.pristine && subTypeControl.pristine && funderControl.pristine)) return of(null);

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidHopwaWorksheetQualified || !this.getBillableValue()) return of(null);

      let dsModel: Partial<ClientDirectServiceModel> = new ClientDirectService();
      dsModel.deliveredDate = formatISO(this.getDeliveredDate(), { representation: 'date' });
      dsModel.serviceId = this.getServiceValue();
      dsModel.funder = this.getFunderValue();
      dsModel.subId = this.getSubTypeValue();

      return this.dsDataService.isHopwaWorksheetDataQualified(dsModel).then(
        isAllowed => {
          if (this.getBillableValue() && !isAllowed)
            return { invalidHopwaWorksheetQualified: '*This client is NOT qualified to receive HOPWA funded long term housing rental assistance. See clients HOPWA worksheet.' };
          else
            return null;
        });
    }
  }    

  public medicalCaseManagementDataValidator(): AsyncValidatorFn {
    return (formGroup: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const billableControl = formGroup.get('billable');
      const deliveredDateControl = formGroup.get('deliveredDate');
      const serviceControl = formGroup.get('serviceId');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!billableControl || !deliveredDateControl || !serviceControl) return of(null);
      //  ||  (billableControl.pristine && deliveredDateControl.pristine && serviceControl.pristine)) return of(null);

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidMedicalCaseManagementData || !this.getBillableValue()) return of(null);

      let dsModel: Partial<ClientDirectServiceModel> = new ClientDirectService();
      dsModel.deliveredDate = formatISO(this.getDeliveredDate(), { representation: 'date' });
      dsModel.serviceId = this.getServiceValue();
      //dsModel.funder = this.getFunderValue();
      //dsModel.subId = this.getSubTypeValue();

      return this.dsDataService.isValidMedicalCaseManagementData(dsModel.serviceId, dsModel.deliveredDate).then(
        isAllowed => {
          if (this.getBillableValue() && !isAllowed)
            return { invalidMedicalCaseManagementData: '*This client is NOT qualified to receive Medical Case Management service because information regarding the last medical visit has not been entered or is expired (more than 6 month old). See clients Medical Case Management tab and provider the required information.' };
          else
            return null;
        });
    }
  }

  public directServiceBillableByFunderBillingRestrictionEncounterValidator(): AsyncValidatorFn {
    return (formGroup: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const billableControl = formGroup.get('billable');
      const deliveredDateControl = formGroup.get('deliveredDate');
      const serviceControl = formGroup.get('serviceId');
      const subTypeControl = formGroup.get('subId');
      const funderControl = formGroup.get('dsFunder');      

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!billableControl || !deliveredDateControl || !serviceControl || !subTypeControl || !funderControl) return of(null);
      // || (billableControl.pristine && deliveredDateControl.pristine && serviceControl.pristine && subTypeControl.pristine && funderControl.pristine)) return of(null);

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidDirectServiceBillableByFunderBillingRestrictionEncounter || !this.getBillableValue()) return of(null);

      let dsModel: Partial<ClientDirectServiceModel> = new ClientDirectService();
      dsModel.deliveredDate = formatISO(this.getDeliveredDate(), { representation: 'date' });
      dsModel.serviceId = this.getServiceValue();
      dsModel.subId = this.getSubTypeValue();      
      dsModel.funder = this.getFunderValue();

      return this.dsDataService.isDirectServiceBillableByFunderBillingRestrictionEncounter(dsModel).then(
        isAllowed => {
          if (this.getBillableValue() && !isAllowed) {
            var serviceDesc = this.commonDataService.getServiceCategoryDescription(this.getServiceValue());
            var subTypeDesc = this.commonDataService.getSubTypeDescription(this.getSubTypeValue());
            return { invalidDirectServiceBillableByFunderBillingRestrictionEncounter: '*Service ' + serviceDesc + ', ' + subTypeDesc + ' is not billable because the encounter has already been billed for the patient under a different Funder.' };
          }
          else
            return null;
        });
    }
  }

  public emergencyFinancialOtherAllowedValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const subTypeControl = formGroup.get('subId');
      const billableControl = formGroup.get('billable');
      const serviceControl = formGroup.get('serviceId');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!subTypeControl || !billableControl || !serviceControl) return null;
      //  || (subTypeControl.pristine && billableControl.pristine && serviceControl.pristine)) return null;

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidEmergencyFinancialOtherAllowed) return null;

      var subId = this.getSubTypeValue();
      var serviceId = this.getServiceValue();
      var billable = this.getBillableValue();
      if (!billable || subId <= 0 || serviceId <= 0) return null;

      if (!this.dsDataService.isEmergencyFinancialOtherAllowed(serviceId, subId))
      {
        return { invalidEmergencyFinancialOtherAllowed: '*Emergency Financial Assistance - Other is not a billable subtype. Please contact your program monitor to authorize this unit as billable.' };
      }

      return null;
    }
  }

  public validAgeForIndeterminateStatusValidator(): AsyncValidatorFn {
    return (formGroup: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const billableControl = formGroup.get('billable');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!billableControl) return of(null); // || billableControl.pristine

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidAgeForIndeterminateStatus || !this.getBillableValue()) return of(null);

      return this.clientService.isValidAgeForIndeterminateStatus().then(
        status => {
          if (this.getBillableValue() && status == HIVIndeterminateStatusResult.IndeterminateStatusInvalidAge)
            return { invalidAgeForIndeterminateStatus: '*The direct service was set to not billable because the HIV Status of HIV-Indeterminate is not a valid option since the clients age is greater than the requirement which is under 2 years old.' };
          else
            return null;
        });
    }
  }

  public moreThan4UnitsIn12MonthsValidator(): AsyncValidatorFn {
    return (formGroup: UntypedFormGroup): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
      const billableControl = formGroup.get('billable');
      const subTypeControl = formGroup.get('subId');
      const deliveredUnitsControl = formGroup.get('deliveredUnits');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!billableControl || !subTypeControl || !deliveredUnitsControl) return of(null);
      //  ||  (billableControl.pristine && subTypeControl.pristine && deliveredUnitsControl.pristine)) return of(null);

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidMoreThan4UnitsIn12Months || !this.getBillableValue()) return of(null);

      let dsModel: Partial<ClientDirectServiceModel> = new ClientDirectService();
      dsModel.subId = this.getSubTypeValue();
      dsModel.deliveredUnits = this.getDeliveredUnitsValue();

      return this.dsDataService.isMoreThan4UnitsIn12Months(dsModel).then(
        returnValue => {
          if (this.getBillableValue() && returnValue?.moreThan4UnitsIn12MonthsResult) {
            var serviceDesc = this.commonDataService.getServiceCategoryDescription(this.getServiceValue());
            var subTypeDesc = this.commonDataService.getSubTypeDescription(this.getSubTypeValue());
            var invalidMoreThan4UnitsIn12MonthsMessage = '*Service ' + serviceDesc + ', ' + subTypeDesc + ' is not billable because the encounter cannot bill more than 4 units in a 12 month duration and ' +
                                                          returnValue?.totalDeliveredUnits.toString() + ' total units have been billed so far and there are ' + returnValue?.remainingUnits.toString() + ' billable units remaining.';
            return { invalidMoreThan4UnitsIn12Months: invalidMoreThan4UnitsIn12MonthsMessage };
          }
          else
            return null;
        });
    }
  }

  public directServiceFundedValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const billableControl = formGroup.get('billable');
      const deliveredDateControl = formGroup.get('deliveredDate');
      const serviceControl = formGroup.get('serviceId');
      const subTypeControl = formGroup.get('subId');
      const funderControl = formGroup.get('dsFunder');

      if (!billableControl || !deliveredDateControl || !serviceControl || !subTypeControl || !funderControl) return null;
      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      // if (!billableControl || !deliveredDateControl || !serviceControl || !subTypeControl || !funderControl ||
      //     (billableControl.pristine && deliveredDateControl.pristine && serviceControl.pristine && subTypeControl.pristine && funderControl.pristine)) return null;

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidDirectServiceFunded) return null;

      let dsModel: Partial<ClientDirectServiceModel> = new ClientDirectService();
      dsModel.billable = this.getBillableValue();  
      dsModel.serviceId = this.getServiceValue();
      dsModel.subId = this.getSubTypeValue();
      dsModel.funder = this.getFunderValue();
      //dsModel.deliveredDate = formatISO(this.getDeliveredDateValue(), { representation: 'date' });
      dsModel.deliveredDate = this.getDeliveredDate();
      dsModel.contractGuid = this.getContractValue();

      var isValidDeliveredDate = isDate(dsModel.deliveredDate);

      if (!dsModel.billable || dsModel.serviceId <= 0 || dsModel.subId <= 0 || dsModel.funder <= 0 || !isValidDeliveredDate ||
          dsModel.contractGuid?.length != 36) return null;

      if (!this.dsDataService.isValidContractLineItemFound(dsModel))
      {
        return { invalidDirectServiceFunded: '*Service / Subtype combination is not funded on providers contract. Service can still be billable.' };
      }

      return null;
    }
  }

  public assessmentValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const subTypeControl = formGroup.get('subId');
      const billableControl = formGroup.get('billable');

      // pristine only fires if a user made a change through the UI, spoke to Den on 10/6/2021 he wants the validators to fire on initial startup too
      if (!subTypeControl || !billableControl) return null;

      // if the user acknowledged the warning then bypass the validation
      if (this.ackInvalidAssessment) return null;

      var subId = this.getSubTypeValue();
      var billable = this.getBillableValue();
      if (!billable || subId <= 0 || this.newDSItem) return null;

      // if qualifies and no assessment exists or the assessment is expired
      // compareAsc((this.directService.clientAssessment?.expired ?? this.originDate), this.originDate) != 0)
      if (this.qualifyForAssessment() &&
          (!this.directService.clientAssessment || this.helperService.isExpired(this.directService.clientAssessment?.expired)))
      {
        return { invalidAssessment: '*The direct service was set to not billable because of missing or expired assessment.' };
      }

      return null;
    }
  }
}
