<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<!-- <div class="card champ-card champ-card-bottom">
    Form status : {{dsForm.status | json}}
    Form value : {{dsForm.value | json}}
    Funder errors : {{dsForm.controls['dsFunder']?.errors ?? 'no error(s)'}}
    Funder value : {{dsForm.get('dsFunder').value?.contractGuid}}
    Funder specific error : {{ !!dsForm.controls['dsFunder'].errors ? dsForm.controls['dsFunder'].errors['invalidRequiredField'] ?? 'no error' : 'no errorS'}}
</div> -->

<!--  (ngSubmit)="onSaveChanges()" -->
<form [formGroup]='dsForm' autocomplete="off">

<div class="container-fluid champ-card-body-sm" style="width: 99.25%;"> <!-- container-fluid champ-card-body-sm margin-top: .5rem; margin-left: .5rem; margin-right: .5rem; margin-bottom: 1rem;-->
    <div class="card-header champ-card-header-sm">Direct Service Search</div>
    
    <div class="p-fluid p-formgrid grid" style="margin-left: .0125rem">
        <div class="col-6 field " style="margin-top: 10px ;">
            <div class="formgroup-inline" style="height: 25px;">
                <label for="filterOption" style="margin-right: 0.5rem;">Filter Options:</label>
                <div class="field-radiobutton"> <!-- (click)="onChangeFilterOption(1)"  (click)="onChangeFilterOption(2)"-->
                    <p-radioButton [style]="{'margin-top': '0.25rem','align-items': 'unset'}" name="filterOption" value=1 label="Standard Filters" formControlName="filterOption" (click)="onChangeFilterOption(1)"></p-radioButton>
                </div>
                <div class="field-radiobutton">
                    <p-radioButton [style]="{'margin-top': '0.25rem','align-items': 'unset'}" name="filterOption" value=2 label="Custom Filters" formControlName="filterOption" (click)="onChangeFilterOption(2)"></p-radioButton>
                </div>
            </div>
        </div>
    </div>
    <div class="p-fluid p-formgrid grid" style="margin-left: .0125rem">
        <div class="field col-1" *ngIf="usingCustomDSListFiltering()">
            <label for="dsMonth">Month</label>
            <p-dropdown formControlName="dsMonth" [options]="months" optionLabel="name" optionValue="code" appendTo="body" (onChange)="onChangeMonth($event.value)"> </p-dropdown>
        </div>
        <div class="field col-1" *ngIf="usingCustomDSListFiltering()">
            <label for="dsYear">Year</label>
            <p-dropdown formControlName="dsYear" [options]="years" appendTo="body" (onChange)="onChangeYear($event.value)"> </p-dropdown>
        </div>
        <div class="field col-2" *ngIf="!usingCustomDSListFiltering()">
            <label for="filterSelection">Please select a time period</label>
            <p-dropdown formControlName="filterSelection" [options]="standardFilterSelections" optionLabel="name" optionValue="code" appendTo="body" (onChange)="onChangeFilterSelection($event.value)"> </p-dropdown>
        </div>        
        <div class="field col2">
            <!-- <button pButton pRipple label="New" icon="pi pi-plus" class="btn btn-primary"  *ngIf="!clientService.readonly"
                    style="height:28px;width:75px;float:left;margin-top:25px;" (click)="onOpenNewDirectServiceDialog()"></button> -->

            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'15rem'}"  styleClass="p-button-text " class="p-button-text mr-1 mt-4"
                    (click)="onOpenNewDirectServiceDialog()"   > 
                    <i class="fa-sharp fa-plus fa-lg" ></i> <span>New</span>
            </p-button>        
        </div>
    </div>

    <div class="p-fluid p-formgrid grid">
        <div class="field col"> <!-- style="display: inline-block; vertical-align: top" -->
            <ng-template #loading>
                <div class="champ-notification-loading">Loading services...</div>
            </ng-template>            
        </div>
    </div>

    <p-scrollPanel [style]="{width: '99.2%', padding: '0', margin: '.5rem'}"> <!-- height: '20%', -->
        <ng-container *ngIf="(clientDirectServices$ | async) as dsData else loading"> <!-- "(clientDirectServices$ | async) as dsData else loading" -->
            <!-- [autoLayout]="true" [resizableColumns]="true" scrollHeight="100%" scrollWidth="100%" [rows]="10" [style]="{'width':'99.75%'}" -->
            <p-table [value]="dsData"
                    [rowHover]="true"
                    [style]="{'width':'99.9%'}" 
                    styleClass="p-datatable-sm p-datatable-striped"
                    name="tblClientDirectServices"
                    [showCurrentPageReport]="false"
                    [paginator]="false"
                    currentPageReportTemplate="{totalRecords} entries">
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width:85px;max-width:85px;text-align:left"></th>
                    <th pSortableColumn="deliveredDate" style="min-width:70px;max-width:70px;text-align:left">Date</th>
                    <th pSortableColumn="subTypeAssessmentId" style="min-width:45px;max-width:45px;text-align:left">CA</th>
                    <th pSortableColumn="containsAnyTypeOfRiskReduction" style="min-width:60px;max-width:60px;text-align:left">Popup</th>
                    <th pSortableColumn="eis" style="min-width:45px;max-width:45px;text-align:left">EIS</th>
                    <th pSortableColumn="cohortIntervention" style="min-width:55px;max-width:55px;text-align:left">CoInt</th>
                    <th pSortableColumn="serviceDescription" style="min-width:150px;max-width:150px;text-align:left">Service Category<p-sortIcon field="serviceDescription"></p-sortIcon></th>
                    <th pSortableColumn="subTypeDescription" style="min-width:160px;max-width:160px;text-align:left">Sub Category<p-sortIcon field="subTypeDescription"></p-sortIcon></th>
                    <th pSortableColumn="plannedUnits" style="min-width:65px;max-width:65px;text-align:left">Planned</th>
                    <th pSortableColumn="deliveredUnits" style="min-width:70px;max-width:70px;text-align:left">Delivered</th>
                    <th pSortableColumn="billable" style="min-width:60px;max-width:60px;text-align:left">Billable</th>
                    <th pSortableColumn="unbillableReasonDescription" style="min-width:200px;max-width:200px;text-align:left">Reason<p-sortIcon field="unbillableReasonDescription"></p-sortIcon></th>
                    <th pSortableColumn="paySource" style="min-width:130px;max-width:130px;text-align:left">Pay Source<p-sortIcon field="paySource"></p-sortIcon></th>
                    <th pSortableColumn="paySourceReason" style="min-width:150px;max-width:150px;text-align:left">Pay Source Reason<p-sortIcon field="paySourceReason"></p-sortIcon></th>
                    <th pSortableColumn="notes" style="min-width:160px;max-width:160px;text-align:left">Notes<p-sortIcon field="notes"></p-sortIcon></th>
                    <th pSortableColumn="subTypeInclusionDescriptions" style="min-width:160px;max-width:160px;text-align:left">Sub Types Inc<p-sortIcon field="subTypesIncluded"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" ngFor let-clientDirectService>
                <tr >
                    <td >  <!-- style="min-width:85px;max-width:85px;text-align:left" -->
                        <button title="Edit" *ngIf="!clientService.readonly" pButton pRipple class="p-button p-button-text m-1 mr-2 " (click)="onEditDirectService(clientDirectService)" style="width:1.5rem">
                            <i class="fa-sharp fa-light fa-pencil " style="color: green;"></i>
                        </button> <!-- icon="pi pi-pencil"   style="color: limegreen; background-color: white; border-color: white"-->
                        <button title="Delete" *ngIf="!clientService.readonly"  pButton pRipple  class="p-button p-button-text p-button-danger m-1 mr-2" (click)="onDeleteDirectService($event, clientDirectService)" style="width:1rem">
                            <i class="fa-sharp fa-regular fa-trash  " style="color: red;" ></i>
                        </button> <!-- style="color: red; background-color: white; border-color: white" -->
                         <button title="open" *ngIf="clientService.readonly" pButton pRipple  class="p-button p-button-text m-1 " (click)="onEditDirectService(clientDirectService)" style="width:1.5rem">
                            <i class="fa-sharp fa-regular fa-book  "  ></i>
                        </button> <!--  style="color: limegreen; background-color: white; border-color: white"-->
                    </td>
                    <td style="min-width:70px;max-width:70px;text-align:left" class="champ-datatable-date">{{clientDirectService.deliveredDate | date:'shortDate'}}</td>
                    <td style="min-width:45px;max-width:45px;text-align:center">
                        <div *ngIf="qualifyForAssessmentById(clientDirectService.subTypeAssessmentId) && !!clientDirectService?.clientAssessment">
                            <fa-icon class="champ-field-checked" [icon]="['fas', 'check']" (click)="onClickShowAssessment(clientDirectService, false)"></fa-icon> 
                        </div>                       
                    </td>
                    <td style="min-width:60px;max-width:60px;text-align:center">
                        <!-- {{clientDirectService.serviceId} {clientDirectService.subId}} -->
                        <div *ngIf="clientDirectService.containsAnyTypeOfRiskReduction && !!clientDirectService.clientMedicalRiskReduction">
                            <fa-icon class="champ-field-checked" [icon]="['fas', 'check']" (click)="onClickShowRiskReduction(clientDirectService, false)"></fa-icon> 
                        </div>                    
                    </td>
                    <td style="min-width:45px;max-width:45px;text-align:center">  
                        <div *ngIf="clientDirectService.eis">
                            <fa-icon class="champ-field-checked" [icon]="['fas', 'check']"></fa-icon> 
                        </div>
                    </td>                
                    <td style="min-width:55px;max-width:55px;text-align:center">  
                        <div *ngIf="clientDirectService?.cohortIntervention == 1">
                            <fa-icon class="champ-field-checked" [icon]="['fas', 'check']"></fa-icon> 
                        </div>
                        <div *ngIf="clientDirectService?.cohortIntervention == 2">
                            <fa-icon class="champ-field-times" [icon]="['fas', 'times']"></fa-icon>
                        </div>
                    </td>
                    <td style="min-width:150px;max-width:150px;text-align:left"><span title="{{clientDirectService.serviceDescription}}">{{clientDirectService.serviceDescription}}</span></td>
                    <td style="min-width:160px;max-width:160px;text-align:left"><span title="{{clientDirectService.subTypeDescription}}">{{clientDirectService.subTypeDescription}}</span></td>
                    <td style="min-width:65px;max-width:65px;text-align:left">{{clientDirectService.plannedUnits}}</td>
                    <td style="min-width:70px;max-width:70px;text-align:left">{{clientDirectService.deliveredUnits}}</td>
                    <td style="min-width:60px;max-width:60px;text-align:center">  
                        <div *ngIf="clientDirectService.billable">
                            <fa-icon class="champ-field-checked" [icon]="['fas', 'check']"></fa-icon> 
                        </div>
                    </td>
                    <td style="min-width:200px;max-width:200px;text-align:left"><span title="{{clientDirectService.unbillableReasonDescription}}">{{clientDirectService.unbillableReasonDescription}}</span></td>
                    <td style="min-width:130px;max-width:130px;text-align:left"><span title="{{clientDirectService.paySource}}">{{clientDirectService.paySource}}</span></td>
                    <td style="min-width:150px;max-width:150px;text-align:left"><span title="{{clientDirectService.paySourceReason}}">{{clientDirectService.paySourceReason}}</span></td>
                    <td style="min-width:160px;max-width:160px;text-align:left"><span title="{{clientDirectService.notes}}">{{clientDirectService.notes}}</span></td>
                    <td style="min-width:160px;max-width:160px;text-align:left"><span title="{{clientDirectService.subTypeInclusionDescriptions}}">{{clientDirectService.subTypeInclusionDescriptions}}</span></td>
                </tr>
            </ng-template>
            <!--
            <ng-template pTemplate="summary">
                <div class="p-d-flex p-ai-center p-jc-between">
                    In total there are {{products ? products.length : 0 }} products.
                </div>
            </ng-template>
            -->
            </p-table>

        </ng-container>
    </p-scrollPanel>    
</div>


</form>

<!-- Risk Reduction Dialog -->
<app-risk-reduction (dialogCompleted)="onRiskReductionDialogCompletedNotification($event)"></app-risk-reduction>

<!-- Assessment Dialog -->
<app-assessment (dialogCompleted)="onAssessmentDialogCompletedNotification($event)"></app-assessment>

<!--appendToBody="true"  -->

<!-- direct service grid && !hasChanges -->

