<form [formGroup]='client' autocomplete="off" autocapitalize="on">
    <div class="fluid formgrid grid" style="background-color: whitesmoke;">
        <div class="field col-12 xl:col-6">
            <app-mental-health [behavior_health]="client.get('behavior_health').get('MH')"></app-mental-health>
        </div>

        <div class="field col-12 xl:col-6">
            <app-substance-abuse [behavior_health]="client.get('behavior_health').get('SA')"></app-substance-abuse>
        </div>
    </div>
</form>