import { Injectable } from '@angular/core';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IdleService {
  public idleState = 'Not started.';
  public timedOut = false;
  public lastPing?: Date = null;
  idleTimeout: number;
  inactivityTimeout: number;

  constructor( public idle: Idle, public keepalive: Keepalive ) {
      this.idleTimeout = 5;
      this.inactivityTimeout = environment.inactivityTimeout;

   }

   startIdleMonitoring(){
        // sets an idle timeout of 5 seconds, for testing purposes.
        this.idle.setIdle(this.idleTimeout);

        // sets a timeout period of 5 seconds. after 10 seconds of inactivity, the user will be considered timed out.
        this.idle.setTimeout(this.inactivityTimeout); 

        // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
   }
   
   reset() {
      this.idle.watch();
      this.idle.idleState = 'Started.';
      this.idle.timedOut = false;
      this.idleState = this.idle.onIdleState; 
      this.timedOut  = this.idle.timedOut ;
  }

}
