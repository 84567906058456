//ContractMaintenanceComponent
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
//import { HelperService } from '../services/helper.service';
//import { ProductEnum } from '../models/common-data.model';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { LoginService } from 'src/app/login/services/login.service';
import { DropDownItem, ProviderContractMaintenanceModel, ProviderContractMaintenanceRequestModel } from 'src/app/models/admin.models';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { UserService } from 'src/app/services/user.service';
import { ContractItemsComponent } from '../contract-items/contract-items.component';
import { ContractMaintenanceDetailComponent } from '../contract-maintenance-detail/contract-maintenance-detail.component';
import { ContractMaintenanceService } from './contract-maintenance.service';

@Component({
  selector: 'app-contract-maintenance',
  templateUrl: './contract-maintenance.component.html',
  styleUrls: ['./contract-maintenance.component.scss','../admin-styles.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class ContractMaintenanceComponent implements OnInit {
  fg: UntypedFormGroup;
  diagRef: DynamicDialogRef;
  providers: any; //DropDownItem[];
  users: DropDownItem[];  
  providerContracts: Partial<ProviderContractMaintenanceModel>[];

  getProviderId = () => this.fg?.get('providerId');
  getProviderContracts = () => this.providerContracts;

  constructor(private fb: UntypedFormBuilder,
              private app: AppContextService,
              private authService: AuthService,
              private loginService: LoginService,
              //private clientService: ClientService,
              //private commonDataService: CommonDataService,
              private userService: UserService,
              private helper: HelperClassService,
              private router: Router,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private validations: FormValidations,
              public dialogService: DialogService,
              private contractMaintenanceService: ContractMaintenanceService) {
      this.fg = fb.group({
        providerId: [1]
      },
      {
        //validators: [this.customValidator()],
        //asyncValidators: [this.timePeriodValidator()]
        //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
      });      
  }

  ngOnInit(): void {
    // if(!this.authService.isUserAuthenticated){
    //   this.loginService.standAloneLogin().then(x=> this.loadData());
    // }
    // else this.loadData();      

    this.app.updateTabTitle('CHAMP - Contract Maintenance');

     // Feature disabled for now 
    //  this.userService.GetUserConfigValue('CHAMP.Registry.Admin.CurrentProviderId').subscribe({
    //    next: data => {
    //       this.getProviderId().setValue(data?.keyValue);  
    //       this.loadData(); 
    //   }});

    this.loadData(); 
  }

  loadData() {
        this.userService.userInfo$.subscribe({
          next: (data: any) => {
            if (!!data) {
              this.providers = this.userService.getAccessibleProviders().map(provider => { const result: any = {value: `${provider.providerId}`, 
                                                                                                                label: `${provider.providerName}`};
                                                                                          return result; });
               //if (  this.getProviderId().value == null  )  
                 this.getProviderId().setValue(this.providers[0].value);                                                                                
              this.loadProviderContracts();
            }
          },
          error: (error) => {
            this.app.userServ.LogError(error);
          },
          complete:() => {
          }         
        });
  
  }

  loadProviderContracts() {
    var request: Partial<ProviderContractMaintenanceRequestModel> = {};
    Object.assign(request, {});
    request.providerId = this.getProviderId().value;

    this.contractMaintenanceService.getProviderContracts(request).subscribe({
      next: (data: ProviderContractMaintenanceModel[]) => {
            //console.log(data);
            this.providerContracts = data;
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => { }
      });
  }

  onChangeFilter(e, dtProviderContracts) {
    this.loadProviderContracts();
    this.userService.SaveUserConfigValue('CHAMP.Registry.Admin.CurrentProviderId',  this.getProviderId()?.value?.toString());
  }

  onEditContract(e, contract: ProviderContractMaintenanceModel) { //, user: AdminUserModel
    contract.contractIdToClone = 0;
    this.diagRef = this.dialogService.open(ContractMaintenanceDetailComponent, {
      header:  contract == null ? 'Add CHAMP Contract': ' Edit CHAMP Contract',
      width: '50%',
      //height: '80%', //'700px'
      contentStyle: {"max-height": "950px", "overflow": "visible"},
      baseZIndex: 10000,
      data: contract
    });

    this.app.overLayMangr.add(this.diagRef, 'dialogRef');

    this.diagRef.onClose.subscribe(async (data) => {
        this.loadProviderContracts();
    });
  }

  onDeleteContract(e, contract: ProviderContractMaintenanceModel) {
  }  

  onCloneContract(e, contract: ProviderContractMaintenanceModel) {
    contract.guid = null;
    contract.contractIdToClone = contract.id;
    this.diagRef = this.dialogService.open(ContractMaintenanceDetailComponent, {
      header:  'Clone CHAMP Contract',
      width: '50%',
      contentStyle: {"max-height": "750px", "overflow": "auto"},
      baseZIndex: 10000,
      data: contract
    });

    this.app.overLayMangr.add(this.diagRef, 'dialogRef');

    this.diagRef.onClose.subscribe(async (data) => {
        this.loadProviderContracts();
        this.app.overLayMangr.remove(this.diagRef);
    });
  }

  // openContractItems(guid: string) {
  //   let route  = ['../contract-items/${guid}']; 
  //   this.router.navigate(['../contract-items/' + guid]);
  // }
  openContractItems(contractItem: Partial<ProviderContractMaintenanceModel> | null = null) {
    //this.router.navigate(['contract-items'], { state: { params: cgpParams } })
    var cgpParams: Partial<ProviderContractMaintenanceModel> = { };

    if (!!contractItem) {
      cgpParams.id = contractItem.id;
      cgpParams.guid = contractItem.guid;
      cgpParams.programName = contractItem.programName;
      cgpParams.fundYear = contractItem.fundYear;
      cgpParams.startDate = contractItem.startDate;
      cgpParams.endDate = contractItem.endDate;
      cgpParams.contractAmt = contractItem.contractAmt;
    }

    var dialogHeader = 'Contract Items for ' + contractItem?.programName;

    this.diagRef = this.dialogService.open(ContractItemsComponent, {
      header: dialogHeader,
      width: '90%',
      contentStyle: {"max-height": "650px", "overflow": "visible"},
      baseZIndex: 5, //10000
      data: cgpParams
    });

    this.app.overLayMangr.add(this.diagRef, 'dialogRef');

    this.diagRef.onClose.subscribe((returnData) => {
      this.loadProviderContracts();
      this.app.overLayMangr.remove(this.diagRef);
      //this.router.routeReuseStrategy.shouldReuseRoute = () => false;
      //this.router.onSameUrlNavigation = 'reload';      
    });    
  }  
}
