import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { orderBy } from 'lodash';
import { CheckboxChangeEvent } from 'primeng/checkbox';
import { DialogService } from 'primeng/dynamicdialog';
import { OverlayPanel } from 'primeng/overlaypanel';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { AdminProviderUsersModel, AdminUserModel, ProviderClinicsPermissionModel } from 'src/app/models/admin.models';
import { UserMaintenanceService } from '../user-maintenance/user-maintenance.service';
import { ConfirmationService, MessageService } from 'primeng/api';
import { UserProviderClinicPermService } from './user-provider-clinic-perm.service';

@Component({
  selector: 'app-user-provider-clinic-perm',
  templateUrl: './user-provider-clinic-perm.component.html',
  styleUrls: ['./user-provider-clinic-perm.component.scss','../admin-styles.scss']
})
export class UserProviderClinicPermissionComponent implements OnInit {
  @Output("onRefresh") refreshUser: EventEmitter<AdminProviderUsersModel[]> = new EventEmitter();

  //user: AdminUserModel;
  userProviderClinicsPerms: AdminProviderUsersModel[];
  providerClinicData:Partial<ProviderClinicsPermissionModel>[];
  private showUserProviderClinicOverlay: boolean; 
  @ViewChild('providerClinicPermOverlay', { static: false }) providerClinicPermOverlay: OverlayPanel;
  key: string = '';
 

  constructor(
    public app: AppContextService,
    public dialogService: DialogService,
    public userMaintenanceService: UserMaintenanceService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    public userProviderClinicPermService: UserProviderClinicPermService
  ){


  }

  ngOnInit(): void {

  }

  onProviderClinicsPermButtonClick(e){
    if (!this.userProviderClinicPermService.visible) {  // && this.key == this.userProviderClinicPermService.key) || this.userProviderClinicPermService.key == '' ) {
        this.load();
        this.showUserProviderClinicOverlay = !this.showUserProviderClinicOverlay;
        this.providerClinicPermOverlay.toggle(e);
        this.userProviderClinicPermService.visible = this.providerClinicPermOverlay.overlayVisible; 
        //this.userProviderClinicPermService.key = this.key;
      }
    else{
      if (this.userProviderClinicPermService.visible ){ //&& this.key == this.userProviderClinicPermService.key) {
        this.onClose(e)
        //this.userProviderClinicPermService.key = '';
        //this.userProviderClinicPermService.visible = this.providerClinicPermOverlay.overlayVisible; 
      }
    }
  }

  public reset(){
    this.providerClinicPermOverlay.hide();
  }  

  public load(){
    this.getProviderClinicPermissions();
  }

  public hide(){
    this.providerClinicData = [];
    this.showUserProviderClinicOverlay = false;
    this.providerClinicPermOverlay.hide();
    this.userProviderClinicPermService.key = '';
    this.userProviderClinicPermService.visible = this.providerClinicPermOverlay.overlayVisible; 
  }

  getProviderClinicPermissions( ) {
         this.userProviderClinicPermService.user?.providerUsers?.forEach(perm => {
          var foundPerm =  this.userProviderClinicPermService.user?.providerClinicPermissionList?.find(x=> x.providerId == perm.providerId && x.clinicId == perm.defaultClinicId)
          if (foundPerm ){
            foundPerm.permission = true; 
          }
        });
        this.providerClinicData = this.userProviderClinicPermService.user?.providerClinicPermissionList;
  }  

  onClose(e){
   // Save Back to AdminUser Model
   // Build New Provider Users Perm Table  
   let newList:AdminProviderUsersModel[] = []
   this.providerClinicData?.forEach(perm => {
      if (perm.permission ){
          newList.push( { guid:'"00000000-0000-0000-0000-000000000000"',providerId:perm.providerId, defaultClinicId:perm.clinicId, userId:this.userProviderClinicPermService.user.userId, defaultEmployeeId:0 } )
      }
    } );

    this.userProviderClinicPermService.user.providerUsers = newList;
    this.refreshUser.emit( this.userProviderClinicPermService.user?.providerUsers);
    this.providerClinicPermOverlay.toggle(e);
    this.userProviderClinicPermService.visible = this.providerClinicPermOverlay.overlayVisible; 
    //this.userProviderClinicPermService.user = this.userProviderClinicPermService.user;
  }

  onDataChanged(e:CheckboxChangeEvent, providerUsers ){

     console.log(providerUsers);
     //this.refreshUser.emit(this.userProviderClinicPermService.user?.providerUsers);

   }

}
