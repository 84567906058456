import { DatePipe } from '@angular/common';
//import { Component, OnInit, ViewEncapsulation, isDevMode, ChangeDetectorRef, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder, UntypedFormGroup
} from '@angular/forms';
import { ConfirmationService, MessageService } from "primeng/api";
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { ServiceCategory } from '../models/common-data.model';
import { CommonDataService } from '../services/common-data.service';
import { ProviderService } from '../services/provider.service';
import { UserService } from '../services/user.service';
import { ClientDirectServiceModel, ClientDirectServiceRequestModel, EISSubCategoryDatesModel } from './services/client-direct-service-models';
import { ClientDirectServicesService } from './services/client-direct-services.service';
//import { arrayToHash } from '@fullcalendar/core/util/object';
//import { library } from '@fortawesome/fontawesome-svg-core';
import { getMonth } from 'date-fns';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { DirectServiceFilterSelectionChoices, YesNo } from "src/app/models/enums";
//import { stringify } from '@angular/compiler/src/util';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AssessmentComponent } from 'src/app/client-direct-services/assessment/assessment.component';
import { DirectServiceComponent } from 'src/app/client-direct-services/direct-service/direct-service.component';
import { RiskReductionComponent } from 'src/app/client-direct-services/risk-reduction/risk-reduction.component';
import { AppContextService } from '../app-context/app-context.service';
import { ClientRequiredFieldErrorsComponent } from '../client-required-field-errors/client-required-field-errors.component';
import { HelperClassService } from '../services/helper-class.service';

@Component({
  selector: 'app-client-direct-services',
  templateUrl: './client-direct-services.component.html',
  styleUrls: ['./client-direct-services.component.css']
})

export class ClientDirectServicesComponent implements OnInit {
  @ViewChild(RiskReductionComponent) riskReductionSub: RiskReductionComponent;
  @ViewChild(AssessmentComponent) assessmentSub: AssessmentComponent;
  @ViewChild(ClientRequiredFieldErrorsComponent) requiredFieldErrorsSub: ClientRequiredFieldErrorsComponent;
  
  YesNoEnum = YesNo;
  clientGUID: string;
  months: any[] = this.helperService.getMonths();
  years: string[] = this.helperService.getYears();
  standardFilterSelections: any[] = this.dsDataService.getStandardFilterSelections();
  employees: any[];
  employeeId = 0;
  contractFunders: any[];
  //contractId = 0;
  serviceCategories: ServiceCategory[];
  serviceId = 0;
  subTypes: any[] = [];
  paySources: any[];
  paySourceId = 0;
  paySourceReasons: any[] = [];
  barriers: any[];
  barrierId = 0;
  subTypeInclusions: any[] = [];
  subTypeInclusionsSelectedItems: any[] = []; // this has to be multi select use a single select for now
  dsForm: UntypedFormGroup;
  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  originDate: Date = new Date(0, 0); //Mon Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time) // new Date(1900, 0, 1)
  //lastContractDate: Date = this.originDate;
  contractDate: Date = this.currentDate;
  hasChanges: boolean = false; //receive an event from children if any changes have been made
  clientDirectServices$: Observable<ClientDirectServiceModel[]>;
  //formDetectChanges$: Observable<any>;
  subTypeInclusionDropDownSettings: any = {};
  //useProviderAssessment: boolean = false;
  assessmentTypeIdInitialValue: number = 0;
  //submitted: boolean;
  newDSItem: boolean = true;
  saveAndNew: boolean = false;
  newItemWasInitiallySetBillable = false;
  useCustomDSListFiltering = false;
  directService: Partial<ClientDirectServiceModel> = null; //any = {};
  directServiceDefaults: Partial<ClientDirectServiceModel> = { guid: '00000000-0000-0000-0000-000000000000',
                                                               clientGuid: '00000000-0000-0000-0000-000000000000',
                                                               billable: true, deliveredUnits: 1, plannedUnits: 1, unit: 1,
                                                               deliveredDate: this.helperService.castToDateString(this.currentDate, "yyyy-MM-dd"),
                                                               notes: "", cohortIntervention: 0, subTypeInclusionIds: ""};
  loadingDirectServiceDialog: boolean = true;
  eisSubCategoriesBillableData: EISSubCategoryDatesModel;
  deliveredDateSubscription: Subscription;
  
  // dialog visible variables
  displayDirectServiceDialog: boolean = false;
  alwaysVisible: boolean = true;
  dsModalRef: DynamicDialogRef;
  getClient = () => { return (!this.clientService) ? this.dsDataService.getClientService() : this.clientService; };
  getClientData = () => { return (!this.clientService) ? this.dsDataService?.clientData : this.clientService?.clientData; };
  //getClientCohortStudy = () => { return this.getClientData()?.cohortStudy ?? false; }; // this will be replaced once I get a reference to a client object from Den
  getClientCohortStudy = () => { return this.getClientData()?.cohortStudyLatest ?? false; };
  getCurrentDate = () => { var dt = new Date(); dt.setHours(0,0,0,0); return dt; };
  getMonthValue = () => this.dsForm.get('dsMonth').value;
  setMonthValue = (value) => this.dsForm.get('dsMonth').setValue(value);
  getYearValue = () => this.dsForm.get('dsYear').value;
  setYearValue = (value) => this.dsForm.get('dsYear').setValue(value);
  getSubTypeValue = () => parseInt(isNaN(this.dsForm?.get('subId')?.value) ? 0 : (this.dsForm?.get('subId')?.value ?? 0));
  getFilterOption = () => this.dsForm?.get('filterOption')?.value ?? 1;
  getFilterSelection = () => this.dsForm?.get('filterSelection')?.value ?? DirectServiceFilterSelectionChoices.CurrentMonth;
  setFilterSelection = (value) => this.dsForm?.get('filterSelection').setValue(value ?? DirectServiceFilterSelectionChoices.CurrentMonth);
  // getSubTypeInclusions = () => this.dsForm.get('dsSubTypeInclusion').value;
  qualifyForAssessmentById = (subTypeAssessmentId) => { return (this.providerService?.getProviderOptions().useAssessment && subTypeAssessmentId > 0); }
  qualifyForAssessment = () => { return (this.providerService?.getProviderOptions().useAssessment && this.commonDataService?.getSubType(this.getSubTypeValue())?.assessmentTypeId > 0); }
  // qualifyForRiskReduction = () => { return (this.commonDataService.containsAnyRiskReductionCounseling(this.getServiceValue(), this.getSubTypeValue())); }
  // getUnbillableReasonDescription = () => { let list: Array<number> = [ UnbillableReason.Billable, UnbillableReason.UserMarkUnbillable, UnbillableReason.UserEnteredNotBillable ];
  //                                          return (this.getUnbillableReason() > 0 && !list.includes(this.getUnbillableReason())) ? 
  //                                          ('*Service is not billable due to ' + this.commonDataService.getDescription("UnbillableReason", this.getUnbillableReason())) : 
  //                                          null; }
  hasInitialAssessmentChanged = () => { return (this.assessmentTypeIdInitialValue != this.commonDataService.getSubType(this.getSubTypeValue())?.assessmentTypeId) }
  hasMissingRequiredFieldErrors = () => { return !!this.clientService.clientRequiredFieldErrors; }
  usingCustomDSListFiltering = () => { return (this.getFilterOption() == 2);}

  constructor(
              
              private app: AppContextService, 
              private fb: UntypedFormBuilder, 
              private dsDataService: ClientDirectServicesService, 
              private commonDataService: CommonDataService,
              private userService: UserService,
              private providerService: ProviderService,
              public  clientService: ClientFormService,
              private datePipe: DatePipe,
              private helperService: HelperClassService,
              private confirmationService: ConfirmationService,
              private messageService: MessageService,
              public  dialogService: DialogService,
              
              )
  {
      var currentMonth = (getMonth(new Date()) + 1).toString(); //this.months[0].code
      this.dsForm = fb.group({
        dsMonth: [currentMonth],
        dsYear: [this.years[0]],
        filterOption: [1],
        filterSelection: [DirectServiceFilterSelectionChoices.CurrentMonth],
        // guid: ['00000000-0000-0000-0000-000000000000'],
        // clientGuid: ['00000000-0000-0000-0000-000000000000'],
        // dsFunder: [],
        // funder: [0],
        // contractGuid: ['00000000-0000-0000-0000-000000000000'],
        // employeeId: [this.employeeId],      
        // serviceId: [this.serviceId],
        // subId: ['0'],
        // dsSubTypeInclusion: [],
        // subTypeInclusionIds: [''],
        // paySourceId: ['0'],
        // paySourceReasonId: [0],
        // billable: [true],
        // plannedUnits: [1],
        // deliveredUnits: [1], //{ validators: [Validators.required], updateOn: 'blur' }
        // dsUnit: [{value: '', disabled: true}],
        // deliveredDate: [this.currentDate],
        // barrier: [0],
        // notes: [''],
        // cohortIntervention: [0],
        // unbillableReason: [],
        // userId: [0]
      },
      {
        // validators: [this.barrierValidator(), this.unitCostValidator(), this.interventionValidator(), this.medicaidExpansionValidator(),
        //              this.firstEISSubTypeDateValidator(), this.firstBillableEISSubTypeDateValidator(), this.paySourceBillableValidator(),
        //              this.emergencyFinancialOtherAllowedValidator(), this.directServiceFundedValidator(), this.assessmentValidator()],
        // asyncValidators: [this.billableTimePeriodValidator(), this.hopwaWorksheetExpiredValidator(), this.hopwaWorksheetQualifiedValidator(),
        //                   this.medicalCaseManagementDataValidator(), this.directServiceBillableByFunderBillingRestrictionEncounterValidator(),
        //                   this.validAgeForIndeterminateStatusValidator(), this.moreThan4UnitsIn12MonthsValidator()]
        //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
      });
      
      this.clientGUID = this.clientService.clientData.guid; //this.route.snapshot.params.guid;
      // if (!this.providerService.data) {
      //   this.standAloneLogin();
                           
      // }
      // else {
      //   this.initializeData();
      // }


      this.initializeData();
  }

  ngOnInit(): void {
  }

  // async standAloneLogin() {
  //   var user = "msavaryn@hotmail.com";
  //   var password = "Dallas99";
  //   try {
  //     this.authService.SignIn(user, password);
  //     if(this.authService.isUserAuthenticated) {
  //         //this.loginService.validate("marks", "Dallas99").subscribe({
  //         this.loginService.validate(user, password).subscribe({
  //                           next: (resp) => { console.log(resp) },
  //                           error: (error) => { console.log(error) },
  //                           complete: () => { 
  //                                 // this happens when routing directly to ClientDirectServicesComponent for testing and not routing to AppMainComponent
  //                                 // Provider ID 1 testing guids "F2A2CCD6-C1A2-43B6-A42C-67138FAE77B5"; //"fd4c19a9-79de-40b7-976b-3738ef9571c5"; // for testing isDevMode()
  //                                 // Provider ID 94 testing guids "96489FB6-D0FA-4701-B034-1498607F25AA"
  //                                 if (!this.clientGUID) this.clientGUID = "96489FB6-D0FA-4701-B034-1498607F25AA";
  //                                 if (!this.clientService?.clientData) this.dsDataService.loadMockClient(this.clientGUID); else this.dsDataService.setClientService(this.clientService);

  //                                 this.initializeData();
  //                                 //if (!!this.providerService.data) console.log("provider data loaded");
  //                                 //console.log(this.userService.CurrentProviderID);
  //                               }});
  //     }
  //   }
  //   catch (error) {
  //     console.error(error);
  //   }
  // }

  async initializeData() {
    // funder is same as contracts
    this.directServiceDefaults.month = this.months[0]?.code;
    this.directServiceDefaults.year = this.years[0];
    this.directServiceDefaults.clientGuid = this.clientGUID;

    var result = await lastValueFrom(this.userService.GetUserConfigValue('CHAMP.Registry.Filters.DirectServiceStandardFilter'));
    if (!!result?.keyValue) this.setFilterSelection(result.keyValue);
    else this.setFilterSelection(DirectServiceFilterSelectionChoices.CurrentMonth);
    this.fetchDirectServiceGridData();
    
    //this.userService.GetUserConfigValue('CHAMP.Registry.Filters.DirectServiceStandardFilter').subscribe( (result) =>
    // { 
    //   if (result)
    //   { 
    //     this.setFilterSelection(result.keyValue);
    //   }
    //   this.fetchDirectServiceGridData();
    // });

    //// this.fetchDirectServiceGridData(); //populate grid

    //// Assessment/Screening fields
    //// this.assessmentFunders = this.commonDataService.getCommonSetByName("Funder", true);
    //// this.assessmentServiceTypes = this.commonDataService.getServiceCategories(true);
    //// this.assessmentEmployees = this.providerService.getProviderEmployeeList(true);
  }

  // resetting fields is handled by the assignment to directServiceDefaults in open and edit methods
  setFieldValues() {
    this.setMonthValue(this.directService.month);
    this.setYearValue(this.directService.year);

    //var dsGuid = this.directService.guid;
    //if (this.newDSItem) dsGuid = uuidv4();
    this.dsForm.get('guid').setValue(this.directService.guid);
    this.dsForm.get('clientGuid').setValue(this.clientGUID);

    this.contractFunders = this.providerService.getProviderContractFunderItems(this.helperService.convertToDate(this.directService.deliveredDate, "YYYY-MM-DD"), true);
    this.directService.contractFunder = this.contractFunders.find(v => v.keys.funderId == this.directService.funder && 
                                                                       v.keys.contractGuid == this.directService.contractGuid)
    if (!this.directService.contractFunder?.keys) this.directService.contractFunder = this.contractFunders[0];
    this.assessmentTypeIdInitialValue = this.commonDataService.getSubType(this.getSubTypeValue())?.assessmentTypeId;

    //loop through and set sub type inclusions
    this.subTypeInclusionsSelectedItems.length = 0;
    if (this.directService.subTypeInclusionIds?.length > 0) {
      this.directService.subTypeInclusionIds.split(", ")
          .forEach( (subId) => {
              var subTypeInclusionItem = this.subTypeInclusions.find(x => x.id == subId);
              if (subTypeInclusionItem != null) this.subTypeInclusionsSelectedItems.push(subTypeInclusionItem);
          });
      //don't need this because of the 2 way binding in the html
      //this.dsForm.get('dsSubTypeInclusion') = this.subTypeInclusionsSelectedItems;
    }
 }

  onChangeFilterOption(option: number) {
    //this.useCustomDSListFiltering = (option == 2);
    //this.setFilterOption(option);
    this.fetchDirectServiceGridData();
  }

  fetchDirectServiceGridData() {
    var request: ClientDirectServiceRequestModel =
    {  
        providerID: this.userService.CurrentProviderID,
        clientGuid: this.clientGUID, 
        dsGuid:null,
        month: parseInt(this.getMonthValue()), 
        year: parseInt(this.getYearValue()),
        filterSelectionChoice: this.getFilterOption() == 2 ? DirectServiceFilterSelectionChoices.MonthAndYear: this.getFilterSelection()
    };

    // if standard filter option was selected then set the correct option to pass to the API call
    if (!this.usingCustomDSListFiltering()) {
      request.filterSelectionChoice = this.getFilterSelection();
      // also save config setting to DB
    }
    else {
      if (isNaN(request.month)) { alert("Cannot refresh direct service grid, Invalid month"); return; }
      if (isNaN(request.year)) { alert("Cannot refresh direct service grid, Invalid year"); return; }
    }

    // this may have to change after adding new client list date range filters
    //this.contractDate = new Date(request.month.toString() + '-01-' + request.year.toString())
    //this.contractFunders = this.providerService.getProviderContractFunderItems(this.contractDate, true);
    
    this.clientDirectServices$ = this.dsDataService.getDirectServices(request)
  }


  deleteDirectService(directService: ClientDirectServiceModel){
    var request: ClientDirectServiceRequestModel =
    {  
        providerID: this.userService.CurrentProviderID,
        clientGuid: this.clientGUID, 
        dsGuid:directService.guid,
        month: parseInt(this.getMonthValue()), 
        year: parseInt(this.getYearValue()),
        filterSelectionChoice: this.getFilterOption() == 2 ? DirectServiceFilterSelectionChoices.MonthAndYear: this.getFilterSelection()
    };

   this.dsDataService.deleteDirectService(request).subscribe(
      {
        next: (data: any) => {
          this.messageService.add({severity:'success', summary: 'Successful', detail: 'Service Deleted', life: this.app.messageDisplayTime});
        },
        error: (error) => {
          this.app.userServ.LogError(error);
          //this.messageService.add({ severity: 'error', summary: error, detail: 'Failed to load employees' });
          this.messageService.add({severity: "error", summary: "Error", detail: "Delete failed", life: this.app.messageDisplayTime});
        },
        complete:() => {
        }   

      });
  }

  onEditDirectService(directService: ClientDirectServiceModel) {
    // this.loadingDirectServiceDialog = true;
    // this.newDSItem = false;
    // this.getClient().resetRequiredFields();
    // this.directService = {...this.directServiceDefaults};
    // this.directService = {...directService};
    // // convert the string date format to a real date
    // this.directService.deliveredDate = parseISO(this.directService.deliveredDate);
    // this.setFieldValues();

    // if (this.clientService.readonly)  this.dsForm.disable();
    // this.displayDirectServiceDialog = this.alwaysVisible = true;
    // this.loadingDirectServiceDialog = false;

    this.openDirectServiceModal(directService);

  }  

  openDirectServiceModal(ds:any){
    //console.log(mcm);
    this.dsModalRef = this.dialogService.open(DirectServiceComponent, {
      header: 'Direct Service Information',
      width: '60rem',
      //height:'40 rem',
      //contentStyle: {"max-height": "31rem", "overflow": "visible"},
      data: {
          clientGuid:this.clientService.clientData.guid,
          guid: ds?.guid,
          newClient: false
      }
    });

    // Need this to keep track of pop ups tp log out user
    this.app.overLayMangr.add(this.dsModalRef, 'dialogRef');

    this.dsModalRef.onClose.subscribe(
      {
        next:  async (result) => {
          this.fetchDirectServiceGridData();
          this.app.overLayMangr.remove(this.dsModalRef);
        },
        error:(err) => {
          this.helperService.displayError(this.messageService, "Fatal error occurred when loading a non RW Visit. Please contact CHAMP support:", err, true);
        },
        complete:()=> {
        }

    } );
  }

  onChangeMonth(value) {
    this.directServiceDefaults.month = value; //this.getMonthValue();
    this.fetchDirectServiceGridData();
  }

  onChangeYear(value) {
    this.directServiceDefaults.year = value; //this.getYearValue();
    this.fetchDirectServiceGridData();
  }

  onChangeFilterSelection(value) {
    this.userService.SaveUserConfigValue('CHAMP.Registry.Filters.DirectServiceStandardFilter', value.toString());
    this.fetchDirectServiceGridData();
  }

  onOpenNewDirectServiceDialog() {
    this.dsModalRef = this.dialogService.open(DirectServiceComponent, {
      header: 'Direct Service Information',
      width: '60rem',
      //height:'40 rem',
      //contentStyle: {"max-height": "31rem", "overflow": "visible"},
      data: {
          clientGuid: this.clientService.clientData.guid,
          newClient: true
      }
    });

    this.app.overLayMangr.add(this.dsModalRef, 'dialogRef');

    this.dsModalRef.onClose.subscribe(
      {
        next:  async (result) => {
          this.fetchDirectServiceGridData();
          this.app.overLayMangr.remove(this.dsModalRef);
        },
        error:(err) => {
          this.helperService.displayError(this.messageService, "Fatal error occurred when loading a direct service. Please contact CHAMP support:", err, true);
        },
        complete:()=> {
        }

    } );
  }

  onDeleteDirectService(event: Event, directService: ClientDirectServiceModel) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete the service ' + directService.serviceDescription + '?',
      header: 'Confirm',
      icon: 'pi pi-info-circle',
      defaultFocus:'reject',
      acceptLabel:'No',
      rejectLabel:'Yes',
      
      accept: () => {
        this.messageService.add({severity: "error", summary: "Aborted", detail: "Delete canceled", life: 10000});
      },
      reject: () => {
          
          this.deleteDirectService(directService);
          }
      });
  }

  onClickShowAssessment(selectedDirectService: Partial<ClientDirectServiceModel>, allowEdit: boolean) {
    if (!!selectedDirectService.clientAssessment || !!selectedDirectService.clientReferral) {
      this.assessmentSub.onShowDialog(selectedDirectService, allowEdit);
      //this.loadAssessment(selectedDirectService);
      //this.displayAssessmentDialog = true;
    }
    else {
      this.messageService.add({severity: "error", summary: "Aborted", detail: "Currently, no Assessment entries exist for the selected direct service!", life: 2000});
    }
  }

  onClickShowRiskReduction(selectedDirectService: Partial<ClientDirectServiceModel>, allowEdit: boolean) {
    // met with Den on 10/27/21 and he wanted any hyperlinks to show risk reduction to require a risk reduction entry to exist for the given direct service in order to display
    // only when a forced save occurs from the save button do we check client risk reduction history
    if (!!selectedDirectService.clientMedicalRiskReduction) {
      this.riskReductionSub.onShowDialog(selectedDirectService, allowEdit, false);
    }
    else {
      this.messageService.add({severity: "error", summary: "Aborted", detail: "Currently, no Counselings and Included Screenings entries exist for selected direct service!", life: 2000});
    }
  }

  onRiskReductionDialogCompletedNotification(value) {
  }

  onAssessmentDialogCompletedNotification(value) {
  }

}
