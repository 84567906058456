import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
//import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
//import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';

import { ClientMergeComponent } from './admin/client-merge/client-merge.component';
import { ContractMaintenanceComponent } from './admin/contract-maintenance/contract-maintenance.component';
import { ErrorLogComponent } from './admin/error-log/error-log.component';
import { ProviderMaintenanceComponent } from './admin/provider-maintenance/provider-maintenance.component';
import { UnitMaintenanceComponent } from './admin/unit-maintenance/unit-maintenance.component';
import { UserActivityComponent } from './admin/user-activity/user-activity.component';
import { UserMaintenanceComponent } from './admin/user-maintenance/user-maintenance.component';
import { AppMainComponent } from './app.main.component';
import { AuthGuard } from './auth/auth-guard.service';
import { ClientDirectServicesComponent } from './client-direct-services/client-direct-services.component';
import { ClientFormComponent } from './client-form/client-form.component';
import { ClientListComponent } from './client-list/client-list.component';
import { AppLoginComponent } from './login/app.login.component';
import { AppLogoutComponent } from './login/app.logout.component';
import { SwitchProviderComponent } from './menu/switch-provider/switch-provider.component';
import { AboutComponent } from './pages/about.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { ReportsComponent } from './reports/reports.component';
import { BulkGroupComponent } from './bulk-group/bulk-group.component';
import { ClientStatusFollowupComponent } from './client-status-followup/client-status-followup.component';
import { ReferralsComponent } from './client-referrals/referral-edit-list/referral-edit-list.component';
import { ClientReferralsComponent } from './client-referrals/client-referrals.component';
import { ReferralTicketComponent } from './client-referrals/referral-ticket/referral-ticket.component';
import { RsrListComponent } from './rsr/rsr-list/rsr-list.component';

const routes: Routes = [
    {
         path: '', component: AppMainComponent,
         //path: '', component: ClientDirectServicesComponent,
         children: [
            {path: 'client-list', component: ClientListComponent, canActivate: [AuthGuard]},
            {path: 'client-form', component: ClientFormComponent, canActivate: [AuthGuard] },                    
            {path: 'client-form/:guid', component: ClientFormComponent, canActivate: [AuthGuard] },
            {path: 'client-direct-services', component: ClientDirectServicesComponent, canActivate: [AuthGuard] },                    
            {path: 'client-direct-services/:guid', component: ClientDirectServicesComponent, canActivate: [AuthGuard] },
            {path: 'bulk-group', component: BulkGroupComponent, canActivate: [AuthGuard] },
            {path: 'bulk-group/:mode', component: BulkGroupComponent, canActivate: [AuthGuard] },
            //{path: 'client-status-followup', component: ClientStatusFollowupComponent, canActivate: [AuthGuard] },
            {path: 'client-status-followup',  canActivate: [AuthGuard] 
                        , loadComponent: () => import('./client-status-followup/client-status-followup.component').then(c => c.ClientStatusFollowupComponent)},
            

            {path: 'client-referrals', component: ClientReferralsComponent, canActivate: [AuthGuard] },
            {path: 'referral-ticket', component: ReferralTicketComponent, canActivate: [AuthGuard] },
            {path: 'switch-provider', component: SwitchProviderComponent, canActivate: [AuthGuard]},
            {path: 'rsr-list', component: RsrListComponent, canActivate: [AuthGuard]},
            {path: 'reports/:report_id', component: ReportsComponent, canActivate: [AuthGuard]},

            {path: 'user-activity', component: UserActivityComponent, canActivate: [AuthGuard]},
            {path: 'error-log', component: ErrorLogComponent, canActivate: [AuthGuard]},
            {path: 'user-maintenance', component: UserMaintenanceComponent, canActivate: [AuthGuard]},
            {path: 'provider-maintenance', component: ProviderMaintenanceComponent, canActivate: [AuthGuard]},
            {path: 'contract-maintenance', component: ContractMaintenanceComponent, canActivate: [AuthGuard]},
            {path: 'unit-maintenance', component: UnitMaintenanceComponent, canActivate: [AuthGuard]},

            {path: 'about', component: AboutComponent},
            {path: 'login', component: AppLoginComponent},
            {path: 'logout', component: AppLogoutComponent},

            {path: 'test-ua', component: UserActivityComponent},
            {path: 'test-um', component: UserMaintenanceComponent},
            {path: 'test-elogs', component: ErrorLogComponent},
            {path: 'test-prov-main-list', component: ProviderMaintenanceComponent},
            {path: 'test-prov-contract-list', component: ContractMaintenanceComponent},
            {path: 'test-unit', component: UnitMaintenanceComponent},
            {path: 'test-client-merge', component: ClientMergeComponent},
         ]
     },
     //{path: '', component: AppMainComponent},
     {path: 'error', component: AppErrorComponent},
     {path: 'access', component: AppAccessdeniedComponent},
     {path: 'notfound', component: AppNotfoundComponent},
     {path: '**', redirectTo: '/notfound'},
     //{path: '**', redirectTo: ''},
 ];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, 
            { useHash:true,  scrollPositionRestoration: 'enabled' ,
                // Don't perform initial navigation in iframes or popups
              // initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
            } )   
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

   

}


