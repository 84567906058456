<form [formGroup]='behavior_health' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                    <div class="field col-7">Substance Abuse Screening</div>
                    <div class="field col-4"></div>
                    <div class="field col-1">
                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('SAS', 'Substance Abuse Screening')"></button>      
                    </div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12">
                    <div class="formgroup-inline champ-grid">
                        <label>Result of latest Substance Abuse Screening?</label>
                        <label id="idSubstanceAbuseScreeningResultDate">
                            <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientScreeningSubstanceAbuse" 
                            [fieldId]="MasterFields.SubstanceAbuseScreeningResultDate" 
                            [lastUpdated]="this.clientData.clientScreeningSubstanceAbuse?.screeningDate"
                            [formGroup]="behavior_health"></app-client-expired-field>
                        </label>                            
                    </div>
                </div>   

                <div class="field col-12">
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-4">
                            <div class="formgroup-inline champ-grid" >
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idSAResultDate" style="margin-right: 1rem;">Date of Result</label> 
                                <input type="text" #idSAResultDatePicker name="idSAResultDate" autocomplete="off" bsDatepicker formControlName="idSAResultDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idSAResultDate" [maxDate]="maxResultDate" [minDate]="minResultDate"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : behavior_health.controls['idSAResultDate']?.invalid}" 
                                    class="champ-date-picker" pInputText/>
                            </div>
                        </div>

                        <div class="field col-8">
                            <div class="formgroup-inline champ-grid">
                                <div style="margin-left: 1.5rem;">
                                    <fa-icon class="champ-field-required" style="vertical-align: top !important;" [icon]="['fas', 'asterisk']"></fa-icon>
                                    <label>Result:</label>
                                    <p-radioButton formControlName="idSAResult" value=2 label="Negative" [style]="{'margin-left':'15px'}"></p-radioButton>
                                    <p-radioButton formControlName="idSAResult" value=1 label="Positive" [style]="{'margin-left':'15px'}"></p-radioButton>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="field col-2"></div> -->

                        <!-- next row for errors above -->
                        
                        <div class="field col-4">
                            <div *ngIf="behavior_health.controls['idSAResultDate']?.invalid">
                                <div *ngIf="behavior_health.controls['idSAResultDate']?.errors?.required"> 
                                    <small id="idSAResultDate-help" class="p-invalid" style="margin-left: 1rem;">Result Date is required.</small>
                                </div>
                                <div *ngIf="!behavior_health.controls['idSAResultDate']?.errors?.required && !!behavior_health.controls['idSAResultDate']?.errors"> 
                                    <small id="idSAResultDate2-help" class="p-invalid" style="margin-left: 1rem;">{{behavior_health.controls['idSAResultDate']?.errors['invalidDate']}}</small>
                                </div>                                                               
                            </div>
                         </div>

                        <div class="field col-6">
                            <div *ngIf="!!behavior_health.controls['idSAResult']?.errors"> 
                                <small id="idSAResult-help" class="p-invalid" style="margin-left: 2.5rem;">Result is required.</small>
                            </div>
                        </div>

                        <div class="field col-2"></div>
                    </div>

                    <!-- next row -->
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-5">
                            <div class="formgroup-inline champ-grid">
                                <label htmlFor="idSADiagnosis" style="margin-right: 1rem;">Substance Abuse Diagnosis</label>
                            </div>
                        </div>
                        <div class="field col-7">
                            <div class="formgroup-inline champ-grid" [ngClass]="{'disableDiv': disableSubstanceAbuseDiagnosisFields()}">
                                <p-radioButton formControlName="idSADiagnosis" value=2 label="No" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idSADiagnosis" value=1 label="Yes" [style]="{'margin-left':'15px'}"></p-radioButton>
                            </div>
                        </div>
                    </div>

                    <!-- next row -->
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-5">
                            <div class="formgroup-inline champ-grid">
                                <label htmlFor="idSAFollowup" style="margin-right: 1rem;">Substance Abuse Follow-up / Treatment Plan Documented?</label>
                            </div>
                        </div>
                        <div class="field col-7">
                            <div class="formgroup-inline champ-grid" [ngClass]="{'disableDiv': disableSubstanceAbuseDiagnosisFields()}">
                                <p-radioButton formControlName="idSAFollowup" value=2 label="No" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idSAFollowup" value=1 label="Yes" [style]="{'margin-left':'15px'}"></p-radioButton>
                            </div>
                        </div>                        
                    </div>                    
                </div>
            </div>

        </div>
    </div>
</form>

<app-history ></app-history>
