import { Component, OnInit, ViewEncapsulation, isDevMode, Input, ViewChild, ElementRef, ChangeDetectorRef  } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, Form } from '@angular/forms';
import { CommonDataService } from 'src/app/services/common-data.service';
import { CommonDataModel, CommonData, ServiceCategory, SubType, ServiceSubType } from 'src/app/models/common-data.model';
import { Observable, Subject, from, noop } from 'rxjs';
import { LoginService } from 'src/app/login/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { ProviderService } from 'src/app/services/provider.service';
import { ClientFormService} from 'src/app/client-form/services/client-form.service';
import { ClientModel } from 'src/app/models/client.model';
//import { stringify } from '@angular/compiler/src/util';
import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, 
         sortedUniq, sortBy, orderBy, map, merge, uniqBy, remove } from 'lodash';
import { Time, DatePipe } from '@angular/common';
//import { ClientFormHelperService} from 'src/app/client-form/services/client-form-helper.service';
import { ClientFormHelper} from 'src/app/client-form/client-form.helper';
import { DateHelper } from "src/app/common/date-helper";
import { FormValidations} from 'src/app/client-form/validators/form-validators';
import { MedicalYesNo, MedicalComponents } from "src/app/models/enums";
import { Cd4Component } from './cd4/cd4.component';
import { ViralLoadComponent } from './viral-load/viral-load.component';
import { HepatitisBComponent } from './hepatitis-b/hepatitis-b.component';
import { HepatitisCComponent } from './hepatitis-c/hepatitis-c.component';
import { AidsDefineConditionComponent } from './aids-define-condition/aids-define-condition.component';
import { TuberculosisComponent } from './tuberculosis/tuberculosis.component';
import { SyphilisComponent } from './syphilis/syphilis.component';
import { ChlamydiaComponent } from './chlamydia/chlamydia.component';
import { GonorrheaComponent } from './gonorrhea/gonorrhea.component';
import { ToxoplasmaComponent } from './toxoplasma/toxoplasma.component';
import { LipidpanelComponent } from './lipidpanel/lipidpanel.component';
import { CovidComponent } from './covid/covid.component';
import { PharmaAssistanceComponent } from './pharma-assistance/pharma-assistance.component';
import { AntiretroviralTherapiesComponent } from './antiretroviral-therapies/antiretroviral-therapies.component';
import { VaccinationsComponent } from './vaccinations/vaccinations.component';
import { FemaleOnlyComponent } from './female-only/female-only.component';
import { OtherStiComponent } from './other-sti/other-sti.component';

@Component({
  selector: 'app-medical',
  templateUrl: './medical.component.html',
  styleUrls: ['./medical.component.scss']
})
export class MedicalComponent implements OnInit {
  @Input() client: UntypedFormGroup;
  @ViewChild(Cd4Component, { static: false }) cd4Sub: Cd4Component;
  @ViewChild(ViralLoadComponent, { static: false }) viralLoadSub: ViralLoadComponent;
  @ViewChild(HepatitisBComponent, { static: false }) hepatitisBSub: HepatitisBComponent;
  @ViewChild(HepatitisCComponent, { static: false }) hepatitisCSub: HepatitisCComponent;
  @ViewChild(AidsDefineConditionComponent, { static: false }) aidsDefineConditionSub: AidsDefineConditionComponent;
  @ViewChild(TuberculosisComponent, { static: false }) tuberculosisSub: TuberculosisComponent;
  @ViewChild(SyphilisComponent, { static: false }) syphilisSub: SyphilisComponent;
  @ViewChild(ChlamydiaComponent, { static: false }) chlamydiaSub: ChlamydiaComponent;
  @ViewChild(GonorrheaComponent, { static: false }) gonorrheaSub: GonorrheaComponent;
  @ViewChild(ToxoplasmaComponent, { static: false }) toxoplasmaSub: ToxoplasmaComponent;
  @ViewChild(LipidpanelComponent, { static: false }) lipidpanelSub: LipidpanelComponent;
  @ViewChild(CovidComponent, { static: false }) covidSub: CovidComponent;
  @ViewChild(PharmaAssistanceComponent, { static: false }) pharmaAssistanceSub: PharmaAssistanceComponent;
  @ViewChild(AntiretroviralTherapiesComponent, { static: false }) antiretroviralTherapiesSub: AntiretroviralTherapiesComponent;
  @ViewChild(VaccinationsComponent, { static: false }) vaccinationsSub: VaccinationsComponent;
  @ViewChild(FemaleOnlyComponent, { static: false }) femaleOnlySub: FemaleOnlyComponent;
  @ViewChild(OtherStiComponent, { static: false }) otherStiSub: OtherStiComponent;
  
  // if matching by hard coded id
  //@ViewChild('hepatitisB', { static: false }) hepatitisBSub: HepatitisBComponent;

  public loaded:boolean=false;

  clientData: ClientModel;
  clientGUID: string;
  YesnoMedical: CommonData[];
  noReasons: CommonData[];
  MedicalYesNo = MedicalYesNo;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();
  
  constructor(
    private fb: UntypedFormBuilder, 
    private commonDataService:CommonDataService,
    private providerService: ProviderService,
    private userService:UserService,
    private clientService:ClientFormService,
    private datePipe: DatePipe,
    private dateHelper:DateHelper,
    public  validationHelper:FormValidations,
    private cdRef:ChangeDetectorRef
    ) { 
  }

  ngOnInit(): void {
    //console.debug('medical ngOnInit');
  }

  setFieldValues() {
  }

  getModel(type: MedicalComponents) {
    if (type == MedicalComponents.HepatitisB) return this.hepatitisBSub.getModel();
    else if (type == MedicalComponents.HepatitisC) return this.hepatitisCSub.getModel();
    else if (type == MedicalComponents.CD4) return this.cd4Sub.getModel();
    else if (type == MedicalComponents.ViralLoad) return this.viralLoadSub.getModel();
    else if (type == MedicalComponents.Tuberculosis) return this.tuberculosisSub.getModel();
    else if (type == MedicalComponents.Syphilis) return this.syphilisSub.getModel();
    else if (type == MedicalComponents.Chlamydia) return this.chlamydiaSub.getModel();
    else if (type == MedicalComponents.Gonorrhea) return this.gonorrheaSub.getModel();
    else if (type == MedicalComponents.Covid) return this.covidSub.getModel();
    // else if (type == MedicalComponents.Toxoplasma) return this.toxoplasmaSub.getModel()
    // else if (type == MedicalComponents.LipidPanel) return this.lipidpanelSub.getModel();
    else if (type == MedicalComponents.PharmaAssistance) return this.pharmaAssistanceSub.getModel();
    else if (type == MedicalComponents.AntiretroviralTherapy) return this.antiretroviralTherapiesSub.getModel();
    else if (type == MedicalComponents.InfluenzaVaccination) return this.vaccinationsSub.getInfluenzaModel();
    else if (type == MedicalComponents.PneumoccocalVaccination) return this.vaccinationsSub.getPneumoccocalModel();
    else if (type == MedicalComponents.HepatitisBVaccination) return this.vaccinationsSub.getHepatitisBModel();
    else if (type == MedicalComponents.FemalePapSmear) return this.femaleOnlySub.getFemalePapSmearModel();
    else if (type == MedicalComponents.FemalePelvicExam) return this.femaleOnlySub.getFemalePelvicExamModel();
    else if (type == MedicalComponents.FemalePregnancy) return this.femaleOnlySub.getFemalePregnancyModel();
    else if (type == MedicalComponents.AntiretroviralPrevention) return this.femaleOnlySub.getFemaleAntiretroviralPreventionModel();
   
    return null;
  }

  // this is needed because screened is a bool and not a number like the ones above in getModel
  getModel2(type: MedicalComponents) {
    if (type == MedicalComponents.Toxoplasma) return this.toxoplasmaSub.getModel()
    else if (type == MedicalComponents.LipidPanel) return this.lipidpanelSub.getModel();

    return null;    
  }

  getModelArray(type: MedicalComponents) {
    if (type == MedicalComponents.AidsDefiningCondition) return this.aidsDefineConditionSub.getModelArray();
    if (type == MedicalComponents.FemaleBirthResults) return this.femaleOnlySub.getFemaleBirthResultsModelArray();
    if (type == MedicalComponents.OtherSTIResults) return this.otherStiSub.getOtherSTIModelArray();
    
    return null;
  }

}
