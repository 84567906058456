import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { SpinnerService } from '../common/spinner/spinner.service';
import { ProviderService } from '../services/provider.service';
import { UserService } from '../services/user.service';
import { LoginRequest, LoginResponse, SignUpRequest, UserModel, PasswordResetRequest } from './services/login-models';
import { LoginService } from './services/login.service';
import { IdleService } from '../services/idle-service.service';
import { setLocaleDayOfWeek } from 'ngx-bootstrap/chronos/units/day-of-week';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.scss']
})
export class AppLoginComponent implements OnInit {

loginRequest: LoginRequest = {
  email:'', password:''
};

signUpRequest: SignUpRequest = {
  email:'', password:'', passwordConfirm : ''
};

passwordResetRequest: PasswordResetRequest = {
  email:'', currentPassword:'', newPassword : '', newPasswordConfirm : ''
};

loginResponse: LoginResponse[] ;
model: UserModel;
public loginErrorMessage: string = "";
public emailVerified: boolean = true; 
public signUpErrorMessage: string = "";
public resetErrorMessage: string = "";

public showLoginDialog:boolean = true;
public showSignupDialog:boolean = false;
public showPasswordResetDialog:boolean = false;


constructor(
     private loginService: LoginService, 
     private router: Router,
     private providerService: ProviderService,
     private userService: UserService,
     public authService: AuthService,
     public spinnerService:SpinnerService,
     private idleService: IdleService
) { }

ngOnInit(): void {
  this.initializeForm();
}

private initializeForm()
{

}

setCurrentUser() {
  const user :UserModel = JSON.parse (localStorage.getItem('champ-user-data'));
}

 async login() {
    //console.log(this.loginRequest);
    this.emailVerified = true;
    this.loginErrorMessage = "";
    this.spinnerService.On();
    //this.showLoginDialog = false;
    const self = this;
    await this.authService.SignIn(this.loginRequest.email, this.loginRequest.password);
    if(this.authService.isUserAuthenticated){
          if ( !this.authService.IsEmailVerified) {
              this.emailVerified = false;
              this.spinnerService.Off();  
          }
          else
          {
            self.loginService.login(this.loginRequest).subscribe( 
              {
                next: ( response )=> {
                  console.log(response);
                  self.showLoginDialog = false;
                  if  (self.loginService.loggedin )
                      { 
                        self.idleService.reset(); // Reset the idle timer 
                        self.router.navigate(['/client-list']);
                      }
                      else{
                        self.loginService.loggedin = false;
                        self.spinnerService.Off();
                        self.showLoginDialog = true;
                      }
                }, 
                error: (e)=> {
                  
                  self.loginErrorMessage = e;
                  self.loginService.loggedin = false;
                  self.spinnerService.Off();
                  self.showLoginDialog = true;
                  //self.loginErrorMessage = self.authService.errorMessage;
                } ,  
                complete:() => {
                 
                  }
                }
            );   //.pipe(shareReplay());   
      }
    }
    else{
      this.spinnerService.Off();
      this.showLoginDialog = true;
      this.loginErrorMessage = this.authService.errorMessage;
      //console.log('error');
    }
  }

  cancelLogin(){
    this.loginService.setLoginStatus(false,false,null, true);
    this.showLoginDialog = false;
    this.router.navigate(['/logout']); 

  }

  showSignup(){
      this.showLoginDialog = false;
      this.showSignupDialog = true;
  }
 
  completeSignup(){
      if ( this.signUpRequest.password !=  this.signUpRequest.passwordConfirm) {
        this.signUpErrorMessage = "Password annd password confirmation do not match."
        return;
      }
      this.spinnerService.On();
      this.authService.SignUp(this.signUpRequest.email, this.signUpRequest.password);
      this.showSignupDialog = false;
      this.loginErrorMessage = "A verification email has been send to your address. Verify your email prior to logging in."
      this.showLoginDialog = true;
      this.spinnerService.Off();
  }

  cancelSignup(){
      this.showSignupDialog = false;
      this.showLoginDialog = true;
      this.showPasswordResetDialog = false;
  }

  cancelReset(){
    this.showSignupDialog = false;
    this.showLoginDialog = true;
    this.showPasswordResetDialog = false;
}

  resendEmailVerification(){
      this.authService.ResendVerificationMail(this.loginRequest.email, this.loginRequest.password);
  }

  showPasswordReset(){
      this.showLoginDialog = false;
      this.showSignupDialog = false;
      this.showPasswordResetDialog = true;

    }

  sendPasswordResetEmail(){
      this.spinnerService.On();
      this.authService.sendPasswardResetEmail(this.loginRequest.email);
      if (this.authService.errorMessage?.length == 0 )
      {
        this.loginErrorMessage = "A password reset email has been send to your address. Use the link in the email to change your password and then login into CHAMP."
        this.showPasswordResetDialog = false;
        this.showLoginDialog = true;
      }
      else
      {
        this.resetErrorMessage = this.authService.errorMessage;
      }

      this.spinnerService.Off();
    }

}
