import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, AsyncValidatorFn, ValidationErrors, UntypedFormArray } from '@angular/forms';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { lastValueFrom } from 'rxjs';
import { getWeekYearWithOptions } from 'date-fns/fp';
import { ReportsService } from './services/reports.service';
import { ReportConfigGroupListModel, ReportConfigParamSQLValueModel, ReportConfigReportParamsModel, ReportConfigReportsModel, ReportParameterControlType, ReportParameterDataType, ReportParameterModel, ReportsModel, ReportsRequestModel, ReportMenuItem } from '../models/reports.model';
import { UserService } from '../services/user.service';
import { report } from 'process';
import { LoginService } from '../login/services/login.service';
import { AuthService } from '../auth/auth.service';
import { fstatSync } from 'fs';
import { partial } from 'lodash';
import { format, addDays, addMonths, subMonths, parseISO, formatISO, toDate, getDate, startOfMonth, endOfDay, subDays, sub, getTime, getMonth } from 'date-fns';
import { fr } from 'date-fns/locale';
import { SpinnerService } from '../common/spinner/spinner.service';
import { TreeNode } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ApiException} from '../models/errors.model'
//import { NullVisitor } from '@angular/compiler/src/render3/r3_ast';
import { CommonDataService } from '../services/common-data.service';
import { CommonData, ServiceCategory } from '../models/common-data.model';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers:[MessageService]
})
export class ReportsComponent implements OnInit {

  reportId: number;
  private params: any;
  reportData:ReportConfigReportsModel;
  reportList:ReportConfigGroupListModel[];
  public form:UntypedFormGroup;
  public showSpinner: boolean = false;
  nodes: TreeNode[] = [];
  selectedReport: TreeNode;
  menuId:number;
  reportMenu:ReportMenuItem[]=[];

  getReportsMenuId = () => { return this.form?.controls['reportsMenuId'].value;  }
  get parameters () { return this.form.controls['parameters'] as UntypedFormArray;}
  get controlTypeEnum () { return  ReportParameterControlType; }
  get dataTypeEnum () { return  ReportParameterDataType; }
  
  constructor(private reportService: ReportsService,
              public userService:UserService, 
              public config: DynamicDialogConfig,
              private loginService: LoginService,    
              private authService: AuthService,
              private messageService: MessageService,
              private fb: UntypedFormBuilder,
              private diagRef: DynamicDialogRef,
              private spinnerService: SpinnerService,
              private commonDataService: CommonDataService
              ) { }  
            
  ngOnInit(): void {

    this.form = this.fb.group({
      reportsMenuId:(1),
      parameters: this.fb.array([])
    });

    this.reportMenu.push( {id: 1, menuName:"Provider Reports"});
    if (this.userService.hasAdminPermission || this.userService.hasDHHSPermission){
        this.reportMenu.push( {id: 2, menuName:"Grantee Reports"});
        this.reportMenu.push( {id: 3, menuName:"System Reports"});
    }
    
    //this.form.addControl('reportsMenu', this.fb.control('1', [Validators.required])); 

    this.reportId = this.config.data?.reportId;

    // if(!this.authService.isUserAuthenticated){
    //   this.loginService.standAloneLogin().then(x=> {
    //       this.loadReportTreeMenu();
    //       this.loadData()
    //     });
    // }
    // else {
    //   this.loadReportTreeMenu();
    //   this.loadData();  
    // }

    this.loadReportTreeMenu();
    this.loadData();  
  }

  onReportMenuChange(){
    this.loadReportTreeMenu();
  }

  loadReportTreeMenu(){

    this.reportService.getReportList( this.getReportsMenuId()  ).subscribe(
      {
        next: (result: ReportConfigGroupListModel[]) => {
          this.reportList = result;
          //let treeData = [];
          this.nodes = [];

          this.reportList.forEach( reportGroup => {
              let reportsInGroup = [];
              if (reportGroup.reports?.length > 0 )
              {
                  reportGroup.reports.forEach(report => {
                        let rnode = {key: report.reportId.toString(), label: report?.reportNameLong, data:report?.reportDesc, type: 'text'}
                        reportsInGroup.push(rnode);
                      });

                      const node: TreeNode = { key: reportGroup.groupId.toString(),
                      label: reportGroup.groupName.toString(),
                      children: reportsInGroup};

                      this.nodes.push(node);
              }
           });
           console.log(this.nodes);
           this.expandAll();
           this.loadReport(this.reportList[0].reports[0].reportId);

          //  if (treeData.length > 0 ){
          //  }

        },
        error: (e) => {
          this.userService.LogError(e) 
        }
        ,
        complete:() => { }
      }     
     );
  }

  expandAll(){
    this.nodes.forEach( node => {
        this.expandRecursive(node, true);
    } );
  }


nodeSelect(event) {
    //this.messageService.add({severity: 'info', summary: 'Node Selected', detail: event.node.label});

    this.loadReport(event.node.key);
}

private expandRecursive(node:TreeNode, isExpand:boolean){
  node.expanded = isExpand;
  if (node.children){
      node.children.forEach( childNode => {
          this.expandRecursive(childNode, isExpand);
      } );
  }
}
  
loadData(){

    this.loadReport(this.reportId); 
  }

addParamatersToForm() {
    this.reportData?.params.forEach(async param => {
        //console.log(param.paramName);
        if ( param.paramName.toLowerCase() == "providerid") { 
          param.defaultValue = this.userService.CurrentProviderID.toString();
        }

        // Check for previously used contract guid
        if ( param.paramName.toLowerCase() == "contractguid") { 
              param.defaultValue = this.userService.CurrentContract.toUpperCase();
        }

        if ( param.paramName.toLowerCase() == "servicecat") { 
          //console.log("here");
           let serviceCategories = this.commonDataService.getServiceCategories(99999, false);
           let paramValues: ReportConfigParamSQLValueModel[]= [];
           paramValues.push({value: "0", description: "All Categories", valueFilter:""});
           paramValues.push({value: "1", description: "None", valueFilter:""});
           serviceCategories.forEach(serviceCat => {
            paramValues.push({value: serviceCat.id.toString(), description: serviceCat.description, valueFilter:""});
           });
          param.paramValues = paramValues;
        }

        // If client profile report, set eis param to false
        if ( param.paramName.toLowerCase() == "eis" && this.reportId == 48) { 
          param.defaultValue = '0';
          param.currentValue = '0';
          //this.reportData.reportName = "P"

        }

        // if ( param.paramName.toLowerCase() == "funder") { 
        //   console.log("here");
        // }

        // if ( param.paramName.toLowerCase() == "enddate") { 
        //   console.log("here");
        // }

        // if ( param.paramName.toLowerCase() == "cycle") { 
        //   console.log("here");
        // }        

        // if ( param.paramName.toLowerCase() == "clientid") { 
        //   console.log("here");
        // }        

        if ( param.paramName.toLowerCase() == "clinicid" || param.paramName.toLowerCase() == "clinic") { 
              param.defaultValue = this.userService.CurrentClinicID.toString();
        }

        if ( param.paramName.toLowerCase() == "clientaltid") { 
          // Change this Param to be a dropdown search, Different from 3.5
          param.paramControlTypeId = this.controlTypeEnum.ComboEditBox;
        }

       // if a current value is null at this point, set to defaul value t
       if (param.defaultValue == null && param.paramDefaultValue != null && param.paramDefaultValue.length > 0) {
         param.defaultValue = param.paramDefaultValue;
       }

        // Set default combo to first item 
        if (param.paramControlTypeId == this.controlTypeEnum.ComboBox ) { 
          if ( param.defaultValue == null && param.paramValues != null ){
              param.currentValue = param?.paramValues[0]?.value 
              if (param.defaultValue == '0' ) param.defaultValue = param.paramValues[0]?.value 
          }
        }

        const newparam = this.fb.group({
          paramId: [param.paramId],
          paramName: [param.paramName],
          label_text: [param.paramLabelText],
          paramVisible:[param.paramVisible ],
          value: [param.defaultValue ?? param.currentValue, Validators.required],
          dataType: [param.paramDataTypeId],
          paramType: [param.paramControlTypeId],
          defaultValue: [param.defaultValue],
          paramValues: [param.paramValues],
          paramDependantParamId:[param.paramDependantParamId]
        })

        this.parameters.push(newparam);
        
        // Post events  dependant combos 
        if ( param.paramName.toLowerCase() == "providerid" || param.paramName.toLowerCase() == "contractguid") { 
          //param.defaultValue = this.userService.CurrentProviderID.toString();
          this.onChangeParamValue(param.defaultValue, newparam);
        }

    });

    let contractParam: ReportConfigReportParamsModel[] = this.reportData?.params?.filter(x=> x.paramName.toLowerCase() == "contractguid");
    if (contractParam?.length > 0 ) this.getContractDates(contractParam[0]?.defaultValue)

  }

  loadReport(reportId: number) {
    this.reportId = reportId;
    let request : ReportsRequestModel = {
      userId : this.userService.userID,
      providerId : this.userService.CurrentProviderID,
      clinicId : this.userService.CurrentClinicID,
      reportId : reportId,
      emaId : 1
   };


   this.reportService.getReport(request).subscribe(
        {
          next: (result: ReportConfigReportsModel) => {
            this.reportData = result;
            this.parameters.clear();
            
            // this.form = this.fb.group({
            //   parameters: this.fb.array([])
            // });

            this.addParamatersToForm();

            let headerElement = document.getElementsByClassName('p-dialog-title');
            //headerElement[0].innerHTML =  "Report: " +  this.reportData.reportNameLong;
            headerElement[0].innerHTML =  "Reports Manager";
          },
          error: (e) => {
            this.userService.LogError(e);
            this.messageService.add({severity: 'error', summary: 'Error', detail: e.error?.message});
          }
          ,
          complete:() => {  
                          // this.onChangeClient(this.clientList[0].value.client);
                        }
        }     
       );
  }

  async onChangeParamValue( value: string, param:UntypedFormGroup){
     
    let params = this.reportData.params;
    let paramId = param.controls['paramId'].value;
    let pvalue = param.controls['value'].value;
    let paramDepId = param.controls['paramDependantParamId'].value;

    var currentParam = this.reportData.params.filter(x=> x.paramId == paramId);
    var depParams = this.reportData.params.filter(x=> x.paramId == paramDepId);

    currentParam[0].currentValue = pvalue;  // Set current value back to report data array 

    // this.reportData.params.forEach( param => {
    //     console.log(param);
    //   });

    if ( currentParam[0].paramEventName == 'cboProviderFillContracts_SelectedIndexChanged'){
      let paramvalues = await lastValueFrom( this.reportService.getContractsForProvider(pvalue));
      const index = this.parameters.controls.findIndex(fg => {
        return fg.value.paramId == paramDepId
      });
      let fa = this.form.get('parameters') as UntypedFormArray;
      fa.controls[index].get('paramValues').setValue(paramvalues);
    }

    if ( currentParam[0].paramEventName == 'cboServiceCategoryFillSubType_SelectedIndexChanged'){
      let subTypes  = this.commonDataService.getSubTypesByService(pvalue, 99999, false, false);
      let paramValues: ReportConfigParamSQLValueModel[]= [];
      paramValues.push({value: "0", description: "All SubTypes", valueFilter:""});
      subTypes.forEach(subTypes => {
        paramValues.push({value: subTypes.id.toString(), description: subTypes.description, valueFilter:""});
      });
      //param.paramValues = paramValues;
      


      const index = this.parameters.controls.findIndex(fg => {
        return fg.value.paramId == paramDepId
      });

      let fa = this.form.get('parameters') as UntypedFormArray;
      fa.controls[index].get('paramValues').setValue(paramValues);
    }

    if ( currentParam[0].paramEventName == 'cboContractFillClients_SelectedIndexChanged'){
      let paramvalues = await lastValueFrom( this.reportService.getClientList(pvalue));
      const index = this.parameters.controls.findIndex(fg => {
        return fg.value.paramId == paramDepId
      });
      let fa = this.form.get('parameters') as UntypedFormArray;
      fa.controls[index].get('paramValues').setValue(paramvalues);
      fa.controls[index].get('value').setValue("");
    }

    // HardCoding for Required Fields Reports Differences from 3.5
    let reqFieldsReports : number[]= [55,81,111,110];
    if (currentParam[0].paramName.toLowerCase() == 'contractguid'  &&  reqFieldsReports.includes(currentParam[0].reportId) ){
      let paramvalues = await lastValueFrom( this.reportService.getClientList(pvalue));

      paramvalues.unshift( { value: " ", description: " ",valueFilter:" "});
      const index = this.parameters.controls.findIndex(fg => {
        return fg.value.paramName.toLowerCase() == "clientaltid"
      });
      let fa = this.form.get('parameters') as UntypedFormArray;
      fa.controls[index].get('paramValues').setValue(paramvalues);
      fa.controls[index].get('value').setValue(" ");
    }


    //param.controls['paramValues'].setValue(depParams[0].paramValues.filter( x=>  x.valueFilter  == pvalue) );
    //depParams[0].paramValues  = depParams[0].paramValues.filter( x=>  x.valueFilter  == pvalue);
    //cboProviderFillContracts_SelectedIndexChanged

    // Set contract Dates if contract selected 
    if ( currentParam[0].paramName.toLocaleLowerCase() == 'contractguid'){
      this.getContractDates(currentParam[0].currentValue)
    }
  }

  async getContractDates(guid:string){
    let contract = await lastValueFrom( this.reportService.getContractsDateByGuid(guid));
    if ( contract != null ){
      this.parameters.controls.forEach((fg: UntypedFormGroup) => {  //() 
        if (fg.controls.paramName.value.toLowerCase() == "startdate")
        {
          fg.controls.value.setValue(new Date(contract.startDate));
        }
        if (fg.controls.paramName.value.toLowerCase() == "enddate")
        {
          fg.controls.value.setValue(new Date(contract.endDate));
        }
    });
    }
  }

  async getClientsList(guid:string){
    return await lastValueFrom( this.reportService.getClientList(guid));
  }

  getComboBoxValues(param:UntypedFormControl){

      let  filterValue:string ;
      console.log(param['paramValues'] );
      if (filterValue != "")
        return param['paramValues']?.value 
      else 
        return param['paramValues']?.value.filter(x => x.filterValue == filterValue);

  }

  private blobToString(blob): string {
    const url = URL.createObjectURL(blob);
    let xmlRequest = new XMLHttpRequest();
    xmlRequest.open('GET', url, false);
    xmlRequest.send();
    URL.revokeObjectURL(url);
    return xmlRequest.responseText;
}


  onRunReport()
  {
    var reportModel : ReportsModel = { reportId: this.reportData.reportId ,reportName: this.reportData.reportName, 
                                      reportFormat:this.reportData.reportFormat, parameters:[] };

    this.parameters.controls.forEach((fg: UntypedFormGroup) => {  
       let fc = fg.controls;
       let controlValue  = fc.value.value;

        if (fc.dataType.value == this.dataTypeEnum.DateTime) {
          let dateValue: Date = new Date(controlValue);
          controlValue = format(dateValue, 'MM/dd/yyyy');
        
        } 

        // Boolean
        if (fc.dataType.value == this.dataTypeEnum.Boolean) { 
            //controlValue = controlValue =='False' ? '0' : '1'
            controlValue = controlValue =='0' ? 'False' : 'True';
        } 

        if (fc.dataType.value == this.dataTypeEnum.String) { 
          controlValue = controlValue  ?? "";
        } 

        console.log( "(" + fc.paramId.value +")" + fc.paramName.value  + "  VALUE: " + controlValue);

       var reportParam: ReportParameterModel =  {name: fc.paramName.value, value: controlValue};
       reportModel.parameters.push(reportParam);
    });

    this.spinnerService.On();
    const runReport$  = this.reportService.runReport(reportModel).subscribe(
      {
        next: (result: any) => {
          let blob = new Blob([result], { type: 'application/pdf' });
          let pdfUrl = window.URL.createObjectURL(blob);

          var File_link = document.createElement('a');
          File_link.href = pdfUrl;
          //   TO OPEN PDF ON BROWSER IN NEW TAB
          //window.open(pdfUrl, '_blank');
          
          var reportName  = this.reportData.reportName;
          // Hard Coded Renames
          if ( this.reportData.reportId == 48 ){
            reportName = 'ClientProfile';
          }



          //   TO DOWNLOAD PDF TO YOUR COMPUTER
          if (this.reportData.reportFormat.toLowerCase() =="pdf"){
            File_link.download = (reportName + format(new Date(), 'yyyyMMddHHmmss')+ ".pdf").toLowerCase();
          }
          else if (this.reportData.reportFormat.toLowerCase() =="excel"){
            File_link.download = (reportName + format(new Date(), 'yyyyMMddHHmmss')+ ".xlsx").toLowerCase();
          }
          File_link.click();
        },
        error: (e: HttpErrorResponse) => {
          this.userService.LogError(e);
          this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.message, sticky: true});
          this.spinnerService.Off();
        }
        ,
        complete:() => {  
                        this.spinnerService.Off();
                        this.messageService.add({severity: 'info', summary: 'Completed', detail: "Report executed sucessfully and can be found in downloads window."});
                      }
      }     
     );


    // this.spinnerService.showSpinnerUntilCompleted(reunReport$).subscribe();
  

  }
}
