import { ClientRequiredFieldErrorModel } from "./client.model";

export interface BulkGroupListRequestModel {
    bulkGroupIndicator: number;
    providerID: number;
    month: number | null;
    year: number | null;
}

export interface BulkGroupListResponseModel {
    bulkGroupGuid: string | null;
    bulkGroupDescription: string;
}

export interface BulkGroupRequestModel {
    providerID: number;
    bulkGroupGuid: string;
    userId: number;
}

export interface BulkGroupModel {
    contractGuid: string;
    funder: number;    
    clientGuid: string;
    clientAltId: string;
    deliveredDate: string;
    deliveredTime: string;
    serviceId: number;
    subId: number;
    plannedUnits: number;
    deliveredUnits: number;
    billable: boolean;
    notes: string;
    unbillableReasonId: number | null;
    employeeId: number | null;
    barrierId: number | null;
}

export interface BulkGroupReponseModel extends BulkGroupModel {
    serviceCategory: string;
    subType: string;
    unbillableReason: string;
    clientRequiredFieldErrors: ClientRequiredFieldErrorModel[] | null;
}

export interface BulkGroupPostModel extends BulkGroupModel {
    bulkGroupGuid: string;
    userId: number;
    providerId: number;
}

export class BulkGroupService {
	public constructor(init?: BulkGroupReponseModel) {
		if (!!init) {
			//remove un-needed fields to get past API call failing
			delete init.clientRequiredFieldErrors;

			Object.assign(this, init);
		}
    }
}

export interface PostResponse {
	success: boolean;
	message: string;
	data: string;
}