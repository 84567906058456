import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HistoryComponent } from '../../../history/history.component';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalViralModel, ClientMedicalViral, ClientMedicalCommon } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields } from "src/app/models/enums";
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-viral-load',
  templateUrl: './viral-load.component.html',
  styleUrls: ['./viral-load.component.scss']
})
export class ViralLoadComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  clientData: ClientModel;  
  clientGUID: string;  
  maxDate = new Date();
  minDate = new Date();

  getViralLoadLatestResultUnavailableControl = () => this.medical?.get('idVLUnavailable');
  getViralLoadResultUndetectableControl = () => this.medical?.get('idVLUndetectable');
  getViralLoadResultUndetectableRangeControl = () => this.medical?.get('idVLUndetectableRange');
  getViralLoadResultDateControl = () => this.medical?.get('idVLDate');
  getViralLoadResultCountControl = () => this.medical?.get('idVLCount');

  isViralLoadUndetectable = () => this.getViralLoadResultUndetectableControl()?.value ?? false;
  isViralLoadUnavailable = () => (this.getViralLoadLatestResultUnavailableControl()?.value ?? false);
  showViralLoadUnavailable = () => { 
                                      // the order of the logic must be preserved for accuracy
                                      if (this.clientData?.clientMedicalViral == null) return true; // if no rows in table for this client then allow Unavailable to show
                                      //if (this.clientData?.clientMedicalViral?.positive) return false;
                                      if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalViral?.lastUpdate, true), (this.app.requiredFields30DayGracePeriodReset * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again    
                                      if (!this.clientData?.clientMedicalViral?.unavailable) return false;
                                      if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalViral?.lastUpdate, true), this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
                                      return false;                                       
                                   }

  constructor(private fb: UntypedFormBuilder, 
    private commonDataService: CommonDataService,
    public providerService: ProviderService,
    private userService: UserService,
    private clientService: ClientFormService,
    private datePipe: DatePipe,
    private dateHelper: DateHelper,
    public  validationHelper: FormValidations,
    private helperService: HelperClassService,
    private medcommon: MedicalCommon,
    private app: AppContextService) { }

  ngOnInit(): void {
    this.minDate = this.medcommon.getMinScreenedDate(this.minDate);
    this.maxDate = this.medcommon.getMaxScreenedDate(this.maxDate);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
                         });
     }

     this.getViralLoadLatestResultUnavailableControl().valueChanges.subscribe(value => { this.setUnavailable(value); });
     this.getViralLoadResultUndetectableControl().valueChanges.subscribe(value => { this.setUndetectable(value); });
     //this.providerService.isFieldRequired(MasterFields.ViralLoadResultDate)
  }

  refreshAfterSave() {
    this.setFieldValues();
  }  

  setUndetectable(value) {
    var undetectableRange = this.getViralLoadResultUndetectableRangeControl();
    var viralCount = this.getViralLoadResultCountControl();

    if (value) {
      this.validationHelper.AddRequiredMinMaxValidator(undetectableRange, 20, 200); //if (this.providerService.isFieldRequired(MasterFields.ViralLoadResultUndetectable))
      this.validationHelper.RemoveValidators(viralCount, true);
      viralCount.setValue(0);
    }
    else {
      this.validationHelper.RemoveValidators(undetectableRange, true);
      undetectableRange.setValue(0);
      this.validationHelper.AddRequiredMinMaxValidator(viralCount, 1, 9999999)
    }
  }

  setUnavailable(unavailable) {
    //var unavailable = this.medical.get('idVLUnavailable').value;
    var count = this.getViralLoadResultCountControl();
    var date = this.getViralLoadResultDateControl();

    if (unavailable) {
      this.validationHelper.RemoveValidators(count, true);
      this.validationHelper.RemoveValidators(date, true);
    }
    else {
      this.validationHelper.AddRequiredValidator(date); // if (this.providerService.isFieldRequired(MasterFields.ViralLoadResultDate))
      this.validationHelper.AddRequiredValidator(count); // if (this.providerService.isFieldRequired(MasterFields.ViralLoadResultCount))
    }
  }  

  setFieldValues() {
    if (!this.clientData) return;
    if (!this.helperService.isExpired(this.clientData?.clientMedicalViral?.expired)) {
      this.getViralLoadResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalViral?.datetime));
      this.getViralLoadResultCountControl().setValue(this.clientData.clientMedicalViral?.count ?? 0);
      this.getViralLoadResultUndetectableControl().setValue(this.clientData.clientMedicalViral?.undetectable ?? false);
      this.getViralLoadResultUndetectableRangeControl().setValue(this.clientData.clientMedicalViral?.undetectableRange ?? 0);
    }
  }  

  getModel(): Partial<ClientMedicalViralModel> {
	  var model: Partial<ClientMedicalViralModel> = new ClientMedicalCommon(this.userService.userID); //ClientMedicalViral(this.medical.value);

    if (this.getViralLoadLatestResultUnavailableControl().dirty || this.getViralLoadResultCountControl().dirty || this.getViralLoadResultDateControl().dirty ||
        this.getViralLoadResultUndetectableControl().dirty || this.getViralLoadResultUndetectableRangeControl().dirty)
    {
      let date_screened = (this.isViralLoadUnavailable() || !this.getViralLoadResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getViralLoadResultDateControl().value, { representation: 'date' });
     
      model.count = this.getViralLoadResultCountControl()?.value ?? 0;
      model.datetime = date_screened;
      model.unavailable = this.getViralLoadLatestResultUnavailableControl()?.value ?? false;
      model.undetectable = this.getViralLoadResultUndetectableControl()?.value ?? false;
      model.undetectableRange = this.getViralLoadResultUndetectableRangeControl()?.value ?? 0;
      // model.guid = ClientFormHelper.EmptyGuid();
      // model.deleted = false;
      // model.userId = this.userService.userID;
      // model.lastUpdate = ClientFormHelper.nullDate(); //set on server side
      // model.expired = ClientFormHelper.nullDate(); //set on server side

      return model;
    }

    return null;
  }

  openHistoryWindow(code, title) {
    this.histComp.clientGuid = this.clientData?.guid;
    this.histComp.clientAltID = this.clientData?.clientAltId;
    this.histComp.dataElement = code;
    this.histComp.title = title;
    this.histComp.showHistory(); 
  }    
}
