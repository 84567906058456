<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-7">Vaccinations</div>
                <div class="field col-5"></div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <!-- Influenza section -->
            <div class="p-fluid p-formgrid grid">
                <div class="field col-12" style="padding: 0;">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idFluVaxScreened">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Did this patient receive an influenza vaccination?
                            </label>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idFluVaxScreened" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idFluVaxScreened" value=1 label="Yes"></p-radioButton>
                        </div>
                        <div class="field col-4">
                            <label id="idInfluenzaVaccinationResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalInfluenzaVaccination"  [control]="medical.controls['idFluVaxScreened']"
                                [fieldId]="MasterFields.InfluenzaVaccinationDate" [lastUpdated]="this.clientData.clientMedicalInfluenzaVaccination?.datetime"></app-client-expired-field>
                            </label>
                        </div>
                        <div class="field col-1">
                            <button pButton pRipple type="button" label="History" style="color:#2196F3 !important;" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('FLUVAX', 'Influenza')"></button>      
                        </div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idFluVaxScreened']?.errors?.required">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-6">
                            <small id="idFluVaxScreened-help" class="p-invalid">Influenza vaccination is required.</small>
                        </div>
                        <div class="field col-6"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;">
                    <div id="FluNoDiv" *ngIf="showInfluenzaVaccinationNoReason()" class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idFluVaxScreenedNoReason">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Reason
                            </label>
                        </div>
                        <div class="field col-3">
                            <p-dropdown formControlName="idFluVaxScreenedNoReason" [options]="influenzaNoReasons" optionLabel="description" optionValue="id" appendTo="body" 
                            [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'10rem'}" tooltip="{{getInfluenzaVaccinationScreenedNoReasonDescription()}}"> </p-dropdown>
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="showInfluenzaVaccinationNoReason() && medical.controls['idFluVaxScreenedNoReason']?.errors">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-3">
                            <small id="idFluVaxScreenedNoReason-help" class="p-invalid">Reason is required.</small>
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;">
                    <div id="FluYesDiv" *ngIf="showInfluenzaVaccinationResultFields()" class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idFluVaxScreenedDate">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Date of Result
                            </label>                            
                        </div>
                        <div class="field col-2">
                            <input type="text"  #fluDiagDatePicker  name="idFluVaxScreenedDate" autocomplete="off" bsDatepicker formControlName="idFluVaxScreenedDate"
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idFluVaxScreenedDate" [maxDate]="maxScreenedDate"
                                placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid' : medical.controls['idFluVaxScreenedDate']?.invalid}" 
                                class="champ-date-picker" pInputText/>
                        </div>
                        <div class="field col-5"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idFluVaxScreenedDate']?.invalid">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-2">
                            <div *ngIf="medical.controls['idFluVaxScreenedDate']?.errors?.required"> 
                                <small id="idFluVaxScreenedDate-help" class="p-invalid">Result Date is required.</small>
                            </div>
                            <div *ngIf="!medical.controls['idFluVaxScreenedDate']?.errors?.required && !!medical.controls['idFluVaxScreenedDate']?.errors"> 
                                <small id="idFluVaxScreenedDate2-help" class="p-invalid">{{medical.controls['idFluVaxScreenedDate']?.errors['invalidDate']}}</small>
                            </div>  
                        </div>
                        <div class="field col-5"></div>
                    </div>
                </div>
            </div>
            <!-- End Influenza section -->

            <!-- Pneumoccocal section -->
            <div class="fluid formgrid grid">
                <div class="field col-12" style="padding: 0;">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idPneumoVaxScreened">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Did this patient receive a pneumoccocal vaccination?
                            </label>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idPneumoVaxScreened" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idPneumoVaxScreened" value=1 label="Yes"></p-radioButton>
                        </div>
                        <div class="field col-4">
                            <label id="idInfluenzaVaccinationResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalPneumoccocalVaccination" [control]="medical.controls['idPneumoVaxScreened']"
                                     [fieldId]="MasterFields.PneumoccocalVaccinationDate" [lastUpdated]="this.clientData.clientMedicalPneumoccocalVaccination?.datetime"></app-client-expired-field>
                            </label>
                        </div>
                        <div class="field col-1">
                            <button pButton pRipple type="button" label="History" style="color:#2196F3 !important" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('PNEUMOVAX', 'Pneumoccocal')"></button>      
                        </div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idPneumoVaxScreened']?.errors?.required">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-6">
                            <small id="idPneumoVaxScreened-help" class="p-invalid">Pneumoccocal vaccination is required.</small>
                        </div>
                        <div class="field col-6"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;">
                    <div id="PneumoNoDiv" *ngIf="showPneumoccocalVaccinationNoReason()" class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idPneumoVaxScreenedNoReason">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Reason
                            </label>
                        </div>
                        <div class="field col-3">
                            <p-dropdown formControlName="idPneumoVaxScreenedNoReason" [options]="pneumoccocalNoReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                    [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}" tooltip="{{getPneumoccocalVaccinationNoReasonDescription()}}"> </p-dropdown>    
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>                

                <div class="field col-12" style="padding: 0;" *ngIf="showPneumoccocalVaccinationNoReason() && medical.controls['idPneumoVaxScreenedNoReason']?.errors">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-3">
                            <small id="idPneumoVaxScreenedNoReason-help" class="p-invalid">Reason is required.</small>
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;">
                    <div id="PneumoYesDiv" *ngIf="showPneumoccocalVaccinationResultFields()" class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idPneumoVaxScreenedDate">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Date of Result
                            </label>
                        </div>
                        <div class="field col-2">
                            <input type="text"  #pneumoDiagDatePicker  name="idPneumoVaxScreenedDate" autocomplete="off" bsDatepicker formControlName="idPneumoVaxScreenedDate"
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idPneumoVaxScreenedDate" [maxDate]="maxScreenedDate"
                            placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid' : medical.controls['idPneumoVaxScreenedDate']?.invalid}" 
                            class="champ-date-picker" pInputText/>
                        </div>
                        <div class="field col-5"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idPneumoVaxScreenedDate']?.invalid">
                    <div class="fluid formgrid grid champ-grid">
                        <div class="field col-5"></div>
                        <div class="field col-2">
                            <div *ngIf="medical.controls['idPneumoVaxScreenedDate']?.errors?.required"> 
                                <small id="idPneumoVaxScreenedDate-help" class="p-invalid">Result Date is required.</small>
                            </div>
                            <div *ngIf="!medical.controls['idPneumoVaxScreenedDate']?.errors?.required && !!medical.controls['idPneumoVaxScreenedDate']?.errors"> 
                                <small id="idPneumoVaxScreenedDate2-help" class="p-invalid">{{medical.controls['idPneumoVaxScreenedDate']?.errors['invalidDate']}}</small>
                            </div>  
                        </div>
                        <div class="field col-5"></div>
                    </div>
                </div>
            </div>
            <!-- End Pneumoccocal section -->            

            <!-- HepatitisB section -->
            <div class="fluid formgrid grid">
                <div class="field col-12" style="padding: 0;">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idHepBVaxScreened">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Did this patient complete the Hepatitis B vaccination series?
                            </label>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idHepBVaxScreened" value=2 label="No"></p-radioButton>
                        </div>
                        <div class="field col-1">
                            <p-radioButton formControlName="idHepBVaxScreened" value=1 label="Yes"></p-radioButton>
                        </div>
                        <div class="field col-4">
                            <label id="idInfluenzaVaccinationResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalHepatitisBVaccination" [control]="medical.controls['idHepBVaxScreened']"
                                 [fieldId]="MasterFields.HepatitisBVaccinationDate" [lastUpdated]="this.clientData.clientMedicalHepatitisBVaccination?.datetime"></app-client-expired-field>
                            </label>
                        </div>
                        <div class="field col-1">
                            <button pButton pRipple type="button" label="History" style="color:#2196F3 !important" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('HEPBVAX', 'HepatitisB')"></button>      
                        </div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idHepBVaxScreened']?.errors?.required">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-6">
                            <small id="idHepBVaxScreened-help" class="p-invalid">Hepatitis B vaccination is required.</small>
                        </div>
                        <div class="field col-6"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;">
                    <div id="HepBNoDiv" *ngIf="showHepatitisBVaccinationNoReason()" class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idHepBVaxScreenedNoReason">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Reason
                            </label>
                        </div>
                        <div class="field col-3">
                            <p-dropdown formControlName="idHepBVaxScreenedNoReason" [options]="hepatitisBNoReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                    [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}" tooltip="{{getHepatitisBVaccinationScreenedNoReasonDescription()}}"> </p-dropdown>
                        </div>
                        <div class="field col-4"></div>
                    </div>
                </div>

                <div class="field col-12" style="padding: 0;" *ngIf="showHepatitisBVaccinationNoReason() && medical.controls['idHepBVaxScreenedNoReason']?.errors">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-3">
                            <small id="idHepBVaxScreenedNoReason-help" class="p-invalid">Reason is required.</small>
                        </div>
                        <div class="field col-4"></div>
                    </div>                    
                </div>
                
                <div class="field col-12" style="padding: 0;">
                    <div id="HepBYesDiv" *ngIf="showHepatitisBVaccinationResultFields()" class="fluid formgrid grid champ-grid" >
                        <div class="field col-5">
                            <label htmlFor="idHepBVaxScreenedDate">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" style="vertical-align: top !important"></fa-icon>
                                Date of Result
                            </label> 
                        </div>
                        <div class="field col-2">
                            <input type="text"  #hepBDiagDatePicker  name="idHepBVaxScreenedDate" autocomplete="off" bsDatepicker formControlName="idHepBVaxScreenedDate"
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idHepBVaxScreenedDate" [maxDate]="maxScreenedDate"
                            placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid' : medical.controls['idHepBVaxScreenedDate']?.invalid}" 
                            class="champ-date-picker" pInputText/>
                        </div>
                        <div class="field col-5"></div>
                    </div>                
                </div>                
                    
                <div class="field col-12" style="padding: 0;" *ngIf="medical.controls['idHepBVaxScreenedDate']?.invalid">
                    <div class="fluid formgrid grid champ-grid" >
                        <div class="field col-5"></div>
                        <div class="field col-2">
                            <div *ngIf="medical.controls['idHepBVaxScreenedDate']?.errors?.required"> 
                                <small id="idHepBVaxScreenedDate-help" class="p-invalid">Result Date is required.</small>
                            </div>
                            <div *ngIf="!medical.controls['idHepBVaxScreenedDate']?.errors?.required && !!medical.controls['idHepBVaxScreenedDate']?.errors"> 
                                <small id="idHepBVaxScreenedDate2-help" class="p-invalid">{{medical.controls['idHepBVaxScreenedDate']?.errors['invalidDate']}}</small>
                            </div>
                        </div>
                        <div class="field col-5"></div>
                    </div>
                </div>
            </div>
            <!-- End HepatitisB section -->             
        </div>
    </div>
</form>

<app-history ></app-history>