<form [formGroup]="clientForm" autocomplete="off" >

<div id="overlay" *ngIf="spinning" >  <!--  *ngIf="spinning$ | async"-->
    <p-progressSpinner></p-progressSpinner>
    <br/>
    Loading ...
</div>

<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
    

<div class="p-fluid" style="margin-top: 10px; margin-bottom: 10px;"  >
    <div class="grid champ-form-title-row">  <!--   8.33333333 * 1.5 -->

        <div class="col-12 lg:col-6 " > <!-- 1.5 -->  <!-- style="width: 12.499999995%; flex: 0 0 12.499999995%;max-width: 12.499999995%;"-->
            <span class="champ-page-title">Client Form</span>

            <span class="ml-6 text-sm"><u> Unique Identifier</u> :  {{clientData?.clientAltId  |  uppercase }}</span>
            <span class="ml-4 text-sm"><u> GUID</u> :  {{clientData?.guid |  uppercase }}</span>
            <span class="ml-4 text-sm"><u> Provider Client ID </u> :  {{clientData?.providerClientId |  uppercase }}</span>

        </div>

        <div class="col-12 lg:col-6 " > 
            
            
            <span class="ml-4"  *ngIf="this.certificationAlert > 0" style="color: darkred; background-color: yellow; margin-right: .5rem ;" > 
                <span (click)="onOpenRecertificationPopup($event)"  pTooltip="Click for certification details"  style="width: 8rem;">CERTIFICATION REQUIRED</span>
            </span>
            
            <span class="ml-4" *ngIf="this.eligibilityAlert > 0 "style=" color: yellow; background-color: darkred;"> 
                <span (click)="onOpenEligibiilitPopup($event)"  pTooltip="Click for eligibilty details" style="width: 8rem;">RYAN WHITE ELIGIBILTY ALERT</span> 
            </span> 

            <span class="ml-8 mt-0" *ngIf="!readonly && (this.clientService.$clientGet | async) as client"  >
                <app-countdown-timer #timerLockExpire [minutes]="timerClientLockTimeoutMinutes" [seconds]="0" title="Lock Expires" ></app-countdown-timer>
                <app-countdown-timer #timerLockExit [minutes]="0" [seconds]="timerLockTimeoutExitSeconds" [showMinutes]="false" [showSeconds]="true" title=""
                                             [hidden]="true" ></app-countdown-timer>
            </span>        
            <span class="ml-4" *ngIf="readonly && (this.clientService.$clientGet | async) as client" >
                <span style="background-color: lightgreen; width: 6rem;">  READ ONLY </span>
            </span>   

        </div>   

         <!-- 1.5 --> <!-- style="width: 12.499999995%; flex: 0 0 12.499999995%;max-width: 12.499999995%;" -->
 
        <!-- <div class="flex col-12 lg:col-2" >
            <u> Unique Identifier</u> :  {{clientData?.clientAltId  |  uppercase }}
        </div> 
        -->
        <!-- 2.25%-->  <!-- style="width: 18.75%; flex: 0 0 18.75%;max-width: 18.75%;" -->
        <!--
        <div class="col-12 lg:col-2" > 
            <u> GUID </u> : {{clientData?.guid |  uppercase }}
        </div>
        -->

        <!--  style="width: 13.5%; flex: 0 0 13.5%;max-width: 13.5%;" -->
        <!--
        <div class="col-12  lg:col-2" > 
            <u> Provider Client ID </u> :  {{clientData?.providerClientId |  uppercase }}
        </div>
        -->

        <!-- <div class="col-12  lg:col-2"  *ngIf="this.certificationAlert > 0" style="color: darkred; background-color: yellow; margin-right: .5rem ; width: 10rem;">
            <div (click)="onOpenRecertificationPopup($event)"  pTooltip="Click for certification details" >CERTIFICATION REQUIRED</div>
        </div>
        
        <div class="col-12  lg:col-2" *ngIf="this.eligibilityAlert > 0 "style=" color: yellow; background-color: darkred; width: 10rem;"> 
            <div (click)="onOpenEligibiilitPopup($event)"  pTooltip="Click for eligibilty details">RYAN WHITE ELIGIBILTY ALERT</div> 
        </div>  -->

        <!-- <div class="col-12  lg:col-2 align-items-end" > </div>  -->

        <!-- <div class="col-12  lg:col-3" >
            <div class="col-12  lg:col-6" *ngIf="!readonly && (this.clientService.$clientGet | async) as client" >
                <app-countdown-timer #timerLockExpire [minutes]="timerClientLockTimeoutMinutes" [seconds]="0" title="Lock Expires"></app-countdown-timer>
                <app-countdown-timer #timerLockExit [minutes]="0" [seconds]="timerLockTimeoutExitSeconds" [showMinutes]="false" [showSeconds]="true" title="" [hidden]="true"></app-countdown-timer>
            </div>        
            <div class="col-12  lg:col-6" *ngIf="readonly && (this.clientService.$clientGet | async) as client" >
                <span style="background-color: lightgreen; width: 6rem;">  READ ONLY </span>
            </div>        
        </div>   -->

    </div>
</div>


<div class="p-fluid p-formgrid grid">
    <div class="field col-12 lg:col-11"> 

        <p-tabView>
            <!--  [ngStyle]="{'color': _tabIDHasErrors == true ? 'red' : '#2196F3'} ;"-->
            <p-tabPanel header="Identification - Basic Info" [headerStyle]="{'color': _tabIDHasErrors == true ? 'red' : '#2196F3'}">
                <app-identification [identification]="clientForm.get('identification')" (componentFinishedLoading)="isIdentificationFinishedLoading($event)"></app-identification>
            </p-tabPanel>

            <p-tabPanel header="Demographics & HIV Data"  [headerStyle]="{'color': _tabIDHasErrors == true ? 'red' : '#2196F3'}">
                <!-- Issue with Lazy loading and pristine error & tab not loading if not click so data is never loaded into the controls, will use standard loading for now. -->
                <!-- <ng-template pTemplate="content"> -->
                    <!-- <app-demographics [demographics]="clientForm.get('demographics')"></app-demographics> -->
                <!-- </ng-template> -->

                <app-demographics [demographics]="clientForm.get('demographics')"></app-demographics>
            </p-tabPanel>

            <p-tabPanel header="Socio-Economic">
                <!-- Issue with Lazy loading and pristine error, will use standard loading for now. -->
                <!-- <ng-template pTemplate="content"> -->
                     <!-- <app-socio-econ [socio_econ]="clientForm.get('socio_econ')"></app-socio-econ> -->
                <!-- </ng-template> -->

                <app-socio-econ [socio_econ]="clientForm.get('socio_econ')"></app-socio-econ>
            </p-tabPanel>

            <p-tabPanel header="Medical" *ngIf="!isNewClient()">
                <app-medical [client]="clientForm"> </app-medical>
                <!-- <app-medical [medical]="clientForm"> </app-medical> -->
            </p-tabPanel>
            

            <p-tabPanel header="MCM"  *ngIf="!isNewClient()" >  
                <ng-template pTemplate="content">
                    <app-mcm [mcm]="clientForm.get('mcm')"></app-mcm>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel header="Behavioral Health" *ngIf="!isNewClient()">
                <app-behavior-health [client]="clientForm"></app-behavior-health>
            </p-tabPanel>

            <p-tabPanel header="Direct Services" *ngIf="!isNewClient()">   <!--  Lazy Loaded -->
                <ng-template pTemplate="content">
                    <app-client-direct-services   ></app-client-direct-services>
                </ng-template>
            </p-tabPanel>

            <p-tabPanel header="Referrals" *ngIf="!isNewClient()">
                <ng-template pTemplate="content">
                    <app-referrals ></app-referrals>
                </ng-template>
            </p-tabPanel>
            
            <!-- <p-tabPanel header="Notes">
                Notes
            </p-tabPanel> -->
        </p-tabView>

    </div>

    <div class="field col-12 lg:col-1" >  
        <div class="p-fluid p-formgrid grid">

            <!-- {{ !clientForm.dirty}}
            {{ clientService.disableSave}}
            {{ !isRequiredIdentificationSupplied()}}
            {{ this.reclockService.lockAdminReadOnlyMode}} -->
            <div class="p-col" style="margin: .5rem;" >
                <p-button  pButton pRipple type="button" [ngStyle]="{'width':'12rem'}"   styleClass="p-button-text " class=" p-button-text mr-1 "
                         (click)="onSave()" [disabled]="!(clientForm.dirty) || clientService.disableSave || !(isRequiredIdentificationSupplied()) || this.reclockService.lockAdminReadOnlyMode" [hidden]="clientService.readonly" > 
                        <i class="fa-sharp fa-regular fa-cloud-arrow-up fa-xl" ></i><span class="text-lg">Save</span>
                </p-button>                         
            </div>

            <!-- <div class="p-col" style="margin: .5rem;" *ngIf="isDevelopment"  >
                <button pButton type="button" label="Validate" icon="pi pi-cloud-upload" style="width: 10rem;margin-right: 1rem;" 
                         (click)="onValidate()" ></button>
            </div> -->

            <div class="p-col" style="margin: .5rem;">
                 <p-button  pButton pRipple type="button" [ngStyle]="{'width':'12rem'}"  styleClass="p-button-text " class="p-button-text mr-1 "
                        (click)="showClientLookupDialog()"   > 
                        <i class="fa-sharp fa-solid fa-magnifying-glass fa-xl "></i><span class="text-lg">Lookup</span>
                </p-button>
            </div>

            <!-- <div class="p-col" style="margin: .5rem;">
                <button pButton type="button" label="Print" icon="pi pi-print" style="width: 10rem;margin-right: 1rem;"></button>
            </div> -->
            
            <!-- <div class="p-col" style="margin: .5rem;" >
                <button pButton type="button" label="Delete" icon="pi pi-trash" style="width: 10rem;" [hidden]="clientService.readonly" (click)="onDelete()" ></button>
            </div> -->

        </div>
    </div>

</div>


<!-- 
<div class="field col-12 p-xl-12">
    <div class="card ">
        Form value : {{clientForm.value | json}}
    </div>


    <p>
        Form Status: {{ clientForm.status }}
    </p>

     <div class="card ">
        Form value : {{clientForm.valueChanges| json}}
    </div> 


 </div>   

-->

</form>

<p-dialog [(visible)]="_validationErrorDialog"
          header="Validation Errors" [modal]="true" 
          [draggable]="true" [resizable]="true"
          [style]="{width: '25vw', minWidth: '30vw'}" [baseZIndex]="10000"
          >

     <div  style="height: 50%">
        <p-table [value]="this.clientService.invalidControls"  styleClass="p-datatable-sm p-datatable-striped " name="Validation Errors"  [scrollable]="true" scrollHeight="200px">
            <ng-template pTemplate="header">
                <tr>
                    <th  pSortableColumn="name"  >Field<p-sortIcon ></p-sortIcon></th>
                    <!-- <th  pSortableColumn="subType" >Sub Type<p-sortIcon field="subType"></p-sortIcon></th>
                    <th  pSortableColumn="visitDate" style="width:75px" >Date<p-sortIcon field="visitDate"></p-sortIcon></th>
                    <th  pSortableColumn="units" style="width:75px"> Units<p-sortIcon field="units"></p-sortIcon></th> -->
                </tr>
            </ng-template>

            <ng-template  pTemplate="body"  >
                <div *ngFor="let control of this.clientService.invalidControls ">
                <tr class="cl-row">   
                    <td>{{control.name}}</td>
                    
                </tr>
            </div>
            </ng-template>
        </p-table>
    </div>

</p-dialog>


<!-- <p-confirmDialog key="lock" [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text"></p-confirmDialog> -->

<p-confirmDialog #cd key="lock" [style]="{width: '50vw'}" [styleClass]="" >
    <ng-template pTemplate="header" >
        <h4 style="color: white; margin: .5rem;">Lock Expired</h4>
    </ng-template>
    <ng-template pTemplate="footer">
        <!-- icon="pi pi-times"  -->
        <p-button styleClass=" p-button-rounded p-button-warning" (click)="cd.reject()">    
            <ng-template pTemplate="content">
                <span>Release Lock & Exit</span>    
                <app-countdown-timer #timerLockExit [minutes]="0" [seconds]="timerLockTimeoutExitSeconds" [showMinutes]="false" [showSeconds]="true" title=""></app-countdown-timer>
            </ng-template>
        </p-button>
        <button pButton pRipple type="button" icon="pi pi-check" label="Renew Lock & Continue Editing" (click)="cd.accept()" class=" p-button-rounded p-button-warning">
        </button>
    </ng-template>
</p-confirmDialog>