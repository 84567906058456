import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom, Subscription } from 'rxjs';
import { ClientModel, ClientPaySourcesModel, VisitLinkageModel, HopwaWorksheetZipCodeModel, ClientListModel,
         ClientRequiredFieldErrorModel, ClientMedicalRiskReductionModel, ExistingClientModel} from '../../models/client.model'
import { UserService } from 'src/app/services/user.service'
import { environment } from 'src/environments/environment';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RecertificationErrors, EligibilityAlertErrors, PaySource, HIVIndeterminateStatusResult, HIVIndeterminateStatus, 
         RequiredFieldResult, RequiredFieldGroup } from "src/app/models/enums";
import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, some,
         sortedUniq, sortBy, orderBy, map, merge, uniqBy, intersection, split, toNumber, String} from 'lodash';
import { CommonDataService } from '../../services/common-data.service';
import { format, addDays, addMonths, parse, parseISO, differenceInCalendarYears, subMonths, subYears, addYears, isDate, getDate } from 'date-fns';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { ProviderService } from 'src/app/services/provider.service';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Injectable({
  providedIn: 'root'
})
export class ClientFormService {

  private readonly URL = environment.apiURL + '/client/';
  public newclient: boolean = false;
  public readonly: boolean = false;
  public disableSave: boolean = false;
  public clientData: ClientModel = null;
  public clientForm: UntypedFormGroup;
  
  private clientSource = new BehaviorSubject<ClientModel>(null);
  $clientGet = this.clientSource.asObservable();

  private clientPost = new BehaviorSubject<ClientModel>(null);
  $clientPost = this.clientPost.asObservable();

  private subscriptions: Subscription;

  // store the errors after calling validate required fields
  public clientRequiredFieldErrors: ClientRequiredFieldErrorModel[];

  clientDefaults: Partial<ClientModel> = { guid: '00000000-0000-0000-0000-000000000000', firstName:"", lastName:"" };
    
  public loadedDemos:boolean=false;
  public loadedSocio:boolean=false;
  public loadedMedical:boolean=false;
  public loadedBH:boolean=false;
  public loadedMCM:boolean=false;

  public invalidControls = [];
  public getZipCodeControl = () => this.clientForm?.controls.identification.get("idZipCode");
  public getPaySourceControl = () => this.clientForm?.controls.socio_econ.get("idPaySource");
  public getIncomeDataControl = () => this.clientForm?.controls.socio_econ.get("incomeData");


  constructor(private http: HttpClient, 
              private userService : UserService, 
              private app: AppContextService,
              private commonDataService: CommonDataService, 
              private helper: HelperClassService,
              private providerService:ProviderService) { 

      this.app.updateTabTitle('CHAMP - Client ' );                
                
  }

  public resetRequiredFields() {
    this.clientRequiredFieldErrors = null;
  }

  public getClientData(clientGuid: string) //clientAltId: string
  { 
      var value:string;
      let params = new HttpParams();
      params = params.append('guid', clientGuid);
      //params = params.append('clientAltId', clientAltId);
      params = params.append('userId', this.userService.userID);
      // params = params.append('clinicID', this.userService.CurrentClinicID.toString());

      // need to reset behavior subject each time because once complete is called the Behavior subject will no longer fire events (isStopped becomes true)
      // the clients need to re-subscribe for this to work correctly, i.e. ngOnInit
      this.clientSource = new BehaviorSubject<ClientModel>(null);
      this.$clientGet = this.clientSource.asObservable();

      let url  = this.URL;
      this.http.get<ClientModel>(url, {params:params}).subscribe({
            next: (clientData: ClientModel) => {
                this.clientData = clientData;
                this.clientSource.next(this.clientData);
                this.app.updateTabTitle('CHAMP - Client - ' + clientData.clientAltId);
                this.resetRequiredFields(); // ask Den if he wants to pull back existing required fields when loading a client
            },
            error: (error) => {
                console.log(error);
                //this.clientSource.error("getClientData ERROR LOG CHAMP4"  + error?.error ?? error );
                this.clientSource.error("getClientData ERROR LOG CHAMP4"  + error?.error );
            },
            complete: () => {
                this.clientSource.complete();
            }
          });
    
  }

  public getClientAltIdList(providerId: number) {
    let params = new HttpParams();
    params = params.append('providerId', providerId);

    return this.http.get<ClientListModel[]>(this.URL + 'GetClientAltIdList/', { params:params });
  }

  public findClient(providerId: number, clientAltId: string, clientId: number) {
    let params = new HttpParams();
    params = params.append('providerId', providerId);
    params = params.append('clientAltId', clientAltId);
    params = params.append('clientId', clientId);

    return this.http.get<ExistingClientModel[]>(this.URL + 'FindClient/', { params:params });
  }

  public async validateRequiredFields(clientGuid:string, userId:number, requiredFieldGroup:RequiredFieldGroup = RequiredFieldGroup.All): Promise<RequiredFieldResult> {
    let params = new HttpParams();
    params = params.append('clientGuid', clientGuid);
    params = params.append('userId', userId);
    params = params.append('requiredFieldGroup', requiredFieldGroup);

    this.clientRequiredFieldErrors = await lastValueFrom(this.http.get<ClientRequiredFieldErrorModel[]>(this.URL + 'ValidateRequiredFields/', { params:params }));

    // the result is repeated in the return object so only need the first occurance of the result to return
    return this.clientRequiredFieldErrors[0]?.requiredFieldResult;
  }

  public getRiskReductionHistory(clientGuid: string) {
    let params = new HttpParams();
    params = params.append('clientGuid', clientGuid);

    return this.http.get<ClientMedicalRiskReductionModel[]>(this.URL + 'RiskReductionHistory/', { params:params });
  }

  public getMCMVisitLinkageData(clientAltId:string, startDate:string): Promise<VisitLinkageModel> {
    //The stored procedure was replaced by the Web API call MCMVisitLinkageData
    //This stored procedure executes and returns 2 columns one column is for linkage true or false which is calculated for any Medical Visit
    //the other column is the eis end date which has to be a prescribing privilege medical visit sub type - Aj 08/14/2011 Ref: FogBugz 1112
    //string strDSEISSQL = "exec sp_GetLinkageEISEndDateForClientById  '" + this.ParentClient.AltClientID + "','" + EISStartDateVal + "'";
    //DataSet dsDSEIS = this.m_AppContext.ExecuteAdHocQuery(strDSEISSQL);
    let params = new HttpParams();
    params = params.append('clientAltId', clientAltId);
    params = params.append('startDate', startDate);

    return lastValueFrom(this.http.get<VisitLinkageModel>(this.URL + 'MCMVisitLinkageData/', { params:params }));
  }

  public getHopwaWorksheetZipCodeData(hopwaWorksheetClientZipCodeGuid:string): Promise<HopwaWorksheetZipCodeModel> {
    let params = new HttpParams();
    params = params.append('hopwaWorksheetClientZipCodeGuid', hopwaWorksheetClientZipCodeGuid);

    return lastValueFrom(this.http.get<HopwaWorksheetZipCodeModel>(this.URL + 'HopwaWorksheetZipCodeData/', { params:params }));
  }  

  // need to call thsi otherwise the $clientPost.subscribe hooks get called when switching between clients and will initially load the prior clients data before the $clientGet.subscribe call happens
  // this could cause mismatch data to load on the screen
  public postCleanUp() {
    this.clientData = null;
    this.clientPost = new BehaviorSubject<ClientModel>(this.clientData);
    this.$clientPost = this.clientPost.asObservable();
  }

  public postClientData(clientDataModel: any) 
  { 
    this.clientData = clientDataModel;

    // THE CODE BELOW DOES NOT WORK FOR REsETTING THE OBSERVABLE - MS 10-7-22
    // need to reset behavior subject because once complete is called the Behavior subject will no longer fire events (isStopped becomes true)
    // the clients need to re-subscribe for this to work correctly, i.e. where-ever the save logic is called the re-subscribe shoud take place before anything else
    //this.clientPost = new BehaviorSubject<ClientModel>(null);
    //this.$clientPost = this.clientPost.asObservable();    

    let url = this.URL; 
    this.http.post<ClientModel>(url, this.clientData).subscribe({
        next: (data: ClientModel) => {   
            //console.log(data);
            this.clientData = data;
            this.clientPost.next(this.clientData);
        },
        error: (error) => {
           // console.log("postClientData ERROR LOG CHAMP4"  + error?.error ?? error);
            console.log("postClientData ERROR LOG CHAMP4"  + error?.error );
            this.clientPost.error(error);
        }
        // we don't want to complete the Subject because it basically ends its life and each component would have to re-subscribe to a new instance
        // complete: () => {
        //     this.clientPost.complete();
        // THE CODE BELOW DOES NOT WORK FOR REsETTING THE OBSERVABLE - MS 10-7-22
        //     // need to reset behavior subject because once complete is called the Behavior subject will no longer fire events (isStopped becomes true)
        //     // the clients need to re-subscribe for this to work correctly, i.e. where-ever the save logic is called the re-subscribe shoud take place before anything else
        //     this.clientPost = new BehaviorSubject<ClientModel>(null);
        //     this.$clientPost = this.clientPost.asObservable();
        // }        
      });
  }

  public resetInvalidControlsList() {
    this.invalidControls = [];
  }

   public getInvalidControlsList(formGroup: UntypedFormGroup| UntypedFormArray, parent: string): void {
      Object.keys(formGroup.controls).forEach(key => {
        const control = formGroup.controls[key] as UntypedFormControl | UntypedFormGroup | UntypedFormArray;
        if(control instanceof UntypedFormControl) {
          if ( control.invalid ) {
            console.log(`Invalid :  ${key}` );
            //control.setErrors()
            this.invalidControls.push({ name: key, msg: `Invalid :  ${key}`});
            if  (parent == 'identification') {
                
            }

          }

          if ( control.dirty ) {
            console.log(`Dirty :  ${key}` );
           // this.invalidControls.push(control);
          }
          //control.updateValueAndValidity()
        } else if(control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
          //console.log(`control '${key}' is nested group or array. calling clearValidators recursively`);
          this.getInvalidControlsList(control, key);
        } else {
          console.log("ignoring control")
        }
      });
  }

  public clearValidators(formGroup: UntypedFormGroup| UntypedFormArray): void {
    Object.keys(formGroup.controls).forEach(key => {
      const control = formGroup.controls[key] as UntypedFormControl | UntypedFormGroup | UntypedFormArray;
      if(control instanceof UntypedFormControl) {
        console.log(`Clearing Validators of ${key}`);
        //control.clearValidators();
        control.updateValueAndValidity()
      } else if(control instanceof UntypedFormGroup || control instanceof UntypedFormArray) {
        //console.log(`control '${key}' is nested group or array. calling clearValidators recursively`);
        this.clearValidators(control);
      } else {
        console.log("ignoring control")
      }
    });
  }
 
  public isClientEligible(): EligibilityAlertErrors {
    if (!this.clientData) return EligibilityAlertErrors.NotSet;

    var clientEligiblity = EligibilityAlertErrors.None;
    const maxPovertyLevelPercentage = 5; const minPovertyLevelPercentage = 1.39;
    var noInsurancePaySourceTypes: Array<number> = [ PaySource.NoInsurance, PaySource.Charity ];

    var latestFPL = this.clientData?.clientIncomeData;
    var latestPaySourceCollection = this.clientData?.clientPaySources;
    var povertyLevelPercentage = this.commonDataService.getPovertyLevelPercentage(latestFPL?.householdIncome, latestFPL?.householdSize)

    if (latestFPL != null && povertyLevelPercentage > maxPovertyLevelPercentage)
      clientEligiblity = EligibilityAlertErrors.FPLOver500;

    if (latestPaySourceCollection != null && latestPaySourceCollection.length > 0)
    {
        //latestPaySourceCollection.includes(v => noInsurancePaySourceTypes.includes(v.id)
        if (some(latestPaySourceCollection, function(e:ClientPaySourcesModel){ return noInsurancePaySourceTypes.includes(e.id); }) && 
            ((latestFPL != null && povertyLevelPercentage < minPovertyLevelPercentage)))
        {
          clientEligiblity = clientEligiblity == EligibilityAlertErrors.None ? EligibilityAlertErrors.FPLUnder139orNoInsuranceandNoMedicaid : clientEligiblity | EligibilityAlertErrors.FPLUnder139orNoInsuranceandNoMedicaid;
        }
    }

    if (this.clientRecertificationRequired() != RecertificationErrors.None)
      clientEligiblity = clientEligiblity == EligibilityAlertErrors.None ? EligibilityAlertErrors.NotCertified : clientEligiblity | EligibilityAlertErrors.NotCertified;

    return clientEligiblity;
  }

  public clientRecertificationRequired(): RecertificationErrors {
    if (!this.clientData) return RecertificationErrors.NotSet;
    
    var currentRecertErrors = RecertificationErrors.None;
    var today = new Date();
    let zipExpired = this.clientData?.clientZipCodes?.expired

    // Added code to calulate the expiration rather than rely on DB 
    var rf = this.providerService.getExpiredRequiredField(5);
    var latestZipCodeExpired  = addDays(this.helper.castToDate(this.clientData?.clientZipCodes?.datetime), rf?.expiresDays );
    if (!latestZipCodeExpired || latestZipCodeExpired < today)
       currentRecertErrors = currentRecertErrors == RecertificationErrors.None ? RecertificationErrors.ZipCode : currentRecertErrors | RecertificationErrors.ZipCode;

    var rf = this.providerService.getExpiredRequiredField(28);
    var latestPaySourceExpired  = addDays(this.helper.castToDate(this.clientData?.clientZipCodes?.datetime), rf?.expiresDays );
    //var latestPaySourceExpired = (this.clientData?.clientPaySources[0]?.expired ?? "1900-01-01").substr(0, 10);
    if (!latestPaySourceExpired || latestPaySourceExpired < today)
        currentRecertErrors = currentRecertErrors == RecertificationErrors.None ? RecertificationErrors.Insurance : currentRecertErrors | RecertificationErrors.Insurance;

    var rf = this.providerService.getExpiredRequiredField(31);
    var latestFPLExpired  = addDays(this.helper.castToDate(this.clientData?.clientZipCodes?.datetime), rf?.expiresDays );
   // var latestFPLExpired = (this.clientData?.clientIncomeData?.expired ?? "1900-01-01").substr(0, 10);
    if (!latestFPLExpired || (latestFPLExpired < today))
        currentRecertErrors = currentRecertErrors == RecertificationErrors.None ? RecertificationErrors.FPL : currentRecertErrors | RecertificationErrors.FPL;

    return currentRecertErrors;    
  }

  public async isValidAgeForIndeterminateStatus() {
    if (this.clientData?.clientHivStatus?.id != HIVIndeterminateStatus.HIVIndeterminateStatus)
      return HIVIndeterminateStatusResult.NotIndeterminateStatus;
    if (this.clientData?.clientHivStatus?.id == HIVIndeterminateStatus.HIVIndeterminateStatus && (await this.age() >= 2))
      return HIVIndeterminateStatusResult.IndeterminateStatusInvalidAge;
    //is only valid for less than 2 years old
    return HIVIndeterminateStatusResult.IndeterminateStatusValidAge;
  }

  public async age() {
    var currentDateTime = await this.commonDataService.getCurrentDateTime();
    var birthDate = parseISO(this.clientData?.birthdate);
    // birthDate = addYears(birthDate, 21); //used for testing
    var timeDiff = Math.abs(currentDateTime.getTime() - birthDate.getTime());
    var age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);    
    // var age = differenceInCalendarYears(currentDateTime, birthDate);
    // if (birthDate.getMonth() > currentDateTime.getMonth()) age--;
    // if (birthDate.getMonth() == currentDateTime.getMonth() && birthDate.getDate() > currentDateTime.getDate()) age--;
    return age    
  }

  public createClientAltId(firstName? : string, lastName?: string, gender?: number, ssn?: string, birthDate?: string, defaultClientAltId?: string) {
    var newClientAltId = defaultClientAltId;

    if (!firstName || !lastName || !gender || !ssn || !birthDate || birthDate?.length < 10) return newClientAltId;

    var genderChar = gender == 1 ? 'M' : gender == 2 ? 'F' : 'T';
    var day = getDate(parse(birthDate, 'MM-dd-yyyy', new Date())).toString();
    //var birthDateChars = birthDate.length == 10 ? birthDate.substring(3, 5) : '00'; //birthDate.substring(8, 10)
    newClientAltId = firstName.substring(0, 1) + lastName.substring(0, 1) + genderChar + ssn + day.padStart(2, '0');

    return newClientAltId.toUpperCase();
  }
}
