<form [formGroup]='client' autocomplete="off" autocapitalize="on">
    <p-scrollPanel [style]="{width: '99.25%', height: '600px'}" styleClass="custombar1">
        <div class="fluid formgrid grid" style="background-color: whitesmoke;">
            <div class="field col-12 xl:col-6">
                <app-cd4 [medical]="client.get('medical').get('cd4')"></app-cd4>
                <app-viral-load [medical]="client.get('medical').get('vl')"></app-viral-load>
                <app-aids-define-condition [medical]="client.get('medical').get('adc')"></app-aids-define-condition>
                <app-syphilis [medical]="client.get('medical').get('syphilis')"></app-syphilis>
                <app-pharma-assistance [medical]="client.get('medical').get('pharma')"></app-pharma-assistance>
                <app-antiretroviral-therapies [medical]="client.get('medical').get('art')"></app-antiretroviral-therapies>
                <app-covid [medical]="client.get('medical').get('covid')"></app-covid>            
                <app-vaccinations [medical]="client.get('medical').get('vax')"></app-vaccinations>
                <app-other-sti [medical]="client.get('medical').get('otherSTI')"></app-other-sti>
            </div>

            <div class="field col-12 xl:col-6">
                <app-hepatitis-b #hepatitisB [medical]="client.get('medical').get('hepB')"></app-hepatitis-b>
                <app-hepatitis-c [medical]="client.get('medical').get('hepC')"></app-hepatitis-c>
                <app-tuberculosis [medical]="client.get('medical').get('tb')"></app-tuberculosis>
                <app-chlamydia [medical]="client.get('medical').get('chlamydia')"></app-chlamydia>
                <app-gonorrhea [medical]="client.get('medical').get('gono')"></app-gonorrhea>
                <app-toxoplasma [medical]="client.get('medical').get('toxo')"></app-toxoplasma>
                <app-lipidpanel [medical]="client.get('medical').get('lipid')"></app-lipidpanel>
                <app-female-only [medical]="client.get('medical').get('female')"></app-female-only>
            </div>
        </div>
    </p-scrollPanel>
</form>
