import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  ClientDirectServiceModel, ClientDirectServiceRequestModel,
  ClientDirectServicesSubsetModel,
  EISSubCategoryDatesModel,
  FirstPMorMCMDirectServiceModel,
  MoreThan4UnitsIn12MonthsResult,
  PostResponse,
  SimilarClientDirectServiceModel
} from './client-direct-service-models';
//import { stringify } from '@angular/compiler/src/util';
import { addDays, addMonths, endOfDay, formatISO, parseISO, startOfMonth, subDays } from 'date-fns';
import {
  orderBy,
  round, sumBy
} from 'lodash';
import { Observable, noop } from 'rxjs';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { ClientHopwaWorksheetModel, ClientModel } from 'src/app/models/client.model';
import {
  DiagnosedYesNo,
  HIVIndeterminateStatusResult,
  HIVStatus,
  PayRollPeriod,
  RequiredFieldGroup,
  RequiredFieldResult,
  RequiredFields,
  UnbillableReason
} from "src/app/models/enums";
import { CommonDataService } from '../../services/common-data.service';
import { ProviderService } from '../../services/provider.service';
import { UserService } from '../../services/user.service';
import { HelperClassService } from 'src/app/services/helper-class.service';

@Injectable({
  providedIn: 'root'
})
export class ClientDirectServicesService {
  private readonly URL = environment.apiURL + '/clientdirectservices';
  clientData: ClientModel;

  getDefaultDate = () => parseISO('1900-01-01');

  constructor(private http: HttpClient, public clientService:ClientFormService, 
              private providerService: ProviderService, 
              private commonDataService: CommonDataService,
              private helperService: HelperClassService,
              private userService: UserService) { }

  //
  public getDirectServices(request:ClientDirectServiceRequestModel) : Observable<ClientDirectServiceModel[]>
  { 
    //this.commonDataService.containsAnyTypeOfRiskReduction(serviceId, subTypeId)
    return this.http.post<ClientDirectServiceModel[]>(this.URL + '/GetDirectServicesList/', request);
  }

  public getDirectService(request:ClientDirectServiceRequestModel) : Observable<ClientDirectServiceModel>
  { 
    return this.http.post<ClientDirectServiceModel>(this.URL + '/GetDirectServiceByGuid/', request);
  }


  public getDirectServiceDates(clientGuid: string) 
  { 
    return this.http.get(this.URL + '/directservicedates/' + clientGuid);
  }

  public saveDirectService(dsModel: any)
  {
    return this.http.post<PostResponse>(this.URL + '/save/', dsModel);
  }

  public deleteDirectService(dsModel: any)
  {
    return this.http.post<PostResponse>(this.URL + '/delete/', dsModel);
  }

  public saveAssessmentWithReferral(dsModel: any)
  {
    return this.http.post<PostResponse>(this.URL + '/saveAssessmentWithReferral/', dsModel);
  }

  public saveRiskReduction(dsModel: any)
  {
    return this.http.post<PostResponse>(this.URL + '/saveRiskReduction/', dsModel);
  }

  // public saveAssessmentReferralOutsideProvider(dsModel: any)
  // {
  //   return this.http.post<PostResponse>(this.URL + '/saveassessmentreferraloutsideprovider/', dsModel);
  // }  

  // public saveAssessment(dsModel: any)
  // {
  //   return this.http.post<PostResponse>(this.URL + '/saveassessment/', dsModel);
  // }

  // public saveReferral(dsModel: any)
  // {
  //   return this.http.post<PostResponse>(this.URL + '/savereferral/', dsModel);
  // }   

  public getFirstPMorMCMDirectServiceDeliveredDateAtAgency(startDate: Date, clientAltId: string) : Promise<FirstPMorMCMDirectServiceModel[]>
  {
    let params = new HttpParams();
    params = params.append('providerId', this.getClientData().providerId);
    params = params.append('clientAltId', clientAltId);
    params = params.append('startDate', startDate.toISOString());

    return this.http.get<FirstPMorMCMDirectServiceModel[]>(this.URL + '/FirstPMorMCMDirectServiceDeliveredDateAtAgency/', { params:params }).toPromise();
  }

  public getEISSubCategoryDirectServiceDates(clientGuid: string, subCatId: number) : Promise<EISSubCategoryDatesModel>
  {
    let params = new HttpParams();
    params = params.append('clientGuid', clientGuid);
    params = params.append('subCatId', subCatId);

    return this.http.get<EISSubCategoryDatesModel>(this.URL + '/EISSubCategoryDirectServiceDates/', { params:params }).toPromise();
  }

  public getClientDirectServicesSubset(clientGuid: string, daysBacktoSearch: number, serviceId: number, subId: number) : Promise<ClientDirectServicesSubsetModel[]>
  {
    let params = new HttpParams();
    params = params.append('clientGuid', clientGuid);
    params = params.append('daysBacktoSearch', daysBacktoSearch);    
    params = params.append('serviceId', serviceId);
    params = params.append('subId', subId);

    return this.http.get<ClientDirectServicesSubsetModel[]>(this.URL + '/ClientDirectServicesSubset/', { params:params }).toPromise();
  }

  public getSimilarClientDirectServices(clientGuid: string, deliveredDate: any, serviceId: number, subId: number) : Promise<SimilarClientDirectServiceModel[]>
  {
    let params = new HttpParams();
    params = params.append('clientGuid', clientGuid);
    params = params.append('deliveredDate', deliveredDate);    
    params = params.append('serviceId', serviceId);
    params = params.append('subId', subId);

    return this.http.get<SimilarClientDirectServiceModel[]>(this.URL + '/SimilarClientDirectServices/', { params:params }).toPromise();
  } 

  public getStandardFilterSelections() {
    return [{name:"Current Month", code:"0"},
            {name:"Last Month", code:"1"},
            {name:"Last 3 Months", code:"3"},
            {name:"Last 6 Months", code:"6"},
            {name:"Last 12 Months", code:"12"},
            {name:"Current Fiscal Year", code:"13"}];
  }

  //load mock client object
  public loadMockClient(clientGuid: string)
  { 
    this.clientService.getClientData(clientGuid); //clientAltId
    this.clientService.$clientGet.subscribe({
                                             next: (result) => { noop },
                                             error: (error) =>  { console.log(error); }, 
                                             complete: () => { this.clientData = this.clientService.clientData;} });    
  }

  public getClientService(): ClientFormService {
    return this.clientService;
  }

  public setClientService(clientService:ClientFormService) {
    this.clientService = clientService;
    this.clientData = this.clientService.clientData;
  }

  public getClientData(): ClientModel {
    return this.clientService?.clientData ?? this.clientData;
  }

  public async getEISSubCategoriesBillableData(subCategoryEIS: number) {
    // var returnObject: { firstBillableEISService: any; firstEISSubtypeDate: any; isEISSubCategoryBillable: boolean } = 
    //                   { firstBillableEISService: this.getDefaultDate(), firstEISSubtypeDate: this.getDefaultDate(), isEISSubCategoryBillable: false };

    var eisDates = await this.getEISSubCategoryDirectServiceDates(this.getClientData().guid, subCategoryEIS);
    eisDates.firstEISDatetime = parseISO(eisDates.firstEISDatetime);
    eisDates.firstBillableEISDatetime = parseISO(eisDates.firstBillableEISDatetime);
    eisDates.isEISSubCategoryBillable = eisDates.firstBillableEISDatetime.getTime() == this.getDefaultDate().getTime();    

    return eisDates;
  }

  public isEISTrueByDeliveredDateTime(deliveredDate: any, eisStartDate: any, eisEndDate: any)
  {
    // console.log(eisStartDate.getTime() == this.getDefaultDate().getTime()); console.log(eisEndDate.getTime() == this.getDefaultDate().getTime());
    // console.log(eisStartDate.getTime());
    // console.log(getTime(eisStartDate));
    // console.log(deliveredDate.getTime());
    // console.log(getTime(deliveredDate));
    // console.log(eisEndDate.getTime());
    // console.log(getTime(eisEndDate));

    if (eisStartDate.getTime() == this.getDefaultDate().getTime() || eisEndDate.getTime() == this.getDefaultDate().getTime()) return false;
    return (eisStartDate.getTime() <= deliveredDate.getTime() && deliveredDate.getTime() <= eisEndDate.getTime());
    // if (getTime(eisStartDate) == getTime(this.getDefaultDate()) || getTime(eisEndDate) == getTime(this.getDefaultDate())) return false;
    // return (getTime(eisStartDate) <= getTime(deliveredDate) && getTime(deliveredDate) <= getTime(eisEndDate));
  }  

  public getEISStartDate() {
    var eisStartDateVal = this.getDefaultDate();
    if (!this.getClientData()) return eisStartDateVal;

    if ((this.getClientData()?.newlyDiagnosed ?? DiagnosedYesNo.NoState) == DiagnosedYesNo.Yes)
    {
      if ((this.getClientData()?.clientMedicalCd4?.count < 200 && !this.getClientData()?.clientMedicalCd4?.unavailable) ||
          (this.getClientData()?.clientHivStatus?.id == HIVStatus.AIDS))
        eisStartDateVal = parseISO(this.getClientData()?.aidsDiagnosYear);
      else
        eisStartDateVal = parseISO(this.getClientData()?.hivConfirmYear);
    }

    return eisStartDateVal;
  }

  public async getEISEndDate()
  {
    var returnObject: { recievedMedical: boolean; eisDate: any; } = { recievedMedical: false, eisDate: this.getDefaultDate() }; //1900-01-01T00:00:00Z
    // agreed with Den to comment out the next 2 lines and handle refreshing in the medical case management screen
    //this.ParentClient.MedicalCasemanagementHistory.UpdateRyanWhiteMedicalVisits();
    //this.ParentClient.MedicalCasemanagementHistory.UpdateRyanWhiteFromDirectService(this);
    var eisStartDate:any = this.getEISStartDate();

    // date won't match unless you use the getTime() method
    if (!this.getClientData() || eisStartDate.getTime() == this.getDefaultDate().getTime()) return returnObject;

    //1002 ES Linkage SubCat
    var eis3MonthDate = addMonths(eisStartDate, 6); // DL CQM Meeting 2/27/2012 SP, KA, SM
    returnObject.eisDate = eis3MonthDate;

    var linkData = await this.clientService.getMCMVisitLinkageData(this.getClientData()?.clientAltId, formatISO(eisStartDate));
    if (!!linkData)
    {
        //this is to make the next if loop false if no value is set from database - Aj 08/02/2011
        var eisMedicalVisitDate = addDays(parseISO(linkData.visitDate) ?? eis3MonthDate, 1);
        returnObject.recievedMedical = linkData.linkage ?? false;
        if (eisMedicalVisitDate.getTime() < eis3MonthDate.getTime()) returnObject.eisDate = eisMedicalVisitDate;
    }

    // Condition 2) ANY primary medical or MCM subtype delivered at this agency. DL 8/2/2011
    var eisPMorMCMDirectServices = await this.getFirstPMorMCMDirectServiceDeliveredDateAtAgency(eisStartDate, this.getClientData()?.clientAltId);
    if (!!eisPMorMCMDirectServices && eisPMorMCMDirectServices?.length > 0)
    {
        var firstPMorMCMDirectService = eisPMorMCMDirectServices[0]; // the server is sorting by delivered date
        let eisMedicalVisitDate = parseISO(firstPMorMCMDirectService.deliveredDate);
        //This is linkage for any medical visit - Aj 08/14/2011 Ref: FogBugz 1112
        if (eisMedicalVisitDate.getTime() < eis3MonthDate.getTime()) returnObject.recievedMedical = true;

        var firstPMorMCMPrescribingPrivilegeDirectService = eisPMorMCMDirectServices
                                                            .sort(s => parseISO(s.deliveredDate).getTime())
                                                            .find(f => this.commonDataService.isSubTypePrescribingPrivilege(f.subId) == true);
        if (!!firstPMorMCMPrescribingPrivilegeDirectService) eisMedicalVisitDate = parseISO(firstPMorMCMPrescribingPrivilegeDirectService.deliveredDate);

        //This is the eis end date only calculated by prescribing visits  - Aj 08/14/2011 Ref: FogBugz 1112
        if(eisMedicalVisitDate.getTime() < eis3MonthDate.getTime()) returnObject.eisDate = eisMedicalVisitDate;
    }

    return returnObject;
  }

  public isPaySourceBillable(dsModel: Partial<ClientDirectServiceModel>, paySourceReasonCount: number): boolean {    
    var referralSourceCheckStartDate = parseISO(environment.referralSourceCheckStartDate) ?? this.getDefaultDate();
    let serviceList: Array<number> = [ 87, 88 ];
    let paySourceList: Array<number> = [ 16, 17 ];

    if (referralSourceCheckStartDate.getTime() < parseISO(dsModel.deliveredDate).getTime() &&
        (dsModel.serviceId == 81 || (serviceList.includes(dsModel.serviceId) && paySourceList.includes(dsModel.paySourceId))) &&
        (dsModel.paySourceId == 18 || (dsModel.paySourceId > 0 && (dsModel.paySourceReasonId <= 0 && paySourceReasonCount > 0))) &&
        (this.commonDataService.isFunderRyanWhite(dsModel.funder) || dsModel.funder == 20)) 
    {
      return false;
    }
    return true;
  }

  public async isAllowedBillable(deliveredDate: any)
  {
    var currentDateTime = await this.commonDataService.getCurrentDateTime();
    //console.log(currentDateTime);

    //increment to next month and get to the first of the month
    var dtNextMonth = startOfMonth(addMonths(parseISO(deliveredDate), 1));
    var adminBillableDays = (this.providerService.data?.options[0]?.adminBillableDays ?? 0);
    //console.log(dtNextMonth);
    
    //now add the # of billable days passed the end of the month
    if (adminBillableDays > 0) dtNextMonth = addDays(dtNextMonth, (adminBillableDays - 1));
    //console.log(dtNextMonth);
    dtNextMonth = endOfDay(dtNextMonth);
    //console.log(dtNextMonth);
    
    if (currentDateTime.getTime() <= dtNextMonth.getTime()) return true;
    
    return false;
  }

  public async isValidHopwaWorksheetData(dsModel: Partial<ClientDirectServiceModel>)
  {
      var retVal: boolean = true;
      //Perform validation if sub type is Rental Assistance - Long Term
      //if not Rental Assistance - Long Term then return true = "424"
      //Funder must equal Hopwa = "3"
      //Service type = Housing and Related Services


      //comment out for testing
      if (!((dsModel.subId == 424) && (dsModel.funder == 3) && (dsModel.serviceId == 91)))
          return retVal;

      //if no Hopwa Worksheet data then return false
      if (!this.getClientData()?.clientHopwaWorkSheet || this.getClientData()?.clientHopwaWorkSheet?.length <= 0) return false;

      retVal = await (this.isMostRecentHopwaWorksheetExpired(dsModel.deliveredDate));
      return (!retVal);
  }

  public async isMostRecentHopwaWorksheetExpired(workSheetDate)
  {
      //must check to see if the latest client hopwa worksheet item is not expired
      var numDaysToExpire = -1;
      
      try
      {
        var fieldId = RequiredFields.HopwaWorksheetDate;
        //fieldId = 96; //used for testing
        numDaysToExpire = this.providerService.getRequiredField(fieldId)?.expiresDays;
      }
      catch { }
      if (numDaysToExpire >= 0)
      {
        var hopwaWorksheet = orderBy(this.getClientData()?.clientHopwaWorkSheet, ['datetime'], ['desc']).find(f => f.datetime <= workSheetDate);
        if (!!hopwaWorksheet)
        {
          //check if that worksheet is expired
          var currentDateTime = await this.commonDataService.getCurrentDateTime();
          var compareDateTime = subDays(currentDateTime, numDaysToExpire);
          if (compareDateTime.getTime() > parseISO(hopwaWorksheet.datetime).getTime())
            return true;
        }
      }
      return false;
  }

  public async isHopwaWorksheetDataQualified(dsModel: Partial<ClientDirectServiceModel>)
  {
      var retVal = true;
      //Perform validation if sub type is Rental Assistance - Long Term
      //if not Rental Assistance - Long Term then return true = "424"
      //Funder must equal Hopwa = "3"
      //Service type = Housing and Related Services
      if (!((dsModel.subId == 424) && (dsModel.funder == 3) && (dsModel.serviceId == 91)))
          return retVal;

      //if no Hopwa Worksheet data then return true
      if (!this.getClientData()?.clientHopwaWorkSheet || this.getClientData()?.clientHopwaWorkSheet?.length <= 0) return retVal;

      retVal = await this.isMostRecentHopwaWorksheetQualified(dsModel.deliveredDate);
      return retVal;
  }

  public async isMostRecentHopwaWorksheetQualified(workSheetDate)
  {
    var hopwaWorksheet = orderBy(this.getClientData()?.clientHopwaWorkSheet, ['datetime'], ['desc']).find(f => f.datetime <= workSheetDate);
    if (!!hopwaWorksheet)
    {
      var hopwaTotal = await this.getHopwaHopwaTotal(hopwaWorksheet);
      if (hopwaTotal <= 0)
        return false;
    }
    return true;
  }

  public async getHopwaHopwaTotal(hopwaWorksheet: ClientHopwaWorksheetModel)
  {
    var hopwaTotal = 0;
    hopwaTotal = (hopwaWorksheet.rent - this.getHopwaTenantPortion(hopwaWorksheet));

    var housingReimburseAmount = await this.getHopwaHousingReimburseAmount(hopwaWorksheet);
    if (hopwaWorksheet.rent > housingReimburseAmount)
        hopwaTotal = hopwaTotal + (housingReimburseAmount - hopwaWorksheet.rent);

    return round(hopwaTotal, 2);
  }

  public getHopwaTenantPortion(hopwaWorksheet: ClientHopwaWorksheetModel): number
  {
    return round(this.getHopwaAdjustedMonthlyIncome(hopwaWorksheet) * this.commonDataService.getHopwaTenantPercent(), 2);
  }

  public getHopwaAdjustedMonthlyIncome(hopwaWorksheet: ClientHopwaWorksheetModel): number
  {
    var annualIncome = this.getHopwaAnnualIncome(hopwaWorksheet);
    var hopwaDisability = this.commonDataService.getHopwaDisability();
    var hopwaChildrenReimburse = this.getHopwaChildrenReimburse(hopwaWorksheet);
    if (annualIncome > 0 && annualIncome > Math.abs(hopwaDisability + hopwaChildrenReimburse))
      return round(((annualIncome - hopwaDisability - hopwaChildrenReimburse) / 12), 2);
    else
      return 0;
  }

  public getHopwaChildrenReimburse(hopwaWorksheet: ClientHopwaWorksheetModel): number
  {
    return round((this.commonDataService.getHopwaConfig().perChild * hopwaWorksheet.numChildren), 2);
  }  

  public getHopwaAnnualIncome(hopwaWorksheet: ClientHopwaWorksheetModel): number
  {
    if (hopwaWorksheet.payrollPeriod == PayRollPeriod.Weekly)
      return round(hopwaWorksheet.income * 52, 2);
    else if (hopwaWorksheet.payrollPeriod == PayRollPeriod.BiWeekly)
      return round(hopwaWorksheet.income * 26, 2);
    else if (hopwaWorksheet.payrollPeriod == PayRollPeriod.Monthly)
      return round(hopwaWorksheet.income * 12, 2);
    else
      return 0;
  }

  public async getHopwaHousingReimburseAmount(hopwaWorksheet: ClientHopwaWorksheetModel)
  {
    var hopwaHousingRate = await this.getHopwaHousingRateDataRow(hopwaWorksheet);
    if (!!hopwaHousingRate) {
      return round(hopwaHousingRate.reimburse, 2);
    }
    return 0;
  }

  public async getHopwaHousingRateDataRow(hopwaWorksheet: ClientHopwaWorksheetModel)
  {
      //Make sure master_hopwa_rates uses codes 0, 1, 2, 3, 4 to signify the number of bedrooms
      //So if the current batch of rates expires and a new batch is entered make sure the
      //new batch only uses 0, 1, 2, 3, 4 in the code field
      //added 4th bedroom rate
    var numRooms = hopwaWorksheet.numBedrooms;
    if (numRooms > 4) numRooms = 4;

    //look up zip code first and once have the zip then lookup county and state from
    var hopwaWorksheetZipCodeData = await this.clientService.getHopwaWorksheetZipCodeData(hopwaWorksheet.clientZipCodeGuid);
    var hopwaHousingRate = this.commonDataService.getHopwaHousingRate(hopwaWorksheet.numBedrooms, hopwaWorksheetZipCodeData.county, hopwaWorksheetZipCodeData.state);

    //if no match for rates under a county then use default rates
    if (!hopwaHousingRate)
      hopwaHousingRate = this.commonDataService.getHopwaHousingRate(numRooms, '', '');

    return hopwaHousingRate;
  }  

  // commented out since logic is invalid after speaking with Den on 7/22/2021
  // public bool IsValidHIVStatus()
  // {
  //   bool retVal = true;
  //   short hivStatusID = 0;
  //         int age = 0;
  //         if (this.ParentClient != null)
  //         {
  //             if (this.ParentClient.HIVStatusHistory.Count > 0)
  //             {
  //                 HIVStatus objHIVStatus = this.ParentClient.HIVStatusHistory[this.ParentClient.HIVStatusHistory.Count - 1];
  //                 hivStatusID = objHIVStatus.ID;
  //             }
  //             age = ParentClient.Age;
  //         }
  //         else //if ParentClient is null then this is being called from Bulk/Group
  //         {
  //             string strAdHoc = "exec sp_GetHIVStatusAge " + m_AppContext.CurrentProvider.ID.ToString() + ", '" + this.ClientAltID + "'";
  //             DataSet dsDSHIVStatusAge = this.m_AppContext.ExecuteAdHocQuery(strAdHoc);
  //             if (dsDSHIVStatusAge != null && dsDSHIVStatusAge.Tables[0].Rows.Count > 0)
  //             {
  //                 if (dsDSHIVStatusAge.Tables[0].Rows[0]["hiv_status"] != System.DBNull.Value)
  //                     hivStatusID = Convert.ToInt16(dsDSHIVStatusAge.Tables[0].Rows[0]["hiv_status"]);
  //                 if (dsDSHIVStatusAge.Tables[0].Rows[0]["age"] != System.DBNull.Value)
  //                     age = Convert.ToInt32(dsDSHIVStatusAge.Tables[0].Rows[0]["age"]);
  //             }
  //         }
  //         if (age > 2)
  //   {
  //     foreach (ProviderFieldException objFldException in m_AppContext.CurrentProvider.FieldExceptions)
  //     {
  //       //Only perform a check if at least one HIV Status match occurs
  //       if (hivStatusID == objFldException.HIVStatus)
  //       {
  //         retVal = false;
  //         //if sub id is zero then any sub types fulfill the match
  //         //or if an exact match by sub id
  //         if ((objFldException.SubID == 0) || (objFldException.SubID == this.SubCat))
  //         {
  //           //if service category matches then we the HIV status type is OK.
  //           if (objFldException.ServiceCategory == this.ServiceCat)
  //           {
  //             retVal = true;
  //             break;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   return retVal;
  // }

  // This may be able to move into the Client Service, need to discuss with Den 7-26-2021
  public async isValidMedicalCaseManagementData(serviceId: number, deliveredDate: any)
  {
    var retVal = true;
    //Perform Validation if service category is Medical Case Management
    if (serviceId != 87) return retVal;
    // if (!this.clientData?.clientMedicalCaseManagement || this.clientData?.clientMedicalCaseManagement.length <= 0) return false;

    var currentDateTime = await this.commonDataService.getCurrentDateTime();
    if (this.getClientData()?.clientMedicalCaseManagement?.length == 1)
    {
        var firstMedicalVisit = this.getClientData()?.clientMedicalCaseManagement[0];
        if (firstMedicalVisit.unavailable)
        {
          var firstMedicalVisitPlus90 = addDays(parseISO(firstMedicalVisit.lastUpdate), 90);
          if (firstMedicalVisitPlus90.getTime() < currentDateTime.getTime())
              return false;
          else
              return true;
        }
    }
    retVal = this.isMostRecentPhysicianVisitExpired(deliveredDate, currentDateTime);
    return retVal;
  }

  public isMostRecentPhysicianVisitExpired(dtServiceDate: any, currentDateTime: any)
  {
    var numDaysToExpire = -1;
    
    try
    {
      var fieldId = RequiredFields.LastMCMPhysicianVisitDate;
      numDaysToExpire = this.providerService.getRequiredField(fieldId)?.expiresDays;
      //numDaysToExpire = 6; // used for testing
      if (!numDaysToExpire) return true;
    }
    catch { }

    if (numDaysToExpire >= 0)
    {
        var medicalCaseManagement = this.getMostRecentPhysicianVisitDataItem(dtServiceDate);
        if (!!medicalCaseManagement && !!medicalCaseManagement.visitDate)
        {
          var physicianVisitDate = parseISO(medicalCaseManagement.visitDate);
          var currentExpireDateTime = subDays(currentDateTime, numDaysToExpire);
          //check if that Medical Visit is expired
          //check if it the first time unavailable 
          if (medicalCaseManagement.unavailable) return true;
          if (currentExpireDateTime.getTime() < physicianVisitDate.getTime()) return true;
        }
    }

    return false;
  }

  public getMostRecentPhysicianVisitDataItem(dtServiceDate: any)
  {
    return orderBy(this.getClientData()?.clientMedicalCaseManagement, ['visitDate'], ['desc']).find(f => f.visitDate <= dtServiceDate);
  }

  public async isDirectServiceBillableByFunderBillingRestrictionEncounter(dsModel: Partial<ClientDirectServiceModel>)
  {
    if (this.isFunderBillingRestrictionEncounter())
    {
        var isSubTypeAllow2xEncounterBilling = this.commonDataService.isSubTypeAllow2xEncounterBilling(dsModel.subId);
        var similarClientDirectServices = await this.getSimilarClientDirectServices(this.getClientData().guid, dsModel.deliveredDate, dsModel.serviceId, dsModel.subId);

        if (!!similarClientDirectServices && !isSubTypeAllow2xEncounterBilling)
        {
          var similarClientDirectServices = similarClientDirectServices.filter(f => f.guid != dsModel.guid && f.funder != dsModel.funder);
          if (similarClientDirectServices.length > 0) return false;
        }
    }

    return true;
  }

  public isFunderBillingRestrictionEncounter(): boolean
  {
    var funderBillingRestrictionEncounter = this.providerService.data?.options[0]?.funderBillingRestrictionEncounter;

    if (!!funderBillingRestrictionEncounter)
    { 
      if (this.userService.hasAdminPermission) return false;
      return funderBillingRestrictionEncounter;
    }

    return false;
  }

  public isEmergencyFinancialOtherAllowed(serviceId: number, subId: number) {
    //if not an admin, then Emergency Financial Ass Other needs approval
    if ((serviceId == 90 && subId == 389) &&
        (!(this.userService.hasAdminPermission || this.userService.hasDHHSPermission)))
    {
      return false;
    }
    return true;
  }

  public isInPatientOverride(serviceId: number, subId: number) {
      //12/8/2006 Den wanted to override Inpatient sub type(s) 112;196 to bypass 
      //the required field check
      if (serviceId == 112 || subId == 196)
          return true;
      else
          return false;
  }

  public isMissingRequiredFieldsInCategory(serviceId: number) {
    if (!!this.clientService.clientRequiredFieldErrors) {
      let list: Array<number> = [ 0, serviceId ]; 
      let cnt = this.clientService.clientRequiredFieldErrors.filter(f => list.includes(f.serviceId)).length;
      return (cnt > 0 ? true: false);
    }
    return false;
  }

  public isARequiredFieldNotBillableFoundInCategory(serviceId: number) {
    if (!!this.clientService.clientRequiredFieldErrors) {
      let list: Array<number> = [ 0, serviceId ]; 
      let cnt = this.clientService.clientRequiredFieldErrors.filter(f => list.includes(f.serviceId) && !f.allowBillable).length;
      return (cnt > 0 ? true: false);
    }
    return false;
  }

  public async isBillableRegardlessMissingFields(dsModel: Partial<ClientDirectServiceModel>): Promise<MoreThan4UnitsIn12MonthsResult> {
      //var modal = <IModal>{};
      var moreThan4UnitsIn12MonthsResult = <MoreThan4UnitsIn12MonthsResult> { billableRegardlessMissingFieldsResult: false, remainingUnits: 0};
      // moreThan4UnitsIn12MonthsResult.billableRegardlessMissingFieldsResult = false;
      // moreThan4UnitsIn12MonthsResult.remainingUnits = 0;

      if (this.commonDataService.isSubTypIncluded4xPer12MonthCheck(dsModel.subId))
      {
        const numberDaysToSearchInPast = 365;
        const IdForAllServices = 0;
        moreThan4UnitsIn12MonthsResult.totalDeliveredUnits = 0;
        moreThan4UnitsIn12MonthsResult.remainingUnits = 0;

        // look at active(non deleted) services within the past year that are billable for the same sub type
        var similarClientDirectServices = await this.getClientDirectServicesSubset(this.getClientData().guid, numberDaysToSearchInPast, IdForAllServices, dsModel.subId);
        if (!!similarClientDirectServices) {
          moreThan4UnitsIn12MonthsResult.totalDeliveredUnits = sumBy(similarClientDirectServices, service => { return service.billable ? service.deliveredUnits : 0; });
          //moreThan4UnitsIn12MonthsResult.totalDeliveredUnits = 2; // used for testing
          if (moreThan4UnitsIn12MonthsResult.totalDeliveredUnits <= 4) { 
            moreThan4UnitsIn12MonthsResult.remainingUnits = 4 - moreThan4UnitsIn12MonthsResult.totalDeliveredUnits;
          }
        }
        moreThan4UnitsIn12MonthsResult.billableRegardlessMissingFieldsResult = (moreThan4UnitsIn12MonthsResult.remainingUnits >= (dsModel.deliveredUnits ?? 0));
      }

      return moreThan4UnitsIn12MonthsResult;
  }

  public async isMoreThan4UnitsIn12Months(dsModel: Partial<ClientDirectServiceModel>): Promise<MoreThan4UnitsIn12MonthsResult> {
    var moreThan4UnitsIn12MonthsResult = <MoreThan4UnitsIn12MonthsResult> { billableRegardlessMissingFieldsResult: false, 
                                                                            moreThan4UnitsIn12MonthsResult: false, remainingUnits: 0};
    // moreThan4UnitsIn12MonthsResult.billableRegardlessMissingFieldsResult = false;
    // moreThan4UnitsIn12MonthsResult.moreThan4UnitsIn12MonthsResult = false;
    // moreThan4UnitsIn12MonthsResult.remainingUnits = 0;    
    // isSubTypIncluded4xPer12MonthCheck is also called in isBillableRegardlessMissingFields but we still need to call it here in the outer call
    // otherwise you can have an invalid scenario returned which would produce an invalid result. Converted as is from CHAMP 3.5
    if (this.commonDataService.isSubTypIncluded4xPer12MonthCheck(dsModel.subId)) {
      moreThan4UnitsIn12MonthsResult = await this.isBillableRegardlessMissingFields(dsModel);
      moreThan4UnitsIn12MonthsResult.moreThan4UnitsIn12MonthsResult = !moreThan4UnitsIn12MonthsResult.billableRegardlessMissingFieldsResult;
    }
    return moreThan4UnitsIn12MonthsResult;
  }

  public CheckforHardCodedFunderRelatedRequiredFields(funderId: number) {
    let list: Array<number> = [ 21 ]; // Funding sources that will require a Covid Test
    return list.includes(funderId);
  }

  public isMHScreeningNotBillableBasedOnSubtypes(dsModel: Partial<ClientDirectServiceModel>) {
    //if at least one required field in the same category is found to be not billable then return true
    var retVal = false;
    var existsInIncludedSubtypes = false;

    if (!!dsModel.subTypeInclusionIds) existsInIncludedSubtypes = this.commonDataService.anyMHScreeningSubTypes(dsModel.subTypeInclusionIds);

    // Lost to care follow-up condition
    if (!!this.clientService.clientRequiredFieldErrors && 
        (this.commonDataService.MHScreeningSubTypes.includes(dsModel.subId) || existsInIncludedSubtypes)) {

        var cnt = this.clientService.clientRequiredFieldErrors.filter(f => (f.serviceId == 0 || f.serviceId == 85) && !f.allowBillable).length;
        if (cnt > 0) retVal = true;
    }

    return retVal;
  }

  //public linkContractLineItem(dsModel: Partial<ClientDirectServiceModel>) {
  public isValidContractLineItemFound(dsModel: Partial<ClientDirectServiceModel>) {
    // establish Contract Line Item linkage
    var delDate = this.helperService.castToDate(dsModel.deliveredDate);
    var providerContractItems = this.providerService.getProviderContractItemsByGuid(dsModel.contractGuid)
                                                    .filter(f => f.serviceId == dsModel.serviceId)
                                                    .filter(f => f.funder == dsModel.funder)
                                                    .filter(f => delDate.getTime() >= parseISO(f.startDate).getTime())
                                                    .filter(f => delDate.getTime() <= parseISO(f.endDate).getTime());
    if (!providerContractItems) return false;

    // start with direct sub id match
    var subId = dsModel.subId ?? 0;
    var contractItem = providerContractItems.find(f => f.subId == subId);
    if (!contractItem) {
      var isGroupSubType = this.commonDataService.isGroupSubType(dsModel.subId);
      // if no direct match then try group or non group match
      if (isGroupSubType) { 
        subId = 777; 
      }
      else {
        subId = 888; 
      }
      contractItem = providerContractItems.find(f => f.subId == subId);
      if (!contractItem) {
        // if still not match finally try 999
        subId = 999;
        contractItem = providerContractItems.find(f => f.subId == subId);
      }
    }

    return !!contractItem;
  }

  public async getFirstUnbillableReason(dsModel: Partial<ClientDirectServiceModel>, paySourceReasonCount: number, defaultReason: number): Promise<number> {
    // default reasons should be Billable, UserMarkUnbillable, or IncompleteOrInvalidAssessment
    // Billable & UserMarkUnbillable should happen through screen interaction with the billable checkbox
    // IncompleteOrInvalidAssessment will happen because of the popup dialog for certain required assessment types before onSaveDirectServiceChanges is called
    // IncompleteOrInvalidAssessment will happen last in the final return statement at the end of this function after all other validations occur
    var reason: number = defaultReason;
    // comment out this for testing
    //if (!(dsModel.billable ?? false)) return reason; // spoke with den on 10/6/2021 he said to exit out when non billable

    // this is a special case when saving a new direct service and a required assessment is not entered
    // we initially have to save the direct service as non billable with an IncompleteOrInvalidAssessment error and
    // then after the assessment popup shuts down it will re-enter this routine to see if there are any other errors on the newly created
    // direct service, the reason we need the initial save to happen is because when the assessment popup opens up for a new direct service 
    // that service needs to be created in the database so the assessment can save correctly because of the foreign key constraint between assessments and direct services
    if (reason == UnbillableReason.IncompleteOrInvalidAssessment) return reason;

    // ****** all the rules below are coming from frmClientChar.DirectServiceAdd(bool blnFlagDeleted) in CHAMP3.5 ******
    var requiredFieldResult = await this.clientService.validateRequiredFields(dsModel.clientGuid, dsModel.userId, RequiredFieldGroup.ExcludeDirectServiceAndReferral);

    if (this.isPaySourceBillable(dsModel, paySourceReasonCount) == false) { 
      return UnbillableReason.PaymentSourceNotBillable;
    }

    if (await this.isAllowedBillable(dsModel.deliveredDate) == false) {
      return UnbillableReason.DeadlineMissed;
    }

    ////if (objDirectService.IsValidHIVStatus() == false) ***** commented out since logic is invalid after speaking with Den on 7/22/2021
    ////{ return UnbillableReason.HIVAffectedIndeterminateClient }

    //// //this currently doesn't have an unbillable reason in CHAMP35 but we need to use something to flag the ds as not billable when saving
    //// if (await this.isMostRecentHopwaWorksheetExpired(dsModel.deliveredDate) == false) {
    ////   return UnbillableReason.UserMarkUnbillable;
    //// }

    //// //this currently doesn't have an unbillable reason in CHAMP35 but we need to use something to flag the ds as not billable when saving
    //// if (await this.isHopwaWorksheetDataQualified(dsModel) == false) {
    ////   return UnbillableReason.UserMarkUnbillable;
    //// }

    if (await this.isValidMedicalCaseManagementData(dsModel.serviceId, dsModel.deliveredDate) == false) {
      return UnbillableReason.MissingMCMData;
    }

    if (await this.isDirectServiceBillableByFunderBillingRestrictionEncounter(dsModel) == false) {
      return UnbillableReason._2XSameEncounter;
    }

    if (this.isEmergencyFinancialOtherAllowed(dsModel.serviceId, dsModel.subId) == false) {
      return UnbillableReason.RequiresApproval;
    }

    if (await this.clientService.isValidAgeForIndeterminateStatus() == HIVIndeterminateStatusResult.IndeterminateStatusInvalidAge) {
      return UnbillableReason.HIVAffectedIndeterminateClient;
    }
    
    if (requiredFieldResult != RequiredFieldResult.Pass &&
        this.isMissingRequiredFieldsInCategory(dsModel.serviceId) && !this.isInPatientOverride(dsModel.serviceId, dsModel.subId) &&
        this.isARequiredFieldNotBillableFoundInCategory(dsModel.serviceId)) {
      return UnbillableReason.MissingRequiredField;
    }
    
    // MS 10-7-2024 fixed added missing await
    var result = await this.isMoreThan4UnitsIn12Months(dsModel);
    if (result.moreThan4UnitsIn12MonthsResult) {
      return UnbillableReason.MoreThan4UnitsIn12Months;
    }

    // ****** all the rules below are coming from Client.DirectServices.AddDirectService(DirectService rec) in CHAMP3.5 ******
    if (requiredFieldResult != RequiredFieldResult.Pass &&
      this.isMissingRequiredFieldsInCategory(dsModel.serviceId) && !this.isInPatientOverride(dsModel.serviceId, dsModel.subId) &&
      this.CheckforHardCodedFunderRelatedRequiredFields(dsModel.funder)) {
      return UnbillableReason.MissingRequiredField;
    }

    if (requiredFieldResult != RequiredFieldResult.Pass &&
      this.isMissingRequiredFieldsInCategory(dsModel.serviceId) && !this.isInPatientOverride(dsModel.serviceId, dsModel.subId) &&
      this.isMHScreeningNotBillableBasedOnSubtypes(dsModel)) {
      return UnbillableReason.MissingRequiredField;
    }
    
    // Lost to care follow-up condition (this rule seems redundant but following what's in CHAMP3.5)
    // if (result.billableRegardlessMissingFieldsResult) {
    //   return UnbillableReason.Billable;
    // }
    // else {
    //   return UnbillableReason.MoreThan4UnitsIn12Months;
    // }    
    var result = await this.isBillableRegardlessMissingFields(dsModel);
    if (result.billableRegardlessMissingFieldsResult && 
        reason == UnbillableReason.MissingRequiredField) return UnbillableReason.Billable;

    // MS 10-7-2024 not needed because isMoreThan4UnitsIn12Months call above with set UnbillableReason.MoreThan4UnitsIn12Months if criteria matches        
    // if (!result.billableRegardlessMissingFieldsResult && result.moreThan4UnitsIn12MonthsResult) {
    //   return UnbillableReason.MoreThan4UnitsIn12Months;
    // }

    // Den said no contract match should NOT mark the direct service non billable, so commenting out
    // if (!this.isValidContractLineItemFound(dsModel)) {
    //   return UnbillableReason.NoContractItemMatch;
    // }

    // skipping this logic in CHAMP3.5 because the validators are catching all the errors up front
    //only scan direct service fields
    //retVal = this.ParentClient.CheckRequiredFields(CHAMP.BusinessObjects.Client.RequiredFieldGroup.DirectService);
    //there can't be any allowable errors 
    //if (retVal == bus.Client.RequiredFieldResult.PassWithError)
    //    retVal = bus.Client.RequiredFieldResult.RequiredFail;
    //if (retVal == bus.Client.RequiredFieldResult.RequiredFail)
    //{
    //    //since there was invalid or missing data then remove the item from the collection
    //    this.Remove(rec);
    //}

    // this.ValidateClientSide(); changed name to linkContractLineItem
    //this.linkContractLineItem(dsModel); // added logic to onSaveDirectServiceChanges in the client-direct-services.component

    // AddPaySourceToSet(rec.Guid, rec.PaySourceID); // added logic to backend SaveDirectService

    return reason;
  }

}
