import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { Observable, Subject, from, noop, of, BehaviorSubject, lastValueFrom, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogRef, DynamicDialogConfig, DialogService } from 'primeng/dynamicdialog';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginService } from 'src/app/login/services/login.service';
import { UserService } from 'src/app/services/user.service';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MergeClientList, MergeClientRequestModel } from 'src/app/models/client.model';
import { ClientMergeService } from './client-merge.service';
import { ProviderService } from 'src/app/services/provider.service';
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { Table } from 'primeng/table';
import { PostResponse } from 'src/app/models/provide.model';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-client-merge',
  templateUrl: './client-merge.component.html',
  styleUrls: ['./client-merge.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class ClientMergeComponent {
  @ViewChild('dtSourceClients') dtSourceClients: Table;
  @ViewChild('dtDestinationClients') dtDestinationClients: Table;

  fg: UntypedFormGroup;
  diagRef: DynamicDialogRef;
  providers: any; //DropDownItem[];
  //users: DropDownItem[];  
  sourceClients: Partial<MergeClientList>[];
  destinationClients: Partial<MergeClientList>[];

  getProviderIdControl = () => this.fg?.get('providerId');
  getSourceClients = () => this.sourceClients;
  getDestinationClients = () => this.destinationClients;
  getSourceClientAltIdControl = () => this.fg?.get('sourceClientAltId');
  getDestinationClientAltIdControl = () => this.fg?.get('destinationClientAltId');
  isFormValid = () => !!this.dtSourceClients?._selection && !!this.dtDestinationClients?._selection && 
                        this.dtSourceClients?._selection?.length == 1 && this.dtDestinationClients?._selection?.length == 1 &&
                        this.dtSourceClients?._selection[0].clientAltId != this.dtDestinationClients?._selection[0].clientAltId;

  constructor(private fb: UntypedFormBuilder,
              private app: AppContextService,
              private authService: AuthService,
              private loginService: LoginService,
              //private clientService: ClientService,
              //private commonDataService: CommonDataService,
              private userService: UserService,
              private helper: HelperClassService,
              private router: Router,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              private validations: FormValidations,
              public dialogService: DialogService,
              private clientMergeService: ClientMergeService,
              private providerService: ProviderService,
              private spinnerService: SpinnerService
              ) {
    this.fg = fb.group({
      sourceClientAltId: ["AA"],
      destinationClientAltId: ["AA"],
      providerId: [0] //this.providerService.providerID
    },
    {
      //validators: [this.customValidator()],
      //asyncValidators: [this.timePeriodValidator()]
      //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
    });      
  }

  ngOnInit(): void {
    // if(!this.authService.isUserAuthenticated){
    //   this.loginService.standAloneLogin().then(x => this.loadData()).then(x => this.loadProviders());
    // }
    // else this.loadData();
    
    this.app.updateTabTitle('CHAMP - Client Merge');
    this.loadProviders();
    this.loadData(); 
  }
  
  loadData() {
    this.loadSourceClients(this.getSourceClientAltIdControl().value);
    this.loadDestinationClients(this.getDestinationClientAltIdControl().value);
  }

  onChangeSourceFilter(e, dtSourceClients) {
    //this.dtSourceClients._selection = null;
    this.loadSourceClients(this.getSourceClientAltIdControl().value);
  }

  onChangeDesintationFilter(e, dtSourceClients) {
    //this.dtDestinationClients._selection = null;
    this.loadDestinationClients(this.getDestinationClientAltIdControl().value);
  }

  onChangeProviderFilter(e) {

  }

  loadProviders() {
    this.userService.userInfo$.subscribe({
      next: (data: any) => {
        if (!!data) {
          this.providers = this.userService.getAccessibleProviders().map(provider => { const result: any = {value: `${provider.providerId}`, 
                                                                                                            label: `${provider.providerName}`};
                                                                                       return result; });
          // default to current Provider logged into
          this.getProviderIdControl().setValue(this.providers[0]?.value); //this.providerService.providerID
        }
      },
      error: (error) => {
        this.app.userServ.LogError(error);
      },
      complete:() => {
      }         
    });
  }  

  loadSourceClients(clientAltId: string) {
    if (!!this.dtSourceClients) this.dtSourceClients._selection = null;
    this.spinnerService.showSpinnerUntilCompleted(this.clientMergeService.getMergeClientList(this.getProviderIdControl().value, clientAltId)).subscribe({
      next: (data: MergeClientList[]) => {
            //console.log(data);
            this.sourceClients = data;
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => { }
      });
  }

  loadDestinationClients(clientAltId: string) {
    if (!!this.dtDestinationClients) this.dtDestinationClients._selection = null;
    this.spinnerService.showSpinnerUntilCompleted(this.clientMergeService.getMergeClientList(this.getProviderIdControl().value, clientAltId)).subscribe({
      next: (data: MergeClientList[]) => {
            //console.log(data);
            this.destinationClients = data;
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => { }
      });
  }

  onMergeClientConfirm(event: Event) {
    var sourceAltId = this.dtSourceClients?._selection[0].clientAltId;
    var destAltId = this.dtDestinationClients?._selection[0].clientAltId;
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure that you want to merge " + sourceAltId + " into " + destAltId + "?",
      icon: "pi pi-info-circle",
      accept: () => {
        // console.log(this.dtSourceClients._selection);
        // console.log(this.dtDestinationClients._selection);
        var sourceGuid = this.dtSourceClients?._selection[0].clientGuid;
        var destGuid = this.dtDestinationClients?._selection[0].clientGuid;
        var request: Partial<MergeClientRequestModel> = { sourceProviderId: this.getProviderIdControl().value, sourceClientAltId: sourceAltId, destinationClientAltId: destAltId,
                                                          sourceClientGuid: sourceGuid, destinationClientGuid: destGuid, deleteSource: true };
        // Object.assign(request, {});
        this.spinnerService.showSpinnerUntilCompleted(this.clientMergeService.mergeClient(request)).subscribe({
          next: (data: PostResponse) => {
                //console.log(data);
                // refresh since client alt id that was just merged is now flagged deleted
                this.loadSourceClients(this.getSourceClientAltIdControl().value);
                this.loadDestinationClients(this.getDestinationClientAltIdControl().value);
              },
          error: (error) => {
                this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
                this.app.userServ.LogError(error);
              },
          complete: () => { 
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Changes saved successfully.', life: this.app.messageDisplayTime});
            }
          });        
      },
      reject: () => {
        this.messageService.add({
          severity: "error",
          summary: "Aborted",
          detail: "Changes Canceled",
          life: 2000
        });
      }
    });
  }
}
