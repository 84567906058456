import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl,
         AsyncValidatorFn, ValidationErrors } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable, Subject, from, noop, of, lastValueFrom } from 'rxjs';
import { DividerModule } from 'primeng/divider';
import { formatISO, getMonth, getYear, parseISO } from 'date-fns';
import { ReportsService } from 'src/app/reports/services/reports.service';
import { ReportConfigGroupListModel, ReportConfigReportsModel, ReportsModel } from '../../models/reports.model';
import { UserService } from 'src/app/services/user.service';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { SpinnerService } from 'src/app/common/spinner/spinner.service';
import { firstValueFrom } from 'rxjs';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { DirectServiceValidatorsService } from 'src/app/validators/direct-service-validators.service';
import { YesNo, PosNegResults, ReferralStatus, ClientReferralYesNo } from "src/app/models/enums";
import { ClientReferral, ClientReferralModel } from 'src/app/client-direct-services/services/client-direct-service-models';
import { CommonDataService } from 'src/app/services/common-data.service';
import { ClientReferralsRequestModel, RequestType, ClientReferralsModel } from 'src/app/models/client_referrals.models';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { ReferralService } from '../referral-edit-list/referrals.service';
import { HelperClassService } from 'src/app/services/helper-class.service';
//import { PdfViewerComponent } from 'ng2-pdf-viewer';
//import { DynamicDialogConfig } from 'primeng/dynamicdialog';
//import { ClientFormHelper} from 'src/app/client-form/client-form.helper';
//import { DialogService } from 'primeng/dynamicdialog';
//import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-referral-ticket',
  // standalone: true,
  // imports: [],
  templateUrl: './referral-ticket.component.html',
  styleUrl: './referral-ticket.component.scss',
  providers:[MessageService, ConfirmationService]
})

export class ReferralTicketComponent {
  @Output("onRefresh") refreshReferral: EventEmitter<any> = new EventEmitter();
  //@ViewChild('pdfViewer') public pdfViewer: PdfViewerComponent;
  @ViewChild('pdfViewer') public pdfViewer: PdfJsViewerComponent;

  fg: UntypedFormGroup;
  //reportBlob: Blob;
  //reportData: Uint8Array; //Blob //File;
  showDialog: boolean = false;
  canProvideRefusalList: any;
  willProvideRefusalList: any;
  //serviceOption: number = 0;

  getReferralGuidControl = () => this.fg?.get('guid');
  getReferralGuidValue = () => this.getReferralGuidControl()?.value;
  setReferralGuidValue = (value) => this.getReferralGuidControl()?.setValue(value);

  getReferralIdControl = () => this.fg?.get('id');
  getReferralIdValue = () => this.getReferralIdControl()?.value;
  setReferralIdValue = (value) => this.getReferralIdControl()?.setValue(value);

  getProviderIdControl = () => this.fg?.get('providerId');
  getProviderIdValue = () => this.getProviderIdControl()?.value;
  setProviderIdValue = (value) => this.getProviderIdControl()?.setValue(value);

  getReferralStatusControl = () => this.fg?.get('referralStatus');
  getReferralStatusValue = () => this.getReferralStatusControl()?.value;
  setReferralStatusValue = (value) => this.getReferralStatusControl()?.setValue(value);  

  getServiceOptionControl = () => this.fg?.get('serviceOption');
  getServiceOptionValue = () => this.getServiceOptionControl()?.value;
  setServiceOptionValue = (value) => this.getServiceOptionControl()?.setValue(value);

  getCanProvideServiceControl = () => this.fg?.get('canProvideService');
  getCanProvideServiceValue = () => this.getCanProvideServiceControl()?.value;
  setCanProvideServiceValue = (value) => this.getCanProvideServiceControl()?.setValue(value);

  getCanProvideRefusalControl = () => this.fg?.get('canProvideRefusal');
  getCanProvideRefusalValue = () => this.getCanProvideRefusalControl()?.value;
  setCanProvideRefusalValue = (value) => this.getCanProvideRefusalControl()?.setValue(value);

  getContactedReferrerControl = () => this.fg?.get('contactedReferrer');
  getContactedReferrerValue = () => this.getContactedReferrerControl()?.value;
  setContactedReferrerValue = (value) => this.getContactedReferrerControl()?.setValue(value);

  getContactedReferrerDateControl = () => this.fg?.get('contactedReferrerDate');
  getContactedReferrerDateValue = () => this.getContactedReferrerDateControl()?.value;
  setContactedReferrerDateValue = (value) => this.getContactedReferrerDateControl()?.setValue(value);  

  getWillProvideServiceControl = () => this.fg?.get('willProvideService');
  getWillProvideServiceValue = () => this.getWillProvideServiceControl()?.value;
  setWillProvideServiceValue = (value) => this.getWillProvideServiceControl()?.setValue(value);

  getWillProvideRefusalControl = () => this.fg?.get('willProvideRefusal');
  getWillProvideRefusalValue = () => this.getWillProvideRefusalControl()?.value;
  setWillProvideRefusalValue = (value) => this.getWillProvideRefusalControl()?.setValue(value);  

  getAgreeProvideServiceControl = () => this.fg?.get('agreeProvideService');
  getAgreeProvideServiceValue = () => this.getAgreeProvideServiceControl()?.value;
  setAgreeProvideServiceValue = (value) => this.getAgreeProvideServiceControl()?.setValue(value);

  getCompletedControl = () => this.fg?.get('completed');
  getCompletedValue = () => this.getCompletedControl()?.value;
  setCompletedValue = (value) => this.getCompletedControl()?.setValue(value);
  
  getCompletedDateControl = () => this.fg?.get('completedDate');
  getCompletedDateValue = () => this.getCompletedDateControl()?.value;
  setCompletedDateValue = (value) => this.getCompletedDateControl()?.setValue(value);

  getCompletedNotesControl = () => this.fg?.get('completedNotes');
  getCompletedNotesValue = () => this.getCompletedNotesControl()?.value;
  setCompletedNotesValue = (value) => this.getCompletedNotesControl()?.setValue(value);

  isReferralAcceptedOrCompleted = () => this.getReferralStatusValue() == ReferralStatus.Accepted || this.getReferralStatusValue() == ReferralStatus.Completed;

  canProvideRefusalVisible = () => (this.getCanProvideServiceValue() ?? "1").toString() == ClientReferralYesNo.No.toString();
  willProvideRefusalVisible = () => (this.getWillProvideServiceValue() ?? "1").toString() == ClientReferralYesNo.No.toString();

  canProvideServicePanelDisabled = () => this.isReferralAcceptedOrCompleted();
  contactedReferrerPanelDisabled = () => !((this.getCanProvideServiceValue() ?? "0").toString() == ClientReferralYesNo.Yes.toString()) || this.isReferralAcceptedOrCompleted();
  willProvideServicePanelDisabled = () => !(this.getContactedReferrerValue() == true) || this.isReferralAcceptedOrCompleted();
  agreeProvideServicePanelDisabled = () => !((this.getWillProvideServiceValue() ?? "0").toString() == ClientReferralYesNo.Yes.toString()) || this.isReferralAcceptedOrCompleted();
  //completionPanelDisabled = () => this.userService.CurrentProviderID.toString() == (this.getProviderIdValue() ?? 0).toString() || this.getReferralStatusValue() != ReferralStatus.Accepted;
  completionPanelDisabled = () => !(this.userService.CurrentProviderID.toString() != (this.getProviderIdValue() ?? 0).toString() && this.getReferralStatusValue() == ReferralStatus.Accepted);
  
  // contactedReferrerPanelDisabled: boolean = true;
  // willProvideServicePanelDisabled: boolean = true;
  // agreeProvideServicePanelDisabled: boolean = true;
  // completionPanelDisabled: boolean = true;

  constructor(private reportService: ReportsService, private userService: UserService, private messageService: MessageService,
              private route: ActivatedRoute, private router: Router, private fb: UntypedFormBuilder, private cds: CommonDataService, 
              private app: AppContextService, private refService: ReferralService, private helper: HelperClassService,
              private spinnerService: SpinnerService, private validators: DirectServiceValidatorsService, private confirmationService: ConfirmationService) { 
    this.fg = fb.group({
      guid: [],
      id: [],
      clientGuid: [],
      serviceId: [],
      subId: [],
      providerId: [],
      confirmStatus: [],
      confirmLocation: [],
      memo: [],
      userId: [],
      datetime: [],
      employeeId: [],
      employeeName: [],
      referralStatus: [],
      canProvideService: [],
      contactedReferrer: [],
      contactedReferrerDate: [new Date(), validators.futureDateValidator()],
      willProvideService: [],
      contractGuid: [],
      funderId: [],
      funderName: [],
      serviceOption: [0],
      canProvideRefusal: [],
      willProvideRefusal: [],
      directServiceGuid: [],
      outsideProviderId: [],
      destinationProviderId: [],
      clinicId: [],
      completedDate: [new Date(), validators.futureDateValidator()],
      completedNotes: [''],
      lostToFollowupDate: [],
      lostToFollowupNote: [],
      clientAltId: [],
      providerName: [],
      sourceProvider: [],
      destinationProvider: [],
      referralStatusDesc: [],
      serviceDesc: [],
      subTypeDesc: [],
      referrerName: [],
      referrerPhone: [],
      referrerEmail: [],
      referrerNote: [],
      sourceClinicId: [],
      deleted: [],
      agreeProvideService: [],
      completed: []
    },
    {
      // validators:
      // asyncValidators:
      //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
    });                
  }

  ngOnInit(): void {
    //this.runReport();
  }

  onShow(referral: ClientReferralModel) {
    this.initialize(referral);
    this.runReport();
    this.showDialog = true;    
  }

  initialize(referral: ClientReferralModel) {
    this.canProvideRefusalList = this.cds.getCommonSetByName("ReferralRefusalExplanations").filter(f => f.supplementalField1Value == "True");
    this.willProvideRefusalList = this.cds.getCommonSetByName("ReferralRefusalExplanations").filter(f => f.supplementalField2Value == "True");
    this.fg.reset({
      //id: this.fg.value.id, 
      serviceOption: this.fg.value.serviceOption
    });
    this.fg.patchValue(referral);
    this.setContactedReferrerDateValue(this.helper.getISODate(referral.contactedReferrerDate));
    if (referral.referralStatus == ReferralStatus.Accepted || referral.referralStatus == ReferralStatus.Completed) this.setAgreeProvideServiceValue(true);
    this.setCompletedDateValue(this.helper.getISODate(referral.completedDate));
    //console.log(formatISO('01-01-1900', { representation: 'date' }));
    if (!this.helper.isDateDefaultOrEmpty(referral.completedDate)) this.setCompletedValue(true);

    // setup dynamic date validations if the YES question is answered
    this.getContactedReferrerControl().valueChanges.subscribe(val => {
      if (val) {
        this.getContactedReferrerDateControl().setValidators([Validators.required, this.validators.futureDateValidator()]);
      } else {
        this.getContactedReferrerDateControl().clearValidators();
      }
      this.getContactedReferrerDateControl().updateValueAndValidity();
    });

    this.getCompletedControl().valueChanges.subscribe(val => {
      if (val) {
        this.getCompletedDateControl().setValidators([Validators.required, this.validators.futureDateValidator()]);
      } else {
        this.getCompletedDateControl().clearValidators();
      }
      this.getCompletedDateControl().updateValueAndValidity();
    });

    // setup dynamic validations if the NO question is answered
    this.getCanProvideServiceControl().valueChanges.subscribe(val => {
      if (val == ClientReferralYesNo.No) {
        this.getCanProvideRefusalControl().setValidators([this.validators.requiredFieldValidator()]);
      } else {
        this.getCanProvideRefusalControl().clearValidators();
      }
      this.getCanProvideRefusalControl().updateValueAndValidity();
    });

    this.getWillProvideServiceControl().valueChanges.subscribe(val => {
      if (val == ClientReferralYesNo.No) {
        this.getWillProvideRefusalControl().setValidators([this.validators.requiredFieldValidator()]);
      } else {
        this.getWillProvideRefusalControl().clearValidators();
      }
      this.getWillProvideRefusalControl().updateValueAndValidity();
    });

    // this.setReferralGuidValue(referral.guid);
    // this.setReferralIdValue(referral.id);
    // this.setProviderIdValue(referral.providerId);
    // this.setReferralStatusValue(referral.referralStatus);
    // this.setCanProvideServiceValue(referral.canProvideService);
    // this.setContactedReferrerValue(referral.contactedReferrer);
    // this.setWillProvideServiceValue(referral.willProvideService);
    // //this.setcompletedValue(); ???
    // this.setcompletedNotesValue(referral.completedNotes);
    // //this.completionPanelDisabled = this.userService.CurrentProviderID.toString() == (this.getProviderIdValue() ?? 0).toString() || this.getReferralStatusValue() != ReferralStatus.Accepted;
  }
  
  onHide(event) {
    //this.fg.reset();
    this.showDialog = false;
    //this.completionPanelDisabled = true;
  }

  onPDFViewerEvent(message, event) {
    //console.log(message);
  }

  onChangeCanProvideServiceYesNo(value: number) {
    //this.setCanProvideServiceYesNoValue(value);
    if (value == ClientReferralYesNo.No) {
      this.setContactedReferrerValue(null);
      this.setContactedReferrerDateValue(parseISO("1900-01-01"));
      this.setWillProvideServiceValue(null);
      this.setWillProvideRefusalValue(null);
      this.setAgreeProvideServiceValue(null);
    }
  }

  onChangeWillProvideServiceYesNo(value: number) {
    //this.setWillProvideServiceValue(value);
  }

  onChangeContactedReferrer(value) {
    if (value)
      this.setContactedReferrerDateValue(new Date());
    else
      this.setContactedReferrerDateValue(null);
  }

  onChangeAgreeProvideService(value) {

  }

  onChangecompleted(value) {
    if (value)
      this.setCompletedDateValue(new Date());
    else
      this.setCompletedDateValue(null);
  }

  onChangeServiceOption(value: number) {
    //this.serviceOption = value;
    this.runReport();
  }

  onSaveConfirm(event) {
    if (!this.getCompletedValue()) {
      if (this.getCanProvideServiceValue() == ClientReferralYesNo.No.toString() ||
          this.getWillProvideServiceValue() == ClientReferralYesNo.No.toString()) {
            this.confirmationService.confirm({
              target: event.target,
              message: "By indicating No you are declining and canceling this referral. Are you sure you want to continue?",
              icon: "pi pi-info-circle",
              accept: () => { this.onSave(); },  
              reject: () => { this.messageService.add({ severity: "error", summary: "Aborted", detail: "Changes Canceled", life: 2000 }); } });
      }
      else if (this.getAgreeProvideServiceValue()) {
        this.confirmationService.confirm({
          target: event.target,
          message: "By indicating Yes you are accepting this referral. Are you sure you want to continue?",
          icon: "pi pi-info-circle",
          accept: () => { this.onSave(); },  
          reject: () => { this.messageService.add({ severity: "error", summary: "Aborted", detail: "Changes Canceled", life: 2000 }); } });
      }
      else {
        this.confirmationService.confirm({
          target: event.target,
          message: "Are you sure that you want to proceed?",
          icon: "pi pi-info-circle",
          accept: () => { this.onSave(); },
          reject: () => { this.messageService.add({ severity: "error", summary: "Aborted", detail: "Changes Canceled", life: 2000 }); }
        });
      }
    }
    else {
      this.confirmationService.confirm({
        target: event.target,
        message: "Are you sure that you want to proceed?",
        icon: "pi pi-info-circle",
        accept: () => { this.onSave(); },
        reject: () => { this.messageService.add({ severity: "error", summary: "Aborted", detail: "Changes Canceled", life: 2000 }); }
      });
    }
  }

  onSave() {
    let model: Partial<ClientReferralsRequestModel> = {
      requestType: RequestType.SaveTicket,
      success: false,
      guid: this.fg.value.guid,
      providerId: this.fg.value.providerId,
      clientGuid: this.fg.value.clientGuid,
      userId: this.app.userServ.userID,
      // message: '',
      // clientGuid: '',
      // month: 0,
      // year: 0,
      // filterSelectionChoice: 0,
      // dateSelectionChoice: 0,
      // clinicId: 0,
      // referralStatus: 0,
      // onlyShowInNetworkReferrals: false,
      // showDeleted: false,
      // referralCreatedReceived: 0,
      // waitingCount: 0,
      referrals: []
    };
    //let referral: Partial<ClientReferralsModel> = {};    
    //referral = {...this.fg.value};
    //Object.assign(referral, this.fg.value);
    // delete referral.serviceOption;

    // let referrals: ClientReferralsModel[] = [];
    // let referral: Partial<ClientReferralsModel> = {
    //    guid: this.fg.value.guid,
    //    id: this.fg.value.id,
    //    referralStatus: this.fg.value.agreeProvideService ? ReferralStatus.Accepted : this.fg.value.referralStatus,
    //    userId: this.app.userServ.userID,
    //    canProvideService: this.fg.value.canProvideService,
    //    canProvideRefusal: this.fg.value.canProvideRefusal,
    //    contactedReferrer: this.fg.value.contactedReferrer,
    //    contactedReferrerDate: this.fg.value.contactedReferrerDate,
    //    willProvideService: this.fg.value.willProvideService,      
    //    willProvideRefusal: this.fg.value.willProvideRefusal,
    //    completedDate: this.fg.value.completed ? formatISO(this.fg.value.completedDate, { representation: 'date' }) : formatISO('01-01-1900', { representation: 'date' }),
    //    completedNotes: this.fg.value.completed ? this.fg.value.completedNotes : ''
    // };

    const { agreeProvideService, serviceOption, completed, ...everythingExceptExcluded } = this.fg.value;
    let referral: ClientReferralsModel = everythingExceptExcluded; //{...this.fg.value};
    if (!!this.fg.value.contactedReferrerDate) referral.contactedReferrerDate = formatISO(this.fg.value.contactedReferrerDate, { representation: 'date' });
    referral.datetime = formatISO('01-01-1900', { representation: 'date' });
    referral.lostToFollowupDate = formatISO('01-01-1900', { representation: 'date' });
    referral.completedDate = formatISO('01-01-1900', { representation: 'date' });
    referral.userId = this.app.userServ.userID;
    referral.deleted = false;
    referral.contractGuid = '00000000-0000-0000-0000-000000000000';
    referral.directServiceGuid = '00000000-0000-0000-0000-000000000000';

    if (this.fg.value.completed) {
      referral.referralStatus = ReferralStatus.Completed;
      if (!!this.fg.value.completedDate) referral.completedDate = formatISO(this.fg.value.completedDate, { representation: 'date' });
    }
    else if (this.fg.value.agreeProvideService) 
      referral.referralStatus = ReferralStatus.Accepted;

    // referral.canProvideRefusal = 0;
    // referral.confirmStatus = 0;
    // referral.employeeId = 0;
    // referral.employeeName = '';
    // referral.funderId = 0;
    // referral.funderName = '';
    // referral.memo = '';
    // referral.outsideProviderId = 0;
    // referral.providerName = '';
    // referral.sourceClinicId = 0;
    // referral.willProvideRefusal = 0;
    // // referral.willProvideService = 0;
    // // referral.contactedReferrer = false;

    model.referrals.push(referral as ClientReferralsModel);

    this.spinnerService.showSpinnerUntilCompleted(this.refService.PostReferralRequest(model))
    .subscribe({
      next: (data: ClientReferralsRequestModel) => {
          console.log(data.success);
          this.refreshReferral.emit();
      },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
          this.app.userServ.LogError(error);
      },
      complete: () => { 
        this.messageService.add({ severity: "info", summary: "Confirmed", detail: "Changes Saved", life: 2000});
      }
    });
  }

  runReport() {
    let params = [];
    params.push({name:"ReferralID", value: this.getReferralIdValue().toString()});
    params.push({name:"ServiceOptionType", value: this.getServiceOptionValue().toString()}); //  //this.serviceOption.toString()
    var reportModel : ReportsModel = { reportId: 138, reportName: "ClientReferralMain", reportFormat: "PDF", parameters: params };

    this.spinnerService.showSpinnerUntilCompleted(this.reportService.runReport(reportModel)).subscribe({
      next: (result: Blob) => {
        //FileSaver.saveAs(result, "ClientReferralTicket.pdf");
        //this.reportData = new Blob([result], { type: 'application/pdf' });
        //this.reportData = new File([result], "ClientReferralTicket.txt", { type: "text/plain" });        
        //this.reportData = new Uint8Array(result.arrayBuffer()); //new Uint8Array(await blob.arrayBuffer());
        this.pdfViewer.pdfSrc = result; // pdfSrc can be Blob or Uint8Array
        this.pdfViewer.refresh(); // pdf viewer to load/refresh pdf
      },
      error: (e) => { //HttpErrorResponse
        this.userService.LogError(e);
        this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.message, sticky: true});
      },
      complete: () => {
        //this.messageService.add({severity: 'info', summary: 'Completed', detail: "Report executed sucessfully and can be found in downloads window."});
      }
    });

    //this.spinnerService.On();
    // try {
    //   this.reportBlob = await firstValueFrom(this.reportService.runReport(reportModel));
    //   //this.reportData = new Uint8Array(await this.reportBlob.arrayBuffer());
    //   this.pdfViewer.pdfSrc = this.reportBlob; // pdfSrc can be Blob or Uint8Array
    //   this.pdfViewer.refresh(); // pdf viewer to load/refresh pdf
    //   //console.log('PDF data');
    //   //console.log(this.reportData);
    // }
    // catch(e) {
    //   console.log(e);
    // }
    // finally {
    //   this.spinnerService.Off();
    // }    
  }
}
