import { Component, OnInit } from '@angular/core';
import { AppMainComponent} from './app.main.component';
import { UserService } from './services/user.service';
import { UserConfigInfoModel, UserModel } from './models/user.config.model';
import { Observable } from 'rxjs';
import { AppContextService } from './app-context/app-context.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html'
})
export class AppTopBarComponent implements OnInit {

  user$: Observable<UserConfigInfoModel>;
  user:UserConfigInfoModel;

  //clientStatusLookup$: Observable<number>;
  clientLookupCount:number=0;
  referralCount:number=0;


    constructor(
        public app: AppMainComponent, 
        private userService:UserService, 
        private appContext: AppContextService,
        private router:Router
        ) {

          this.appContext.userServ.userInfo$.subscribe({
            next:(data:UserConfigInfoModel) =>{
              this.user = data;
            },
            error:(error)=>{
              this.appContext.userServ.LogError(error); 
            }
          });

          this.appContext.loginSvc.currentUser$.subscribe({
            next:(data: Partial<UserModel>) =>{
              if (data && this.appContext?.userServ?.CurrentProviderID && this.appContext?.userServ?.CurrentClinicID) {
                this.appContext.getClientStatusLookupCount();
                this.appContext.getReferralsWaitingCount();
              } 
            },
            error:(error)=>{
              this.appContext.userServ.LogError(error); 
            }
          });
    
     }

    
    ngOnInit(): void {
      
      
      this.appContext.clientStatusLookupCount$.subscribe({
        next:(data) =>{
          this.clientLookupCount = data ?? 0 ;
        },
        error:(error)=>{
          this.appContext.userServ.LogError(error); 
        }
      });
      
      
      this.appContext.referralWaitingCount$.subscribe({
        next:(data) =>{
          this.referralCount = data ?? 0 ;
        },
        error:(error)=>{
          this.appContext.userServ.LogError(error); 
        }
      });
      
      
      this.getUserData();
      console.log('NG OnInit');


    }

    getUserData(){
      this.user$ =  this.userService.userInfoData();
    }

    onLoadClientStatusLookup() {
      this.router.navigate( ['../client-status-followup'] );
    }

    
    onLoadClientReferrals() {
      this.router.navigate( ['../client-referrals'] );
    }

}
