import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { getMonth } from 'date-fns';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable, lastValueFrom, shareReplay } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { ClientReferralModel } from 'src/app/client-direct-services/services/client-direct-service-models';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { ClientReferralsService } from 'src/app/client-referrals/client-referrals.service';
import { ClientReferralsRequestModel, ReferralStatusTypes, RequestType } from 'src/app/models/client_referrals.models';
import { DirectServiceFilterSelectionChoices, MCMFilterSelectionChoices } from "src/app/models/enums";
import { HelperClassService } from 'src/app/services/helper-class.service';
import { ClientFormService } from '../../client-form/services/client-form.service';
import { ReferralService } from './referrals.service';
import { ReferralEditComponent } from '../referral-edit/referral-edit.component';

@Component({
  selector: 'app-referrals',
  templateUrl: './referral-edit-list.component.html',
  styleUrls: ['./referral-edit-list.component.scss']
})
export class ReferralsComponent implements OnInit {

  @ViewChild(ReferralEditComponent) referralEditComponent: ReferralEditComponent;

  referForm: UntypedFormGroup;
  //clientData: ClientModel;
  referrals:any;
  referralServices$: Observable<ClientReferralsRequestModel>;
  public  get requestType() {return RequestType };
  public  get referralStatusTypes() { return ReferralStatusTypes };
  showDeleted = false;
  deletedLabel:string  = "Show Deleted";

  months: any[] = this.helperService.getMonths();
  years: string[] = this.helperService.getYears();
  usingCustomDSListFiltering = () => { return (this.getFilterOption() == 2);}
  standardFilterSelections: any[] = this.referralService.getStandardFilterSelections();

  getFilterOption = () => this.referForm?.get('filterOption')?.value ?? 1;
  setFilterSelection = (value) => this.referForm?.get('filterOption').setValue(value ?? 3);
  
  getMCMVisitsOptions = () => this.referForm?.get('mcmVisitsOption');
  getDateFilterSelection = () => this.referForm?.get('dateFilterSelection')?.value ?? DirectServiceFilterSelectionChoices.CurrentMonth;
  setDateFilterSelection = (value) => this.referForm?.get('dateFilterSelection').setValue(value ?? DirectServiceFilterSelectionChoices.CurrentMonth);

  constructor(              
      public app: AppContextService, 
      private fb: UntypedFormBuilder, 
      private referralService:ReferralService,
      public  clientService:ClientFormService,
      public  validationHelper:FormValidations,
      private referralsService:ClientReferralsService,
      private helperService: HelperClassService,
      public  dialogService: DialogService,
      private messageService:MessageService,
      private confirmationService: ConfirmationService
    ) 
    {

      var currentMonth = (getMonth(new Date()) + 1).toString(); //this.months[0].code
      this.referForm = fb.group({
        month: [currentMonth],
        year: [this.years[0]],
        filterOption: [1],
        dateFilterSelection: [MCMFilterSelectionChoices.CurrentMonth],
        //mcmVisitsOption:[3]

      },
      {
      });

    }

    async ngOnInit(): Promise<void>  {
    await this.initializeData();
  }

  async initializeData(){
    var result = await lastValueFrom(this.app.userServ.GetUserConfigValue('CHAMP.Registry.Filters.ReferralsStandardFilter'));
    if (result) this.setDateFilterSelection(result.keyValue);

    this.fetchGridData();
  }
  
  onChangeMonth(value) {
    this.fetchGridData();
  }

  onChangeYear(value) {
    this.fetchGridData();
  }

  onChangeFilterSelection(value) {
    this.app.userServ.SaveUserConfigValue('CHAMP.Registry.Filters.ReferralsStandardFilter', value.toString());
    this.fetchGridData();
  }

  onChangeFilterOption(option){
    this.fetchGridData();
    //this.getMCMVisitsOptions().markAsPristine();
  }

  public showAddReferralDialog(){
    this.referralEditComponent.show(null);
  }

  fetchGridData(){
    const request: Partial<ClientReferralsRequestModel> = {
       clientGuid: this.clientService.clientData.guid , 

       month: Number(this.referForm?.get('month')?.value ?? 0),
       year:Number( this.referForm?.get('year')?.value ?? 0),

       filterSelectionChoice:  Number(this.getFilterOption() ),
       dateSelectionChoice:MCMFilterSelectionChoices.MonthAndYear,
       showDeleted: this.showDeleted
       
    };

    //this.spinnerService.On();
    let model:Partial<ClientReferralsRequestModel> = {};
    Object.assign(model, {});
    model.clientGuid = this.clientService.clientData.guid;
    model.month =  Number(this.referForm?.get('month')?.value ?? 0);
    model.year = Number( this.referForm?.get('year')?.value ?? 0);
    model.requestType = this.requestType.GetFromClient

    // model.providerId=this.app.provSvc.providerID;
    // model.clinicId = this.app.userServ.CurrentClinicID;
    // model.referralStatus = this.fg.value.referralStatus;
    // model.onlyShowInNetworkReferrals = this.fg.value.onlyShowInNetworkReferrals;
    // model.referralCreatedReceived = this.fg.value.referralCreatedReceived;
    this.referralServices$ = this.referralsService.PostReferralRequest(model);
    this.referralServices$
    .subscribe({
      next: (data: ClientReferralsRequestModel) => {
            this.referrals = data.referrals;
          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => {}
      });

  //   // if standard filter option was selected then set the correct option to pass to the API call
  //   if (!this.usingCustomDSListFiltering()) {
  //     request.dateSelectionChoice = Number(this.getDateFilterSelection());
  //     // also save config setting to DB
  //   }
  //   else {
  //     if (isNaN(request.month)) { alert("Cannot refresh MCM grid, Invalid month"); return; }
  //     if (isNaN(request.year)) { alert("Cannot refresh MCM grid, Invalid year"); return; }
  //   }

  //   this.mcmServices$ = this.mcmService.getMCMServices(request).pipe(shareReplay());;
  //   this.mcmServices$.subscribe({
  //     next: (result:MCMListResponseModel) => { 
  //       this.mcmVisits = result.mcmServices;
  //       if ( this.mcmVisits?.length > 0  ) 
  //           this.getMCMVisitDate().setValue(parseISO(result.lastPMVisitDate));
  //           //console.log(this.mcmVisits[0]?.visitDate,  this.getMCMVisitDate().value);
  //           this.getMCMVisitDate().markAsPristine();
  //     },
  //     error: (error) =>  { 
  //       this.helperService.displayError(this.messageService, "Fatal error occurred when loading a non RW Visit. Please contact CHAMP support:", error, true);
  //     }, 
  //     complete: () => {  }
  //      });  
   }


   onOpenReferral(referral:ClientReferralModel){
    this.referralEditComponent.show(referral.guid);
  }

  // openReferralModal(ds:any){


  //   // this.dsModalRef = this.dialogService.open(DirectServiceComponent, {
  //   //   header: 'Direct Service Information',
  //   //   width: '60rem',
  //   //   data: {
  //   //       clientGuid:this.clientService.clientData.guid,
  //   //       guid:ds?.guid
  //   //   }
  //   // });

  //   // this.app.overLayMangr.add(this.dsModalRef, 'dialogRef');

  //   // this.dsModalRef.onClose.subscribe(
  //   //   {
  //   //     next:  async (result) => {
  //   //       this.fetchGridData();
  //   //       this.app.overLayMangr.remove(this.dsModalRef);
  //   //     },
  //   //     error:(err) => {
  //   //       this.helperService.displayError(this.messageService, "Fatal error occurred when loading a non RW Visit. Please contact CHAMP support:", err, true);
  //   //     },
  //   //     complete:()=> {
  //   //     }

  //   // } );

  // }

  onRefreshReferralList(){
    this.fetchGridData();
  }


  deleteReferral(referral: ClientReferralModel, undelete:boolean= false){
    let model:Partial<ClientReferralsRequestModel> = {};
    Object.assign(model, {});
    model.clientGuid = this.clientService.clientData.guid;
    model.userId = this.app.userServ.userID;
    model.requestType = undelete ? this.requestType.UnDelete: this.requestType.Delete ;
    model.guid = referral.guid;

    this.referralServices$ = this.referralsService.PostReferralRequest(model).pipe(shareReplay());
    this.referralServices$
    .subscribe({
      next: (data: ClientReferralsRequestModel) => {
          if (!undelete) this.messageService.add({severity:'success', summary: 'Successful', detail: 'Referral Deleted', life: this.app.messageDisplayTime});  
          if (undelete) this.messageService.add({severity:'success', summary: 'Successful', detail: 'Referral undeleted', life: this.app.messageDisplayTime});  
          this.fetchGridData();

          },
      error: (error) => { 
            this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
            this.app.userServ.LogError(error);
          },
      complete: () => {}
      });
  }

  onDeleteReferral(event: Event, referral: ClientReferralModel) {
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete the referral ?',
      header: 'Confirm',
      icon: 'pi pi-info-circle',
      acceptLabel:'No',   // Reverse the labels since default button does not work yet in Prime
      rejectLabel: 'Yes',
      accept: () => {
        this.messageService.add({severity: "error", summary: "Aborted", detail: "Delete referral canceled", life: 5000});
      },
      reject: () => {
        
        this.deleteReferral(referral);
        }
      });
  }

  onUndeleteReferral(event: Event, referral: ClientReferralModel) {

    this.deleteReferral(referral, true);
  }

  showDeletedClicked(){
    this.showDeleted = !this.showDeleted;
    this.fetchGridData();
   
    if (this.showDeleted )
      this.deletedLabel = "Hide Deleted";
    else
      this.deletedLabel = "Show Deleted";  
  }

}
