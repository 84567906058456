
export interface ClientModel {
    guid: string;
    id: number;
    clientAltId: string;
    providerId: number;
    firstName: string;
    middleName: string;
    lastName: string;
    streetAddress: string;
    ward: number | null;
    phoneNumber: string;
    emailAddress: string;
    ssn: string;
    birthdate: string | null;
    birthplace: string;
    gender: number;
    race: number;
    ethnicity: number;
    regionOrigin: number;
    sexualOrient: number;
    hivConfirmYear: string | null;
    aidsDiagnosYear: string | null;
    fyYearNew: number;
    newClientDate: string;
    employeeId: number | null;
    userId: number;
    lastUpdate: string;
    notes: string;
    fullFirstName: string;
    fullLastName: string;
    providerClientId: string;
    primaryCarePhysician: number | null;
    newlyDiagnosed: number | null;
    newlyDiagnosedDate: string | null;
    eisIndicator: boolean | null;
    eisIndicatorLastUpdate: string | null;
    linkage: boolean | null;
    estDeathDate: string | null;
    cohortStudy: boolean | null;
    cohortStudyLatest: boolean | null;
    cohortRemovedDate: string | null;
    cohortRemovedReason: string;
    cohortDate: string | null;
    clientStatus: ClientStatusModel;
    clientStatusHistory: ClientStatusHistoryModel;
    clientCareGiverSupport: ClientCareGiverSupportModel;
    clientPrimaryCareSource: ClientPrimaryCareSourceModel;
    clientEmploymentStatus: ClientEmploymentStatusModel;
    clientLiveIn: ClientLiveInModel;
    clientLiveWith: ClientLiveWithModel[];
    clientPaySources: ClientPaySourcesModel[];
    clientHivStatus: ClientHivStatusModel;
    clientMedicalViral: Partial<ClientMedicalViralModel>;
    clientIncomeSources: ClientIncomeSourcesModel[];
    clientIncomeData: ClientIncomeDataModel;
    clientReferralSource: ClientReferralSourceModel;
    clientMedicalCd4: Partial<ClientMedicalCd4Model>;
    clientTranModes: ClientTranModesModel[];
    clientZipCodes: ClientZipCodeModel;
    clientPrimaryCarePhysicians: ClientPrimaryCarePhysicianModel;
    clientHopwaWorkSheet: ClientHopwaWorksheetModel[];
    clientMedicalCaseManagementCarePlan:Partial<ClientMedicalCaseManagementCarePlanModel>;
    clientMedicalCaseManagement: ClientMedicalCaseManagementModel[];
    clientMedicalHepatitisB: Partial<ClientMedicalHepatitisBModel>;
    clientMedicalHepatitisC: Partial<ClientMedicalHepatitisCModel>;
    clientMedicalOppDiseases: Partial<ClientMedicalOppDiseaseModel>[];
    clientMedicalTuberculosis: Partial<ClientMedicalTuberculosisModel>;
    clientMedicalSyphilis: Partial<ClientMedicalSyphilisModel>;
    clientMedicalChlamydia: Partial<ClientMedicalChlamydiaModel>;
    clientMedicalGonorrhea: Partial<ClientMedicalGonorrheaModel>;
    clientMedicalToxoplasma: Partial<ClientMedicalToxoplasmaModel>;
    clientMedicalLipidPanel: Partial<ClientMedicalLipidPanelModel>;
    clientMedicalCovid: Partial<ClientMedicalCovidModel>;
    clientMedicalPharmaAssistance: Partial<ClientMedicalPharmaAssistanceModel>;
    clientMedicalAntiretroviralTherapy: Partial<ClientMedicalAntiretroviralTherapyModel>;
    clientMedicalInfluenzaVaccination: Partial<ClientMedicalInfluenzaVaccinationModel>;
    clientMedicalPneumoccocalVaccination: Partial<ClientMedicalPneumoccocalVaccinationModel>;
    clientMedicalHepatitisBVaccination: Partial<ClientMedicalHepatitisBVaccinationModel>;
    clientMedicalFemalePapSmear: Partial<ClientMedicalFemalePapSmearModel>;
    clientMedicalFemalePelvicExam: Partial<ClientMedicalFemalePelvicExamModel>;
    clientMedicalFemalePregnancy: Partial<ClientMedicalFemalePregnancyModel>;
    clientMedicalAntiretroviralPrevention: Partial<ClientMedicalAntiretroviralPreventionModel>;
    clientMedicalFemaleBirthResults: Partial<ClientMedicalFemaleBirthResultsModel>[];
    clientMedicalOtherSti: Partial<ClientMedicalOtherStiModel>[];
    clientScreeningMentalHealth: Partial<ClientScreeningMentalHealthModel>;
    clientScreeningSubstanceAbuse: Partial<ClientScreeningSubstanceAbuseModel>;
}

export interface ClientStatusModel {
    guid: string;
    id: number;
    datetime: string;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    clinicId: number | null;
}

export interface ClientHivStatusModel {
    guid: string;
    id: number;
    datetime: string;
    expired: string | null;
    userId: number;
    lastUpdate: string;
    sourceGuid: string | null;
    sourceProviderId: number | null;
}

export interface ClientMedicalViralModel {
    guid: string;
    count: number;
    unavailable: boolean | null;
    datetime: string;
    undetectable: boolean;
    userId: number | null;
    lastUpdate: string;
    expired: string | null;
    undetectableRange: number | null;
}

export class ClientMedicalViral {
	public constructor(init?: any) {
        if (!!init) {
            //temporarily remove dates to get past API call failing
            //delete init.datetime;
            Object.assign(this, init);
        }
	}
}

export interface ClientTranModesModel {
    guid: string;
    setguid: string;
    id: number;
    datetime: string;
    userId: number;
    lastUpdate: string;
    expired: string | null;
}

export interface ClientReferralSourceModel {
    guid: string;
    id: number;
    datetime: string;
    expired: string | null;
    userId: number;
    lastUpdate: string;
    clinicId: number | null;
}

export interface ClientStatusHistoryModel {
    guid: string;
    id: number;
    datetime: string;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    clinicId: number | null;
}

export interface ClientEmploymentStatusModel {
    guid: string;
    id: number;
    datetime: string;
    expired: string | null;
    userId: number;
    lastUpdate: string;
}

export interface ClientCareGiverSupportModel {
    guid: string;
    id: number;
    datetime: string;
    expired: string | null;
    userId: number;
    lastUpdate: string;
}

export interface ClientPrimaryCareSourceModel {
    guid: string;
    id: number;
    datetime: string;
    expired: string | null;
    userId: number;
    lastUpdate: string;
}

export interface ClientLiveInModel {
    guid: string;
    id: number;
    datetime: string;
    expired: string;
    userId: number;
    lastUpdate: string;
}

export interface ClientLiveWithModel {
    guid: string;
    setguid: string;
    id: number;
    datetime: string;
    expired: string | null;
    userId: number;
    lastUpdate: string;
}

export interface ClientIncomeDataModel {
    guid: string;
    datetime: string;
    medUnable: number;
    individualIncome: number | null;
    householdIncome: number | null;
    householdSize: number;
    expired: string;
    userId: number;
    lastUpdate: string;
    recSource: string;
    povertyCode: number | null;
    sourceGuid: string | null;
    sourceProviderId: number | null;
}

export interface ClientIncomeSourcesModel {
    guid: string;
    setguid: string;
    id: number;
    datetime: string;
    deleted: boolean;
    userId: number;
    expired: string | null;
    lastUpdate: string;
}

export interface ClientPaySourcesModel {
    guid: string;
    setguid: string;
    id: number;
    datetime: string;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    recSource: string;
    directServiceGuid: string | null;
    sourceGuid: string | null;
    sourceProviderId: number | null;
}

export interface ClientMedicalCd4Model {
    guid: string;
    count: number;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    pcpProphylaxis: number | null;
    macProphylaxis: number | null;
    pcpProphylaxisNoReason: number | null;
    macProphylaxisNoReason: number | null;
}

export class ClientMedicalCd4 {
	public constructor(init?: any) {
        if (!!init) {
            //temporarily remove dates to get past API call failing
            //delete init.datetime;
            Object.assign(this, init);
        }
	}
}

export interface ClientMedicalOppDiseaseModel {
    guid: string;
    clientGuid: string;
    setguid: string;
    id: number;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    recSource: string;
}

export class ClientMedicalOppDisease {
	public constructor(init?: any) {
        if (!!init) {
            Object.assign(this, init);
        }
	}
}

export interface ClientZipCodeModel {
    guid: string;
    zipCode: string;
    datetime: string;
    deleted: boolean;
    expired: string | null;
    userId: number;
    lastUpdate: string;
    streetAddress: string;
    recSource: string;
    sourceGuid: string | null;
    sourceProviderId: number | null;
}

export interface ClientPrimaryCarePhysicianModel {
    guid: string;
    id: number;
    datetime: string;
    deleted: boolean;
    expired: string | null;
    userId: number;
    lastUpdate: string;
    clinicId: number;
    firstVisitDate: string;
}

export interface ClientPaySourcesModel {
    guid: string;
    setguid: string;
    id: number;
    datetime: string;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    directServiceGuid: string | null;
    sourceGuid: string | null;
    sourceProviderId: number | null;
}

export interface VisitLinkageModel {
    linkage: boolean | null;
    visitDate: string | null;
    prescribingPrivilege: boolean | null;
}

export interface ClientHopwaWorksheetModel {
    guid: string;
    datetime: string;
    hopwaConfigId: number;
    payrollPeriod: number;
    income: number;
    numChildren: number;
    rent: number;
    numBedrooms: number;
    hopwaHousingRatesId: number;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    clientZipCodeGuid: string | null;
    clientSurveyPerformed: number | null;
    recSource: string | null;
}

export interface HopwaWorksheetZipCodeModel {
    zipCode: string;
    state: string;
    city: string;
    county: string;
}

export interface ClientMedicalCaseManagementCarePlanModel {
    guid: string;
    datetime: string;
    carePlanDate: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    recSource: string;
    unavailable: boolean | null;
}

export interface ClientMedicalCaseManagementModel {
    guid: string;
    visitDate: string;
    location: string;
    unavailable: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    serviceId: number | null;
    subId: number | null;
    providerId: number | null;
    clinicId: number | null;
    memo: string;
    ryanWhite: boolean;
    recSource: string;
    cohortIntervention: number | null;
}

export interface ClientRequiredFieldErrorModel {
    requiredFieldResult: number;
    guid: string;
    serviceId: number;
    fieldId: number;
    datetime: string;
    userId: number;
    lastUpdate: string;
    fldErrType: number | null;
    errDesc: string;
    recSource: string;
    allowSave: boolean;
    allowBillable: boolean;
    expiresDays: number;
    effDate: string | null;
    required: boolean;
    fieldDescription: string | null;
    fieldCategoryDescription: string | null;
    serviceCategoryDescription: string | null;
}

export interface ClientScreeningSubstanceAbuseModel {
    guid: string;
    clientGuid: string;
    screeningDate: string | null;
    screeningType: number | null;
    result: number | null;
    followup: number | null;
    deleted: boolean | null;
    userId: number | null;
    lastUpdate: string | null;
    expired: string | null;
    recSource: string;
    dsGuid: string | null;
    subtype: number | null;
    includedSubtype: number | null;
    notes: string;
    diagnosis: number | null;
}

export interface ClientScreeningMentalHealthModel {
    guid: string;
    clientGuid: string;
    screeningDate: string | null;
    screeningType: number | null;
    result: number | null;
    followup: number | null;
    deleted: boolean | null;
    userId: number | null;
    lastUpdate: string | null;
    expired: string | null;
    recSource: string;
    dsGuid: string | null;
    subtype: number | null;
    includedSubtype: number | null;
    notes: string;
    diagnosis: number | null;
}

export interface ClientMedicalOtherStiModel {
    guid: string;
    clientGuid: string;
    positive: boolean;
    unavailable: boolean;
    id: number | null;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    recSource: string | null;
}

export interface ClientMedicalFemaleBirthResultsModel {
    guid: string;
    clientGuid: string;
    genderId: number;
    hivStatusId: number;
    birthDate: string;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    recSource: string | null;
}

export interface ClientMedicalAntiretroviralPreventionModel {
    guid: string;
    clientGuid: string;
    performed: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    noReason: number | null;
    recSource: string | null;
}

export interface ClientMedicalFemalePregnancyModel {
    guid: string;
    pregnant: boolean;
    id: number;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    recSource: string | null;
}

export interface ClientMedicalFemalePelvicExamModel {
    guid: string;
    performed: boolean;
    datetime: string;
    noReason: number | null;
    deleted: boolean;
    userId: number | null;
    lastUpdate: string;
    expired: string | null;
    recSource: string;
}

export interface ClientMedicalFemalePapSmearModel {
    guid: string;
    performed: boolean;
    datetime: string;
    noReason: number | null;
    deleted: boolean;
    userId: number | null;
    lastUpdate: string;
    expired: string | null;
    recSource: string;
}

export interface ClientMedicalHepatitisBVaccinationModel {
    guid: string;
    received: boolean | null;
    datetime: string;
    noReason: number;
    deleted: boolean;
    userId: number | null;
    lastUpdate: string;
    expired: string | null;
    recSource: string;
}

export interface ClientMedicalPneumoccocalVaccinationModel {
    guid: string;
    received: boolean | null;
    datetime: string;
    noReason: number;
    deleted: boolean;
    userId: number | null;
    lastUpdate: string;
    expired: string | null;
    recSource: string;
}

export interface ClientMedicalInfluenzaVaccinationModel {
    guid: string;
    received: boolean | null;
    datetime: string;
    noReason: number;
    deleted: boolean;
    userId: number | null;
    lastUpdate: string;
    expired: string | null;
    recSource: string;
}

export interface ClientMedicalAntiretroviralTherapyModel {
    guid: string;
    id: number;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    prescribed: number | null;
    reason: number | null;
    prescribedDate: string | null;
}

export interface ClientMedicalPharmaAssistanceModel {
    guid: string;
    id: number;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
}

export interface ClientMedicalCovidModel {
    guid: string;
    positive: number | null;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    screened: number | null;
    noReason: number | null;
    testType: number | null;
}

export interface ClientMedicalLipidPanelModel {
    guid: string;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    received: boolean;
    noReason: number | null;
}

export interface ClientMedicalToxoplasmaModel {
    guid: string;
    result: number | null;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    screened: boolean;
    noReason: number | null;
}

// export class ClientMedicalToxoplasma { 
// 	public constructor(init?: any) {
//         if (!!init) {
//             //temporarily remove dates to get past API call failing
//             //delete init.datetime;
//             Object.assign(this, init);
//         }
// 	}
// }

export interface ClientMedicalGonorrheaModel {
    guid: string;
    positive: number | null;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    screened: number | null;
    noReason: number | null;
}

export interface ClientMedicalChlamydiaModel {
    guid: string;
    positive: number | null;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    screened: number | null;
    noReason: number | null;
}

export interface ClientMedicalSyphilisModel {
    guid: string;
    positive: number | null;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    screened: number | null;
    noReason: number | null;
    treated: boolean;
}

export interface ClientMedicalTuberculosisModel {
    guid: string;
    positive: number | null;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    screened: number | null;
    noReason: number | null;
    testType: number | null;
}

//import { UserService } from 'src/app/services/user.service';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';

export class ClientMedicalCommon {  //ClientMedicalTuberculosis
    public deleted: boolean;
    public userId: number;
    public expired?: string;
    public lastUpdate?: string;
    public guid?: string;

	public constructor(userId: number) { //, init?: any //private userService:UserService
        // this only works if the reactive controls match the names in the model object
        // if (!!init) {
        //     //temporarily remove dates to get past API call failing
        //     //delete init.datetime;
        //     Object.assign(this, init);
        // }
        Object.assign(this, {});
        this.guid = ClientFormHelper.EmptyGuid();
        this.deleted = false;
        this.userId = userId; //this.userService.userID
        this.expired = ClientFormHelper.nullDate();
        this.lastUpdate = ClientFormHelper.nullDate();
	}
}

export interface ClientMedicalHepatitisBModel {
    guid: string;
    positive: number | null;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    screened: number | null;
    noReason: number | null;
}

export class ClientMedicalHepatitisB {
	public constructor(init?: any) {
        if (!!init) {
            //temporarily remove dates to get past API call failing
            //delete init.datetime;
            Object.assign(this, init);
        }
	}
}

export interface ClientMedicalHepatitisCModel {
    guid: string;
    positive: number | null;
    unavailable: boolean;
    datetime: string;
    deleted: boolean;
    userId: number;
    lastUpdate: string;
    expired: string | null;
    screened: number | null;
    noReason: number | null;
}

export class ClientMedicalHepatitisC {
    public constructor(init?: any) {
        if (!!init) {
            //temporarily remove dates to get past API call failing
            //delete init.datetime;
            Object.assign(this, init);
        }
    }
}

export interface ClientMedicalRiskReductionModel {
	guid: string;
	clientGuid: string;
	directServiceGuid: string | null;
	riskReduction: number | null;
	riskReductionDate: string | null;
	treatmentAdherence: number | null;
	treatmentAdherenceDate: string | null;
	datetime: string | null;
	deleted: boolean | null;
	userId: number;
	lastUpdate: string | null;
	expired: string | null;
	recSource: string;
	mentalHealthScreening: number | null;
	mentalHealthScreeningDate: string | null;
	substanceAbuseScreening: number | null;
	substanceAbuseScreeningDate: string | null;
	tobaccoUse: number | null;
	tobaccoCounseling: number | null;
	tobaccoCounselingDate: string | null;
	alcoholUse: number | null;
	alcoholCounseling: number | null;
	alcoholCounselingDate: string | null;
	tobaccoCounselingLastUpdate: string | null;
	alcoholCounselingLastUpdate: string | null;
	riskReductionCounselingLastUpdate: string | null;
	treatementAdherenceCounselingLastUpdate: string | null;
	substanceAbuseScreeningLastUpdate: string | null;
	mentalHealthScreeningLastUpdate: string | null;
	tobaccoCounselingRequired: boolean | null;
	alcoholCounselingRequired: boolean | null;
	riskReductionCounselingRequired: boolean | null;
	treatementAdherenceCounselingRequired: boolean | null;
	substanceAbuseScreeningRequired: boolean | null;
	mentalHealthScreeningRequired: boolean | null;
}

export interface NewClientModel {
    hivStatus: number;
    familySize: number;
    familyIncome: number;
    zipCode: string;
    cityState: string;
    poveryCode: number;    
    povertyDescription: string;
    city: string;
    state: string;
    county: string;
}

export interface ExistingClientModel {
    guid: string;
    clientAltId: string;
    firstName: string;
    middleName: string;
    lastName: string;
    fullFirstName: string;
    fullLastName: string;
    genderId: number;
    gender: string;
    sSN: string;
    birthDate: string | null;
    streetAddress: string;
    city: string;
    state: string;
    zipCode: string;
    phoneNumber: string;
    emailAddress: string;
    matchValue: number;
}

export interface ClientListModel {
    clientAltId: string;
    clientGuid: string;
    hasErrors: boolean;
}

export interface MergeClientList extends ClientListModel {
    //clientAltId: string;
    //clientGuid: string;
    providerClientId: string;
    clientStatusId: number;
    clientHivStatusId: number;
    clientStatusDesc: string;
    clientHivStatusDesc: string;
}

export interface MergeClientRequestModel {
    sourceProviderId: number;
    sourceClientGuid: string;
    sourceClientAltId: string;
    destinationClientGuid: string;
    destinationClientAltId: string;
    deleteSource: boolean;
}