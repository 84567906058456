import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { addDays, addYears, format } from 'date-fns';
import { ReportsService } from 'src/app/reports/services/reports.service';
import { ReportConfigGroupListModel, ReportConfigReportsModel, ReportsModel } from '../models/reports.model';
import { UserService } from '../services/user.service';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { SpinnerService } from '../common/spinner/spinner.service';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ClientFormHelper} from 'src/app/client-form/client-form.helper';

@Component({
  selector: 'app-client-lookup',
  templateUrl: './client-lookup.component.html',
  styleUrls: ['./client-lookup.component.scss']
})
export class ClientLookupComponent implements OnInit {
  //@Input() clientAltId: number;
  reportData:ReportConfigReportsModel;
  reportList:ReportConfigGroupListModel[];
  clientAltId:string;
  endDate:Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  startDate:Date = addYears(this.endDate, -10);
  

  reportBlob:Blob;
  
  constructor(private reportService:ReportsService, private userService:UserService, private messageService:MessageService,
              private spinnerService:SpinnerService, public config: DynamicDialogConfig) { }

  ngOnInit(): void {
    this.runReport();
  }

  runReport(){


    this.clientAltId = this.config.data?.clientAltId;

    let params = [];
    params.push({name:"ProviderID", value:this.userService.CurrentProviderID.toString()});
    params.push({name:"UserID", value: this.userService.userID.toString()});
    params.push({name:"ContractGuid", value: ClientFormHelper.EmptyGuid()});  //ClientFormHelper.EmptyGuid()}
    params.push({name:"ClientID", value: this.clientAltId});
    params.push({name:"StartDate", value:format(this.startDate, 'MM/dd/yyyy')});
    params.push({name:"EndDate", value:format(this.endDate, 'MM/dd/yyyy')});
    params.push({name:"ServiceCat", value:"0"});
    params.push({name:"SubType", value:"0"});

    var reportModel : ReportsModel = { reportId: 1 ,reportName: "ClientLookup", 
      reportFormat:"PDF", parameters:params };

     console.log("run report") ;

    this.spinnerService.On();
    this.reportService.runReport(reportModel).subscribe(
      {
        next: (result: any) => {
          this.reportBlob = new Blob([result], { type: 'application/pdf' });
          console.log("Value Received") ;
          console.log(result) ;

          // let blob = new Blob([result], { type: 'application/pdf' });
          // let pdfUrl = window.URL.createObjectURL(blob);

          // var File_link = document.createElement('a');
          // File_link.href = pdfUrl;
          // //   TO OPEN PDF ON BROWSER IN NEW TAB
          // //window.open(pdfUrl, '_blank');
          
          // var reportName  = this.reportData.reportName;
          // // Hard Coded Renames
          // if ( this.reportData.reportId == 48 ){
          //   reportName = 'ClientProfile';
          // }



          // //   TO DOWNLOAD PDF TO YOUR COMPUTER
          // if (this.reportData.reportFormat.toLowerCase() =="pdf"){
          //   File_link.download = (reportName + format(new Date(), 'yyyyMMddHHmmss')+ ".pdf").toLowerCase();
          // }
          // else if (this.reportData.reportFormat.toLowerCase() =="excel"){
          //   File_link.download = (reportName + format(new Date(), 'yyyyMMddHHmmss')+ ".xlsx").toLowerCase();
          // }
          // File_link.click();



        },
        error: (e: HttpErrorResponse) => {
          console.log(e) ;
          this.userService.LogError(e);
          this.messageService.add({severity: 'error', summary: 'Error', detail: e.error.message, sticky: true});
          this.spinnerService.Off();
        }
        ,
        complete:() => {  
                        this.spinnerService.Off();
                        //this.messageService.add({severity: 'info', summary: 'Completed', detail: "Report executed sucessfully and can be found in downloads window."});
                      }
      }     
     );



  }

}
