import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuditUserEventsResponseModel, UserActivityRequestModel } from 'src/app/models/admin.models';
import { environment } from 'src/environments/environment';
//import { stringify } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class UserActivityService {

  // URL which returns list of JSON items (API end-point URL)
  private readonly URL = environment.apiURL + '/admin';


  constructor(private http: HttpClient) { }

//   public getClientList() {
//     // this.http.get<any>(this.URL).toPromise().then(res => <ClientListResponse[]> res.data).then( data=> {return data;});
//      return this.http.get(this.URL);

//   }

//   public getClientListWithParams(request: clientListRequest) 
//   { 
//      let url  = this.URL; 
//      return this.http.post(url, request);

//   }

  public getDefaultData () 
  {
    let url  = this.URL + '/getDefaults';
    return this.http.get(url);
  }

  public getEvents (request: UserActivityRequestModel) 
  {
    let url  = this.URL + '/userevents';
    return this.http.post<AuditUserEventsResponseModel[]>(url, request);
  }

//   public getPrimaryMedicalData( clientGuid:string   ) 
//   { 

//     var value:string;
//     let params = new HttpParams();
//     params = params.append('clientGuid', clientGuid);

//     let url  = this.URL  + "/primarymedical";
//      return this.http.get<PrimaryMedicalInfo>(url, {params:params});

//   }
  
}
