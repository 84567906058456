import { Component } from '@angular/core';
import { LoginService } from './login/services/login.service';
//import { ErrorMessagesComponent } from  './common/error-messages/error-messages.component';
import {environment} from '../environments/environment';
import { AppContextService } from './app-context/app-context.service';
import { UserEnvironment } from './models/admin.models';
import { LoginStatus } from './login/services/login-models';


@Component({
  selector: 'app-footer',
  templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {

public showServerDetails:Boolean = false;
currentApplicationVersion = environment.appVersion;
env: UserEnvironment;

constructor( private loginService:LoginService, private app:AppContextService  )  {

  loginService.currentLoginStatus$.subscribe({
        next: (status:LoginStatus) => {
          this.showServerDetails = false;
          if (status.loggedIn && this.app.userServ.hasAdminPermission){

            this.app.getEnvironmentInfo().subscribe({
              next: (edata) => {
                this.showServerDetails = true;
                this.env = edata;
              },
              error: ( error) => {
                this.app.loginSvc.LogError(error);
              }
            });
          }
        },
        error: ( error) => {
          this.app.loginSvc.LogError(error);
        }
   });

}

showLoginPopup(){
  console.log('show login pop up');
  this.loginService.setLoginStatus(false, true, null, false);

}

onReload(){
   location.reload();

}


}


