<div>
    <p-toast key="DSMODAL"></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<!-- <div class="card champ-card champ-card-bottom">   
    Form status : {{dsForm.status | json}}
    Form value : {{dsForm.value | json}}
    Funder errors : {{dsForm.controls['dsFunder']?.errors ?? 'no error(s)'}}
    Funder value : {{dsForm.get('dsFunder').value?.contractGuid}}
    Funder specific error : {{ !!dsForm.controls['dsFunder'].errors ? dsForm.controls['dsFunder'].errors['invalidRequiredField'] ?? 'no error' : 'no errorS'}}
</div> -->

<!--  (ngSubmit)="onSaveChanges()" -->
<form [formGroup]='dsForm' autocomplete="off">

<!-- Direct Service Dialog [(visible)]="alwaysVisible" -->
<!-- <div *ngIf="displayDirectServiceDialog"> -->
<!-- <p-dialog [(visible)]="alwaysVisible" (onHide)="onHideDirectServiceDialog()" (onShow)="onShowDirectServiceDialog()"
          header="Direct Service Information"
          [modal]="true" position="center" [resizable]="false" [draggable]="true" showEffect="explode"
          styleClass="pDialog" [style]="{width: '620px', minWidth: '790px'}"> -->
    <!-- <ng-template pTemplate="content"> -->
        
        <div class="card champ-card">
            <div class="card-body champ-card-body"> <!-- card-body -->
                <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                    <div class="field col-1">
                        <label for="billable" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Billable</label>
                        <div class="formgroup-inline">
                            <div class="field-checkbox">
                                <p-checkbox [style]="{'width': '30px', 'padding': '0px 0px 0px 7px'}" formControlName="billable" binary="true" inputId="billable" (onChange)="onChangeBillable($event.checked)"></p-checkbox>
                            </div>
                        </div>
                    </div>

                    <div class="field col-2">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <span style="vertical-align:text-top"><label htmlFor="deliveredDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Delivery Date</label></span>
                        <div class="formgroup-inline">
                            <input type="text" name="deliveredDateName" autocomplete="off" bsDatepicker formControlName="deliveredDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="deliveredDateID"    
                                    placeholder="Delivery Date"
                                    [ngClass]="{'p-invalid' : dsForm.controls['deliveredDate'].invalid}"
                                    class="champ-date-picker" pInputText style="z-index: 1000 !important;"/>
                        </div>
                        <div *ngIf="dsForm.controls['deliveredDate'].invalid && (dsForm.controls['deliveredDate'].dirty || dsForm.controls['deliveredDate'].touched)">
                            <div *ngIf="dsForm.controls['deliveredDate'].errors.required">
                                <small id="dsDeliveredDate-help" class="p-invalid">*Delivered Date is required.</small>
                            </div>
                            <div *ngIf="dsForm.controls['deliveredDate'].errors"> 
                                <small id="dsDeliveredDate-help" class="p-invalid">{{dsForm.controls['deliveredDate'].errors['invalidDate']}}</small>
                            </div>                             
                        </div>
                    </div>

                    <div class="field col-3">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <span style="vertical-align:text-top"><label htmlFor="deliveredTime" style="margin-top: 0.125rem; margin-bottom: 0.25rem;">Delivery Time</label></span>
                        <timepicker formControlName="deliveredTime" [showSpinners]="false"></timepicker>
                        <!-- class="p-component" <p-inputMask mask="23:59" formControlName="deliveredTime" placeholder="23:59"></p-inputMask> -->
                        <!-- <div *ngIf="fg.controls['deliveredTime'].invalid && (fg.controls['deliveredTime'].dirty || fg.controls['deliveredTime'].touched)"> -->
                            <div *ngIf="!!dsForm.controls['deliveredTime'].errors">
                                <small id="deliveredTime-help" class="p-invalid">*Invalid Delivered Time.</small>
                            </div>
                        <!-- </div> -->
                    </div>

                    <div class="field col-6">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <span style="vertical-align:text-top"><label for="dsFunder" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Contract/Program</label></span>
                        <p-dropdown formControlName="dsFunder" [options]="contractFunders" optionLabel="name" optionValue="keys" appendTo="body" 
                                    (onChange)="onChangeFunder($event.value)"></p-dropdown>
                        <!-- <div *ngIf="!dsForm.get('dsFunder').value || dsForm.get('dsFunder').value?.dsFunder == 0">
                            <small id="dsFunder-help" class="p-invalid">*Contract/Program is required.</small>
                        </div> -->
                        <div *ngIf="!!dsForm.controls['dsFunder'].errors && !!dsForm.controls['dsFunder'].errors['invalidRequiredField']"> 
                            <small id="dsFunder-help" class="p-invalid">*Contract/Program is required.</small>
                        </div>                         
                    </div>
                </div>

                <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                    <div class="field col-6">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <span style="vertical-align:text-top"><label for="serviceId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Service Category</label></span>
                        <p-dropdown formControlName="serviceId" [options]="serviceCategories" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeServiceType($event.value)"> </p-dropdown>
                        <!-- <div *ngIf="!dsForm.get('serviceId').value || dsForm.get('serviceId').value == 0">
                            <small id="serviceId-help" class="p-invalid">*Service Category is required.</small>
                        </div> -->
                        <div *ngIf="!!dsForm.controls['serviceId'].errors && !!dsForm.controls['serviceId'].errors['invalidRequiredField']"> 
                            <small id="serviceId-help" class="p-invalid">*Service Category is required.</small>
                        </div>                            
                    </div>

                    <div class="field col-6">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <span style="vertical-align:text-top"><label for="subId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Sub Category</label></span>
                        <!-- <div *ngIf="qualifyForAssessment()" style="display: inline-block; vertical-align:middle;"> -->
                        <button *ngIf="qualifyForAssessment() && !hasInitialAssessmentChanged()" pButton pRipple type="button" label="Assessment" class="p-button-text p-button-sm" style="height: 20px;width: 100px;text-decoration: underline;float:right" (click)="onClickShowAssessment(directService, true)"></button>
                        <button *ngIf="!!this.directService?.clientMedicalRiskReduction && qualifyForRiskReduction()" pButton pRipple type="button" label="Risk Reduction" class="p-button-text p-button-sm" style="height: 20px;width: 150px;text-decoration: underline;float:right" (click)="onClickShowRiskReduction(directService, true)"></button>
                        <p-dropdown formControlName="subId" [options]="subTypes" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeSubType($event.value)"> 
                            <ng-template let-subType pTemplate="item">
                                <div style="width: 125px;">
                                    <div style="font-weight: bold;">{{subType.description}}</div>
                                    <div style="font-size: smaller;font-weight: 300;"><span title="{{subType.longDescription}}">{{subType.longDescription}}</span></div>
                                </div>
                            </ng-template>
                        </p-dropdown>
                        <!-- <div *ngIf="!dsForm.get('subId').value || dsForm.get('subId').value == 0">
                            <small id="subId-help" class="p-invalid">*Sub Type is required.</small>
                        </div> -->
                        <div *ngIf="!!dsForm.controls['subId'].errors && !!dsForm.controls['subId'].errors['invalidRequiredField']"> 
                            <small id="subId-help" class="p-invalid">*Sub Type is required.</small>
                        </div>
                    </div>   

                    <div class="field col-12" *ngIf="this.subTypeInclusions.length">
                        <label for="dsSubTypeInclusion" style="margin-top: 0.125rem;">Sub Type Inclusions</label>
                        <ng-multiselect-dropdown name="dsSubTypeInclusion" formControlName="dsSubTypeInclusion" [disabled]="clientService.readonly"
                            [settings]="subTypeInclusionDropDownSettings" 
                            [data]="subTypeInclusions"
                            [(ngModel)]="subTypeInclusionsSelectedItems"
                            (onSelect)="onSubTypeInclusionItemSelect($event)"
                            (onSelectAll)="onSubTypeInclusionSelectAll($event)"
                            (onDeSelect)="onSubTypeInclusionItemDeSelect($event)"
                            (onDeSelectAll)="onSubTypeInclusionDeSelectAll($event)"
                        >
                        </ng-multiselect-dropdown>
                    </div>      
                                  
                </div>          
                
                <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                    <div class="field col-6">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <span style="vertical-align:text-top"><label for="paySourceId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Health Insurance Coverage</label></span>
                        <div pTooltip="• Pay source must be provided in order for any Ryan White service to be billable.<br>• Medicaid (Managed Care) - Primary Medical services are NOT billable." style="display:inline-block;" tooltipPosition="right" [escape]="false">
                            <fa-icon class="champ-field-question" [icon]="['fas', 'question-circle']"></fa-icon>
                        </div>
                        <p-dropdown formControlName="paySourceId" [options]="paySources" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangePaySource($event.value)"> </p-dropdown>
                        <!-- <div *ngIf="!dsForm.get('paySourceId').value || dsForm.get('paySourceId').value == 0">
                            <small id="paySourceId-help" class="p-invalid">*Health Insurance Coverage is required.</small>
                        </div>                         -->
                        <div *ngIf="!!dsForm.controls['paySourceId'].errors && !!dsForm.controls['paySourceId'].errors['invalidRequiredField']"> 
                            <small id="paySourceId-help" class="p-invalid">*Health Insurance Coverage is required.</small>
                        </div>                        
                    </div>
                    <div class="field col-6">
                        <span style="vertical-align:text-top"><label for="paySourceReasonId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Ryan White Pay Source Reason</label></span>
                        
                        <p-dropdown formControlName="paySourceReasonId" [options]="paySourceReasons" optionLabel="description" optionValue="id" appendTo="body"> </p-dropdown>
                    </div>            
                </div>          

                <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                    <div class="field col-2">
                        <label htmlFor="plannedUnits" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Planned</label>
                        <div style="width: 5rem;">
                            <!-- <p-inputMask formControlName="plannedUnits" inputId="plannedUnits" mask="9?999.99" maxlength="7"></p-inputMask> -->
                            <p-inputNumber formControlName="plannedUnits" inputId="plannedUnits" [min]="0" [max]="9999" [minFractionDigits]="0" [maxFractionDigits]="2"></p-inputNumber>
                        </div>
                    </div>

                    <div class="field col-2">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <span style="vertical-align:text-top"><label htmlFor="deliveredUnits" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Delivered Units</label></span>
                        <div style="width: 5rem;">
                            <!-- <p-inputMask formControlName="deliveredUnits" inputId="deliveredUnits" mask="9?999.99" maxlength="7"></p-inputMask> -->
                            <p-inputNumber formControlName="deliveredUnits" inputId="deliveredUnits" [min]="0" [max]="9999" [minFractionDigits]="0" [maxFractionDigits]="2" [disabled]=disableUnits></p-inputNumber>
                        </div>
                        <div *ngIf="dsForm.controls['deliveredUnits'].invalid && (dsForm.controls['deliveredUnits'].dirty || dsForm.controls['deliveredUnits'].touched)">
                            <div *ngIf="dsForm.controls['deliveredUnits'].errors.required">
                                <small id="deliveredUnits-help" class="p-invalid">*Delivered Units are required.</small>
                            </div>
                        </div>
                    </div>                    

                    <div class="field col-3">
                        <label for="dsUnit" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Unit</label>
                        <input type="text" disabled="true" formControlName="dsUnit" inputId="dsUnit" style="width:165px;height: 28px" >
                    </div>

                    <div class="field col-5">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon> <!-- [styles]="{'color': 'red', 'padding': '0px 3px 0px 0px'}" -->
                        <span style="vertical-align:text-top"><label for="employeeId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Employee</label></span>
                        <p-dropdown formControlName="employeeId" [options]="employees" optionLabel="fullName" optionValue="id" appendTo="body"> </p-dropdown>
                        <!-- <div *ngIf="!dsForm.get('employeeId').value || dsForm.get('employeeId').value == 0">
                            <small id="employeeId-help" class="p-invalid">*Employee is required.</small>
                        </div> -->
                        <div *ngIf="!!dsForm.controls['employeeId'].errors && !!dsForm.controls['employeeId'].errors['invalidRequiredField']"> 
                            <small id="employeeId-help" class="p-invalid">*Employee is required.</small>
                        </div>                         
                    </div>                    
                </div>

                <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">
                    <div class="field col-3" *ngIf="this.getClientCohortStudy()">
                        <label for="cohortIntervention" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Intervention Provided?</label>
                        <div class="formgroup-inline">
                            <div class="field-radiobutton">
                                <p-radioButton name="cohortIntervention" value=1 label="Yes" formControlName="cohortIntervention" (click)="onChangeRadioIntervention()"></p-radioButton>
                            </div>
                            <div class="field-radiobutton">
                                <p-radioButton name="cohortIntervention" value=2 label="No" formControlName="cohortIntervention" (click)="onChangeRadioIntervention()"></p-radioButton>
                            </div>
                        </div>
                    </div>

                    <div class="field col-4">
                        <label for="barrier" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Barrier</label>
                        <p-dropdown formControlName="barrier" [options]="barriers" optionLabel="description" optionValue="id" appendTo="body"> </p-dropdown>
                        <div *ngIf="!!dsForm.errors?.invalidBarrier">
                            <small id="barrier-help" class="p-invalid">{{dsForm.errors?.invalidBarrier}}</small> 
                        </div>
                    </div>                    

                    <div class="field col-5">
                        <label for="notes" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Notes</label>
                        <textarea formControlName="notes" id="notes" rows="5" cols="25" pInputTextarea style="height:80px;"></textarea>
                    </div>                    
                </div>

                <!-- <div class="flex justify-content-end" > -->
                    <div class="field col-12">
                        <div class="flex justify-content-end" > <!--class="flex align-content-end"-->
                            <!--<button class="btn btn-primary" type="submit" [disabled]="!dsForm.valid">SaveChanges</button>-->
                            <!-- <button pButton pRipple class="btn" (click)="onSaveAndNewDirectServiceChangesConfirm($event)" icon="pi pi-check"  *ngIf="!clientService.readonly"
                                    label="Save & New" [disabled]="!dsForm.valid" style="width: 130px;float: right;margin-top: 0.5rem;"></button> -->

                            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'13rem'}"  styleClass="p-button-text " class="p-button-text mr-2"
                                    (click)="onSaveAndNewDirectServiceChangesConfirm($event)" *ngIf="!clientService.readonly" [disabled]="!dsForm.valid"> 
                                    <i class="fa-sharp fa-plus fa-lg" ></i> <span>Save & New</span>
                            </p-button>
                            <!--  -->
                            
                            <!-- <button pButton pRipple class="btn btn-primary" (click)="onSaveDirectServiceChangesConfirm($event)" icon="pi pi-check" *ngIf="!clientService.readonly"
                                    label="Save" [disabled]="!dsForm.valid" style="width: 130px;float: right;margin-right: 5px;margin-top: 0.5rem;"></button> -->

                            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'10rem'}"  styleClass="p-button-text " class="p-button-text mr-2"
                                    (click)="onSaveDirectServiceChangesConfirm($event)" *ngIf="!clientService.readonly" [disabled]="!dsForm.valid"> 
                                    <i class="fa-sharp fa-plus fa-lg"></i> <span>Save</span>
                            </p-button>       
                            <!--  -->

                            <p-button  pButton pRipple type="button" [ngStyle]="{'width':'10rem'}"  styleClass="p-button-text " class="p-button-text mr-2"
                                    (click)="onHideDirectServiceDialog($event)" > 
                                    <i class="fa-sharp fa-xmark fa-lg"  style="color: red;" ></i> <span>Cancel </span>
                            </p-button>    

                            <!-- &nbsp;&nbsp;&nbsp;
                            <button pButton pRipple label="Cancel" icon="pi pi-times" class="btn" 
                                    (click)="onHideDirectServiceDialog($event)" style="width: 130px;float: right;margin-right: 5px;margin-top: 0.5rem;"></button>      -->
                        </div>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidUnitCost">
                        <input name="ackInvalidUnitCost" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidUnitCostClick()">
                        <small id="invalidunitCost-help" class="p-invalid">{{dsForm.errors?.invalidUnitCost}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidIntervention">
                        <input name="ackInvalidIntervention" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidInterventionClick()">                          
                        <small id="invalidintervention-help" class="p-invalid">{{dsForm.errors?.invalidIntervention}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidMedicaidExpansion">
                        <input name="ackInvalidMedicaidExpansion" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidMedicaidExpansionClick()">                             
                        <small id="invalidmedicaidExpansion-help" class="p-invalid">{{dsForm.errors?.invalidMedicaidExpansion}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidFirstEISSubTypeDate">
                        <!-- <input name="ackInvalidFirstEISSubTypeDate" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidFirstEISSubTypeDateClick()">                            -->
                        <small id="invalidfirstEISSubTypeDate-help" class="p-invalid">{{dsForm.errors?.invalidFirstEISSubTypeDate}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidFirstBillableEISSubTypeDate">
                        <input name="ackInvalidFirstBillableEISSubTypeDate" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidFirstBillableEISSubTypeDateClick()">                          
                        <small id="invalidfirstBillableEISSubTypeDate-help" class="p-invalid">{{dsForm.errors?.invalidFirstBillableEISSubTypeDate}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidPaySourceBillable">
                        <input name="ackInvalidPaySourceBillable" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidPaySourceBillableClick()">                         
                        <small id="invalidPaySourceBillable-help" class="p-invalid">{{dsForm.errors?.invalidPaySourceBillable}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidBillableTimePeriod">
                        <input name="ackInvalidBillableTimePeriod" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidBillableTimePeriodClick()"> 
                        <small id="invalidBillableTimePeriod-help" class="p-invalid">{{dsForm.errors?.invalidBillableTimePeriod}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidHopwaWorksheetExpired">
                        <input name="ackInvalidHopwaWorksheetExpired" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidHopwaWorksheetExpiredClick()"> 
                        <small id="invalidHopwaWorksheetExpired-help" class="p-invalid">{{dsForm.errors?.invalidHopwaWorksheetExpired}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidHopwaWorksheetQualified">
                        <input name="ackInvalidHopwaWorksheetQualified" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidHopwaWorksheetQualifiedClick()"> 
                        <small id="invalidHopwaWorksheetQualified-help" class="p-invalid">{{dsForm.errors?.invalidHopwaWorksheetQualified}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidMedicalCaseManagementData">
                        <input name="ackInvalidMedicalCaseManagementData" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidMedicalCaseManagementDataClick()"> 
                        <small id="invalidMedicalCaseManagementData-help" class="p-invalid">{{dsForm.errors?.invalidMedicalCaseManagementData}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidDirectServiceBillableByFunderBillingRestrictionEncounter">
                        <input name="ackInvalidDirectServiceBillableByFunderBillingRestrictionEncounter" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidDirectServiceBillableByFunderBillingRestrictionEncounterClick()"> 
                        <small id="invalidDirectServiceBillableByFunderBillingRestrictionEncounter-help" class="p-invalid">{{dsForm.errors?.invalidDirectServiceBillableByFunderBillingRestrictionEncounter}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidEmergencyFinancialOtherAllowed">
                        <input name="ackInvalidEmergencyFinancialOtherAllowed" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidEmergencyFinancialOtherAllowedClick()"> 
                        <small id="invalidEmergencyFinancialOtherAllowed-help" class="p-invalid">{{dsForm.errors?.invalidEmergencyFinancialOtherAllowed}}</small>
                    </div>                    
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidAgeForIndeterminateStatus">
                        <input name="ackInvalidAgeForIndeterminateStatus" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidAgeForIndeterminateStatusClick()"> 
                        <small id="invalidAgeForIndeterminateStatus-help" class="p-invalid">{{dsForm.errors?.invalidAgeForIndeterminateStatus}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidMoreThan4UnitsIn12Months">
                        <input name="ackInvalidMoreThan4UnitsIn12Months" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidMoreThan4UnitsIn12MonthsClick()"> 
                        <small id="invalidMoreThan4UnitsIn12Months-help" class="p-invalid">{{dsForm.errors?.invalidMoreThan4UnitsIn12Months}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidDirectServiceFunded">
                        <input name="ackInvalidDirectServiceFunded" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidDirectServiceFundedClick()"> 
                        <small id="invalidDirectServiceFunded-help" class="p-warning">{{dsForm.errors?.invalidDirectServiceFunded}}</small>
                    </div>
                    <div class="field col-12" *ngIf="!!dsForm.errors?.invalidAssessment">
                        <input name="ackInvalidAssessment" type="checkbox" class="p-checkbox-acknowledgement"
                        (click)="onAckInvalidAssessmentClick()"> 
                        <small id="invalidAssessment-help" class="p-invalid">{{dsForm.errors?.invalidAssessment}}</small>
                    </div>
                    <!-- Static messages below  -->
                    <div class="field col-12" *ngIf="!!getUnbillableReasonDescription()">
                        <small id="unbillableReason-help" class="p-invalid">{{getUnbillableReasonDescription()}}</small>
                    </div>
                    <div class="field col-12" *ngIf="hasMissingRequiredFieldErrors()">
                        <button pButton pRipple type="button" label="*View Missing Required Data" class="p-button-text p-button-sm" 
                                style="text-decoration: underline;color: #f44336 !important; padding: 0rem 0rem;" (click)="this.requiredFieldErrorsSub.onShowRequiredFieldErrorsDialog()"></button>
                        <!-- width: 170px;-->
                        <!-- <div (mouseover)="this.requiredFieldErrorsSub.onShowRequiredFieldErrorsDialog()">
                            <small id="clientRequiredFieldErrors-help" class="p-invalid"><b>*View Missing Required Data</b></small>
                        </div> -->
                    </div>
                <!-- </div> -->
            </div>
        </div>
    <!-- </ng-template> -->
<!-- </p-dialog> -->
<!-- </div> -->

<!-- Risk Reduction Dialog -->
<app-risk-reduction (dialogCompleted)="onRiskReductionDialogCompletedNotification($event)"></app-risk-reduction>

<!-- Assessment Dialog -->
<app-assessment (dialogCompleted)="onAssessmentDialogCompletedNotification($event)"></app-assessment>

<!-- Client Required Field Error Dialog -->
<app-client-required-field-errors></app-client-required-field-errors>

</form>

<!--appendToBody="true"  -->

<!-- direct service grid && !hasChanges -->

