import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ClientListResponse, clientListRequest, PrimaryMedicalInfo } from './client-list-models' 
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
//import { stringify } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class ClientListService {

  // URL which returns list of JSON items (API end-point URL)
  private readonly URL = environment.apiURL + '/clientlist';


  constructor(private http: HttpClient) { }

  public getClientList() {
    // this.http.get<any>(this.URL).toPromise().then(res => <ClientListResponse[]> res.data).then( data=> {return data;});
     return this.http.get(this.URL);

  }

  public getClientListWithParams(request: clientListRequest) 
  { 
     let url  = this.URL; 
     return this.http.post(url, request);

  }

  public getDefaults (provider_id:number) 
  {
    let url  = this.URL + '/defaults' + '/?provider_id=' + provider_id?.toString();
    return this.http.get(url);
  }


  public getPrimaryMedicalData( clientGuid:string   ) 
  { 

    var value:string;
    let params = new HttpParams();
    params = params.append('clientGuid', clientGuid);

    let url  = this.URL  + "/primarymedical";
     return this.http.get<PrimaryMedicalInfo>(url, {params:params});

  }


  public deleteClient(model:ClientListResponse, undelete:boolean=false, userID ) : Observable<boolean>
{ 
  let url  = this.URL + '/deleteClient';
  let params = new HttpParams();
  params = params.append('guid', model.clientGuid.toString());
  params = params.append('undelete', undelete);
  params = params.append('userID', userID);

  return this.http.get<boolean>(url, { params:params });
}
  
}
