import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserMaintenanceService } from 'src/app/admin/user-maintenance/user-maintenance.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { AdminEmployeesModel, AdminUserModel, AdminUserResponseModel, ProviderClinicsPermissionModel, ProvidersResponseModel } from 'src/app/models/admin.models';
import { v4 as uuidv4 } from 'uuid';
//import { EncryptionService } from 'src/app/services/encryption.service';
import { CommonDataService } from 'src/app/services/common-data.service';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { UserProviderClinicPermissionComponent } from '../user-provider-clinic-perm/user-provider-clinic-perm.component';
import { UserProviderClinicPermService } from '../user-provider-clinic-perm/user-provider-clinic-perm.service';
import { EmployeeComponent } from '../employee/employee.component';

@Component({
  selector: 'app-user-maintenance-detail',
  templateUrl: './user-maintenance-detail.component.html',
  styleUrls: ['./user-maintenance-detail.component.scss','../admin-styles.scss'],
  providers: [MessageService]
})
export class UserMaintenanceDetailComponent implements OnInit {

  // @Output("onRefresh") refreshPayer: EventEmitter<any> = new EventEmitter();
  fg: UntypedFormGroup;
  params: Partial<AdminUserModel> = {};
  userId:number = 0;
  isNewUser:boolean = false;
  guid:string;
  user:Partial<AdminUserModel> = {};
  employees:AdminEmployeesModel[];
  showUserDetailDialog:boolean = false;
  providers:ProvidersResponseModel[] = [];

  @ViewChild(UserProviderClinicPermissionComponent) userProviderClinicPermissionComponent:  UserProviderClinicPermissionComponent;
  @ViewChild(EmployeeComponent) employeeComponent:  EmployeeComponent;

   getGuidControl = () => this.fg?.get('guid');
   getIdControl = () => this.fg?.get('id');
   getUserIdControl = () => this.fg?.get('userId');
   getUserNameControl = () => this.fg?.get('name');
   getPasswordControl = () => this.fg?.get('password');
   getPasswrodConfirmControl = () => this.fg?.get('passwordConfirm');
   getLastNameControl = () => this.fg?.get('lastName');
   getMiddleNameControl = () => this.fg?.get('middleName');
   getFirstNameControl = () => this.fg?.get('firstName');
   getEmailAddressControl = () => this.fg?.get('emailAddress');
   getAccountEnabledControl = () => this.fg?.get('accountEnabled');
   getGranteePrivilegeControl = () => this.fg?.get('granteePrivilege');
   getAdminPrivilegeControl = () => this.fg?.get('adminPrivilege');
   getEditPrivilegeControl = () => this.fg?.get('editPrivilege');
   getReadOnlyPrivilegeControl = () => this.fg?.get('readOnlyPrivilege');   
   getDeletedControl = () => this.fg?.get('deleted');
   getEmployeeIdControl = () => this.fg?.get('employeeId');
   getLastLoginDateControl = () => this.fg?.get('lastLoginDate');
   getProvidersControl = () => this.fg?.get('providerUsers');

   getProviderName( id ) {
      return this.providers.find(x=> x.id == id)?.providerName;
   } 

   isProviderUserValid = () => { if (  ( this.getAdminPrivilegeControl().value || this.getGranteePrivilegeControl().value)  
                                        ||  this.user?.providerUsers?.length >  0 )
                                                return true;  else return  false;   }  

  adminUserModelDefaults: Partial<AdminUserModel> = { guid: '00000000-0000-0000-0000-000000000000',
                                                      userId:0       };                                                
constructor(
              private app: AppContextService,
              private fb: UntypedFormBuilder,
              private router: Router,
              private route: ActivatedRoute,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public diagRef: DynamicDialogRef, 
              public diagConfig: DynamicDialogConfig,
              public userMaintenanceService: UserMaintenanceService,
              public validationHelper:FormValidations,
              private cds: CommonDataService,
              private userProviderClinicPermService: UserProviderClinicPermService,

            ) 
   {
          this.params = this.diagConfig.data;
          this.getProviders();

          this.fg = fb.group({
            guid:[uuidv4()],
            id: [0],
            userId: [0],
            name: [null, [Validators.required]],
            // only need as log as CHAMP 35 still runs, once out of service pwd can be removed. Will be managed by firebase - DL
            password: [null, [validationHelper.passwordValidator()]], 
            passwordConfirm: [null, [validationHelper.passwordValidator()]],
            lastName: [null, [Validators.required]],
            middleName: [null],
            firstName: [null, [Validators.required]],
            emailAddress: [null, [Validators.required]],
            accountEnabled:  [false],
            granteePrivilege:  [false],
            adminPrivilege:  [false],
            editPrivilege:  [false],
            readOnlyPrivilege:  [false],
            deleted:  [false],
            employeeId:  [null],
            lastLoginDate:  [null]
            //providers: [null]
          },
          {
            //validators: [this.customValidator()],
            //asyncValidators: [this.timePeriodValidator()]
            //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
          });    
   }


  ngOnInit(): void {
      if (this.params == null )
      {
        // New User
        this.isNewUser = true;
        this.user = { ... this.adminUserModelDefaults };
        this.userProviderClinicPermService.user = this.user;
        this.getProvidersClincList();
         //this.employeeComponent.load(data.employeeId, this.user)
      }
      else{
        this.guid = this.params?.guid ; // if new user, id  = 0 
        if (this.guid != null ){
              this.getUser(this.params.guid);
        }
        else{ 
            // Clone User 
            this.user =  this.params;
            this.userProviderClinicPermService.user = this.user;
            if (this.user != null )  this.loadFormGroup(); // Load if clone user, dont load for new user
        }
     }
    //this.getDefaults();
    this.showUserDetailDialog = true 
  }
 
  loadEmployees(){
   //let providerID = this.user?.providerUsers?.[0]?.providerId ?? 0;
   let provList:string=''; 
   this.user?.providerUsers.forEach(p => {
      provList = provList + p?.providerId + ';';
   });
   //if ( providerID ) this.employeeComponent?.getEmployees(providerID);
   if ( provList ) this.employeeComponent?.getEmployeesFromMultipleProviders(provList);

  }

  loadFormGroup(){
      this.getGuidControl().setValue(this.user.guid);
      this.getIdControl().setValue(this.user.id);
      this.getUserIdControl().setValue(this.user.userId);
      this.getUserNameControl().setValue(this.user.name);
      this.getPasswordControl().setValue(this.user.password);
      this.getFirstNameControl().setValue(this.user.firstName);
      this.getLastNameControl().setValue(this.user.lastName);
      this.getMiddleNameControl().setValue(this.user.middleName);
      this.getEmailAddressControl().setValue(this.user.emailAddress);
      this.getAdminPrivilegeControl().setValue(this.user.adminPrivilege);
      this.getGranteePrivilegeControl().setValue(this.user.granteePrivilege);
      this.getEditPrivilegeControl().setValue(this.user.editPrivilege);
      this.getReadOnlyPrivilegeControl().setValue(this.user.readOnlyPrivilege);
      this.getDeletedControl().setValue(this.user.deleted);
      this.getAccountEnabledControl().setValue(this.user.accountEnabled);
      this.getLastLoginDateControl().setValue(this.user.lastLoginDate);
      this.getEmployeeIdControl().setValue(this.user.employeeId);
      //this.getProvidersControl().setValue(this.user.providerUsers);
  }

  getUser(guid:string ) {
    this.userMaintenanceService.getUser(guid).subscribe({
      next: (data:AdminUserModel) => {    
            this.user =  data;
            this.loadEmployees();
            
            //this.userProviderClinicPermissionComponent.setData(this.user);
            this.userProviderClinicPermService.user = this.user;
            this.employeeComponent.load(data.employeeId, this.user)
            this.loadFormGroup();
            this.fg.markAsPristine();
              // //const data = {"data":{"businessData":{"accessToken":"AAILcG9ydGFsX3VzZXKLuaHyVm6DCicccB32tWa_wCpeLQ-SMHEw-Jn2F5zhnIVzevQZksgaGX3T4YMC5uvt9XAjPV-v0wlSwMw9oRbBnyWSzhSEGgr0_4W1cIDWsw","accessId":"334"}},"status":"200"};
              // const data2 = {"data":data.password};
              // console.log(data.password);
              // const decrypted2 = this.encryption.decryptUsingTripleDES(data.password);
              // console.log(decrypted2);

              // // // DES
              // const encrypted = this.encryption.encryptUsingTripleDES(data2, true);
              // console.log(encrypted);
              // const decrypted = this.encryption.decryptUsingTripleDES(encrypted);
              // console.log(decrypted);
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed T' });
          console.log(error);
         },
      complete: () => { }
      });
  }  
  

  getProviders( ) {
    this.userMaintenanceService.getProviders().subscribe({
       next: (data:ProvidersResponseModel[]) => {    
          this.providers = data;
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed T' });
          console.log(error);
         },
      complete: () => { }
      });
  }  

  public findInvalidControls() {
    const invalid = [];
    const controls = this.fg.controls;
    for (const name in controls) {
        if (controls[name].invalid) {
            invalid.push(name);
        }
    }
    return invalid;
} 


onSaveUser(event: Event) {
  //this.findInvalidControls();
  this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure that you want save?",
      icon: "pi pi-info-circle",
      accept: () => {
        try {
          // set the latest model into the clientProductsDetailRow
         // this.params.clientProductsDetailRow = this.getClientProductDetailModel();
         this.SaveUser();
         
         //this.diagRef.close(this.params);
          // success message should be displayed from onShowProductDetails in client products component in the onClose subscribe
          // this.messageService.add({severity:'success', summary: 'Successful', detail: 'Changes saved successfully.', life: this.app.messageDisplayTime});
        } 
        catch (error) {
          console.log(error.error?.message); console.log(error.error.data);
        //  this.helper.displayError(this.messageService, "Changes failed to save successfully.", error);
          this.messageService.add({severity: "error", summary: "Failure", detail: "Changes failed to save successfully.", life: this.app.messageDisplayTime});
        }	  
      },
      reject: () => {
        this.messageService.add({severity: "error", summary: "Aborted", detail: "Changes canceled", life: this.app.messageDisplayTime});
      }
    });
  }  


SaveUser(){
    var data: any = { }; // Partial<Clients>
    Object.assign(data, this.fg.value)
    data.employeeId = this.employeeComponent?.employeeId;
    data.providerUsers = this.user.providerUsers;
    this.userMaintenanceService.saveUser(data).subscribe({
      next: (response:AdminUserResponseModel) => {    

           if (response.success){
              console.log(response.data);
              this.user =  response.data;

              if (this.isNewUser){
                this.employeeComponent.load(data.employeeId, this.user)
              }

              this.isNewUser = false;
              //this.fg.get('id').setValue(response.data.id);

              this.user = response.data; 
              this.loadFormGroup();
              this.messageService.add({severity:'success', detail: response.message, summary: 'User Saved.', life: this.app.messageDisplayTime});
            }
            else{
              this.messageService.add({ severity: 'error', detail: response.message, summary: 'Request failed' });
            }
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', detail: error, summary: 'Request failed' });
          console.log(error);
         },
      complete: () => { }
      });

  }


  onProvidersClincPermRefresh(e){
       //this.user.providerUsers = data;
       this.loadEmployees();
  }

  getProvidersClincList( ) {
    this.userMaintenanceService.getProviderClinicsPermissionList(this.userProviderClinicPermService.user?.userId ?? 0).subscribe({
       next: (data:ProviderClinicsPermissionModel[]) => {    
        this.userProviderClinicPermService.user.providerClinicPermissionList = data;
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed T' });
          this.app.loginSvc.LogError(error.message);
         },
      complete: () => { }
      });

  }  
}
