<div>
    <p-toast class="custom-toast" [baseZIndex]="500000"  ></p-toast> <!--  [ngStyle]="{ 'width': '90%' }"   position="center" -->
    <p-confirmPopup></p-confirmPopup>   
</div>

<!-- <div  *ngIf="showSpinner">
<p-progressSpinner></p-progressSpinner>
</div> -->

<form  [formGroup]="form"  autocomplete="off">   
     <div  >   <!-- class="card champ-card" -->
            <div class="progress-spinner" *ngIf="showSpinner">
                <p-progressSpinner></p-progressSpinner>
            </div>

            <div class="fluid formgrid grid">
                <div class="col-5" >
                    <div style="margin-bottom: 3rem;" >
                        <div class="form-group">
                                <!-- <label class="control-label mr-2" for="drpClientStatus" >Report Menu: </label>
                                <select id="reportMenu"  class="form-control" [(ngModel)]="reportMenu" (change)="onReportMenuChange()" >
                                </select>    -->
                                <!-- <option *ngFor="let item of _clientStatusList" [ngValue]="item.id" [ngStyle]="{'font-weight':item.id === '0' ? 'bold' : 'normal' }">{{item.description}}</option> -->

                                <p-dropdown formControlName="reportsMenuId" [options]="reportMenu" optionLabel="menuName" optionValue="id" appendTo="body" (onChange)="onReportMenuChange()" 
                                        [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}" > </p-dropdown> 
                        </div>      


                        <p-tree [value]="nodes"  scrollHeight="500px" selectionMode="single"  [(selection)]="selectedReport" (onNodeSelect)="nodeSelect($event)">
                            <ng-template let-node pTemplate="url">
                                <a [href]="node.data">{{node.label}}</a>
                            </ng-template>
                            <ng-template let-node pTemplate="default">
                                <b>{{node.label}}</b>
                            </ng-template>
                        </p-tree>


                    </div>
                </div>


                <div class="col-7" *ngIf="reportId > 0 ">
                    <div class="card champ-card">
                            <div  class="row" style="margin-bottom: 1.5rem;">
                                    <div class="col-9" style="margin-left: 0rem; font-weight: bold;  text-decoration: underline;">  {{reportData?.reportNameLong + " Report"}} </div>       
                                    <div class="col-3" style="margin-left: 0rem; "> Report ID: ( {{reportData?.reportId }} ) </div>                 
                            </div>
                        
                            <div  class="row" style="margin-bottom: 1.5rem;">
                                <div class="col">   {{reportData?.reportDesc}}</div>    
                            </div>

                            <ng-container formArrayName="parameters" >
                                <ng-container *ngFor="let param of parameters.controls; let i = index">
                                    <div [formGroup] = "param">
                                        
                                        <div>
                                            <!-- <div>{{ param.controls | json }}</div> -->
                                            <!-- <div> {{ getParamType(param)?.value  }} </div> -->
                                            <!-- param.controls['paramType'].value -->
                                            
                                            <div *ngIf="param.controls['paramVisible'].value == 1" >
                                                <label > {{param.controls['label_text'].value }} </label>  
                                                
                                                <div  *ngIf="param.controls['paramType'].value== controlTypeEnum.ComboBox" style="width: 99%;margin-bottom: 1rem;">    
                                                    <p-dropdown [options]="param.controls['paramValues']?.value " optionLabel="description" optionValue="value" formControlName="value" 
                                                        (onChange)="onChangeParamValue($event.value, param)" [style]="{'width':'100%'}"></p-dropdown>                                
                                                    <!-- param.controls['paramValues'].value -->
                                                </div> 

                                                <div  *ngIf="param.controls['paramType'].value== controlTypeEnum.ComboEditBox" style="width: 99%;margin-bottom: 1rem;">    
                                                    <p-dropdown [options]="param.controls['paramValues']?.value" optionLabel="description" optionValue="value" formControlName="value"
                                                              [filter]="true" filterBy="description" [showClear]="true"  placeholder=" "  [editable]="true"  [required]="false"
                                                              (onChange)="onChangeParamValue($event.value, param)" [style]="{'width':'100%'}" >
                                                    </p-dropdown>                                                    
                                                </div> 

                                                <div  *ngIf="param.controls['paramType'].value== controlTypeEnum.DateTime" style="width: 99%;margin-bottom: 1rem;">    
                                                    <input type="text"  autocomplete="off" bsDatepicker placeholder="MM-DD-YYYY" style="width:90px"   class="champ-date-picker" pInputText
                                                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false}" 
                                                                formControlName="value" appendTo="body" />
                                                                <!-- [container]="'.p-dynamicdialog' " -->
                                                                <!-- dateCustomClasses="show-at-top" -->
                                                                <!-- [maxDate]="maxAidsDiagDate" [minDate]="minAidsDiagDate" -->
                                                                <!-- [ngClass]="{'p-invalid' : demographics.controls['idAIDSDiagnosis'].invalid}"  -->

                                                </div> 

                                                <div  *ngIf="param.controls['paramType'].value== controlTypeEnum.CheckBox" style="width: 99%;margin-bottom: 1rem;">    
                                                    <p-checkbox value="false"  formControlName="value" [binary]="true"></p-checkbox>
                                                </div> 


                                                <div  *ngIf="param.controls['paramType'].value== controlTypeEnum.TextBox" style="width: 99%;margin-bottom: 1rem;">
                                                    <input type="text" pInputText class="p-inputtext-sm"   style="width:90px" formControlName="value" >   
                                                </div> 

                                            </div>
                                        </div>  
                                        <!-- {{paramForm}} -->

                                    </div>
                                </ng-container>
                            </ng-container>

                            <div >
                                <!-- <button pButton type="button" label="Run Report" icon="pi pi-plus" style="width: 10rem;" (click)="onRunReport()" ></button> -->


                                <p-button  pButton pRipple type="button" [ngStyle]="{'width':'15rem'}"  styleClass="p-button-text " class="p-button-text mr-1 "
                                        (click)="onRunReport()"  > 
                                        <i class="fa-sharp fa-thin fa-chart-line fa-xl"></i><span class="text-lg">Run report</span>
                                </p-button>   
                                <!-- <td><a routerLink="../client-form/{{ClientListResponse.clientGuid}}"    routerLinkActive="active" > {{ClientListResponse.clientAltID}}</a></td>   -->
                            </div>
                    </div>
                </div>                    
             
               <div class="col-8" *ngIf="reportId == 0 ">
                    <div  class="row" style="margin-bottom: 1.5rem;">
                        <div class="col">   Select Report to run. </div>    
                    </div>
                </div>


            </div>
            <!-- <p-progressBar mode="indeterminate"></p-progressBar> -->
    </div>
</form>

