<form [formGroup]="socio_econ" autocomplete="off" *ngIf="socio_econ">
    <div class="p-fluid p-formgrid grid">
        <div class="field col-12 xl:col-6">
            <div class="card champ-card-sm">
                <div class="champ-card-header-sm" >Socio-Economic</div>
                <div class="champ-card-body-sm" >
                    <div class="p-fluid p-formgrid grid">
                        <div class="field col-6">
                            <div class="p-fluid p-formgrid grid">

                                <div class="field col-12">
                                    <span style="vertical-align:text-top">
                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(socio_econ.controls['idEmploymentStatus'])"></fa-icon>
                                        <label htmlFor="idEmploymentStatus">Employment Status</label>
                                        <label id="idEmploymentStatusExpire">
                                            <app-client-expired-field *ngIf="!!this.clientData" [fieldId]="MasterFields.EmploymentStatus" [control]="socio_econ.controls['idEmploymentStatus']"
                                                        [lastUpdated]="this.clientData.clientEmploymentStatus?.datetime" (isFieldExpired)="onCheckedExpiredField($event)"></app-client-expired-field>
                                        </label>                                         
                                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" (click)="openHistoryWindow('EMPSTAT', 'Employment Status')" ></button>  <!-- style="width: 100px; height: 20px; float:right;"  -->
                                        <p-dropdown formControlName="idEmploymentStatus" [options]="empStatuses" optionLabel="description" optionValue="id" appendTo="body" 
                                                [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}"  > </p-dropdown>      
                                        <div  *ngIf="socio_econ.controls['idEmploymentStatus'].invalid && (socio_econ.controls['idEmploymentStatus'].dirty || socio_econ.controls['idEmploymentStatus'].touched)"> 
                                            <div *ngIf="socio_econ.controls['idEmploymentStatus'].errors.required || socio_econ.controls['idEmploymentStatus'].errors['invalidSelection']"> 
                                                <small id="idEmploymentStatus-help" class="p-invalid">Employment Status is required.</small>
                                            </div>
                                        </div>
                                    </span>
                                </div> 
        
                                <div class="field col-12">
                                    <span style="vertical-align:text-top">
                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(socio_econ.controls['idCareGiver'])"></fa-icon> 
                                        <label htmlFor="idCareGiver">Care Giver Support</label> 
                                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px; height: 20px; float:right;"   (click)="openHistoryWindow('CAREGIVE', 'Care Giver Support')" ></button>  
                                        <p-dropdown formControlName="idCareGiver" [options]="careGivers" optionLabel="description" optionValue="id" appendTo="body" 
                                                [style]="{'width': '100%', 'margin-right': '1rem','min-width':'15rem'}"  > </p-dropdown>      
                                        <!-- <div  *ngIf="socio_econ.controls['idCareGiver'].invalid && (socio_econ.controls['idCareGiver'].dirty || socio_econ.controls['idCareGiver'].touched)"> 
                                            <div *ngIf="socio_econ.controls['idCareGiver'].errors.required"> 
                                                <small id="idCareGiver-help" class="p-invalid">Care Giver Support is required.</small>
                                            </div>
                                            <div *ngIf="socio_econ.controls['idEmploymentStatus'].errors['invalidSelection']"> 
                                                <small id="idEmploymentStatus-help" class="p-invalid">Employment Status is required.</small>
                                            </div>
                                        </div> -->
                                    </span>
                                </div> 
                
                                <div class="field col-12">
                                    <span style="vertical-align:text-top">
                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(socio_econ.controls['idUsualSourcePrimaryCare'])"></fa-icon>
                                        <label htmlFor="idUsualSourcePrimaryCare">Primary Care Source</label> 
                                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;" (click)="openHistoryWindow('PRIME', 'Primary Care Source')" ></button>  
                                        <p-dropdown formControlName="idUsualSourcePrimaryCare" [options]="careGivers" optionLabel="description" optionValue="id" appendTo="body" 
                                                [style]="{'width': '100%', 'margin-right': '1rem','min-width':'15rem'}"  > </p-dropdown>      
                                        <!-- <div  *ngIf="socio_econ.controls['idUsualSourcePrimaryCare'].invalid && (socio_econ.controls['idUsualSourcePrimaryCare'].dirty || socio_econ.controls['idUsualSourcePrimaryCare'].touched)"> 
                                            <div *ngIf="socio_econ.controls['idUsualSourcePrimaryCare'].errors.required"> 
                                                <small id="idUsualSourcePrimaryCare-help" class="p-invalid">Usual Source of Primary Care is required.</small>
                                            </div>
                                            <div *ngIf="socio_econ.controls['idEmploymentStatus'].errors['invalidSelection']"> 
                                                <small id="idEmploymentStatus-help" class="p-invalid">Employment Status is required.</small>
                                            </div>                                            
                                        </div> -->
                                    </span>
                                </div> 
        
                                <div class="field col-12">
                                    <span style="vertical-align:text-top">
                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(socio_econ.controls['idLivingIn'])"></fa-icon>
                                        <label htmlFor="idLivingIn">Living In</label>
                                        <label id="idLivingInExpire">
                                            <app-client-expired-field *ngIf="!!this.clientData" [fieldId]="MasterFields.LivingIn" [control]="socio_econ.controls['idLivingIn']" 
                                               [lastUpdated]="this.clientData.clientLiveIn?.datetime" (renewValue)="onRenewValue($event)" (isFieldExpired)="onCheckedExpiredField($event)"></app-client-expired-field>
                                        </label>                                         
                                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;" (click)="openHistoryWindow('LIVEIN', 'Living In')"  ></button>  
                                        <p-dropdown formControlName="idLivingIn" [options]="livingIns" optionLabel="description" optionValue="id" appendTo="body" 
                                                [style]="{'width': '100%', 'margin-right': '1rem', 'min-width':'15rem'}"  > </p-dropdown>      
                                        <div  *ngIf="socio_econ.controls['idLivingIn'].invalid && (socio_econ.controls['idLivingIn'].dirty || socio_econ.controls['idLivingIn'].touched)"> 
                                            <div *ngIf="socio_econ.controls['idLivingIn'].errors.required || socio_econ.controls['idLivingIn'].errors['invalidSelection']"> 
                                                <small id="idLivingIn-help" class="p-invalid">Living in is required.</small>
                                            </div>
                                                  
                                        </div>
                                    </span>
                                </div> 
                            </div>
                        </div>

                        <div class="field col-6" formGroupName="incomeData"  > <!--  formGroupName="incomeData" [formGroup]="socio_econ.get['incomeData']" -->
                            <div class="field col-12">
                                    Income Data:
                                    <label #idIncomeDataExpire id="idIncomeDataExpire">  <!--  [formGroup]="socio_econ.get['incomeData'] -->
                                        <app-client-expired-field *ngIf="!!this.clientData" [fieldId]="MasterFields.FamilyIncome" [formGroup]="this.getIncomeData()"  
                                           [lastUpdated]="this.clientData.clientIncomeData?.datetime" (renewValue)="onRenewValue($event)" (isFieldExpired)="onCheckedExpiredField($event)"></app-client-expired-field>
                                    </label>                                     
                                    <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;" (click)="openHistoryWindow('INCDATA', 'Income Data')"  ></button>  
                            </div>
                            
                            <div class="card" style="padding: 5px; background-color:initial ;" >
                                <div class="field col-12" style="padding:0rem">
                                    <div class="formgroup-inline" style="margin-top: 5px; margin-bottom: 15px; margin-right: 5px;">
                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(this.getIncomeData().controls['idMedicallyUnableToWork'])"></fa-icon>
                                        <div class="field col-12">
                                                <label  style="font-weight:normal; margin-bottom: 10px;">Medically unable to work?</label>
                                                <!-- <p-checkbox [style]="{'width': '30px', 'padding': '0px 0px 0px 7px'}" formControlName="idDiagnosedLast12Months" binary="true" inputId="idDiagnosedLast12Months"></p-checkbox>
                                                <label for="dsTobaccoYesNo">Is this patient using Tobacco products?</label> style="margin-right: 1rem; margin-left: 1rem; margin-bottom: 1rem;" -->
                                        </div> 
                                        <div class="field col-4" style="width:55px;margin-right: 0px;"><p-radioButton formControlName="idMedicallyUnableToWork" value=1 label="Yes"></p-radioButton></div>
                                        <div class="field col-4" style="width:55px;margin-right: 0px;"><p-radioButton formControlName="idMedicallyUnableToWork" value=2 label="No"></p-radioButton></div>
                                        <div class="field col-4" style="width: 120px;margin-right: 0px;"><p-radioButton formControlName="idMedicallyUnableToWork" value=3 label="Don't Know"></p-radioButton></div>
                                    </div>

                                    <div  *ngIf="getIncomeData()?.controls['idMedicallyUnableToWork'].invalid 
                                        && (getIncomeData()?.controls['idMedicallyUnableToWork'].dirty || getIncomeData()?.controls['idMedicallyUnableToWork'].touched)"> 
                                        <div *ngIf="getIncomeData()?.controls['idMedicallyUnableToWork'].errors.required"> 
                                            <small id="idMedicallyUnableToWork-help" class="p-invalid">Medically unable to work question is required.</small>
                                        </div>
                                    </div> 

                                </div>   

                                <div class="field col-12">
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(getIncomeData().controls['idFamilySize'])"></fa-icon>
                                    <span style="vertical-align:text-top"><label htmlFor="idFamilySize">Family Size</label></span>
                                    <div style="width: 5rem;">
                                        <p-inputMask formControlName="idFamilySize" inputId="idFamilySize" mask="9?9" minlength="1" size="2" unmask="true" (onBlur)="onBlurIncome()"></p-inputMask>
                                    </div>
                                    <div *ngIf="getIncomeData()?.controls['idFamilySize'].invalid && (getIncomeData()?.controls['idFamilySize'].dirty || getIncomeData()?.controls['idFamilySize'].touched)">
                                        <div *ngIf="getIncomeData()?.controls['idFamilySize'].errors.required">
                                            <small id="idFamilySize-help" class="p-invalid">Family Size is required.</small>
                                        </div>
                                    </div>
                                </div>                
        
                                <div class="field col-12 formgroup-inline" style="padding:0rem">
                                    <div class="field col-4">
                                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(getIncomeData().controls['idFamilyIncome'])"></fa-icon>
                                        <span style="vertical-align:text-top"><label htmlFor="idFamilyIncome">Family Income</label></span>
                                        <div style="width: 2rem;">
                                            <p-inputMask formControlName="idFamilyIncome" inputId="idFamilyIncome"  [style]="{'width': '65px', 'padding': '0px 0px 0px 7px'}"
                                                mask="$9?99999" minlength="1"  size="8"  unmask="true"
                                                (onBlur)="onBlurIncome()"></p-inputMask>
                                        </div>
                                        <div *ngIf="getIncomeData()?.controls['idFamilyIncome'].invalid && (getIncomeData()?.controls['idFamilyIncome'].dirty || getIncomeData()?.controls['idFamilyIncome'].touched)">
                                            <div *ngIf="getIncomeData()?.controls['idFamilyIncome'].errors.required">
                                                <small id="idFamilyIncome-help" class="p-invalid">Family Income is required.</small>
                                            </div>

                                            <div  *ngIf="getIncomeData()?.controls['idFamilyIncome'].errors"> 
                                                <small id="idFamilyIncome-help" class="p-invalid">{{getIncomeData()?.controls['idFamilyIncome'].errors['invalidIncome']}}</small>
                                            </div> 
                                        </div>
                                    </div>       
                                    <div class="col-7">
                                        <span style="vertical-align:text-top">Federal Poverty Level</span>
                                        <div>
                                            <span style="font-weight: bold; font-size: 10px;"> {{getIncomeData()?.controls['idFPLDescription'].value }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                   
                        <div class="field col-12">
                            <div class="field col-12" >
                                <label for="idLivingWith">Live With  </label>
                                <button pButton pRipple type="button"  label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;" (click)="openHistoryWindow('LIVEW', 'Live With')"  ></button>  
                                <ng-multiselect-dropdown name="idLivingWith" formControlName="idLivingWith"
                                    [settings]="multiCheckDropDownSettings"     
                                    [data]=livingWiths
                                    [(ngModel)]="livingWithSelectedItems" [disabled]="clientService.readonly"
                                >
                                </ng-multiselect-dropdown>
                            </div>   


                            <div class="field col-12" >
                                <label for="idIncomeSource">Income Source </label>
                                <button pButton pRipple type="button"  label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;"  (click)="openHistoryWindow('INCSO', 'Income Source')" ></button>  
                                <ng-multiselect-dropdown name="idIncomeSource" formControlName="idIncomeSource"
                                    [settings]="multiCheckDropDownSettings"
                                    [data]=incomeSources
                                    [(ngModel)]="incomeSourcesSelectedItems"  [disabled]="clientService.readonly"
                                >
                                </ng-multiselect-dropdown>
                            </div>   
    
                            <div class="field col-12" >
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']" *ngIf="validationHelper.hasRequiredField(socio_econ.controls['idPaySource'])"></fa-icon> 
                                <label for="idPaySource">Health Insurance / Payment Source</label>
                                <label id="idPaySourceExpire">
                                    <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientPaySources" [control]="socio_econ.controls['idPaySource']"
                                    [fieldId]="MasterFields.PaymentSource"   [lastUpdated]="this.clientData.clientPaySources[0]?.datetime" (renewValue)="onRenewValue($event)" (isFieldExpired)="onCheckedExpiredField($event)"></app-client-expired-field>
                                </label>                                     
                                <button pButton pRipple type="button"  label="History" class="p-button-text p-button-sm champ_history_button" style="width: 100px;" (click)="openHistoryWindow('PAY', 'Health Insurance / Payment Source')" ></button>  
                                <ng-multiselect-dropdown name="idPaySource" formControlName="idPaySource"
                                    [settings]="multiCheckDropDownSettings" 
                                    [data]=paySources 
                                    [(ngModel)]="paySourcesSelectedItems" [disabled]="clientService.readonly"
                                >
                                </ng-multiselect-dropdown>

                                <div  *ngIf="socio_econ.controls['idPaySource'].invalid && (socio_econ.controls['idPaySource'].dirty || socio_econ.controls['idPaySource'].touched)"> 
                                    <div *ngIf="socio_econ.controls['idPaySource'].errors.required || socio_econ.controls['idPaySource'].errors['idPaySource'] "> 
                                        <small id="idPaySource-help" class="p-invalid">Health Insurance / Payment Source is required.</small>
                                    </div>                                    
                                </div>
                                

                            </div>   
                        </div>
                    </div>
               </div>
            </div>
        </div>
        
        <!-- <div class="field col-12 xl:col-6">
            <div class="card ">
                Form value : {{socio_econ.value | json}}
            </div>
        </div>   -->


    </div>
 
</form>

<app-history ></app-history> 


