export interface ReportsRequestModel {
    reportId: number;
    emaId: number;
    providerId: number;
    clinicId: number;
    userId: number;
    //params: ReportConfigReportParamsModel[];
}

export interface ReportsModel {
    reportId: number;
    reportName: string;
    reportFormat: string;
    parameters: ReportParameterModel[];
}

export interface ReportMenuItem {
    id: number;
    menuName: string;
}


export interface ReportParameterModel {
    name: string;
    value: string;
}

export interface ReportConfigReportsModel {
    reportId: number;
    reportName: string;
    reportNameLong: string;
    reportDesc: string;
    groupId: number | null;
    groupName: string;
    reportFormat: string;
    params: ReportConfigReportParamsModel[];
}

export interface ReportConfigReportGroupsModel {
    groupId: number;
    groupName: string;
    rsFolder: string;
    deleted: boolean;
    groupDesc: string;
    parentId: number | null;
}


export interface ReportConfigReportParamsModel {
    paramId: number;
    reportId: number;
    paramOrder: number;
    paramDataTypeId: number;
    paramDataTypeDesc: string;
    paramControlTypeId: number;
    paramControlTypeDesc: string;
    paramName: string;
    paramLabelText: string;
    paramHelpText: string;
    paramDefaultValue: string;
    paramDefaultHardcodeValue: string;
    paramDefaultSqlValue: string;
    paramSdmTableId: number;
    paramVisible: boolean | null;
    paramHeight: number;
    paramWidth: number;
    paramXCoord: number;
    paramYCoord: number;
    paramAutoGenLabel: boolean | null;
    paramAutoGenLabelWidth: number;
    paramEventName: string;
    paramDependantParamId: number;
    paramRequired: boolean;
    deleted: boolean;
    publishCreateSubDir: boolean;
    publishCreateSubDirOrder: number;
    publishUseParamNameOrKey: number;
    publishUseParamOrder: number;
    publishUseParamFormatMask: string;
    bypassReportParam: boolean;
    paramValues: ReportConfigParamSQLValueModel[];
    defaultValue: string;
    currentValue: string;
}

export interface ReportConfigParamSQLValueModel {
    value: string;
    description: string;
    valueFilter:string;
}

export enum ControlType {
    Value = 1,
    Description,
    HiddenKey,
    HiddenKey2,
    HiddenKey3,
    HiddenKey4
}

export enum ReportParameterControlType {
    Label = 1,
    TextBox,
    ComboBox,
    DateTime,
    CheckBox,
    ComboEditBox
}

export enum ReportParameterDataType
{
    String = 1,
    Number,
    DateTime,
    Boolean,


}

export interface ReportConfigContractDates {
    startDate: string;
    endDate: string;
}


export interface ReportConfigReportListModel {
    reportId: number;
    reportName: string;
    reportNameLong: string;
    reportDesc: string;
    reportFormat: string;
}

export interface ReportConfigGroupListModel {
    groupId: number | null;
    groupName: string;
    reports: ReportConfigReportListModel[];
}