import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ProviderMaintenanceModel, ProviderMaintenancePostResponse} from 'src/app/models/admin.models';

@Injectable({
  providedIn: 'root'
})
export class ProviderMaintenanceService {
  private readonly URL = environment.apiURL + '/admin';

  constructor(private http: HttpClient) { 
  }

  public getProviders () {
    let url = this.URL + '/getProviderList';
    return this.http.get<ProviderMaintenanceModel[]>(url);
  }

  public getProvider (providerId: number) {
    let url = this.URL + '/getProvider';
    let params = new HttpParams();
    params = params.append('providerId', providerId);
    return this.http.get<ProviderMaintenanceModel>(url, { params:params });
  }

  public saveProvider (model: ProviderMaintenanceModel) {
    let url  = this.URL + '/SaveProvider';
    return this.http.post<ProviderMaintenancePostResponse>(url, model);
  }  
}
