<div>
    <p-toast></p-toast>
    <!-- <p-confirmPopup></p-confirmPopup>    -->
</div>
<p-dialog [(visible)]="showDialog" header="RSR Errors" (onHide)="onHide($event)" [modal]="true" [closable]="true" position="center"
          [draggable]="false" showEffect="explode" [style]="{'width': '30%', 'height': '45%'}" [contentStyle]="{'overflow-y': 'clip'}">
    <div class="container-fluid" id="grid"> 
        <p-table #dtRsrReportList [value]="getRsrClientErrors()" [scrollable]="true" scrollDirection="both" scrollHeight="65vh" [tableStyle]="{ 'max-width': '100%' }">
                styleClass="p-datatable-sm p-datatable-striped" >  <!-- [filters]="filters" -->
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="clientAltId">Client Id<p-sortIcon field="clientAltId"></p-sortIcon></th> 
                    <th pSortableColumn="errorDescription">Error Description<p-sortIcon field="errorDescription"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rsr let-editing="editing" let-rowIndex="rowIndex">
                <tr class="cl-row"> 
                    <!-- <td ><a [ngClass]="'clientID'" (click)="openClient(rsr.clientGuid)"> {{rsr?.clientAltId}}</a></td>  -->
                    <td class="text-overflow-ellipsis" [ngStyle]="{'color': (!!rsr?.clientAltId) ? 'blue': 'red'}"> {{ rsr?.clientAltId }} </td>
                    <td class="text-overflow-ellipsis"> {{ rsr?.errorDescription }} </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr>
                    <td colspan="15">Count: {{rsrClientErrors?.length}}</td>
                </tr>
            </ng-template>            
        </p-table>  
    </div> 
</p-dialog>