
<div  *ngIf="(showLoginDialog)">
    <div class="login-body">
        <div class="login-panel p-fluid">
            <div class="p-grid">
                <div class="col-12" style="text-align: right;">
                    <button type="submit" pButton label="cancel" class="p-button-text " style="width:75px" (click)="cancelLogin()"></button>        
                </div>

                <div class="col-12 logo-container">
                    <img src="assets/layout/images/ribbon-6-32.png" alt="logo-dark"  [ngStyle]="{'width':'50px','height':'50px'}"  />
                    <h1>WELCOME TO CHAMP </h1>
                    <h2>Login To Your Account</h2>
                </div>
                <div class="col-12" style="margin-bottom: 10px;">
                    <div class="input-wrapper">
                        <input name="email" [(ngModel)]="loginRequest.email"
                            type="text" autocomplete="off" placeholder="Email Address" pInputText>
                    </div>
                </div>
                <div class="col-12">
                    <div class="input-wrapper">
                        <input name="password" [(ngModel)]="loginRequest.password" type="password" autocomplete="off" placeholder="Password" pInputText>
                    </div>
                </div>
                
                <!-- <div class="col-12 chkbox-container">
                    <div class="field-checkbox">
                        <p-checkbox [binary]="true"></p-checkbox>
                        <label>Remember Me</label>
                    </div>
                </div> -->


                <div class="col-12 button-container">
                    <button type="submit" pButton label="Sign In"   (click)="login()" class="p-button-success p-button-rounded p-button-raised"></button>        
                </div>

                <div class="col-12 error" *ngIf="loginErrorMessage?.length > 0 ">
                    <span style="font-weight: bold; font-size: 12px; color: red;"> {{loginErrorMessage }}</span>
                </div>

                <div class="col-12 error" *ngIf="!emailVerified">
                    <span style="font-weight: bold; font-size: 12px; color: red;">
                        Email has not been verified. 
                        <button pButton pRipple type="button" label="( Resend verification email )" id="signupButton" 
                        class="p-button-help p-button-text  p-button champ_alpha" [ngStyle]="{'width':'20rem'}"   (click)="resendEmailVerification()" ></button> 
                    </span> 
                </div>

                <div class="col-12 error"  *ngIf="emailVerified" >
                    <button pButton pRipple type="button" label="Don't have a login? ( register )"  class="p-button-help p-button-text  p-button"  style="color: blue;"
                       (click)="showSignup()" ></button>  
                </div>

                <div class="col-12 error"  *ngIf="emailVerified" >
                    <button pButton pRipple type="button" label=" Forgot password? (reset)" class="p-button-help p-button-text p-button" style="color: blue;"
                     (click)="showPasswordReset()" ></button>  
                </div>
            </div>
        </div>
    </div>
</div>

<div  *ngIf="(showSignupDialog )" >  
    <div class="login-body" >
        <div class="login-panel fluid">
            <div class="p-grid">
                    <div class="col-12" style="text-align: right;">
                        <button type="submit" pButton label="cancel" class="p-button-text " style="width:75px" (click)="cancelSignup()"></button>        
                    </div>

                    <div class="col-12 logo-container">
                        <img src="assets/layout/images/ribbon-6-32.png" alt="logo-dark"  [ngStyle]="{'width':'50px','height':'50px'}" />
                        <h1  *ngIf="showSignupDialog">Create Sign-in</h1>
                        <h1  *ngIf="showPasswordResetDialog">Reset Password</h1>
                    </div>

                    <div class="col-12">
                        <div class="col-12 logo-container" >
                            <h2 style="color:red">Create a sign in for your CHAMP account. Input your email and password.  Your credentials  will require verification. 
                                An email will be sent to you to verify your account. <u>Your email must be authorized by CHAMP support prior to completing this regsitration.</u></h2> 
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="input-wrapper">
                            <input name="username" [(ngModel)]="signUpRequest.email" style="width: 100%;"
                                type="email" autocomplete="off" placeholder="Email Address" pInputText>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-wrapper">
                            <input name="password" [(ngModel)]="signUpRequest.password" style="width: 100%;"
                                type="password" autocomplete="off" placeholder="Password" pInputText>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="input-wrapper">
                            <input name="passwordConfirm" [(ngModel)]="signUpRequest.passwordConfirm" style="width: 100%;"
                                type="password" autocomplete="off" placeholder="Confirm Password" pInputText>
                        </div>
                    </div>
                
                    <div class="col-12 error button-container"  *ngIf="showSignupDialog">
                        <button type="submit" pButton label="Complete Registration"  class="p-button-success p-button-rounded p-button-raised" (click)="completeSignup()"></button>        
                    </div>

                    <div class="col-12 error" *ngIf="signUpErrorMessage?.length > 0 ">
                        <span style="font-weight: bold; font-size: 12px; color: red;"> {{signUpErrorMessage }}</span>
                    </div>

                    <!-- <div class="col-12">
                        <a>
                            <img src="assets/google_signin_buttons/web/1x/btn_google_signin_dark_normal_web.png"  />
                        </a>
                    </div> -->
            </div>
        </div>
    </div>
</div>


<div  *ngIf="(showPasswordResetDialog)" > 
    <div class="login-body">
        <div class="login-panel fluid">

            <div class="col-12" style="text-align: right;">
                <button type="submit" pButton label="cancel" class="p-button-text " style="width:75px" (click)="cancelReset()"></button>        
            </div>

            <div class="col-12 logo-container">
                <img src="assets/layout/images/ribbon-6-32.png" alt="logo-dark"   [ngStyle]="{'width':'50px','height':'50px'}" />
                <h1 >Reset Password</h1>
            </div>

            <div class="p-grid">
                <div class="col-12 logo-container" >
                    <h2 style="color:red">Enter email, current password, new password and confirmation to reset your password.</h2> 
                </div>
            </div> 

            <div class="col-12">
                <div class="input-wrapper">
                    <input name="username" [(ngModel)]="passwordResetRequest.email"  style="width: 100%;"
                        type="email" autocomplete="off" placeholder="Email Address" pInputText>
                </div>
            </div>
<!-- 
            <div class="col-12">
                <div class="input-wrapper">
                    <input name="password" [(ngModel)]="passwordResetRequest.currentPassword"
                        type="password" autocomplete="off" placeholder="Current Password" pInputText>
                </div>
            </div>


            <div class="col-12">
                <div class="input-wrapper">
                    <input name="password" [(ngModel)]="passwordResetRequest.newPassword"
                        type="password" autocomplete="off" placeholder="New Password" pInputText>
                </div>
            </div>

            <div class="col-12">
                <div class="input-wrapper">
                    <input name="passwordConfirm" [(ngModel)]="passwordResetRequest.newPasswordConfirm"
                        type="password" autocomplete="off" placeholder="Confirm New Password" pInputText>
                </div>
            </div> -->

            <div class="col-12 error button-container"  *ngIf="showPasswordResetDialog">
                <button type="submit" pButton label="Reset Password"  class="p-button-success p-button-rounded p-button-raised" (click)="sendPasswordResetEmail()"></button>        
            </div>


            <div class="col-12 error" *ngIf="resetErrorMessage?.length > 0 ">
                <span style="font-weight: bold; font-size: 12px; color: red;"> {{resetErrorMessage }}</span>
            </div>



        </div>
    </div>
</div>

<!-- </p-dialog> -->

