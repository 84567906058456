import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AdminEmployeesModel, MonthModel, ProviderContractMaintenanceModel, ProviderContractMaintenanceRequestModel, RecalcUnitsModelRequest, RecalcUnitsModelResponse, UnitMaintenanceModelOptions, UnitMaintenanceModelRequest, UnitMaintenanceModelResponse, UnitMaintenanceModelUpdateRequest, UnitMaintenanceServicesModel } from 'src/app/models/admin.models';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class UnitMaintenanceService {

    // URL which returns list of JSON items (API end-point URL)
    private readonly URL = environment.apiURL + '/admin';

    constructor(private http: HttpClient) { }

    public getFundingYears () 
    {
      let url  = this.URL + '/getFundingYears';
      return this.http.get<string[]>(url);
    }

    public getMonths () 
    {
      let url  = this.URL + '/getMonths';
      return this.http.get<MonthModel[]>(url);
    }

    
    public getUnitMaintenanceUnits (model: Partial<UnitMaintenanceModelRequest>) {
      let url = this.URL + '/getUnitMaintenanceUnits';
      return this.http.post<UnitMaintenanceModelResponse>(url, model);
    }

    public saveUnitMaintenanceUnits (model: Partial<UnitMaintenanceModelUpdateRequest>) {
      let url = this.URL + '/saveUnitMaintenanceUnits';
      return this.http.post<UnitMaintenanceModelResponse>(url, model);
    }

    public recalcUnits (request: RecalcUnitsModelRequest) {
      let url = this.URL + '/recalcUnits';
      return this.http.post<RecalcUnitsModelResponse>(url, request);
    }

    public getEmployeesFromProvider (providerId) {
      let params = new HttpParams();
      params = params.append('providerId', providerId);
      return this.http.get<AdminEmployeesModel[]>(this.URL + '/getEmployeesFromProvider/', { params:params });

    }



}
