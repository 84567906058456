<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]='formNonRWVisit' autocomplete="off">
        <div class="card champ-card" >
            <div class="card-body champ-card-body">             
                <div class="p-fluid p-formgrid grid" style="padding-bottom: 5px;">

                        <!-- Visit Date -->
                        <div class="field col-12">
                            <div class="formgroup-inline">
                                <div class="col-3 text-end" >
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                    <span style="vertical-align:text-top"><label htmlFor="visitDate" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Visit Date</label></span>
                                </div>
                                <div class="col-5">
                                    <input type="text" name="visitDate" autocomplete="off" bsDatepicker formControlName="visitDate"
                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="visitDate"    
                                        placeholder="Visit Date"
                                        [ngClass]="{'p-invalid' : formNonRWVisit.controls['visitDate'].invalid}"
                                        class="champ-date-picker" pInputText style="z-index: 1000 !important;"/>
                                </div>
                                <div class="col-4">
                                        <div *ngIf="getVisitDate().invalid && (getVisitDate().dirty || getVisitDate().touched)">
                                            <div *ngIf="getVisitDate().errors.required">
                                                <small id="dsDeliveredDate-help" class="p-invalid">*Visit Date is required.</small>
                                            </div>
                                            <div *ngIf="getVisitDate().errors"> 
                                                <small id="dsDeliveredDate-help" class="p-invalid">{{getVisitDate().errors['invalidDate']}}</small>
                                            </div>                             
                                        </div>
                                </div>
                            </div>

                        </div>

                        <!-- Location -->
                        <div class="field col-12">
                            <div class="formgroup-inline">
                                <div class="col-3 text-end">
                                    <label for="location">Location</label>
                                </div>
                                <div class="col-5">
                                    <input type="text" formControlName="location" [maxLength]="100"  pInputText  style="height:31px;">
                                </div>
                                <div class=" col-4">
                                    <div *ngIf="!!getLocation().errors && !!getLocation().errors['invalidRequiredField']"> 
                                        <small id="locationhelp" class="p-invalid">*Location in required.</small>
                                    </div>   
                                </div>

                            </div>

                        </div>

                        <!-- Service Cat -->
                        <div class="field col-12">
                            <div class="formgroup-inline">
                                <div class="col-3 text-end" >
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                    <span style="vertical-align:text-top"><label for="serviceId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Service Category</label></span>
                                </div>
                                <div class="col-5">
                                    <p-dropdown formControlName="serviceId" [options]="serviceCategories" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeServiceType($event.value)"> </p-dropdown>
                                    
                                </div>
                                <div class=" col-4">
                                    <div *ngIf="!!getServiceID().errors && !!getServiceID().errors['invalidRequiredField']"> 
                                        <small id="serviceId-help" class="p-invalid">*Service Category is required.</small>
                                    </div>     
                                </div>
                            </div>

                        </div>

                        <!-- Sub Type -->
                        <div class="field col-12">
                            <div class="formgroup-inline">
                                <div class="col-3 text-end" >
                                    <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                    <span style="vertical-align:text-top"><label for="serviceId" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Sub Type</label></span>
                                </div>
                                <div class="col-5">
                                    <p-dropdown  formControlName="subId" [options]="subTypes" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeSubType($event.value)"> 
                                        <ng-template let-subType pTemplate="item">
                                            <div style="width: 125px;">
                                                <div  style="font-weight: bold;">{{subType.description}}</div> 
                                                <div  style="font-size: smaller;font-weight: 300;"><span title="{{subType.longDescription}}">{{subType.longDescription}}</span></div>  
                                            </div>
                                        </ng-template>
                                    </p-dropdown>
                                    
                                </div>
                                <div class=" col-4">
                                    <div *ngIf="!!getSubID().errors && !!getSubID().errors['invalidRequiredField']"> 
                                        <small id="subId-help" class="p-invalid">*Sub Type is required.</small>
                                    </div>                 
                                </div>
                            </div>

                        </div>

                        <!-- Intervention -->
                        <div class="field col-12">
                            <div class="formgroup-inline">
                                <div class="field col-3 text-end">
                                    <label for="cohortIntervention">Intervention</label>
                                </div>
                                <div class="field col-5">
                                    <div class="formgroup-inline">
                                        <div class="field-radiobutton">
                                            <p-radioButton name="cohortIntervention" value=1 label="Yes" formControlName="cohortIntervention" (click)="onChangeInterventionOption(1)"></p-radioButton>
                                        </div>
                                        <div class="field-radiobutton">
                                            <p-radioButton name="cohortIntervention" value=0 label="No" formControlName="cohortIntervention" (click)="onChangeInterventionOption(0)"></p-radioButton>
                                        </div>
                                    </div>
                                </div>
                                <div class=" col-4">
                                </div>
                            </div>
                        </div>            

                        <!-- Intervention -->
                        <div class="field col-12">
                            <div class="formgroup-inline">
                                <div class=" col-3 text-end">
                                    <label for="notes" style="margin-top: 0.125rem; margin-bottom: 0.5rem;">Notes</label>
                                </div>
                                <div class="col-5">
                                    <div class="formgroup-inline">
                                        <textarea formControlName="notes" id="notes" rows="5" cols="25" pInputTextarea style="height:80px;"></textarea>
                                    </div>
                                </div>
                                <div class="col-4">
                                </div>
                            </div>
                        </div>            
<!-- 
                        <div class="field col-12">
                            <div class="card champ-card champ-card-bottom">
                                Form status : {{formNonRWVisit.status | json}}
                                Form value : {{formNonRWVisit.value | json}}
                            </div>
                        </div>  
                         -->

                         <div class="field col-12">

                            <button pButton pRipple class="btn btn-primary" (click)="onSaveChangesNoConfirm($event)" icon="pi pi-check"
                                *ngIf="!clientService.readonly" [disabled]="!formNonRWVisit.dirty || !formNonRWVisit.valid" 
                                label="Save"  style="width:130px;float: right;"></button>  
    

                                <button pButton pRipple label="Cancel" icon="pi pi-times" class="btn btn-primary"  (click)="onHideDialog($event)" style="width:130px;float: right;margin-right:10px;"></button>       


                                <!-- <button  (click)="onHideDialog($event)"></button>        -->


                                <!-- <button [disabled]="!isEligibilityValid()" pButton pRipple class="btn btn-primary" (click)="onContinue($event)" icon="pi pi-check" label="Continue" style="float: right"></button> -->
                        </div>
                    <!-- </div> -->
                </div>
            </div>
        </div>
</form> 




