import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalCommon, ClientMedicalSyphilisModel } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields, PosNegResults } from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-syphilis',
  templateUrl: './syphilis.component.html',
  styleUrls: ['./syphilis.component.scss']
})
export class SyphilisComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  YesnoMedical: CommonData[];
  noReasons: CommonData[];
  MedicalYesNo = MedicalYesNo;
  PosNegResults = PosNegResults;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();

  getSyphilisScreenedControl = () => this.medical?.get('idSyphScreened');
  getSyphilisScreenedNoReasonControl = () => this.medical?.get('idSyphScreenedNoReason');
  getSyphilisResultDateControl = () => this.medical?.get('idSyphScreenedDate');
  getSyphilisLastResultControl = () => this.medical?.get('idSyphPositive');
  getSyphilisLastestResultUnavailableControl = () => this.medical?.get('idSyphUnavailable');
  getSyphilisTreatedControl = () => this.medical?.get('idSyphTreated');

  showSyphilisNoReason = () => (this.getSyphilisScreenedControl()?.value ?? 0) == this.MedicalYesNo.No; // this.providerService.isFieldRequired(MasterFields.SyphilisScreenedNoReason) --Den wanted this type of lookup removed 1/6/22
  showSyphilisResultFields = () => (this.getSyphilisScreenedControl()?.value ?? 0) == this.MedicalYesNo.Yes;
  showSyphilisUnavailable = () => { 
                                    // the order of the logic must be preserved for accuracy
                                    if (this.clientData?.clientMedicalSyphilis == null) return true; // if no rows in table for this client then allow Unavailable to show
                                    if (this.clientData?.clientMedicalSyphilis?.positive) return false;
                                    if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalSyphilis?.lastUpdate, true), (this.app.requiredFields30DayGracePeriodReset * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again    
                                    if (!this.clientData?.clientMedicalSyphilis?.unavailable) return false;
                                    if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalSyphilis?.lastUpdate, true), this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
                                    return false;                                    
                                  }
  isSyphilisUnavailable = () => (this.getSyphilisLastestResultUnavailableControl()?.value ?? false);

  constructor(private fb: UntypedFormBuilder, 
              private commonDataService: CommonDataService,
              public providerService: ProviderService,
              private userService: UserService,
              private clientService: ClientFormService,
              private datePipe: DatePipe,
              private dateHelper: DateHelper,
              public  validationHelper: FormValidations,
              private helperService: HelperClassService,
              private medcommon: MedicalCommon,
              private app: AppContextService) { 
  }

  ngOnInit(): void {
    this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
    this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate);
    this.YesnoMedical = this.commonDataService.getCommonSetByName("YesnoMedical", true);
    this.noReasons =  this.YesnoMedical.filter(x => x.id == 0 || x.id > 2);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
                         });
     }

     if (!this.clientService.readonly) {
      this.validationHelper.AddRequiredMinMaxValidator(this.getSyphilisScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.SyphilisScreened))
      this.getSyphilisScreenedControl().valueChanges.subscribe(value => { this.setScreened();  });
      this.getSyphilisLastestResultUnavailableControl().valueChanges.subscribe(value => { this.setUnavailable(value); });    
     }
  }

  refreshAfterSave() {
    this.setFieldValues();
  }  

  setFieldValues() {
    if (!this.clientData) return;

    if (this.clientData.clientMedicalSyphilis?.positive == PosNegResults.Positive) this.setUnavailable(true);
    
    if (!this.helperService.isExpired(this.clientData?.clientMedicalSyphilis?.expired)) {
      if (this.clientData?.clientMedicalSyphilis?.unavailable != null)
        this.getSyphilisLastestResultUnavailableControl().setValue(this.clientData.clientMedicalSyphilis?.unavailable);

      if (this.clientData?.clientMedicalSyphilis?.screened != null)
        this.getSyphilisScreenedControl().setValue(this.clientData.clientMedicalSyphilis?.screened);

      if (this.clientData?.clientMedicalSyphilis?.noReason != null)
        this.getSyphilisScreenedNoReasonControl().setValue(this.clientData.clientMedicalSyphilis?.noReason);

      if (this.clientData?.clientMedicalSyphilis?.datetime != null)
        this.getSyphilisResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalSyphilis?.datetime));      
      
      if (this.clientData?.clientMedicalSyphilis?.positive != null)
        this.getSyphilisLastResultControl().setValue(this.clientData.clientMedicalSyphilis?.positive);

      if (this.clientData?.clientMedicalSyphilis?.treated != null)
        this.getSyphilisTreatedControl().setValue(this.clientData.clientMedicalSyphilis?.treated);
    }
  }

  setUnavailable(unavailable) {
    //let unavailable = this.medical.get('idHepBUnavailable').value;

    if (unavailable) {
      this.validationHelper.RemoveValidators(this.getSyphilisScreenedControl(), true);
      this.validationHelper.RemoveValidators(this.getSyphilisScreenedNoReasonControl(), true);
      this.validationHelper.RemoveValidators(this.getSyphilisResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getSyphilisLastResultControl(), true);
      this.validationHelper.RemoveValidators(this.getSyphilisTreatedControl(), true);
    }
    else 
    {
      this.validationHelper.AddRequiredMinMaxValidator(this.getSyphilisScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.SyphilisScreened))
      this.setScreened();
    }
  }

  setScreened() {
    if (this.getSyphilisScreenedControl()?.value == this.MedicalYesNo.Yes) {
      this.validationHelper.RemoveValidators(this.getSyphilisScreenedNoReasonControl(), true);
      this.validationHelper.AddValidators(this.getSyphilisResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]); // if (this.providerService.isFieldRequired(MasterFields.SyphilisResultDate))
      this.validationHelper.AddRequiredMinMaxValidator(this.getSyphilisLastResultControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.SyphilisLastResult))
      //this.validationHelper.AddValidators(this.getSyphilisTreatedControl());
    }
    else if (this.getSyphilisScreenedControl()?.value == this.MedicalYesNo.No) {
      //new Array(Validators.required, this.validationHelper.dropdownValidator()) or [Validators.required, this.validationHelper.dropdownValidator()]
      this.validationHelper.AddValidators(this.getSyphilisScreenedNoReasonControl(), this.validationHelper.dropdownValidator()); // if (this.providerService.isFieldRequired(MasterFields.SyphilisScreenedNoReason))
      this.validationHelper.RemoveValidators(this.getSyphilisResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getSyphilisLastResultControl(), true);
      //this.validationHelper.RemoveValidators(this.getSyphilisTreatedControl(), true);
      this.getSyphilisResultDateControl().setValue(null);
    }
  }

	getModel(): Partial<ClientMedicalSyphilisModel> {
	  var model: Partial<ClientMedicalSyphilisModel> = new ClientMedicalCommon(this.userService.userID); //this.medical.value

    if (this.getSyphilisScreenedControl().dirty || this.getSyphilisLastestResultUnavailableControl().dirty || this.getSyphilisScreenedNoReasonControl().dirty ||
        this.getSyphilisResultDateControl().dirty || this.getSyphilisLastResultControl().dirty || this.getSyphilisTreatedControl().dirty)
    {
      let date_screened = (this.isSyphilisUnavailable() || !this.getSyphilisResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getSyphilisResultDateControl().value, { representation: 'date' });

      model.unavailable = this.getSyphilisLastestResultUnavailableControl()?.value ?? false;      
      model.screened = this.getSyphilisScreenedControl()?.value;
      model.datetime = date_screened;
      model.positive = this.getSyphilisLastResultControl()?.value ?? 0;
      model.noReason = this.getSyphilisScreenedNoReasonControl()?.value ?? 0;
      model.treated = this.getSyphilisTreatedControl()?.value ?? false;
      // moved the below into base class ClientMedicalCommon
      // model.guid = ClientFormHelper.EmptyGuid();
      // model.deleted = false;
      // model.userId = this.userService.userID;
      // model.lastUpdate = ClientFormHelper.nullDate();
      // model.expired = null;

      return model;
    } 

    return null;
	}  

  openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
  }  

}
