<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>

<form [formGroup]='referForm' autocomplete="off">

    <div class="card champ-card-sm" > 
        <div class=" champ-card-header-sm">
            <div class="p-fluid p-formgrid grid">
                <div class="field col-7">Referrals</div>
                <div class="field col-4">
 
                </div>
                <div class="field col-1">
                    <!-- <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" ></button>  -->
                        <!-- (click)="openHistoryWindow('HEPB', 'Hepatitis B')" -->   
                </div>
            </div>
        </div>
    
        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col">
                    <ng-template #loading>
                        <div class="champ-notification-loading">Loading Referrals...</div>
                    </ng-template>                
                </div>
            </div>
            
            <div class=" pb-3">
                <div class="field grid pt-0">
                    <div class="flex col-10" >
                        <div class=" field ml-0" style="margin-top: 10px ;">
                            <div class="formgroup-inline" style="height: 25px;">
                                <label for="filterOption" class="mr-2">Filter - Date Options:</label>
                                <div class="field-radiobutton"> <!-- (click)="onChangeFilterOption(1)"  (click)="onChangeFilterOption(2)"-->
                                    <p-radioButton [style]="{'margin-top': '0.25rem','align-items': 'unset'}" name="filterOption" value=1 label="Standard Filters" formControlName="filterOption" (click)="onChangeFilterOption(1)"></p-radioButton>
                                </div>
                                <div class="field-radiobutton">
                                    <p-radioButton [style]="{'margin-top': '0.25rem','align-items': 'unset'}" name="filterOption" value=2 label="Custom Filters" formControlName="filterOption" (click)="onChangeFilterOption(2)"></p-radioButton>
                                </div>
                            </div>
                        </div>

                        <div class="field ml-4" style="min-width: 10rem;"*ngIf="usingCustomDSListFiltering()">
                            <label for="dsMonth">Month</label>
                            <p-dropdown formControlName="month" [options]="months" optionLabel="name" optionValue="code" appendTo="body" (onChange)="onChangeMonth($event.value)"> </p-dropdown>
                        </div>

                        <div class="field ml-4 " style="min-width: 10rem;" *ngIf="usingCustomDSListFiltering()">
                            <label for="dsYear">Year</label>
                            <p-dropdown formControlName="year" [options]="years" appendTo="body" (onChange)="onChangeYear($event.value)"> </p-dropdown>
                        </div>
                        
                        <div class="field ml-4" style="min-width: 15rem;" *ngIf="!usingCustomDSListFiltering()">
                            <label for="dateFilterSelection">Please select a time period</label>
                            <p-dropdown formControlName="dateFilterSelection" [options]="standardFilterSelections" optionLabel="name" optionValue="code" appendTo="body" (onChange)="onChangeFilterSelection($event.value)"> </p-dropdown>
                        </div>        

                        <div class="flex align-items-center pb-0 ml-5" *ngIf="!clientService.readonly">
                            <p-button  pButton pRipple type="button"   styleClass="p-button-text " class="p-button-text mr-1 mt-1 w-15rem"
                                [disabled]="!(this.app.userServ?.hasAdminPermission || this.app.userServ?.hasEditPermissions)"
                                (click)="showAddReferralDialog()"   > 
                                <i class="fa-sharp fa-plus fa-lg mr-2" ></i> <span>Add Referral</span>
                            </p-button>        
                        </div>

                    </div>
                    <div class="flex col-2" >
                        @if( this.app.userServ?.hasAdminPermission)
                        {
                           <button pButton pRipple type="button" label={{deletedLabel}} id="deletedButton"  class="p-button-text   p-button" style="font-weight: bold; border-style: none;"  (click)="showDeletedClicked()"></button>    
                        }
                    </div>
                </div>
            </div>
     
           <p-scrollPanel [style]="{width: '99.2%', padding: '0', margin: '.5rem'}">
                <ng-container *ngIf="(referralServices$ | async) as referralData else loading"> 
                    <!-- let-product let-editing="editing" let-rowIndex="rowIndex" -->
                    <!-- [autoLayout]="true" [resizableColumns]="true" scrollHeight="100%" scrollWidth="100%" [rows]="10" [style]="{'width':'99.75%'}" -->
                    <p-table [value]="referralData.referrals"
                            [rowHover]="true"
                            [style]="{'width':'99.9%'}" 
                            styleClass="p-datatable-sm p-datatable-striped"
                            name="tblReferrlList"
                            [showCurrentPageReport]="false"
                            [paginator]="false"
                            currentPageReportTemplate="{totalRecords} entries"
                           
                            >
                    <ng-template pTemplate="header">
                        <tr>
                            <!-- <th style="min-width:65px;max-width:65px;text-align:left"></th> -->
                            <th style="min-width:5rem;"></th>
                            <th  style="min-width:4rem;max-width:4rem;" pSortableColumn="datetime"  >Referral Date</th>
                            
                            <th pSortableColumn="referralStatusDesc" >Status<p-sortIcon field="referralStatusDesc"></p-sortIcon></th>
                            <th pSortableColumn="serviceDesc" >Service Category<p-sortIcon field="serviceDesc"></p-sortIcon></th>
                            <th pSortableColumn="subTypeDesc" >Sub Category<p-sortIcon field="subTypeDesc"></p-sortIcon></th>
                            <th pSortableColumn="funderName" >Funder Name<p-sortIcon field="funderName"></p-sortIcon></th>
                            <th pSortableColumn="employeeName" >Employee Name<p-sortIcon field="employeeName"></p-sortIcon></th>

                            <th pSortableColumn="destinationProvider" >Provider/Clinic Name<p-sortIcon field="destinationProvider"></p-sortIcon></th>
                            <!-- <th pSortableColumn="outsideProviderId" style="min-width:160px;max-width:160px;text-align:left">Outside Provider Name<p-sortIcon field="outsideProviderId"></p-sortIcon></th> -->
                            <th pSortableColumn="confirmLocation" >Confirm Location<p-sortIcon field="confirmLocation"></p-sortIcon></th>
                            <th pSortableColumn="memo" >Memo<p-sortIcon field="memo"></p-sortIcon></th>
                            <th pSortableColumn="completedDate" >Date Completed</th>
                            <th pSortableColumn="completedNotes" >Completed Notes</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" ngFor let-referral let-editing="editing" let-rowIndex="rowIndex" >
                        <tr>
                            <td style="min-width:5rem;" >

                                <button title="Edit" *ngIf="!clientService.readonly" pButton pRipple class="p-button p-button-text m-1 mr-2" (click)="onOpenReferral(referral)"  style="width:2rem">
                                    <i class="fa-sharp fa-light fa-pencil " style="color: green;"></i>
                                </button> 
                                @if( this.app.userServ?.hasAdminPermission && !referral.deleted)
                                { 
                                    <button title="Delete" pButton pRipple  class="p-button p-button-text p-button-danger m-1" (click)="onDeleteReferral($event, referral)" style="width:2rem">
                                        <i class="fa-sharp fa-regular fa-trash  " style="color: red;" ></i>
                                    </button>
                                 }
                                 @if( this.app.userServ?.hasAdminPermission && referral.deleted)
                                 {
                                     <button title="Undelete"  pButton pRipple  class="p-button p-button-text m-1 mr-2"  style="width:2rem" (click)="onUndeleteReferral($event, referral)"   >
                                         <i class="fa-sharp fa-regular fa-trash-undo" style="color: darkgoldenrod;" ></i>
                                    </button>  
                                 }
                                 
                            </td>
                            <td  style="min-width:5rem;max-width:5rem;"  class="champ-datatable-date text-right">{{referral.datetime | date:'shortDate'}}</td>
                            
                            <td style="min-width:70px;max-width:70px;" ><span title="{{referral.referralStatusDesc}}">{{referral.referralStatusDesc}}</span></td>
                            <td style="min-width:70px;max-width:200px;" ><span title="{{referral.serviceDesc}}">{{referral.serviceDesc}}</span></td>
                            <td style="min-width:70px;max-width:70px;" ><span title="{{referral.subTypeDesc}}">{{referral.subTypeDesc}}</span></td>
                            <td style="min-width:100px;max-width:100px;" ><span title="{{referral.funderName}}">{{referral.funderName}}</span></td>
                            <td style="min-width:70px;max-width:70px;" ><span title="{{referral.employeeName}}">{{referral.employeeName}}</span></td>
                            <td style="min-width:70px;max-width:70px;" ><span title="{{referral.destinationProvider}}">{{referral.destinationProvider}}</span></td>
                            <td style="min-width:70px;max-width:70px;" ><span title="{{referral.confirmLocation}}">{{referral.confirmLocation}}</span></td>
                            <td style="min-width:70px;max-width:70px;" ><span title="{{referral.memo}}">{{referral.memo}}</span></td>
                            <td style="min-width:50px;max-width:50px;" class="champ-datatable-date">{{referral.completedDate | date:'shortDate'}}</td>
                            <td style="min-width:70px;max-width:70px;" ><span title="{{referral.completedNotes}}">{{referral.completedNotes}}</span></td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="footer">
                        <tr>
                            <td colspan="4">Count: {{referrals?.length}}</td>
                        </tr>
                    </ng-template>   
                    <!--
                    <ng-template pTemplate="summary">
                        <div class="p-d-flex p-ai-center p-jc-between">
                            In total there are {{products ? products?.length : 0 }} products.
                        </div>
                    </ng-template>
                    -->
                    </p-table>

                </ng-container>
            </p-scrollPanel>    

            </div>
    </div>






</form>


<app-referral-edit (onRefresh)="onRefreshReferralList()"></app-referral-edit>

