<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                <div class="field col-7">Viral Load</div>

                <div class="field col-4">
                    <div [ngClass]="{'disableDiv': !showViralLoadUnavailable()}">                        
                        <p-checkbox value="false" inputId="unavailable" formControlName="idVLUnavailable" [binary]="true"></p-checkbox>
                        <label style="font-size: 0.875rem; font-weight: normal;" for="unavailable">Unavailable</label>
                    </div>
                </div>

                <div class="field col-1">
                    <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('VL', 'Viral Load')"></button>      
                </div>
            </div>
        </div>

		<div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid" *ngIf="!isViralLoadUnavailable()">
                <div class="field col-12">
                    <div class="formgroup-inline chamformgroup-inline" style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px; line-height: 20px;">
                        <label style="font-weight:normal; margin-bottom: 10px; margin-right: 10px;">Result of last Viral Load on medical record?
                            <label id="idViralLoadResultDate">
                                <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalViral" [formGroup]="medical"
                                   [fieldId]="MasterFields.ViralLoadResultDate" [lastUpdated]="this.clientData.clientMedicalViral?.datetime"></app-client-expired-field>
                            </label>                              
                        </label>
                    </div>
                </div>

                <div class="field col-12">
                    <div class="fluid formgrid grid">
                        <div class="field col-4">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idVLDate" style="margin-right: 1rem;">Date of Result</label> 
                                <input type="text" name="idVLDate" autocomplete="off" bsDatepicker formControlName="idVLDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idVLDate" [maxDate]="maxDate" [minDate]="minDate"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idVLDate'].invalid}" 
                                    class="champ-date-picker" pInputText/>
                            </div>
                            <div *ngIf="medical.controls['idVLDate'].invalid"> 
                                <div *ngIf="medical.controls['idVLDate']?.errors.required"> 
                                    <small id="idVLDate-help" class="p-invalid">Result Date is required.</small>
                                </div>
                                <div *ngIf="medical.controls['idVLDate']?.errors">
                                    <small id="idVLDate-help" class="p-invalid">{{medical.controls['idVLDate']?.errors['invalidDate']}}</small>
                                </div>
                            </div>
                        </div>

                        <div class="field col-4" style="padding-left: 1rem;">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idVLCount" style="margin-right: 1rem;">Viral Load</label>
                                <div [ngClass]="{'disableDiv': isViralLoadUndetectable()}" style="width: 5rem;">
                                    <p-inputMask formControlName="idVLCount" inputId="idVLCount" mask="9?99999" maxlength="6"></p-inputMask>
                                </div>
                            </div>
                            <div *ngIf="medical.controls['idVLCount'].invalid"> 
                                <div *ngIf="medical.controls['idVLCount'].errors.required"> 
                                    <small id="idVLCount-help" class="p-invalid">Viral Load is required.</small>
                                </div>
                                <div *ngIf="!medical.controls['idVLCount'].errors.required && !!medical.controls['idVLCount'].errors"> 
                                    <small id="idVLCount-help" class="p-invalid">Viral Load must be greater than zero.</small>
                                </div>                                
                            </div>
                        </div>

                        <div class="field col-4">
                            <div class="formgroup-inline chamformgroup-inline">
                                <p-checkbox value="false" inputId="unavailable" formControlName="idVLUndetectable" [binary]="true"></p-checkbox>
                                <label htmlFor="idVLUndetectable" style="width:120px;">Undetectable &nbsp;&nbsp; VL &lt;</label>
                                <div [ngClass]="{'disableDiv': !isViralLoadUndetectable()}" style="padding-left: 1rem;width: 4rem;">
                                    <p-inputMask formControlName="idVLUndetectableRange" inputId="idVLUndetectableRange" mask="9?99" maxlength="3"></p-inputMask>
                                </div>
                                <label htmlFor="idVLUndetectable" style="padding-left: 1rem;width:75px;">(20 - 200)</label>
                            </div>
                            <div *ngIf="medical.controls['idVLUndetectableRange'].errors"> 
                                <small id="idVLUndetectableRange-help" class="p-invalid">Invalid Undetectable range.</small>
                            </div>                            
                        </div>

                    </div>
                </div>

                <!-- <div class="field col-12">
                    <div class="fluid formgrid grid">
                        <div class="formgroup-inline chamformgroup-inline" style="padding-left: 1rem;">
                            <p-checkbox value="false" inputId="unavailable" formControlName="idVLUndetectable" [binary]="true"></p-checkbox>
                            <label htmlFor="idVLUndetectable" style="width:120px;">Undetectable &nbsp;&nbsp; VL &lt;</label>
                            <div [ngClass]="{'disableDiv': !isViralLoadUndetectable()}" style="padding-left: 1rem;width: 4rem;">
                                <p-inputMask formControlName="idVLUndetectableRange" inputId="idVLUndetectableRange" mask="9?99" maxlength="3"></p-inputMask>
                            </div>
                            <label htmlFor="idVLUndetectable" style="padding-left: 1rem;width:75px;">(20 - 200)</label>
                        </div>
                    </div>
                    <div class="field col-2"></div>
                    <div class="field col-10">
                        <div *ngIf="medical.controls['idVLUndetectableRange'].errors"> 
                            <small id="idVLUndetectableRange-help" class="p-invalid">Invalid Undetectable range.</small>
                        </div>
                    </div>
                </div> -->
            </div>            

            <div class="fluid formgrid grid" *ngIf="isViralLoadUnavailable()">
                <small id="idVLUnavailable-help">Viral Load was marked unavailable. Screening will be required in 30 days.</small>
            </div>
        </div>        
    </div>
</form>

<app-history ></app-history>
