<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='mainForm' autocomplete="off"> <!-- (onShow)="onShowDialog()" (onHide)="onHideDialog()"  -->
    <p-dialog [(visible)]="displayRiskReductionDialog" id="RiskReductionModal" [closable]="!forceSave"
            header="Counselings and Included Screenings"
            [modal]="true" position="center" [resizable]="false" [draggable]="false" showEffect="explode"
            [style]="{width: '675px', minWidth: '675px'}">
        <ng-template pTemplate="content">
            <div class="card champ-card">
                <div class="card-body champ-card-body">            
                    <div style="width: 95%; font-weight: normal; line-height: 1.25; padding-top: .25rem; padding-left: .75rem; padding-bottom: .25rem;">
                        For any of the questions below, please enter the date of the last known screening. Date of screening must be
                        within the last 6 months  of this visit (3 months for treatment adherence) .
                    </div>
                    <div class="fluid formgrid grid" style="width: 610px;margin-right: 0;margin-left: 0;" [ngClass]="{'disableDiv': readOnly}">  <!-- Test   -->
                        <p-accordion multiple="true">
                            <p-accordionTab *ngIf="tobaccoPanelVisible" header="Tobacco Cessation Counseling" [selected]="tobaccoPanelVisible"> <!-- [disabled]="!tobaccoPanelEnabled" style="width: 680px;height: 80px;"-->
                                <div class="formgroup-inline chamformgroup-inline">
                                    <div class="field col-7">
                                        <label for="tobaccoUse">Is this patient using Tobacco products?</label> <!--style="margin-right: 1rem; margin-left: 1rem; margin-bottom: 1rem;" -->
                                        <div *ngIf="!!mainForm.errors?.invalidTobaccoUse">
                                            <small id="tobaccoUse-help" class="p-invalid">{{mainForm.errors?.invalidTobaccoUse}}</small>
                                        </div>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !tobaccoPanelEnabled}">
                                        <p-radioButton formControlName="tobaccoUse" value=0 label="No" (click)="onChangeTobaccoYesNo(0)"></p-radioButton>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !tobaccoPanelEnabled}">
                                        <p-radioButton formControlName="tobaccoUse" value=1 label="Yes" (click)="onChangeTobaccoYesNo(1)"></p-radioButton>
                                    </div>
                                    <div class="field p-col">
                                    </div>
                                </div>
                                <div class="formgroup-inline chamformgroup-inline" *ngIf="getTobaccoYesNoValue() == 1">
                                    <div class="field col-7">
                                        <label for="tobaccoCounseling">Did the patient receive Tobacco Cessation Counseling?</label>
                                        <div *ngIf="!!mainForm.errors?.invalidTobaccoCounseling">
                                            <small id="tobaccoCounseling-help" class="p-invalid">{{mainForm.errors?.invalidTobaccoCounseling}}</small>
                                        </div>
                                        <div *ngIf="mainForm.controls['tobaccoCounselingDate'].errors"> 
                                            <small id="tobaccoCounselingDate-help" class="p-invalid">{{mainForm.controls['tobaccoCounselingDate'].errors['invalidDate']}}</small>
                                        </div>                                
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !tobaccoPanelEnabled}">
                                        <p-radioButton formControlName="tobaccoCounseling" value=0 label="No" (click)="onChangeTobaccoCounselingYesNo(0)"></p-radioButton>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !tobaccoPanelEnabled}">
                                        <p-radioButton formControlName="tobaccoCounseling" value=1 label="Yes" (click)="onChangeTobaccoCounselingYesNo(1)"></p-radioButton>
                                    </div>
                                    <div class="field p-col" *ngIf="getTobaccoCounselingYesNoValue() == 1" [ngClass]="{'disableDiv': !tobaccoPanelEnabled}">
                                        <input type="text" formControlName="tobaccoCounselingDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"    
                                        placeholder="Date"
                                        class="champ-date-picker" pInputText/>
                                    </div>
                                </div>
                            </p-accordionTab>
                            <p-accordionTab *ngIf="riskReductionPanelVisible" header="Risk Reduction" [selected]="riskReductionPanelVisible">
                                <div class="formgroup-inline chamformgroup-inline">
                                    <div class="field col-7">
                                        <label for="riskReduction">Did the patient receive Risk Reduction Counseling?</label>
                                        <div *ngIf="!!mainForm.errors?.invalidRiskReduction">
                                            <small id="riskReduction-help" class="p-invalid">{{mainForm.errors?.invalidRiskReduction}}</small>
                                        </div>
                                        <div *ngIf="mainForm.controls['riskReductionDate'].errors">
                                            <small id="riskReductionDate-help" class="p-invalid">{{mainForm.controls['riskReductionDate'].errors['invalidDate']}}</small>
                                        </div>                                                           
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !riskReductionPanelEnabled}">
                                        <p-radioButton formControlName="riskReduction" value=0 label="No" (click)="onChangeRiskReductionYesNo(0)"></p-radioButton>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !riskReductionPanelEnabled}">
                                        <p-radioButton formControlName="riskReduction" value=1 label="Yes" (click)="onChangeRiskReductionYesNo(1)"></p-radioButton>
                                    </div>
                                    <div class="field p-col" *ngIf="getRiskReductionYesNoValue() == 1" [ngClass]="{'disableDiv': !riskReductionPanelEnabled}">
                                        <input type="text" formControlName="riskReductionDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"    
                                        placeholder="Date"
                                        class="champ-date-picker" pInputText/>
                                    </div>
                                </div>
                            </p-accordionTab>
                            <p-accordionTab *ngIf="treatmentAdherencePanelVisible" header="Treatement Adherence" [selected]="treatmentAdherencePanelVisible">
                                <div class="formgroup-inline chamformgroup-inline">
                                    <div class="field col-7">
                                        <label for="treatmentAdherence">Did the patient receive Treatement Adherence Counseling?</label>
                                        <div *ngIf="!!mainForm.errors?.invalidTreatmentAdherence">
                                            <small id="treatmentAdherence-help" class="p-invalid">{{mainForm.errors?.invalidTreatmentAdherence}}</small>
                                        </div>								
                                        <div *ngIf="mainForm.controls['treatmentAdherenceDate'].errors">
                                            <small id="treatmentAdherenceDate-help" class="p-invalid">{{mainForm.controls['treatmentAdherenceDate'].errors['invalidDate']}}</small>
                                        </div>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !treatmentAdherencePanelEnabled}">
                                        <p-radioButton formControlName="treatmentAdherence" value=0 label="No" (click)="onChangeTreatementAdherenceYesNo(0)"></p-radioButton>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !treatmentAdherencePanelEnabled}">
                                        <p-radioButton formControlName="treatmentAdherence" value=1 label="Yes" (click)="onChangeTreatementAdherenceYesNo(1)"></p-radioButton>
                                    </div>
                                    <div class="field p-col" *ngIf="getTreatementAdherenceYesNoValue() == 1" [ngClass]="{'disableDiv': !treatmentAdherencePanelEnabled}">
                                        <input type="text" formControlName="treatmentAdherenceDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"  
                                        placeholder="Date"
                                        class="champ-date-picker" pInputText/>
                                    </div>
                                </div>
                            </p-accordionTab>
                            <p-accordionTab *ngIf="alcoholPanelVisible" header="Alcohol Counseling" [selected]="alcoholPanelVisible">
                                <div class="formgroup-inline chamformgroup-inline">
                                    <div class="field col-7">
                                        <label for="alcoholUse">Is this patient using Alcohol?</label>
                                        <div *ngIf="!!mainForm.errors?.invalidAlcoholUse">
                                            <small id="alcoholUse-help" class="p-invalid">{{mainForm.errors?.invalidAlcoholUse}}</small>
                                        </div>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !alcoholPanelEnabled}">
                                        <p-radioButton formControlName="alcoholUse" value=0 label="No" (click)="onChangeAlcoholYesNo(0)"></p-radioButton>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !alcoholPanelEnabled}">
                                        <p-radioButton formControlName="alcoholUse" value=1 label="Yes" (click)="onChangeAlcoholYesNo(1)"></p-radioButton>
                                    </div>
                                    <div class="field p-col">
                                    </div>
                                </div>
                                <div class="formgroup-inline chamformgroup-inline" *ngIf="getAlcoholYesNoValue() == 1">
                                    <div class="field col-7">
                                        <label for="alcoholCounseling">Did the patient receive Alcohol Counseling?</label>
                                        <div *ngIf="!!mainForm.errors?.invalidAlcoholCounseling">
                                            <small id="alcoholCounseling-help" class="p-invalid">{{mainForm.errors?.invalidAlcoholCounseling}}</small>
                                        </div>
                                        <div *ngIf="mainForm.controls['alcoholCounselingDate'].errors">
                                            <small id="alcoholCounselingDate-help" class="p-invalid">{{mainForm.controls['alcoholCounselingDate'].errors['invalidDate']}}</small>
                                        </div>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !alcoholPanelEnabled}">
                                        <p-radioButton formControlName="alcoholCounseling" value=0 label="No" (click)="onChangeAlcoholCounselingYesNo(0)"></p-radioButton>
                                    </div>
                                    <div class="field col-1" [ngClass]="{'disableDiv': !alcoholPanelEnabled}">
                                        <p-radioButton formControlName="alcoholCounseling" value=1 label="Yes" (click)="onChangeAlcoholCounselingYesNo(1)"></p-radioButton>
                                    </div>
                                    <div class="field p-col" *ngIf="getAlcoholCounselingYesNoValue() == 1" [ngClass]="{'disableDiv': !alcoholPanelEnabled}">
                                        <input type="text" formControlName="alcoholCounselingDate" autocomplete="off" bsDatepicker
                                        [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"  
                                        placeholder="Date"
                                        class="champ-date-picker" pInputText/>
                                    </div>
                                </div>
                            </p-accordionTab>
                            <div class="fluid formgrid grid" *ngIf="this.allowEdit && this.isAtLeastOneCounselingRequired()">
                                <div class="field col-12">
                                    <button pButton pRipple class="btn btn-primary" (click)="onSaveChangesNoConfirm($event)" icon="pi pi-check"  *ngIf="!clientService.readonly"
                                            label="Save" [disabled]="!mainForm.valid" style="width:130px;float: right;margin-top: 1rem;"></button>
                                    <!-- &nbsp;&nbsp;&nbsp;
                                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="btn" 
                                            (click)="onHideDialog()" style="width:130px;float: right;margin-right:10px;"></button>      -->
                                </div>
                            </div>                    
                        <!-- // Changed to false to permantly hide the MH question DL 10/2018
                                // Changed to false to permantly hide the SA question DL 10/2018 -->
            <!--            <p-accordionTab header="Included Screenings" [selected]="false" [disabled]="true">
                                <ng-template pTemplate="content">
                                    <div class="formgroup-inline">
                                        <label for="dsMentalHealthYesNo">Mental Health Screening Performed?</label>
                                        <p-radioButton formControlName="dsMentalHealthYesNo" value=1 label="Yes"></p-radioButton>
                                        <p-radioButton formControlName="dsMentalHealthYesNo" value=2 label="No"></p-radioButton>
                                        <input type="text" formControlName="dsMentalHealthDate" autocomplete="off" bsDatepicker
                                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"
                                                placeholder="Date" 
                                                class="champ-date-picker" pInputText/>
                                    </div>
                                    <div class="formgroup-inline">
                                        <label for="dsSubstanceAbuseYesNo">Substance Abuse Screening Performed?</label>
                                        <p-radioButton formControlName="dsSubstanceAbuseYesNo" value=1 label="Yes"></p-radioButton>
                                        <p-radioButton formControlName="dsSubstanceAbuseYesNo" value=2 label="No"></p-radioButton>
                                        <input type="text" formControlName="dsSubstanceAbuseDate" autocomplete="off" bsDatepicker
                                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"
                                                placeholder="Date" 
                                                class="champ-date-picker" pInputText/>
                                    </div>                                    
                                </ng-template>
                            </p-accordionTab> -->
                        </p-accordion>
                    </div>
                    <!--             <div class="fluid formgrid grid">
                                <div class="field p-col">
                                    <button type="button" pButton icon="fa-close" (click)="displayRiskReductionModal=false" label="Close"></button>
                                </div>
                            </div> -->
                </div>
            </div>
        </ng-template>
    </p-dialog>
</form>