<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                    <div class="field col-7">Tuberculosis</div>

                    <div class="field col-4">
                        <div [ngClass]="{'disableDiv': !showTuberculosisUnavailable()}">
                            <p-checkbox value="false" inputId="unavailable" formControlName="idTBUnavailable" [binary]="true"></p-checkbox>   
                            <label style="font-size: 0.875rem; font-weight: normal;" for="unavailable">Unavailable</label>
                        </div>
                    </div>

                    <div class="field col-1">
                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('TB', 'Tuberculosis')"></button>      
                    </div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid" *ngIf="!isTuberculosisUnavailable()">
                <div class="field col-12">
                    <div class="formgroup-inline chamformgroup-inline" style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px; line-height: 20px;">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <label  style="font-weight:normal; margin-right: 10px;">Was the client screened for Tuberculosis?</label>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idTBScreened" value=2 label="No"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idTBScreened" value=1 label="Yes"></p-radioButton></div>
                        <label id="idTuberculosisResultDate">
                            <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalTuberculosis && this.clientData.clientMedicalTuberculosis?.positive != PosNegResults.Positive" [formGroup]="medical"
                              [fieldId]="MasterFields.TuberculosisResultDate" [lastUpdated]="this.clientData.clientMedicalTuberculosis?.datetime"></app-client-expired-field>
                        </label>                            
                    </div>

                    <div *ngIf="medical.controls['idTBScreened']?.errors?.required"> 
                        <small id="idTBScreened-help" class="p-invalid" style="margin-left: 1rem;">Tuberculosis screening is required.</small>
                    </div>
                </div>   

                <div class="field col-12">
                    <div id="tbNoDiv" class="fluid formgrid grid" *ngIf="showTuberculosisNoReason()">
                        <div class="field col-12">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idTBScreenedNoReason" style="margin-right: 1rem;">Reason</label>
                                <div class="field grid">
                                    <p-dropdown formControlName="idTBScreenedNoReason" [options]="noReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}"  > </p-dropdown>      
                                </div>
                            </div>
                            <div *ngIf="medical.controls['idTBScreenedNoReason']?.errors"> 
                                <small id="idTBScreenedNoReason-help" class="p-invalid" style="margin-left: 1rem;">Reason is required.</small>
                            </div>
                        </div>
                    </div>
                    
                    <div id="tbYesDiv" class="fluid formgrid grid" *ngIf="showTuberculosisResultFields()">
                        <div class="field col-6">
                            <div class="formgroup-inline chamformgroup-inline" >
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idTBScreenedDate" style="margin-right: 1rem;">Date of Result</label> 
                                <input type="text"  #aidsDiagDatePicker  name="idTBScreenedDate" autocomplete="off" bsDatepicker formControlName="idTBScreenedDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="idTBScreenedDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idTBScreenedDate'].invalid}" 
                                    class="champ-date-picker" pInputText/>
                            </div>
                        </div>

                        <div class="field col-6">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label  style="font-weight:normal; margin-right: 10px;">Result:</label>
                                <p-radioButton formControlName="idTBPositive" value=2 label="Negative" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idTBPositive" value=1 label="Positive" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idTBPositive" value=3 label="Client Did Not Return" [style]="{'margin-left':'75px'}"></p-radioButton>
                            </div>
                        </div>
                        
                        <div class="field col-6">
                            <div *ngIf="medical.controls['idTBScreenedDate']?.invalid">
                                <div *ngIf="medical.controls['idTBScreenedDate']?.errors?.required"> 
                                    <small id="idTBScreenedDate-help" class="p-invalid" style="margin-left: 1rem;">Result Date is required.</small>
                                </div>
                                <!-- <div *ngIf="!!medical.controls['idTBScreenedDate'].errors['invalidDate']">-->
                                <div *ngIf="!medical.controls['idTBScreenedDate']?.errors?.required && !!medical.controls['idTBScreenedDate']?.errors"> 
                                    <small id="idTBScreenedDate2-help" class="p-invalid" style="margin-left: 1rem;">{{medical.controls['idTBScreenedDate']?.errors['invalidDate']}}</small>
                                </div>                                                               
                            </div>
                         </div>

                        <div class="field col-6">
                            <div *ngIf="!!medical.controls['idTBPositive']?.errors"> 
                                <small id="idTBPositive-help" class="p-invalid" style="margin-left: 1rem;">Result is required.</small>
                            </div>
                        </div>

                        <div class="field col-6"></div>

                        <div class="field col-6">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idTBTestType" style="margin-right: 1rem;">Test Type</label>
                                <div class="field grid">
                                    <p-dropdown formControlName="idTBTestType" [options]="testTypes" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}" ></p-dropdown>      
                                </div>
                            </div>
                        </div>

                        <div class="field col-6"></div>

                        <div class="field col-6">
                            <div *ngIf="medical.controls['idTBTestType']?.errors"> 
                                <small id="idTBTestType-help" class="p-invalid" style="margin-left: 1rem;">Test Type is required.</small>
                            </div>
                        </div>                   
                    </div>
                </div>
            </div>

            <div class="fluid formgrid grid" *ngIf="isTuberculosisUnavailable()">
                <small id="idTuberculosisUnavailable-help">Tuberculosis screening was marked unavailable. Screening will be required in 30 days.</small>
            </div>
        </div>
    </div>
</form>

<app-history ></app-history> 