<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='fg' autocomplete="off">
    <div class="grid">
        <div class="grid col-12 md:col-12 xl:col-5 lg:col-8">
            <div class="grid" style="padding-top: 1rem;">
            <!-- <div class="fluid formgrid grid " style="margin-bottom: 1rem;"> -->
                <div class="col-2">
                    <h3><b>Error Logs</b></h3>
                </div>

                <!-- <div class="col-md-1" style="width: 12.499999995%"></div>
                Since col-md-1 is of width 8.33333333%; simply multiply 8.33333333 * 1.5 and set it as your width. -->

                <div class="formgrid col-3">
                        <label style="margin-right:1rem ;" htmlFor="startDate">Start Date:</label>                            
                        <input type="text" name="startDate" autocomplete="off" bsDatepicker formControlName="startDate"
                                [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="startDateId"    
                                placeholder="Start Date"
                                [ngClass]="{'p-invalid' : fg.controls['startDate'].invalid}"
                                class="champ-date-picker" pInputText style="z-index: 1000 !important;"/>
                </div>

                <div class="formgrid col-3">
                    <label style="margin-right:1rem ;" htmlFor="endDate">End Date:</label>
                    <input type="text" name="endDate" autocomplete="off" bsDatepicker formControlName="endDate"
                            [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }" id="endDateId"    
                            placeholder="End Date"
                            [ngClass]="{'p-invalid' : fg.controls['endDate'].invalid}"
                            class="champ-date-picker" pInputText style="z-index: 1000 !important;"/>
                </div>

                <div class="formgrid col-3">
                    <span class="flex justify-content-end" style="width: 100%;">
                        <span style="align-content: center; display: grid;">CHAMP 3.5</span>
                        <div style="padding-left: .5rem; padding-right: .5rem">
                            <p-inputSwitch formControlName="logTypeSeriLog"></p-inputSwitch>
                        </div>
                        <span style="align-content: center; display: grid;">CHAMP 4</span>
                    </span>            
                </div>        

                <div class="field col-1">
                    <button #refresh pButton type="button" label="Refresh" class="p-button-link" style="width: 10rem;" (click)="onChangeFilter($event, dtErrors)"></button>
                </div>
            </div>                
            <!-- </div> -->
        </div>
    </div>

    <div class="container-fluid" id="grid" > 
        <p-table #dtErrors [value]="getErrors()" [resizableColumns]="false" [scrollable]="true" scrollDirection="both" scrollHeight="65vh" 
               styleClass="p-datatable-sm p-datatable-striped"   >   <!--   [filters]="filters"  -->
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th pSortableColumn="errorId" style="width:8rem;">Error ID<p-sortIcon field="errorId" ></p-sortIcon></th> -->
                    <th pSortableColumn="datetime" style="width:13rem;">Error Date<p-sortIcon field="datetime"></p-sortIcon></th>
                    <th pSortableColumn="logLevel" style="min-width:10rem;">Level<p-sortIcon field="logLevel"></p-sortIcon></th>                    
                    <th pSortableColumn="errorDesc" style="width: 20rem;">Error Description<p-sortIcon field="errorDesc"></p-sortIcon></th>
                    <th pSortableColumn="host" style="min-width:10rem;">Host<p-sortIcon field="host"></p-sortIcon></th>
                    <th pSortableColumn="userName" style="min-width:10rem;">User<p-sortIcon field="userName"></p-sortIcon></th>
                    <th pSortableColumn="providerName" style="width:10rem;">Provider<p-sortIcon field="providerName"></p-sortIcon></th>
                    <th pSortableColumn="assemblyName" style="min-width:10rem;">Assembly Name<p-sortIcon field="assemblyName"></p-sortIcon></th>
                    <th pSortableColumn="assemblyVersion" style="min-width:10rem;">Assembly Version<p-sortIcon field="assemblyVersion"></p-sortIcon></th>
                </tr>
                <tr>
                    <th style="width:13rem;"></th>
                    <th style="min-width:10rem;">
                        <p-columnFilter type="text" field="logLevel" matchMode="startsWith"></p-columnFilter>

                        <!-- <p-columnFilter field="representative" matchMode="in" [showMenu]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect [ngModel]="value" [options]="representatives" placeholder="Any" (onChange)="filter($event.value)" optionLabel="name">
                                    <ng-template let-option pTemplate="item">
                                        <div class="inline-block vertical-align-middle">
                                            <img [alt]="option.label" src="https://primefaces.org/cdn/primeng/images/demo/avatar/{{ option.image }}" width="24" class="vertical-align-middle" />
                                            <span class="ml-1 mt-1">{{ option.name }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>             -->
                        
                        
                    </th>                     
                    <th style="width:20rem;">
                        <p-columnFilter style="width: 10rem;" type="text" field="errorDesc" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="min-width:10rem;">
                        <p-columnFilter type="text" field="host" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="min-width:10rem;">
                        <p-columnFilter type="text" field="userName" matchMode="contains"></p-columnFilter>
                    </th>         
                    <th style="width:20rem;">
                        <p-columnFilter type="text" field="providerName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="min-width:10rem;">
                        <p-columnFilter type="text" field="assemblyName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="min-width:10rem;">
                        <p-columnFilter type="text" field="assemblyVersion" matchMode="contains"></p-columnFilter>
                    </th>
                </tr> 
            </ng-template>
            <ng-template pTemplate="body" let-errorReport let-editing="editing" let-rowIndex="rowIndex">
                <tr >  <!-- [pEditableRow]="event" -->
                    <!-- <td style="width:8rem;  "> {{ errorReport?.errorId }}  </td> -->
                    <td style="width:13rem; "> {{ errorReport?.datetime | date:'MM/dd/yyyy  hh:mm:ss aa' }}  </td>
                    <td style="min-width:10rem; "><span title="{{errorReport?.logLevel}}"> {{ errorReport?.logLevel }} </span></td>                    
                    <td style="width:20rem;max-width:40rem "><span title="{{errorReport?.errorDesc}}"> {{ errorReport?.errorDesc?.substring(0, 230) }} </span></td>
                    <td style="min-width:10rem; "><span title="{{errorReport?.host}}"> {{ errorReport?.host }} </span></td>
                    <td style="min-width:10rem;max-width:15rem; "><span title="{{errorReport?.userName}}"> {{ errorReport?.userName }} </span></td>
                    <td style="width:10rem; max-width:20rem; "><span title="{{errorReport?.providerName}}"> {{ errorReport?.providerName }} </span></td>
                    <td style="min-width:10rem; "><span title="{{errorReport?.assemblyName}}"> {{ errorReport?.assemblyName }} </span></td>
                    <td style="min-width:10rem; "><span title="{{errorReport?.assemblyVersion}}"> {{ errorReport?.assemblyVersion }} </span></td>
                </tr>
            </ng-template>
        </p-table>                    
    </div> 
</form>