<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='fg' autocomplete="off">
    <div class="container-fluid champ-card-body-sm" style="width: 100%;margin-top: 2rem;">
        <!-- <div class="card-header rmt-card-header-sm" style="width: 99%;">{{ getContractItemHeader() }}</div> -->

        <!-- <div class="card rmt-card" style="width: 99%;">
            <div>{{ params | json }}</div>
        </div> -->

        <!-- contract items section -->
        <div class="card champ-card" style="width: 99%;">
            <div class="p-fluid p-formgrid p-grid" style="margin-top: .5em;">                                                                                                                                                                                               
                <div class="p-field p-col-12" style="margin-right: 10px;">
                    <p-table #dtItems [value]="getContractsItems()" dataKey="guid" editMode="row" selectionMode="single"
                            [scrollable]="true"  scrollHeight="40rem" scrollDirection="both"  >
                        <ng-template pTemplate="header">
                            <tr>
                                <!-- <th style="width:10rem"><button pButton pRipple label="New" icon="pi pi-plus" class="btn btn-primary mr-2" (click)="onRowAdd($event, dtItems)" [disabled]="isRowInEditMode" style="width: 5rem !important; height: 2rem !important; padding: .5rem"></button></th>                                 -->
                                <th style="width:10rem"><p-button   label="New" [text]="true" icon="pi pi-plus" (click)="onRowAdd($event, dtItems)" [disabled]="isRowInEditMode" styleClass="p-button-text note-button font-bold" class="p-button-help p-button-text mr-1 " ></p-button></th>                                
                                <th style="width:8rem" pSortableColumn="startDate">Start Date <p-sortIcon field="startDate"></p-sortIcon></th>
                                <th style="width:8rem">End Date</th>
                                <th style="width:20rem">Service Category</th>
                                <th style="width:20rem">Sub Category</th>
                                <th style="width:10rem">Unit</th>
                                <th style="width:8rem">Unit Cost</th>
                                <!-- <th style="width:8rem">Indirect Cost</th> -->
                                <th style="width:20rem">Funder</th>
                                <th style="width:10rem">Amount</th>
                                <th style="width:5rem">Deleted</th>
                                <th style="width:20rem">Memo</th>
                                <th style="width:10rem">Divide Units</th>
                                <th style="width:10rem">Allow Client Lookup</th>
                            </tr>
                            <tr>
                                <th style="width:10rem"></th>
                                <th style="width:8rem">
                                </th>                                  
                                <th style="width:8rem">
                                </th>                                
                                <th style="width:20rem">
                                    <!-- <p-dropdown [options]="products" (onChange)="dtProducts.filter($event.value, 'productId', 'equals')" styleClass="p-column-filter" placeholder="Select Product" [showClear]="true">
                                        <ng-template let-option pTemplate="item">
                                            <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                        </ng-template>
                                    </p-dropdown>                                     -->
                                    <!-- <p-columnFilter type="text" field="productId" matchMode="contains"></p-columnFilter> -->
                                    <!-- productId commonDataService.getProductDescription(product?.productId) -->                                    
                                </th>                                 
                                <th style="width:20rem">
                                </th>                                
                                <th style="width:10rem">
                                </th>
                                <th style="width:8rem">
                                </th>
                                <!-- <th style="width:8rem">
                                </th> -->
                                <th style="width:20rem">
                                    <!-- <p-multiSelect [options]="underwriters" (onChange)="dtProducts.filter($event.value, 'underwriterId', 'in')" styleClass="p-column-filter" placeholder="Select Underwriter" [showClear]="true">
                                        <ng-template let-option pTemplate="item">
                                            <span [class]="'customer-badge status-' + option.value">{{option.label}}</span>
                                        </ng-template>
                                    </p-multiSelect>                                     -->
                                </th>     
                                <th style="width:10rem">
                                    <!-- <p-columnFilter type="numeric" field="contractAmt"></p-columnFilter> -->
                                </th>
                                <th style="width:5rem">
                                    <p-columnFilter type="boolean" field="deleted"></p-columnFilter>                                    
                                </th>                                
                                <th style="width:20rem">
                                </th>
                                <th style="width:10rem">
                                    <p-columnFilter type="boolean" field="divideGroupUnits"></p-columnFilter>
                                </th>
                                <th style="width:10rem">
                                    <p-columnFilter type="boolean" field="allowClientLookup"></p-columnFilter>
                                </th>                           
                            </tr>                            
                        </ng-template>
                        <ng-template pTemplate="body" let-contractItem let-editing="editing" let-rowIndex="rowIndex">
                            <tr [pEditableRow]="contractItem">
                                <td style="text-align: center; width: 10rem" #buttonContainer>
                                    <!-- <div>{{ rowIndex | json }}</div>
                                    <div>{{ rowIndexInEditMode | json }}</div> -->
                                    <button #editRowButton *ngIf="!isRowInEditMode" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onRowEditInit($event, dtItems, contractItem, rowIndex)" class="p-button-rounded p-button-text"></button>
                                    <!-- <button #showProductDetailsButton *ngIf="!isRowInEditMode" pButton pRipple type="button" icon="pi pi-book" (click)="onShowProductDetails($event, product)" class="p-button-rounded p-button-text"></button>                                        -->
                                    <!-- <button #editRowButton *ngIf="!isRowInEditMode" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" (click)="onShowProductDetails($event, dtProducts, product, rowIndex)" class="p-button-rounded p-button-text"></button> -->
                                    <button #saveRowButton *ngIf="fg.valid && isRowInEditMode && rowIndexInEditMode === rowIndex" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="onRowEditSave($event, dtItems, contractItem, rowIndex)" class="p-button-rounded p-button-text p-button-success p-mr-2"></button>
                                    <button #cancelRowButton *ngIf="isRowInEditMode && rowIndexInEditMode === rowIndex" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times" (click)="onRowEditCancel($event, dtItems, contractItem, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
                                    <button #deleteRowButton *ngIf="!isRowInEditMode" pButton pRipple type="button" icon="pi pi-trash" (click)="onRowDelete($event, dtItems, contractItem, rowIndex)" class="p-button-rounded p-button-text p-button-danger"></button>
                                </td>

                                <td pEditableColumn style="width:8rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <div class="formgroup-inline">
                                                    <input type="text" autocomplete="off" bsDatepicker formControlName="startDate"
                                                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers: false }"
                                                    placeholder="MM/DD/YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : fg.controls['startDate'].invalid}" 
                                                    class="champ-date-picker" pInputText appendTo="body"/>
                                                </div>
                                                <div *ngIf="fg.controls['startDate'].errors?.required">
                                                    <small id="startDate-help" class="p-invalid">Start Date is required.</small>
                                                </div>
                                                <div *ngIf="!fg.controls['startDate'].errors?.required && fg.controls['startDate'].errors">
                                                    <small id="startDate2-help" class="p-invalid">{{ fg.controls['startDate'].errors['invalidDate'] }}</small>
                                                </div>                                                
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ this.helper.getFormattedDate(contractItem.startDate) }}
                                            </div>
    
                                            <!-- <div *ngIf="medical.controls['startDate']?.invalid">
                                                <div *ngIf="medical.controls['startDate']?.errors?.required"> 
                                                    <small id="startDate-help" class="p-invalid">Birthdate is required.</small>
                                                </div>
                                                <div *ngIf="!medical.controls['startDate']?.errors?.required && !!medical.controls['startDate']?.errors"> 
                                                    <small id="startDate-help" class="p-invalid">{{medical.controls['startDate']?.errors['invalidDate']}}</small>
                                                </div>
                                            </div> -->
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ this.helper.getFormattedDate(contractItem.startDate) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                
                                <td pEditableColumn style="width:8rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <div class="formgroup-inline">
                                                    <input type="text" autocomplete="off" bsDatepicker formControlName="endDate"
                                                    [bsConfig]="{ dateInputFormat: 'MM/DD/YYYY', showWeekNumbers: false }"
                                                    placeholder="MM/DD/YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : fg.controls['endDate'].invalid}" 
                                                    class="champ-date-picker" pInputText appendTo="body"/>
                                                </div>
                                                <div *ngIf="fg.controls['endDate'].errors?.required">
                                                    <small id="endDate-help" class="p-invalid">End Date is required.</small>
                                                </div>
                                                <div *ngIf="!fg.controls['endDate'].errors?.required && fg.controls['endDate'].errors"> 
                                                    <small id="endDate2-help" class="p-invalid">{{ fg.controls['endDate'].errors['invalidDate'] }}</small>
                                                </div>
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ this.helper.getFormattedDate(contractItem?.endDate) }}
                                            </div>
    
                                            <!-- <div *ngIf="medical.controls['termDate']?.invalid">
                                                <div *ngIf="medical.controls['termDate']?.errors?.required"> 
                                                    <small id="termDate-help" class="p-invalid">Birthdate is required.</small>
                                                </div>
                                                <div *ngIf="!medical.controls['termDate']?.errors?.required && !!medical.controls['termDate']?.errors"> 
                                                    <small id="termDate-help" class="p-invalid">{{medical.controls['termDate']?.errors['invalidDate']}}</small>
                                                </div>
                                            </div> -->
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ this.helper.getFormattedDate(contractItem?.endDate) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>                                

                                <td pEditableColumn style="width:20rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-dropdown formControlName="serviceId" [options]="serviceCategories" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeServiceType($event.value)"> </p-dropdown>
                                                <!-- <p-dropdown [options]="serviceCategories" optionLabel="label" optionValue="value" formControlName="serviceId" [style]="{'width':'100%'}"></p-dropdown> -->
                                                <div *ngIf="fg.controls['serviceId'].errors?.required">
                                                    <small id="serviceId-help" class="p-invalid">Service Category is required.</small>
                                                </div>
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ commonDataService.getServiceCategoryDescription(contractItem?.serviceId) }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ commonDataService.getServiceCategoryDescription(contractItem?.serviceId) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn style="width:20rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-dropdown formControlName="subId" [options]="subTypes" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeSubType($event.value)"> 
                                                    <ng-template let-subType pTemplate="item">
                                                        <div style="width: 125px;">
                                                            <div style="font-weight: bold;">{{subType.description}}</div>
                                                            <div style="font-size: smaller;font-weight: 300;"><span title="{{subType.longDescription}}">{{subType.longDescription}}</span></div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                                <div *ngIf="fg.controls['subId'].errors?.required">
                                                    <small id="subId-help" class="p-invalid">Sub Type is required.</small>
                                                </div>                                                
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ commonDataService.getSubTypeDescription(contractItem?.subId) }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ commonDataService.getSubTypeDescription(contractItem?.subId) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                
                                <td pEditableColumn style="width:10rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <!-- <p-inputNumber formControlName="unit" inputId="unit" [ngStyle]="{'width.px': 100}"></p-inputNumber> -->
                                                <p-dropdown formControlName="unit" [options]="units" optionLabel="description" optionValue="id" appendTo="body"></p-dropdown>
                                                <div *ngIf="fg.controls['unit'].errors?.required">
                                                    <small id="unit-help" class="p-invalid">Unit is required.</small>
                                                </div>
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ commonDataService.getDescription('UnitType', contractItem?.unit) }}
                                            </div>                                   
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ commonDataService.getDescription('UnitType', contractItem?.unit) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn  style="width:8rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-inputNumber formControlName="unitCost" inputId="unitCost" [ngStyle]="{'width.px': 100}" mode="decimal" [minFractionDigits]="4"></p-inputNumber>
                                                <div *ngIf="fg.controls['unitCost'].errors?.required">
                                                    <small id="unitCost-help" class="p-invalid">Unit Cost is required.</small>
                                                </div>
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ contractItem?.unitCost | currency:'CAD':'symbol-narrow' }}
                                            </div>                                   
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ contractItem?.unitCost | currency:'CAD':'symbol-narrow' }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <!-- <td pEditableColumn  style="width:8rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-inputNumber formControlName="indirectCost" inputId="indirectCost" [ngStyle]="{'width.px': 100}" mode="decimal" [minFractionDigits]="4"></p-inputNumber>
                                                <div *ngIf="fg.controls['indirectCost'].errors?.required">
                                                    <small id="indirectCost-help" class="p-invalid">Indirect Cost is required.</small>
                                                </div>                                                
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ contractItem?.indirectCost | currency:'CAD':'symbol-narrow' }}
                                            </div>                                   
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ contractItem?.indirectCost | currency:'CAD':'symbol-narrow' }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->

                                <td pEditableColumn style="width:20rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-dropdown formControlName="funder" [options]="funders" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeFunder($event.value)"></p-dropdown>
                                                <div *ngIf="fg.controls['funder'].errors?.required">
                                                    <small id="funder-help" class="p-invalid">Funder is required.</small>
                                                </div>                                                
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ commonDataService.getDescription('Funder', contractItem?.funder) }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ commonDataService.getDescription('Funder', contractItem?.funder) }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn  style="width:10rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-inputNumber formControlName="contractAmt" inputId="contractAmt" [ngStyle]="{'width.px': 100}" mode="decimal" [minFractionDigits]="4" (onBlur)="onBlurContractItemAmount($event, contractItem)">
                                                    <!-- (onBlur)="onBlurContractItemAmount($event, contractItem)"> -->
                                                </p-inputNumber>
                                                <div *ngIf="fg.controls['contractAmt'].errors?.required">
                                                    <small id="contractAmt-help" class="p-invalid">Contract amount is required.</small>
                                                </div>
                                                <!--
                                                <div *ngIf="!fg.controls['contractAmt'].errors?.required && fg.controls['contractAmt'].errors">
                                                    <small id="contractAmt2-help" class="p-warning">The contract items exceed the contract amount.</small>
                                                </div>
                                                -->
                                                <!-- <div *ngIf="!fg.controls['contractAmt'].errors?.required && fg.controls['contractAmt'].warnings"> -->
                                                <div *ngIf="!!this.contractAmountNotEqual">                                                    
                                                    <small id="contractAmt2-help" class="p-warning">The contract items exceed the contract amount.</small>
                                                </div>                                                 
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ contractItem?.contractAmt | currency:'CAD':'symbol-narrow'}}
                                            </div>                                   
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ contractItem?.contractAmt | currency:'CAD':'symbol-narrow'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>                                

                                <td pEditableColumn style="width:5rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-field-checkbox" *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-checkbox formControlName="deleted" [style]="{'width': '100%', 'margin-top': '1rem'}" binary="true" (onChange)="onBlurContractItemAmount($event, contractItem)">
                                                    <!-- (onChange)="onBlurContractItemAmount($event, contractItem)" -->
                                                </p-checkbox>
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                <i class="pi" [ngClass]="{'true-icon pi-check-circle': contractItem.deleted, 'false-icon pi-times-circle': !contractItem.deleted}"></i>
                                                <!-- <i class="fa-sharp fa-check fa-xl" style="color:green;"></i> -->
                                                <div *ngIf="contractItem.deleted">  <i class="fa-sharp fa-xmark fa-xl" style="color:red;"></i>    </div>    
                                                <!-- <div *ngIf="contractItem.deleted">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>     -->
 
                                            </div>                                 
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>
                                                <!-- <i class="pi" [ngClass]="{'true-icon pi-check-circle': contractItem.deleted, 'false-icon pi-times-circle': !contractItem.deleted}"></i> -->
                                                <div *ngIf="contractItem.deleted">  <i class="fa-sharp fa-xmark fa-xl" style="color:red;"></i>    </div>    
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn style="width:20rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <textarea formControlName="memo" rows="3" cols="1" pInputTextarea style="height:40px;"></textarea>
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                {{ contractItem?.memo }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{ contractItem?.memo }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn style="width:10rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-field-checkbox" *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-checkbox formControlName="divideGroupUnits" [style]="{'width': '100%', 'margin-top': '1rem'}" binary="true"></p-checkbox>
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                <i class="pi" [ngClass]="{'true-icon pi-check-circle': contractItem.divideGroupUnits, 'false-icon pi-times-circle': !contractItem.divideGroupUnits}"></i>
                                                <div *ngIf="contractItem.divideGroupUnits">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   
                                                <!-- <div *ngIf="contractItem.divideGroupUnits">  <i class="fa-sharp fa-xmark fa-xl" style="color:red;"></i>    </div>    -->
                                            </div>                                 
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>
                                                <!-- <i class="pi" [ngClass]="{'true-icon pi-check-circle': contractItem.divideGroupUnits, 'false-icon pi-times-circle': !contractItem.divideGroupUnits}"></i> -->
                                                <div *ngIf="contractItem.divideGroupUnits">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>

                                <td pEditableColumn style="width:10rem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-field-checkbox" *ngIf="isGroupRowInEditMode(rowIndex)">
                                                <p-checkbox formControlName="allowClientLookup" [style]="{'width': '100%', 'margin-top': '1rem'}" binary="true"></p-checkbox>
                                            </div>
                                            <div *ngIf="!isGroupRowInEditMode(rowIndex)">
                                                <!-- <i class="pi" [ngClass]="{'true-icon pi-check-circle': contractItem.allowClientLookup, 'false-icon pi-times-circle': !contractItem.allowClientLookup}"></i> -->
                                                <div *ngIf="contractItem.allowClientLookup">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   
                                            </div>                                 
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <div>
                                                <!-- <i class="pi" [ngClass]="{'true-icon pi-check-circle': contractItem.allowClientLookup, 'false-icon pi-times-circle': !contractItem.allowClientLookup}"></i> -->
                                                <div *ngIf="contractItem.allowClientLookup">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   
                                            </div>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>                                
                            </tr>
                        </ng-template>
                    </p-table>                    
                </div>
            </div>  
        </div>
        <!-- End of contract items section -->          
    </div>
</form>    