<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='fg' autocomplete="off">
    <div class="p-fluid p-formgrid grid " style="margin-bottom: 1rem;">
        <div class="col-2">
            <h3><b> User Maintenance</b></h3>
        </div>
        <div class="field col-2" style="padding-right:2rem">
            <label htmlFor="provider" style="margin-right:1rem; font-weight: bold;">Provider</label>
            <p-dropdown formControlName="providerId" inputId="provider"  [options]="providers" optionLabel="label" optionValue="value"
                        (onChange)="onChangeFilter($event, dtUsers)" ></p-dropdown>
        </div>        
        <!-- <div class="field col-2">
            <button #refresh pButton type="button" label="Refresh" class="p-button-link" style="width: 10rem;" (click)="onParamChange($event)"></button>
        </div>  -->
    </div>

    <div class="p-container-fluid" id="grid" > 
        <p-table #dtUsers [value]="getUsers()"    [scrollable]="true"  scrollDirection="both"  scrollHeight="65vh"  styleClass="p-datatable-sm p-datatable-striped" 
            sortField="id" sortOrder="-1" > 
                    <!-- sortField="lastLoginDate" sortOrder="-1" >  -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10rem"><p-button   label="New" [text]="true" icon="pi pi-plus" (click)="onEditUser($event,null)" [disabled]="isRowInEditMode" styleClass="p-button-text note-button font-bold" class="p-button-help p-button-text mr-1 " ></p-button></th>                                
                    <!-- <th pSortableColumn="guid" style="width:8rem; ">Guid<p-sortIcon field="guid" ></p-sortIcon></th> -->
                    <!-- <th style="width:15rem; "></th> -->
                    <th pSortableColumn="id" style="width:10rem; ">User ID<p-sortIcon field="id" ></p-sortIcon></th>
                    <th pSortableColumn="name" style="width:15rem;">User Name<p-sortIcon field="name" ></p-sortIcon></th>
                    <th pSortableColumn="lastName" style="width:15rem;">Last Name<p-sortIcon field="lastName" ></p-sortIcon></th>                                
                    <th pSortableColumn="firstName" style="width:15rem; ">First Name<p-sortIcon field="firstName" ></p-sortIcon></th>
                    <th pSortableColumn="emailAddress" style="width:20rem; ">Email<p-sortIcon field="emailAddress" ></p-sortIcon></th>
                    <th pSortableColumn="accountEnabled" style="width:15rem; ">Enabled<p-sortIcon field="accountEnabled" ></p-sortIcon></th>
                    <th pSortableColumn="lastLoginDate" style="width:18rem; ">Last Login<p-sortIcon field="lastLoginDate"></p-sortIcon></th>
                    <!-- <th pSortableColumn="serverAssemblyVersion" style="width:15rem; ">Server Assembly Version<p-sortIcon field="serverAssemblyVersion" ></p-sortIcon></th> -->
                </tr>
                 <tr>
                    <th style="width:10rem"></th>
                    <th style="width:10rem"><p-columnFilter type="text" field="id" matchMode="contains" showMatchModes=”false” showOperator=”false” ></p-columnFilter></th>
                    <th style="width:15rem"><p-columnFilter type="text" field="name" matchMode="contains"></p-columnFilter></th>
                    <th style="width:15rem"><p-columnFilter type="text" field="lastName" matchMode="contains"></p-columnFilter></th>
                    <th style="width:15rem"><p-columnFilter type="text" field="firstName" matchMode="contains"></p-columnFilter></th>  
                    <th style="width:20rem"><p-columnFilter type="text" field="emailAddress" matchMode="contains"></p-columnFilter></th>                                    
                    <th style="width:15rem"><p-columnFilter type="boolean" field="accountEnabled" matchMode="contains" showMatchModes=”false” ></p-columnFilter></th>    
                    <th style="width:18rem"> </th>    
                    <!-- <th style="width:15rem"> </th>                              -->
                </tr>                             
            </ng-template>
            <ng-template pTemplate="body" let-user let-editing="editing" let-rowIndex="rowIndex">
                <tr >  <!-- [pEditableRow]="event" -->
                    <!-- <td style="width:8rem; "> {{ user?.guid }}  </td> -->
                    <td style="width:10rem;text-align:center" #buttonContainer>
                        <button #editRowButton  pButton pRipple type="button"  icon="pi pi-pencil"  class="p-button-rounded p-button-text" pTooltip="Edit User" (click)="onEditUser($event, user)"></button>  
                        <button #deleteRowButton  pButton pRipple type="button" icon="pi pi-trash"  class="p-button-rounded p-button-text p-button-danger" pTooltip="Delete User" (click)="onDeleteUser($event, user)"></button> 
                        <button #cloneRowButton  pButton pRipple type="button" icon="pi pi-clone"  class="p-button-rounded p-button-text" pTooltip="Clone User" (click)="onCloneUser($event, user)"></button> <!--  (click)="onRowDelete($event, dtClientGroups, clientGroup, rowIndex)"  -->

                    </td>
                    <td style="width:10rem; "> {{ user?.id }}   </td>
                    <td style="width:15rem; "> {{ user?.name }} </td>
                    <td style="width:15rem; "> {{ user?.lastName }} </td>
                    <td style="width:15rem; "> {{ user?.firstName}} </td>
                    <td style="width:20rem; "> {{ user?.emailAddress }} </td>
                    <td style="width:15rem; "> 
                        <i class="pi" [ngClass]="{'true-icon pi-check-circle': user?.accountEnabled, 'false-icon pi-times-circle ': !user?.accountEnabled}" ></i>
                    </td>
                    <td style="width:18rem; "> {{ user?.lastLoginDate | date:'MM/dd/yyyy  hh:mm:ss aa' }}  </td>
                   
                    <!-- <td style="width:15rem; "> {{ user?.serverAssemblyVersion }} </td> -->
                    </tr>
            </ng-template>
        </p-table>                    
    </div> 


    <!-- <app-user-maintenance-detail (onRefresh)="onRefresh()"></app-user-maintenance-detail> -->
</form>    