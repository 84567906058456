import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HistoryComponent } from '../../../history/history.component';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalOppDiseaseModel, ClientMedicalOppDisease, ClientMedicalCommon } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields } from "src/app/models/enums";
import { format, parseISO, addDays } from 'date-fns';
import { HelperClassService } from '../../../../services/helper-class.service';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { isEqual } from 'lodash';
import { noop } from 'rxjs';

@Component({
  selector: 'app-aids-define-condition',
  templateUrl: './aids-define-condition.component.html',
  styleUrls: ['./aids-define-condition.component.scss']
})
export class AidsDefineConditionComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  clientData: ClientModel;  
  clientGUID: string;
  aidsDefineConditionDropDownSettings: any = {};
  aidsDefineConditions: any[] = [];
  aidsDefineConditionsSelectedItems: any[] = []; // this has to be multi select use a single select for now
  NONE: number = 0; // master_opportunistic_disease none option is zero

  getAidsDefineConditionControl = () => this.medical?.get('idAidsDefineCondition');

  constructor(private fb: UntypedFormBuilder, 
              private commonDataService: CommonDataService,
              public providerService: ProviderService,
              private userService: UserService,
              public clientService: ClientFormService,
              private datePipe: DatePipe,
              private dateHelper: DateHelper,
              public  validationHelper: FormValidations,
              private helperService: HelperClassService,
              private medcommon: MedicalCommon,
              private cdRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.aidsDefineConditionDropDownSettings = { 
          singleSelection: false,
          limitSelection: 4,
          idField: 'id',
          textField: 'description', 
          selectAllText: 'Select All',
          unSelectAllText: 'UnSelect All',
          enableCheckAll: false,
          allowSearchFilter: false };

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      }); 

    if (this.clientService.newclient)
    {
      this.medical.markAllAsTouched();
    }
    else
    {
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.aidsDefineConditions = this.commonDataService.getCommonIdDescriptionSetByName("OpportunisticDisease");
                             //this.aidsDefineConditions = this.commonDataService.getCommonSetByName("OpportunisticDisease");
                             this.setFieldValues();
                           }
                         });
     }
  }

  refreshAfterSave() {
    this.setFieldValues();
  }  

  setFieldValues() {
    if (!this.clientData) return;
    
    // this.getAidsDefineConditionControl().reset();
    this.aidsDefineConditionsSelectedItems.length = 0;
    if (this.clientData?.clientMedicalOppDiseases?.length > 0) {
      this.clientData.clientMedicalOppDiseases
          .forEach( (aidsDefineConditionsSelectedItem) => {
              var item = this.aidsDefineConditions.find(x => x.id == aidsDefineConditionsSelectedItem.id);
              if (item != null) this.aidsDefineConditionsSelectedItems.push(item);
          });
      //shouldn't need to do this because of 2 way binding but have to since there seems to be a bug
      this.getAidsDefineConditionControl().setValue(this.aidsDefineConditionsSelectedItems);
    }
    this.cdRef.detectChanges();
    this.getAidsDefineConditionControl().markAsPristine();
  }

  getModelArray(): Partial<ClientMedicalOppDiseaseModel>[] {
    var modelArray: Partial<ClientMedicalOppDiseaseModel>[] = [];
    
    if (!this.clientData?.clientMedicalOppDiseases) return null;

    // since the multiselect control doesn't properly support the touch and dirty properties need to do a manual compare for changes
    //if (this.getAidsDefineConditionControl().touched && this.getAidsDefineConditionControl().dirty)
    var arr1 = this.clientData.clientMedicalOppDiseases.map(element => element.id);
    var arr2 = this.getAidsDefineConditionControl().value.map(element => element.id);
    if (!isEqual(arr1, arr2))
    {
      this.getAidsDefineConditionControl().value?.forEach(element => {
        // see if the item existed in the original set returned from the web server
        // var existingOppDisease = this.clientData.clientMedicalOppDiseases?.find(x => x.id == element.id);
        var model: Partial<ClientMedicalOppDiseaseModel> = new ClientMedicalCommon(this.userService.userID); //ClientMedicalOppDisease(this.medical.value);
        model.clientGuid = this.clientService.clientData?.guid;
        model.setguid = ClientFormHelper.EmptyGuid(); //set on server side
        model.id = element.id;
        model.datetime = new Date().toISOString(); //set on server side, since we always add new entries always use today's date if entries already exist for today's date then delete and re-add them
        // moved the below into base class ClientMedicalCommon
        // model.guid = ClientFormHelper.EmptyGuid(); //set on server side
        // model.deleted = false;
        // model.userId = this.userService.userID;
        // model.lastUpdate = ClientFormHelper.nullDate(); //set on server side
        // model.expired = ClientFormHelper.nullDate(); //set on server side

        modelArray.push(model);
      });

      // if nothing at all selected then select and show NONE
      if (modelArray.length == 0) {
        // this.clientData.clientMedicalOppDiseases
        modelArray.push(this.aidsDefineConditions.find(x => x.id == 0));
        this.getAidsDefineConditionControl().setValue(modelArray);
      }

      return modelArray;
    }

    return null;
  }

  openHistoryWindow(code, title) {
    this.histComp.clientGuid = this.clientData?.guid;
    this.histComp.clientAltID = this.clientData?.clientAltId;
    this.histComp.dataElement = code;
    this.histComp.title = title;
    this.histComp.showHistory(); 
  }

  onAidsDefineConditionItemSelect(aidsDefineConditionItem:any) {
    //console.log(aidsDefineConditionItem);
    if (aidsDefineConditionItem.id == this.NONE) {
      this.aidsDefineConditionsSelectedItems.length = 0;
      this.aidsDefineConditionsSelectedItems.push(this.aidsDefineConditions.find(x => x.id == 0));
      this.getAidsDefineConditionControl().setValue(this.aidsDefineConditionsSelectedItems);
      this.aidsDefineConditionDropDownSettings = Object.assign({}, this.aidsDefineConditionDropDownSettings, { limitSelection: 1 });
    }
    //this.aidsDefineConditionsSelectedItems.push(aidsDefineConditionItem);
  }

  onAidsDefineConditionSelectAll(aidsDefineConditionItems:any) {
    //this.aidsDefineConditionsSelectedItems.length = 0;
    //this.aidsDefineConditionsSelectedItems = aidsDefineConditionItems;
  }

  onAidsDefineConditionItemDeSelect(aidsDefineConditionItem:any) {
    //console.log(aidsDefineConditionItem);
    if (aidsDefineConditionItem.id == this.NONE) {
      this.aidsDefineConditionDropDownSettings = Object.assign({}, this.aidsDefineConditionDropDownSettings, { limitSelection: 4 });
    }    
    //const index = this.aidsDefineConditionsSelectedItems.findIndex(x => x.id == aidsDefineConditionItem.id);
    //this.aidsDefineConditionsSelectedItems.splice(index, 1);
  }

  onAidsDefineConditionDeSelectAll(aidsDefineConditionItems:any){
    //this.aidsDefineConditionItems.length = 0;
  }  
}
