import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { format, parseISO, addDays, formatISO } from 'date-fns';
import { ClientFormHelper } from 'src/app/client-form/client-form.helper';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { DateHelper } from "src/app/common/date-helper";
import { ClientModel, ClientMedicalHepatitisBModel, ClientMedicalHepatitisB, ClientMedicalCommon } from 'src/app/models/client.model';
import { CommonData } from 'src/app/models/common-data.model';
import { MedicalYesNo, MasterFields, PosNegResults } from "src/app/models/enums";
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../../history/history.component';
import { HelperClassService } from '../../../../services/helper-class.service';
import { noop } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-hepatitis-b',
  templateUrl: './hepatitis-b.component.html',
  styleUrls: ['./hepatitis-b.component.scss']
})
export class HepatitisBComponent implements OnInit {
  @Input() medical: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  public loaded: boolean = false;
  clientData: ClientModel;
  clientGUID: string;
  YesnoMedical: CommonData[];
  noReasons: CommonData[];
  MedicalYesNo = MedicalYesNo;
  PosNegResults = PosNegResults;
  maxScreenedDate = new Date();
  minScreenedDate = new Date();

  getHepatitisBScreenedControl = () => this.medical?.get('idHepBScreened');
  getHepatitisBScreenedNoReasonControl = () => this.medical?.get('idHepBScreenedNoReason');
  getHepatitisBResultDateControl = () => this.medical?.get('idHepBScreenedDate');
  getHepatitisBLastResultControl = () => this.medical?.get('idHepBPositive');
  getHepatitisBLastestResultUnavailableControl = () => this.medical?.get('idHepBUnavailable');

  showHepatitisBNoReason = () => (this.getHepatitisBScreenedControl()?.value ?? 0) == MedicalYesNo.No; // this.providerService.isFieldRequired(MasterFields.HepatitisBScreenedNoReason) --Den wanted this type of lookup removed 1/6/22
  showHepatitisBResultFields = () => (this.getHepatitisBScreenedControl()?.value ?? 0) == MedicalYesNo.Yes;
  showHepatitisBUnavailable = () => { 
                                      // the order of the logic must be preserved for accuracy
                                      if (this.clientData?.clientMedicalHepatitisB == null) return true; // if no rows in table for this client then allow Unavailable to show
                                      if (this.clientData?.clientMedicalHepatitisB?.positive) return false;
                                      if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalHepatitisB?.lastUpdate, true), (this.app.requiredFields30DayGracePeriodReset * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again    
                                      if (!this.clientData?.clientMedicalHepatitisB?.unavailable) return false;
                                      if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalHepatitisB?.lastUpdate, true), this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
                                      return false;                                      
                                    }
  isHepatitisBUnavailable = () => (this.getHepatitisBLastestResultUnavailableControl()?.value ?? false);

  constructor(
    private fb: UntypedFormBuilder, 
    private commonDataService: CommonDataService,
    public providerService: ProviderService,
    private userService: UserService,
    public clientService: ClientFormService,
    private datePipe: DatePipe,
    private dateHelper: DateHelper,
    public  validationHelper: FormValidations,
    private helperService: HelperClassService,
    private medcommon: MedicalCommon,
    private app: AppContextService
    ) { 
    
    }

  ngOnInit(): void {
     this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
     this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate);
     this.YesnoMedical = this.commonDataService.getCommonSetByName("YesnoMedical", true);
     this.noReasons =  this.YesnoMedical.filter(x => x.id == 0 || x.id > 2);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { 
                          if (!!result) {
                            this.clientData = this.clientService?.clientData; 
                            this.refreshAfterSave();
                          }
                        },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        // complete: () => { 
                        //   this.clientData = this.clientService?.clientData; 
                        //   this.refreshAfterSave(); 
                        // }
                      });

     if (this.clientService.newclient)
     {
       this.medical.markAllAsTouched();
     }
     else
     {
        this.clientService.$clientGet.subscribe({
                            next: (result) => { },
                            error: (error) => { },
                            complete: () => {
                              this.clientData = this.clientService.clientData;
                              this.setFieldValues();
                            }
                          });
      }
      if (!this.clientService.readonly) {
        this.validationHelper.AddRequiredMinMaxValidator(this.getHepatitisBScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.HepatitisBScreened))
        this.getHepatitisBScreenedControl().valueChanges.subscribe(value => { this.setScreened();  });
        this.getHepatitisBLastestResultUnavailableControl().valueChanges.subscribe(value => { this.setUnavailable(value); });
      }
  }

  refreshAfterSave() {
    this.setFieldValues();
  }  

	getModel(): Partial<ClientMedicalHepatitisBModel> {
	  var model: Partial<ClientMedicalHepatitisBModel> = new ClientMedicalCommon(this.userService.userID); //ClientMedicalHepatitisB(this.medical.value);

    if (this.getHepatitisBScreenedControl().dirty || this.getHepatitisBLastestResultUnavailableControl().dirty || this.getHepatitisBScreenedNoReasonControl().dirty ||
        this.getHepatitisBResultDateControl().dirty || this.getHepatitisBLastResultControl().dirty)
    {
      let date_screened = (this.isHepatitisBUnavailable() || !this.getHepatitisBResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getHepatitisBResultDateControl().value, { representation: 'date' });

      model.screened = this.getHepatitisBScreenedControl()?.value;
      model.datetime = date_screened;
      model.positive = this.getHepatitisBLastResultControl()?.value ?? 0;
      model.unavailable = this.getHepatitisBLastestResultUnavailableControl()?.value ?? false;
      model.noReason = this.getHepatitisBScreenedNoReasonControl()?.value ?? 0;
      // moved the below into base class ClientMedicalCommon
      // model.guid = ClientFormHelper.EmptyGuid();
      // model.deleted = false;
      // model.userId = this.userService.userID;
      // model.lastUpdate = ClientFormHelper.nullDate();
      // model.expired = null;

      return model;
    } 

    return null;
	}

  setUnavailable(unavailable) {
    //let unavailable = this.medical.get('idHepBUnavailable').value;
    if (unavailable) {
      this.validationHelper.RemoveValidators(this.getHepatitisBScreenedControl(), true);
      this.validationHelper.RemoveValidators(this.getHepatitisBScreenedNoReasonControl(), true);
      this.validationHelper.RemoveValidators(this.getHepatitisBResultDateControl(), true);
      this.validationHelper.RemoveValidators(this.getHepatitisBLastResultControl(), true);
    }
    else 
    {
      this.validationHelper.AddRequiredMinMaxValidator(this.getHepatitisBScreenedControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.HepatitisBScreened))
      this.setScreened();
    }
  }

  setScreened() {

    if (!this.clientService.readonly){
        if (this.getHepatitisBScreenedControl()?.value == this.MedicalYesNo.Yes) {
          this.validationHelper.RemoveValidators(this.getHepatitisBScreenedNoReasonControl(), true);
          this.validationHelper.AddValidators(this.getHepatitisBResultDateControl(), [Validators.required, this.validationHelper.dateFutureDateValidator()]); // if (this.providerService.isFieldRequired(MasterFields.HepatitisBResultDate))
          this.validationHelper.AddRequiredMinMaxValidator(this.getHepatitisBLastResultControl(), this.MedicalYesNo.Yes, this.MedicalYesNo.No); // if (this.providerService.isFieldRequired(MasterFields.HepatitisBLastResult))
        }
        else if (this.getHepatitisBScreenedControl()?.value == this.MedicalYesNo.No) {
          this.validationHelper.AddValidators(this.getHepatitisBScreenedNoReasonControl(), this.validationHelper.dropdownValidator()); // if (this.providerService.isFieldRequired(MasterFields.HepatitisBScreenedNoReason))
          this.validationHelper.RemoveValidators(this.getHepatitisBResultDateControl(), true);
          this.validationHelper.RemoveValidators(this.getHepatitisBLastResultControl(), true);
          this.getHepatitisBResultDateControl().setValue(null);
        }
    }

  }

  setFieldValues() {
    if (!this.clientData) return;
    
    if (this.clientData.clientMedicalHepatitisB?.positive == PosNegResults.Positive) this.setUnavailable(true);

    if (!this.helperService.isExpired(this.clientData?.clientMedicalHepatitisB?.expired)) {
      if (this.clientData.clientMedicalHepatitisB?.unavailable != null)
        this.getHepatitisBLastestResultUnavailableControl().setValue(this.clientData.clientMedicalHepatitisB?.unavailable);

      if (this.clientData.clientMedicalHepatitisB?.screened != null)
        this.getHepatitisBScreenedControl().setValue(this.clientData.clientMedicalHepatitisB?.screened);

      if (this.clientData.clientMedicalHepatitisB?.noReason != null)
        this.getHepatitisBScreenedNoReasonControl().setValue(this.clientData.clientMedicalHepatitisB?.noReason);

      if (this.clientData.clientMedicalHepatitisB?.datetime != null)
        this.getHepatitisBResultDateControl().setValue(this.helperService.getISODate(this.clientData.clientMedicalHepatitisB?.datetime));      
      
      if (this.clientData.clientMedicalHepatitisB?.positive != null)
        this.getHepatitisBLastResultControl().setValue(this.clientData.clientMedicalHepatitisB?.positive);
    }
  }

    openHistoryWindow(code, title) {
      this.histComp.clientGuid = this.clientData?.guid;
      this.histComp.clientAltID = this.clientData?.clientAltId;
      this.histComp.dataElement = code;
      this.histComp.title = title;
      this.histComp.showHistory(); 
   }
}
