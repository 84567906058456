import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClientModel, ClientMedicalCommon, ClientScreeningMentalHealthModel } from 'src/app/models/client.model';
import { UserService } from 'src/app/services/user.service';
import { CommonDataService } from 'src/app/services/common-data.service';
import { ProviderService } from 'src/app/services/provider.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { HelperClassService } from '../../../../services/helper-class.service';
import { MedicalCommon } from 'src/app/client-form/tabs/medical/medical-common';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { HistoryComponent } from '../../../history/history.component';
import { MedicalYesNo, MasterFields, PosNegResults } from "src/app/models/enums";
import { format, parseISO, addDays, formatISO } from 'date-fns';

@Component({
  selector: 'app-mental-health',
  templateUrl: './mental-health.component.html',
  styleUrls: ['./mental-health.component.scss']
})
export class MentalHealthComponent implements OnInit {
  @Input() behavior_health: UntypedFormGroup;
  @ViewChild(HistoryComponent, {static: false}) histComp: HistoryComponent;
  public get MasterFields(): typeof MasterFields { return MasterFields; } // need to do this to expose the enum to the HTML file

  clientData: ClientModel;
  clientGUID: string;
  MedicalYesNo = MedicalYesNo;
  maxResultDate = new Date();
  minResultDate = new Date();

  getMentalHealthResultDateControl = () => this.behavior_health?.get('idMHResultDate');
  getMentalHealthResultControl = () => this.behavior_health?.get('idMHResult');
  getMentalHealthDiagnosisControl = () => this.behavior_health?.get('idMHDiagnosis');
  getMentalHealthFollowupControl = () => this.behavior_health?.get('idMHFollowup');

  disableMentalHealthDiagnosisFields = () => (this.getMentalHealthResultControl()?.value ?? 0) == this.MedicalYesNo.No;
  // showMentalHealthFollowupFields = () => (this.getMentalHealthDiagnosisControl()?.value ?? 0) == this.MedicalYesNo.Yes; this is not dependant on the diagnosis selection
  
  constructor(private fb: UntypedFormBuilder,
              private clientService: ClientFormService,
              private commonDataService: CommonDataService,
              public providerService: ProviderService,
              private userService: UserService,
              public  validationHelper: FormValidations,
              private helperService: HelperClassService,
              private medcommon: MedicalCommon) { 

  }

  ngOnInit(): void {
    this.minResultDate = this.medcommon.getMinScreenedDate(this.minResultDate, 720);
    this.maxResultDate = this.medcommon.getMaxScreenedDate(this.maxResultDate, 0);

    // notification for savings client changes
    this.clientService.$clientPost.subscribe( {
                        next: (result) => { this.clientData = result; },
                        error: (error) =>  { this.userService.LogError(error); }, 
                        // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
                        complete: () => { this.refreshAfterSave(); } }); 

    if (this.clientService.newclient)
    {
      this.behavior_health.markAllAsTouched();
    }
    else
    {
       this.clientService.$clientGet.subscribe({
                           next: (result) => { },
                           error: (error) => { },
                           complete: () => {
                             this.clientData = this.clientService.clientData;
                             this.setFieldValues();
                           }
                         });
     }

     this.getMentalHealthResultDateControl().valueChanges.subscribe(value => { this.resetFieldValues(value); });
     this.getMentalHealthResultControl().valueChanges.subscribe(value => { this.checkResult(value); });
  }

  checkResult(value) {
    if (value == this.MedicalYesNo.No) {
      this.getMentalHealthDiagnosisControl().setValue(null);
      this.getMentalHealthFollowupControl().setValue(null);
    }
  }

  resetFieldValues(value) {
    this.getMentalHealthResultControl().setValue(null);
    this.getMentalHealthDiagnosisControl().setValue(null);
    this.getMentalHealthFollowupControl().setValue(null);
  }

  refreshAfterSave() {
    this.setFieldValues();
  }

  setFieldValues() { 
    if (!this.helperService.isExpired(this.clientData?.clientScreeningMentalHealth?.expired)) {
      if (this.clientData?.clientScreeningMentalHealth?.screeningDate != null)
        this.getMentalHealthResultDateControl().setValue(parseISO(this.clientData.clientScreeningMentalHealth?.screeningDate));      
      
      if (this.clientData?.clientScreeningMentalHealth?.result != null)
        this.getMentalHealthResultControl().setValue(this.clientData.clientScreeningMentalHealth?.result);

      if (this.clientData?.clientScreeningMentalHealth?.diagnosis != null)
        this.getMentalHealthDiagnosisControl().setValue(this.clientData.clientScreeningMentalHealth?.diagnosis);
        
      if (this.clientData?.clientScreeningMentalHealth?.followup != null)
        this.getMentalHealthFollowupControl().setValue(this.clientData.clientScreeningMentalHealth?.followup);        
    }
  }  

	getModel(): Partial<ClientScreeningMentalHealthModel> {
	  var model: Partial<ClientScreeningMentalHealthModel> = new ClientMedicalCommon(this.userService.userID); //ClientMedicalCd4(this.medical.value);

    if (this.getMentalHealthResultDateControl().dirty || this.getMentalHealthResultControl().dirty ||
        this.getMentalHealthDiagnosisControl().dirty || this.getMentalHealthFollowupControl().dirty)
    {
      let date_screened = (!this.getMentalHealthResultDateControl().value) ? formatISO(new Date(), { representation: 'date' }) : formatISO(this.getMentalHealthResultDateControl().value, { representation: 'date' });

      model.screeningDate = date_screened;
      model.result = this.getMentalHealthResultControl()?.value ?? 0;
      model.diagnosis = this.getMentalHealthDiagnosisControl()?.value ?? 0;
      model.followup = this.getMentalHealthFollowupControl()?.value ?? 0;

      return model;
    }

    return null;
	}

  openHistoryWindow(code, title) {
    this.histComp.clientGuid = this.clientData.guid;
    this.histComp.clientAltID = this.clientData.clientAltId;
    this.histComp.dataElement = code;
    this.histComp.title = title;
    this.histComp.showHistory(); 
  }  

}
