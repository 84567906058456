<div class="topbar container-fluid" >
        <div class="row topbar-main ">
            <div class="col-2">
                <a href="#" id="menu-button" (click)="app.onMenuButtonClick($event)">
                    <span class="pi pi-bars"></span>
                </a>

                <a href="/">
                    <img alt="main logo" src="assets/layout/images/ribbon-6-32.png" class="logo">
                </a>

                <div class="app-name">
                    <span style="color: white; font-size:xx-large;">CHAMP</span>  <!--  font-family:'cursive';-->
                </div>
            </div>

            <div class="col-5">
                 <app-provider-info ></app-provider-info> 
            </div>
            
            <div class="col-5"  *ngIf="user" > <!-- *ngIf="(userService.userInfo$ | async) as user"-->
                <a href="#" id="user-display" >   <!--   (click)="app.onTopbarMenuButtonClick($event)"    Disabled for now -->
                    <span class="username">{{ user.user.firstName + " " + user.user.lastName}}</span>
                    <span class="userrole">{{ user.user.name }}</span>
                    <!-- <img alt="main logo" src="assets/layout/images/avatar.png"> -->
                </a>
            
                <ul id="topbar-menu" class="fadeInDown animated" [ngClass]="{'topbar-menu-visible': app.topbarMenuActive}" >
                    <li class="search-item">
                        <div>
                            <input type="text" pInputText placeholder="Search"  />
                            <span class="pi pi-search"></span>
                        </div>
                    </li>

                    <li #profile [ngClass]="{'menuitem-active':app.activeTopbarItem === profile}">
                        <a href="#" (click)="app.onTopbarItemClick($event, profile)" dsi>
                            <i class="topbar-icon pi pi-fw pi-user"></i>
                            <span class="topbar-item-name">PROFILE</span>
                        </a>
                        <ul>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-user"></i>
                                    <span>Profile</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-lock"></i>
                                    <span>Privacy</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-cog"></i>
                                    <span>Settings</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-sign-out"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li #settings [ngClass]="{'menuitem-active':app.activeTopbarItem === settings}">
                        <a href="#" (click)="app.onTopbarItemClick($event, settings)">
                            <i class="topbar-icon pi pi-fw pi-cog"></i>
                            <span class="topbar-item-name">SETTINGS</span>
                        </a>
                        <ul>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-palette"></i>
                                    <span>Change Theme</span>
                                    <span class="topbar-submenuitem-badge">4</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-star"></i>
                                    <span>Favorites</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-lock"></i>
                                    <span>Lock Screen</span>
                                    <span class="topbar-submenuitem-badge">2</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-image"></i>
                                    <span>Wallpaper</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li #messages [ngClass]="{'menuitem-active':app.activeTopbarItem === messages}">
                        <a href="#" (click)="app.onTopbarItemClick($event, messages)">
                            <i class="topbar-icon material-icons animated swing pi pi-fw pi-envelope"></i>
                            <span class="topbar-item-name">MESSAGES</span>
                            <span class="topbar-badge animated rubberBand">4</span>
                        </a>
                        <ul>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img alt="avatar 1" src="assets/layout/images/avatar1.png" width="35">
                                    <span>Give me a call</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img alt="avatar 2" src="assets/layout/images/avatar2.png" width="35">
                                    <span>Reports attached</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img alt="avatar 3" src="assets/layout/images/avatar3.png" width="35">
                                    <span>About your invoice</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img alt="avatar 2" src="assets/layout/images/avatar2.png" width="35">
                                    <span>Meeting today</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" class="topbar-message" (click)="app.onTopbarSubItemClick($event)">
                                    <img alt="avatar 4" src="assets/layout/images/avatar4.png" width="35">
                                    <span>Out of office</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                    <li #notifications [ngClass]="{'menuitem-active':app.activeTopbarItem === notifications}">
                        <a href="#" (click)="app.onTopbarItemClick($event, notifications)">
                            <i class="topbar-icon pi pi-fw pi-bell"></i>
                            <span class="topbar-item-name">NOTIFICATIONS</span>
                            <span class="topbar-badge animated rubberBand">2</span>
                        </a>
                        <ul>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-sliders-h"></i>
                                    <span>Pending tasks</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-calendar"></i>
                                    <span>Meeting today</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-download"></i>
                                    <span>Download</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="#" (click)="app.onTopbarSubItemClick($event)">
                                    <i class="pi pi-fw pi-ticket"></i>
                                    <span>Book flight</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>


                <p-button label="New Referrals Waiting"  badge="{{this.referralCount}}" styleClass="p-button-secondary mr-2" badgeClass="p-badge-danger"  (onClick)="onLoadClientReferrals()" ></p-button>
                <p-button label="Client Status Follow-ups" icon="pi pi-users" styleClass="p-button-warning" badge="{{this.clientLookupCount}}" badgeClass="p-badge-danger" (onClick)="onLoadClientStatusLookup()"></p-button>

            </div>
        </div>
       

        <app-menu></app-menu>
        

    </div>
