<p-scrollPanel [style]="{width: '99.2%', padding: '0', margin: '.5rem'}">
    <ng-container *ngIf="(existingClients$ | async) as existingClients"> <!-- else loading -->
        <p-table name="tblExistingClients" [value]="existingClients" [rowHover]="true" [style]="{'width':'99.9%'}" styleClass="p-datatable-sm p-datatable-striped"> <!-- [scrollable]="true" scrollHeight="flex" -->
            <ng-template pTemplate="header">
                <tr>
                    <th style="min-width:25px;max-width:85px;text-align:left"></th>
                    <th style="min-width:105px; max-width:105px" pSortableColumn="clientAltId">Unique Id<p-sortIcon field="clientAltId"></p-sortIcon></th>
                    <th style="min-width:100px; max-width:100px" pSortableColumn="firstName">First Name<p-sortIcon field="firstName"></p-sortIcon></th>
                    <th style="min-width:115px; max-width:115px" pSortableColumn="middleName">Middle Name<p-sortIcon field="middleName"></p-sortIcon></th>
                    <th style="min-width:100px; max-width:100px" pSortableColumn="lastName">Last Name<p-sortIcon field="lastName"></p-sortIcon></th>
                    <th style="min-width:80px; max-width:80px" pSortableColumn="gender">Gender<p-sortIcon field="gender"></p-sortIcon></th>
                    <th style="min-width:65px; max-width:65px" pSortableColumn="ssn">SSN<p-sortIcon field="ssn"></p-sortIcon></th>
                    <th style="min-width:100px; max-width:100px" pSortableColumn="birthDate">Birth Date<p-sortIcon field="birthDate"></p-sortIcon></th>
                    <th style="min-width:120px; max-width:120px" pSortableColumn="streetAddress">Street Address<p-sortIcon field="streetAddress"></p-sortIcon></th>
                    <th style="min-width:100px; max-width:100px" pSortableColumn="city">City<p-sortIcon field="city"></p-sortIcon></th>
                    <th style="min-width:70px; max-width:70px" pSortableColumn="state">State<p-sortIcon field="state"></p-sortIcon></th>
                    <th style="min-width:85px; max-width:85px" pSortableColumn="zipCode">Zip Code<p-sortIcon field="zipCode"></p-sortIcon></th>
                    <th style="min-width:80px; max-width:80px" pSortableColumn="phoneNumber">Phone #<p-sortIcon field="phoneNumber"></p-sortIcon></th>
                    <th style="min-width:115px; max-width:115px" pSortableColumn="emailAddress">Email Address<p-sortIcon field="emailAddress"></p-sortIcon></th>
                    <th style="min-width:105px; max-width:105px" pSortableColumn="matchValue">Match Value<p-sortIcon field="matchValue"></p-sortIcon></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-existingClient>
                <tr>
                    <td style="min-width:25px;max-width:85px;text-align:left">
                        <button title="Open Client" pButton pRipple icon="pi pi-book" class="p-button-rounded p-button-text" (click)="selectClient(existingClient)"></button> <!--  style="color: limegreen; background-color: white; border-color: white"-->
                    </td>                    
                    <td style="min-width:105px; max-width:105px"><span title="{{existingClient.clientAltId}}">{{existingClient.clientAltId}}</span></td>
                    <td style="min-width:100px; max-width:100px"><span title="{{existingClient.firstName}}">{{existingClient.firstName}}</span></td>
                    <td style="min-width:115px; max-width:115px"><span title="{{existingClient.middleName}}">{{existingClient.middleName}}</span></td>
                    <td style="min-width:100px; max-width:100px"><span title="{{existingClient.lastName}}">{{existingClient.lastName}}</span></td>
                    <td style="min-width:80px; max-width:80px"><span title="{{existingClient.gender}}">{{existingClient.gender}}</span></td>
                    <td style="min-width:65px; max-width:65px"><span title="{{existingClient.ssn}}">{{existingClient.ssn}}</span></td>
                    <td style="min-width:100px; max-width:100px"><span title="{{existingClient.birthDate}}">{{existingClient.birthDate}}</span></td>
                    <td style="min-width:120px; max-width:120px"><span title="{{existingClient.streetAddress}}">{{existingClient.streetAddress}}</span></td>
                    <td style="min-width:100px; max-width:100px"><span title="{{existingClient.city}}">{{existingClient.city}}</span></td>
                    <td style="min-width:70px; max-width:70px"><span title="{{existingClient.state}}">{{existingClient.state}}</span></td>
                    <td style="min-width:85px; max-width:85px"><span title="{{existingClient.zipCode}}">{{existingClient.zipCode}}</span></td>
                    <td style="min-width:80px; max-width:80px"><span title="{{existingClient.phoneNumber}}">{{existingClient.phoneNumber}}</span></td>
                    <td style="min-width:115px; max-width:115px"><span title="{{existingClient.emailAddress}}">{{existingClient.emailAddress}}</span></td>
                    <td style="min-width:105px; max-width:105px"><span title="{{existingClient.matchValue}}">{{existingClient.matchValue}}</span></td>
                </tr>
            </ng-template>
        </p-table>
    </ng-container>
</p-scrollPanel>