import { Injectable } from '@angular/core';
import { AdminUserModel } from 'src/app/models/admin.models';

@Injectable({
  providedIn: 'root'
})
export class UserProviderClinicPermService {
  visible: boolean = false; 
  key:string = '';
  user:Partial<AdminUserModel>;

  constructor() { }
}
