import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { addDays, getMonth, parseISO } from 'date-fns';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Observable, lastValueFrom, shareReplay } from 'rxjs';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { DirectServiceComponent } from 'src/app/client-direct-services/direct-service/direct-service.component';
import { RiskReductionComponent } from 'src/app/client-direct-services/risk-reduction/risk-reduction.component';
import { NonRWVisitComponent } from 'src/app/client-form/tabs/mcm/non-rwvisit/non-rwvisit.component';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { ClientModel } from 'src/app/models/client.model';
import { DirectServiceFilterSelectionChoices, MCMFilterSelectionChoices, MasterFields } from "src/app/models/enums";
import { MCMListRequestModel, MCMListResponseListItem, MCMListResponseModel } from 'src/app/models/mcm.models';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { UserService } from 'src/app/services/user.service';
import { HistoryComponent } from '../../history/history.component';
import { ClientFormService } from '../../services/client-form.service';
import { MedicalCommon } from '../medical/medical-common';
import { MCMService } from './mcm.service';


@Component({
  selector: 'app-mcm',
  templateUrl: './mcm.component.html',
  styleUrls: ['./mcm.component.scss'],
  providers: [MessageService, ConfirmationService, DialogService]
})
export class McmComponent implements OnInit {

  @Input() mcm: UntypedFormGroup;
  
  @ViewChild(RiskReductionComponent) riskReductionSub: RiskReductionComponent;
  @ViewChild(NonRWVisitComponent) NonRWVisit: NonRWVisitComponent;
  @ViewChild(HistoryComponent, {static: false}) histComp:HistoryComponent;
  //clientData: MCMListRequestModel;

  mcmForm: UntypedFormGroup;
  clientData: ClientModel;
  mcmServices$: Observable<MCMListResponseModel>;
  mcmVisits: MCMListResponseListItem[];
  maxScreenedDate = new Date();
  minScreenedDate = new Date();
  public filterOption:number=3;

  showDeleted = false;
  deletedLabel:string  = "Show Deleted";

  displayNonWRMCMVisit:boolean= false;
  nonRWMCMVisitFormTitle:string = 'Non Ryan White Medical/Dental Visit'
  mcmModalRef: DynamicDialogRef;
  dsModalRef: DynamicDialogRef;

  public get MasterFields(): typeof MasterFields { return MasterFields; } 
  
  getMCMCarePlanDate = () => this.mcm?.get('idMCMCarePlanDate');
  getMCMCarePlanDateUnavailable = () => this.mcm?.get('idMCMCarePlanDateUnavailable');
  idMCMCarePlanServicesUnavailable = () => this.mcm?.get('idMCMCarePlanServicesUnavailable');
 
  getMCMVisitDate = () => this.mcm?.get('idMCMVisitDate');
 
  //form: UntypedFormGroup;
  months: any[] = this.helperService.getMonths();
  years: string[] = this.helperService.getYears();
  usingCustomDSListFiltering = () => { return (this.getFilterOption() == 2);}
  standardFilterSelections: any[] = this.mcmService.getStandardFilterSelections();

  getFilterOption = () => this.mcmForm?.get('filterOption')?.value ?? 1;
  setFilterSelection = (value) => this.mcmForm?.get('filterOption').setValue(value ?? 3);
  
  getMCMVisitsOptions = () => this.mcmForm?.get('mcmVisitsOption');
  getDateFilterSelection = () => this.mcmForm?.get('dateFilterSelection')?.value ?? DirectServiceFilterSelectionChoices.CurrentMonth;
  setDateFilterSelection = (value) => this.mcmForm?.get('dateFilterSelection').setValue(value ?? DirectServiceFilterSelectionChoices.CurrentMonth);

  showMCMVisitsUnavailable = () => { 
    if (this.mcmVisits == null) return true; // if no rows in table for this client then allow Unavailable to show
    if (addDays(this.helperService.castToDateWithNoTime(this.mcmVisits[0]?.visitDate, true), (365 * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again
    return false;
  }

  showMCMCarePlanUnavailable = () => { 
    // if (this.clientData?.clientMedicalCaseManagementCarePlan == null) return true; // if no rows in table for this client then allow Unavailable to show
    // if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalCaseManagementCarePlan.carePlanDate , true), (365 * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again
    // return false;
		//if (this.clientService.newclient || this.clientData?.clientMedicalCaseManagementCarePlan == null) return true; // if no rows in table for this client then allow Unavailable to show    
		//if (addDays(this.clientService.clientData.newClientDate, this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
		if (this.clientData?.clientMedicalCaseManagementCarePlan == null) return true; // if no rows in table for this client then allow Unavailable to show
		if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalCaseManagementCarePlan?.lastUpdate, true), (this.app.requiredFields30DayGracePeriodReset * 2)).getTime() <= new Date().getTime()) return true; // if 2 years lapsed then allow Unavailable to show again    
    if (!this.clientData?.clientMedicalCaseManagementCarePlan?.unavailable) return false;
		if (addDays(this.helperService.castToDateWithNoTime(this.clientData?.clientMedicalCaseManagementCarePlan?.lastUpdate, true), this.app.requiredFields30DayGracePeriod).getTime() >= new Date().getTime()) return true;
		return false;    
  }

  constructor(
              public app: AppContextService, 
              private fb: UntypedFormBuilder, 
              private mcmService:MCMService,
              private userService:UserService,
              public  clientService:ClientFormService,
              public  validationHelper:FormValidations,
              private medcommon:MedicalCommon,
              private helperService: HelperClassService,
              public  dialogService: DialogService,
              private messageService:MessageService,
              private confirmationService: ConfirmationService,
    ) {


      var currentMonth = (getMonth(new Date()) + 1).toString(); //this.months[0].code
      this.mcmForm = fb.group({
        month: [currentMonth],
        year: [this.years[0]],
        filterOption: [1],
        dateFilterSelection: [MCMFilterSelectionChoices.CurrentMonth],
        mcmVisitsOption:[3]

      },
      {
      });

  }
 
  async ngOnInit(): Promise<void> {
    this.minScreenedDate = this.medcommon.getMinScreenedDate(this.minScreenedDate);
    this.maxScreenedDate = this.medcommon.getMaxScreenedDate(this.maxScreenedDate);

    // await this.initializeData();

    var result = await lastValueFrom(this.userService.GetUserConfigValue('CHAMP.Registry.Filters.MCMStandardFilter'));
    if (result) this.setDateFilterSelection(result.keyValue);

  
    this.clientService.$clientPost.subscribe( {
      next: (result) => { 
        if (!!result) {
          this.clientData = this.clientService?.clientData; 
        //  this.refreshAfterSave();
        }
        },
      error: (error) =>  { this.userService.LogError(error); }, 
      // this is a callback for when after a Save occurs, do any necessary local object updates and screen refreshing here
      complete: () => { } 
    });  


   

    if (this.clientService.newclient)
    {
      this.mcm.markAllAsTouched();
    }
    else
    {
        this.clientService.$clientGet.subscribe({
                            next: (result) => { },
                            error: (error) => { },
                            complete: () => {
                              this.clientData = this.clientService.clientData;
                              this.setFieldValues();
                              this.getMCMVisitsOptions().setValue(3);
                              this.fetchGridData();
                            
                            }
                          });
      }
      if (!this.clientService.readonly) {
      }
      else{
      this.getMCMVisitsOptions().enable();
      }


    }


  async initializeData(){
    var result = await lastValueFrom(this.userService.GetUserConfigValue('CHAMP.Registry.Filters.MCMStandardFilter'));
    if (result) this.setDateFilterSelection(result.keyValue);


  }
  
  onChangeMonth(value) {
    this.fetchGridData();
  }

  onChangeYear(value) {
    this.fetchGridData();
  }

  onChangeFilterSelection(value) {
    this.userService.SaveUserConfigValue('CHAMP.Registry.Filters.MCMStandardFilter', value.toString());
    this.fetchGridData();
  }

  setFieldValues() {
    if (this.clientData?.clientMedicalCaseManagementCarePlan?.carePlanDate != undefined)
      this.getMCMCarePlanDate().setValue(parseISO(this.clientData?.clientMedicalCaseManagementCarePlan?.carePlanDate));

    this.getMCMCarePlanDateUnavailable().setValue(this.clientData?.clientMedicalCaseManagementCarePlan?.unavailable);

    if (!this.helperService.isExpired(this.clientData?.clientMedicalCaseManagementCarePlan?.carePlanDate)) {
    //  console.log("here");
    //   if (this.clientData.clientMedicalHepatitisB?.unavailable != null)
    //     this.getHepatitisBLastestResultUnavailableControl().setValue(this.clientData.clientMedicalHepatitisB?.unavailable);
    }

    if (this.clientData?.clientMedicalCaseManagement[0]?.visitDate)
      this.getMCMVisitDate().setValue(parseISO(this.clientData?.clientMedicalCaseManagement[0]?.visitDate));


    this.clientService.loadedMCM = true;
 
  }

  onOpenMCMVisit(mcm:any){
      this.openAddRWVisitModal(mcm);
  }

  onOpenDirectService(ds:any){

    this.openDirectServiceModal(ds);

  }

  openAddRWVisitModal(mcm:any){

      //console.log(mcm);
      this.mcmModalRef = this.dialogService.open(NonRWVisitComponent, {
        header: 'Add non Ryan White Visit',
        width: '50%',
        height:'30rem',
        contentStyle: {"max-height": "31rem", "overflow": "visible"},
        data: {
            clientGuid:this.clientService.clientData.guid,
            guid:mcm?.guid
        }
      });

      this.app.overLayMangr.add(this.mcmModalRef, 'dialogRef');

    //   if (this.clientData.clientMedicalHepatitisB?.noReason != null)
    //     this.getHepatitisBScreenedNoReasonControl().setValue(this.clientData.clientMedicalHepatitisB?.noReason);

      this.mcmModalRef.onClose.subscribe(
        {
          next:  async (result) => {
            this.fetchGridData();
            this.app.overLayMangr.remove(this.mcmModalRef);
          },
          error:(err) => {
            this.helperService.displayError(this.messageService, "Fatal error occurred when loading a non RW Visit. Please contact CHAMP support:", err, true);
          },
          complete:()=> {
          }

      }
    
    );

  }


  openDirectServiceModal(ds:any){

    //console.log(mcm);
    this.dsModalRef = this.dialogService.open(DirectServiceComponent, {
      header: 'Direct Service Information',
      width: '60rem',
      //height:'40 rem',
      //contentStyle: {"max-height": "31rem", "overflow": "visible"},
      data: {
          clientGuid:this.clientService.clientData.guid,
          guid:ds?.guid
      }
    });

    this.app.overLayMangr.add(this.dsModalRef, 'dialogRef');

    this.dsModalRef.onClose.subscribe(
      {
        next:  async (result) => {
          this.fetchGridData();
          this.app.overLayMangr.remove(this.dsModalRef);
        },
        error:(err) => {
          this.helperService.displayError(this.messageService, "Fatal error occurred when loading a non RW Visit. Please contact CHAMP support:", err, true);
        },
        complete:()=> {
        }

    } );

}

  fetchGridData( ){
    const request: MCMListRequestModel = {
       providerID :this.userService.CurrentProviderID,
       clientGuid: this.clientService.clientData.guid , 
       clientAltId: this.clientService.clientData?.clientAltId,
       month: Number(this.mcmForm?.get('month')?.value ?? 0),
       year:Number( this.mcmForm?.get('year')?.value ?? 0),
       mCMVisitsOption:  this.getMCMVisitsOptions().value ?? 1 ,
       filterSelectionChoice:  Number(this.getFilterOption() ),
       dateSelectionChoice:MCMFilterSelectionChoices.MonthAndYear,
       showDeleted:this.showDeleted
       
    };

    // if standard filter option was selected then set the correct option to pass to the API call
    if (!this.usingCustomDSListFiltering()) {
      request.dateSelectionChoice = Number(this.getDateFilterSelection());
      // also save config setting to DB
    }
    else {
      if (isNaN(request.month)) { alert("Cannot refresh MCM grid, Invalid month"); return; }
      if (isNaN(request.year)) { alert("Cannot refresh MCM grid, Invalid year"); return; }
    }

    this.mcmServices$ = this.mcmService.getMCMServices(request).pipe(shareReplay());;
    this.mcmServices$.subscribe({
      next: (result:MCMListResponseModel) => { 
        this.mcmVisits = result.mcmServices;
        if ( this.mcmVisits?.length > 0  ) 
            this.getMCMVisitDate().setValue(parseISO(result.lastPMVisitDate));
            //console.log(this.mcmVisits[0]?.visitDate,  this.getMCMVisitDate().value);
            this.getMCMVisitDate().markAsPristine();
      },
      error: (error) =>  { 
        this.helperService.displayError(this.messageService, "Fatal error occurred when loading a non RW Visit. Please contact CHAMP support:", error, true);
      }, 
      complete: () => {  }
       });  
  }

  onChangeFilterOption(option){
    this.fetchGridData();
    this.getMCMVisitsOptions().markAsPristine();
  }

  openHistoryWindow(code, title) {
    this.histComp.clientGuid = this.clientData?.guid;
    this.histComp.clientAltID = this.clientData?.clientAltId;
    this.histComp.dataElement = code;
    this.histComp.title = title;
    this.histComp.showHistory(); 
 }

 public showAddNonRWVisitDialog(){
  
    this.openAddRWVisitModal(null);

 }

  onRiskReductionDialogCompletedNotification(value) {
    // var riskReductionNotificationMessage = JSON.parse(value);
    // if (riskReductionNotificationMessage?.dialogCompleted && riskReductionNotificationMessage?.validRiskReduction) {
    //   var riskReductionModel = this.riskReductionSub.getRiskReductionModel();
    //   this.directService.clientMedicalRiskReduction = riskReductionModel;
    //   this.onSaveDirectServiceChangesWithMessaging();
    //   //this.riskReductionSub.mainForm.getRawValue()
    //   //Object.assign(this.directService.clientMedicalRiskReduction, riskReductionModel);
    // }
  }

  async onClickShowRiskReduction() {
    // met with Den on 10/27/21 and he wanted any hyperlinks to show risk reduction
    // to require a risk reduction entry to exist for the given direct service in order to display
    // only when a forced save occurs from the save button do we check client risk reduction history


    var directService =  await lastValueFrom( this.mcmService.getLatestDirectServiceWithRiskReduction(this.clientData.guid) );
    if (directService ) {
      this.riskReductionSub.onShowDialog(directService, false, false);
    }
    
    // if (!!selectedDirectService.clientMedicalRiskReduction) {
    //   this.riskReductionSub.onShowDialog(selectedDirectService, false, false);
    // }
    // else {
    //   this.messageService.add({severity: "error", summary: "Aborted", detail: "Currently, no Counselings and Included Screenings entries exist for selected direct service!", life: 2000});
    // }

  }

  onDeleteMCMVisit(mcm:any){
    this.confirmationService.confirm({
      target: event.target,
      message: 'Are you sure you want to delete the Non Ryan-White Visit?',
      header: 'Confirm',
      icon: 'pi pi-info-circle',
      defaultFocus:'reject',
      acceptLabel:'No',
      rejectLabel:'Yes',
      accept: () => {
        this.messageService.add({severity: "error", summary: "Aborted", detail: "Delete canceled", life: this.app.messageDisplayTime});
      },
      reject: () => {
          this.deleteNonRWVisits(mcm, false);
          }
      });
  }

  onUndeleteMCMVisit(mcm:any){
     this.deleteNonRWVisits(mcm, true);
  }

  deleteNonRWVisits(mcm, undelete){
    this.mcmService.deleteNonRWVisit(mcm, undelete).subscribe(
    {
          next: (result: any) => {
            if (result){
              this.messageService.add({severity:'success', summary: 'Successful', detail: 'No Ryan White Visited Deleted', life: this.app.messageDisplayTime});
              this.fetchGridData();
            }
            else 
              this.messageService.add({severity: "error", summary: "Error", detail: "Delete failed  ", life: this.app.messageDisplayTime});
          },
          error: (error) => {
            this.app.userServ.LogError(error);
            this.messageService.add({severity: "error", summary: "Error", detail: "Delete failed  " + error.error , life: this.app.messageDisplayTime});
          },
          complete:() => {
          }   
        });
    }

    showDeletedClicked(){
      this.showDeleted = !this.showDeleted;
      this.fetchGridData();
     
      if (this.showDeleted )
        this.deletedLabel = "Hide Deleted";
      else
        this.deletedLabel = "Show Deleted";  
    }

}
