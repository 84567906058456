import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonDataService } from 'src/app/services/common-data.service';
import { CommonDataModel, CommonData, ServiceCategory, SubType, ServiceSubType } from 'src/app/models/common-data.model';
import { ClientModel } from 'src/app/models/client.model';
// import { Component, OnInit, ViewEncapsulation, isDevMode, Input  } from '@angular/core';
import { FormControl, FormBuilder, FormGroup, Validators, Form, AbstractControl, ValidatorFn } from '@angular/forms';
// import { CommonDataService } from 'src/app/services/common-data.service';
// import { Observable, Subject, from, noop } from 'rxjs';
// import { LoginService } from 'src/app/login/services/login.service';
 import { UserService } from 'src/app/services/user.service';
// import { ProviderService } from 'src/app/services/provider.service';
//import { stringify } from '@angular/compiler/src/util';
// import { find, pull, filter, times, constant, debounce, set, get, keyBy, reduce, cloneDeep, 
//   sortedUniq, sortBy, orderBy, map, merge, uniqBy } from 'lodash';
//import { sortBy } from 'lodash';
import { LoginService } from 'src/app/login/services/login.service';
//import { dateSelectionJoinTransformer } from '@fullcalendar/core/interactions/date-selecting';
import { Time, DatePipe } from '@angular/common';

export class ClientFormHelper { 

//       static datePipe:DatePipe;

//      constructor( private dp:DatePipe){
//              datePipe = dp;
//      }

      static  GetMultiItems( control: AbstractControl,  multiItems: any[],  common : CommonData[]) {
            var returnList: CommonData[]=[];
        
            if (common && multiItems && multiItems?.length > 0) {
                multiItems.forEach( (tm) => {
                        var x = common.find(x => x.id == tm.id);
                        if (x != null) returnList.push(x);   
                  });
                  control.markAsPristine();
                  return returnList;
            }
            else{
             control.markAsPristine();
              return null;
            }
      }

      static SaveHistoryField (formGroup:AbstractControl,  formControlName:string,  model:any, userId:Number ) {

            if (  formGroup.get(formControlName).dirty){

                  console.log(formGroup.get(formControlName).dirty);
                  model.guid  = this.EmptyGuid(); // Guid get set to null to tell backend to add a new rec
                  model.clientZipCodes.userId = userId;
                  model.clientZipCodes.deleted = false; 
                  // model.datetime, model.lastUpdate  // Date Fields will be updated on backend 
    
                  if ( formControlName == 'idZipCode')         {
                        model.clientZipCodes.zipCode = formGroup.get(formControlName);
                  }
              }
              else{
                    // ' Do Nothing ???'  
              }

              return model;

      }


      static EmptyGuid  () {
            return  '00000000-0000-0000-0000-000000000000';
      }
      
      static nullDate  () {
            return  new Date('1/1/1900 12:00:00 AM').toISOString();
      }

      static CalculateAge(date:Date){

            let timeDiff = Math.abs(Date.now() - date.getTime());
            let age = Math.floor((timeDiff / (1000 * 3600 * 24))/365.25);

            return age;

      }

      static GetElementValue(element:any){
              
            if (element){
                  return element.id;
            }
            else{
                  return null;
            }
      }

      // static AddValidators(parentControl: AbstractControl, control:AbstractControl, validators: ValidatorFn[]) { //validators: ValidatorFn[] = [Validators.required] //...validators: ValidatorFn[]
      //       control.enable();
      //       control.clearValidators();
      //       //control.setValidators(validators);
      //       //validators.forEach(validator => control.addValidators(validator));
      //       control.setValidators(validators);
      //       control.markAsTouched();
      //       control.updateValueAndValidity();
      // }

      // static RemoveValidators(control:AbstractControl, enable:boolean = false) {
      //       if (enable) control.enable();
      //       else control.disable();
      //       control.clearValidators();
      //       control.updateValueAndValidity();
      // }      

      static RequireField(control:AbstractControl) {
            control.enable();
            control.setValidators(Validators.required);
            control.markAsTouched();
            control.updateValueAndValidity();
      }

      // static RequireMinMaxField(control:AbstractControl, min:number, max:number) {
      //       control.enable();
      //       control.setValidators([Validators.required, Validators.min(min), Validators.max(max)]);
      //       control.markAsTouched();
      //       control.updateValueAndValidity();
      // }      

      static RemoveRequireField(control:AbstractControl, enable:boolean = false) {
            if (enable) control.enable();
            else control.disable();
            control.clearValidators();
            control.updateValueAndValidity();
      }

        


      // public hasValidator(control: string, validator: string): boolean {
      //       return !!this.myForm.controls[control].validators(control).hasOwnProperty(validator);
      //      // returns true if control has the validator
      // }

      // static GetDateValue(element:any){

      //       //datePipe: DatePipe;

      //       if (element && element != '1900-01-01'){

      //             return    datePipe.transform(element, 'MM-dd-YYYY');
      //       }
      //       else{
      //             return null;
      //       }
      // }
}
