<form [formGroup]='medical' autocomplete="off">
    <div class="card champ-card-sm">
        <div class="champ-card-header-sm" >
            <div class="p-fluid p-formgrid grid">
                    <div class="field col-7">Lipid Panel</div>

                    <div class="field col-4">
                        <div [ngClass]="{'disableDiv': !showLipidPanelUnavailable()}">
                            <p-checkbox value="false" inputId="unavailable" formControlName="idLipidUnavailable" [binary]="true"></p-checkbox>   
                            <label style="font-size: 0.875rem; font-weight: normal;" for="unavailable">Unavailable</label>
                        </div>
                    </div>

                    <div class="field col-1">
                        <button pButton pRipple type="button" label="History" class="p-button-text p-button-sm champ_history_button_medical" (click)="openHistoryWindow('LIPID', 'Lipid Panel')"></button>      
                    </div>
            </div>
        </div>

        <div class="champ-card-body-sm" >
            <div class="p-fluid p-formgrid grid" *ngIf="!isLipidPanelUnavailable()">
                <div class="field col-12">
                    <div class="formgroup-inline chamformgroup-inline" style="margin-top: 5px; margin-bottom: 5px; margin-right: 5px; line-height: 20px;">
                        <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                        <label style="font-weight:normal; margin-right: 10px;" for="idLipidScreened">Did the patient receive a fasting Lipid panel?</label>
                        <label style="font-size: 0.875rem; font-weight: normal;" for="idLipidScreened">(fasting cholesterol, HDL, calculated LDL and triglycerides)</label>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idLipidScreened" value=2 label="No"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="idLipidScreened" value=1 label="Yes"></p-radioButton></div>
                        <label id="idLipidPanelResultDate">
                            <app-client-expired-field *ngIf="!!this.clientData && this.clientData.clientMedicalLipidPanel" [formGroup]="medical"
                              [fieldId]="MasterFields.LipidPanelResultDate" [lastUpdated]="this.clientData.clientMedicalLipidPanel?.datetime"></app-client-expired-field>
                        </label>                            
                    </div>

                    <div *ngIf="medical.controls['idLipidScreened']?.errors?.required"> 
                        <small id="idLipidScreened-help" class="p-invalid" style="margin-left: 1rem;">Lipid Panel screening is required.</small>
                    </div>
                </div>   

                <div class="field col-12">
                    <div id="LipidNoDiv" class="fluid formgrid grid" *ngIf="showLipidPanelNoReason()">
                        <div class="field col-12">
                            <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idLipidScreenedNoReason" style="margin-right: 1rem;">Reason</label>
                                <div class="field grid">
                                    <p-dropdown formControlName="idLipidScreenedNoReason" [options]="noReasons" optionLabel="description" optionValue="id" appendTo="body" 
                                            [style]="{'width': '100%', 'margin-right': '1rem','min-width':'10rem'}"  > </p-dropdown>      
                                </div>
                            </div>
                            <div *ngIf="medical.controls['idLipidScreenedNoReason']?.errors"> 
                                <small id="idLipidScreenedNoReason-help" class="p-invalid" style="margin-left: 1rem;">Reason is required.</small>
                            </div>
                        </div>
                    </div>
                    
                    <div id="LipidYesDiv" class="fluid formgrid grid" *ngIf="showLipidPanelResultFields()">
                        <div class="field col-6">
                            <div class="formgroup-inline chamformgroup-inline" >
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label htmlFor="idLipidScreenedDate" style="margin-right: 1rem;">Date of Result</label> 
                                <input type="text" #idLipidScreenedDatePicker autocomplete="off" bsDatepicker formControlName="idLipidScreenedDate"
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"
                                    placeholder="MM-DD-YYYY" style="width:90px; height: 30px;" [ngClass]="{'p-invalid-grid' : medical.controls['idLipidScreenedDate'].invalid}" 
                                    class="champ-date-picker" pInputText/> <!-- id="idLipidScreenedDate" name="idLipidScreenedDate" [maxDate]="maxScreenedDate" [minDate]="minScreenedDate" -->
                            </div>
                        </div>

                        <div class="field col-6">
                            <!-- <div class="formgroup-inline chamformgroup-inline">
                                <fa-icon class="champ-field-required" [icon]="['fas', 'asterisk']"></fa-icon>
                                <label style="font-weight:normal; margin-right: 10px;">Result:</label>
                                <p-radioButton formControlName="idLipidResult" value=2 label="Negative" [style]="{'margin-left':'15px'}"></p-radioButton>
                                <p-radioButton formControlName="idLipidResult" value=1 label="Positive" [style]="{'margin-left':'15px'}"></p-radioButton>
                            </div> -->
                        </div>
                        
                        <div class="field col-6">
                            <div *ngIf="medical.controls['idLipidScreenedDate']?.invalid">
                                <div *ngIf="medical.controls['idLipidScreenedDate']?.errors?.required"> 
                                    <small id="idLipidScreenedDate-help" class="p-invalid" style="margin-left: 1rem;">Result Date is required.</small>
                                </div>
                                <!-- <div *ngIf="!!medical.controls['idLipidScreenedDate'].errors['invalidDate']">-->
                                <div *ngIf="!medical.controls['idLipidScreenedDate']?.errors?.required && !!medical.controls['idLipidScreenedDate']?.errors"> 
                                    <small id="idLipidScreenedDate2-help" class="p-invalid" style="margin-left: 1rem;">{{medical.controls['idLipidScreenedDate']?.errors['invalidDate']}}</small>
                                </div>                                                               
                            </div>
                         </div>

                        <div class="field col-6">
                            <!-- <div *ngIf="!!medical.controls['idLipidResult']?.errors"> 
                                <small id="idLipidResult-help" class="p-invalid" style="margin-left: 1rem;">Result is required.</small>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>

            <div class="fluid formgrid grid" *ngIf="isLipidPanelUnavailable()">
                <small id="idLipidPanelUnavailable-help">Lipid Panel screening was marked unavailable. Screening will be required in 30 days.</small>
            </div>
        </div>
    </div>
</form>

<app-history ></app-history>