<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<p-dialog [(visible)]="alwaysVisible"
          header="Switch Provider"
          [modal]="true" position="center" [resizable]="false" [draggable]="false" showEffect="explode"
          styleClass="pDialog" [style]="{width: '25%', minWidth: '25%'}" (onHide)="onCloseDialog()">

    <div class="fluid formgrid grid" style="margin: 20px 2px 2px 2px; width: 99%;">
        <div class="col-1"></div>
        <div class="col-10">
            <div class="form-group">
                    <label class="control-label m-1 mr-2" for="drpProviders" > Select a provider you would like to switch to. </label>
                    <select id="drpProviders"  class="form-control" [(ngModel)]="providerId" (change)="onChangeProvider()" >
                        <option *ngFor="let item of providerList" [ngValue]="item.providerId" [ngStyle]="{'font-weight':item.id === '0' ? 'bold' : 'normal' }">{{item.providerName}}</option>
                    </select>   
            </div>      
        </div>
        <div class="col-1"></div>        
    </div>
    <div class="fluid formgrid grid" style="margin: 2px; width: 99%;">
        <div class="col-1"></div>
        <div class="col-10">
            <div class="form-group">
                    <label class="control-label m-1 mr-2" for="drpClinics" > Clinics: </label>
                    <select id="drpClinics"  class="form-control" [(ngModel)]="clinicId" >
                        <option *ngFor="let item of clinicList" [ngValue]="item.clinicId" [ngStyle]="{'font-weight':item.id === '0' ? 'bold' : 'normal' }">{{item.clinicName}}</option>
                    </select>   
            </div>      
        </div>
        <div class="col-1"></div>        
    </div>
    <div class="flex justify-content-end" > <!-- style="margin: 2px; width: 99%;"-->
        <p-button  pButton pRipple type="button" [ngStyle]="{'width':'15rem'}"  styleClass="p-button-text " class="p-button-text mr-1 "
                (click)="onSwitchProviderConfirm($event)"  [disabled]="!enableSwitchProvider" > 
                <i class="fa-sharp fa-thin fa-repeat fa-xl"></i><span class="text-lg">Apply Change</span>
        </p-button>                    
    </div>
</p-dialog>