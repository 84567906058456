import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { getYear } from 'date-fns';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { AppContextService } from 'src/app/app-context/app-context.service';
import { FormValidations } from 'src/app/client-form/validators/form-validators';
import { ProviderClinicsModel, ProviderContractMaintenanceModel, ProviderContractMaintenancePostResponse, ProviderContractMaintenanceRequestModel } from 'src/app/models/admin.models';
import { ZipCodeResult } from 'src/app/models/common-data.model';
import { CommonDataService } from 'src/app/services/common-data.service';
import { HelperClassService } from 'src/app/services/helper-class.service';
import { UserService } from 'src/app/services/user.service';
import { v4 as uuidv4 } from 'uuid';
import { ContractMaintenanceService } from '../contract-maintenance/contract-maintenance.service';

@Component({
  selector: 'app-contract-maintenance-detail',
  templateUrl: './contract-maintenance-detail.component.html',
  styleUrls: ['./contract-maintenance-detail.component.scss','../admin-styles.scss'],
  providers: [MessageService, ConfirmationService]
})
export class ContractMaintenanceDetailComponent implements OnInit {
  fg: UntypedFormGroup;
  params: Partial<ProviderContractMaintenanceModel> = {};
  contractId: number = 0;
  contractIdToClone: number = 0;
  contractGuid: string;
  contract: Partial<ProviderContractMaintenanceModel> = {};
  clinics: ProviderClinicsModel[];

  getGuidControl = () => this.fg?.get('guid');
  getIdControl = () => this.fg?.get('id');
  getProviderIdControl = () => this.fg?.get('providerId');  
  getProviderNameControl = () => this.fg?.get('providerName');
  getProgramNameControl = () => this.fg?.get('programName');
  getStartDateControl = () => this.fg?.get('startDate');
  getEndDateControl = () => this.fg?.get('endDate');
  getContractAmountControl = () => this.fg?.get('contractAmt');
  getFundYearControl = () => this.fg?.get('fundYear');
  getLastUpdateControl = () => this.fg?.get('lastUpdate');
  getDeletedControl = () => this.fg?.get('deleted');
  getUserIdControl = () => this.fg?.get('userId');
  getMemoControl = () => this.fg?.get('memo');
  getSiteControl = () => this.fg?.get('site');
  getAddressLine1Control = () => this.fg?.get('addressLine1');
  getAddressLine2Control = () => this.fg?.get('addressLine2');
  getCityControl = () => this.fg?.get('city');
  getStateControl = () => this.fg?.get('state');
  getZipControl = () => this.fg?.get('zip');
  getPhoneControl = () => this.fg?.get('phone');
  getFaxControl = () => this.fg?.get('fax');
  getUrlControl = () => this.fg?.get('url');
  getEmailControl = () => this.fg?.get('email');
  getClinicIdControl = () => this.fg?.get('clinicId');
  getRecSourceControl = () => this.fg?.get('recSource');
  getIncludesEisUnitsControl = () => this.fg?.get('includesEisUnits');
  getIdToCloneControl = () => this.fg?.get('contractIdToClone');

  constructor(private fb: UntypedFormBuilder,
              private messageService: MessageService,
              private confirmationService: ConfirmationService,
              public diagRef: DynamicDialogRef, 
              public diagConfig: DynamicDialogConfig,
              private helperClassService: HelperClassService,
              private commonDataService: CommonDataService,
              private contractMaintenanceService: ContractMaintenanceService,
              private datePipe: DatePipe,
              public validationHelper: FormValidations,
              private userService: UserService,
              private app: AppContextService) 
  { 
    this.params = this.diagConfig.data;

    this.fg = fb.group({
      guid:[],
      id: [0],
      providerId: [0, [Validators.required]],
      providerName: [],
      programName: [, [Validators.required]],
      startDate: [, [Validators.required], [this.validationHelper.dateBeginAsyncValidator('endDate')]], // async validators go in a separate array after the sync validators
      endDate: [, [Validators.required], [this.validationHelper.dateEndAsyncValidator('startDate')]],
      contractAmt: [0, [Validators.required]],
      fundYear: [, [Validators.required]],
      lastUpdate: [],
      deleted: [false],
      userId: [0],
      memo: [],
      site: [],
      addressLine1: [],
      addressLine2: [],
      city: [],
      state: [],
      zip: [],
      phone: [],
      fax: [],
      url: [],
      email: [],
      clinicId: [],
      recSource: [],
      includesEisUnits: [],
      contractIdToClone: []
    },
    {
      //validators: [this.customValidator()],
      //asyncValidators: [this.timePeriodValidator()]
      //updateOn: 'blur' //***** DON'T USE, CAUSES ISSUES WITH REACTIVE FORM CONTROLS NOT UPDATING
    });  
  }

  ngOnInit(): void {
    this.contractId = this.params?.id ?? 0;
    this.contractGuid = this.params?.guid;
    this.contractIdToClone = this.params?.contractIdToClone ?? 0;

    //this.clinics = this.providerService.getProviderClinics();
    this.contractMaintenanceService.getProviderClinics(this.params?.providerId).subscribe({
      next: (data: ProviderClinicsModel[]) => {
        this.clinics = data;
      },
      error: (error) => {
        this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
        this.app.userServ.LogError(error);
      },
    complete: () => { }
    });
    
    if (this.contractId > 0) {
          this.getContract(this.contractId);
    }    
  }

  getContract(contractId: number) {
    var request: Partial<ProviderContractMaintenanceRequestModel> = {};
    //request.providerId 
    request.contractId = contractId;

    this.contractMaintenanceService.getProviderContract(request).subscribe({
      next: (data: ProviderContractMaintenanceModel) => {
            // //console.log(data);
            // this.contract = data;
            // //var data: any = { }; // Partial<Clients>
            // //Object.assign(this.fg.value, data)
            // data.startDate = this.datePipe.transform(data.startDate, 'MM-dd-YYYY');
            // data.endDate = this.datePipe.transform(data.endDate, 'MM-dd-YYYY');
            // this.fg.patchValue(data);
            this.patchData(data);
            // if no guid was passed to the component that means we need to clone to a new Provider set a default bogus guid so the HTTP call does not fail, the backend will assign the real guids
            if (!this.contractGuid) {
              this.getProgramNameControl().setValue(this.contract.programName );  // Jay wanted clone out  - DL 2/29/24  + ' CLONE'
              this.contractGuid = uuidv4();
              this.getGuidControl().setValue(this.contractGuid);
              this.getIdToCloneControl().setValue(this.contractIdToClone);
              var fundYear = parseInt(this.getFundYearControl().value) + 1;
              this.getFundYearControl().setValue(fundYear.toString());
              //var date = hlp.castToDateString(addYears(hlp.castToDateWithNoTime(data.startDate), 1));
              var existingYear = getYear(this.helperClassService.castToDateWithNoTime(data.startDate));
              var newYear = getYear(this.helperClassService.castToDateWithNoTime(this.helperClassService.getContractStartDate(data.startDate, 1)));
              this.getProgramNameControl().setValue(this.getProgramNameControl().value.replace(existingYear.toString(), newYear.toString()));
              this.getStartDateControl().setValue(this.helperClassService.getContractStartDate(data.startDate, 1));
              //var date = hlp.castToDateString(addYears(hlp.castToDateWithNoTime(data.endDate), 1));
              this.getEndDateControl().setValue(this.helperClassService.getContractEndDate(data.endDate, 1));
              this.getStartDateControl().setErrors(null);
            }
        },
      error: (error) => {
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
          this.app.userServ.LogError(error);
        },
      complete: () => { }
    });
  }

  patchData(data: ProviderContractMaintenanceModel) {
    this.contract = data;
    data.startDate = this.datePipe.transform(data.startDate, 'MM-dd-YYYY');
    data.endDate = this.datePipe.transform(data.endDate, 'MM-dd-YYYY');
    this.fg.patchValue(data);    
  }

  onChangeZipCode(event: any) {
    var zip = event.target.value?.replaceAll('_', '');
    if (zip?.length != 5) {
      this.getCityControl().setValue("");
      this.getStateControl().setValue("");      
      //this.setZipCodeInvalidClass();
      return;
    }
    this.commonDataService.getZipCodeData(zip).subscribe(    
      {
        next: (result: ZipCodeResult) => {
          if (result) {
            this.getCityControl().setValue(result?.city);
            this.getStateControl().setValue(result?.state);
          }
          else {
            this.getCityControl().setValue("");
            this.getStateControl().setValue("");
          }
        },
        error: (e) => console.error(e),
        complete:() => { }
      } );
  }

  onSaveContract(event: Event) {
    //this.findInvalidControls();
    this.confirmationService.confirm({
        target: event.target,
        message: "Are you sure that you want save?",
        icon: "pi pi-info-circle",
        accept: () => {
          try {
           this.saveContract();
          }
          catch (error) {
            console.log(error.error.message); console.log(error.error.data);
            this.app.userServ.LogError(error.error);
            //  this.helper.displayError(this.messageService, "Changes failed to save successfully.", error);
            this.messageService.add({severity: "error", summary: "Failure", detail: "Changes failed to save successfully.", life: this.app.messageDisplayTime});
          }	  
        },
        reject: () => {
          this.messageService.add({severity: "error", summary: "Aborted", detail: "Changes canceled", life: this.app.messageDisplayTime});
        }
      });
  }

  saveContract() {
    //const data:Partial<ProviderMaintenanceModel> = {};
    var data: any = { }; // Partial<ProviderMaintenanceModel>
    this.getUserIdControl().setValue(this.userService.userID);
    Object.assign(data, this.fg.value)
    data.startDate = this.datePipe.transform(data.startDate, 'yyyy-MM-dd');
    data.endDate = this.datePipe.transform(data.endDate, 'yyyy-MM-dd');
    this.contractMaintenanceService.saveProviderContract(data).subscribe({
      next: (data: ProviderContractMaintenancePostResponse) => {    
            // console.log(data);
            // // return entire contract object
            // this.contract = data.data;
            // this.fg.patchValue(data.data);
            this.patchData(data.data);
            this.messageService.add({severity:'success', summary: 'Successful', detail: 'Contract Saved.', life: this.app.messageDisplayTime});
            // this.refreshPayer.emit();
        },
      error: (error) => { 
          this.messageService.add({ severity: 'error', summary: error, detail: 'Request failed' });
          this.app.userServ.LogError(error);
         },
      complete: () => { }
      });
  }  
}
