<p-toast></p-toast>
<p-confirmPopup></p-confirmPopup>
<!-- <form autocomplete="off"> -->
    <div class="card champ-card-sm">
        <!-- <div class="champ-card-header-sm" >
            <div class="fluid formgrid grid">
                <div class="field col-12">Pre-Screen Data</div>
           </div>
        </div> -->

        <div class="champ-card-body-sm">
            <div class="fluid formgrid grid">
                <div class="field col-12">
                    <div style="margin-left: .5rem;">
                        <label>HIV Status</label>
                        <!-- optionValue="value" [ngModelOptions]="{standalone: true}" [style]="{'width':'100%'}" style="width: 99.9%;"-->
                        <div style="width: 5rem;">
                            <p-dropdown #hivStatusId inputId="hivStatusId" [options]="hivStatuses" optionLabel="label" [(ngModel)]="hivStatus" appendTo="body"
                                        [ngClass]="{'p-invalid' : isHivStatusInvalid()}"></p-dropdown>
                        </div>
                        <div *ngIf="isHivStatusInvalid()"> 
                            <!-- <small id="hivStatus-help" class="p-invalid">HIV Status is not eligible for Ryan White Services. Client and services cannot be entered in CHAMP.</small> -->
                            <small id="hivStatus-help" class="p-invalid">Please select a valid HIV Status.</small>
                        </div>                        
                    </div>
                </div>

                <div class="field col-12">
                    <div style="margin-left: .5rem;">
                        <label>Family Size</label>
                        <div style="width: 5rem;">
                            <p-inputMask #familySizeId inputId="familySize" [(ngModel)]="familySize" mask="9?9" maxlength="2" slotChar="" (onInput)="onChangeFamilySize($event)"></p-inputMask>
                        </div>
                        <div *ngIf="isFamilySizeInvalid()"> 
                            <small id="familySize-help" class="p-invalid">Invalid Family Size. Must be between 1 and 49.</small>
                        </div>                            
                    </div>
                </div>

                <div class="field col-12">
                    <div style="margin-left: .5rem;">
                        <label>Family Income</label>
                        <div style="width: 5rem;">
                            <p-inputNumber #familyIncomeId inputId="familyIncome" [(ngModel)]="familyIncome" 
                                           mode="currency" minFractionDigits="0" maxFractionDigits="0" max="999999" min="0" [maxlength]="9"
                                           currency="USD" locale="en-US" (onInput)="onChangeFamilyIncome($event)"></p-inputNumber>
                        </div>
                        <div *ngIf="isFamilyIncomeInvalid()"> 
                            <small id="familyIncome-help" class="p-invalid">Invalid Family Income. Must be between $1 and $999,999.</small>
                        </div>                            
                    </div>
                </div>

                <div class="field col-12">
                    <div style="margin-left: .5rem;">
                        <label>Federal Poverty Level</label>
                        <div>
                            <input type="text" inputId="povertyDescription" [(ngModel)]="povertyDescription" style="width: 18rem;font-weight: 700;opacity: .9;background-color: whitesmoke;"
                            [ngStyle]="{'color': isPovertyCodeUnder() ? 'green' : 'red'}" pInputText [disabled]="true"/>
                        </div>                                          
                    </div>
                </div>                
                
                <div class="field col-12">
                    <div style="margin-left: .5rem;">
                        <label>Zip Code</label>
                        <div style="width: 5rem;">
                            <p-inputMask #zipCodeId #zipCodeModel="ngModel" inputId="zipCode" [(ngModel)]="zipCode" mask="9?9999" slotChar= "" maxlength="5" (onInput)="onChangeZipCode($event)"
                                        [ngClass]="{'p-invalid' : isZipCodeMissing() || isZipCodeInvalid()}"></p-inputMask>
                        </div>                                     
                        <!-- <div>Zip Value {{zipCodeModel.value?.replaceAll('_', '').length ?? -1}}</div> -->
                        <div *ngIf="isZipCodeMissing()"> 
                            <small id="zipCode-help" class="p-invalid">Zip Code is required.</small>
                        </div>
                        <div *ngIf="!isZipCodeMissing() && isZipCodeInvalid()"> 
                            <small id="zipCode-help" class="p-invalid">Invalid Zip Code.</small>
                        </div>
                    </div>                                                        
                </div>

                <div class="field col-12">
                    <div style="margin-left: .5rem;">
                        <label>City, State, County</label>
                        <div>
                            <input type="text" inputId="cityState" [(ngModel)]="cityState" pInputText [disabled]="true" style="width: 18rem;font-weight: 700;opacity: .9;background-color: whitesmoke;"
                                [ngStyle]="{'color': isZipCodeMissing() || isZipCodeInvalid() ? 'black' : 'green'}"/>
                        </div>                               
                    </div>                    
                </div>
            </div>

            <div class="field col-12" style="padding-bottom: 5px;">
                <!-- [disabled]="!dsForm.valid" style="width: 130px;float: right;margin-right: 5px;margin-top: 0.5rem;" -->
                <button [disabled]="!isEligibilityValid()" pButton pRipple class="btn btn-primary" (click)="onContinue($event)" icon="pi pi-check" label="Continue" style="float: right"></button>
            </div>            
        </div>
    </div>
<!-- </form> -->
