<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='fg' autocomplete="off">
    <div class="fluid formgrid grid " style="margin-bottom: 1rem;">
        <div class="col-2 ">
            <h3><b>Provider Maintenance</b></h3>
        </div>
    
        <!-- <div class="col-md-1" style="width: 12.499999995%"></div>
        Since col-md-1 is of width 8.33333333%; simply multiply 8.33333333 * 1.5 and set it as your width. -->
        <div class="field col-2">
            <button #refresh pButton type="button" label="Refresh" class="p-button-link" style="width: 10rem;" (click)="onChangeFilter($event, dtProviders)"></button>
        </div> 
    </div>

    <div class="container-fluid" id="grid" > 

        <p-table #dtProviders [value]="getProviders()" [scrollable]="true" scrollDirection="both" scrollHeight="65vh"  
            styleClass="p-datatable-sm p-datatable-striped" [filters]="filters"> 
            <ng-template pTemplate="header">
                <tr>
                    <th style="width:10rem"></th>
                     <!-- TODO: Need to Add New Provider Feature  -->
                    <!-- <th style="width:10rem"><p-button   label="New" [text]="true" icon="pi pi-plus" (click)="onEditUser($event,null)" [disabled]="isRowInEditMode" styleClass="p-button-text note-button font-bold" class="p-button-help p-button-text mr-1 " ></p-button></th>                                 -->
                    <th pSortableColumn="id" style="width:8rem;">ID<p-sortIcon field="id" ></p-sortIcon></th>
                    <th pSortableColumn="providerName" style="width:30rem;">Name<p-sortIcon field="providerName"></p-sortIcon></th>
                    <th pSortableColumn="address" style="width:25rem;">Address<p-sortIcon field="address"></p-sortIcon></th>
                    <th pSortableColumn="city" style="width:15rem;">City<p-sortIcon field="city"></p-sortIcon></th>
                    <th pSortableColumn="state" style="width:10rem;">State<p-sortIcon field="state"></p-sortIcon></th>
                    <th pSortableColumn="zip" style="width:10rem;">Zip<p-sortIcon field="zip"></p-sortIcon></th>
                    <th pSortableColumn="mainPhone" style="min-width:12rem;">Phone<p-sortIcon field="mainPhone"></p-sortIcon></th>
                    <th pSortableColumn="email" style="width:20rem;">Email<p-sortIcon field="email"></p-sortIcon></th>
                    <th pSortableColumn="billableCutOff" style="width:15rem;">Billable CutOff<p-sortIcon field="billableCutOff"></p-sortIcon></th>
                    <th pSortableColumn="test" style="width:5rem;">Test<p-sortIcon field="test"></p-sortIcon></th>
                    <th pSortableColumn="active" style="width:5rem;">Active<p-sortIcon field="active"></p-sortIcon></th>
                    <th pSortableColumn="funded" style="width:5rem;">Funded<p-sortIcon field="funded"></p-sortIcon></th>
                    <th pSortableColumn="deleted" style="width:5rem;">Deleted<p-sortIcon field="deleted"></p-sortIcon></th>
                </tr>
                <tr>
                    <th style="width:10rem"></th>
                    <th style="width:8rem;"></th>
                    <th style="width:30rem;">
                        <p-columnFilter type="text" field="providerName" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:25rem;">
                        <p-columnFilter type="text" field="address" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:15rem;">
                        <p-columnFilter type="text" field="city" matchMode="contains"></p-columnFilter>
                    </th>         
                    <th style="width:10rem;">
                        <p-columnFilter type="text" field="state" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:10rem;">
                        <p-columnFilter type="text" field="zip" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="min-width:12rem;">
                        <p-columnFilter type="text" field="mainPhone" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:20rem;">
                        <p-columnFilter type="text" field="email" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:15rem;">
                        <p-columnFilter type="numeric" field="billableCutOff" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:5rem;">
                        <p-columnFilter type="boolean" field="test" matchMode="contains"></p-columnFilter>
                    </th>                    
                    <th style="width:5rem;">
                        <p-columnFilter type="boolean" field="active" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:5rem;">
                        <p-columnFilter type="boolean" field="funded" matchMode="contains"></p-columnFilter>
                    </th>
                    <th style="width:5rem;">
                        <p-columnFilter type="boolean" field="deleted" matchMode="contains"></p-columnFilter>
                    </th>                    
                </tr> 
            </ng-template>
            <ng-template pTemplate="body" let-provider let-editing="editing" let-rowIndex="rowIndex">
                <tr >  <!-- [pEditableRow]="event" -->
                    <td style="width:10rem;text-align:center" #buttonContainer>
                        <button #editRowButton  pButton pRipple type="button"  icon="pi pi-pencil"  class="p-button-rounded p-button-text" pTooltip="Edit Provider" (click)="onEditProvider($event, provider)"></button>  
                        <button #deleteRowButton  pButton pRipple type="button" icon="pi pi-trash"  class="p-button-rounded p-button-text p-button-danger" pTooltip="Delete Provider" (click)="onDeleteProvider($event, provider)"></button> 
                        <button #cloneRowButton  pButton pRipple type="button" icon="pi pi-clone"  class="p-button-rounded p-button-text" pTooltip="Clone Provider" (click)="onCloneProvider($event, provider)"></button>
                    </td>
                    <td style="width:8rem; "> {{ provider?.id }} </td>
                    <td style="width:30rem; "><span title="{{provider.providerName}}"> {{ provider?.providerName }} </span></td>
                    <td style="width:25rem; "><span title="{{provider.address}}"> {{ provider?.address }} </span></td>
                    <td style="width:15rem; "><span title="{{provider.city}}"> {{ provider?.city }} </span></td>
                    <td style="width:10rem; "><span title="{{provider.state}}"> {{ provider?.state }} </span></td>
                    <td style="width:10rem; "><span title="{{provider.zip}}"> {{ provider?.zip }} </span></td>                    
                    <td style="min-width:12rem; "><span title="{{provider.mainPhone}}"> {{ provider?.mainPhone   }} </span></td>
                    <td style="width:20rem; "><span title="{{provider.email}}"> {{ provider?.email }} </span></td>
                    <td style="width:15rem; "><span title="{{provider.billableCutOff}}"> {{ provider?.billableCutOff }} </span></td>
                    

                    <!-- <td style="width:5rem; "><i class="pi" [ngClass]="{'true-icon pi-check-circle': provider?.test, 'false-icon pi-times-circle p-button-danger': !provider?.test}"></i></td>
                    <td style="width:5rem; "><i class="pi" [ngClass]="{'true-icon pi-check-circle': provider?.active, 'false-icon pi-times-circle p-button-danger': !provider?.active}"></i></td>
                    <td style="width:5rem; "><i class="pi" [ngClass]="{'true-icon pi-check-circle': provider?.funded, 'false-icon pi-times-circle p-button-danger': !provider?.funded}"></i></td>
                    <td style="width:5rem; "><i class="pi" [ngClass]="{'true-icon pi-check-circle': provider?.deleted, 'false-icon pi-times-circle p-button-danger': !provider?.deleted}"></i></td>                     -->

                    <td style="width:5rem; "><div *ngIf="provider?.test">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   </td>
                    <td style="width:5rem; "><div *ngIf="provider?.active">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   </td>
                    <td style="width:5rem; "><div *ngIf="provider?.funded">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   </td>
                    <td style="width:5rem; "><div *ngIf="provider?.deleted">  <i class="fa-sharp fa-check fa-xl" style="color:green;"></i>    </div>   </td>


                </tr>
            </ng-template>
        </p-table>                    
    </div> 
</form>