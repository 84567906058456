import { Time, DatePipe } from '@angular/common';
//import { Component, OnInit, ViewEncapsulation, isDevMode, ChangeDetectorRef, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { AfterViewInit, Component, ElementRef, OnInit, isDevMode, Input, ViewChild, EventEmitter, Output, HostListener } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl,
         AsyncValidatorFn, ValidationErrors, NgModelGroup } from '@angular/forms';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { Observable, Subject, from, noop, of, lastValueFrom } from 'rxjs';
import { YesNo, AssessmentType, AssessmentOutcome } from "src/app/models/enums";
import { ClientAssessmentReferral, ClientAssessmentReferralModel, ClientReferral, ClientReferralModel, 
         ClientAssessment, ClientAssessmentModel, ClientDirectServiceModel } from '../services/client-direct-service-models';
//         ClientReferralOutsideProvider, ClientReferralOutsideProviderModel } from '../services/client-direct-service-models';
import { ExternalProvider, ExternalProvidersModel } from '../../models/provide.model';
import { CommonDataService } from '../../services/common-data.service';
import { ConfirmationService, MessageService, PrimeNGConfig } from "primeng/api";
import { CommonData, ServiceCategory } from '../../models/common-data.model';
import { ProviderService } from '../../services/provider.service';
import { format, addDays, addMonths, parseISO, formatISO, toDate, getYear, getMonth, isDate, compareAsc } from 'date-fns';
import { UserService } from '../../services/user.service';
import { ClientDirectServicesService } from './../services/client-direct-services.service';
import { stubTrue } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ClientFormService } from 'src/app/client-form/services/client-form.service';
import { AppContextService } from 'src/app/app-context/app-context.service';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {
  @Output() dialogCompleted: EventEmitter<any> = new EventEmitter();
  
  mainForm: UntypedFormGroup;
  YesNoEnum = YesNo;  
  displayAssessmentDialog: boolean = false;
  displayOutsideProviderDialog: boolean = false;
  useProviderAssessment: boolean = false;
  allowEdit: boolean = false;
  allowExit: boolean = true;
  newAssessment: boolean = false;
  newReferral: boolean = false;
  validAssessment: boolean = false;
  readOnly: boolean = false;
  //static functionBeforeCloseEvent: any;

  currentDate: Date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  originDate: Date = new Date(0, 0); //Mon Jan 01 1900 00:00:00 GMT-0500 (Eastern Standard Time) // new Date(1900, 0, 1)

  // Assessment Screenings Dialog Settings
  assessmentFunders: any[];
  assessmentServiceTypes: ServiceCategory[];
  assessmentSubTypes: any[] = [];
  assessmentReferralProviders: any[];
  assessmentOutsideProviders: any[];
  assessmentEmployees: any[];
  assessmentMentalHealthRanges: any[];
  assessmentScreeningOnly: boolean = false;
  assessmentIncludedSubtype: number = 0;

  // Dynamic assessment items
  assessmentFormTitle: string = "";
  assessmentQuestion0Text: string = "";
  assessmentQuestion1Text: string = "";
  assessmentQuestion2Text: string = "";
  assessmentQuestion1Visible: boolean = false;
  assessmentQuestion2Visible: boolean = false;
  assessmentFollowupVisible: boolean = false;
  assessmentFollowupEnabled: boolean = false;
  assessmentOutComeVisible: boolean = false;
  assessmentOutComeEnabled: boolean = false;
  assessmentOutsideProviderEnabled: boolean = false;  

  getAssessmentDateValue = () => this.mainForm?.get('assessment.assessmentDate')?.value;
  getAssessmentOutcomeControl = () => this.mainForm?.get('assessment.assessmentOutcome');
  getAssessmentOutcomeValue = () => this.getAssessmentOutcomeControl()?.value;

  getAssessmentCurrentYesNoControl = () => this.mainForm?.get('assessment.assessmentCurrentYesNo');
  getAssessmentCurrentYesNoValue = () => this.getAssessmentCurrentYesNoControl()?.value;
  setAssessmentCurrentYesNoValue = (value) => { this.mainForm?.get('assessment.assessmentCurrentYesNo').setValue(value ?? -1); 
                                                if (this.getAssessmentTypeValue() == AssessmentType.SubstanceAbuse) this.setAssessmentCurrentSubstanceAbuseValue(value ?? -1);
                                                if (this.getAssessmentTypeValue() == AssessmentType.MentalHealth) this.setAssessmentMentalHealthIssuesIdentifiedValue(value ?? -1); 
                                              }

  getAssessmentDeliveredDateMonthValue = () => getMonth(this.getAssessmentDeliveredDateValue()) + 1;
  getAssessmentDeliveredDateYearValue = () => getYear(this.getAssessmentDeliveredDateValue());
  // always store dates as Date and not string, convert to strings before calling any Web APIs
  getAssessmentDeliveredDateValue = () => this.mainForm?.get('assessment.deliveredDate')?.value;
  getAssessmentExpiredDateValue = () => this.mainForm?.get('assessment.expired')?.value;
  getAssessmentReferredValue = () => this.mainForm?.get('assessment.referred')?.value;
  getAssessmentReferralGuidValue = () => this.mainForm?.get('assessment.referralGuid')?.value;
  getAssessmentUserIdValue = () => this.mainForm?.get('assessment.userId')?.value;
  getAssessmentCurrentSubstanceAbuseValue = () => this.mainForm?.get('assessment.currentSubstanceAbuse')?.value;
  getAssessmentMentalHealthIssuesIdentifiedValue = () => this.mainForm?.get('assessment.mentalHealthIssuesIdentified').value;
  getAssessmentTypeValue = () => this.mainForm?.get('assessment.assessmentType').value;
  getAssessmentPastHistoryValue = () => this.mainForm?.get('assessment.pastHistory').value;
  getReferralFunderControl = () => this.mainForm?.get('referral.funderId');
  getReferralFunderValue = () => this.getReferralFunderControl()?.value;
  getReferralServiceTypeControl = () => this.mainForm?.get('referral.serviceId');
  getReferralServiceTypeValue = () => this.getReferralServiceTypeControl()?.value;
  getReferralSubTypeControl = () => this.mainForm?.get('referral.subId');
  getReferralSubTypeValue = () => this.getReferralSubTypeControl()?.value;
  getReferralProviderControl = () => this.mainForm?.get('referral.providerId');
  getReferralProviderValue = () => this.getReferralProviderControl()?.value;
  getReferralOutsideProviderIdControl = () => this.mainForm?.get('referral.outsideProviderId');
  getReferralOutsideProviderIdValue = () => this.getReferralOutsideProviderIdControl()?.value ?? 0;
  getReferralOutsideProviderNameControl = () => this.mainForm?.get('referral.outsideProviderName');
  getReferralOutsideProviderNameValue = () => this.getReferralOutsideProviderNameControl()?.value ?? '';
  getReferralOutsideProviderPhoneControl = () => this.mainForm?.get('referral.outsideProviderPhone');
  getReferralOutsideProviderPhoneValue = () => this.getReferralOutsideProviderPhoneControl()?.value ?? '';
  getReferralOutsideProviderAddressControl = () => this.mainForm?.get('referral.outsideProviderAddress');
  getReferralOutsideProviderAddressValue = () => this.getReferralOutsideProviderAddressControl()?.value ?? '';
  getReferralEmployeeControl = () => this.mainForm?.get('referral.employeeId');
  getReferralEmployeeValue = () => this.getReferralEmployeeControl()?.value;
  getReferralConfirmLocationValue = () => this.mainForm?.get('referral.confirmLocation')?.value;
  getReferralMemoValue = () => this.mainForm?.get('referral.memo')?.value;
  getReferralGuidValue = () => this.mainForm?.get('referral.guid')?.value;
  getReferralClientGuidValue = () => this.mainForm?.get('referral.clientGuid')?.value;
  getReferralDirectServiceGuidValue = () => this.mainForm?.get('referral.directServiceGuid')?.value;
  getReferralContractGuidValue = () => this.mainForm?.get('referral.contractGuid')?.value;
  getReferralDateTimeValue = () => this.mainForm?.get('referral.datetime')?.value;

  setReferralDateTimeValue = (value) => { 
                                          if (typeof value == 'string')
                                            this.mainForm?.get('referral.datetime').setValue(parseISO(value));
                                          else
                                            this.mainForm?.get('referral.datetime').setValue(toDate(value)); 
                                        };
  setReferralContractGuidValue = (value) => this.mainForm?.get('referral.contractGuid').setValue(value);
  setReferralDirectServiceGuidValue = (value) => this.mainForm?.get('referral.directServiceGuid').setValue(value);
  setReferralClientGuidValue = (value) => this.mainForm?.get('referral.clientGuid').setValue(value);
  setReferralGuidValue = (value) => this.mainForm?.get('referral.guid').setValue(value);
  setReferralMemoValue = (value) => this.mainForm?.get('referral.memo').setValue(value);
  setReferralConfirmLocationValue = (value) => this.mainForm?.get('referral.confirmLocation').setValue(value);
  setReferralEmployeeValue = (value) => this.mainForm?.get('referral.employeeId').setValue(value);
  setReferralOutsideProviderIdValue = (value) => this.mainForm?.get('referral.outsideProviderId').setValue(value);
  setReferralOutsideProviderNameValue = (value) => this.mainForm?.get('referral.outsideProviderName').setValue(value);
  setReferralOutsideProviderPhoneValue = (value) => this.mainForm?.get('referral.outsideProviderPhone').setValue(value);
  setReferralOutsideProviderAddressValue = (value) => this.mainForm?.get('referral.outsideProviderAddress').setValue(value);

  setReferralProviderValue = (value) => this.mainForm?.get('referral.providerId').setValue(value);
  setReferralSubTypeValue = (value) => this.mainForm?.get('referral.subId').setValue(value);
  setReferralServiceTypeValue = (value) => this.mainForm?.get('referral.serviceId').setValue(value);
  setReferralFunderIdValue = (value) => this.mainForm?.get('referral.funderId').setValue(value);

  setAssessmentUserIdValue = (value) => this.mainForm?.get('assessment.userId').setValue(value);
  setAssessmentReferralGuidValue = (value) => this.mainForm?.get('assessment.referralGuid').setValue(value);
  setAssessmentReferredValue = (value) => this.mainForm?.get('assessment.referred').setValue(value);
  setAssessmentGuidValue = (value) => this.mainForm?.get('assessment.guid').setValue(value);
  setAssessmentClientGuidValue = (value) => this.mainForm?.get('assessment.clientGuid').setValue(value);
  setAssessmentDirectServiceGuidValue = (value) => this.mainForm?.get('assessment.directServiceGuid').setValue(value);
  setAssessmentDeliveredDateValue = (value) => {  
                                                  if (typeof value == 'string')
                                                    this.mainForm?.get('assessment.deliveredDate').setValue(parseISO(value));
                                                  else
                                                    this.mainForm?.get('assessment.deliveredDate').setValue(toDate(value)); 
                                                };
  setAssessmentDateValue = (value) => { 
                                        if (typeof value == 'string')
                                          this.mainForm?.get('assessment.assessmentDate').setValue(parseISO(value));
                                        else
                                          this.mainForm?.get('assessment.assessmentDate').setValue(toDate(value)); 
                                      };
  setAssessmentOutcomeValue = (value) => this.mainForm?.get('assessment.assessmentOutcome').setValue(value);                               
  setAssessmentTypeValue = (value) => this.mainForm?.get('assessment.assessmentType').setValue(value);
  //setAssessmentCurrentYesNoValue = (value) => this.mainForm?.get('assessment.assessmentCurrentYesNo').setValue(value);
  setAssessmentCurrentSubstanceAbuseValue = (value) => this.mainForm?.get('assessment.currentSubstanceAbuse').setValue(value);
  setAssessmentMentalHealthIssuesIdentifiedValue = (value) => this.mainForm?.get('assessment.mentalHealthIssuesIdentified').setValue(value);
  setAssessmentFollowUpValue = (value) => this.mainForm?.get('assessment.followUp').setValue(value);
  // since p radio button doesn't support boolean, need to switch PastHistory values from boolean to numeric and later 
  // in getAssessmentModel function before a save need to convert it back to boolean to pass to the Web API
  setAssessmentPastHistoryValue = (value) => { 
                                               //var boolValue = value == 'true' ? true : false;
                                               var intVal = (value == true ? 1 : 0)
                                               this.mainForm?.get('assessment.pastHistory').setValue(intVal); 
                                              };
  setAssessmentExpiredDateValue = (value) => {  
                                                if (typeof value == 'string')
                                                  this.mainForm?.get('assessment.expired').setValue(parseISO(value));
                                                else
                                                  this.mainForm?.get('assessment.expired').setValue(toDate(value)); 
                                              };
  isExpired = () => { return (compareAsc(this.getAssessmentExpiredDateValue(), this.originDate) != 0); };
  isReferralSelected = () => { return this.getAssessmentOutcomeValue() == AssessmentOutcome.Referral };
  getAssessmentNotificationJSONMessage = (dialogCompleted:boolean) => { return '{ "dialogCompleted":' + dialogCompleted.toString() + ', "validAssessment":' + this.validAssessment.toString() + ' }'; }

  isAtLeastOneAssessmentVisible = () => { return (this.assessmentOutComeEnabled || this.assessmentOutComeVisible); }
                                                  
  constructor(private route: ActivatedRoute, 
              private router: Router, 
              private fb: UntypedFormBuilder, 
              private dsDataService: ClientDirectServicesService, 
              private commonDataService: CommonDataService, 
              private app: AppContextService,
              private providerService: ProviderService, 
              private confirmationService: ConfirmationService, 
              private messageService: MessageService, 
              private userService: UserService, 
              private datePipe: DatePipe, 
              private elementRef: ElementRef, 
              public clientService: ClientFormService) { 
      this.mainForm = fb.group({
        assessment:this.fb.group({
          guid: ['00000000-0000-0000-0000-000000000000'],
          clientGuid: ['00000000-0000-0000-0000-000000000000'],
          directServiceGuid: ['00000000-0000-0000-0000-000000000000'],
          deliveredDate: [this.currentDate],
          assessmentDate: [this.currentDate, [Validators.required, this.futureDateValidator()]],
          assessmentOutcome: [-1],
          assessmentType: [0],
          currentSubstanceAbuse: [-1],
          mentalHealthIssuesIdentified: [-1],
          followUp: [0],
          pastHistory: [],
          referred: [false],
          referralGuid: ['99999999-9999-9999-9999-999999999999'],
          notes: [''],
          userId: [0],          
          datetime: [this.originDate],
          deleted: [false],
          expired: [this.originDate],          
          lastUpdate: [this.currentDate],
          recSource: ['4'],
          assessmentCurrentYesNo: []
        }),
        referral:this.fb.group({ 
          guid: ['00000000-0000-0000-0000-000000000000'],
          clientGuid: ['00000000-0000-0000-0000-000000000000'],
          serviceId: [0],
          subId: [0],
          period: [],
          expiring: [],
          providerId: [0],
          confirmStatus: [],
          confirmLocation: [],
          memo: [],
          deleted: [false],
          userId: [0],
          lastUpdate: [this.currentDate],  
          datetime: [this.currentDate],
          expired: [this.originDate],
          employeeId: [],
          referralStatus: [],
          canProvideService: [],
          contactedReferrer: [],
          contactedReferrerDate: [],
          willProvideService: [0],
          contractGuid: ['00000000-0000-0000-0000-000000000000'],
          funderId: [0], //[Validators.required, Validators.min(1)]
          canProvideRefusal: [],
          willProvideRefusal: [],
          directServiceGuid: ['00000000-0000-0000-0000-000000000000'],
          recSource: ['4'],
          clinicId: [],
          completedDate: [],
          completedNotes: [],
          referredClientId: [],
          lostToFollowupDate: [],
          lostToFollowupNote: [],
          statusChangeNote: [],
          statusChangeDate: [],
          sourceClinicId: [],
          outsideProviderId: [0],
          outsideProviderName: [],
          outsideProviderPhone: [],
          outsideProviderAddress: []
        }),
        mental_health_gaf:this.fb.group({
          dsAssessmentMentalHealthValue: [null, [Validators.minLength(1), Validators.maxLength(3), Validators.min(0), Validators.max(100)]],
          dsAssessmentMentalHealthRange: []          
        })
    },
    {
      validators: [this.funderValidator(), this.serviceIdValidator(), this.subIdValidator(), this.providerIdValidator(), this.outsideProviderIdValidator(),
                   this.employeeIdValidator(), this.outsideProviderNameValidator(), this.assessmentOutcomeValidator()]
    });
  }

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    // works for parent ds dialog
    // if (!!this.elementRef.nativeElement.parentElement.querySelector('.p-dialog-header-close-icon') && !this.beforeCloseEventAdded) {
    //   this.elementRef.nativeElement.parentElement.querySelector('.p-dialog-header-close-icon').addEventListener('click', this.onClick.bind(this));     
    //   this.beforeCloseEventAdded = true;
    // }

    //&& !this.beforeCloseEventAdded
    // if (!!this.elementRef.nativeElement.parentElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon')) {
    //   if (!!AssessmentComponent.functionBeforeCloseEvent) {
    //     this.elementRef.nativeElement.parentElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon').removeEventListener('click', AssessmentComponent.functionBeforeCloseEvent);
    //     AssessmentComponent.functionBeforeCloseEvent = null;
    //   }
    //   AssessmentComponent.functionBeforeCloseEvent = this.onBeforeClose;
    //   this.elementRef.nativeElement.parentElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon').addEventListener('click', AssessmentComponent.functionBeforeCloseEvent.bind(this));     
    //   this.beforeCloseEventAdded = true;
    // }

    //if (!!this.elementRef.nativeElement.parentElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon')) {
    // if (!!this.elementRef.nativeElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon')) {
    //   if (!AssessmentComponent.functionBeforeCloseEvent && this.displayAssessmentDialog) {
    //     AssessmentComponent.functionBeforeCloseEvent = this.onBeforeClose;
    //     // this.elementRef.nativeElement.parentElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon').removeEventListener('click', AssessmentComponent.functionBeforeCloseEvent.bind(this));
    //     // this.elementRef.nativeElement.parentElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon').addEventListener('click', AssessmentComponent.functionBeforeCloseEvent.bind(this));     
    //     this.elementRef.nativeElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon').removeEventListener('click', AssessmentComponent.functionBeforeCloseEvent.bind(this));
    //     this.elementRef.nativeElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon').addEventListener('click', AssessmentComponent.functionBeforeCloseEvent.bind(this));     
    //     this.beforeCloseEventAdded = true;
    //   }
    // }
  }
  
  //onBeforeClose(event: any) {
    //event.preventDefault();
    //alert('onBeforeClose clicked');
    // this.elementRef.nativeElement.parentElement.querySelector('#AssessmentModal').querySelector('.p-dialog-header-close-icon').removeEventListener('click', AssessmentComponent.functionBeforeCloseEvent.bind(this));
    // AssessmentComponent.functionBeforeCloseEvent = null;
    //this.onHideDialog(event);

    // if (this.validAssessment) { 
    //   //this.onHideDialog(event);
    //   this.onSaveChangesNoConfirm(event);
    // }
    // else {
    //   this.confirmationService.confirm({
    //     target: event?.target,
    //     message: "Are you sure that you want to cancel, the direct service will be flagged not billable?",
    //     icon: "pi pi-info-circle",
    //     accept: () => {
    //       this.onHideDialog(event);
    //     },
    //     reject: () => {
    //       this.messageService.add({
    //         severity: "error",
    //         summary: "Aborted",
    //         detail: "Cancel aborted",
    //         life: 2000
    //       });
    //     }
    //   });
    // }
  //}

  onHideDialog() {
    // press the cancel button fires this event twice probably because of the dialog event (onHide)="onHideDialog()"
    // since we don't want to raise the event twice only raise the event on the second time in which is when 
    // displayAssessmentDialog is false
    // if (!this.displayAssessmentDialog && this.allowEdit) {
    //   //this.dialogCompleted.emit('{ "dialogCompleted":true, "validAssessment":' + this.validAssessment.toString() + ' }');
    //   this.dialogCompleted.emit(this.getAssessmentNotificationJSONMessage(true));
    // }
    this.displayAssessmentDialog = false;
    this.displayOutsideProviderDialog = false;
    //this.readOnly = false;
    if (this.allowEdit) this.dialogCompleted.emit(this.getAssessmentNotificationJSONMessage(true));
    // this.allowEdit = false;
    // this.allowExit = true;
  }

  onShowOutsideProviderDialog() {
    this.displayOutsideProviderDialog = true;
    var outsideProviderId = this.getReferralOutsideProviderIdValue();
    var outsideProvider = this.providerService.getExternalProviderById(outsideProviderId);
    this.setReferralOutsideProviderNameValue(outsideProvider?.name ?? '');
    this.setReferralOutsideProviderPhoneValue(outsideProvider?.phone ?? '');
    this.setReferralOutsideProviderAddressValue(outsideProvider?.address ?? '');    
  }

  onHideOutsideProviderDialog() {
    this.displayOutsideProviderDialog = false;
  }

  onShowDialog(selectedDirectService: Partial<ClientDirectServiceModel>, allowEdit: boolean = false, allowExit: boolean = true) {
    this.intializeDialog(selectedDirectService, allowEdit, allowExit);
  }

  intializeDialog(selectedDirectService:Partial<ClientDirectServiceModel>, allowEdit: boolean, allowExit: boolean) {
    this.newAssessment = false; // default value
    this.validAssessment = false; // default value
    this.allowEdit = allowEdit;
    this.readOnly = !allowEdit;
    this.allowExit = allowExit;
    this.useProviderAssessment = this.providerService.getProviderOptions().useAssessment;
    this.mainForm.reset();
    this.loadAssessment(selectedDirectService);
    if (this.clientService.readonly) this.mainForm.disable();
    this.displayAssessmentDialog = true;
    this.dialogCompleted.emit(this.getAssessmentNotificationJSONMessage(false));
  }

  onSaveChangesNoConfirm(event: Event) {
    this.onSaveChanges().then(value => { this.onHideDialog(); })
                        .catch((error) => { alert(error); });
    // this.confirmationService.confirm({
    //   target: event.target,
    //   message: "Are you sure that you want to proceed?",
    //   icon: "pi pi-info-circle",
    //   accept: () => {
    //     this.onSaveChanges().then(value => {
    //       this.messageService.add({
    //         severity: "info",
    //         summary: "Confirmed",
    //         detail: "Changes Saved",
    //         life: 2000
    //       });         
    //     });
    //   },
    //   reject: () => {
    //     this.messageService.add({
    //       severity: "error",
    //       summary: "Aborted",
    //       detail: "Changes Canceled",
    //       life: 2000
    //     });
    //   }
    // });
  }

  onOutsideProviderSaveChangesConfirm(event: Event) {
    this.confirmationService.confirm({
      target: event.target,
      message: "Are you sure that you want to proceed?",
      icon: "pi pi-info-circle",
      accept: () => {
        this.onOutsideProviderSaveChanges().then(value => {
          this.messageService.add({
            severity: "info",
            summary: "Confirmed",
            detail: "Changes Saved",
            life: 2000
          });         
        });
      },
      reject: () => {
        this.messageService.add({
          severity: "error",
          summary: "Aborted",
          detail: "Changes Canceled",
          life: 2000
        });
      }
    });
  }

  async onOutsideProviderSaveChanges() {
    var model = this.getReferralOutsideProviderModel();

    model.id = this.getReferralOutsideProviderIdValue();
    model.sourceProviderId = this.providerService.providerID;
    model.name = this.getReferralOutsideProviderNameValue();
    model.phone = this.getReferralOutsideProviderPhoneValue();
    model.address = this.getReferralOutsideProviderAddressValue();

    try {
      var response = await lastValueFrom(this.providerService.saveExternalProvider(model));   
      const keys = JSON.parse(response.data);
      model.id = Number(keys.id);
      model.phone = keys.phone.toString(); // the web api cleanses special characters out of phone #
      this.setReferralOutsideProviderPhoneValue(model.phone);
      // add or update the new external provider to the local external providers array
      this.providerService.updateExternalProvider(model);
      // next refresh the external provider list which adds the new external provider to assessmentOutsideProviders drop down
      this.assessmentOutsideProviders = this.providerService.getExternalProviders(true);
      // now select the newly created external provider
      this.setReferralOutsideProviderIdValue(model.id);
    }
    catch (error) {
      console.log(error.error.message); console.log(error.error.data);
    }	    
  }

  async onSaveChanges() {
    if (!this.mainForm.dirty) {
      return;
    }
    else if (this.mainForm.valid) {
      //console.log(this.mainForm.status);//console.log(this.mainForm.valueChanges);
      // update/create the assessment
      var model = this.getAssessmentWithReferralModel();
      // to handle the case of a local expired assessment we need to reset the assessment guid so a new assessment is created in the web api
      if (this.newAssessment) {
        model.clientAssessment.guid == '00000000-0000-0000-0000-000000000000';
      }      
      // set referred and referral_guid on the assessment and set guid on referral if applicable
      if (this.getAssessmentOutcomeValue() == AssessmentOutcome.Referral && 
          model.clientReferral.guid == '00000000-0000-0000-0000-000000000000' &&
          model.clientAssessment?.referralGuid == '99999999-9999-9999-9999-999999999999' &&
          model.clientReferral?.serviceId > 0 && model.clientReferral?.subId > 0) {
        this.newReferral = true;
        var newReferralGuid = uuidv4();
        model.clientReferral.guid = newReferralGuid;
        this.setReferralGuidValue(newReferralGuid);
        model.clientReferral.clientGuid = model.clientAssessment.clientGuid;
        this.setReferralClientGuidValue(model.clientAssessment.clientGuid);
        model.clientAssessment.referralGuid = newReferralGuid;
        this.setAssessmentReferralGuidValue(newReferralGuid);
        model.clientAssessment.referred = true;
        this.setAssessmentReferredValue(true);
      }

      var response = await lastValueFrom(this.dsDataService.saveAssessmentWithReferral(model));
      try {
        console.log(response);
        const keys = JSON.parse(response.data);
        if (this.newAssessment) {
          this.setAssessmentGuidValue(keys.assessmentGuid);
          this.setAssessmentClientGuidValue(keys.clientGuid);
          this.newAssessment = false;
        }
        if (this.newReferral) {
          this.setReferralGuidValue(keys.referralGuid);
          this.setReferralClientGuidValue(keys.clientGuid)
          this.newReferral = false;
        }
        // on successful save flag that we have a valid assessment
        this.validAssessment = true;
        this.mainForm.reset(this.mainForm.value);
      }
      catch (error) {
        console.log(error);
        this.app.loginSvc.LogError(error);
      }
    }
  }

  getReferralOutsideProviderModel(): Partial<ExternalProvidersModel> {
    var model:Partial<ExternalProvidersModel> = new ExternalProvider();
    model.dateTime = formatISO(model.dateTime ?? this.currentDate, { representation: 'date' });

    return model;
  }

  getAssessmentWithReferralModel(): Partial<ClientAssessmentReferralModel> {
    var model:Partial<ClientAssessmentReferralModel> = new ClientAssessmentReferral();
    model.clientAssessment = this.getAssessmentModel();
    model.clientReferral = this.getReferralModel();

    return model;
  }

  getAssessmentModel(): Partial<ClientAssessmentModel> {
    var model:Partial<ClientAssessmentModel> = new ClientAssessment(this.mainForm.value.assessment);

    // removed time component, when time was being included and formatISO was not used to convert the date then every save added 4 hours to the datetime (not UTC compliant)
    // also need a string formatted date for API call to work
    model.guid = model.guid ?? '00000000-0000-0000-0000-000000000000';
    model.assessmentDate = formatISO(model.assessmentDate, { representation: 'date' });
    model.expired = formatISO(model.expired ?? this.originDate, { representation: 'date' });
    model.datetime = formatISO(model.datetime ?? this.originDate, { representation: 'date' });
    model.deleted = false;
    model.userId = this.userService.userID;
    model.recSource = model.recSource ?? '4';
    model.notes = model.notes ?? '';
    // since p radio button doesn't support boolean, need to switch PastHistory values from boolean to numeric and later 
    // in getAssessmentModel function convert it back to boolean to pass to the Web API    
    model.pastHistory = (this.getAssessmentPastHistoryValue() == this.YesNoEnum.Yes);

    // this.getAssessmentCurrentSubstanceAbuseValue
    // if (ca?.assessmentType == AssessmentType.SubstanceAbuse) this.setAssessmentCurrentYesNoValue(ca?.currentSubstanceAbuse);
    // if (ca?.assessmentType == AssessmentType.MentalHealth) this.setAssessmentCurrentYesNoValue(ca?.mentalHealthIssuesIdentified);

    return model;
  }

  getReferralModel(): Partial<ClientReferralModel> {
    var model:Partial<ClientReferralModel> = new ClientReferral(this.mainForm.value.referral);

    if (this.getAssessmentOutcomeValue() != AssessmentOutcome.Referral) { 
      model = null; }
    else {
      // removed time component, when time was being included and formatISO was not used to convert the date then every save added 4 hours to the datetime (not UTC compliant)
      // also need a string formatted date for API call to work    
      model.expired = formatISO(model.expired ?? this.originDate, { representation: 'date' });
      model.contactedReferrerDate = formatISO(model.contactedReferrerDate ?? this.originDate, { representation: 'date' });
      model.completedDate = formatISO(model.completedDate ?? this.originDate, { representation: 'date' });
      model.lostToFollowupDate = formatISO(model.lostToFollowupDate ?? this.originDate, { representation: 'date' });
      model.statusChangeDate = formatISO(model.statusChangeDate ?? this.originDate, { representation: 'date' });
      model.datetime = formatISO(model.datetime ?? this.currentDate, { representation: 'date' });
      model.deleted = false;
      model.userId = this.userService.userID;
      model.recSource = model.recSource ?? '4';
      model.canProvideService = model.canProvideService ?? 0;
      model.canProvideRefusal = model.canProvideRefusal ?? 0;
      model.clinicId = model.clinicId ?? 0;
      model.completedNotes = model.completedNotes ?? '';
      model.confirmStatus = model.confirmStatus ?? 0;
      model.contactedReferrer = model.contactedReferrer ?? false;
      model.expiring = model.expiring ?? -1;
      model.period = model.period ?? -1;
      model.referralStatus = model.referralStatus ?? 1;
      model.referredClientId = model.referredClientId ?? 0;
      model.sourceClinicId = model.sourceClinicId ?? 0;
      model.statusChangeNote = model.statusChangeNote ?? '';
      model.willProvideRefusal = model.willProvideRefusal ?? 0;
      model.willProvideService = model.willProvideService ?? 0;
      model.providerId = model.providerId ?? 0;
      model.outsideProviderId = model.outsideProviderId ?? 0;
    }

    return model;
  }

  // have to treat PastHistory as a numeric value since radio button doesn't support boolean
  // onChangePastHistory(value:boolean) {
  //   this.setAssessmentPastHistoryValue(value);
  // }

  onChangeAssessmentCurrentYesNo(value:number){
    //this.assessmentOutComeEnabled = this.assessmentFollowupEnabled = (parseInt(this.getAssessmentCurrentYesNoValue()) == YesNo.Yes);
    if (value >= 0) this.assessmentOutComeEnabled = this.assessmentFollowupEnabled = (value == YesNo.Yes);
    this.setAssessmentCurrentYesNoValue(value);
    if (this.getAssessmentCurrentYesNoValue() == YesNo.No) {
      this.setAssessmentFollowUpValue(0);
      this.setAssessmentOutcomeValue(-1); //AssessmentOutcome.NoReferral
    }
  }

  onChangeReferralServiceType(serviceId:number) {
    // TO DO lookup funder from selected referral contract and replace 99999 below, passing 99999 won't exclude any sub types
    this.assessmentSubTypes = this.commonDataService.getSubTypesByService(serviceId, 99999, true, true);
    this.setReferralSubTypeValue(this.assessmentSubTypes[0]?.id ?? 0);
    this.loadReferralProviders(serviceId, this.getReferralSubTypeValue());
  }

  onChangeReferralSubType(subTypeId:number) {
    this.loadReferralProviders(this.getReferralServiceTypeValue(), subTypeId);
  }

  onChangeReferralProvider(referralProviderId:number) {
    // load outside providers
    this.assessmentOutsideProviders = null;
    if (referralProviderId == 0) {
      this.assessmentOutsideProviders = this.providerService.getExternalProviders(true);
      this.assessmentOutsideProviderEnabled = true;
    }
    else
      this.assessmentOutsideProviderEnabled = false;
  }

  onClickShowSubstanceAbuseAssessmentHistory() {
    console.debug("onClickShowSubstanceAbuseAssessmentHistory");
  }  

  loadReferralProviders(serviceId:number, subTypeId:number) {
    this.assessmentReferralProviders = null;
    if (!!serviceId && !!subTypeId)
    {
      var year = this.getAssessmentDeliveredDateYearValue();
      var month = this.getAssessmentDeliveredDateMonthValue();
      var date = new Date(); var rangeDate = new Date(year, month, 1);
      this.assessmentReferralProviders = this.providerService.getReferralProviders(serviceId, subTypeId, rangeDate, true);
    }
    this.setReferralProviderValue(!!this.assessmentReferralProviders ? this.assessmentReferralProviders[0]?.id : 0);
  }

  loadAssessment(selectedDirectService:Partial<ClientDirectServiceModel>) {
    this.loadAssessmentSettings(selectedDirectService);

    this.assessmentFunders = this.commonDataService.getCommonSetByName("Funder", true);
    // TO DO lookup funder from selected referral contract and replace 99999 below, passing 99999 won't exclude any services
    this.assessmentServiceTypes = this.commonDataService.getServiceCategories(99999, true);
    this.assessmentEmployees = this.providerService.getProviderEmployeeList(true);
    
    // TODO: SHOW DELETED ASSESSMENTS AT A LATER TIME, SHOW AS DISABLED AND SHOW DATE DELETED
    //assessment data
    var ca = selectedDirectService.clientAssessment;
    //this.setAssessmentExpiredDateValue(selectedDirectService.deliveredDate); // for testing
    this.setAssessmentExpiredDateValue(ca?.expired ?? this.originDate);
    this.setAssessmentDeliveredDateValue(selectedDirectService.deliveredDate ?? this.originDate);
    this.setAssessmentGuidValue(ca?.guid ?? '00000000-0000-0000-0000-000000000000');
    this.setAssessmentClientGuidValue(ca?.clientGuid ?? selectedDirectService.clientGuid);
    this.setAssessmentDirectServiceGuidValue(ca?.directServiceGuid ?? selectedDirectService.guid)      
    this.setAssessmentDateValue(ca?.assessmentDate ?? selectedDirectService.deliveredDate);
    this.setAssessmentTypeValue(ca?.assessmentType ?? selectedDirectService.subTypeAssessmentId);
    this.setAssessmentOutcomeValue(ca?.assessmentOutcome ?? -1);
    this.setAssessmentFollowUpValue(ca?.followUp ?? 0);
    this.setAssessmentPastHistoryValue(ca?.pastHistory ?? false);
    this.setAssessmentReferredValue(ca?.referred ?? false);
    this.setAssessmentReferralGuidValue(ca?.referralGuid ?? '99999999-9999-9999-9999-999999999999');
    this.setAssessmentUserIdValue(ca?.userId ?? this.userService.userID);
    this.setAssessmentCurrentSubstanceAbuseValue(ca?.currentSubstanceAbuse ?? -1);
    this.setAssessmentMentalHealthIssuesIdentifiedValue(ca?.mentalHealthIssuesIdentified ?? -1);
    //if (ca?.assessmentType == AssessmentType.SubstanceAbuse) this.setAssessmentCurrentYesNoValue(ca?.currentSubstanceAbuse);
    //if (ca?.assessmentType == AssessmentType.MentalHealth) this.setAssessmentCurrentYesNoValue(ca?.mentalHealthIssuesIdentified);
    var currentYesNoValue = -1;
    if (ca?.assessmentType == AssessmentType.SubstanceAbuse) currentYesNoValue = ca?.currentSubstanceAbuse ?? -1;
    if (ca?.assessmentType == AssessmentType.MentalHealth) currentYesNoValue = ca?.mentalHealthIssuesIdentified ?? -1;
    this.onChangeAssessmentCurrentYesNo(currentYesNoValue);

    if (!!ca && !this.isExpired()) this.validAssessment = true; else this.newAssessment = true;

    // TODO: SHOW DELETED REFERRALS AT A LATER TIME, SHOW AS DISABLED AND SHOW DATE DELETED
    //referral data
     var cr = selectedDirectService.clientReferral;
    if (!ca && !!cr) {
      // if a referral exists then override the Current Outcome
      this.setAssessmentOutcomeValue(AssessmentOutcome.Referral);
      this.assessmentFormTitle = "Referral";
      this.assessmentQuestion0Text = "Referral Date";
    }
    this.setReferralGuidValue(cr?.guid ?? '00000000-0000-0000-0000-000000000000');
    this.setReferralClientGuidValue(cr?.clientGuid ?? '00000000-0000-0000-0000-000000000000');
    this.setReferralDirectServiceGuidValue(cr?.directServiceGuid ?? '00000000-0000-0000-0000-000000000000');
    this.setReferralContractGuidValue(cr?.contractGuid ?? '00000000-0000-0000-0000-000000000000');
    this.setReferralFunderIdValue(cr?.funderId ?? 0);
    this.setReferralServiceTypeValue(cr?.serviceId ?? 0);
    this.onChangeReferralServiceType(cr?.serviceId);
    this.setReferralSubTypeValue(cr?.subId ?? 0);
    this.onChangeReferralSubType(cr?.subId);
    if (!!cr?.providerId) {
      this.setReferralProviderValue(cr?.providerId);
      this.onChangeReferralProvider(cr?.providerId);
      //if (cr?.providerId == -1) this.setReferralOutsideProviderValue(cr?.outsideProviderId);
    }
    if (!!cr?.outsideProviderId) this.setReferralOutsideProviderIdValue(cr?.outsideProviderId);
    this.setReferralEmployeeValue(cr?.employeeId ?? 0);
    this.setReferralConfirmLocationValue(cr?.confirmLocation ?? '');
    this.setReferralMemoValue(cr?.memo ?? '');
    this.setReferralDateTimeValue(cr?.datetime ?? this.currentDate)
  }

  loadAssessmentSettings(selectedDirectService:Partial<ClientDirectServiceModel>) {
    // reset values every time load is called
    this.assessmentFormTitle = "";
    this.assessmentQuestion0Text = this.assessmentQuestion1Text = this.assessmentQuestion2Text = "";
    // change defaults from true to false
    this.assessmentQuestion1Visible = this.assessmentQuestion2Visible = false;
    this.assessmentFollowupVisible = this.assessmentOutComeVisible = false;     
    this.assessmentFollowupEnabled = this.assessmentOutComeEnabled = this.assessmentScreeningOnly = false;

    if (this.commonDataService.isSubTypeMHScreening(selectedDirectService.subId) ||
        this.commonDataService.isSubTypeSAScreening(selectedDirectService.subId)) {
          this.assessmentScreeningOnly = true;
    }
    if (!this.assessmentScreeningOnly) {
      this.assessmentIncludedSubtype = this.commonDataService.getMHScreeningIncludedSubTypes(selectedDirectService.subTypeInclusionIds)[0];
      if (this.assessmentIncludedSubtype > 0) {
        this.assessmentScreeningOnly = true;
      }
      else {
        this.assessmentIncludedSubtype = this.commonDataService.getSAScreeningIncludedSubTypes(selectedDirectService.subTypeInclusionIds)[0];
        if (this.assessmentIncludedSubtype > 0) this.assessmentScreeningOnly = true;
      }
    }

    if (selectedDirectService.subTypeAssessmentId == AssessmentType.SubstanceAbuse) {
      this.assessmentFormTitle = "Substance Abuse " + (this.assessmentScreeningOnly ? "Screening" : "Assessment");
      this.assessmentQuestion0Text = this.assessmentFormTitle + " Date"      
      this.assessmentQuestion1Text = "Is there a past history of substance abuse?";
      this.assessmentQuestion2Text = "Is there current substance abuse?";
      this.assessmentQuestion1Visible = this.assessmentQuestion2Visible = this.assessmentOutComeVisible = true;
      this.assessmentFollowupVisible = this.assessmentScreeningOnly;
    }
    else if (selectedDirectService.subTypeAssessmentId == AssessmentType.MentalHealth) {
      this.assessmentFormTitle = "Mental Health " + (this.assessmentScreeningOnly ? "Screening" : "Assessment");
      this.assessmentQuestion0Text = this.assessmentFormTitle + " Date"         
      this.assessmentQuestion1Text = "Is there a past history of mental health issues?";
      this.assessmentQuestion2Text = "Were mental health issues identified?";
      this.assessmentQuestion1Visible = this.assessmentQuestion2Visible = this.assessmentOutComeVisible = true;
      this.assessmentFollowupVisible = this.assessmentScreeningOnly;
    }
    else if (selectedDirectService.subTypeAssessmentId == AssessmentType.InitialInterview) {
      this.assessmentFormTitle = "Initial Interview Assessment";
      this.assessmentQuestion0Text = "Initial Interview Delivered Date";
      this.assessmentOutComeVisible = this.assessmentOutComeEnabled = true;
    }
    else if (selectedDirectService.subTypeAssessmentId == AssessmentType.MICA) {
      this.assessmentFormTitle = "MICA Assessment/Screening";
      this.assessmentQuestion0Text = "MICA Delivered Date";
      this.assessmentOutComeVisible = this.assessmentOutComeEnabled = true;
    }
    else if (selectedDirectService.subTypeAssessmentId == AssessmentType.Initial) {
      this.assessmentFormTitle = "Initial Assessment";
      this.assessmentQuestion0Text = "Initial Delivered Date";
      this.assessmentOutComeVisible = this.assessmentOutComeEnabled = true;
    }         
    else if (selectedDirectService.subTypeAssessmentId == AssessmentType.Nutritional) {
      this.assessmentFormTitle = "Nutritional Screening";
      this.assessmentQuestion0Text = "Nutritional Delivered Date";
      this.assessmentOutComeVisible = this.assessmentOutComeEnabled = true;
    }
    else if (selectedDirectService.subTypeAssessmentId == AssessmentType.Reassessment) {
      this.assessmentFormTitle = "Reassessment";
      this.assessmentQuestion0Text = "Reassessment Delivered Date";
      this.assessmentOutComeVisible = this.assessmentOutComeEnabled = true;
    }      
  }

  futureDateValidator(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const today = new Date().getTime();
      if(!(control && control.value)) {  // if there's no control or no value, that's ok
        return null;
      }
      var value = new Date(control.value);
      // remove hours minutes seconds and milliseconds
      value.setHours(0,0,0,0);
      // return null if there's no errors
      return value.getTime() > today ? {invalidDate: '*Invalid future date.' }  : null;
    }
  }

  public funderValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const funderControl = this.getReferralFunderControl();
      const outcomeControl = this.getAssessmentOutcomeControl();

      if (!funderControl || !outcomeControl) return null;

      if (this.isReferralSelected() && ((this.getReferralFunderValue() ?? 0) <= 0))
      {
        return { invalidFunder: '*Funder is required.' };
      }

      return null;
    }
  }
  
  public serviceIdValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const serviceTypeControl = this.getReferralServiceTypeControl();
      const outcomeControl = this.getAssessmentOutcomeControl();

      if (!serviceTypeControl || !outcomeControl) return null;

      if (this.isReferralSelected() && ((this.getReferralServiceTypeValue() ?? 0) <= 0))
      {
        return { invalidServiceId: '*Service Category is required.' };
      }

      return null;
    }
  }

  public subIdValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const subTypeControl = this.getReferralSubTypeControl();
      const outcomeControl = this.getAssessmentOutcomeControl();

      if (!subTypeControl || !outcomeControl) return null;

      if (this.isReferralSelected() && ((this.getReferralSubTypeValue() ?? 0) <= 0))
      {
        return { invalidSubId: '*Sub Type is required.' };
      }

      return null;
    }
  }

  public providerIdValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const providerControl = this.getReferralProviderControl();
      const outcomeControl = this.getAssessmentOutcomeControl();

      if (!providerControl || !outcomeControl) return null;

      if (this.isReferralSelected() && ((this.getReferralProviderValue() ?? 0) == 0))
      {
        return { invalidProviderId: '*Provider is required.' };
      }

      return null;
    }
  }

  public outsideProviderIdValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const providerControl = this.getReferralProviderControl();
      const outsideProviderControl = this.getReferralOutsideProviderIdControl();
      const outcomeControl = this.getAssessmentOutcomeControl();

      if (!providerControl || !outcomeControl || !outsideProviderControl) return null;

      if (this.isReferralSelected() && ((this.getReferralOutsideProviderIdValue() ?? 0) <= 0) && ((this.getReferralProviderValue() ?? 0) == -1))
      {
        return { invalidOutsideProviderId: '*Outside Provider is required.' };
      }

      return null;
    }
  }

  public employeeIdValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const employeeControl = this.getReferralEmployeeControl();
      const outcomeControl = this.getAssessmentOutcomeControl();

      if (!employeeControl || !outcomeControl) return null;

      if (this.isReferralSelected() && ((this.getReferralEmployeeValue() ?? 0) <= 0))
      {
        return { invalidEmployeeId: '*Employee is required.' };
      }

      return null;
    }
  }
  
  public outsideProviderNameValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const outsideProviderNameControl = this.getReferralOutsideProviderNameControl();
      const outcomeControl = this.getAssessmentOutcomeControl();

      if (!outsideProviderNameControl || !outcomeControl || !this.displayOutsideProviderDialog) return null;

      var outsideProviderName:string = this.getReferralOutsideProviderNameValue() ?? '';
      if (this.isReferralSelected() && (outsideProviderName.length <= 0))
      {
        return { invalidOutsideProviderName: '*Outside Provider Name is required.' };
      }

      return null;
    }
  }

  public assessmentOutcomeValidator(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const assessmentOutcomeControl = this.getAssessmentOutcomeControl();
      const outcomeControl = this.getAssessmentOutcomeControl();
      const currentYesNoControl = this.getAssessmentCurrentYesNoControl();

      if (!assessmentOutcomeControl || !outcomeControl || !currentYesNoControl) return null;

      var assessmentOutcome = this.getAssessmentOutcomeValue();

      //if ((!assessmentOutcome || assessmentOutcome < AssessmentOutcome.ProvidedOnSite) && this.getAssessmentCurrentYesNoValue() == YesNo.Yes)
      if ((!assessmentOutcome || assessmentOutcome < AssessmentOutcome.ProvidedOnSite) && this.assessmentOutComeEnabled)
      {
        return { invalidAssessmentOutcome: '*Assessment Outcome is required.' };
      }

      return null;
    }
  }  

}
