<!-- <div>displayRequiredFieldErrorsDialog : {{displayRequiredFieldErrorsDialog | json}} </div> -->
<div *ngIf="displayRequiredFieldErrorsDialog"> <!--  -->
    <p-dialog header="Missing Required Data" 
            [(visible)]="alwaysVisible" position="center" [draggable]="true" showEffect="explode"
            [resizable]="false" [modal]="true" [maximizable]="true" appendTo="body"
            (onHide)="onHideRequiredFieldErrorsDialog()"
            [style]="{width: '60vw'}" [contentStyle]="{height: '400px'}">
        <ng-container *ngIf="(clientRequiredFieldErrors$ | async) as reqFldData"> <!-- else loading -->
            <p-table [value]="reqFldData" styleClass="p-datatable-sm p-datatable-striped" [scrollable]="true" scrollDirection="both" scrollHeight="30vh"> <!-- [scrollable]="true" scrollHeight="flex" -->
                <ng-template pTemplate="header">
                    <tr>
                        <th style="min-width:200px; max-width:100px" pSortableColumn="fieldDescription">Field Name<p-sortIcon field="fieldDescription"></p-sortIcon></th>
                        <th style="min-width:200px; max-width:100px" pSortableColumn="fieldCategoryDescription">Field Category<p-sortIcon field="fieldCategoryDescription"></p-sortIcon></th>
                        <th style="min-width:200px; max-width:100px" pSortableColumn="serviceCategoryDescription">Service Category Affected<p-sortIcon field="serviceCategoryDescription"></p-sortIcon></th>
                        <th style="min-width:500px; max-width:500px" pSortableColumn="errDesc">Required Field Error<p-sortIcon field="errDesc"></p-sortIcon></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-clientRequiredFieldError>
                    <tr>
                        <td style="min-width:200px; max-width:100px"><span title="{{clientRequiredFieldError.fieldDescription}}">{{clientRequiredFieldError.fieldDescription}}</span></td>
                        <td style="min-width:200px; max-width:100px"><span title="{{clientRequiredFieldError.fieldCategoryDescription}}">{{clientRequiredFieldError.fieldCategoryDescription}}</span></td>
                        <td style="min-width:200px; max-width:100px"><span title="{{clientRequiredFieldError.serviceCategoryDescription}}">{{clientRequiredFieldError.serviceCategoryDescription}}</span></td>
                        <td style="min-width:500px; max-width:500px">{{clientRequiredFieldError.errDesc}}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                    <tr>
                        <td colspan="4">Count: {{reqFldData?.length}}</td>
                    </tr>
                </ng-template>
            </p-table>
        </ng-container>
        <!-- <ng-template pTemplate="footer">
            <button type="button" pButton pRipple icon="pi pi-times" (click)="dialogVisible=false" label="Dismiss" class="p-button-text"></button>
        </ng-template> -->
        <!-- <ng-template #loading>
            <div class="champ-notification-loading">Loading required field errors...</div>
        </ng-template>     -->
    </p-dialog>
</div>    