<!-- <p>user activity works </p> -->

<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='fg' autocomplete="off">
    <div class="grid " style="margin-bottom: 1rem;">
        <div class="mr-8" >
            <h3><b> User Activity</b></h3>
        </div>
    
        <!-- <div class="col-md-1" style="width: 12.499999995%"></div>
        Since col-md-1 is of width 8.33333333%; simply multiply 8.33333333 * 1.5 and set it as your width. -->

        <div class="field mr-4" style="width: 20rem;"  > <!-- style="width: 10.5%; flex: 0 0 10.5%;max-width: 10.5%;" -->
            <label  class="mb-0" style="margin-right:1rem ;" htmlFor="lastXMinutes">Within the last "XX" of minutes:</label>  
            <div class="col">
                <p-inputNumber formControlName="lastXMinutes" inputId="lastXMinutes" [ngStyle]="{'width':'3rem'}" (onBlur)="onParamChange($event)" ></p-inputNumber>
            </div>                          
       </div>

        <div class="field mr-2"> <!--  style="width:9.5%; flex: 0 0 9.5%;max-width: 9.5%; margin-right: 2rem;" -->
            <label  class="mb-0" for="lastEventOnly" style="margin-right:1rem">Show Last User Event only</label>
            <div class="col">
                <p-checkbox formControlName="lastEventOnly" [binary]="true" inputId="lastEventOnly" (onChange)="onParamChange($event)"></p-checkbox>
            </div>
        </div>

        <div class="field mr-2" style="padding-right:2rem">
            <label  class="mb-0" htmlFor="provider" style="margin-right:1rem">Provider</label>
            <div class="col">
                <p-dropdown formControlName="providerId" inputId="provider" [options]="providers" optionLabel="text" optionValue="value"
                            (onChange)="onParamChange($event)" ></p-dropdown>
            </div>
        </div> 

        <div class="field mr-2">
            <label class="mb-0" htmlFor="user" style="margin-right:1rem">User</label>
            <div class="col">
                <p-dropdown formControlName="userId" inputId="user" [options]="users" optionLabel="text" optionValue="value"
                            [virtualScroll]="true" [virtualScrollItemSize]="50"
                            (onChange)="onParamChange($event)"></p-dropdown>
            </div>
        </div> 

        <div class="fields formgrid" style="margin-top:2rem; margin-left:1rem">
            <span class="flex justify-content-end" style="width: 100%;">
                <span style="align-content: center; display: grid;">CHAMP 3.5</span>
                <div style="padding-left: .5rem; padding-right: .5rem">
                    <p-inputSwitch formControlName="logTypeSeriLog" (onChange)="onParamChange($event)"></p-inputSwitch>
                </div>
                <span style="align-content: center; display: grid;">CHAMP 4</span>
            </span>            
        </div>    

        <div class="field mr-2" style="margin-top:1rem">
            <!-- <button #refresh pButton type="button" label="Refresh" class="p-button-link" style="width: 10rem;" (click)="onParamChange($event)"></button> -->
            <p-button pButton pRipple type="button" [ngStyle]="{'width':'12rem'}" styleClass="p-button-text note-button" 
                    class="p-button-help p-button-text p-button mr-4 ml-0 mt-1 " (click)="onParamChange($event)" >
                        <i class="fa-sharp fa-refresh" style="color:blue" pTooltip="Refresh user activity list."  ></i>
                    <span class="ml-2 font-bold">Refresh</span>
            </p-button>
        </div> 

        <!-- <div class="col-3"> -->
                <!-- <label class="mr-2" for="clientID">Client ID:</label>
                <input type="text" class="form-control" id="clientID"  (change)="onChangeClientID($event)"   [(ngModel)]="_clientID" >  -->
        <!-- </div>         -->

        <!-- <div class="col-sm-5">

        </div> -->
      



</div>



    <div class="container-fluid" id="grid" > 

        <p-table #dtEvents [value]="getAuditEvents()"    [scrollable]="true"  scrollDirection="both"  scrollHeight="55rem"  styleClass="p-datatable-sm p-datatable-striped"                > <!-- selectionMode="single"  dtEvents editMode="row"  
                scrollHeight="40rem" scrollDirection="both" 
                -->  
            <ng-template pTemplate="header">
                <tr>
                    <!-- <th style="width:10rem"><button pButton pRipple label="New" icon="pi pi-plus" class="btn btn-primary mr-2" (click)="onRowAdd($event, dtProducts)" [disabled]="isRowInEditMode" style="width: 5rem !important; height: 2rem !important; padding: .5rem"></button></th>                                 -->
                    <th pSortableColumn="auditId" style="width:8rem; ">Audit ID<p-sortIcon field="auditId" ></p-sortIcon></th>
                    <th pSortableColumn="userId" style="width:10rem; ">User ID<p-sortIcon field="userId" ></p-sortIcon></th>
                    <th pSortableColumn="hostName" style="width:15rem;">Host Name<p-sortIcon field="hostName" ></p-sortIcon></th>
                    <th pSortableColumn="provider" style="width:20rem;">Provider<p-sortIcon field="provider" ></p-sortIcon></th>                                
                    <th pSortableColumn="lastEvent" style="width:30rem; ">Event<p-sortIcon field="lastEvent" ></p-sortIcon></th>
                    <th pSortableColumn="datetime" style="width:13rem; ">Date time<p-sortIcon field="datetime" ></p-sortIcon></th>
                    <th pSortableColumn="assemblyName" style="width:10rem; ">Assembly Name<p-sortIcon field="assemblyName" ></p-sortIcon></th>
                    <th pSortableColumn="assemblyVersion" style="width:10rem; ">Assembly Version<p-sortIcon field="assemblyVersion" ></p-sortIcon></th>
                    <th pSortableColumn="serverAssemblyVersion" style="width:15rem; ">Server Assembly Version<p-sortIcon field="serverAssemblyVersion" ></p-sortIcon></th>
                </tr>
                <!-- <tr>
                    <th style="width:10rem"></th>
                    <th style="width:8rem"></th>
                    <th style="width:8rem"></th>
                    <th style="width:8rem"></th>
                    <th style="width:8rem"></th>  
                    <th style="width:8rem"></th>                                   
                    <th style="width:8rem"></th>    
                    <th style="width:10rem"> </th>    
                    <th style="width:15rem"> </th>                             
                </tr>                             -->
            </ng-template>
            <ng-template pTemplate="body" let-event let-editing="editing" let-rowIndex="rowIndex">
                <tr >  <!-- [pEditableRow]="event" -->
                    <td style="width:8rem; "> {{ event?.auditId }}  </td>
                    <td style="width:10rem; "> {{ event?.userId }}   </td>
                    <td style="width:15rem; "> {{ event?.hostName }} </td>
                    <td style="width:20rem; "> {{ event?.provider }} </td>
                    <td style="width:30rem; "> {{ event?.lastEvent}} </td>
                    <td style="width:13rem; "> {{ event?.datetime | date:'MM/dd/yyyy  hh:mm:ss aa' }}  </td>
                    <td style="width:10rem; "> {{ event?.assemblyName }} </td>
                    <td style="width:10rem; "> {{ event?.assemblyVersion }} </td>
                    <td style="width:15rem; "> {{ event?.serverAssemblyVersion }} </td>
                    </tr>
            </ng-template>
            <ng-template pTemplate="footer">
                <tr >
                    <td colspan="15">Count: {{getAuditEvents()?.length}}</td>
                </tr>
            </ng-template>   
        </p-table>                    
    </div> 
</form>    