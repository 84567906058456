import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ErrorReportRequestModel, ErrorReportResponseModel } from 'src/app/models/admin.models';

@Injectable({
  providedIn: 'root'
})
export class ErrorLogService {
  private readonly URL = environment.apiURL + '/admin';

  constructor(private http: HttpClient) { 
  }

  public getErrors (request: ErrorReportRequestModel) {
    let url = this.URL + '/errors';
    return this.http.post<ErrorReportResponseModel[]>(url, request);
  }  
}
