import { Component, OnInit } from '@angular/core';
import { UserModel, LoginRequest, LoginResponse } from './services/login-models';
import { LoginService } from './services/login.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';



@Component({
  selector: 'app-logout',
  templateUrl: './app.logout.component.html'
})
export class AppLogoutComponent implements OnInit {

  constructor(private loginService: LoginService, private router: Router ) { }

  ngOnInit(): void {
    this.logout();
  }


  logout () {
    //this.loginService.logout();
  ///  if  (! this.loginService.loggedin )
   //      this.router.navigate(['/']).then(); 

  }

}
