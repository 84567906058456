<div>
    <p-toast></p-toast>
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='mainForm' autocomplete="off"> <!-- (onShow)="onShowDialog() header="{{ assessmentFormTitle }}"  (onHide)="onHideDialog($event)"" [autoAlign]="false" [style]="{width: '725px', minWidth: '725px'}"-->
    <p-dialog [(visible)]="displayAssessmentDialog" id="AssessmentModal" [closable]="allowExit"
              [modal]="true" position="center" [resizable]="false" [draggable]="false" showEffect="explode"
              [responsive]="true" [style]="{width: '765px', minWidth: '765px', maxHeight: '150%'}">
        <p-header style="flex-wrap: nowrap; align-items: baseline; display: flex;">
            {{ assessmentFormTitle }} &nbsp;<div *ngIf="!readOnly" style="font-size: .75em; vertical-align:bottom;">(please provide information below)</div>
        </p-header>
        <ng-template pTemplate="content">
            <div class="card champ-card" [ngClass]="{'disableDiv': isExpired() || readOnly}">
                <div class="card-body champ-card-body">
            <!--             
            <div class="card champ-card champ-card-bottom">
            main form valid : {{mainForm?.valid | json}}
            <br>
            main form errors : {{mainForm.errors | json}}
            <br>
            assessment form valid : {{mainForm?.get('assessment').valid | json}}
            <br>
            referral form valid : {{mainForm?.get('referral').valid | json}}
            <br>
            referral funder value : {{mainForm?.get('referral.funderId').value | json}}
            </div>            
            <div class="card champ-card champ-card-bottom">
            1 assessment value : {{mainForm['controls'].assessment['controls'].assessmentDate.value | json}}
            <br>    
            2 mainForm assessment value : {{mainForm?.get('assessment.assessmentDate').value | json}}
            <br>
            mainForm assessment valid : {{mainForm?.get('assessment.assessmentDate').valid | json}}
            <br>
            mainForm assessment dirty : {{mainForm?.get('assessment.assessmentDate').dirty | json}}
            <br>
            mainForm assessment touched : {{mainForm?.get('assessment.assessmentDate').touched | json}}
            <br>
            mainForm assessment errors : {{mainForm?.get('assessment.assessmentDate').errors | json}}
            </div> -->
                    <div formGroupName="assessment" class="fluid formgrid grid" *ngIf="isExpired()">
                        <div class="field col-12" style="height: 40px;">
                            <div class="fluid formgrid grid champ-grid">
                                <div class="field col-6">
                                    <label for="expired">Expired On :</label>
                                </div>
                                <div class="field col-4">
                                    <input type="text" formControlName="expired" autocomplete="off" bsDatepicker
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"    
                                    placeholder="Date"
                                    class="champ-date-picker" pInputText/>
                                    <div *ngIf="isExpired()">
                                        <small id="assessmentDate-help" class="p-invalid">*Assessment is expired.</small>
                                    </div>                        
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formGroupName="assessment" class="fluid formgrid grid">
                        <div class="field col-12" style="height: 40px;">
                            <div class="fluid formgrid grid champ-grid">
                                <div class="field col-6">
                                    <label for="assessmentDate">{{assessmentQuestion0Text}}</label>
                                </div>
                                <div class="field col-2">
                                    <input type="text" formControlName="assessmentDate" autocomplete="off" bsDatepicker
                                    [bsConfig]="{ dateInputFormat: 'MM-DD-YYYY', showWeekNumbers: false }"    
                                    placeholder="Date"
                                    class="champ-date-picker" pInputText/>
                                </div>
                            </div>
                        </div>
                        <div class="field col-12" *ngIf="mainForm?.get('assessment.assessmentDate').errors">
                            <div class="fluid formgrid grid champ-grid">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-6">
                                        <div *ngIf="mainForm?.get('assessment.assessmentDate').invalid && (mainForm?.get('assessment.assessmentDate').dirty || mainForm?.get('assessment.assessmentDate').touched)">
                                            <div *ngIf="mainForm?.get('assessment.assessmentDate').errors.required">
                                                <small id="assessmentDate-help" class="p-invalid">*Date is required.</small>
                                            </div>
                                            <div *ngIf="mainForm?.get('assessment.assessmentDate').errors"> 
                                                <small id="assessmentDate-help" class="p-invalid">{{mainForm?.get('assessment.assessmentDate').errors['invalidDate']}}</small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formGroupName="assessment" class="fluid formgrid grid" *ngIf="assessmentQuestion1Visible">
                        <div class="field col-12" style="height: 40px;">
                            <div class="fluid formgrid grid champ-grid">
                                <div class="field col-6">
                                    <label for="pastHistory">{{assessmentQuestion1Text}}</label>
                                </div>
                                <div class="field col-1">
                                    <p-radioButton formControlName="pastHistory" value=1 label="Yes"></p-radioButton> <!-- (click)="onChangePastHistory(1)" -->
                                </div>                            
                                <div class="field col-1">
                                    <p-radioButton formControlName="pastHistory" value=0 label="No"></p-radioButton> <!-- (click)="onChangePastHistory(0)" -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formGroupName="assessment" class="fluid formgrid grid" *ngIf="assessmentQuestion2Visible">
                        <div class="field col-12" style="height: 40px;">
                            <div class="fluid formgrid grid champ-grid">
                                <div class="field col-6">
                                    <label for="assessmentCurrentYesNo">{{assessmentQuestion2Text}}</label>
                                </div>
                                <div class="field col-1">
                                    <p-radioButton formControlName="assessmentCurrentYesNo" value=1 label="Yes" (click)="onChangeAssessmentCurrentYesNo(1)"></p-radioButton>
                                </div>                            
                                <div class="field col-1">
                                    <p-radioButton formControlName="assessmentCurrentYesNo" value=0 label="No" (click)="onChangeAssessmentCurrentYesNo(0)"></p-radioButton>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formGroupName="assessment" *ngIf="assessmentFollowupVisible" [ngClass]="{'disableDiv': !assessmentFollowupEnabled}">
                        <div class="fluid formgrid grid">
                            <div class="field col-12" style="height: 40px;">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-6">
                                        <label for="followUp">Follow-up / Treatment Plan Documented?</label>
                                    </div>
                                    <div class="field col-1">
                                        <p-radioButton formControlName="followUp" value=1 label="Yes"></p-radioButton>
                                    </div>                                
                                    <div class="field col-1">
                                        <p-radioButton formControlName="followUp" value=0 label="No"></p-radioButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formGroupName="assessment" *ngIf="assessmentOutComeVisible" [ngClass]="{'disableDiv': !assessmentOutComeEnabled}">
                        <div class="fluid formgrid grid">
                            <div class="field col-12" style="height: 40px;">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-6">
                                        <label for="assessmentOutcome">Please provide the outcome :</label>
                                    </div>
                                    <div class="field col-2" style="width: 20.833333325%; flex: 0 0 20.833333325%;max-width: 20.833333325%;">
                                        <p-radioButton formControlName="assessmentOutcome" value=2 label="Client Referral"></p-radioButton>
                                    </div>
                                    <div class="field col">
                                        <p-radioButton formControlName="assessmentOutcome" value=3 label="Patient Declined Service"></p-radioButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fluid formgrid grid">
                            <div class="field col-12" style="height: 40px;">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-6">
                                    </div>
                                    <div class="field col-2" style="width: 20.833333325%; flex: 0 0 20.833333325%;max-width: 20.833333325%;">
                                        <p-radioButton formControlName="assessmentOutcome" value=5 label="In Treatment"></p-radioButton>
                                    </div>
                                    <div class="field col">
                                        <p-radioButton formControlName="assessmentOutcome" value=1 label="Provide On Site Service"></p-radioButton>
                                    </div>
                                </div>                            
                            </div>
                        </div>
                        <div class="fluid formgrid grid">
                            <div class="field col-12" style="height: 40px;">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-6">
                                    </div>
                                    <div class="field col-2" style="width: 20.833333325%; flex: 0 0 20.833333325%;max-width: 20.833333325%;">
                                    </div>
                                    <div class="field col">
                                        <p-radioButton formControlName="assessmentOutcome" value=4 label="No Referral"></p-radioButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="fluid formgrid grid" *ngIf="!!mainForm.errors?.invalidAssessmentOutcome">
                            <div class="field col-12">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-6">
                                    </div>
                                    <div class="field col-3">
                                        <div *ngIf="!!mainForm.errors?.invalidAssessmentOutcome">
                                            <small id="assessmentOutcome-help" class="p-invalid">{{mainForm.errors?.invalidAssessmentOutcome}}</small>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div formGroupName="referral" class="fluid formgrid grid" *ngIf="isReferralSelected()">
                        <div class="fluid formgrid grid">
                            <div class="field col-12" style="padding-bottom: 1rem">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-5">
                                        <label for="funderId">Funder :</label>
                                    </div>
                                    <div class="field col-6">
                                        <p-dropdown formControlName="funderId" [options]="assessmentFunders" optionLabel="description" optionValue="id" appendTo="body"> </p-dropdown>
                                        <div *ngIf="!!mainForm.errors?.invalidFunder">
                                            <small id="funderId-help" class="p-invalid">{{mainForm.errors?.invalidFunder}}</small>
                                        </div>                            
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12" style="padding-bottom: 1rem">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-5">
                                        <label for="serviceId">Service Type :</label>
                                    </div>
                                    <div class="field col-6">
                                        <p-dropdown formControlName="serviceId" [options]="assessmentServiceTypes" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeReferralServiceType($event.value)"> </p-dropdown>
                                        <div *ngIf="!!mainForm.errors?.invalidServiceId">
                                            <small id="serviceId-help" class="p-invalid">{{mainForm.errors?.invalidServiceId}}</small>
                                        </div>                            
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12" style="padding-bottom: 1rem">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-5">
                                        <label for="subId">Sub Type :</label>
                                    </div>
                                    <div class="field col-6">
                                        <p-dropdown formControlName="subId" [options]="assessmentSubTypes" optionLabel="description" optionValue="id" appendTo="body" (onChange)="onChangeReferralSubType($event.value)"> </p-dropdown>
                                        <div *ngIf="!!mainForm.errors?.invalidSubId">
                                            <small id="subId-help" class="p-invalid">{{mainForm.errors?.invalidSubId}}</small>
                                        </div>                            
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12" style="padding-bottom: 1rem">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-5">
                                        <label for="providerId">Provider :</label>
                                    </div>
                                    <div class="field col-6">
                                        <p-dropdown formControlName="providerId" [options]="assessmentReferralProviders" optionLabel="clinicName" optionValue="providerId" appendTo="body" (onChange)="onChangeReferralProvider($event.value)"> </p-dropdown>
                                        <div *ngIf="!!mainForm.errors?.invalidProviderId">
                                            <small id="providerId-help" class="p-invalid">{{mainForm.errors?.invalidProviderId}}</small>
                                        </div>                             
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12" style="padding-bottom: 1rem" [ngClass]="{'disableDiv': !assessmentOutsideProviderEnabled}">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-5">
                                        <label for="outsideProviderId">Outside Provider :</label>
                                    </div>
                                    <div class="field col-6">
                                        <p-dropdown formControlName="outsideProviderId" [options]="assessmentOutsideProviders" optionLabel="fullName" optionValue="id" appendTo="body"> </p-dropdown>
                                        <div *ngIf="!!mainForm.errors?.invalidOutsideProviderId">
                                            <small id="outsideProviderId-help" class="p-invalid">{{mainForm.errors?.invalidOutsideProviderId}}</small>
                                        </div>                            
                                    </div>
                                    <div class="field col-1">
                                        <div pTooltip="Modify Outside Providers" style="display:inline-block;" tooltipPosition="top" *ngIf="!clientService.readonly">
                                            <!-- <button pButton pRipple class="btn" (click)="onShowOutsideProviderDialog()" icon="pi pi-ellipsis-h" 
                                                    label="" style="width: 20px; height: 20px;"></button> -->

                                            <p-button  pButton pRipple type="button"   styleClass="p-button-text " class="p-button-text mr-2 "
                                                (click)="onShowOutsideProviderDialog()"  > 
                                                <i class="fa-sharp fa-solid fa-ellipsis fa-lg"></i>
                                            </p-button>                                                       
                                        </div>
                                        <div *ngIf="!!mainForm.errors?.invalidOutsideProviderId">
                                            <small id="outsideProviderId-help" class="p-invalid" style="display: inline-block; vertical-align: top"></small>
                                        </div>                                     
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12" style="padding-bottom: 1rem">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-5">
                                        <label for="employeeId">Employee :</label>
                                    </div>
                                    <div class="field col-6">
                                        <p-dropdown formControlName="employeeId" [options]="assessmentEmployees" optionLabel="fullName" optionValue="id" appendTo="body"> </p-dropdown>
                                        <div *ngIf="!!mainForm.errors?.invalidEmployeeId">
                                            <small id="employeeId-help" class="p-invalid">{{mainForm.errors?.invalidEmployeeId}}</small>
                                        </div>                            
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12" style="padding-bottom: 1rem">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-5">
                                        <label for="confirmLocation">Primary Documentation is located at</label>
                                    </div>
                                    <div class="field col-6">
                                        <textarea formControlName="confirmLocation" rows="1" cols="50" pInputTextarea style="height:31px;"></textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="field col-12" style="padding-bottom: 1rem">
                                <div class="fluid formgrid grid champ-grid">
                                    <div class="field col-5">
                                        <label for="memo">Notes :</label>
                                    </div>
                                    <div class="field col-6">
                                        <textarea formControlName="memo" rows="2" cols="50" pInputTextarea style="height:60px;"></textarea>
                                    </div>
                                </div>
                            </div>                            
                        </div>
                    </div>
                    <div formGroupName="mental_health_gaf" class="fluid formgrid grid" *ngIf="this.getAssessmentOutcomeValue() == '-99'">
                        <div class="field col-12">
                            <div class="fluid formgrid grid champ-grid">
                                <div class="field col-5">
                                    <label for="dsAssessmentMentalHealthValue">Enter a mental health value between 0 - 100 :</label>
                                </div>                            
                                <div class="field col-2">
                                    <div style="width: 5rem;">
                                        <p-inputMask formControlName="dsAssessmentMentalHealthValue" mask="9?99" maxlength="3"></p-inputMask>
                                    </div>
                                    <!-- <div *ngIf="mainForm.controls['dsAssessmentMentalHealthValue'].invalid && (mainForm.controls['dsAssessmentMentalHealthValue'].dirty || mainForm.controls['dsAssessmentMentalHealthValue'].touched)">
                                        <div *ngIf="mainForm.controls['dsAssessmentMentalHealthValue'].errors?.required">
                                            <small id="dsAssessmentMentalHealthValue-help" class="p-invalid">*Mental health value is required.</small>
                                        </div>
                                        <div *ngIf="mainForm.controls['dsAssessmentMentalHealthValue'].errors?.min">
                                            <small id="dsAssessmentMentalHealthValue-help" class="p-invalid">*Min value is 0.</small>
                                        </div>
                                        <div *ngIf="mainForm.controls['dsAssessmentMentalHealthValue'].errors?.max">
                                            <small id="dsAssessmentMentalHealthValue-help" class="p-invalid">*Max value is 100.</small>
                                        </div>                                                                  
                                    </div>                                 -->
                                </div>
                                <div class="field col">
                                    <p-button label="History" styleClass="p-button-link champ-button-link" (click)="onClickShowSubstanceAbuseAssessmentHistory()"></p-button>
                                </div>
                            </div>
                            <!-- <div class="field col"></div> -->
                        </div>
                        <div class="field col-12">
                            <div class="fluid formgrid grid champ-grid">
                                <div class="field col-5">
                                    <label for="dsAssessmentMentalHealthRange">Select a mental health range :</label>
                                </div>
                                <div class="field col-6">
                                    <p-dropdown formControlName="dsAssessmentMentalHealthRange" [options]="assessmentMentalHealthRanges" optionLabel="fullName" optionValue="id" appendTo="body"> </p-dropdown>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="fluid formgrid grid" *ngIf="this.allowEdit">
                        <div class="field col-12"></div>
                        <div class="field col-12"></div>
                        <div class="field col-12"></div>
                        <div class="field col-12"></div>
                        <div class="field col-12">
                            <div class="fluid formgrid grid champ-grid">
                                <div class="field col">
                                    <button pButton pRipple class="btn btn-primary" (click)="onSaveChangesNoConfirm($event)" icon="pi pi-check" *ngIf="!clientService.readonly"
                                            label="Save" [disabled]="!mainForm.valid" style="width:130px;float: right;"></button>
                                    <!-- &nbsp;&nbsp;&nbsp;
                                    <button pButton pRipple label="Cancel" icon="pi pi-times" class="btn" 
                                            (click)="onHideDialog()" style="width:130px;float: right;margin-right:10px;"></button>      -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-dialog>

    <p-dialog [(visible)]="displayOutsideProviderDialog" id="OutsideProviderModal" header="Outside Provider Information" (onHide)="onHideOutsideProviderDialog()"
              [modal]="true" position="center" [resizable]="false" [draggable]="false" showEffect="explode"> 
        <div formGroupName="referral" class="fluid formgrid grid">
            <div class="card champ-card">
                <div class="card-body champ-card-body">
                    <div class="field col-12"> <!-- class="formgroup-inline" -->
                        <div class="field col-5">
                            <label for="outsideProviderName">Provider Name :</label>
                        </div>
                        <div class="field col-7">
                            <textarea formControlName="outsideProviderName" rows="1" cols="50" pInputTextarea style="height:30px;"></textarea>
                            <div *ngIf="!!mainForm.errors?.invalidOutsideProviderName">
                                <small id="outsideProviderName-help" class="p-invalid">{{mainForm.errors?.invalidOutsideProviderName}}</small>
                            </div>                            
                        </div>
                    </div>
                    <div class="field col-12"> <!-- class="formgroup-inline" -->
                        <div class="field col-5">
                            <label for="outsideProviderPhone">Provider Phone :</label>
                        </div>
                        <div class="field col-7">
                            <p-inputMask formControlName="outsideProviderPhone" mask="(999) 999-9999" placeholder="(999) 999-9999"></p-inputMask>
                        </div>
                    </div>             
                    <div class="field col-12"> <!-- class="formgroup-inline" -->
                        <div class="field col-5">
                            <label for="outsideProviderAddress">Provider Address :</label>
                        </div>
                        <div class="field col-7">
                            <textarea formControlName="outsideProviderAddress" rows="1" cols="50" pInputTextarea style="height:30px;" placeholder="100 MAIN STREET NEWARK NJ 07102"></textarea>
                        </div>
                    </div>

                    <div class="fluid formgrid grid" *ngIf="this.allowEdit">
                        <div class="field col-12"> 
                            <button pButton pRipple class="btn btn-primary" (click)="onOutsideProviderSaveChangesConfirm($event)" icon="pi pi-check"   *ngIf="!clientService.readonly"
                                    label="Save" [disabled]="!!mainForm.errors?.invalidOutsideProviderName" style="width:130px;float: right;"></button>
                            <!-- &nbsp;&nbsp;&nbsp; -->
                            <!-- <button pButton pRipple label="Cancel" icon="pi pi-times" class="btn" 
                                    (click)="onHideOutsideProviderDialog()" style="width:130px;float: right;margin-right:10px;"></button>      -->
                        </div>
                    </div>                    

                </div>
            </div>  
        </div>
    </p-dialog>
</form>
