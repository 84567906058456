import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, first, Observable } from 'rxjs';
//import { filter, first } from 'rxjs/operators';


@Injectable()
export class VersionCheckService {
    private readonly URL = environment.apiURL + '/version?env=' + environment.environmentName;
    private readonly appVersion = environment.appVersion;
    private readonly versionCheckInterval = environment.versionCheckInterval;
    private updateSubject = new BehaviorSubject<boolean>(false);
    public updateReady$: Observable<boolean> = this.updateSubject.asObservable();

    constructor(private http: HttpClient) {}
    /**
     * Checks in every set frequency the version of frontend application
     * @param url
     * @param {number} frequency - in milliseconds, defaults to 30 minutes
     */


    public initVersionCheck(url, frequency = 1000 * 60 * this.versionCheckInterval) { // 5 minutes 
        setInterval(() => {
            this.checkVersion();
        }, frequency);
    }

    private checkVersion() {
       
        //this.http.get(url + '?t=' + new Date().getTime())
        this.http.get(this.URL)
            .pipe(first())
            .subscribe({
               next: (response: any) => {
                   if (response) {
                    const hash = response.hash;
                        const hasVersionChanged = this.hasVersionChanged(this.appVersion, response.version);
                        // If new version, do something
                        if (hasVersionChanged) {
                            this.updateSubject.next(true);
                        }
                        else 
                        {
                            this.updateSubject.next(false);
                        }
                    }
                    else
                    {
                        console.error('Could not get version - response is returned as null - check version table');
                    }
                },
               error: (err) => {
                    console.error(err, 'Could not get version');
                    this.updateSubject.next(false);
                }
            });
    }


    
    private hasVersionChanged(localAppVersion, currentAppVersion) {
        localAppVersion = localAppVersion.replace('-local', '');
        localAppVersion = localAppVersion.replace('-qa', '');
        localAppVersion = localAppVersion.replace('-dev', '');
        localAppVersion = localAppVersion.replace('-prod', '');

        localAppVersion = localAppVersion.trim();

        const localVer = localAppVersion.match(/\d+/g);
        const currentVer = currentAppVersion.match(/\d+/g);

        return ( localVer[0] < currentVer[0] || localVer[1] < currentVer[1] || localVer[2] < currentVer[2] ) ;
     }
}