export enum DirectServiceFilterSelectionChoices
{
    CurrentMonth = 0, 
    LastMonth = 1,
    Last3Months = 3,
    Last6Months = 6,
    Last12Months = 12,
    CurrentFiscalYear = 13,
    MonthAndYear = 100
}
export enum MCMFilterSelectionChoices
{
    CurrentMonth = 0, 
    LastMonth = 1,
    Last3Months = 3,
    Last6Months = 6,
    Last12Months = 12,
    CurrentFiscalYear = 13,
    MonthAndYear = 100
}
export enum ReferralFilterSelectionChoices
{
    CurrentMonth = 0, 
    LastMonth = 1,
    Last3Months = 3,
    Last6Months = 6,
    Last12Months = 12,
    CurrentFiscalYear = 13,
    MonthAndYear = 100
}
export enum ReferralStatus
{
    New = 1,
    Accepted,
    Declined,
    InProgress,
    WithDrawn,
    Completed,
    LostToFollowup
}
export enum AssessmentType
{
    InitialInterview = 1,
    MentalHealth,
    SubstanceAbuse,
    MICA,
    Initial,
    Reassessment,
    Nutritional
}
export enum AssessmentOutcome
{
    ProvidedOnSite = 1,
    Referral,
    PatientDeclined,
    NoReferral,
    InTreatment
}
export enum BulkGroupIndicator { Bulk = 1, Group }
export enum PosNegResults { NoState = 0, Positive, Negative };
export enum YesNo { No = 0, Yes, None }
export enum MedicalYesNo { Yes = 1, No, Unknown }
export enum ClientReferralYesNo { None, Yes, No }
export enum RecertificationErrors { NotSet = -1, None = 0, ZipCode = 1, FPL = 2, Insurance = 4 };
export enum EligibilityAlertErrors { NotSet = -1, None = 0, FPLOver500 = 1, FPLUnder139orNoInsuranceandNoMedicaid = 2, NotCertified = 4 };
export enum RequiredFields
{
    FirstName = 1,
    LastName=2,
    MI = 3,
    Address = 4,
    Zip = 5,
    Ward = 6,
    Phone = 7,
    Email = 8,
    SSN = 9,
    DOB = 10,                            //10
    BirthPlace = 11,
    Gender = 12,
    ClientStatus = 13,
    ReferralSource = 14,
    Race = 15,
    Ethnicity = 16,
    SexualOrientation = 17,
    YearofHIVDiagnosis = 18,
    YearofAIDSDiagnosis = 19,
    HIVStatus = 20,                      //20
    TransmissionModes = 21,
    EmploymentStatus = 22,
    CareGiverSupport = 23,
    PrimaryCareSource = 24,
    LivingIn = 25,
    LiveWith = 26,
    IncomeSource = 27,
    PaymentSource = 28,
    MedicallyUnableToWork = 29,
    IndividualIncome  = 30,               //30
    FamilyIncome  = 31,
    FamilySize  = 32,
    Funder  = 33,
    DirectServiceStaff  = 34,
    ServiceCategory  = 35,
    SubType  = 36,
    Billable  = 37,
    PlannedUnits  = 38,
    DeliveredUnits  = 39,
    DeliveredDate  = 40,                  //40
    DeliveredTime  = 41,
    Barrier  = 42,
    DirectServiceNotes  = 43,
    CD4BaselineAdmissionAvailable  = 44,
    CD4BaselineCount  = 45,
    CD4BaselineDate  = 46,
    CD4Ordered  = 47,
    CD4OrderedDate  = 48,
    CD4LatestResultDate  = 49 ,
    CD4LatestResultCount  = 50,           //50
    ViralLoadAdmissionAvailable  = 51,
    ViralLoadBaselineDate  = 52,
    ViralLoadBaselineCount  = 53,
    ViralLoadBaselineUndetectable  = 54,
    ViralLoadOrdered  = 55,
    ViralLoadOrderedDate  = 56,
    ViralLoadResultDate  = 57,
    ViralLoadResultCount  = 58,
    ViralLoadResultUndetectable  = 59,
    AIDSDefiningCondition  = 60,          //60
    HIVSyndrome  = 61,
    TuberculosisAdmissionAvailable  = 62,
    TuberculosisAdmissionResultDate  = 63,
    TuberculosisAdmissionResults  = 64,
    TuberculosisOrdered  = 65,
    TuberculosisOrderedDate  = 66,
    TuberculosisLastestResultDate  = 67,
    TuberculosisLastestResult  = 68,
    HepatitisCAdmissionAvailable  = 69,
    HepatitisCAdmissionTestDate  = 70,    //70
    HepatitisCAdmissionResult  = 71,
    HepatitisCOrdered  = 72,
    HepatitisCOrderedDate  = 73,
    HepatitisCLastResultDate  = 74,
    HepatitisCLastResult  = 75,
    SyphillisAdmissionAvailable  = 76,
    SyphillisAdmissionResultDate  = 77,
    SyphillisAdmissionResult  = 78,
    SyphillisOrdered  = 79,
    SyphillisOrderedDate  = 80,           //80
    SyphillisLastResultDate  = 81,
    SyphillisLastResult  = 82,
    STIOtherAdmissionAvailable  = 83,
    STIOtherAdmissionDate  = 84,
    STIOtherAdmissionResult  = 85,
    STIOtherAdmissionDescription  = 86,
    STIOtherOrdered  = 87,
    STIOtherOrderedDate  = 88,
    STIOtherOrderedDescription  = 89,
    STIOtherLastResultDate  = 90,         //90
    STIOtherLastResult  = 91,
    STIOtherLastResultDescription  = 92,
    AntiRetroViralTherapies  = 93,
    FemalePapSmearResult  = 94,
    FemalePapSmearDate  = 95,
    FemalePelvicExamResult  = 96,
    FemalePelvicExamDate  = 97,
    FemalePregnantResult  = 98,
    FemalePregnantDate  = 99,
    FemalePregnantTrimester  = 100,        //100
    FemaleAntiRetroPreventionTherapyResult  = 101,
    FemaleAntiRetroPreventionTherapyDate  = 102,
    ReferralServiceCategory  = 103,
    ReferralSubType  = 104,
    ReferralProvider  = 105,
    ReferralConfirmationStatus  = 106,
    ReferralPeriod  = 107,
    ReferralExpiring  = 108,
    ReferralConfirmationLocation  = 109,
    ReferralNotes  = 110,                  //110
    HispanicRegionOrigin  = 111,
    GafScale  = 112,
    CD4LatestResultUnavailable  = 113,
    ViralLoadLatestResultUnavailable  = 114,
    TuberculosisLatestResultUnavailable  = 115,
    HepatitisCLastestResultUnavailable  = 116,
    SyphillisLastestResultUnavailable  = 117,
    STIOtherLastResultUnavailable  = 118,
    HopwaWorksheetDate  = 119,
    PCPProphylaxis = 120,                 //120
    MACProphylaxis  = 121,
    HepatitisBAdmissionAvailable  = 122,
    HepatitisBAdmissionTestDate  = 123,
    HepatitisBAdmissionResult  = 124,
    HepatitisBOrdered  = 125,
    HepatitisBOrderedDate  = 126,
    HepatitisBLastResultDate  = 127,
    HepatitisBLastResult  = 128,
    HepatitisBLastestResultUnavailable  = 129,
    AntiRetroViralTherapyReason  = 130,    //130
    LastMCMPhysicianVisitDate  = 131,
    LastMCMPhysicianLocation  = 132,
    LastMCMPrimaryPhysicianUnavilable  = 133,
    PrimaryCarePhysician  = 134,
    InfluenzaVaccination  = 135,
    PneumoccalVaccination  = 136,
    HepatitisBVaccinations  = 137,
    LipidPanel  = 138,
    ToxoplasmaScreening  = 139,
    LipidPanelResultDate  = 140,           //140
    ToxoplasmaResultDate  = 141,
    ToxoplasmaResult  = 142,
    InfluenzaVaccinationDate  = 143,
    PneumoccocalVaccinationDate  = 144,
    HepatitisBVaccinationDate  = 145,
    AIDSPharmaAssistance  = 146,
    MCMMedicalVisits  = 147,
    MCMServiceCategory  = 148,
    MCMSubType  = 149,
    MCMMemo  = 150,                        //150
    HepatitisBScreened  = 151,
    HepatitisBScreenedNoReason  = 152,
    HepatitisCScreened  = 153,
    HepatitisCScreenednoReason  = 154,
    SyphilisScreened  = 155,
    SyphilisScreenedNoReason  = 156,
    TuberculosisScreened  = 157,
    TuberculosisScreenedNoReason  = 158,
    ToxoplasmaScreenedNoReason  = 159,
    HIVAIDSNewlyDiagnosed  = 160,          //160
    DateOfHIVDiagnosis  = 161,
    DateOfAIDSDiagnosis  = 162,
    //Omer 9/23/2011 - Case 984:Added combobox to display Reason for not getting antiretroviral therapy for women()
    FemaleAntiRetroPreventionTherapyNoReason  = 163,
    //Omer 9/28/2011 - Case 981:Added Chlamydia & Gonorrhea as seperate sections
    ChlamydiaScreened  = 164,
    ChlamydiaScreenedNoReason  = 165,
    ChlamydiaLastResultDate  = 166,
    ChlamydiaLastResult  = 167,
    GonorrheaScreened  = 168,
    GonorrheaScreenedNoReason  = 169,
    GonorrheaLastResultDate  = 170,        //170
    GonorrheaLastResult  = 171,
    InfluenzaVaccinationNoReason  = 172,
    PneumoccocalVaccinationNoReason  = 173,
    HepBVaccinationNoReason = 174,
    ChlamydiaLastResultUnavailable = 175,
    GonorrheaLastResultUnavailable = 176,
    MedicalCaseManagementCarePlanLastUpdate = 177,
    MedicalCaseManagemnetCarePlanUnavaialble = 178,
    MedicalCaseManagementAddNonRWMedicalVisit = 179,
    HIVDrugResistanceReceived = 180,
    MHScreeningResultDate  = 181,
    MHScreeningResult = 182,
    MHScreeningFollowup = 183,
    SAScreeningResultDate = 184,
    SAScreeningResult = 185, 
    SAScreeningFollowup = 186,
    MHScreeningDiagnosis = 187,
    SAScreeningDiagnosis = 188,
    CovidResultDate = 189,
    CovidLatestResult = 190,
    CovidLatestResultUnavailable = 191,
    CovidScreened = 192,
    CovidScreenedNoReason = 193,
    CovidScreenedNo = 194,
    CovidTestType = 195

};
export enum RequiredFieldResult { RequiredFail = 0, CustomFail, FeedsCustomFail, Pass, PassBillable, PassWithError, Invalid, OtherError, UserCancel };
export enum RequiredFieldGroup { All = 0, Identification, DemoHiv, DirectService, Medical, SocioEconomic, Referral, Provider, GAF, Hopwa, ExcludeDirectService = 50, ExcludeReferral, ExcludeDirectServiceAndReferral, ExcludeHopwa, ExcludeDirectServiceAndReferralAndHopwa, MedicalCase };
export enum DiagnosedYesNo { NoState = 0, Yes, No, Affected };
export enum UnbillableReason 
{ 
    Billable = 0, 
    MissingRequiredField, 
    DeadlineMissed, 
    HIVAffectedIndeterminateClient, 
    UserMarkUnbillable, 
    PaymentSourceNotBillable, 
    MissingMCMData, 
    _2XSameEncounter, 
    _2XSameMonthFunder, 
    MoreThan4UnitsIn12Months, 
    UserEnteredNotBillable, 
    BulkGroupInvalidHIVStatus, 
    RequiresApproval, 
    NoContractItemMatch, 
    IncompleteOrInvalidAssessment, 
    NursePCPSameDay,
    EISNotAllowed,
    HopwaWorksheetNotValid,
    HopwaWorksheetNotQualified,
    COHORTInterventionQuestionRequired,
    DeliveredUnitsCostExceedsMaximum,
    SubtypeNotBillable,
    PastAllowableBillableTimePeriod
};

export const UnbillableReasonPriority = [
    {
        unbillableReason: "MoreThan4UnitsIn12Months",
        enumIndex: 9,
        priority: 1
    },
    {
        unbillableReason: "MissingRequiredField",
        enumIndex: 1,
        priority: 2
    },
    {
        unbillableReason: "PastAllowableBillableTimePeriod",
        enumIndex: 22,
        priority: 3
    },    
    {
        unbillableReason: "IncompleteOrInvalidAssessment",
        enumIndex: 14,
        priority: 4
    },
    {
        unbillableReason: "HIVAffectedIndeterminateClient",
        enumIndex: 3,
        priority: 5
    },
    {
        unbillableReason: "RequiresApproval",
        enumIndex: 12,
        priority: 6
    },
    {
        unbillableReason: "_2XSameEncounter",
        enumIndex: 7,
        priority: 7
    },
    {
        unbillableReason: "_2XSameMonthFunder",
        enumIndex: 8,
        priority: 8
    },    
    {
        unbillableReason: "MissingMCMData",
        enumIndex: 6,
        priority: 9
    },
    {
        unbillableReason: "HopwaWorksheetNotQualified",
        enumIndex: 18,
        priority: 10
    },
    {
        unbillableReason: "HopwaWorksheetNotValid",
        enumIndex: 17,
        priority: 11
    },
    {
        unbillableReason: "DeadlineMissed",
        enumIndex: 2,
        priority: 12
    },
    {
        unbillableReason: "NursePCPSameDay",
        enumIndex: 15,
        priority: 13
    },
    {
        unbillableReason: "EISNotAllowed",
        enumIndex: 16,
        priority: 14
    },
    {
        unbillableReason: "PaymentSourceNotBillable",
        enumIndex: 5,
        priority: 15
    },
    {
        unbillableReason: "COHORTInterventionQuestionRequired",
        enumIndex: 19,
        priority: 16
    },
    {
        unbillableReason: "DeliveredUnitsCostExceedsMaximum",
        enumIndex: 20,
        priority: 17
    },
    {
        unbillableReason: "SubtypeNotBillable",
        enumIndex: 21,
        priority: 18
    },
    {
        unbillableReason: "NoContractItemMatch",
        enumIndex: 13,
        priority: 19
    },
    {
        unbillableReason: "BulkGroupInvalidHIVStatus",
        enumIndex: 11,
        priority: 20
    },
    {
        // every other reason is a higher priority
        unbillableReason: "UserMarkUnbillable",
        enumIndex: 10,
        priority: 999        
    }
];

export enum DataRecordSource { FrontEnd = 0, FeedProcess, Administrative, Champ4 };
export enum SecurityPrivileges 
{
    Administrator = 1,
    DHHS = 2,
    ProviderSupervisor = 4,
    DirectServicesEdit = 8,
    ContractsEdit = 16,
    GroupServicesEdit = 32,
    BulkServicesEdit = 64,
    CaseManagementEdit = 128,
    MedicalEdit = 256,
    SupportServicesEdit = 512,
    ReportsAggragate = 1024,
    ReportsFinancial = 2048,
    CaseManagementReadOnly = 4096,
    MedicalReadOnly = 8192,
    DirectServicesLimitedContracted = 16384,
    DirectServicesReadOnly = 32768,
    DirectServicesDelete = 65536,
    ReferralsReadOnly = 131072,
    ReferralsEdit = 262144,
    ReferralsDelete = 524288,
    GroupServicesReadOnly = 1048576,
    GroupServicesDelete = 2097152,
    GroupServicesLimitedContracted = 4194304,
    BulkServicesReadOnly = 8388608,
    BulkServicesDelete = 16777216,
    BulkServicesLimitedContracted = 33554432,
    QualityControlManagement = 67108864,
    HopwaWorksheetEdit = 134217728,
    FeedsExecution = 268435456,
    SharePointProvider = 536870912,
    SharePointGrantee = 1073741824,
    SharePointVisitors = 2147483648,
    RSRExecutivePrivilege = 4294967296
};
export enum MasterFields
{
    FirstName = 1,
    LastName = 2,
    MiddleInitial = 3,
    Address = 4,
    ZIPCode = 5,
    Ward = 6,
    PhoneNumber = 7,
    EmailAddress = 8,
    SocialSecurityNumber = 9,
    BirthDate = 10,
    Birthplace = 11,
    Gender = 12,
    ClientStatus = 13,
    ReferralSource = 14,
    Race = 15,
    Ethnicity = 16,
    SexualOrientation = 17,
    YearofHIVDiagnosis = 18,
    YearofAIDSDiagnosis = 19,
    HIVStatus = 20,
    TransmissionModes = 21,
    EmploymentStatus = 22,
    CareGiverSupport = 23,
    PrimaryCareSource = 24,
    LivingIn = 25,
    LiveWith = 26,
    IncomeSource = 27,
    PaymentSource = 28,
    MedicallyUnableToWork = 29,
    IndividualIncome = 30,
    FamilyIncome = 31,
    FamilySize = 32,
    Funder = 33,
    DirectServiceStaff = 34,
    ServiceCategory = 35,
    SubType = 36,
    Billable = 37,
    PlannedUnits = 38,
    DeliveredUnits = 39,
    DeliveredDate = 40,
    DeliveredTime = 41,
    Barrier = 42,
    DirectServiceNotes = 43,
    CD4BaselineAdmissionAvailable = 44,
    CD4BaselineCount = 45,
    CD4BaselineDate = 46,
    CD4Ordered = 47,
    CD4DateOrdered = 48,
    CD4LatestResultDate = 49,
    CD4LatestResult = 50,
    ViralLoadAdmissionAvailable = 51,
    ViralLoadBaselineDate = 52,
    ViralLoadBaselineCount = 53,
    ViralLoadBaselineUndetectable = 54,
    ViralLoadOrdered = 55,
    ViralLoadOrderedDate = 56,
    ViralLoadResultDate = 57,
    ViralLoadResultCount = 58,
    ViralLoadResultUndetectable = 59,
    AIDSDefiningCondition = 60,
    HIVSyndrome = 61,
    TuberculosisAdmissionAvailable = 62,
    TuberculosisAdmissionResultDate = 63,
    TuberculosisAdmissionResults = 64,
    TuberculosisOrdered = 65,
    TuberculosisOrderedDate = 66,
    TuberculosisResultDate = 67,
    TuberculosisLastResults = 68,
    HepatitisCAdmissionAvailable = 69,
    HepatitisCAdmissionTestDate = 70,
    HepatitisCAdmissionResult = 71,
    HepatitisCOrdered = 72,
    HepatitisCOrderedDate = 73,
    HepatitisCResultDate = 74,
    HepatitisCLastResult = 75,
    SyphillisAdmissionAvailable = 76,
    SyphillisAdmissionResultDate = 77,
    SyphillisAdmissionResult = 78,
    SyphillisOrdered = 79,
    SyphillisOrderedDate = 80,
    SyphillisResultDate = 81,
    SyphillisLastResult = 82,
    STIOtherAdmissionAvailable = 83,
    STIOtherAdmissionDate = 84,
    STIOtherAdmissionResult = 85,
    STIOtherAdmissionDescription = 86,
    STIOtherOrdered = 87,
    STIOtherOrderedDate = 88,
    STIOtherOrderedDescription = 89,
    STIOtherResultDate = 90,
    STIOtherLastResult = 91,
    STIOtherResultDescription = 92,
    AntiRetroViralTherapyResult = 93,
    FemalePapSmearResult = 94,
    FemalePapSmearDate = 95,
    FemalePelvicExamResult = 96,
    FemalePelvicExamDate = 97,
    FemalePregnantResult = 98,
    FemalePregnantDate = 99,
    FemaleTrimester = 100,
    FemaleAntiRetroPreventionTherapyResult = 101,
    FemaleAntiRetroPreventionTherapyDate = 102,
    ReferralServiceCategory = 103,
    ReferralSubType = 104,
    ReferralProvider = 105,
    ReferralConfirmationStatus = 106,
    ReferralPeriod = 107,
    ReferralExpiring = 108,
    ReferralConfirmationLocation = 109,
    ReferralMemo = 110,
    HispanicRegionofOrigin = 111,
    GafScale = 112,
    CD4LatestResultUnavailable = 113,
    ViralLoadLatestResultUnavailable = 114,
    TuberculosisLatestResultUnavailable = 115,
    HepatitisCLastestResultUnavailable = 116,
    SyphillisLastestResultUnavailable = 117,
    STIOtherLastResultUnavailable = 118,
    HopwaWorksheetDate = 119,
    PCPProphylaxis = 120,
    MACProphylaxis = 121,
    HepatitisBAdmissionAvailable = 122,
    HepatitisBAdmissionTestDate = 123,
    HepatitisBAdmissionResult = 124,
    HepatitisBOrdered = 125,
    HepatitisBOrderedDate = 126,
    HepatitisBResultDate = 127,
    HepatitisBLastResult = 128,
    HepatitisBLastestResultUnavailable = 129,
    AntiRetroViralTherapyReason = 130,
    MedicalCaseManagementVisitDate = 131,
    MedicalCaseManagementLocation = 132,
    MedicalCaseManagementPhysicianVisitUnavailable = 133,
    PrimaryCarePhysician = 134,
    InfluenzaVaccination = 135,
    PneumoccalVaccination = 136,
    HepatitisBVaccinations = 137,
    LipidPanel = 138,
    ToxoplasmaScreening = 139,
    LipidPanelResultDate = 140,
    ToxoplasmaResultDate = 141,
    ToxoplasmaResult = 142,
    InfluenzaVaccinationDate = 143,
    PneumoccocalVaccinationDate = 144,
    HepatitisBVaccinationDate = 145,
    AIDSPharmaceuticalAssistance = 146,
    MedicalCaseManagementMedicalVisits = 147,
    MedicalCaseManagementServiceCategory = 148,
    MedicalCaseManagementSubType = 149,
    MedicalCaseManagementMemo = 150,
    HepatitisBScreened = 151,
    HepatitisBScreenedNoReason = 152,
    HepatitisCScreened = 153,
    HepatitisCScreenedNoReason = 154,
    SyphilisScreened = 155,
    SyphilisScreenedNoReason = 156,
    TuberculosisScreened = 157,
    TuberculosisScreenedNoReason = 158,
    ToxoplasmaScreenedNoReason = 159,
    HIVAIDSDiagnosis = 160,
    HIVDiagnosisDate = 161,
    AIDSDiagnosisDate = 162,
    FemaleAntiretroviralTherapyNoReason = 163,
    ChlamydiaScreened = 164,
    ChlamydiaScreenedNoReason = 165,
    ChlamydiaResultDate = 166,
    ChlamydiaLastResult = 167,
    GonorrheaScreened = 168,
    GonorrheaScreenedNoReason = 169,
    GonorrheaResultDate = 170,
    GonorrheaLastResult = 171,
    InfluenzaVaccinationNoReason = 172,
    PneumoccocalVaccinationNoReason = 173,
    HepatitisBVaccinationNoReason = 174,
    ChlamydiaLatestResultUnavailable = 175,
    GonorrheaLatestResultUnavailable = 176,
    MedicalCaseManagementCarePlan = 177,
    MedicalCaseManagemnetCarePlanUnavaialble = 178,
    MedicalCaseManagementAddNonRWMedicalVisit = 179,
    AntiretroviralTherapyHIVDrugResistanceReceived = 180,
    MentalHealthScreeningResultDate = 181,
    MentalHealthScreeningResult = 182,
    MentalHealthScreeningFollowup = 183,
    SubstanceAbuseScreeningResultDate = 184,
    SubstanceAbuseScreeningResult = 185,
    SubstanceAbuseScreeningFollowup = 186,
    MentalHealthScreeningDiagnosis = 187,
    SubstanceAbuseScreeningDiagnosis = 188,
    CovidResultDate = 189,
    CovidLastestResults = 190,
    CovidLatestResultUnavailable = 191,
    CovidScreened = 192,
    CovidScreenedNoReason = 193,
    CovidScreenedNo = 194,
    CovidTestType = 195,
    AntiRetroViralTherapyPrescribedDate = 196
};
export enum MasterPrivileges
{
    Administrator = 1,
    DHHS = 2,
    ProviderSupervisor = 3,
    DirectServicesEdit = 4,
    ContractsEdit = 5,
    GroupServicesEdit = 6,
    BulkServicesEdit = 7,
    CaseManagementEdit = 8,
    MedicalEdit = 9,
    SupportServicesEdit = 10,
    ReportsAggragate = 11,
    ReportsFinancial = 12,
    CaseManagementReadOnly = 13,
    MedicalReadOnly = 14,
    DirectServicesLimitedContracted = 15,
    DirectServicesReadOnly = 16,
    DirectServicesDelete = 17,
    ReferralsReadOnly = 18,
    ReferralsEdit = 19,
    ReferralsDelete = 20,
    GroupServicesReadOnly = 21,
    GroupServicesDelete = 22,
    GroupServicesLimitedContracted = 23,
    BulkServicesReadOnly = 24,
    BulkServicesDelete = 25,
    BulkServicesLimitedContracted = 26,
    QualityControlManagement = 27,
    HopwaWorksheetEdit = 28,
    Feeds = 29,
    SharePointProvider = 30,
    SharePointGrantee = 31,
    SharePointVisitors = 32,
    RSRExecutive = 33
};
export enum ClientStatus {
    Active = 1,
    Discharged_Died,
    Discharged_Moved,
    Discharged_Withdrew,
    Discharged_TransferredOut,
    Suspended_NoContact,
    Suspended_Stable,
    Discharged_DiedFamilyMem,
    Active_TransInsideAgency,
    Active_SharedOtherAgency,
    Discharged_CaseResolved,
    Discharged_Incarcerated,
    Discharged_RemovedFromTreatmentDueToViolationOfRules,
    Discharged_OtherSourceOfCoverage
}
export enum PaySource {
    MCMedicare = 1,
    MANonHMOMedicaid,
    HMONonMANotMedicaid,
    BCBSBlueCrossBlueShield,
    OtherThirdParty,
    PrivatePay,
    Other,
    None,
    Unknown,
    HMOMAUnderMedicaid,
    GAPFundingMunicipalWelfare,
    MedicaidXXX, //deleted in DB
    Medicare,
    PrivateInsuranceEmployer,
    PrivatePayXXX, //deleted in DB
    NoInsurance,
    Charity,
    MedicaidManagedCare,
    MedicaidPlanGXXX, //deleted in DB
    VAHealthCare,
    MedicaidFeeForService,
    MedicaidExpansionNJFC,
    PrivateInsuranceExchange    
}
export enum LiveIn {
    HouseOrApartmentRentOrOwnUnsubsidized = 1,
    SroOrGroupHousing,
    NursingHomeHospice,
    Homeless,
    InstitutionHospitalPsych,
    JailOrPrison,
    ResidentialTreatmentProgram,
    Other,
    UnknownOrNotReported,
    TransitionalHousingRyanWhite,
    EmergencyShelter,
    RefusedHousing,
    HouseOrApartmentDoublingUpOrStayingWithFamilyFriends,
    HOPWALongTerm,
    HouseOrApartmentSubsidizedNonHOPWA,
    RyanWhiteHousing,
    HotelOrMotelNoSubsidyVoucher,
    HotelOrMotelWithSubsidyVoucher,
    TransitionalHousingNotRyanWhite
}
export enum HIVStatus {
    SerostatusUnknown = 1,
    Negative,
    Asymptomatic,
    SymtomaticHIVSyndrome,
    SymptomaticOpportunisticInfection,
    NotApplicable,
    HIVPositiveNoAIDS,
    AIDS,
    HIVPositiveAIDSStatusUnknown,
    HIVAffected, //deleted in DB
    HIVStatusUnknown,
    HIVIndeterminate
}
export enum EISSubTypes
{
    EISReferral = 1000,
    EISLinkage = 1002,
    // the next 2 entries are for testing
    // EIS305 = 305,
    // EIS366 = 366
};
export enum PayRollPeriod {
    Weekly = 1,
    BiWeekly,
    Monthly
}
export enum HIVIndeterminateStatusResult
{
    NotIndeterminateStatus = 0,
    IndeterminateStatusInvalidAge,
    IndeterminateStatusValidAge
}
export enum HIVIndeterminateStatus
{
    HIVIndeterminateStatus = 12 //12 = HIV-Indeterminate
}

export enum VaccinationType
{
    Influenza,
    Pneumoccocal,
    HepatitisB
}

export enum MedicalComponents
{
    CD4,
    ViralLoad,
    HepatitisB,
    HepatitisC,
    AidsDefiningCondition,
    Tuberculosis,
    Syphilis,
    Chlamydia,
    Gonorrhea,
    Toxoplasma,
    LipidPanel,
    Covid,
    PharmaAssistance,
    AntiretroviralTherapy,
    InfluenzaVaccination,
    PneumoccocalVaccination,
    HepatitisBVaccination,
    FemalePapSmear,
    FemalePelvicExam,
    FemalePregnancy,
    AntiretroviralPrevention,
    FemaleBirthResults,
    OtherSTIResults
}

export enum BehavioralHealthComponents
{
    MentalHeath,
    SubstanceAbuse
}

export enum LogType {
    ChampLog = 1,
    SeriLog
}

export enum Gender {
    Male = 1,
    Female,
    Unknown,
    TransgenderMaletoFemale,
    TransgenderFemaletoMale    
}