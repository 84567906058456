<div>
    <!-- <p-toast></p-toast> -->
    <p-confirmPopup></p-confirmPopup>   
</div>
<form [formGroup]='fg' autocomplete="off">
    <div class="p-container-fluid champ-card-body-sm"> 
        <div class="fluid formgrid grid " style="margin-bottom: 1rem;">
            <div class="col-2 ">
                <h3><b>Unit Maintenance</b></h3>
            </div>
       </div>

        <div class="grid flex"> 
            <div class="flex col-3" >
                <div class="p-fluid p-formgrid grid  ml-4"> 
                    <div class="field col-12 m-1 ml-2" >
                        <label class="control-label m-0  mr-2" for="providers" >Provider </label>
                        <p-dropdown formControlName="providerId" [options]="providers" optionLabel="providerName" optionValue="id" (onClick)="onProvidersClick($event)"></p-dropdown>
                    </div>

                    <div class="field col-12 m-1 ml-2" >
                        <div class="flex align-content-start align-items-end " >
                            <label class="flex  justify-content-start control-label mr-2"  style="width:25%" for="providerContract">Provider Contract </label>
                            <span class="flex justify-content-end" style="width: 75%;"> 
                                <button pButton pRipple type="button" label="Recalc Units" class="p-button-text p-button-sm" style="width: 10rem;" (click)="onRecalcUnits($event)" ></button>  
                            </span>
                        </div>


                        <!-- <label class="control-label m-0 mr-2" for="providerContract" >Provider Contract</label> -->
                        <p-dropdown formControlName="providerContract" [options]="providerContracts" optionLabel="programName" optionValue="guid"></p-dropdown>
                    </div>

                    <div class="field col-12 m-1 ml-2" >
                        <label class="control-label m-0  mr-2" for="funder" >Funder</label>
                        <p-dropdown  id="funder"  formControlName="funder" [options]="this.funders()" optionLabel="description" optionValue="id"></p-dropdown>
                    </div>

                    <div class="field col-5 m-1 ml-2" >
                        <label class="control-label m-0  mr-2" for="fundingYear" >Fund Year</label>
                        <p-dropdown  id="fundingYear"  formControlName="fundingYear" [options]="this.fundingYears"></p-dropdown>
                    </div>

                    <div class=" col-1 m-0" ></div>

                    <div class="field col-5 m-1 ml-2" >
                        <label class="control-label m-0 mr-2" for="month" >Month</label>
                        <p-dropdown  id="month" formControlName="month" [options]="this.months"  optionLabel="monthName" optionValue="id"></p-dropdown>
                    </div>

                    <div class=" col-1 m-0" ></div>

                </div>

            </div>

            <div class="flex col-3" >
                <div class="p-fluid p-formgrid grid  ml-4"> 

                    <div class="field col-5 m-1 ml-2 mb-0" >
                        <label class="control-label m-0 mr-2" for="clientID">Client ID</label>
                        <input type="text" class="form-control" id="clientID"  formControlName="clientId"  style="height:28px" >  <!-- (change)="onChangeClientID($event)" -->
                    </div>    
                    
                    <div class="field col-12 m-1 ml-2" >
                        <label class="control-label m-0 mr-2" for="serviceId" >Services</label>
                        <p-dropdown id="serviceId" formControlName="serviceId" [options]="this.serviceCategories" optionLabel="description" optionValue="id" (onChange)="onChangeServiceType($event.value)"></p-dropdown>
                    </div>

                    <div class="field col-12 m-1 ml-2" >
                        <label class="control-label m-0 mr-2" for="subTypes" >SubTypes</label>
                        <p-dropdown id="subTypes" formControlName="subType" [options]="this.subTypes" optionLabel="description" optionValue="id" ></p-dropdown>
                    </div>
                </div>
            </div>

            <div class="flex col-3" >
                <div class="p-fluid p-formgrid grid  ml-4"> 
                    <div class="field col-12 m-1 ml-2" >
                        <label class="control-label m-0 mr-2" for="race" >Staff</label>
                        <p-dropdown id="race" formControlName="staff" [options]="this.employees " optionLabel="FullName" optionValue="id" ></p-dropdown>
                    </div>

                    <div class="field col-12 m-1 ml-2" >
                        <label class="control-label m-0 mr-2" for="ethnicity" >Client Ethnicity</label>
                        <p-dropdown d="ethnicity" formControlName="ethnicity" [options]="this.ethnicities()" optionLabel="description" optionValue="id" ></p-dropdown>
                    </div>

                    <div class="field col-12 m-1 ml-2" >
                        <label class="control-label m-0 mr-2" for="race" >Client Race</label>
                        <p-dropdown id="race" formControlName="race" [options]="this.race()" optionLabel="description" optionValue="id" ></p-dropdown>
                    </div>
                </div>
            </div>

            <div class="flex col-3" >
                <div class="p-fluid p-formgrid grid  ml-4"> 
                    <div class="field col-12 m-1 ml-2 pr-6" >
                        <label class="control-label m-0 mr-2 " for="reasonCode" >Reason Codes</label>
                        <p-dropdown id="reasonCode" formControlName="reasonCode" [options]="this.reasons()" optionLabel="description" optionValue="id"  [ngStyle]="{'width':'20rem'}"></p-dropdown>
                    </div>
                
                    <div class="field col-12 m-1 ml-2 formgroup-inline champ-formgroup-inline flex " >
                        <div class="field col-3"><label  style="font-weight:normal; margin-right: 10px;">Billable</label></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="billable" value="0" label="No"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="billable" value="1" label="Yes"></p-radioButton></div>
                    </div>
                                                
                    <div class="field col-12 m-1 ml-2 formgroup-inline champ-formgroup-inline" >
                        <div class="field col-3"><label  style="font-weight:normal; margin-right: 10px;">Non-Billable</label></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="nonBillable" value=0 label="No"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="nonBillable" value=1 label="Yes"></p-radioButton></div>
                    </div>

                    <div class="field col-12 m-1 ml-2 formgroup-inline champ-formgroup-inline" >
                        <div class="field col-3"><label  style="font-weight:normal; margin-right: 10px;">Deleted</label></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="deleted" value=0 label="No"></p-radioButton></div>
                        <div class="field col-4" [style]="{'width':'55px'}"><p-radioButton formControlName="deleted" value=1 label="Yes"></p-radioButton></div>
                    </div>
            </div>

            </div>

            <div class="flex col-12" >
                
                <div class="flex align-items-center justify-content-center" style="width:12rem"  > 
                    <p-button  pButton pRipple type="button"  [ngStyle]="{'width':'12rem'}" styleClass="p-button-text note-button" 
                                    class="p-button-help p-button-text p-button mr-4 ml-0 mt-1 "  (click)="refreshUnits($event)" >
                                <i class="fa-sharp fa-refresh" style="color:blue" pTooltip="Refresh data. Any unsaved changes will be lost."  ></i>
                                <span class="ml-2 font-bold">Refresh</span>
                    </p-button>
                </div>

                <div   class="flex align-items-center justify-content-center" style="width:60rem" *ngIf="this.unitData?.length > 0 "> 
                    Total Service Deliveries  <span class="m-2" style="font-weight: 600;">{{this.getTotalServicesCount()}} </span> /
                    Total Units <span class="m-2" style="font-weight: 600;"> {{this.getSumOfUnits()}} </span> /
                    Total Selected Units<span class="m-2" style="font-weight: 600;"> {{this.selectedUnitData?.length  ?? 0  }} </span>

                </div>


                <div class="flex align-items-center justify-content-center" style="width:15rem" *ngIf="this.selectedUnitData?.length > 0 " > 
                    <p-button  pButton pRipple type="button"  [ngStyle]="{'width':'15rem'}" styleClass="p-button-text note-button" 
                                    class="p-button-help p-button-text p-button mr-4 ml-0 mt-1 "  (click)="showUpdateDialog($event)" >
                                <i class="fa-sharp fa-regular fa-cloud-arrow-up" style="color:green" pTooltip="Configure update options"  ></i>
                                <span class="ml-2 font-bold">Update Units</span>
                    </p-button>
                </div>

            </div>
        </div>

         <div class="p-container-fluid" id="grid" > 
            <p-table #dtEvents [value]="this.unitData"    [scrollable]="true"  scrollDirection="both"  scrollHeight="40vh"  styleClass="p-datatable-sm p-datatable-striped" 
                        sortField="lastLoginDate" sortOrder="-1" [(selection)]="selectedUnitData"> 
                <ng-template pTemplate="header">
                    <tr>
                         <th pFrozenColumn style="width: 4rem"  > 
                            <p-tableHeaderCheckbox class="ml-1"></p-tableHeaderCheckbox>
                        </th>
                        
                        <th pSortableColumn="directServiceGuid" style="width:10rem; " [hidden]="true">DS Guid<p-sortIcon field="directServiceGuid"></p-sortIcon></th>
                        <th pSortableColumn="clientId" style="width:10rem; ">Client ID<p-sortIcon field="clientId"></p-sortIcon></th>
                        <th pSortableColumn="ethnicity" style="width:15rem;">Ethinicty<p-sortIcon field="ethnicity" ></p-sortIcon></th>
                        <th pSortableColumn="race" style="width:15rem;">Race<p-sortIcon field="race" ></p-sortIcon></th>                                
                        <th pSortableColumn="deliveredDate" style="width:15rem; ">Delivered Date<p-sortIcon field="deliveredDate" ></p-sortIcon></th>
                        <th pSortableColumn="serviceCategory" style="width:20rem; ">Service Category<p-sortIcon field="serviceCategory" ></p-sortIcon></th>
                        <th pSortableColumn="subType" style="width:15rem; ">Sub Type<p-sortIcon field="subType" ></p-sortIcon></th>
                        <th pSortableColumn="unit" style="width:18rem; ">Units<p-sortIcon field="unit"></p-sortIcon></th>
                        <th pSortableColumn="billable" style="width:18rem; ">Billable<p-sortIcon field="billable"></p-sortIcon></th>
                        <th pSortableColumn="nonBillable" style="width:18rem; ">Non Billable<p-sortIcon field="nonBillable"></p-sortIcon></th>
                        <th pSortableColumn="staff" style="width:18rem; ">Staff<p-sortIcon field="staff"></p-sortIcon></th>
                        <th pSortableColumn="contractName" style="width:18rem; ">Contract Name<p-sortIcon field="contractName"></p-sortIcon></th>
                        <th pSortableColumn="lastUpdate" style="width:18rem; ">Last Update<p-sortIcon field="lastUpdate"></p-sortIcon></th>
                        <th pSortableColumn="deleted" style="width:18rem; ">Deleted<p-sortIcon field="deleted"></p-sortIcon></th>
                        <th pSortableColumn="funder" style="width:18rem; ">Funder<p-sortIcon field="funder"></p-sortIcon></th>
                        <th pSortableColumn="memo" style="width:18rem; ">Memo<p-sortIcon field="memo"></p-sortIcon></th>
                        <th pSortableColumn="reasonCode" style="width:18rem; ">Reason Code<p-sortIcon field="reasonCode"></p-sortIcon></th>
                    </tr>

                </ng-template>
                <ng-template pTemplate="body" let-service let-editing="editing" let-rowIndex="rowIndex">
                    <tr >  
                        <td pFrozenColumn style="max-width: 4rem;  max-width: 15rem;" > 
                            <p-tableCheckbox [value]="service"></p-tableCheckbox>
                        </td>
                       
                        <td style="width:rem; " [hidden]="true"> {{ service?.directServiceGuid }}   </td>
                        <td style="width:15rem; "> {{ service?.clientId }} </td>
                        <td style="width:15rem; "> {{ service?.ethnicity }} </td>
                        <td style="width:15rem; "> {{ service?.race }} </td>
                        <td style="width:20rem; "> {{ service?.deliveredDate | date:'MM/dd/yyyy hh:mm:ss aa'   }} </td>
                        <td style="width:20rem; "> {{ service?.serviceCategory  }} </td>
                        <td style="width:20rem; "> {{ service?.subType  }} </td>
                        <td style="width:20rem; "> {{ service?.unit  }} </td>
                        <td style="width:15rem; "> <i class="pi" [ngClass]="{'true-icon pi-check-circle': service?.billable, 'false-icon pi-times-circle ': !service?.billable}" ></i></td>
                        <td style="width:15rem; "> <i class="pi" [ngClass]="{'true-icon pi-check-circle': service?.nonBillable, 'false-icon pi-times-circle ': !service?.nonBillable}" ></i></td>
                        <td style="width:20rem; "> {{ service?.staff  }} </td>
                        <td style="width:20rem; "> {{ service?.contractName  }} </td>
                        <td style="width:20rem; "> {{ service?.lastUpdate  | date:'MM/dd/yyyy hh:mm:ss aa'  }} </td>
                        <td style="width:15rem; "> <i class="pi" [ngClass]="{'true-icon pi-check-circle': service?.deleted, 'false-icon pi-times-circle ': !service?.deleted}" ></i></td>
                        <!-- <td style="width:18rem; "> {{ service?.lastLoginDate | date:'MM/dd/yyyy  hh:mm:ss aa' }}  </td> -->
                        <td style="width:20rem; "> {{ service?.funder  }} </td>
                        <td style="width:20rem; overflow: hidden; text-overflow: ellipsis; "> {{ service?.memo  }} </td>
                        <td style="width:20rem; "> {{ service?.reasonCode  }} </td>
                       
                        <!-- <td style="width:15rem; "> {{ user?.serverAssemblyVersion }} </td> -->
                        </tr>
                </ng-template>
            </p-table>                    
        </div> 
    </div>
</form>

<p-dialog [(visible)]="updateDialog" [style]="{'width': '50rem'}"  styleClass="p-fluid" id="updateUnitDialog" showHeader="true"   >
    <ng-template pTemplate="header" >Unit Update Options  - Selected Units {{ this.selectedUnitData?.length }} </ng-template>
        <form [formGroup]='unitUpdateOptionsFG'>
            <div class="grid m-2 " > 
                <div class="col-6 field"  >
                    <p-checkbox formControlName="replaceUnitValue" [ngStyle]="{'width':'2rem'}"  [binary]="true" inputId="replaceUnitValue"></p-checkbox>
                    <label for="replaceUnitValue" style="width:9rem" >Replace Unit Value</label>
                </div>

                <div class="col-6 field grid ">
                    <label class="control-label"  for="unitValue" style="width:5rem" > Value</label>
                    <div class="col">
                        <p-inputNumber class="ml-1" formControlName="unitValue"  mode="decimal"  [minFractionDigits]="4" inputId="unitValue" [ngStyle]="{'font-weight': 'bolder'}"> </p-inputNumber>
                    </div>
                </div>

                <div class="col-12 field grid" >
                    <label class="control-label m-0  mr-2" for="billableUpdateOption" style="width:18rem" >Billable Update Option </label>
                    <div class="col">
                        <p-dropdown formControlName="billableUpdateOption" [options]="binaryOptions" optionLabel="description"  optionValue="id"  appendTo="body" [ngStyle]="{'width':'40rem'}" ></p-dropdown>
                    </div>
                </div>

                <div class="col-12 field grid " >
                    <label class="control-label m-0  mr-2" for="nonBillableUpdateOption" style="width:18rem"  >Non Billable Update Option </label>
                    <div class="col">
                        <p-dropdown formControlName="nonBillableUpdateOption" [options]="binaryOptions" optionLabel="description" optionValue="id"  appendTo="body" [ngStyle]="{'width':'40rem'}" ></p-dropdown>
                    </div>
                </div>

                <div class="col-12 field grid " >
                    <label class="control-label m-0  mr-2" for="deleteUpdateOption" style="width:18rem"  >Deleted Option </label>
                    <div class="col">
                        <p-dropdown formControlName="deleteUpdateOption" [options]="binaryOptions" optionLabel="description" optionValue="id"  appendTo="body" [ngStyle]="{'width':'40rem'}" ></p-dropdown>
                    </div>
                </div>

                <div class="col-12 field grid " >
                    <label class="control-label m-0 mr-2" for="contractUpdateOption" style="width:18rem"  >Provider Contract</label>
                    <div class="col">
                        <p-dropdown formControlName="contractUpdateOption" [options]="providerContractsToUpdate" optionLabel="programName" optionValue="guid" appendTo="body"  [ngStyle]="{'width':'40rem'}" ></p-dropdown>
                    </div>
                </div>

                <div class="col-12 field grid " >
                    <label class="control-label m-0 mr-2" for="newFunderOption" style="width:18rem">Funder</label>
                    <div class="col">
                        <p-dropdown formControlName="newFunderOption" [options]="this.fundersToUpdate" optionLabel="description" optionValue="id" appendTo="body"  [ngStyle]="{'width':'40rem'}" ></p-dropdown>
                    </div>
                </div>

                <div class="col-12 field grid " >
                    <label class="control-label m-0 mr-2" for="newServiceOption" style="width:18rem" >Service Category</label>
                    <div class="col">
                        <p-dropdown formControlName="newServiceOption" [options]="this.serviceCategoriesToUpdate" optionLabel="description" optionValue="id" appendTo="body"  [ngStyle]="{'width':'40rem'}" (onChange)="onChangeServiceTypeForUpdate($event.value)"></p-dropdown>
                    </div>
                </div>

                <div class="col-12 field grid " >
                    <label class="control-label m-0 mr-2" for="newSubTypeOption" style="width:18rem" >Sub Types</label>
                    <div class="col">
                        <p-dropdown id="newSubTypeOption" formControlName="newSubTypeOption" [options]="this.subTypesToUpdate" optionLabel="description" optionValue="id" appendTo="body"  [ngStyle]="{'width':'40rem'}"></p-dropdown>
                    </div>
                </div>


                <div  class="col-12 field grid flex justify-content-end"  > 
                    <p-button  pButton pRipple type="button"  [ngStyle]="{'width':'18rem'}" styleClass="p-button-text note-button" 
                                    class="p-button-help p-button-text p-button mr-4 ml-0 mt-1 "  (click)="saveUnitChanges($event)" >
                                <i class="fa-sharp fa-regular fa-check" style="color:green" pTooltip="Apply the change options to the database."  ></i>
                                <span class="ml-2 font-bold">Save options to database</span>
                    </p-button>
                </div>

                        
            </div> 
        </form>
</p-dialog>

