import { Injectable } from '@angular/core';
import { format, parseISO, addDays, subDays } from 'date-fns';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { CommonDataModel, CommonData, ServiceCategory, SubType, ServiceSubType } from 'src/app/models/common-data.model'

@Injectable({
  providedIn: 'root'
})
export class MedicalCommon {

    constructor() { }
  
    public getMinScreenedDate = (screenedDate: Date, daysToSubtract: number = 365): Date => { 
        return subDays(screenedDate, daysToSubtract);
    }

    public getMaxScreenedDate = (screenedDate: Date, daysToAdd: number = 1): Date => { 
        return addDays(screenedDate , daysToAdd);
    }
}